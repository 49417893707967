import { ApolloCache } from '@apollo/client';

export function isNotNull<T>(value: T | null): value is T {
  return value !== null;
}

// Type parameters are not required in TS but the standard way to enforce it
// is to provide a not useful default type param, e.g. `never`.
// To provide nicer error messages use `RequiredTypeParma<"Some useful message">`
// The tsc error will look like this:
//   Argument of type '{ deviceID: any; }' is not assignable to parameter
//   of type 'RequiredTypeParam<"InputType parameter is required for a mutation">'.
const RequiredTypeParmField = Symbol();
export type RequiredTypeParam<Msg extends string> = {
  [RequiredTypeParmField]: Msg;
};

/**
 * This tells typscript the indexable object does not contain undefined/null fields.
 * It does not check it for you, only use when TS wont understand.
 */
export function withoutNullFields<T>(o: { [k: string]: T }): {
  [k: string]: NonNullable<T>;
} {
  return o as { [k: string]: NonNullable<T> };
}

export type CacheObject = {
  id: string;
  __typename: string;
};

export function cacheEvict<Type extends CacheObject>(
  cacheObject: Type,
  cache: ApolloCache<any>,
) {
  const normalizedId = cache.identify({
    id: cacheObject.id,
    __typename: cacheObject.__typename,
  });
  cache.evict({ id: normalizedId });
  cache.gc();
}

export function getEnvVariable<T extends string>(key: string): T {
  return process.env[key] as T;
}

export function getEnvVariableOrThrow<T extends string>(key: string): T {
  const value = getEnvVariable<T>(key);
  if (!value) {
    throw new Error(`${key} environment variable not configured`);
  }
  return value;
}

export function getEnvVariableOrDefault<T extends string>(
  key: string,
  defaultValue: T,
): T {
  return getEnvVariable<T>(key) ?? defaultValue;
}
