import React, { useCallback, useState } from 'react';

import { useApolloClient, useQuery } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/SaveAlt';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';

import {
  QUERY_DEVICE_WITH_CONFIG_BY_ID,
  QUERY_INSTANCE_CONFIG_BY_DEVICE_ID,
} from 'client/app/api/gql/queries';
import EditRunConfig from 'client/app/components/DeviceLibrary/EditRunConfig';
import { callDeleteDeviceRunConfigs } from 'client/app/components/DeviceLibrary/mutations';
import { ArrayElement, DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import { downloadTextFile } from 'common/lib/download';
import Checkbox from 'common/ui/components/Checkbox';
import { DialogManager, useDialogManager } from 'common/ui/components/DialogManager';
import InfoDialog from 'common/ui/components/InfoDialog';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type Props = {
  device: DeviceCommon;
  onRefresh: () => void;
};

type RunConfig = ArrayElement<DeviceCommon['runConfigSummaries']>;

function DeviceConfigsPanel(props: Props) {
  const dialogManager = useDialogManager();
  const apollo = useApolloClient();
  const { device, onRefresh } = props;
  const [selected, setSelected] = useState<Set<RunConfig>>(new Set());
  const classes = useStyles();

  const onEditRunConfig = useCallback(
    async (config: RunConfig) => {
      const savedChanges = await dialogManager.openDialogPromise(
        'EDIT_DEVICE_RUN_CONFIG',
        EditRunConfig,
        {
          device,
          existingConfig: config,
        },
      );

      if (savedChanges) {
        onRefresh();
      }
    },
    [device, dialogManager, onRefresh],
  );

  const onDeleteRunConfigs = useCallback(
    async (runConfigs: RunConfig[]) => {
      await callDeleteDeviceRunConfigs(apollo, {
        ids: runConfigs.map(runConfig => runConfig.id),
      });
      onRefresh();
    },
    [apollo, onRefresh],
  );

  const toggleSelectConfig = useCallback(
    (config: RunConfig) => {
      if (selected.has(config)) {
        setSelected(new Set([...selected].filter(c => c !== config)));
      } else {
        setSelected(new Set([...selected, config]));
      }
    },
    [selected],
  );

  const onSelectAll = useCallback(() => {
    if (selected.size < device.runConfigSummaries.length) {
      setSelected(new Set(device.runConfigSummaries));
    } else {
      setSelected(new Set());
    }
  }, [selected, device]);

  const onDownloadRunConfig = useCallback(
    (config: RunConfig) => {
      // TODO: this query should probably be by device ID, not anthahub id.
      //       It's ok currently as all devices with RunConfig have anthaHubGUID
      if (device.anthaHubGUID == null) {
        throw new Error(`Device is missing antha hub GUID`);
      }

      apollo
        .query({
          query: QUERY_DEVICE_WITH_CONFIG_BY_ID,
          variables: {
            anthaHubGUID: device.anthaHubGUID,
          },
        })
        .then(({ data, errors, loading }) => {
          if (!data || loading) {
            return;
          }
          if (errors) {
            throw new Error('Request error:\n' + errors.map(e => e.message).join('\n'));
          }
          const gqlDevice = data.devices[0];
          const fullConfig = gqlDevice.runConfigs.find(({ id }) => id === config.id);
          if (!fullConfig) {
            throw new Error(
              "Selected config doesn't exist. Please check if it was removed or try again.",
            );
          }
          if (!gqlDevice.instanceConfig) {
            throw new Error(
              'Selected config does not have an instanceConfig. Check configuration validity of this device.',
            );
          }
          const instanceConfig = gqlDevice.instanceConfig.config;
          // when downloading a configuration a user will select a particular configuration. As such there will only be
          // one runConfig. For proper parsing though we want to send it as an array of one element, so it is consistent
          // with the device config schema. We also include all the fields of the "instance" config, which is
          // not only the instance config, but also the DeviceConfigData, including SchemaVersion, SchemaType,
          // and a DeviceConfigSummary which is confusingly and probably incorrectly in a field named AnthaDeviceConfig.
          const outputConfig = {
            ...instanceConfig,
            RunConfigs: [fullConfig.config],
          };
          downloadConfigJson(outputConfig, device.name);
        })
        .catch(e => {
          console.error(e);
          dialogManager.openDialog('DOWNLOAD_DEVICE_CONFIG_ERROR', InfoDialog, {
            title: 'Download device configuration',
            message:
              `Error occured when downloading configuration ${config.name} for device ${device.name}:\n` +
              e.message,
          });
        });
    },
    [apollo, device.anthaHubGUID, device.name, dialogManager],
  );

  const hasNoRunConfigs = device.runConfigSummaries.length === 0;
  return (
    <List disablePadding>
      <DeviceInstanceConfigView
        deviceId={device.id}
        deviceName={device.name}
        dialogManager={dialogManager}
        hasNoRunConfigs={hasNoRunConfigs}
      />
      {hasNoRunConfigs ? (
        <ListItem disabled className={classes.infoMessage}>
          <Typography variant="body1">
            This device does not have any run configurations.
          </Typography>
        </ListItem>
      ) : (
        <>
          <ListSubheader className={classes.listSubheader}>
            <Box
              display="flex"
              alignItems="center"
              className={classes.listItemRoot}
              pr={2}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  indeterminate={
                    selected.size > 0 && selected.size < device.runConfigSummaries.length
                  }
                  checked={
                    device.runConfigSummaries.length > 0 &&
                    selected.size === device.runConfigSummaries.length
                  }
                  onChange={onSelectAll}
                  inputProps={{
                    'aria-label': 'Select all device configurations',
                  }}
                />
              </ListItemIcon>
              <Box flexGrow={1}>
                <ListItemText
                  primary="Run Configurations"
                  primaryTypographyProps={{ variant: 'subtitle2' }}
                />
              </Box>
              {selected.size > 0 && (
                <IconButton
                  onClick={() => onDeleteRunConfigs([...selected])}
                  aria-label="Delete the selected run configurations"
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
            <Divider />
          </ListSubheader>
          {device.runConfigSummaries.map(config => (
            <ListItem
              key={config.id}
              button
              onClick={() => toggleSelectConfig(config)}
              classes={{
                container: classes.listItem,
                root: classes.listItemRoot,
              }}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selected.has(config)}
                  tabIndex={-1} // the list item itself is tab selectable, so disable tab index for the checkbox
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={config.name} />
              <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                <IconButton
                  onClick={() => onDownloadRunConfig(config)}
                  aria-label="Download this run configuration"
                  size="large"
                >
                  <DownloadIcon />
                </IconButton>
                <IconButton
                  onClick={() => onEditRunConfig(config)}
                  aria-label="Rename this run configuration"
                  size="large"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={() => onDeleteRunConfigs([config])}
                  aria-label="Delete this run configuration"
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </>
      )}
    </List>
  );
}

function downloadConfigJson<T>(config: T, deviceName: string) {
  downloadTextFile(
    JSON.stringify(config, undefined, '  '),
    `${deviceName} - Global configuration.json`,
    'application/json',
  );
}

/**
 * We support uploading a JSON file from the lab which has exactly one instance config,
 * and 0..n run configs. In the appserver, this gets split into the instance config and
 * run configs and stored separately.
 * It is important to show to the user the instance config was stored successfully. This
 * is especially important in the case when there is one instance config and *zero* run
 * configs, so that we show in the UI the instance config has been stored.
 * This is a common case that happens with plate washers for example - some plate washers
 * only have an instance config an no run configs.
 */
function DeviceInstanceConfigView(props: {
  deviceName: string;
  deviceId: string;
  dialogManager: DialogManager;
  hasNoRunConfigs: boolean;
}) {
  // Use a separate GraphQL query. This way we only fetch the instance config when
  // needed (in this screen) rather than query for the instance config any time we
  // fetch a list of devices (DeviceCommon type) which is done in a lot of places.
  const {
    data: devicesWithInstanceConfig,
    loading,
    error,
  } = useQuery(QUERY_INSTANCE_CONFIG_BY_DEVICE_ID, {
    variables: {
      id: props.deviceId as DeviceId,
    },
  });

  // The GraphQL query returns a list of devices even though it's a query by id :(
  // Extract the single returned device.
  const deviceWithInstanceConfig =
    devicesWithInstanceConfig?.devices?.length === 1 &&
    devicesWithInstanceConfig?.devices[0];

  const instanceConfig = deviceWithInstanceConfig
    ? deviceWithInstanceConfig.instanceConfig?.config
    : null;

  const onDownloadInstanceConfig = useCallback(async () => {
    if (!instanceConfig || loading) {
      return;
    }
    if (error) {
      console.error('Error while downloading the instanceConfig', error);
      props.dialogManager.openDialog('DOWNLOAD_DEVICE_CONFIG_ERROR', InfoDialog, {
        title: 'Download device configuration',
        message:
          `Error occured when downloading global configuration for device ${props.deviceName}:\n` +
          error.message,
      });
    }

    downloadConfigJson(instanceConfig, props.deviceName);
  }, [error, instanceConfig, loading, props.deviceName, props.dialogManager]);

  if (!devicesWithInstanceConfig) {
    // Loading
    // &nbsp; to keep the size consistent and prevent layout jumping as the data is loaded
    return <InstanceConfigListItem disabled text="&nbsp;" />;
  }

  if (!deviceWithInstanceConfig) {
    console.error(
      `Fetching instance config failed: There should be exactly one device with ` +
        `id ${props.deviceId} but found ${devicesWithInstanceConfig?.devices?.length} devices.`,
    );
    return null;
  }

  const showDownloadButton = props.hasNoRunConfigs && instanceConfig;
  return (
    <InstanceConfigListItem
      disabled={!instanceConfig}
      onDownloadInstanceConfig={onDownloadInstanceConfig}
      showDownloadButton={!!showDownloadButton}
      text={
        instanceConfig
          ? 'This device has a global configuration.'
          : 'This device does not have a global configuration.'
      }
    />
  );
}

function InstanceConfigListItem(props: {
  text: string;
  disabled: boolean;
  showDownloadButton?: boolean;
  onDownloadInstanceConfig?: () => void;
}) {
  const classes = useStyles();
  return (
    <ListItem className={classes.infoMessage} disabled={props.disabled}>
      <Typography variant="body1">{props.text}</Typography>
      {props.showDownloadButton && (
        <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
          <IconButton
            onClick={props.onDownloadInstanceConfig}
            aria-label="Download the global configuration"
            size="large"
          >
            <DownloadIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

const useStyles = makeStylesHook(theme => ({
  listSubheader: {
    backgroundColor: 'white', // ensure that the subheader has an opaque background
    padding: 0,
    zIndex: 2, // place subheader above items; without this, the checkbox cannot be used if an item is underneath the subheader
  },
  listItemRoot: {
    paddingLeft: '24px', // horizontally align list item text with dialog title
  },
  listItemSecondaryAction: {}, // referenced below
  listItem: {
    '& $listItemSecondaryAction': {
      visibility: 'hidden',
    },
    '&:hover $listItemSecondaryAction': {
      visibility: 'visible',
    },
  },
  infoMessage: {
    margin: theme.spacing(5),
  },
}));

export default DeviceConfigsPanel;
