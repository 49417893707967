import React, { useMemo } from 'react';

import ErrorIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import findLastIndex from 'lodash/findLastIndex';

import {
  AnthaHubExecutionTask,
  ExecutionTaskID,
  ExecutionTaskStatus,
  isTerminalExecutionTaskStatus,
} from 'common/types/execution';
import Colors from 'common/ui/Colors';
import Alert from 'common/ui/components/Alert';
import Button from 'common/ui/components/Button';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import EmptyGridCell from 'common/ui/components/EmptyGridCell';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

type AnthaHubDevice = { id_on_hub: string };

//#region ExecutionStatusBar

/**
 * Props used only in Hub UI
 */
type ExecutionStatusPropsForHubUI = {
  /**
   * Devices registered with this AnthaHub
   */
  devicesRegistered: AnthaHubDevice[];
  /**
   * Status of AnthaHub's UI switch
   */
  isAutorunToggleEnabled?: boolean;
  /**
   * Callback to run an execution task
   */
  runExecutionTask: (executionTaskId: ExecutionTaskID) => Promise<Response>;
  /**
   * Callback to mark an execution task as skipped
   */
  skipExecutionTask: (executionTaskId: ExecutionTaskID) => Promise<Response>;
};

/**
 * A mapping over ExecutionStatusPropsForHubUI, marking each property as disallowed so that
 * they must either all be supplied or none supplied
 */
type DisallowedExecutionStatusPropsForHubUI = {
  [key in keyof ExecutionStatusPropsForHubUI]?: never;
};

type ExecutionStatusBarProps = {
  tasks: AnthaHubExecutionTask[];
  fullWidth?: boolean;
  /**
   * Hide "Execution Status". Used when placing the status bar inside a card
   *  that already has an header (e.g. in Results Screen)
   */
  hideHeader?: boolean;
} & (ExecutionStatusPropsForHubUI | DisallowedExecutionStatusPropsForHubUI);

function getStatusMessage(currentTask: AnthaHubExecutionTask) {
  switch (currentTask.status) {
    case ExecutionTaskStatus.FAILED:
    case ExecutionTaskStatus.RESUME_FAILED:
      return `Error in: ${currentTask.name}`;

    case ExecutionTaskStatus.RUNNING:
      return `Running: ${currentTask.name}`;

    case ExecutionTaskStatus.CANCELLED:
      return `Cancelled: ${currentTask.name}`;

    case ExecutionTaskStatus.PENDING:
      return `Starting: ${currentTask.name}`;

    case ExecutionTaskStatus.SUCCEEDED:
      return `Completed: ${currentTask.name}`;

    case ExecutionTaskStatus.SKIPPED:
      return `Skipped: ${currentTask.name}`;

    default:
      return `Ready to run: ${currentTask.name}`;
  }
}

function getCurrentAndFollowingTaskStatusMessages(
  currentTask: AnthaHubExecutionTask,
  followingTask: AnthaHubExecutionTask | undefined,
  isAutorunToggleEnabled: boolean | undefined,
  devicesRegistered: AnthaHubDevice[] | undefined,
) {
  const currentTaskStatusMessage = getStatusMessage(currentTask);
  let followingTaskStatusMessage: string | undefined;
  // When in autorun mode, show users what task will be run next. e.g. "Completed LH -
  // ready to run PR"
  if (isAutorunToggleEnabled && followingTask) {
    const followingTaskBelongsToThisHub = devicesRegistered?.find(
      deviceRegistered => deviceRegistered.id_on_hub === followingTask.anthaHubDeviceGuid,
    );
    followingTaskStatusMessage = followingTaskBelongsToThisHub
      ? `Next: ${followingTask.name}`
      : `Next: ${followingTask.name} (Will run on AnthaHub on a different PC)`;
  }
  return { currentTaskStatusMessage, followingTaskStatusMessage };
}

export function computeTaskToDisplay(tasks: AnthaHubExecutionTask[]): {
  currentTask: AnthaHubExecutionTask;
  followingTask?: AnthaHubExecutionTask;
} {
  const lastRunningTaskIndex = findLastIndex(
    tasks,
    task => task.status === ExecutionTaskStatus.RUNNING,
  );
  // If a task is running, we should display it in the StatusBar.
  if (lastRunningTaskIndex >= 0) {
    return {
      currentTask: tasks[lastRunningTaskIndex],
      followingTask: tasks[lastRunningTaskIndex + 1],
    };
  }

  const lastFailedTaskIndex = findLastIndex(
    tasks,
    task => task.status === ExecutionTaskStatus.FAILED,
  );
  // If no tasks are running, show the last errored task, as users can retry errored tasks
  if (lastFailedTaskIndex >= 0) {
    return {
      currentTask: tasks[lastFailedTaskIndex],
      followingTask: tasks[lastFailedTaskIndex + 1],
    };
  } else {
    const lastExecutedOrSkippedTaskIndex = findLastIndex(
      tasks,
      task => task.status !== ExecutionTaskStatus.QUEUED,
    );
    // If no task errored and users did not take actions on any task,
    // show the very first task
    if (lastExecutedOrSkippedTaskIndex < 0) {
      return {
        currentTask: tasks[0],
        followingTask: tasks[1],
      };
    }
    // Else, show the last task users took an action on.
    return {
      currentTask: tasks[lastExecutedOrSkippedTaskIndex],
      followingTask: tasks[lastExecutedOrSkippedTaskIndex + 1],
    };
  }
}

export default function ExecutionStatusBar({
  devicesRegistered,
  fullWidth,
  hideHeader,
  isAutorunToggleEnabled,
  tasks,
  runExecutionTask,
  skipExecutionTask,
}: ExecutionStatusBarProps) {
  const classes = useStyles();

  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

  const { currentTask, followingTask } = useMemo(
    () => computeTaskToDisplay(tasks),
    [tasks],
  );

  const { currentTaskStatusMessage, followingTaskStatusMessage } = useMemo(
    () =>
      getCurrentAndFollowingTaskStatusMessages(
        currentTask,
        followingTask,
        isAutorunToggleEnabled,
        devicesRegistered,
      ),
    [currentTask, devicesRegistered, followingTask, isAutorunToggleEnabled],
  );

  return (
    <>
      <div
        className={cx(classes.container, {
          [classes.fullWidthContainer]: fullWidth,
          [classes.narrowWidthContainer]: !fullWidth,
        })}
      >
        {!hideHeader && (
          <Typography variant="overline" className={classes.currentStatusLabel}>
            Execution Status
          </Typography>
        )}
        <EmptyGridCell />
        <Typography variant="body2" className={classes.statusMessage}>
          {currentTaskStatusMessage}
          {followingTaskStatusMessage && (
            <span>
              <br />
              {followingTaskStatusMessage}
            </span>
          )}
        </Typography>
        {runExecutionTask ? (
          <div className={classes.actions}>
            <ActionsOrNotification
              currentTask={currentTask}
              devicesRegistered={devicesRegistered}
              followingTask={followingTask}
              isAutorunToggleEnabled={isAutorunToggleEnabled}
              openConfirmationDialog={openConfirmationDialog}
              tasks={tasks}
              runExecutionTask={runExecutionTask}
              skipExecutionTask={skipExecutionTask}
            />
          </div>
        ) : null}
      </div>
      {confirmationDialog}
    </>
  );
}

//#endregion

//#region ActionButton

type ActionButtonProps = {
  label: string;
  onClick: () => void;
  className?: string;
};

function ActionButton(props: ActionButtonProps) {
  const classes = useStyles();
  return (
    <Button
      variant="secondary"
      size="small"
      onClick={props.onClick}
      className={props.className || classes.actionBtn}
    >
      {props.label}
    </Button>
  );
}

//#endregion

//#region ActionsOrNotifications
type ActionsOrNotificationProps = {
  currentTask: AnthaHubExecutionTask;
  devicesRegistered: { id_on_hub: string }[];
  followingTask?: AnthaHubExecutionTask;
  isAutorunToggleEnabled?: boolean;
  openConfirmationDialog: (props: any) => Promise<boolean>;
  tasks: AnthaHubExecutionTask[];
  runExecutionTask: (executionTaskId: ExecutionTaskID) => Promise<Response>;
  skipExecutionTask: (executionTaskId: ExecutionTaskID) => Promise<Response>;
};

/* The statusbar will show either actions (retry, skip, continue), or a notification
 * showing the outcome of the execution (e.g. succeeded, failed). */
function ActionsOrNotification({
  currentTask,
  devicesRegistered,
  followingTask,
  isAutorunToggleEnabled,
  tasks,
  runExecutionTask,
  skipExecutionTask,
}: ActionsOrNotificationProps) {
  const classes = useStyles();
  const snackbarManager = useSnackbarManager();

  const handleSkipTask = async (taskToSkip: AnthaHubExecutionTask) => {
    logEvent(`skip_task_from_statusbar`, 'anthahub');
    const response = await skipExecutionTask(taskToSkip.id);
    if (response.ok) {
      snackbarManager.showSuccess(`Skipped ${taskToSkip.name}`);
    } else {
      snackbarManager.showError(
        `There was an error skipping the task. Please check that the SynthaceHub service is running.`,
      );
    }
  };

  const handleRetryCurrentTask = async () => {
    logEvent(`retry_task_from_statusbar`, 'anthahub');

    const response = await runExecutionTask(currentTask.id);
    if (response.ok) {
      snackbarManager.showSuccess(`Retrying ${currentTask.name}`);
    } else {
      snackbarManager.showError(
        `There was an error retrying the task. Please check that the SynthaceHub service is running.`,
      );
    }
  };

  const handleStartTask = async (taskToContinueFrom: AnthaHubExecutionTask) => {
    logEvent(`continue_task_from_statusbar`, 'anthahub');

    const response = await runExecutionTask(taskToContinueFrom.id);
    if (response.ok) {
      snackbarManager.showSuccess(`Continuing ${taskToContinueFrom.name}`);
    } else {
      snackbarManager.showError(
        `There was an error continuing the task. Please check that the AnthaHub service is running.`,
      );
    }
  };

  // AnthaHub should control only the tasks belonging to a device which is
  // registered to the current AnthaHub.
  const currentTaskBelongsToAnotherAnthaHub = !devicesRegistered.find(
    deviceRegistered => deviceRegistered.id_on_hub === currentTask.anthaHubDeviceGuid,
  );
  if (currentTaskBelongsToAnotherAnthaHub) {
    const followingTaskExistsAndBelongsToThisHub = !!(
      followingTask &&
      devicesRegistered.find(
        deviceRegistered =>
          deviceRegistered.id_on_hub === followingTask.anthaHubDeviceGuid,
      )
    );
    // If the current task has been completed or skipped on the other hub with auto-run toggled on,
    // and a following task exists and belongs to this hub, allow users to continue or skip
    if (
      isAutorunToggleEnabled &&
      followingTaskExistsAndBelongsToThisHub &&
      (currentTask.status === ExecutionTaskStatus.SUCCEEDED ||
        currentTask.status === ExecutionTaskStatus.SKIPPED)
    ) {
      return (
        <>
          <ActionButton label="Continue" onClick={() => handleStartTask(followingTask)} />
          <ActionButton label="Skip" onClick={() => handleSkipTask(followingTask)} />
        </>
      );
    }
    return (
      <>
        <InfoOutlinedIcon className={classes.infoIcon} />
        <Typography>
          To proceed with the next task, either successfully complete or skip the task(s)
          in the other SynthaceHub
        </Typography>
      </>
    );
  }

  const isLastTask = currentTask === tasks[tasks.length - 1];

  // Failed and cancelled tasks are retryable & skippable
  if (
    currentTask.status === ExecutionTaskStatus.FAILED ||
    currentTask.status === ExecutionTaskStatus.CANCELLED ||
    currentTask.status === ExecutionTaskStatus.RESUME_FAILED
  ) {
    return (
      <>
        {currentTask.status === ExecutionTaskStatus.FAILED && (
          <>
            <ErrorIcon className={classes.errorIcon} />
            {/* Show tooltip with full message because the message might be cut off if too long. */}
            <Tooltip title={currentTask?.driverMessage || ''}>
              <Typography className={classes.errorMessage}>
                {currentTask?.driverMessage || ''}
              </Typography>
            </Tooltip>
          </>
        )}
        <ActionButton
          label="Retry"
          onClick={handleRetryCurrentTask}
          className={classes.retryBtn}
        />
        <ActionButton label="Skip" onClick={() => handleSkipTask(currentTask)} />
      </>
    );
  }

  // These controls are shown only if Autorun UI toggle is ON,
  // because if OFF, users are not blocked and can run other tasks in any order.
  // If a task succeeded but the automatic execution was stopped (i.e. next
  // task won't run automatically) or task was explicitly skipped, allow users to continue
  // the execution.
  if (
    (currentTask.status === ExecutionTaskStatus.SUCCEEDED ||
      currentTask.status === ExecutionTaskStatus.SKIPPED) &&
    !isLastTask
  ) {
    if (!isAutorunToggleEnabled) {
      return null;
    }
    const doesFollowingTaskBelongToAnotherHub =
      !!followingTask &&
      !devicesRegistered.find(
        deviceRegistered =>
          deviceRegistered.id_on_hub === followingTask.anthaHubDeviceGuid,
      );
    return doesFollowingTaskBelongToAnotherHub ? (
      <Typography>Please run the next task on the other SynthaceHub</Typography>
    ) : (
      <>
        {followingTask ? (
          <>
            <ActionButton
              label="Continue"
              onClick={() => handleStartTask(followingTask)}
            />
            <ActionButton label="Skip" onClick={() => handleSkipTask(followingTask)} />
          </>
        ) : null}
      </>
    );
  }

  const allTaskSucceeded = tasks.every(
    task => task.status === ExecutionTaskStatus.SUCCEEDED,
  );
  if (allTaskSucceeded) {
    return (
      <Alert severity="success" icon={<NotificationImportantOutlinedIcon />}>
        Execution completed successfully
      </Alert>
    );
  }

  const allTaskCompleted = tasks.every(task =>
    isTerminalExecutionTaskStatus(task.status),
  );
  if (allTaskCompleted) {
    return (
      <Alert severity="info" icon={<NotificationImportantOutlinedIcon />}>
        Execution completed - not all tasks were successful
      </Alert>
    );
  }

  return null;
}

//#endregion

const useStyles = makeStylesHook(theme => ({
  container: {
    height: '80px',
    backgroundColor: Colors.WHITE,
    display: 'grid',
    gridTemplateRows: '24px 56px',
  },
  statusMessage: {
    '&.MuiTypography-root': {
      alignSelf: 'center',
      marginLeft: theme.spacing(6),
      gridRow: 2,
      minWidth: '150px',
    },
  },
  errorIcon: {
    '&.MuiSvgIcon-root': {
      color: Colors.ERROR,
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(3),
    },
  },
  errorMessage: {
    '&.MuiTypography-root': {
      color: Colors.ERROR,
      marginRight: theme.spacing(5),
      overflow: 'hidden',
      /** Make sure the text doesn't flow out of the container. */
      maxHeight: '60px',
      wordBreak: 'break-all',
    },
  },
  infoIcon: {
    '&.MuiSvgIcon-root': {
      color: Colors.INFO_MAIN,
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(3),
    },
  },
  actions: {
    gridRow: '2',
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'flex-end',
    margin: theme.spacing(0, 5, 0, 3),
  },
  retryBtn: {
    '&.MuiButtonBase-root': {
      color: Colors.GREEN,
      borderColor: Colors.GREEN,
      gridRow: 2,
    },
  },
  actionBtn: {
    '&.MuiButtonBase-root': {
      gridRow: 2,
      marginLeft: theme.spacing(3),
    },
  },
  currentStatusLabel: {
    '&.MuiTypography-root': {
      marginTop: theme.spacing(6),
      marginLeft: theme.spacing(6),
      alignSelf: 'center',
    },
  },
  fullWidthContainer: {
    width: '100%',
  },
  narrowWidthContainer: {
    width: '80%',
    margin: 'auto',
  },
}));
