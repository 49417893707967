import React, { useCallback, useState } from 'react';

import DialogContent from '@mui/material/DialogContent';

import FilePicker from 'client/app/components/FileBrowser/FilePicker';
import { FileBrowserFileSelection } from 'client/app/lib/file-browser/types';
import Colors from 'common/ui/Colors';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import DialogActions from 'common/ui/components/Dialog/DialogActions';
import SelectionActions from 'common/ui/components/Dialog/SelectionActions';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

/**
 * Return the quantity of selected file in FileBrowserFileSelection.
 *
 * FileBrowserFileSelection can be either an array of file selections, a single selection, or no selection.
 *
 */
function getQuantity(value: FileBrowserFileSelection) {
  if (Array.isArray(value)) {
    return value.length;
  }
  return !value ? 0 : 1;
}

export type PathSelectorProps = {
  value: FileBrowserFileSelection;
  onChange: (value: FileBrowserFileSelection) => void;
  selectMultiple?: boolean;
  basePath?: string; // This is the path to start showing in the filePicker.
  overrideDialogTitle?: string;
  onBackClick?: () => void;
  dialogClassName?: string;
} & DialogProps<boolean>;

export default React.memo(function PathSelectorDialog(props: PathSelectorProps) {
  const {
    selectMultiple,
    isOpen,
    onClose,
    onChange,
    overrideDialogTitle,
    basePath,
    onBackClick,
    dialogClassName,
  } = props;
  const classes = useStyles();
  const [value, setValue] = useState(props.value);
  const dialogTitle =
    overrideDialogTitle || (selectMultiple ? 'Select a set of files' : 'Select a file');

  const handleSelect = useCallback(() => {
    // Save the value and close the dialog when selecting.
    onChange(value);
    onClose(true);
  }, [onChange, onClose, value]);

  const handleClear = useCallback(() => {
    setValue(null);
  }, [setValue]);

  const handleCancel = useCallback(() => {
    // On cancel, reset the value back to whatever was initially passed in
    // so that when the dialog closes, it will fire the onChange
    // callback with that same value
    setValue(props.value);
    onClose(false);
  }, [onClose, props.value]);

  const handlePathChange = useCallback(
    (value: FileBrowserFileSelection) => {
      setValue(value);
    },
    [setValue],
  );

  return (
    <ComplexActionDialog
      isOpen={isOpen}
      onClose={handleCancel}
      title={dialogTitle}
      onBackClick={onBackClick}
      className={dialogClassName}
      content={
        <DialogContent className={classes.content}>
          <FilePicker
            value={value}
            onChange={handlePathChange}
            selectMultiple={selectMultiple}
            skipDisplayPath={basePath ?? ''} // This path dictates what FileSegments to not show.
            basePath={basePath ? basePath + '/' : '/'} // The folder where we want to start the base to be at.
          />
        </DialogContent>
      }
      dialogActions={
        <DialogActions>
          <SelectionActions
            handleClear={handleClear}
            itemLabel="file"
            primaryActionLabel="Select"
            handleCancel={handleCancel}
            handleConfirm={handleSelect}
            selectedItems={getQuantity(value)}
            disableEmptySelection
          />
        </DialogActions>
      }
    />
  );
});

const useStyles = makeStylesHook({
  content: {
    backgroundColor: Colors.GREY_5,
    height: '85vh',
  },
});
