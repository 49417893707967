import React, { useCallback, useEffect, useState } from 'react';

import { Auth0ContextInterface } from '@auth0/auth0-react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { CUSTOMER_CONN_INITIAL_VALUE } from 'client/app/apps/Login/LoginCheck';
import supportedBrowsers from 'client/app/lib/supportedBrowsers';
import { SynthaceLogo } from 'common/assets/SynthaceLogo';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import DebouncedTextField from 'common/ui/components/DebouncedTextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useStateWithLocalStorage from 'common/ui/hooks/useStateWithLocalStorage';
import { anthaTypography } from 'common/ui/Typography';

const ORGANISATION_LOCALSTORAGE_KEY = 'organisation';

const isUnsupportedBrowser = !supportedBrowsers.test(navigator.userAgent);

export type LoginPageProps = {
  auth0ClientId: string;
  setAuth0ClientId: (value: string) => void;
  customerConnectionName: string | undefined;
  setCustomerConnectionName: (value: string) => void;
  isAuth0Loading: boolean;
  isAuthenticated: boolean;
  loginWithRedirectRef: React.MutableRefObject<
    undefined | Auth0ContextInterface['loginWithRedirect']
  >;
};

function LoginPage(props: LoginPageProps) {
  const classes = useStyles();
  const {
    auth0ClientId,
    setAuth0ClientId,
    customerConnectionName,
    setCustomerConnectionName,
    loginWithRedirectRef,
    isAuthenticated,
    isAuth0Loading,
  } = props;
  const [organisation, setOrganisation] = useStateWithLocalStorage<string>(
    ORGANISATION_LOCALSTORAGE_KEY,
    '',
  );

  // When auth0 is `isVerifying` we don't know whether it is because:
  // - user just changed the org
  // - we are reloading the page or loading a new tab
  // We do not want to show a spinner in the first case,
  // so we record the interaction to know we are in first case.
  const [userHasInteracted, setUserHasInteracted] = useState(false);

  const debouncedHandleChangeOrganisation = useCallback(
    (currentOrganisation: string) => {
      (async () => {
        try {
          const normalisedOrg = currentOrganisation.toLowerCase().trim();
          const response = await fetch(`/web/pub-v1/org/${normalisedOrg}/auth0ClientId`);
          const payload = await response.json();
          setAuth0ClientId(payload.auth0ClientId);
          setCustomerConnectionName(payload.customerConnection);
        } catch (e) {
          setAuth0ClientId('');
          setCustomerConnectionName(CUSTOMER_CONN_INITIAL_VALUE);
        }
      })();
    },
    [setAuth0ClientId, setCustomerConnectionName],
  );

  // This is temporarily required for the release of SYN-3675 because we store the auth0ClientId and customerConnection name in local storage.
  // This means that on first login after this change is released a user has the auth0ClientId set but the customerConnection is the initial value.
  // Without this change the user would need to change a character of the org to make debouncedHandleChangeOrganisation fire and fetch the customerConnection.
  // Once SYN-3675 has been released to prod for a few weeks we can delete this block.
  useEffect(() => {
    if (
      organisation &&
      auth0ClientId &&
      customerConnectionName === CUSTOMER_CONN_INITIAL_VALUE
    ) {
      debouncedHandleChangeOrganisation(organisation);
    }
  }, [
    organisation,
    auth0ClientId,
    customerConnectionName,
    debouncedHandleChangeOrganisation,
  ]);

  const handleChangeOrganisation = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentOrganisation = e.target.value;
      // We do not debounce those change, to avoid the login button to stay enabled
      setOrganisation(currentOrganisation);
      setAuth0ClientId('');
      setUserHasInteracted(true);
    },
    [setAuth0ClientId, setOrganisation],
  );

  const handleSubmit = useCallback(async () => {
    if (!loginWithRedirectRef.current) {
      return;
    }
    await loginWithRedirectRef.current({
      appState: {
        // Restore the current URL in the browser location bar after logging in.
        returnTo: window.location.href,
      },
      redirectUri: window.location.href,
    });
  }, [loginWithRedirectRef]);

  const handleFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (auth0ClientId && customerConnectionName !== '') {
        (async () => {
          await handleSubmit();
        })();
      }
    },
    [handleSubmit, auth0ClientId, customerConnectionName],
  );
  const loginDone = isAuth0Loading && !userHasInteracted;

  return !isAuthenticated && !loginDone ? (
    <div className={classes.container}>
      <Paper className={classes.panel} elevation={16}>
        <div className={classes.header}>
          <SynthaceLogo className={classes.logo} />
        </div>
        <div className={classes.content}>
          <Typography variant="subtitle2" color="textPrimary" align="center">
            Please enter the name of your organisation.
          </Typography>
          <form className={classes.textField} onSubmit={handleFormSubmit}>
            <DebouncedTextField
              className={classes.organisationInput}
              fullWidth
              name="organisation"
              placeholder="Organisation name here"
              autoFocus
              InputProps={{ disableUnderline: true }}
              onDebouncedChange={debouncedHandleChangeOrganisation}
              onChange={handleChangeOrganisation}
              delay={200}
              value={organisation}
              disabled={isAuth0Loading && !userHasInteracted}
            />
          </form>
          {!isAuth0Loading || userHasInteracted ? (
            <Button
              variant="tertiary"
              color="primary"
              type="submit"
              form="loginForm"
              onClick={handleSubmit}
              disabled={!auth0ClientId || customerConnectionName === ''}
            >
              Continue To Log In
            </Button>
          ) : (
            <CircularProgress />
          )}
        </div>
      </Paper>
      {isUnsupportedBrowser && (
        <div className={classes.banner}>
          <ErrorOutlineOutlinedIcon className={classes.bannerIcon} />
          <Typography variant="body2" color="textPrimary">
            This browser version is not actively supported. Update your browser to
            experience Synthace&apos;s latest updates.
          </Typography>
        </div>
      )}
    </div>
  ) : null;
}

const useStyles = makeStylesHook(theme => ({
  banner: {
    display: 'flex',
    margin: theme.spacing(8),
    width: '300px',
  },
  bannerIcon: {
    marginRight: theme.spacing(5),
  },
  container: {
    // Linear gradient background of BLUE_50 and INDIGO_70 with opacity of 0.3 overlayed with
    // white background.
    background: `linear-gradient(126.6deg, rgba(22, 112, 255, 0.3) 47.04%, rgba(151, 16, 255, 0.3) 82.93%),\
      linear-gradient(${Colors.WHITE}, ${Colors.WHITE})`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  content: {
    backgroundColor: Colors.BLUE_0,
    borderRadius: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(6),
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    backgroundColor: Colors.GREY_0,
    borderRadius: 'inherit',
    height: '88px',
    justifyContent: 'center',
    padding: theme.spacing(0, 6),
  },
  logo: { height: '32px' },
  panel: {
    borderRadius: '8px',
    width: '300px',
  },
  textField: {
    backgroundColor: Colors.GREY_0,
    borderRadius: '20px',
    margin: theme.spacing(4, 0, 6, 0),
  },
  organisationInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '20px',
    },
    '& .MuiOutlinedInput-root > input': {
      ...anthaTypography.h3,
      height: 'fit-content',
      textAlign: 'center',
      padding: theme.spacing(3, 0),
    },
  },
}));

export default React.memo(LoginPage);
