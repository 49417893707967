import React, { useCallback } from 'react';

import { ArrayElement, DOEWorkflowsQuery } from 'client/app/gql';
import { doeTemplateRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { EntityCard } from 'common/ui/components/EntityCard';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import DOETemplateIcon from 'common/ui/icons/DOETemplateIcon';

type DOETemplateCardProps = {
  doe: ArrayElement<DOEWorkflowsQuery['workflows']['items']>;
};

export default function DOETemplateCard({ doe }: DOETemplateCardProps) {
  const path = doeTemplateRoutes.edit.getPath({
    workflowId: doe.id,
  });
  const handleClick = useCallback(() => {
    logEvent('open-doe', ScreenRegistry.EXPERIMENTS);
  }, []);

  return (
    <EntityCard
      link={path}
      linkTitle="Open DOE"
      onClick={handleClick}
      icon={<DOETemplateIcon fontSize="small" />}
      entityName="DOE"
      name={doe.name}
      author={doe.createdBy.displayName}
      date={new Date(doe.lastModifiedAt)}
    />
  );
}
