import React, { useCallback } from 'react';

import HtmlParser, { convertNodeToElement, Transform } from 'react-html-parser';

import { highlightStringMatches } from 'client/app/lib/workflow/format';
import { Markdown, renderMarkdown } from 'common/lib/markdown';

type Props = {
  markdown: Markdown;
  query: RegExp | null;
};

export function MarkdownQueryHighlighter(props: Props) {
  const { query, markdown } = props;

  // Specify how nodes should be transformed in the HTML parser.
  const transform: Transform = useCallback<Transform>(
    (node, index) => {
      // Convert <p>s to <span>s as they don't add extra padding.
      if (node.type === 'tag' && node.name === 'p') {
        node.name = 'span';
        return convertNodeToElement(node, index, transform);
      }

      // Convert headings to <strong>s so they don't take up as much room.
      if (node.type === 'tag' && node.name?.match('h[1-6]')) {
        node.name = 'strong';
        return convertNodeToElement(node, index, transform);
      }

      // Do the query highlighting on text nodes.
      if (node.type === 'text' && query) {
        return <>{highlightStringMatches(node.data, query)}</>;
      }
      return;
    },
    [query],
  );

  const htmlString = renderMarkdown(markdown);
  const markdownNodes = HtmlParser(htmlString, { transform });

  return <>{markdownNodes}</>;
}
