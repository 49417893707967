import React, { useEffect, useMemo, useState } from 'react';

import Fab from '@mui/material/Fab';

import {
  downloadTaskInstructionFiles,
  getLiquidHandlingTasks,
} from 'client/app/apps/simulation-details/dataUtils';
import { useFetchLiquidHandlingInstructions } from 'client/app/apps/simulation-details/overview/simulationDetailsFiles';
import { TreeEntry } from 'client/app/apps/simulation-details/overview/TreeEntryList';
import { SimulationQuery } from 'client/app/gql';

type Props = {
  simulation: Pick<
    SimulationQuery['simulation'],
    'id' | 'name' | 'tasks' | 'instructionsDownloaded' | '__typename'
  >;
};

export default function LegacyDownloadInstructionsButton({
  simulation: { id, name, tasks, instructionsDownloaded },
}: Props) {
  const mixTasks = useMemo(() => getLiquidHandlingTasks(tasks), [tasks]);

  const [instructions, setInstructions] = useState<readonly TreeEntry[]>([]);
  const fetchLiquidHandlingInstructions = useFetchLiquidHandlingInstructions();

  const handleDownloadInstructions = async () => {
    downloadTaskInstructionFiles(instructions);
  };

  useEffect(() => {
    let cancelled = false;

    if (!mixTasks) {
      return;
    }

    void fetchLiquidHandlingInstructions(id, name, mixTasks).then(instructions => {
      if (!cancelled) {
        setInstructions(instructions);
      }
    });

    return () => {
      cancelled = true;
    };
  }, [id, name, fetchLiquidHandlingInstructions, mixTasks]);

  const color = !instructionsDownloaded ? 'secondary' : 'default';

  const copy = !instructionsDownloaded ? 'Download instructions' : 'Download again';

  return (
    <Fab
      color={color}
      size="medium"
      variant="extended"
      onClick={handleDownloadInstructions}
      disabled={instructions.length === 0}
    >
      {copy}
    </Fab>
  );
}
