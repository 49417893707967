import { getFirstValue } from 'common/object';
import { RoboColumnContent } from 'common/types/robocolumns';

/**
 * For some chromatography run inputs (flow rate, total load volume) we need to know the
 * volume of the RoboColumn. If it is unknown then the inputs should be disabled and show
 * an error.
 */
export function formatRoboColumnVolumeUnknownError(
  parameter: string,
  robocolumns: RoboColumnContent[],
): string {
  const resinVolumes = getResinVolumes(robocolumns);
  if (resinVolumes.size === 0) {
    return `You cannot specify a ${parameter} because there are no robocolumns in this range`;
  } else if (resinVolumes.size > 1) {
    return `You cannot specify a ${parameter} because there is more than one volume of resin in this range`;
  }
  throw new Error('Expected number of robocolumns != 1');
}

/**
 * We convert the volumes to lowercase - ie 100ul not 100uL - because all conversions in
 * chromatography.ts expect lowercase. We won't need to worry about this when we converted
 * it to use core services (T4040).
 */
function getResinVolumes(robocolumns: RoboColumnContent[]) {
  return new Set(robocolumns.map(robocolumn => robocolumn?.Volume?.toLowerCase()));
}

/**
 * Get the volume of the robocolumns. Return undefined if there is more than one volume
 * (e.g. one robocolumn has 200ul and another has 100ul), since we won't know which to
 * use.
 */
export function getRoboColumnVolume(robocolumns: RoboColumnContent[]) {
  const resinVolumes = getResinVolumes(robocolumns);
  return resinVolumes.size === 1 ? getFirstValue(resinVolumes) : undefined;
}
