import React from 'react';

const WIDTH = 56;
const HEIGHT = 48;

type IconWithConnectingLineProps = {
  lineYStart: number;
  lineYEnd: number;
  taskFailed: boolean;
  // If a task and its successor task cannot be ran automatically
  // the icon is just a dot.
  showDotOnly?: boolean;
  dashed?: boolean;
};

const IconWithConnectingLine = React.memo(function IconWithConnectingLine({
  dashed,
  lineYStart,
  lineYEnd,
  taskFailed,
  showDotOnly,
}: IconWithConnectingLineProps) {
  return (
    <svg height={HEIGHT} width={WIDTH}>
      {!showDotOnly && (
        <line
          x1="32"
          y1={lineYStart}
          x2="32"
          y2={lineYEnd}
          stroke="currentColor"
          strokeDasharray={dashed ? '4' : undefined}
        />
      )}
      <circle
        cx="32"
        cy="24"
        r="4.5"
        fill={`${taskFailed ? 'red' : 'currentColor'}`}
        stroke="black"
        strokeWidth="1"
      />
    </svg>
  );
});

type ExecutionTaskIconProps = {
  dashed: boolean;
  shouldThisTaskRunAutomatically: boolean;
  shouldNextTaskRunAutomatically: boolean;
  taskFailed: boolean;
};

/**
 * To show that a series of consecutive tasks will run automatically,
 * we draw a line between them. (T2418)
 */
export const ExecutionTaskIcon = React.memo(function TaskIcon({
  dashed,
  shouldThisTaskRunAutomatically,
  shouldNextTaskRunAutomatically,
  taskFailed,
}: ExecutionTaskIconProps) {
  const shouldBeConnectedAboveAndBelow =
    shouldThisTaskRunAutomatically && shouldNextTaskRunAutomatically;
  const shouldBeConnectedToTaskBelow = shouldNextTaskRunAutomatically;
  const shouldBeConnectedToTaskAbove = shouldThisTaskRunAutomatically;

  if (shouldBeConnectedAboveAndBelow) {
    return (
      <IconWithConnectingLine
        lineYStart={0}
        lineYEnd={HEIGHT}
        taskFailed={taskFailed}
        dashed={dashed}
      />
    );
  } else if (shouldBeConnectedToTaskAbove) {
    return (
      <IconWithConnectingLine
        lineYStart={0}
        lineYEnd={HEIGHT / 2}
        taskFailed={taskFailed}
        dashed={dashed}
      />
    );
  } else if (shouldBeConnectedToTaskBelow) {
    return (
      <IconWithConnectingLine
        lineYStart={HEIGHT / 2}
        lineYEnd={HEIGHT}
        taskFailed={taskFailed}
        dashed={dashed}
      />
    );
  } else {
    return (
      <IconWithConnectingLine
        lineYStart={0}
        lineYEnd={HEIGHT}
        taskFailed={taskFailed}
        showDotOnly
      />
    );
  }
});
