import React, { useCallback, useRef, useState } from 'react';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Popover from '@mui/material/Popover';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { getScreenInfo } from 'client/app/lib/nav/mainNav';
import { IntercomTourIDs } from 'common/lib/intercom';
import Colors from 'common/ui/Colors';
import { ControlledInlineTextEditor } from 'common/ui/components/InlineTextEditor';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  prefix: string;
  name: string;
  onNameChange: (name: string) => void;
  disabled?: boolean;
  popoverPanel?: React.ReactNode;
};

function highlightOnFocus(event: React.FocusEvent<HTMLInputElement>) {
  event.target.select();
}

export default function HeaderEditablename({
  prefix,
  name,
  onNameChange,
  disabled,
  popoverPanel,
}: Props) {
  const classes = useStyles();
  const { currentScreenID } = useNavigation();
  const screenInfo = getScreenInfo(currentScreenID);
  const [isEditing, setIsEditing] = useState(false);

  const Title = useCallback(() => {
    return (
      <Typography className={cx(classes.title, { [classes.disabled]: disabled })} noWrap>
        <span className={classes.prefix}>{prefix} / </span>
        {name}
      </Typography>
    );
  }, [classes.disabled, classes.prefix, classes.title, disabled, name, prefix]);

  const anchorRef = useRef<SVGSVGElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const handleChevronClick = () => {
    logEvent('open-details-panel', currentScreenID as string);
    setIsPopoverOpen(v => !v);
  };
  const handleClosePopover = () => {
    logEvent('close-details-panel', currentScreenID as string);
    setIsPopoverOpen(false);
  };

  const handleNameChange = (name: string) => {
    logEvent('change-workflow-name', currentScreenID as string, name);
    onNameChange(name);
  };

  return (
    <div
      className={classes.nameEditor}
      data-intercom-target={`${IntercomTourIDs.BUILDER}-workflow-name`}
    >
      <ControlledInlineTextEditor
        label={screenInfo.label}
        renderValue={Title}
        value={name}
        onChange={handleNameChange}
        disableUnderline
        textCenter
        onFocus={highlightOnFocus}
        disabled={disabled}
        fullWidth
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
      {!!popoverPanel && (
        <>
          <Popover
            elevation={4}
            open={isPopoverOpen && !isEditing}
            anchorEl={anchorRef.current}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{ paper: classes.panel }}
          >
            {popoverPanel}
          </Popover>
          {!isEditing && (
            <SvgIcon
              onClick={handleChevronClick}
              className={classes.chevron}
              data-intercom-target={`${IntercomTourIDs.BUILDER}-workflow-details-chevron`}
              component="svg"
              ref={anchorRef}
            >
              {isPopoverOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </SvgIcon>
          )}
        </>
      )}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  nameEditor: {
    '& ::selection': {
      backgroundColor: theme.palette.primary.main,
    },
    textDecorationLine: 'none',
    flex: 1,
    overflow: 'hidden',
    textAlign: 'center',
    alignItems: 'center',
    // To properly align the popover panel at the bottom, we want the nameEditor to takes full height of Header.
    alignSelf: 'stretch',
    display: 'flex',
    // a flex child item has default min-width of auto,
    // this lets the name escapes its parent and become way too big.
    '&>*': {
      minWidth: 0,
    },
  },
  chevron: {
    cursor: 'pointer',
  },
  title: {
    width: '100%',
    flex: 1,
    whiteSpace: 'nowrap',
    '&:hover': {
      color: Colors.BLUE_40,
    },
  },
  disabled: {
    '&$title:hover': {
      // do not change color on hover when disabled
      color: 'inherit',
    },
  },
  prefix: {
    color: theme.palette.grey[400],
  },
  withChevron: {
    '&$nameEditor': {
      flex: '0 1 auto',
    },
  },
  panel: { marginTop: theme.spacing(1), borderRadius: theme.spacing(3) },
}));
