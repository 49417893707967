import React, { useEffect, useMemo } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemButton from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { NavLink } from 'react-router-dom';

import AppIcon from 'client/app/icons/AppIcon';
import { STATIC_LIBRARY_NAV_ITEMS } from 'client/app/lib/nav/mainNav';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { InventoryIcon } from 'common/ui/icons/Inventory';

function InventorySubmenu() {
  const { currentScreenID, getScreenPath } = useNavigation();

  const isInventoryRoute = useMemo(
    () => STATIC_LIBRARY_NAV_ITEMS.some(item => item.screenID === currentScreenID),
    [currentScreenID],
  );
  const [open, setOpen] = React.useState(isInventoryRoute);

  useEffect(() => {
    /**
     * This opens the inventory dropdown on initial page load
     */
    if (isInventoryRoute) {
      setOpen(true);
    }
  }, [isInventoryRoute]);

  return (
    <Container>
      <InventoryButton variant="tertiary" onClick={() => setOpen(s => !s)}>
        <StyledInventoryIcon fontSize="medium" />
        <InventoryLabel variant="subtitle2">Inventory</InventoryLabel>
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </InventoryButton>
      <List
        sx={{
          transition: 'all .3s ease',
          height: open ? '200px' : 0,
          opacity: open ? 1 : 0,
        }}
      >
        {STATIC_LIBRARY_NAV_ITEMS.map(item => (
          <ListItem key={item.screenID}>
            <NavLink
              to={getScreenPath(item.screenID)}
              activeStyle={{ color: Colors.PRIMARY_MAIN }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <AppIcon iconId={item.icon} />
                </ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        ))}
      </List>
    </Container>
  );
}

export default React.memo(InventorySubmenu);

const Container = styled('section')(({ theme }) => ({
  marginTop: 'auto',
  paddingTop: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const InventoryButton = styled(Button)(({ theme }) => ({
  display: 'inline-grid',
  gridTemplateColumns: '24px 120px 1fr',
  gap: theme.spacing(3),
}));

const StyledInventoryIcon = styled(InventoryIcon)(() => ({
  alignSelf: 'center',
}));

const InventoryLabel = styled(Typography)(() => ({
  textAlign: 'left',
  textTransform: 'none',
}));

const ListItem = styled(MuiListItem)(() => ({
  padding: 0,
  '& a': {
    display: 'flex',
    flexGrow: 1,
    textDecoration: 'none',
    color: 'inherit',
  },
}));

const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  paddingX: 0,
  paddingY: theme.spacing(3),
}));

const ListItemIcon = styled(MuiListItemIcon)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingX: 0,
  paddingY: theme.spacing(3),
  color: 'inherit',
}));
