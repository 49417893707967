import React, { useMemo } from 'react';

import Bowser from 'bowser';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type WorkspaceVariant = 'dots' | 'lines';

type Props = {
  zoomRatio: number;
  variant?: WorkspaceVariant;
  disabled?: boolean;
};

// Note: This is related to the currentZoomRatio in Workspace which is percentage of
// minZoom to MAX_ZOOM and will always be between 0 and 1.
const ZOOM_RATIO_THRESHOLD = 0.3;

export const WORKSPACE_FILL_COLOR = Colors.GREY_30;
export const WORKSPACE_BACKGROUND_COLOR = Colors.GREY_10;

export default React.memo(function WorkspaceBackground({
  zoomRatio,
  variant = 'lines',
  disabled,
}: Props) {
  const classes = useStyles();

  const workspaceBackground = useMemo(
    () => getWorkspaceBackground(zoomRatio, variant, disabled),
    [disabled, variant, zoomRatio],
  );

  const overlayLayer = useMemo(
    () => getOverlayStyles(zoomRatio, variant),
    [variant, zoomRatio],
  );

  return (
    <div style={workspaceBackground} className={classes.gridBackgroundInner}>
      <div style={overlayLayer} />
    </div>
  );
});

/**
 * When using the grid background in the Workspace, the grid can become distorted on
 * zoom out. In this case, we cover the grid with a layer matching the background color
 * of the grid, and increase the opacity on zoom out such that the grid becomes gradually hidden.
 * This returns the styles to apply to the overlay <div /> depending on the zoomRatio.
 */
function getOverlayStyles(
  zoomRatio: number,
  variant: WorkspaceVariant,
): React.CSSProperties {
  if (zoomRatio > 0.3 || variant === 'lines') {
    return {};
  }
  const minOpacity = 0;
  const maxOpacity = 1;
  // Equation of the line given we want opacity 0 for zoomRatio 0.3, and opacity 1 for zoomRatio 0.1.
  const opacity = Math.min(Math.max(-5 * zoomRatio + 1.5, minOpacity), maxOpacity);
  return {
    opacity,
    height: 'inherit',
    width: 'inherit',
  };
}

/**
 * For the workspace background, we have the option to use the legacy squares background ('lines')
 * or a new 'dots' background. This returns the appropriate backgroundImage.
 */
function getWorkspaceBackground(
  zoomRatio: number,
  variant: WorkspaceVariant,
  disabled?: boolean,
): React.CSSProperties {
  if (disabled) return { backgroundImage: 'none', backgroundColor: Colors.GREY_20 };

  const browser = Bowser.getParser(window.navigator.userAgent);
  if (variant === 'lines') {
    if (zoomRatio < ZOOM_RATIO_THRESHOLD) {
      return {
        backgroundImage: 'url(/app/cdn/assets/workflowbuilder-lines.svg)',
      };
    }
    return {
      backgroundImage: `linear-gradient(to right, ${WORKSPACE_FILL_COLOR} 1px, transparent 1px), \
      linear-gradient(to bottom, ${WORKSPACE_FILL_COLOR} 1px, transparent 1px)`,
      backgroundSize: '24px 24px',
      backgroundRepeat: 'repeat',
    };
  }

  /**
   * We've observed on Safari in MacOS, using a pattern within an SVG results in pixelation of the
   * grid dots - in this case, we use a single SVG with no pattern.
   * We've observed on Chrome and Firefox in MacOS that using a single SVG results in distortion of
   * the dots on zoom out - in this case, we use a SVG with an arbritrarily sized pattern.
   * Ref: T5859
   * */
  if (browser && browser.getBrowserName() === 'Safari') {
    return {
      backgroundImage: 'url(/app/cdn/assets/workflowbuilder-grid-safari.svg)',
    };
  }
  return {
    backgroundImage: 'url(/app/cdn/assets/workflowbuilder-grid.svg)',
  };
}

const useStyles = makeStylesHook({
  /* These are just arbitrarily massive numbers such that under any reasonable
   * scenario the workspace appears infinite.
   */
  gridBackgroundInner: {
    backgroundColor: WORKSPACE_BACKGROUND_COLOR,
    height: `30000px`,
    left: `-15000px`,
    position: `absolute`,
    top: `-15000px`,
    width: `30000px`,
  },
});
