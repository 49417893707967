import React from 'react';

import { Redirect, Route, withRouter } from 'react-router';

import TemplateWorkflowCreateForm from 'client/app/apps/template-workflows/TemplateWorkflowCreateForm';
import TemplateWorkflowCreateFromSimulation from 'client/app/apps/template-workflows/TemplateWorkflowCreateFromSimulation';
import TemplateWorkflowEditor from 'client/app/apps/template-workflows/TemplateWorkflowEditor';
import TemplateWorkflowFormContainer from 'client/app/apps/template-workflows/TemplateWorkflowFormContainer';
import PlatformHeader from 'client/app/components/nav/PlatformHeader';
import {
  experimentsRoutes,
  templateWorkflowRoutes,
  workflowRoutes,
} from 'client/app/lib/nav/actions';
import SimulationNotificationsContextProvider from 'client/app/state/SimulationNotificationsContext';

function TemplateWorkflowsApp() {
  return (
    <>
      <Route
        path={templateWorkflowRoutes.base.pathTemplate}
        exact
        render={() => <Redirect to={experimentsRoutes.base.pathTemplate} />}
      />
      <Route
        path={templateWorkflowRoutes.editTemplate.pathTemplate}
        exact
        render={route => (
          <TemplateWorkflowEditor id={route.match.params.id as TemplateWorkflowId} />
        )}
      />
      <Route
        path={templateWorkflowRoutes.createFromSimulation.pathTemplate}
        exact
        render={route => (
          <TemplateWorkflowCreateFromSimulation
            simulationId={route.match.params.simulationId as SimulationId}
          />
        )}
      />
      <Route
        path={workflowRoutes.editForm.pathTemplate}
        exact
        render={route => (
          <SimulationNotificationsContextProvider>
            <PlatformHeader
              experimentChipPickerProps={{
                workflowId: route.match.params.workflowId as WorkflowId,
              }}
            />
            <TemplateWorkflowFormContainer
              id={route.match.params.workflowId as WorkflowId}
              // We need to drop state and reinit when the id changes
              key={route.match.params.workflowId as WorkflowId}
            />
          </SimulationNotificationsContextProvider>
        )}
      />
      <Route
        path={templateWorkflowRoutes.createForm.pathTemplate}
        exact
        render={route => (
          <TemplateWorkflowCreateForm
            templateWorkflowId={route.match.params.id as TemplateWorkflowId}
          />
        )}
      />
    </>
  );
}

export default withRouter(TemplateWorkflowsApp);
