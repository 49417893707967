import React from 'react';

import { ScreenID } from 'common/ui/navigation';
type ScreenContextValue = Readonly<{ screenId: ScreenID }>;
const ScreenContext = React.createContext<ScreenContextValue>({
  get screenId(): ScreenID {
    throw Error('A ScreenContext Provider is missing.');
  },
});

export default ScreenContext;
