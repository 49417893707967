import React, { useCallback } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import { v4 as uuid } from 'uuid';

import DOEForm from 'client/app/components/DOEFactorForm/components/DOEForm';
import FactorLevelEditor from 'client/app/components/DOEFactorForm/components/FactorLevelEditor';
import FactorNameEditor from 'client/app/components/DOEFactorForm/components/FactorNameEditor';
import Help from 'client/app/components/DOEFactorForm/components/Help';
import { FactorParameterInfo } from 'client/app/components/DOEFactorForm/types';
import useDOEFactorForm, {
  mapStateToFactor,
} from 'client/app/components/DOEFactorForm/useDOEFactorForm';
import { ParameterHeader } from 'client/app/components/Parameters/ElementParameterHeader';
import { FactorItem } from 'common/types/bundle';
import Button from 'common/ui/components/Button';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  className?: string;
  onCancel: () => void;
  onSave: (values: FactorItem) => void;
  isReadonly: boolean;
  parameter?: FactorParameterInfo;
  factor?: FactorItem;
  factorDescriptors: string[];
};

export default function DOEConstantForm({
  className,
  onCancel,
  onSave,
  isReadonly,
  factor,
  parameter,
  factorDescriptors,
}: Props) {
  const { state, updateFactor, updateLevels, showErrors } = useDOEFactorForm(
    parameter,
    factor,
    factorDescriptors,
    'constant',
  );
  const classes = useStyles();
  const errors = state.showErrors ? state.errors : {};
  const showParameterFactorUnit = !!state.unitOptions;

  const level = state.levels[0] ?? { id: uuid(), value: '' };

  const handleSubmit = useCallback(() => {
    if (!isReadonly) {
      if (state.errors) {
        showErrors();
      } else {
        onSave?.(mapStateToFactor(state, parameter));
      }
    }
  }, [isReadonly, onSave, parameter, showErrors, state]);

  return (
    <DOEForm
      className={className}
      onSubmit={handleSubmit}
      classes={{ body: classes.body }}
      actions={
        <>
          <Button
            variant="tertiary"
            size="small"
            type="button"
            onClick={() => onCancel?.()}
          >
            Cancel
          </Button>
          <Button
            variant="secondary"
            color="primary"
            size="small"
            type="submit"
            disabled={isReadonly}
          >
            {!factor ? 'Add' : 'Save'} Constant
          </Button>
        </>
      }
    >
      <div className={classes.field}>
        <ParameterHeader
          displayName="Factor Name"
          isRequired
          help={Help.factorNameHelp}
        />
        <FactorNameEditor
          displayName={state.factor.displayName}
          onChange={name => updateFactor({ displayName: name })}
          errors={errors}
          parameter={parameter}
          isReadonly={isReadonly}
        />
      </div>

      {showParameterFactorUnit && (
        <div className={classes.field}>
          <ParameterHeader displayName="Unit" isRequired />
          <Autocomplete
            autoSelect
            disableClearable
            disabled={isReadonly}
            options={state.unitOptions ?? []}
            value={state.factor.unit}
            onChange={(_, value) => updateFactor({ unit: value })}
            placeholder="Unit"
            renderInput={params => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </div>
      )}

      <div className={classes.field}>
        <ParameterHeader displayName="Value" isRequired help={Help.factorLevelsHelp} />
        <FactorLevelEditor
          value={level.value}
          onChange={value => updateLevels([{ ...level, value: value ?? '' }])}
          parameterInfo={parameter}
          isReadonly={isReadonly}
          error={!!errors?.levelValues?.[level.id]}
          helperText={errors?.levelValues?.[level.id]}
          autoFocus={false}
        />
      </div>
    </DOEForm>
  );
}

const useStyles = makeStylesHook(({ spacing }) => ({
  body: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
