import React, { useCallback, useState } from 'react';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import FileIcon from 'client/app/components/FileBrowser/FileIcon';
import { alphanumericCompare } from 'common/lib/strings';

type FileEntry = Readonly<{
  type: 'file';
  name: string;
  subtitle?: string;
  onDownload: () => void;
}>;

type DirectoryEntry = Readonly<{
  type: 'directory';
  name: string;
  subtitle?: string;
  entries: readonly TreeEntry[];
}>;

export type TreeEntry = FileEntry | DirectoryEntry;

export default function TreeEntryList({ entries }: { entries: readonly TreeEntry[] }) {
  const sortedEntries = [...entries].sort((a, b) => alphanumericCompare(a.name, b.name));
  return (
    <List>
      {sortedEntries.map((entry, i) => {
        if (entry.type === 'file') {
          return (
            <TreeButton
              key={i}
              icon={<FileIcon file={entry} />}
              isDirectory={false}
              label={entry.name}
              subtitle={entry.subtitle}
              onClick={entry.onDownload}
            />
          );
        } else {
          return <Directory key={i} entry={entry} />;
        }
      })}
    </List>
  );
}

function Directory({ entry }: { entry: DirectoryEntry }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleClick = useCallback(() => setIsExpanded(v => !v), [setIsExpanded]);
  return (
    <>
      <TreeButton
        label={entry.name}
        icon={isExpanded ? <ArrowDropDown /> : <ArrowRight />}
        isDirectory
        onClick={handleClick}
      />
      {isExpanded && (
        <Box marginLeft={2}>
          <TreeEntryList entries={entry.entries} />
        </Box>
      )}
    </>
  );
}

type TreeButtonProps = {
  label: string;
  subtitle?: string;
  isDirectory: boolean;
  icon?: React.ReactElement<any>;
  onClick: () => void;
};

function TreeButton({ label, subtitle, icon, isDirectory, onClick }: TreeButtonProps) {
  const handleClick = useCallback(
    e => {
      e.stopPropagation();
      onClick();
    },
    [onClick],
  );
  return (
    <ListItem button onClick={handleClick} title={label}>
      {icon}
      <ListItemText
        inset={!icon}
        primary={isDirectory ? <strong>{label}</strong> : label}
        primaryTypographyProps={{
          noWrap: true,
          display: 'block',
        }}
        secondary={subtitle}
      />
    </ListItem>
  );
}
