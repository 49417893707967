import React from 'react';

import Typography from '@mui/material/Typography';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import { DeckPositionRect } from 'common/ui/components/simulation-details/mix/DeckLayout';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type CarrierRect = DeckPositionRect & { label: string };

type Props = {
  deckPosition: CarrierRect;
  highlight?: boolean;
};

/**
 * A carrier is simply a deck position with a label
 */
export function isCarrier(deckPosition: DeckPositionRect): deckPosition is CarrierRect {
  return !!deckPosition.label;
}

/**
 * Visualises a plate carrier, device, or other hardware.
 */
export default function Carrier({ deckPosition, highlight }: Props) {
  const classes = useStyles();
  return (
    <div
      className={cx(classes.deckPositionOutline, highlight && classes.highlighted)}
      style={deckPosition.absolutePosInDeckPixels}
    >
      <Typography
        className={classes.namedDeckPositionLabel}
        variant="subtitle2"
        color="textPrimary"
      >
        {deckPosition.label}
      </Typography>
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  deckPositionOutline: {
    background: Colors.WHITE,
    border: `2px solid ${Colors.BLUE_5}`,
    pointerEvents: 'none',
    position: 'absolute',
    display: 'flex',
  },
  highlighted: {
    borderColor: Colors.BLUE_50,
  },
  namedDeckPositionLabel: {
    alignSelf: 'flex-start',
    flex: 1,
    textAlign: 'center',
    // Display the name above the deck item.
    transform: 'translate(0, -100%)',
    padding: theme.spacing(4, 1, 4),
    background: Colors.BLUE_5,
    // Expand label to cover the deck outline border
    margin: '0 -2px 0 -2px',
    borderRadius: '8px 8px 0 0',
  },
}));
