import React, { useRef } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import { QUERY_EXPERIMENTS } from 'client/app/apps/experiments/gql/queries';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import { ExperimentCard } from 'client/app/components/cards/ExperimentCard';
import { ContentType, ExperimentsQuery, ExperimentsQueryVariables } from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { PageInfo } from 'common/server/graphql/pagination';
import { circularLoadingContainer } from 'common/ui/commonStyles';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';
import SearchField from 'common/ui/components/SearchField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

export function ExamplesList() {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const [searchQuery, setSearchQuery] = useStateWithURLParams({
    paramName: 'search',
    paramType: 'string',
    defaultValue: '',
  });

  return (
    <ContainerWithIntersectionBar
      scrollableRef={scrollableContainerRef}
      headerRightContent={
        <SearchField
          label="Search"
          defaultValue={searchQuery}
          onQueryChange={setSearchQuery}
        />
      }
      content={
        <ExamplesListItems
          scrollableContainerRef={scrollableContainerRef}
          searchQuery={searchQuery}
        />
      }
      dense
    />
  );
}

type ExamplesListItemsProps = {
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
  searchQuery?: string;
};

function ExamplesListItems({
  scrollableContainerRef,
  searchQuery,
}: ExamplesListItemsProps) {
  const classes = useStyles();

  const variables: ExperimentsQueryVariables = {
    search: searchQuery,
    contentSource: ContentType.EXAMPLE,
  };

  const experimentsQuery = useQuery(QUERY_EXPERIMENTS, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const pageInfo = experimentsQuery.data?.experiments.pageInfo as PageInfo | undefined;
  const hasNextPage = usePagination({
    entity: 'experiments',
    pageInfo,
    fetchMore: experimentsQuery.fetchMore,
    dependencies: [],
    scrollableRef: scrollableContainerRef,
    isInitialLoading: experimentsQuery.loading,
    variables,
  });

  const DataComponent = ({ data }: { data: ExperimentsQuery }) => {
    const experiments = data.experiments?.items || [];

    return (
      <div className={classes.list}>
        {experiments.map(experiment => (
          <ExperimentCard
            key={experiment.id}
            experiment={experiment}
            isLink
            variant="example"
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <RenderQuery
        query={experimentsQuery}
        renderData={DataComponent}
        renderNoData={() => (
          <NoEntitiesMessage
            entityName="examples"
            messageType={MessageType.NO_FILTER_RESULTS}
            searchQuery={searchQuery}
          />
        )}
        emptyCondition={data => data.experiments.items.length === 0}
      />
      {hasNextPage && (
        <div className={classes.circularLoadingContainer}>
          <CircularProgress size={24} />
        </div>
      )}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  ...circularLoadingContainer,
  ...experimentsStyles(theme),
}));
