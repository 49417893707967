import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_ELEMENT_SET_ELEMENT_CONFIGURATIONS } from 'client/app/api/gql/queries';
import { Element } from 'common/types/bundle';
import { ElementConfigurationSpec } from 'common/types/elementConfiguration';

export type ElementWithConfiguration = Pick<Element, 'name' | 'configuration'>;

type ElementConfigurationByName = {
  [elementName: string]: ElementConfigurationSpec | null;
};

export default function useElementConfigs(elementSetID: string | undefined): {
  isLoading: boolean;
  elementConfigs?: ElementConfigurationByName;
} {
  const { loading: isLoading, data } = useQuery(
    QUERY_ELEMENT_SET_ELEMENT_CONFIGURATIONS,
    {
      variables: { id: elementSetID ?? '' },
      skip: !elementSetID,
    },
  );

  const elementConfigs = useMemo(() => {
    const elements = data?.elementSet.elements;
    return elements
      ? Object.fromEntries(
          elements.map(({ name, configuration }) => [name, configuration]),
        )
      : undefined;
  }, [data?.elementSet.elements]);

  return { isLoading, elementConfigs };
}
