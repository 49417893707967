import { useCallback, useRef } from 'react';

import useResizeObserver from 'use-resize-observer';

import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';

/**
 * This hook is used by `ControlOverlay` to measure the visible portion of the canvas,
 * i.e. that part which is not covered up by the toolbar, active panel or instance panel.
 * This area is then stored in the state, so it can be used by the `Workspace` component
 * to properly size the workflow to fit the canvas.
 */
export function useMeasureVisibleCanvasArea() {
  const measurerRef = useRef<HTMLDivElement>(null);
  const dispatch = useWorkflowBuilderDispatch();

  const measureSize = useCallback(() => {
    const measurer = measurerRef.current;

    if (measurer) {
      dispatch({
        type: 'setVisibleCanvasArea',
        // The resize observer callback only provides the new width and height,
        // but we need the top and left offset values as well.
        payload: {
          top: measurer.offsetTop,
          left: measurer.offsetLeft,
          width: measurer.offsetWidth,
          height: measurer.offsetHeight,
        },
      });
    }
  }, [dispatch]);

  useResizeObserver({
    ref: measurerRef,
    onResize: measureSize,
  });

  return measurerRef;
}
