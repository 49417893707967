import React, { useCallback } from 'react';

import { MixPreviewStep } from 'common/types/mixPreview';
import { DeckItemState } from 'common/ui/components/simulation-details/mix/MixState';
import StepSlider, { KeyPoint } from 'common/ui/components/simulation-details/StepSlider';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  currentStep: number;
  /**
   * The estimated time the robot will spend to get to this step
   */
  timeElapsed: number;
  /**
   * Find wells with specific contents
   */
  contentFilter: string;
  /**
   * List of all steps in the preview, used to generate descriptions of each
   * step in the preview
   */
  steps: MixPreviewStep[];
  /**
   * Steps that are considered particularly interesting and will be visually
   * highlighted on the slider.
   */
  keyPoints: readonly KeyPoint[];
  /**
   * Find edges with specific policy
   */
  policyFilter: string;
  deckItems: readonly DeckItemState[];
  isPlaybackEnabled?: boolean;
  onContentFilterChange: (contentFilter: string) => void;
  onPolicyFilterChange: (policyFilter: string) => void;
  onStepChange: (stepNumber: number, timeEstimate?: number) => void;
};

export default function MixControls({
  currentStep,
  timeElapsed,
  contentFilter,
  steps,
  keyPoints,
  policyFilter,
  deckItems,
  onContentFilterChange,
  onPolicyFilterChange,
  onStepChange,
  isPlaybackEnabled,
}: Props) {
  const classes = useStyles();

  const onContentFilterInputChange = useTextFieldChange(onContentFilterChange);

  const onPolicyFilterInputChange = useTextFieldChange(onPolicyFilterChange);

  const handlePointerDown = useCallback(
    (event: React.MouseEvent) =>
      // The parent screen handles background clicks in order to unselect a well.
      // When someone clicks one of the controls, we want the well to stay
      // selected.
      event.stopPropagation(),
    [],
  );

  return (
    <div className={classes.controls} onPointerDown={handlePointerDown}>
      <StepSlider
        currentStep={currentStep}
        steps={steps}
        keyPoints={keyPoints}
        timeElapsed={timeElapsed}
        deckItems={deckItems}
        onStepChange={onStepChange}
        allowPlayback={isPlaybackEnabled}
      />
      <div className={classes.filterBox}>
        {/* TODO: Ideally a single filter box to filter by anything,
            with autocomplete. */}
        <span>Search liquid:</span>
        <input
          className={classes.filterInput}
          name="filter"
          onChange={onContentFilterInputChange}
          type="text"
          value={contentFilter}
        />
      </div>
      <div className={classes.filterBox}>
        <span>Search policy:</span>
        <input
          className={classes.filterInput}
          name="filter"
          onChange={onPolicyFilterInputChange}
          type="text"
          value={policyFilter}
        />
      </div>
    </div>
  );
}

const useStyles = makeStylesHook({
  controls: {
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'row',
  },
  slider: {
    marginRight: '16px',
    width: '300px',
  },
  filterBox: {
    marginLeft: '16px',
    marginTop: '6px',
    display: 'flex',
    alignItems: 'center',
  },
  filterInput: {
    marginLeft: '8px',
    width: '100px',
  },
});
