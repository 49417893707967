import React from 'react';

import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import produce from 'immer';
import merge from 'lodash/merge';

import { useFeatureToggle } from 'common/features/useFeatureToggle';
import Colors from 'common/ui/Colors';
import { anthaTypography } from 'common/ui/Typography';

// Mapping of spacing: 0  1  2  3  4   5   6   7   8   9   10  11  12
const spacingLadder = [0, 2, 4, 8, 12, 16, 24, 32, 40, 48, 56, 64, 96];
export function spacing(factor: number | string): number | string {
  if (typeof factor === 'string') {
    return factor;
  }
  // Factors should be within the spacing ladder options. If not, return a value
  // that is clear that something is wrong.
  if (Math.abs(factor) < spacingLadder.length) {
    return spacingLadder[Math.abs(factor)] * Math.sign(factor);
  }
  throw new Error('Invalid spacing option');
}

const options: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: Colors.PRIMARY_MAIN,
      light: Colors.PRIMARY_LIGHT,
      dark: Colors.PRIMARY_DARK,
      contrastText: Colors.PRIMARY_CONTRAST,
    },
    secondary: {
      main: Colors.SECONDARY_MAIN,
      light: Colors.SECONDARY_LIGHT,
      dark: Colors.SECONDARY_DARK,
      contrastText: Colors.SECONDARY_CONTRAST,
    },
    error: {
      main: Colors.ERROR_MAIN,
      light: Colors.ERROR_LIGHT,
      dark: Colors.ERROR_DARK,
      contrastText: Colors.ERROR_CONTRAST,
    },
    info: {
      main: Colors.INFO_MAIN,
      light: Colors.INFO_LIGHT,
      dark: Colors.INFO_DARK,
      contrastText: Colors.INFO_CONTRAST,
    },
    success: {
      main: Colors.SUCCESS_MAIN,
      light: Colors.SUCCESS_LIGHT,
      dark: Colors.SUCCESS_DARK,
      contrastText: Colors.SUCCESS_CONTRAST,
    },
    warning: {
      main: Colors.WARNING_MAIN,
      light: Colors.WARNING_LIGHT,
      dark: Colors.WARNING_DARK,
      contrastText: Colors.WARNING_CONTRAST,
    },
    text: {
      primary: Colors.TEXT_PRIMARY,
      secondary: Colors.TEXT_SECONDARY,
      disabled: Colors.TEXT_DISABLED,
    },
    action: {
      hover: Colors.ACTION_HOVER,
      selected: Colors.ACTION_SELECTED,
      disabled: Colors.ACTION_DISABLED,
      disabledBackground: Colors.ACTION_DISABLED_BACKGROUND,
    },
    background: {
      default: Colors.BACKGROUND,
    },
    divider: Colors.GREY_30,
  },
  spacing,
  typography: {
    ...anthaTypography,
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'column',
          margin: 6,
          maxWidth: 400,
          width: 'calc(100% - 16px)',
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderTop: `1px ${Colors.GREY_30} solid`,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          flexGrow: 1,
          paddingBottom: 24,
          paddingLeft: 24,
          paddingRight: 24,
          paddingTop: 24,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '8px',
        },
        paperFullScreen: {
          borderRadius: 0,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${Colors.GREY_30}`,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: undefined,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        square: true,
      },
    },
  },
};

export const theme = createTheme(options);

export const LightTheme = function LightTheme(props: { children: React.ReactNode }) {
  const isDisableFocusTrapEnabled = useFeatureToggle('DISABLE_FOCUS_TRAP');

  if (isDisableFocusTrapEnabled) {
    // Enable the `disableEnforceFocus` flag globally. This prevents Menu/Modals to
    // always be in focus, preventing e.g. typing in an Intercom textfield.
    const optionsWithoutTrapFocus = produce(options, draft => {
      if (!draft.components) draft.components = {};
      draft.components.MuiMenu = {
        ...draft.components.MuiMenu,
        defaultProps: {
          ...draft.components.MuiMenu?.defaultProps,
          disableEnforceFocus: true,
        },
      };

      draft.components.MuiModal = {
        ...draft.components.MuiModal,
        defaultProps: {
          ...draft.components.MuiModal?.defaultProps,
          disableEnforceFocus: true,
        },
      };
    });
    const modifiedTheme = createTheme(optionsWithoutTrapFocus);
    return <ThemeProvider theme={modifiedTheme} {...props} />;
  }

  return <ThemeProvider theme={theme} {...props} />;
};

const darkOptions = merge(options, {
  palette: {
    action: {
      active: Colors.GREY_0,
      hover: Colors.DARK_ACTION_HOVER,
      hoverOpacity: 0.24,
      selected: Colors.DARK_ACTION_SELECTED,
      disabled: Colors.DARK_ACTION_DISABLED,
      disabledBackground: Colors.DARK_DISABLED_BACKGROUND,
    },
    background: {
      paper: Colors.GREY_70,
    },
    divider: Colors.GREY_40,
    error: {
      main: Colors.ERROR_LIGHT,
    },
    text: {
      primary: Colors.DARK_TEXT_PRIMARY,
      secondary: Colors.DARK_TEXT_SECONDARY,
      disabled: Colors.DARK_TEXT_DISABLED,
    },
    theme: 'dark',
  },
});
export const darkTheme = createTheme(darkOptions);
export const DarkTheme = (props: { children: React.ReactNode }) => (
  <ThemeProvider theme={darkTheme} {...props} />
);
