import React, { useMemo } from 'react';

import cx from 'classnames';

import { WellLocationOnDeckItem } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import { getHumanReadablePlateLabel } from 'common/ui/components/simulation-details/data-utils/PlatesDataUtils';
import CapView from 'common/ui/components/simulation-details/mix/CapView';
import DeckLayout, {
  DeckPositionRect,
} from 'common/ui/components/simulation-details/mix/DeckLayout';
import LidView from 'common/ui/components/simulation-details/mix/LidView';
import MixPlate, {
  MixPlateProps,
} from 'common/ui/components/simulation-details/mix/MixPlate';
import { DeckItemState } from 'common/ui/components/simulation-details/mix/MixState';
import { PlateSettings } from 'common/ui/components/simulation-details/mix/MixView';
import TipboxView from 'common/ui/components/simulation-details/mix/TipboxView';
import TipwasteView from 'common/ui/components/simulation-details/mix/TipwasteView';
import zIndex from 'common/ui/components/simulation-details/mix/zIndex';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  deckPosition: Omit<DeckPositionRect, 'label'>;
  /**
   * A deck position typically only has one deck item. The only exception is when a plate
   * has a lid; these are modelled as separate plate and lid deck items.
   */
  deckItems?: DeckItemState[];
  deckLayout: DeckLayout;
  plateSettings: PlateSettings;
  currentStep: number;
  isHighlighted?: boolean;
  onClick?: (deckPositionName: string) => void;
};

/**
 * Outlines an area of the deck (or carrier) which a plate can be positioned on. If a
 * deckItem is provided, the item will be rendered within the deck position.
 */
export default function DeckPositionView({
  deckPosition,
  deckItems,
  deckLayout,
  plateSettings,
  currentStep,
  isHighlighted,
  onClick,
}: Props) {
  const classes = useStyles();
  return (
    <div
      className={cx(classes.deckPositionOutline, {
        [classes.highlighted]: isHighlighted,
      })}
      style={deckPosition.absolutePosInDeckPixels}
      onClick={() => onClick?.(deckPosition.deckPositionName)}
    >
      {deckItems?.map(deckItem => (
        <DeckItemContainer
          key={deckItem.id}
          currentStep={currentStep}
          deckItem={deckItem}
          deckLayout={deckLayout}
          plateSettings={plateSettings}
        />
      ))}
    </div>
  );
}

type DeckItemViewProps = {
  deckItem: DeckItemState;
  currentStep: number;
  deckLayout: DeckLayout;
  plateSettings: PlateSettings;
};

function DeckItemContainer({
  deckItem,
  currentStep,
  deckLayout,
  plateSettings,
}: DeckItemViewProps) {
  const classes = useStyles();
  const geometry = deckLayout.getCurrentGeometry(deckItem);
  return (
    <div
      className={classes.deckItem}
      style={{
        width: geometry.absolutePosInDeckPixels.width,
        height: geometry.absolutePosInDeckPixels.height,
        zIndex: deckItem ? zIndex[deckItem.kind] : zIndex.emptyDeckPositionOutline,
      }}
    >
      <DeckItemView
        currentStep={currentStep}
        deckItem={deckItem}
        deckLayout={deckLayout}
        plateSettings={plateSettings}
      />
    </div>
  );
}

function DeckItemView({
  deckItem,
  currentStep,
  deckLayout,
  plateSettings,
}: DeckItemViewProps) {
  switch (deckItem.kind) {
    case 'plate':
      return (
        <MixPlateOnDeck
          deckLayout={deckLayout}
          humanReadablePlateLabel={getHumanReadablePlateLabel(
            deckItem.type,
            plateSettings.plateTypes,
          )}
          hideLabel={plateSettings.hidePlateLabels}
          overlayText={
            plateSettings.overlayText
              ? plateSettings.overlayText[deckItem.name]
              : undefined
          }
          plate={deckItem}
          selectedWells={plateSettings.selectedWells}
          liquidColors={plateSettings.liquidColors}
          isInHotel={deckItem.currentDeckPositionName?.includes('Hotel')}
          onPlatePointerUp={plateSettings.onPlatePointerUp}
          onWellPointerUp={plateSettings.onWellPointerUp}
          onWellMouseEnter={plateSettings.onWellMouseEnter}
          onWellMouseLeave={plateSettings.onWellMouseLeave}
        />
      );
    case 'tipbox':
      return (
        <TipboxView currentStep={currentStep} deckLayout={deckLayout} tipbox={deckItem} />
      );
    case 'tipwaste':
      return <TipwasteView tipwaste={deckItem} />;
    case 'lid':
      return <LidView />;
    case 'cap':
      return <CapView />;
    default:
      console.error('Unsupported deck item', deckItem);
      return null;
  }
}

type MixPlateOnDeckProps = Omit<MixPlateProps, 'highlightedWells'> & {
  selectedWells?: readonly WellLocationOnDeckItem[];
};

function MixPlateOnDeck({ selectedWells, ...props }: MixPlateOnDeckProps) {
  const highlightedWells = useMemo<WellLocationOnDeckItem[] | undefined>(() => {
    const selectedWellsOnPlate = selectedWells?.filter(
      loc => loc.deck_item_id === props.plate.id,
    );
    // The majority of plates will have no changes to their selected wells, so we don't
    // want to trigger a re-render by returning a reference to a new empty array. Instead,
    // return undefined.
    if (!selectedWellsOnPlate || selectedWellsOnPlate.length === 0) {
      return undefined;
    }
    return selectedWellsOnPlate;
  }, [props.plate.id, selectedWells]);
  return <MixPlate {...props} highlightedWells={highlightedWells} />;
}

const useStyles = makeStylesHook({
  deckPositionOutline: {
    backgroundColor: Colors.GREY_5,
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '4px',
    position: 'absolute',
    /**
     * NOTE:
     * "cursor" property here is important as it stops it's inheritance from parent HTML elements.
     * This inheritance has to be stopped as in some cases DeckPositionView has thousands of child HTML elements (SVGs).
     * The inherited change of cursor for thousands of child elements would result into a CPU bottleneck for Chromium.
     * The page would be blocked until the CPU finishes the CSS rendering phase.
     */
    cursor: 'initial',
  },
  highlighted: {
    boxShadow: `0 0 0 3px ${Colors.BLUE_50}`,
  },
  deckItem: {
    position: 'absolute',
    // In layout.json deck items are always positioned top left of the deck position.
    top: 0,
    left: 0,
  },
});
