import React from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import { QUERY_DOE_WORKFLOWS } from 'client/app/api/gql/queries';
import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import DOETemplateCard from 'client/app/components/cards/DOETemplateCard';
import { DOEWorkflowsQueryVariables } from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { PageInfo } from 'common/server/graphql/pagination';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type DOEListProps = {
  scrollableRef: React.RefObject<HTMLDivElement>;
  filterUserId?: string;
  searchQuery?: string;
};

export function DOEList(props: DOEListProps) {
  const { scrollableRef, filterUserId, searchQuery } = props;

  const classes = useStyles();

  const variables: DOEWorkflowsQueryVariables = {
    userId: filterUserId,
    search: searchQuery,
  };

  const {
    loading: isInitialLoading,
    error,
    data,
    refetch,
    fetchMore,
  } = useQuery(QUERY_DOE_WORKFLOWS, { variables });

  const doeWorkflows = data?.workflows?.items || [];

  const pageInfo = data?.workflows.pageInfo as PageInfo | undefined;
  const dependencies = [filterUserId, searchQuery];
  const hasNextPage = usePagination({
    entity: 'workflows',
    pageInfo,
    fetchMore,
    dependencies,
    scrollableRef,
    isInitialLoading,
    variables,
  });

  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }

  if (isInitialLoading) {
    return <CircularProgress />;
  }

  if (doeWorkflows.length === 0) {
    return (
      <NoEntitiesMessage
        entityName="DOEs"
        messageType={MessageType.NO_FILTER_RESULTS}
        searchQuery={searchQuery}
      />
    );
  }

  return (
    <div className={classes.list}>
      {doeWorkflows.map(doe => (
        <DOETemplateCard key={doe.id} doe={doe} />
      ))}
      {hasNextPage && (
        <div className={classes.circularLoadingContainer}>
          <CircularProgress size={24} />
        </div>
      )}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  ...experimentsStyles(theme),
}));
