import React from 'react';

import Typography from '@mui/material/Typography';

import DeviceThumbnail from 'common/ui/components/DeviceThumbnail';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export default function ManualExecution() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <DeviceThumbnail imageUrl="app/cdn/assets/devices/manual.png" />
      <div className={classes.copy}>
        <Typography variant="body1" color="textSecondary">
          This simulation was configured for a manual execution.
        </Typography>
        <Typography variant="body1" color="textSecondary">
          See how to run it step by step by downloading the instructions.
        </Typography>
      </div>
    </div>
  );
}

const useStyles = makeStylesHook(({ spacing }) => ({
  progressWrapper: {
    margin: spacing(3),
  },
  container: {
    display: 'flex',
    gap: spacing(6),
    padding: spacing(0, 3),
  },
  copy: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
  },
}));
