import React from 'react';

import Paper from '@mui/material/Paper';

import { stringToMarkdown } from 'common/lib/markdown';
import { MoveLabwareAction } from 'common/types/mix';
import { MixPreviewStep } from 'common/types/mixPreview';
import { MarkdownPreview } from 'common/ui/components/MarkdownPreview';
import zIndex from 'common/ui/components/simulation-details/mix/zIndex';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = { action: MoveLabwareAction };

export default function MovePlatePrompt({ action }: Props) {
  const classes = useStyles();

  return action.message ? (
    <div className={classes.containerFillsParent}>
      <Paper className={classes.prompt}>
        <MarkdownPreview
          className={classes.message}
          markdown={stringToMarkdown(action.message)}
        />
      </Paper>
    </div>
  ) : null;
}

export function isPlateMovement(action: MixPreviewStep | undefined) {
  switch (action?.kind) {
    case 'move_plate':
      return true;
    default:
      return false;
  }
}

const useStyles = makeStylesHook(({ spacing }) => ({
  containerFillsParent: {
    alignItems: 'flex-start',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    pointerEvents: 'none', // Keep the deck interactive while prompt is shown
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: zIndex.prompt,
  },
  prompt: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '64px',
    maxWidth: '500px',
    padding: spacing(3, 4),
  },
  message: {
    '& > *:first-child': {
      marginTop: '0',
    },
  },
}));
