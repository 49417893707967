import React, { useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';

import { FactorCard } from 'client/app/components/Parameters/FiltrationProtocolDesign/FactorCard';
import FactorForm from 'client/app/components/Parameters/FiltrationProtocolDesign/FactorForm';
import { useFiltrationProtocolDesign } from 'client/app/components/Parameters/FiltrationProtocolDesign/FiltrationProtocolDesignState';
import { Factor } from 'client/app/components/Parameters/FiltrationProtocolDesign/types';
import LabBenchBanner from 'common/assets/LabBenchBanner';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export default function FactorsTab({ isReadonly }: { isReadonly: boolean }) {
  const classes = useStyles();

  const { factors } = useFiltrationProtocolDesign();

  const [selectedFactor, setSelectedFactor] = useState<Factor | undefined>();
  const [showSidePanel, setShowSidePanel] = useState(false);

  const otherFactors = useMemo(
    () => factors.filter(f => f !== selectedFactor).map(f => f.name),
    [factors, selectedFactor],
  );

  const addButton = (
    <Button
      startIcon={<AddIcon />}
      variant="secondary"
      color="primary"
      disabled={showSidePanel || isReadonly}
      onClick={() => {
        setSelectedFactor(undefined);
        setShowSidePanel(true);
      }}
    >
      Add New Factor
    </Button>
  );

  return (
    <div className={classes.container}>
      {factors.length > 0 ? (
        <div className={classes.content}>
          {addButton}
          <div className={classes.factorsList}>
            {factors.map(factor => (
              <FactorCard
                key={factor.clientId}
                factor={factor}
                selected={selectedFactor === factor}
                selectable={!selectedFactor || isReadonly}
                onClick={() => {
                  if (selectedFactor === undefined || isReadonly) {
                    setSelectedFactor(factor);
                    setShowSidePanel(true);
                  }
                }}
                isReadonly={isReadonly}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={classes.empty}>
          <LabBenchBanner className={classes.emptyBanner} />
          <div className={classes.emptyCopy}>
            <Typography variant="body1">
              Factors are variable parameters whose effect your experiment will
              investigate.
            </Typography>
            <Typography variant="body1">
              Begin by defining factors, then apply them to liquid groups for use in the
              stages of your filtration experiment..
            </Typography>
          </div>
          {addButton}
        </div>
      )}
      {showSidePanel && (
        <div className={classes.sidebar}>
          <FactorForm
            factor={selectedFactor}
            otherFactors={otherFactors}
            onClose={() => {
              setShowSidePanel(false);
              setSelectedFactor(undefined);
            }}
            isReadonly={isReadonly}
          />
        </div>
      )}
    </div>
  );
}

export const useStyles = makeStylesHook(({ spacing, palette }) => ({
  container: {
    display: 'grid',
    gridTemplate: `'list sidebar' 1fr / minmax(0, 1fr) auto`,
    flex: 1,
    placeItems: 'stretch',
    borderTop: `1px solid ${palette.grey[300]}`,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
    padding: spacing(6),
    alignItems: 'start',
  },
  factorsList: {
    gridArea: 'list',
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(4),
    alignItems: 'stretch',
    alignSelf: 'stretch',
    flexGrow: 1,
    height: '1px', // this is necessary for overflow to scroll while the real size is defined by flexGrow
    overflowY: 'auto',
  },
  sidebar: {
    gridArea: 'sidebar',
    width: '344px',
    borderLeft: `1px solid ${palette.grey[300]}`,
    display: 'grid',
    gridTemplate: '1fr / 1fr',
    placeItems: 'stretch',
  },
  emptyBanner: {
    width: '342px',
    height: '192px',
  },
  empty: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(6),
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyCopy: {
    display: 'flex',
    gap: spacing(2),
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: spacing(4),
  },
}));
