import React from 'react';

import { ExecutionCard } from 'client/app/components/cards/ExecutionCard';
import SimulationCard from 'client/app/components/cards/SimulationCard';
import { ExperimentBlockFragmentFragment } from 'client/app/gql';

type SimulationBlock = ExperimentBlockFragmentFragment & {
  __typename: 'SimulationBlock';
};

type Props = {
  block: SimulationBlock;
};

export default function SimulationBlock({ block }: Props) {
  const simulation = block.simulation;
  const execution = block.simulation.execution;

  if (execution) {
    // This is a Simulation which has an Execution (has been sent to the lab).
    // Show a card with status of the lab execution.
    return <ExecutionCard execution={{ ...execution, simulation }} isLink />;
  } else {
    // This is just a Simulation which doesn't have an Execution yet
    // (has not been sent to the lab). Show a card with info about the
    // Simulation.
    return <SimulationCard simulation={simulation} isLink disableFavoriteStar />;
  }
}
