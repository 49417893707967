/**
 * Check if an element is a text input (such as `<input type='text' />`).
 *
 * Accepts an `EventTarget` which makes this especially useful for testing targets
 * of browser events. Example usage:
 *
 * ```
 * handleKeyDown = (e: KeyboardEvent) => {
 *   if (targetElementIsTextInput(e.target)) { ... }
 * ```
 */
export function targetElementIsTextInput(targetElement: EventTarget | null): boolean {
  if (!(targetElement instanceof HTMLElement)) {
    // If it's not a HTML element, it's not a text input
    return false;
  }
  const isInput = targetElement.tagName.toLowerCase() === 'input';
  const type = targetElement?.attributes.getNamedItem('type');
  const isTypeText = type?.value === 'text';

  return isInput && isTypeText;
}
