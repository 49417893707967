import React, { useCallback, useMemo, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ListItem from '@mui/material/ListItem';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import AppIcon from 'client/app/icons/AppIcon';
import { getScreenInfo, STATIC_LIBRARY_NAV_ITEMS } from 'client/app/lib/nav/mainNav';
import { TOP_NAV_MENU_ID } from 'client/app/registry';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import MenuWithArrow from 'common/ui/components/MenuWithArrow';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import Tooltip from 'common/ui/components/Tooltip';
import { topNavStyles } from 'common/ui/components/TopNav/topNavStyles';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { InventoryIcon } from 'common/ui/icons/Inventory';
import { NavItem, ScreenID } from 'common/ui/navigation';

const sortByLabel = (a: NavItem, b: NavItem) => a.label.localeCompare(b.label);
const inventoryNavItems = STATIC_LIBRARY_NAV_ITEMS.sort(sortByLabel);

export default React.memo(function InventorySubmenu() {
  const classes = useStyles();
  const { currentScreenID, getScreenPath } = useNavigation();
  const screenInfo = getScreenInfo(currentScreenID);

  const [showInventory, setShowInventoryAnchorEl] = useState<null | HTMLElement>(null);
  const handleShowInventoryMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setShowInventoryAnchorEl(event.currentTarget);
    },
    [],
  );
  const handleCloseInventoryMenu = useCallback(() => {
    setShowInventoryAnchorEl(null);
  }, []);

  const handleNavigateMenuItem = useCallback((screenID: ScreenID) => {
    logEvent('click-on-inventory-item', TOP_NAV_MENU_ID, screenID ?? '');
    setShowInventoryAnchorEl(null);
  }, []);
  const sortedNavItems = useMemo(() => inventoryNavItems, []);

  return (
    <>
      <Tooltip title="Inventory">
        <ListItem
          className={cx(classes.btnWithDropdown, classes.selectedLink)}
          button
          disableRipple
          onClick={handleShowInventoryMenu}
          selected={screenInfo.category === 'Inventory'}
        >
          <InventoryIcon />
          {/* Adjusted viewbox to have arrow closer to icon */}
          <ArrowDropDownIcon viewBox="3 0 24 24" fontSize="small" />
        </ListItem>
      </Tooltip>
      {showInventory && (
        <MenuWithArrow
          anchorEl={showInventory}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={!!showInventory}
          onClose={handleCloseInventoryMenu}
          arrowLeftOffsetPx={20}
        >
          {sortedNavItems.map((navItem, index) => {
            return (
              <NavLink
                to={getScreenPath(navItem.screenID)}
                className={classes.link}
                key={index}
                onClick={() => handleNavigateMenuItem(navItem.screenID)}
              >
                <MenuItemWithIcon
                  icon={<AppIcon iconId={navItem.icon} />}
                  text={navItem.label}
                />
              </NavLink>
            );
          })}
        </MenuWithArrow>
      )}
    </>
  );
});

const useStyles = makeStylesHook(theme => topNavStyles(theme));
