import React, { useMemo } from 'react';

import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';

import Colors from 'common/ui/Colors';

type Props = Omit<TooltipProps, 'arrow'>;

/**
 * Styled Tooltip with a dark background.
 * Use for showing short, descriptive text to the user.
 *
 * If more verbose text is needed, consider using the Popover component.
 */
export default function Tooltip(props: Props) {
  const StyledTooltip = useMemo(
    () =>
      withStyles(theme => ({
        arrow: {
          color: Colors.GREY_60,
        },
        tooltip: {
          color: Colors.DARK_TEXT_PRIMARY,
          backgroundColor: Colors.GREY_60,
          fontSize: theme.typography.caption.fontSize,
        },
      }))(MuiTooltip),
    [],
  );

  return <StyledTooltip arrow {...props} />;
}
