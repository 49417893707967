import React from 'react';

import Paper from '@mui/material/Paper';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import cx from 'classnames';
import merge from 'lodash/merge';

import { topNavStyles } from 'common/ui/components/TopNav/topNavStyles';
import { topNavOverrides } from 'common/ui/components/TopNav/topNavStyles';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { darkTheme } from 'common/ui/theme';

const navTheme = createTheme(merge(darkTheme, topNavOverrides));

export default function StyledTopNavContainer({
  children,
  backgroundOverrideClass,
}: {
  children: React.ReactNode;
  backgroundOverrideClass?: string;
}) {
  const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={navTheme}>
        <Paper className={cx(classes.container, backgroundOverrideClass)}>
          {children}
        </Paper>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const useStyles = makeStylesHook(theme => ({
  ...topNavStyles(theme),
}));
