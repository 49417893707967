import React, { useRef } from 'react';

import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';
import { IncomingList } from 'client/app/apps/experiments/IncomingList';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import SearchField from 'common/ui/components/SearchField';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

export function IncomingScreen() {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const [searchQuery, setSearchQuery] = useStateWithURLParams({
    paramName: 'search',
    paramType: 'string',
    defaultValue: '',
  });
  return (
    <ExperimentsNavigation title="Incoming">
      <ContainerWithIntersectionBar
        scrollableRef={scrollableRef}
        headerRightContent={
          <SearchField
            label="Search"
            defaultValue={searchQuery}
            onQueryChange={setSearchQuery}
          />
        }
        content={<IncomingList searchQuery={searchQuery ?? ''} />}
        dense
      />
    </ExperimentsNavigation>
  );
}
