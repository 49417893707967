import React, { ReactNode } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { formatDateTime, formatDuration } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type CommonHeaderInfo = {
  name: string;
  submitterName: string;
  dateCreated: Date;
};

// This is a union of the UI statuses of a Simulation/Execution in Simulation Details UI
// in antha-com and UI statuses in AnthaHub.
// We can't depend on them directly because this component is in common. Currently,
// these are disjoint sets, but everything will still work if they aren't as long as we
// can use the same styling for the same state on both.
type Status =
  // antha-com Simulation Details legacy UI
  | 'Error'
  | 'Computational'
  | 'Simulation'
  | 'Queued'
  | 'Completed'
  | 'Unknown'
  // antha-com Simulation Details UI
  | 'Simulation failed'
  | 'Simulation succeeded'
  | 'Execution scheduled'
  | 'Execution failed'
  | 'Execution succeeded'
  | 'Execution running'
  | 'Execution incomplete'
  | 'Succeeded'
  | 'Incomplete'
  | 'Scheduled'
  // anthahub-ui
  | 'None'
  | 'New'
  | 'NewBlocked'
  | 'Pending'
  | 'Running'
  | 'CancelledPending'
  | 'Cancelled'
  | 'Succeeded'
  | 'Failed'
  | 'ResumeFailed';

const ERROR_STATUSES: Status[] = [
  'Error',
  'Execution failed',
  'ResumeFailed',
  'Simulation failed',
  'Failed',
  'Incomplete',
  'Execution incomplete',
];

const SUCCESS_STATUSES: Status[] = [
  'Succeeded',
  'Execution succeeded',
  'Simulation succeeded',
];

const INTERMEDIATE_STATUSES: Status[] = [
  'Running',
  'Scheduled',
  'Execution running',
  'Execution scheduled',
];

export type CommonHeaderProps = {
  headerInfo: CommonHeaderInfo;
  status?: Status | null;
  simulationSeriesPart?: number | null;
  estimatedTimeSeconds?: number | null;
  creationAction?: 'Scheduled' | 'Simulated' | 'Created';
  createdAtAction?: 'Simulated' | 'Created';
  /** A component that precedes the title */
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  /** Show an arrow button in the top left corner. */
  onGoBackClick?: () => void;
};

export default function CommonHeader({
  headerInfo,
  status,
  simulationSeriesPart,
  estimatedTimeSeconds,
  creationAction,
  createdAtAction,
  startAdornment,
  onGoBackClick,
  endAdornment,
}: CommonHeaderProps) {
  const classes = useStyles();

  const hasStatus = status && status !== 'Unknown' && status !== 'None';
  const isError = status && ERROR_STATUSES.includes(status);
  const isSuccess = status && SUCCESS_STATUSES.includes(status);
  const isIntermediateStatus = status && INTERMEDIATE_STATUSES.includes(status);

  const showMarginLeftOnTitle = startAdornment || onGoBackClick;

  return (
    <header className={classes.header}>
      <div className={classes.headerTop}>
        {onGoBackClick && (
          <IconButton onClick={onGoBackClick} icon={<ArrowBackIcon />} size="small" />
        )}
        {startAdornment}
        <TypographyWithTooltip
          sx={{
            marginLeft: showMarginLeftOnTitle ? 3 : 0,
          }}
          className={classes.title}
          variant="h2"
          color="textPrimary"
        >
          {headerInfo.name}
        </TypographyWithTooltip>
        {simulationSeriesPart && simulationSeriesPart > 0 && (
          <SimulationSeriesPartIndicator variant="subtitle1" color="textPrimary">
            Part {simulationSeriesPart}
          </SimulationSeriesPartIndicator>
        )}
        <div style={{ marginLeft: 'auto' }}>{endAdornment}</div>
      </div>

      <dl className={classes.metadata}>
        {hasStatus && (
          <>
            <dt>Status:</dt>
            <dd
              className={cx({
                [classes.errorStatus]: isError,
                [classes.successStatus]: isSuccess,
                [classes.intermediateStatus]: isIntermediateStatus,
              })}
            >
              {status}
            </dd>
          </>
        )}

        <dt>{createdAtAction ? createdAtAction + ' at' : 'Date'}: </dt>
        <dd>{formatDateTime(headerInfo.dateCreated)}</dd>

        <dt>{creationAction ?? 'Simulated'} by:</dt>
        <dd>{headerInfo.submitterName}</dd>

        {typeof estimatedTimeSeconds === 'number' && (
          <>
            <dt>Estimated time:</dt>
            <dd>{formatDuration(estimatedTimeSeconds)}</dd>
          </>
        )}
      </dl>
    </header>
  );
}

const SimulationSeriesPartIndicator = styled(Typography)(({ theme }) => ({
  background: Colors.BLUE_5,
  borderRadius: theme.spacing(4),
  fontWeight: 500,
  padding: theme.spacing(2, 4),
  marginRight: theme.spacing(3),
  whiteSpace: 'nowrap',
}));

const useStyles = makeStylesHook(theme => ({
  header: {
    backgroundColor: Colors.SIMULATION_DETAILS_BACKGROUND_GRAY,
    padding: theme.spacing(5, 6, 4, 6),
  },
  headerTop: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    // Make row the same height as the "schedule" button, such that when it's not present
    // the row is still the same height
    height: 40,
  },
  title: {
    marginRight: theme.spacing(3),
  },
  metadata: {
    margin: theme.spacing(0),
    color: theme.palette.text.primary,
    '& > dt': {
      display: 'inline-block',
      fontWeight: 300,
      ...theme.typography.body1,
    },
    '& > dd': {
      display: 'inline-block',
      marginLeft: theme.spacing(2),
      paddingRight: theme.spacing(3),
      ...theme.typography.subtitle2,
      '&:not(:last-child)': {
        borderRight: `1px solid ${Colors.GREY_50}`,
        marginRight: theme.spacing(3),
      },
    },
  },
  errorStatus: {
    color: theme.palette.error.main,
  },
  successStatus: {
    color: theme.palette.success.main,
  },
  intermediateStatus: {
    color: theme.palette.warning.dark,
  },
}));
