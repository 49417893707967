import { GraphQLDevice } from 'client/app/api/deviceFromGraphql';
import { ContentSourceType } from 'common/types/contentSource';
import { getHumanReadableSource } from 'common/ui/components/DeviceCard/DeviceCard';
import { Option } from 'common/ui/components/FilterChip/FilterChipWithCheckbox';

export type DevicesWithModel = {
  modelID: string;
  label: string;
  devices: GraphQLDevice[];
};
export type DevicesByModel = DevicesWithModel[];

export function getDeviceContentSourceFilterOptions(
  contentSourceTypes: Option<ContentSourceType>['label'][],
): Option<ContentSourceType>[] {
  return Object.values(ContentSourceType).map(source => {
    const label = getHumanReadableSource(source).humanReadableName;
    return { value: source, label, selected: contentSourceTypes.includes(label) };
  });
}
