import React, { useCallback } from 'react';

import FavoriteStar from 'client/app/components/FavoriteStar';
import { SimulationStatusIndicatorWithTooltip } from 'client/app/components/SimulationStatusIndicator';
import {
  ArrayElement,
  SimulationsForDialogQuery,
  SimulationSummaryFragmentFragment as SimulationSummaryFragment,
} from 'client/app/gql';
import { simulationRoutes } from 'client/app/lib/nav/actions';
import { EntityCard } from 'common/ui/components/EntityCard';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';

type SimulationForDialog = ArrayElement<
  SimulationsForDialogQuery['simulations']['items']
>;

type SimulationProps<
  SimulationType extends SimulationForDialog | SimulationSummaryFragment,
> = {
  simulation: SimulationType;
  showNewTabButton?: boolean;
  isLink?: boolean;
  onClick?: (simulation: SimulationType) => void;
  disableFavoriteStar?: boolean;
  disabled?: boolean;
};

type SimulationsForDialogItem = ArrayElement<
  SimulationsForDialogQuery['simulations']['items']
>;

export default function SimulationCard<
  SimulationType extends SimulationsForDialogItem | SimulationSummaryFragment,
>({
  simulation,
  showNewTabButton,
  isLink,
  onClick,
  disableFavoriteStar,
  disabled,
}: SimulationProps<SimulationType>) {
  const { getAbsoluteURL } = useNavigation();

  const handleClick = useCallback(() => onClick?.(simulation), [onClick, simulation]);

  const path = simulationRoutes.openInSimulationDetails.getPath({
    simulationId: simulation.id,
  });

  return (
    <EntityCard
      entityName="Simulation"
      icon={<SimulationIcon />}
      link={isLink ? path : undefined}
      star={
        <FavoriteStar
          isFavoritedByCurrentUser={simulation.isFavoritedByCurrentUser}
          favoritedBy={simulation.favoritedBy}
          simulationId={simulation.id}
          size="xsmall"
          disabled={disableFavoriteStar}
        />
      }
      name={simulation.name}
      authorTitle="Simulated by"
      author={simulation.user.displayName}
      dateTitle="Date Simulated"
      date={new Date(simulation.lastUpdatedAt ?? simulation.startedAt)}
      statusIcon={<SimulationStatusIndicatorWithTooltip status={simulation.status} />}
      onClick={handleClick}
      disabled={disabled}
      menu={
        showNewTabButton
          ? [
              {
                label: 'Open in new tab',
                onClick: () =>
                  window.open(
                    getAbsoluteURL(simulationRoutes.openInSimulationDetails, {
                      simulationId: simulation.id,
                    }),
                  ),
              },
            ]
          : undefined
      }
    />
  );
}
