import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';

import { ExecuteSimulationAction } from 'client/app/gql';
import ExecutionClient from 'client/app/lib/ExecutionClient';
import { ScreenRegistry } from 'client/app/registry';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

type Arguments = [fake: false] | [fake: true, action?: ExecuteSimulationAction];

export default function useExecuteSimulation(simulationId: SimulationId) {
  const snackbarManager = useSnackbarManager();
  const apollo = useApolloClient();

  return useCallback(
    async (...[fake, action]: Arguments) => {
      logEvent('execute-simulation', ScreenRegistry.SIMULATION_DETAILS);

      try {
        if (fake) {
          return await new ExecutionClient(apollo).createFakeExecution(
            simulationId,
            action,
          );
        } else {
          return await new ExecutionClient(apollo).executeSimulation(simulationId);
        }
      } catch (e) {
        console.error(e);
        snackbarManager.showError('Something went wrong. Cannot schedule: ' + e.message);
        return null;
      }
    },
    [apollo, snackbarManager, simulationId],
  );
}
