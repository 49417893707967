import React, { useCallback } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { Policy } from 'client/app/lib/policies';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  policy: Policy;
  onOpen?: (id: string) => void;
  onPick?: (id: string) => void;
  onQuickPick?: (id: string) => void;
  selected?: boolean;
};

function PolicyCard(props: Props) {
  const classes = useStyles();
  const { policy, onOpen, onPick, onQuickPick, selected, ...other } = props;
  const { id } = policy;
  const onActionClick = useCallback(() => onOpen?.(id), [id, onOpen]);
  const onCardClick = useCallback(() => onPick?.(id), [id, onPick]);
  const onCardDoubleClick = useCallback(() => onQuickPick?.(id), [id, onQuickPick]);

  let actions = null;
  if (onOpen) {
    actions = (
      <CardActions>
        <Button onClick={onActionClick}>Open</Button>
      </CardActions>
    );
  }

  return (
    <Card
      className={cx(classes.card, { [classes.selected]: selected })}
      data-test="policyCard"
      onClick={onCardClick}
      onDoubleClick={onCardDoubleClick}
      {...other}
    >
      <CardContent>
        <Typography gutterBottom variant="h1" data-test="policyCardTitle">
          {policy.name}
        </Typography>
        <Typography color="textSecondary">{policy.description}</Typography>
      </CardContent>

      {actions}
    </Card>
  );
}

const useStyles = makeStylesHook({
  card: {
    // to avoid slight movement when getting selected
    border: `1px solid transparent`,
  },
  selected: {
    border: `1px solid ${Colors.BLUE_50}`,
  },
});

export default PolicyCard;
