import React from 'react';

import { ContentSourceType } from 'common/types/contentSource';
import { Plate } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';
import { getHumanReadableSource } from 'common/ui/components/DeviceCard/DeviceCard';

export default function getPlateInfo(plate: PlateType | Plate) {
  if (!plate) {
    return undefined;
  }

  const { manufacturer, type } = plate;

  const { wellShape, defaultResidualVolume, catalogNumber, contentSource } =
    'wellShape' in plate ? plate : ({} as Partial<PlateType>);

  const originValue = getHumanReadableSource(
    contentSource as unknown as ContentSourceType,
  ).humanReadableName;

  return (
    <ul style={{ padding: '0 16px' }}>
      {wellShape && <li>Well volume: {wellShape.volumeOverrideUl}ul</li>}
      {defaultResidualVolume && <li>Residual volume: {defaultResidualVolume}ul</li>}
      {manufacturer && <li>Manufacturer: {manufacturer}</li>}
      {catalogNumber && <li>Number: {catalogNumber}</li>}
      {type && <li>Type: {type}</li>}
      {contentSource && <li>Owner: {originValue}</li>}
    </ul>
  );
}
