import React, { useCallback, useMemo, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import cx from 'classnames';

import { getDeviceModelLabel, GraphQLDevice } from 'client/app/api/deviceFromGraphql';
import { DevicesByModel } from 'client/app/components/DeviceLibrary/types';
import SearchField from 'common/ui/components/SearchField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

export function useDeviceFilterBar() {
  const [filterQuery, setFilterQuery] = useState('');
  const [filterModel, setFilterModel] = useState('');
  const [filterSource, setFilterSource] = useState('');

  const onChangeFilterQuery = useCallback((query: string) => {
    setFilterQuery(query);
  }, []);

  const onChangeFilterModel = useCallback((model: string) => {
    setFilterModel(model);
  }, []);

  const onChangeFilterSource = useCallback((source: string[]) => {
    const stringSource = source.toString();
    setFilterSource(stringSource);
  }, []);

  return {
    filterQuery,
    filterModel,
    filterSource,
    onChangeFilterQuery,
    onChangeFilterModel,
    onChangeFilterSource,
  };
}

export type UseDeviceFilterBarType = ReturnType<typeof useDeviceFilterBar>;

export type Props = {
  devices: readonly GraphQLDevice[];
  filterModel: string;
  onChangeFilterQuery: (query: string | string[]) => void;
  onChangeFilterModel: (query: string) => void;
  className?: string;
};

/**
 * The Device filters.
 * Filter by name, model and connectivity.
 *
 * To use with `useDeviceFilterBar` to get the filters and filters' setters.
 */
export default function DeviceFilterBar(props: Props) {
  const classes = useStyles();
  const { onChangeFilterQuery, filterModel, onChangeFilterModel, devices, className } =
    props;
  const onChangeFilterModelHandler = useTextFieldChange(onChangeFilterModel);

  const deviceModels = useMemo(() => {
    const deviceModels: DevicesByModel = [];
    for (const device of devices) {
      let model = deviceModels.find(dm => dm.modelID === device.model.id);
      if (!model) {
        model = {
          modelID: device.model.id,
          label: getDeviceModelLabel(device),
          devices: [],
        };
        deviceModels.push(model);
      }
      model.devices.push(device);
    }
    for (const models of deviceModels) {
      models.devices.sort((d1, d2) => d1.name.localeCompare(d2.name));
    }
    deviceModels.sort((dm1, dm2) => dm1.label.localeCompare(dm2.label));
    return deviceModels;
  }, [devices]);

  return (
    <div className={cx(classes.filters, className)}>
      <SearchField
        placeholder="Filter devices"
        className={classes.searchField}
        onQueryChange={onChangeFilterQuery}
      />
      <TextField
        variant="standard"
        label="Filter by model"
        onChange={onChangeFilterModelHandler}
        select
        value={filterModel}
        InputLabelProps={{ shrink: !!filterModel }}
        SelectProps={{
          MenuProps: {
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          },
        }}
      >
        <MenuItem value="">Clear filter</MenuItem>
        {deviceModels.map(model => (
          <MenuItem key={model.modelID} value={model.modelID}>
            {model.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}

const useStyles = makeStylesHook({
  filters: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
    '&>*': {
      flex: '1 0 200px',
      marginLeft: '16px',
      maxWidth: '230px',
    },
  },
  searchField: {
    // avoid having big gap on the right of the field
    textAlign: 'end',
  },
});
