import memo from 'lodash/memoize';
import stringHash from 'string-hash';

import { formatVolumeObj } from 'common/lib/format';
import { getFirstValue } from 'common/object';
import {
  DeckItem,
  Liquid,
  Plate,
  WellContents,
  WellLocationOnDeckItem,
} from 'common/types/mix';
import { MixPreviewStep } from 'common/types/mixPreview';
import SIMULATION_DETAILS_COLORS, { PERCEPTUAL_PALETTE } from 'common/ui/Colors';

function addBidirectionalLink(
  graph: WeightedGraph,
  liqA: string,
  liqB: string,
  weight: number,
) {
  if (liqA === liqB) {
    return;
  }
  if (!graph.links.has(liqA)) {
    graph.links.set(liqA, new Map());
  }
  if (!graph.links.has(liqB)) {
    graph.links.set(liqB, new Map());
  }
  const liqAConnections = graph.links.get(liqA)!;
  const liqBConnections = graph.links.get(liqB)!;
  liqAConnections.set(liqB, (liqAConnections.get(liqB) || 0) + weight);
  liqBConnections.set(liqA, (liqBConnections.get(liqA) || 0) + weight);
}

/**
 * Create an ID for a given well on the deck.
 */
const hashWellLocationOnDeckItem = memo(
  ({ deck_item_id, row, col }: WellLocationOnDeckItem): string =>
    [deck_item_id, col, row].join(':'),
);

type WeightedGraph = {
  /**
   * List of nodes (set of liquid names)
   */
  nodes: Set<string>;
  /**
   * Weighted links between nodes, as a map: node => { node => weight }
   */
  links: Map<string, Map<string, number>>;
};

/**
 * An abstract colour represented by a number between 1..10000 (the `label`).
 * The label can later be mapped to real colors.
 *
 * The mapping works by first trying to deterministically find a colour using
 * the first liquid in the set of nodes. If that colour has already been used,
 * then it grabs the next unused colour. If there all colours have been used,
 * then stick with the deterministic colour. See `mapColorLabelsToPalette(...)`.
 */
type ColorLabel = {
  label: number;
  /**
   * A higher number indicates that this label should be more likely to receive
   * a unique color
   */
  uniqueness: number;
  /**
   * List of liquid names attributed to this label.
   */
  nodes: Set<string>;
};

/**
 * Check if given well contents describes a liquid. For compatibility with older
 * simulations, this includes well contents without a `kind` specified.
 */
function isLiquid(wellContents?: WellContents): wellContents is Liquid {
  return !!wellContents && (wellContents.kind === 'liquid_summary' || !wellContents.kind);
}

/**
 * Given a set of deck items and steps which describe an entire simulation,
 * create a weighted graph where:
 * - each node represents a unique liquid
 * - each link between two nodes indicates liquids that:
 *     1) are in neighbouring wells during some state
 *     2) occupy the same well in successive states
 *     3) are a source and target in a transfer, such that if the target now
 *        contains a new mixed liquid, this should be a different color to the
 *        source liquid
 *     4) are both present in the first step, such that each liquid initially
 *        present should be uniquely coloured. Users use the first step to set
 *        up labware, so uniquely colouring liquids is important.
 *
 * Each link is weighted, such that a heavily weighted link indicates two
 * liquids that should be prioritised when allocating unique liquids.
 */
function createLiquidGraph(
  deckItems: DeckItem[],
  steps: MixPreviewStep[],
): WeightedGraph {
  const nodes = new Set<string>();
  const links = new Map<string, Map<string, number>>();
  const graph: WeightedGraph = { nodes, links };

  // The only deck items that hold liquids are plates
  const plates = deckItems.filter(
    (deckItem): deckItem is Plate => deckItem.kind === 'plate',
  );

  // Map of liquid in each well, which updates as we iterate through each step
  const wellStates = new Map<string, string>();

  // Create a node for each liquid initially on the deck and links for each
  // adjacent pair of liquids
  for (const plate of plates) {
    // If every well has the same liquid, this will be the liquid name.
    // Otherwise false.
    let liquidInEveryWell: string | undefined | false;

    for (let col = 0; col < plate.columns; col++) {
      for (let row = 0; row < plate.rows; row++) {
        const wellContents = plate.contents?.[col]?.[row];
        if (!isLiquid(wellContents)) {
          // No liquid in this well
          continue;
        }

        const liquidName = summaryOfWell(wellContents);
        nodes.add(liquidName);

        // Update the well
        wellStates.set(
          hashWellLocationOnDeckItem({ deck_item_id: plate.id, row, col }),
          liquidName,
        );

        if (liquidInEveryWell === undefined) {
          liquidInEveryWell = liquidName;
        } else if (liquidName !== liquidInEveryWell) {
          liquidInEveryWell = false;
        }

        // Link adjacent pairs
        const right = plate.contents?.[col + 1]?.[row];
        if (isLiquid(right)) {
          addBidirectionalLink(graph, liquidName, summaryOfWell(right), 10);
        }
        const below = plate.contents?.[col]?.[row + 1];
        if (isLiquid(below)) {
          addBidirectionalLink(graph, liquidName, summaryOfWell(below), 10);
        }

        // Link each liquid present in the first step to prioritise uniqueness
        // when coloring the graph. This constraint is less important than
        // adjacent liquids having unique colors, so we set a low weight for
        // these links.
        for (const node of nodes) {
          addBidirectionalLink(graph, liquidName, node, 1);
        }
      }
    }
  }

  // Iterate through the steps in the preview, each time adding nodes for new
  // liquids, links between new adjacent pairs, and links between liquids
  // occupying the same well in consecutive steps.
  for (const step of steps) {
    // Keep a list of wells that changed, which we'll come back to once all the
    // changes have been applied to figure out new adjacent pairs.
    const changedWells: WellLocationOnDeckItem[] = [];

    // Only look at steps where liquids are moved
    if (step.kind !== 'parallel_transfer' && step.kind !== 'parallel_dispense') {
      continue;
    }

    // Iterate through each channel of the head and look at both the wells
    for (const channel of Object.values(step.channels)) {
      const source = channel.from;
      const target = channel.liquidDestination;
      const liqAtSource = wellStates.get(hashWellLocationOnDeckItem(source.loc));
      const currLiqAtTarget = wellStates.get(hashWellLocationOnDeckItem(target.loc));
      if (!isLiquid(target.new_content)) {
        continue;
      }
      const newLiqAtTarget = summaryOfWell(target.new_content);
      nodes.add(newLiqAtTarget);
      if (liqAtSource) {
        // Where a liquid is transferred from a source to a target, if the
        // target now contains a new mixed liquid, this should be a distinct
        // color to the source.
        addBidirectionalLink(graph, liqAtSource, newLiqAtTarget, 10);
      }
      if (currLiqAtTarget) {
        // Where a liquid immediately replaces another liquid in a well, these
        // liquids should be distinct colors.
        addBidirectionalLink(graph, currLiqAtTarget, newLiqAtTarget, 10);
      }
      changedWells.push(target.loc);
      wellStates.set(
        hashWellLocationOnDeckItem(target.loc),
        summaryOfWell(target.new_content),
      );
    }

    // Now that all changes have been applied, iterate through changed wells,
    // adding each new liquid to the node set and linking new adjacent liquids.
    for (const well of changedWells) {
      const neighbours = getAllLiquidsOnWellPlate(well, wellStates);
      const liqName = wellStates.get(hashWellLocationOnDeckItem(well))!;

      for (const neighbour of neighbours) {
        addBidirectionalLink(graph, liqName, neighbour, 1);
      }
    }
  }
  return graph;
}

/**
 * Returns all liquids present on the same plate as the given well
 * at every simulation step.
 */
function getAllLiquidsOnWellPlate(
  well: WellLocationOnDeckItem,
  wellStates: Map<string, string>,
) {
  const liquidsOnCurrentPlate = new Set<string>();

  for (const [wellLocationHash, liquidName] of wellStates.entries()) {
    const isCurrentPlate = wellLocationHash.includes(well.deck_item_id);
    if (isCurrentPlate) {
      liquidsOnCurrentPlate.add(liquidName);
    }
  }

  return liquidsOnCurrentPlate;
}

/**
 * Given a set of nodes (liquids) and links (see comment for createLiquidGraph),
 * assign a color to each node such that no two adjacent vertices are of the
 * same color.
 *
 * This uses the greedy graph coloring approach described here
 * https://iq.opengenus.org/graph-colouring-greedy-algorithm/
 *
 * @returns an array of color labels, which each define an arbitrary color,
 * nodes of that color, and uniqueness score.
 */
function colorGraph(graph: WeightedGraph): ColorLabel[] {
  const colorLabelsByLabel = new Map<number, ColorLabel>();
  const colorLabelsByNode = new Map<string, ColorLabel>();

  for (const nodeA of graph.nodes) {
    // Iterate through adjacent nodes and flag their colors as unavailable
    const adjacentColors = new Set<number>();
    // Compute the uniqueness for this color by summing the weight of all
    // connected links.
    let uniqueness = 0;
    for (const [nodeB, weight] of graph.links.get(nodeA) || []) {
      uniqueness += weight;
      const colorLabel = colorLabelsByNode.get(nodeB);
      if (colorLabel) {
        adjacentColors.add(colorLabel.label);
        // Also increase the uniqueness of the connected color
        colorLabel.uniqueness += weight;
      }
    }

    // Count up until finding a color that isn't adjacent
    let label = 0;
    while (adjacentColors.has(label)) {
      label++;
      // If we're into the thousands, then the graph is too interconnected to
      // effectively use graph colouring (there's simply not enough perceptually
      // different colours). There's nothing to gain by continuing to create new
      // colours and it's costly to keep checking the adjacentColors set, so
      // assign a random color instead.
      //
      // Why 10k? With all liquids on the first step interconnected, it's
      // feasible we could end up with some nodes with a lot of connections. For
      // example, let say the limit was 100, and in our simulation there are 150
      // different liquids on the first step (plausible if a plate has been
      // cherry picked from a complex simulation). We'll reach the 100 cap and
      // colours will start being randomised, leading to less a deterministic
      // preview.
      if (label > 10000) {
        label = Math.ceil(Math.random() * 10000);
        break;
      }
    }

    let weightedColor = colorLabelsByLabel.get(label);
    if (!weightedColor) {
      weightedColor = { label, uniqueness: 0, nodes: new Set() };
      colorLabelsByLabel.set(label, weightedColor);
    }

    weightedColor.nodes.add(nodeA);
    weightedColor.uniqueness += uniqueness;

    colorLabelsByNode.set(nodeA, weightedColor);
  }

  return [...colorLabelsByLabel.values()];
}

const PERCEPTUAL_PALETTE_COLORS = Object.values(PERCEPTUAL_PALETTE);

/**
 * Given a map of liquid => colorLabel resulting from colorGraph(...), map color
 * labels to the LIQUID_COLORS palette such that, if possible:
 *  1) colors are deterministic, for example "Liquid A" appears as green in
 *     every simulation
 *  2) all available colors are used, such that color reuse is minimised
 *
 * @returns a map liquidName => color
 */
function mapColorLabelsToPalette(weightedColors: ColorLabel[]): Map<string, string> {
  const colorToLiquids = new Map<string, Set<string>>(
    [SIMULATION_DETAILS_COLORS.WATER, ...PERCEPTUAL_PALETTE_COLORS].map(color => [
      color,
      new Set(),
    ]),
  );
  const colorLabelToColor = new Map<number, string>();
  const unusedColors = new Set(PERCEPTUAL_PALETTE_COLORS);

  // Sort colors so those with highest uniqueness are first. These will be
  // allocated colors first.
  weightedColors.sort((a, b) => b.uniqueness - a.uniqueness);

  for (const { nodes: liquidNames, label: colorLabel } of weightedColors) {
    // Get a liquid in the set of nodes and use this to deterministically
    // compute a color for all liquids in the set.
    const firstLiquid = getFirstValue(liquidNames) || '';
    let labelColor = PERCEPTUAL_PALETTE_COLORS[colorIndex(stringHash(firstLiquid))];
    // If this color has already been used and there are unused colors, then
    // use a new color. i.e., avoid reusing colours if possible.
    if (!unusedColors.has(labelColor) && unusedColors.size > 0) {
      labelColor = getFirstValue(unusedColors)!;
    }
    for (const liquidName of liquidNames) {
      let liqColor = labelColor;
      if (liquidName === 'water') {
        liqColor = SIMULATION_DETAILS_COLORS.WATER;
      }
      unusedColors.delete(liqColor);
      colorLabelToColor.set(colorLabel, liqColor);
      colorToLiquids.get(liqColor)!.add(liquidName);
    }
  }

  // The graph coloring algorithm tries to allocate as few colors as possible,
  // but this means we can have a few colors reused many times. To reduce this,
  // for each unused color, take half of the liquids from the most used color.
  for (const color of unusedColors) {
    const mostUsedColor = [...colorToLiquids.keys()].reduce((max, color) =>
      colorToLiquids.get(max)!.size > colorToLiquids.get(color)!.size ? max : color,
    );
    const liquidsToSplit = [...colorToLiquids.get(mostUsedColor)!];
    // Use floor so we don't move a liquid if it's the only one for that color
    const nLiqsToMove = Math.floor(liquidsToSplit.length / 2);
    colorToLiquids.set(mostUsedColor, new Set(liquidsToSplit.slice(0, nLiqsToMove)));
    colorToLiquids.set(color, new Set(liquidsToSplit.slice(nLiqsToMove)));
  }
  // Finally, return a map liquid => color which will be used by LiquidColors.
  return new Map(
    [...colorToLiquids].flatMap(([color, liquids]) =>
      [...liquids].map(liquid => [liquid, color]),
    ),
  );
}

/**
 * Determines the color of each well in a plate.
 *
 * We color each well based on what liquid components it contains. This makes
 * it easier for people to make sense of a plate visually.
 */
export default class LiquidColors {
  /**
   * Creates a new instance.
   *
   * @param colorAdjustments
   * This is a map from liquid summary (e.g. "pure glucose") to its color hex code.
   * The map only contains liquids that were adjusted, not all liquids.
   * @param preventReuse
   * Set to true to try to prioritize unused colours. Colours will only be
   * reused once all colours in the palette have been used once.
   */
  private constructor(
    private colorAdjustments: Map<string, string> = new Map(),
    private preventReuse = false,
  ) {}

  /**
   * Use graph coloring algorithm to avoid color collisions where liquids are
   * neighbours or they successively ended up in the same well.
   */
  static createUsingColorGraph(
    deckItems: DeckItem[],
    steps: MixPreviewStep[] = [],
  ): LiquidColors {
    const graph = createLiquidGraph(deckItems, steps);
    const colorLabels = colorGraph(graph);
    const colors = mapColorLabelsToPalette(colorLabels);
    return new LiquidColors(colors);
  }

  /**
   * Returns a simple implementation of colors, ignoring potential collisions between
   * colors of neighbouring wells.
   */
  static createDefault() {
    return new LiquidColors();
  }

  /**
   * Prioritize unused colours. Colours will only be reused once all colours in
   * the palette have been used once.
   */
  static createAvoidingAllColorCollisions(): LiquidColors {
    // Initialise with a map to store assignment of liquidNames to colors (used
    // to check if a color has already been used)
    return new LiquidColors(new Map(), true);
  }

  /**
   * The main function that returns the color hex code.
   */
  getColorForWell(well: WellContents): string {
    return well.color || this.getColorFromLiquid(summaryOfWell(well));
  }

  /**
   * Returns a color hex code based on the string hash of the `liquidName`.
   *
   * If `this.preventReuse` is true and the color has already been used for a
   * different liquidName, an unused colour will returned instead (unless there
   * are no remaining unused colours).
   *
   * Water is always blue.
   */
  private getColorFromLiquid(liquidName: string): string {
    if (liquidName === 'water') {
      // The liquid is just water and nothing else. Always show this as blue.
      return SIMULATION_DETAILS_COLORS.WATER;
    }

    const adjustedColor = this.colorAdjustments.get(liquidName);
    if (adjustedColor) {
      return adjustedColor;
    }

    // Deterministically compute a color for this liquid name.
    let liquidColor = PERCEPTUAL_PALETTE_COLORS[colorIndex(stringHash(liquidName))];

    // When preventReuse is true, we use the colorAdjustments map (which maps
    // `liquidName => color`) to track which colors have already been used.
    // First try to use the color determined above. If this is already used,
    // then return the next available unused color.
    if (this.preventReuse) {
      const usedColors = new Set(this.colorAdjustments.values());
      // Check if this colour was already allocated to another liquid name
      if (usedColors.has(liquidColor)) {
        // Get the first available unused color. If all colors have been used,
        // then we'll just return the color we started with.
        const unusedColor = PERCEPTUAL_PALETTE_COLORS.find(
          color => !usedColors.has(color),
        );
        if (unusedColor) {
          liquidColor = unusedColor;
        }
      }
      // Allocate this color to the liquid name. This ensures the color is now
      // reserved for this liquid name and also memoizes the function so we
      // don't need to compute this again.
      this.colorAdjustments.set(liquidName, liquidColor);
    }

    return liquidColor;
  }

  /**
   * Get a color hex code based on arbitrary string. Used to fill wells with different
   * colors based on their content. Liquid name is user defined, e.g. "Liquid C"
   * and could be used as the input here.
   *
   * By default this will be case insensitive. Set lowercase false to make it case sensitive.
   */
  getColorFromLiquidString(liquidString: string, lowercase = true): string {
    return this.getColorFromLiquid(lowercase ? liquidString.toLowerCase() : liquidString);
  }
}

function colorIndex(hash: number): number {
  return hash % PERCEPTUAL_PALETTE_COLORS.length;
}

/** Match e.g. "10.317", "1e-5", "1e+5" at the start of a string */
const NUMBER_START_REGEX = /^[\d.e+-]+/;

/**
 * "1.3e-5g/L pure glucose" becomes "g/L pure glucose"
 * This is a heuristic that works well in practice. If we simply use the whole
 * name "1g/L pure glucose", the concentrations vary too much across a plate
 * and the plate becomes a random palette of colors.
 * A cleaner solution would be if the backend provided the liquid name
 * in a structured form. For now we have to work with "1g/L pure glucose".
 */
export function stripNumberFromStart(liquidName: string): string {
  // e.g. "g/L pure glucose"
  const normalisedLiquidName = liquidName.replace(NUMBER_START_REGEX, '');
  if (normalisedLiquidName === '') {
    // The liquidName was a number! E.g. a liquid called '317'.
    // In this case we have to keep the name. This is because a liquid '317'
    // should have a different colour than liquid '44'.
    return liquidName;
  }
  return normalisedLiquidName;
}

/** Returns a concise representation of the well contents, so that we can hash that. */
export function summaryOfWell(liquid: WellContents): string {
  const soluteNamesWithConcentrations = (liquid.solutes ?? []).map(
    solute =>
      `${stripNumberFromStart(solute.name)}:${formatVolumeObj(solute.concentration)}`,
  );
  soluteNamesWithConcentrations.sort();

  const tags = (liquid.tags ?? []).map(
    tag =>
      `${tag.label || 'n/a'}:${
        tag.value_float?.toFixed(2) ?? (tag.value_string || 'n/a')
      }`,
  );
  tags.sort();

  return [liquid.name, ...soluteNamesWithConcentrations, ...tags].join('|');
}
