import React, { PropsWithChildren } from 'react';

import PlatformHeader from 'client/app/components/nav/PlatformHeader';
import LinearProgress from 'common/ui/components/LinearProgress';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export default function Screen({ children }: PropsWithChildren<{}>) {
  const classes = useStyles();
  return <div className={classes.screen}>{children}</div>;
}

export function LoadingScreenWithTopMainNav({ children }: PropsWithChildren<{}>) {
  return (
    <Screen>
      <PlatformHeader />
      <LinearProgress />
      {children}
    </Screen>
  );
}

const useStyles = makeStylesHook(theme => ({
  screen: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: theme.palette.background.default,
  },
}));
