import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import PageviewOutlinedIcon from '@mui/icons-material/PageviewOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';

import { MUTATION_RESTORE_SHARED_METHOD } from 'client/app/api/gql/mutations';
import { ArrayElement, IncomingMethodsQuery } from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { formatDateTime, formatDateTimeWithTimezone } from 'common/lib/format';
import { EntityCardContent } from 'common/ui/components/EntityCard';
import EntityCardExpandable, {
  EntityCardExpandableHeaderRow,
  EntityCardExpandableRow,
} from 'common/ui/components/EntityCardExpandable';
import IconButton from 'common/ui/components/IconButton';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { EditIcon as EditWorkflowIcon } from 'common/ui/icons/Edit';
import { GraphDataIcon } from 'common/ui/icons/GraphDataIcon';

type SharedMethod = ArrayElement<IncomingMethodsQuery['sharedMethods']>;
type RestoredMethod = ArrayElement<SharedMethod['restoredMethods']>;

type IncomingMethodCardProps = {
  sharedMethod: SharedMethod;
};

export default function IncomingMethodCard({ sharedMethod }: IncomingMethodCardProps) {
  const classes = useStyles();
  const userProfile = useUserProfile();
  const isSupport = userProfile?.isSupport || false;

  const history = useHistory();
  const snackbar = useSnackbarManager();
  const [restoreSharedMethodMutation, { loading }] = useMutation(
    MUTATION_RESTORE_SHARED_METHOD,
    {
      onError: e => {
        console.error(e.message);
        snackbar.showError('Sorry. We are having issues with copying the shared Method.');
      },
    },
  );

  const createSharedMethodCopy = useCallback(async () => {
    try {
      const result = await restoreSharedMethodMutation({
        variables: {
          sharedMethodId: sharedMethod.id,
        },
      });
      if (result) {
        const newMethod = result.data?.restoreMethod;
        if (!newMethod) {
          throw new Error(`Could not restore shared Method: ${sharedMethod.id}`);
        }
        history.push(newMethod.href.substring(2));
      }
    } catch (e) {
      console.error(e);
      snackbar.showError('Failed to import Method');
    }
  }, [history, restoreSharedMethodMutation, sharedMethod.id, snackbar]);

  const methodDescription = getMethodDescription(sharedMethod);

  const entityCardAction = loading ? (
    <CircularProgress size={18} />
  ) : (
    <Tooltip title="Open request">
      <IconButton
        className={classes.iconButton}
        size="small"
        icon={<ArchiveOutlinedIcon fontSize="small" />}
        onClick={createSharedMethodCopy}
      />
    </Tooltip>
  );

  return (
    <EntityCardExpandable
      disabledExpand={sharedMethod.restoredMethods.length === 0}
      onClick={
        sharedMethod.restoredMethods.length === 0 ? createSharedMethodCopy : undefined
      }
      entityCardContent={
        <EntityCardContent
          icon={<GraphDataIcon fontSize="small" />}
          entityName="Analyse responses"
          name={`${sharedMethod.sourceMethodApplicationDisplayName} ${methodDescription}`}
          authorTitle="Sent by"
          author={sharedMethod.sourceUser.displayName}
          dateTitle="Time received"
          date={new Date(sharedMethod.createdAt)}
          additionalColumnTitle={isSupport ? 'Organisation' : undefined}
          additionalColumnValue={isSupport ? sharedMethod.sourceOrg.name : undefined}
          hideStatusColumn
        />
      }
      entityCardAction={entityCardAction}
      entityCardCollapseContent={
        <>
          <EntityCardExpandableHeaderRow
            summaryTitle="Data analysis"
            authorTitle="Created at" // NOTE - This title is not used as the author in this case
            additionalColumnTitle="Author"
            dateTitle="Last modified"
          />
          {sharedMethod.restoredMethods.map(restoredMethod => {
            return (
              <RestoredMethodRow
                key={restoredMethod.id}
                sourceMethodDisplayName={sharedMethod.sourceMethodApplicationDisplayName}
                restoredMethod={restoredMethod}
              />
            );
          })}
        </>
      }
    />
  );
}

type RestoredMethodRowProps = {
  sourceMethodDisplayName: string;
  restoredMethod: RestoredMethod;
};

function RestoredMethodRow(props: RestoredMethodRowProps) {
  const classes = useStyles();

  const handleClick = () => {
    window.location.href = props.restoredMethod.href;
  };

  return (
    <EntityCardExpandableRow
      onClick={handleClick}
      title="Analyse responses name"
      name={
        <Typography variant="body2" noWrap>
          {`${props.sourceMethodDisplayName} ${props.restoredMethod.description}`}
        </Typography>
      }
      additionalColumnValue={props.restoredMethod.createdBy.displayName}
      author={formatDateTime(new Date(props.restoredMethod.createdAt))}
      date={formatDateTime(new Date(props.restoredMethod.lastModifiedAt))}
      action={
        <Tooltip title={props.restoredMethod.isOwned ? 'Edit' : 'View'}>
          <IconButton
            className={classes.iconButton}
            size="xsmall"
            icon={
              props.restoredMethod.isOwned ? (
                <EditWorkflowIcon />
              ) : (
                <PageviewOutlinedIcon />
              )
            }
            onClick={handleClick}
          />
        </Tooltip>
      }
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  iconButton: {
    color: theme.palette.text.primary,
  },
}));

function getMethodDescription(sharedMethod: SharedMethod) {
  if (!sharedMethod.sourceMethodDescription) {
    const timestamp = new Date(sharedMethod.sourceMethodLastModifiedAt);
    try {
      return formatDateTimeWithTimezone(
        timestamp,
        sharedMethod.sourceUserTimezone || 'UTC',
      );
    } catch {
      // fallback to "UTC" if an invalid timezone was stored
      return formatDateTimeWithTimezone(timestamp, 'UTC');
    }
  }
  return sharedMethod.sourceMethodDescription;
}
