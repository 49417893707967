import React, { useCallback, useState } from 'react';

import { PlateMap, usePlatesByType } from 'client/app/api/PlateTypesApi';
import PlateLibrary from 'client/app/apps/plate-library/PlateLibrary';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import { PlateSelection } from 'client/app/components/Parameters/PlateType/PlateSelectionEditor';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import DialogActions from 'common/ui/components/Dialog/DialogActions';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  plates: PlateMap;
  isInitialLoading?: boolean;
  previousPlate?: string;
} & DialogProps<PlateSelection>;

export function PlateLibraryDialog(props: Props) {
  const classes = useStyles();
  const { onClose, previousPlate, isOpen, isInitialLoading } = props;
  const [selectedPlate, setSelectedPlate] = useState(previousPlate);
  const handleConfirm = useCallback(() => {
    onClose(selectedPlate);
  }, [onClose, selectedPlate]);

  const handleClose = useCallback(() => {
    onClose(CANCEL_CHOICE);
  }, [onClose]);
  const onPlateSelect = useCallback(
    (plateId: string) => {
      // if we are selecting the same as already selected, unselect
      // if plate is different select that plate
      setSelectedPlate(sp => (sp === plateId ? undefined : plateId));
    },
    [setSelectedPlate],
  );

  return (
    <ComplexActionDialog
      title="Plate Types"
      onClose={handleClose}
      isOpen={isOpen}
      showCloseButton
      paperClassName={classes.paper}
      content={
        <PlateLibrary
          dialog
          plates={props.plates}
          onSelect={onPlateSelect}
          onDoubleSelect={onClose}
          selectedPlate={selectedPlate}
          isInitialLoading={!!isInitialLoading}
        />
      }
      dialogActions={
        !isInitialLoading ? (
          <DialogActions>
            <Button onClick={handleConfirm} color="primary" variant="tertiary">
              {'Select' + (selectedPlate ? '' : ' none')}
            </Button>
          </DialogActions>
        ) : null
      }
    />
  );
}

type PropsWithoutPlates = Omit<Props, 'plates' | 'isInitialLoading'>;
/**
 * A Container that will take care of fetching the plates for the dialog instead of having to pass them through the props.
 */
export default function PlateLibraryDialogWithPlates(props: PropsWithoutPlates) {
  const [platesByType, isInitialLoading] = usePlatesByType();
  return (
    <PlateLibraryDialog
      {...props}
      plates={platesByType}
      isInitialLoading={isInitialLoading}
    />
  );
}

const useStyles = makeStylesHook({
  paper: {
    backgroundColor: Colors.GREY_5,
  },
});
