import React, { useMemo } from 'react';

import { groupBy } from 'common/lib/data';
import { DeckItem, WellLocationOnDeckItem } from 'common/types/mix';
import { MixPreviewStep } from 'common/types/mixPreview';
import { PlateNameData } from 'common/ui/components/simulation-details/data-utils/PlatesDataUtils';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import Carrier, { isCarrier } from 'common/ui/components/simulation-details/mix/Carrier';
import { getDeckItemStates } from 'common/ui/components/simulation-details/mix/deckContents';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import DeckPositionView from 'common/ui/components/simulation-details/mix/DeckPositionView';
import EdgesSvgOverlay from 'common/ui/components/simulation-details/mix/EdgesSvgOverlay';
import { Edge, MixState } from 'common/ui/components/simulation-details/mix/MixState';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type PlateSettings = {
  overlayText?: { [plate: string]: string };
  plateTypes: readonly PlateNameData[];
  selectedWells?: readonly WellLocationOnDeckItem[];
  liquidColors: LiquidColors;
  hidePlateLabels?: boolean;
  onPlatePointerUp?: (plate: string) => void;
  onWellPointerUp?: (loc: WellLocationOnDeckItem, e: React.PointerEvent) => void;
  onWellMouseEnter?: (loc: WellLocationOnDeckItem, e: React.MouseEvent) => void;
  onWellMouseLeave?: (loc: WellLocationOnDeckItem, e: React.MouseEvent) => void;
};

type Props = {
  deckLayout: DeckLayout;
  filteredState: MixState;
  steps?: MixPreviewStep[];
  highlightedPlate?: string;
  highlightedDeckPositionName?: string;
  selectedEdge?: Edge;
  plateSettings: Omit<PlateSettings, 'liquidColors'> & {
    /**
     * By default, MixView will use graph coloring to determine liquid colors.
     */
    liquidColorsOverride?: LiquidColors;
  };
  onEdgeClick?: (edge: Edge) => void;
  onDeckItemPointerUp?: (deckPositionName: string, item?: DeckItem) => void;
};

// The main piece of UI on the MixScreen.
// Shows plates, wells, and arrows representing moving liquids.
export default React.memo(function MixView({
  deckLayout,
  filteredState,
  highlightedPlate,
  highlightedDeckPositionName,
  steps,
  plateSettings,
  onDeckItemPointerUp,
  selectedEdge,
  onEdgeClick,
}: Props) {
  const classes = useStyles();

  // Deck positions used in the current step may be highlighted, such as tip cleaner
  const highlightedDeckPositions = new Set(
    highlightedDeckPositionName
      ? [highlightedDeckPositionName]
      : filteredState.highlightedDeckPositionNames,
  );

  const liquidColors = useMemo(
    () =>
      plateSettings.liquidColorsOverride ||
      LiquidColors.createUsingColorGraph(getDeckItemStates(deckLayout.deck), steps),
    [deckLayout.deck, plateSettings.liquidColorsOverride, steps],
  );

  const deckItemsByLocation = groupBy(
    filteredState.deck.items,
    'currentDeckPositionName',
  );

  const deckPositions = useDeckPositions(deckLayout);

  return (
    <div className={classes.mixView} style={deckLayout.deckBounds}>
      <EdgesSvgOverlay
        deckLayout={deckLayout}
        deckItems={filteredState.deck.items}
        edges={filteredState.edges}
        selectedEdge={selectedEdge}
        onEdgeClick={onEdgeClick}
      />
      {deckPositions.map(deckPosition => {
        if (isCarrier(deckPosition)) {
          return (
            <Carrier
              key={deckPosition.deckPositionName}
              deckPosition={deckPosition}
              highlight={highlightedDeckPositions.has(deckPosition.deckPositionName)}
            />
          );
        }
        const deckItems = deckItemsByLocation[deckPosition.deckPositionName];
        const isHighlighted =
          highlightedDeckPositions.has(deckPosition.deckPositionName) ||
          deckItems?.some(item => item.name === highlightedPlate);
        return (
          <DeckPositionView
            key={deckPosition.deckPositionName}
            deckPosition={deckPosition}
            deckItems={deckItems}
            deckLayout={deckLayout}
            currentStep={filteredState.currentStep}
            isHighlighted={isHighlighted}
            onClick={onDeckItemPointerUp}
            plateSettings={{ ...plateSettings, liquidColors }}
          />
        );
      })}
    </div>
  );
});

function useDeckPositions(deckLayout: DeckLayout) {
  return useMemo(() => deckLayout.getAllDeckPositions(), [deckLayout]);
}

const useStyles = makeStylesHook({
  mixView: {
    position: 'relative',
  },
});
