import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import useExecuteSimulation from 'client/app/apps/simulation-details/useExecuteSimulation';
import { ExecuteSimulationAction } from 'client/app/gql';
import NoEntitiesBanner from 'common/assets/NoEntitiesBanner';
import Button, { Props as ButtonProps } from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  simulationId: SimulationId;
};

export default function NoExecution({ simulationId }: Props) {
  const styles = useStyles();
  const executeSimulation = useExecuteSimulation(simulationId);
  const [actionPerformed, setActionPerformed] = useState<ExecuteSimulationAction>();

  const actionHandler = async (action: ExecuteSimulationAction) => {
    setActionPerformed(action);
    await executeSimulation(true, action);
    setActionPerformed(undefined);
  };

  return (
    <div className={styles.noResultsContainer}>
      <Typography variant="h3">
        Did you run this in the lab? Upload your data or simply mark as completed to find
        this under your executions.
      </Typography>
      <div className={styles.actions}>
        <LoadingButton
          variant="secondary"
          onClick={() => actionHandler(ExecuteSimulationAction.MARK_AS_COMPLETED)}
          disabled={!!actionPerformed}
          loading={actionPerformed === ExecuteSimulationAction.MARK_AS_COMPLETED}
        >
          Mark as completed
        </LoadingButton>
        <LoadingButton
          variant="secondary"
          onClick={() => actionHandler(ExecuteSimulationAction.UPLOAD_DATA)}
          disabled={!!actionPerformed}
          loading={actionPerformed === ExecuteSimulationAction.UPLOAD_DATA}
        >
          Upload data
        </LoadingButton>
      </div>
      <NoEntitiesBanner className={styles.emptyImage} />
    </div>
  );
}

const useStyles = makeStylesHook(({ spacing }) => ({
  noResultsContainer: {
    flex: 2,
    minWidth: '450px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: spacing(9),
  },
  actions: {
    paddingTop: spacing(6),
    display: 'flex',
    gap: spacing(4),
  },
  emptyImage: {
    paddingTop: spacing(10),
    maxWidth: '416px',
  },
}));

// TODO: Add this loading indicator capability into the common button.
const LoadingButton = ({
  loading,
  children,
  ...props
}: ButtonProps & { loading: boolean }) => {
  const styles = useButtonStyles();

  return (
    <Button {...props} style={loading ? { color: 'transparent' } : undefined}>
      {children}
      {loading && (
        <div className={styles.loadingIndicator}>
          <CircularProgress size={20} color="inherit" />
        </div>
      )}
    </Button>
  );
};

const useButtonStyles = makeStylesHook(theme => ({
  loadingIndicator: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'inline-flex',
    color: theme.palette.grey['500'],
  },
}));
