import React, { FunctionComponent, ReactElement } from 'react';

import { OperationVariables, QueryResult } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import { circularLoadingContainer } from 'common/ui/commonStyles';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type RenderQueryProps<
  TData,
  TVariables = OperationVariables,
  DataProps extends { data: TData } = { data: TData },
> = {
  /** The apollo client query you wish to render */
  query: QueryResult<TData, TVariables>;
  /** A functional component to display when the query returns no data */
  renderNoData: FunctionComponent;
  /** A functional component to display when the query returns data */
  renderData: FunctionComponent<DataProps>;
  /** Allows you to specify additional ways that data can be deemed as not present from a query response e.g an empty list */
  emptyCondition?: (data: TData) => boolean;
};

/** This component provides a standardised way to handle rendering an Apollo Client query */
export function RenderQuery<TData, TVariables = OperationVariables>({
  query,
  renderNoData,
  renderData,
  emptyCondition,
}: RenderQueryProps<TData, TVariables>): ReactElement {
  const classes = useStyles();

  if (query.loading) {
    return (
      <div className={classes.circularLoadingContainer}>
        <CircularProgress />
      </div>
    );
  }

  if (query.error) {
    return <GraphQLErrorPanel error={query.error} onRetry={query.refetch} />;
  }

  if (!query.data || emptyCondition?.(query.data)) {
    return <>{renderNoData({})}</>;
  }

  return <>{renderData({ data: query.data })}</>;
}

const useStyles = makeStylesHook({
  ...circularLoadingContainer,
});
