import React, { useRef } from 'react';

import Chip from '@mui/material/Chip';

import { DraftList } from 'client/app/apps/experiments/DraftWorkflows/DraftWorkflowsList';
import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';
import {
  getSelectedSupportedWorkflowTypeFiltersOptions,
  getSupportedWorkflowTypeFiltersOptions,
} from 'client/app/apps/experiments/supportedFilterWorkflowTypes';
import { WorkflowSourceEnum } from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { ScreenRegistry } from 'client/app/registry';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import FilterChipWithCheckbox, {
  Option,
} from 'common/ui/components/FilterChip/FilterChipWithCheckbox';
import SearchField from 'common/ui/components/SearchField';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

export function DraftWorkflowsScreen() {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const currentUser = useUserProfile();
  const [searchQuery, setSearchQuery] = useStateWithURLParams({
    paramName: 'search',
    paramType: 'string',
    defaultValue: '',
  });

  const [filterWorkflowTypes, setFilterWorkflowTypes] = useStateWithURLParams({
    paramName: 'type',
    paramType: 'string[]',
    defaultValue: [],
  });
  const workflowTypesFilterList = getSupportedWorkflowTypeFiltersOptions()
    .filter(workflowType => filterWorkflowTypes?.includes(workflowType.label))
    .map(workflowType => workflowType.value);
  const onFilterByWorkflowTypes = (newValue: Option<WorkflowSourceEnum>[]) => {
    logEvent(
      'filter-workflows-by-source-type',
      ScreenRegistry.EXPERIMENTS,
      workflowTypesFilterList.join(','),
    );
    const newTypes = newValue
      .filter(option => option.selected)
      .map(option => option.label);
    setFilterWorkflowTypes(newTypes);
  };

  if (!currentUser) {
    return null;
  }
  return (
    <ExperimentsNavigation title="Draft Workflows">
      <ContainerWithIntersectionBar
        scrollableRef={scrollableRef}
        headerLeftContent={
          <>
            <Chip color="primary" label={currentUser.displayName} disabled />
            <FilterChipWithCheckbox
              heading="Filter by Workflow Type"
              defaultChipLabel="Workflow Type"
              filterValue={getSelectedSupportedWorkflowTypeFiltersOptions(
                filterWorkflowTypes ?? [],
              )}
              onFilter={onFilterByWorkflowTypes}
            />
          </>
        }
        headerRightContent={<SearchField label="Search" onQueryChange={setSearchQuery} />}
        content={
          <DraftList
            scrollableRef={scrollableRef}
            searchQuery={searchQuery ?? ''}
            filterWorkflowTypesList={workflowTypesFilterList}
            currentUserId={currentUser.id}
          />
        }
        dense
      />
    </ExperimentsNavigation>
  );
}
