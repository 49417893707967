import React, { useCallback, useEffect, useMemo, useState } from 'react';

import TextField from '@mui/material/TextField';
import cx from 'classnames';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { useEnterKeyPress } from 'common/ui/hooks/useEnterKeyPress';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type InlineTextEditorProps = {
  label?: string;
  value: string;
  renderValue?: (value: string) => React.ReactNode;
  getError?: (value: string) => string | null;
  onChange: (value: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disableUnderline?: boolean;
  textCenter?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
};
type ControlledProps = InlineTextEditorProps & {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
};

/**
 * A controlled InlineTextEditor that needs to be told whether it is being edited or not.
 * Use it when the parent component controls or needs to know the editing state of the InlineEditor.
 */
export function ControlledInlineTextEditor({
  renderValue,
  value,
  getError,
  onChange,
  onFocus,
  disableUnderline,
  textCenter,
  disabled,
  fullWidth,
  isEditing,
  setIsEditing,
}: ControlledProps) {
  const classes = useStyles();

  const onStartEditing = useCallback(
    () => !disabled && setIsEditing(true),
    [disabled, setIsEditing],
  );

  const [localValue, setLocalValue] = useState(value);

  const onTextFieldChange = useTextFieldChange(setLocalValue);

  // Without that effect we would have stale `localValue` when the parent change the `value` prop.
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const errorText = useMemo(
    () => (getError ? getError(localValue) : null),
    [getError, localValue],
  );

  const onBlur = useCallback(() => {
    if (errorText || !localValue) {
      return;
    }
    setIsEditing(false);
    onChange(localValue);
  }, [errorText, localValue, onChange, setIsEditing]);

  const onKeyPress = useEnterKeyPress(onBlur);

  return isEditing ? (
    <TextField
      autoFocus
      error={!!errorText}
      helperText={errorText}
      value={localValue}
      onChange={onTextFieldChange}
      onKeyPress={onKeyPress}
      onBlur={onBlur}
      onFocus={onFocus}
      fullWidth={fullWidth}
      variant="standard"
      InputProps={{
        disableUnderline,
        classes: { input: cx({ [classes.textCenter]: textCenter }) },
      }}
    />
  ) : (
    <div
      className={cx(
        classes.root,
        !disableUnderline && classes.underline,
        disabled && classes.disabled,
      )}
      onClick={onStartEditing}
    >
      {renderValue ? renderValue(localValue) : localValue}
    </div>
  );
}

/**
 * Simple component rendering read-only text that becomes editable when clicked on.
 * Triggers onChange only on blur or when enter is pressed.
 */
export function InlineTextEditor(props: InlineTextEditorProps) {
  const [isEditing, setIsEditing] = useState(false);
  return (
    <ControlledInlineTextEditor
      {...props}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
    />
  );
}

const useStyles = makeStylesHook({
  root: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  underline: {
    '&$root:hover': {
      textDecoration: 'underline',
    },
  },
  disabled: {
    '&$root:hover': {
      cursor: 'default',
    },
  },
  textCenter: {
    textAlign: 'left',
  },
});
