import { useCallback, useState } from 'react';

// A hook to update both state and localStorage
export default function useStateWithLocalStorage<T>(
  key: string,
  initialValue: T,
): [T, (value: T) => void] {
  // Try to retrieve value already stored or use value provided by the caller
  const [dataToStore, setDataToStore] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setData = useCallback(
    (value: T) => {
      setDataToStore(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    },
    [key],
  );

  return [dataToStore, setData];
}
