import React from 'react';

import FormHelperText from '@mui/material/FormHelperText';

import { FactorParameterInfo } from 'client/app/components/DOEFactorForm/types';
import { DOEFactorFormErrors } from 'client/app/components/DOEFactorForm/useDOEFactorForm';
import AutocompleteEditor from 'client/app/components/Parameters/AutocompleteEditor';
import { AutocompleteAdditionalProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import TextField from 'common/ui/filaments/TextField';

const FactorNameEditor = ({
  parameter,
  displayName,
  onChange,
  isReadonly,
  errors,
}: {
  parameter?: FactorParameterInfo;
  displayName: string;
  onChange: (name: string | undefined) => void;
  isReadonly: boolean;
  errors?: DOEFactorFormErrors;
}) => {
  if (parameter?.keyEditor?.type === EditorType.AUTOCOMPLETE) {
    const additionalProps = parameter?.keyEditor
      .additionalProps as AutocompleteAdditionalProps;

    return (
      <>
        <AutocompleteEditor
          anthaType={parameter.keyType ?? ''}
          value={displayName}
          onChange={onChange}
          isDisabled={isReadonly}
          disableClearable
          placeholder="Factor name"
          useDynamicOptions={additionalProps.useDynamicOptions}
          staticOptions={additionalProps.staticOptions}
          anthaTypeOverride={additionalProps.anthaTypeOverride}
          additionalSourceTypes={additionalProps.additionalSourceTypes}
          canAcceptCustomValues={additionalProps.canAcceptCustomValues}
          provideDefaultKey={false}
        />
        {errors?.name && <FormHelperText error>{errors?.name}</FormHelperText>}
      </>
    );
  } else {
    return (
      <TextField
        value={displayName}
        onChange={e => onChange(e.target.value)}
        placeholder="Factor name"
        error={!!errors?.name}
        helperText={errors?.name}
        disabled={isReadonly}
      />
    );
  }
};

export default FactorNameEditor;
