import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import {
  createTheme,
  StyledEngineProvider,
  ThemeOptions,
  ThemeProvider,
} from '@mui/material/styles';
import merge from 'lodash/merge';

import { ReleaseQualityIndicator } from 'client/app/apps/workflow-builder/lib/ReleaseQualityIndicator';
import {
  useElementNamePopover,
  useElementSetElement,
} from 'client/app/apps/workflow-builder/panels/element-instance-panel/hooks';
import InstanceNameField from 'client/app/apps/workflow-builder/panels/element-instance-panel/InstanceNameField';
import Panel from 'client/app/apps/workflow-builder/panels/Panel';
import ElementParameterGroupList from 'client/app/components/Parameters/ElementParameterGroupList';
import UIErrorBox from 'client/app/components/UIErrorBox';
import { getElementDisplayName } from 'client/app/lib/workflow/elementConfigUtils';
import { ConnectionMaskDict } from 'client/app/lib/workflow/types';
import { ScreenRegistry } from 'client/app/registry';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { ElementInstance, ParameterValue, ParameterValueDict } from 'common/types/bundle';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { theme } from 'common/ui/theme';

export type Props = {
  isReadonly?: boolean;
  DOETemplateMode?: boolean;
  workflowId: string;
  elementInstance: ElementInstance;
};

const themeOptionOverrides: ThemeOptions = {
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: '1em',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.875em',
        },
      },
    },
  },
};

const overridenTheme = createTheme(merge(theme, themeOptionOverrides));

function ElementInstancePanel(props: Props) {
  const classes = useStyles();
  const theme = useMemo(() => overridenTheme, []);

  const scrollableContentRef = useRef<HTMLDivElement | null>(null);

  // Users will swap between elements of various types constantly. In many
  // cases, they'll be fine tuning related parameters across several elements.
  // When the user is working this way, having to select an element and then
  // scroll to the which ever parameter they need over and over again is
  // super annoying. Also, because they often switch between element types,
  // they may have to scroll up and down and up and down as they move around.
  // To spare them this drudgery, we keep a dictionary of scroll positions.
  // This object maps element instance IDs to parameters pane scroll
  // positions.
  //
  // You may not that there no effort made here to cleanup this object when
  // an element instance gets deleted. Meh. There's no chance of a conflict
  // with IDs since they're monotonically increasing. Also, if a user
  // deletes and then uses undo to get an element back, this intentional
  // memory leak means they'll keep their scroll position. Also, given that
  // a workflow rarely has more than a dozen or so elements (and in the extreme
  // case might have somethlike like 50), there's no real chance of us ruining
  // the performance by not tidying up when instances are deleted.
  const scrollPositions = useRef<{ [id: string]: number }>({});

  const elementInstance = props.elementInstance;
  const connections = useWorkflowBuilderSelector(state => state.InstancesConnections);
  const parameters = useWorkflowBuilderSelector(state => state.parameters);
  const workflowConfig = useWorkflowBuilderSelector(state => state.config);
  const workflowName = useWorkflowBuilderSelector(state => state.workflowName);
  const erroredObjectIds = useWorkflowBuilderSelector(state => state.erroredObjectIds);
  const centerElementId = useWorkflowBuilderSelector(state => state.centerElementId);
  const switchElementParameterValidation = useWorkflowBuilderSelector(
    state => state.switchElementParameterValidation,
  );
  const isElementInstanceErrored = erroredObjectIds.includes(elementInstance.Id);
  const shouldShowValidation =
    (switchElementParameterValidation || elementInstance.Id === centerElementId) &&
    elementInstance.Meta.showValidation;

  const dispatch = useWorkflowBuilderDispatch();

  const element = useElementSetElement(elementInstance);

  const onParamChange = useCallback(
    (parameterName: string, newValue: ParameterValue, instanceName?: string) => {
      if (!elementInstance && !instanceName) {
        return;
      }
      logEvent('edit-element-parameter', ScreenRegistry.WORKFLOW, parameterName);

      // If the errored element instance is the one whose parameter is changing, remove
      // the error state from it by emptying the error set.
      if (isElementInstanceErrored) {
        dispatch({ type: 'setErroredObjects', payload: [] });
      }

      const elementInstanceName = instanceName ?? elementInstance.name;

      dispatch({
        type: 'updateParameter',
        payload: {
          instanceName: elementInstanceName,
          parameterName,
          value: newValue,
        },
      });

      const parameter = element?.inputs.find(input => input.name === parameterName);

      if (parameter?.configuration?.editor.type === EditorType.SPREADSHEET) {
        dispatch({
          type: 'clearStagedParameters',
        });
      }
    },
    [dispatch, element?.inputs, elementInstance, isElementInstanceErrored],
  );

  const onPendingParamChange = useCallback(
    (parameterName: string, newValue: ParameterValue) => {
      if (!elementInstance) {
        return;
      }

      dispatch({
        type: 'updatePendingParameter',
        payload: {
          instanceName: elementInstance.name,
          parameterName,
          value: newValue,
        },
      });
    },
    [dispatch, elementInstance],
  );

  const deselectAll = useCallback(() => {
    dispatch({
      type: 'deselectAll',
    });
  }, [dispatch]);

  const connectionMaskDict = useMemo(() => {
    if (!elementInstance) {
      return {};
    }

    const dict: ConnectionMaskDict = {};
    connections.forEach(conn => {
      if (conn.Target.ElementInstance === elementInstance.name) {
        dict[
          conn.Target.ParameterName
        ] = `${conn.Source.ElementInstance}.${conn.Source.ParameterName}`;
      }
    });

    return dict;
  }, [connections, elementInstance]);

  useEffect(() => {
    if (!elementInstance.Id || !scrollableContentRef.current) {
      return;
    }

    if (scrollPositions.current[elementInstance.Id] === undefined) {
      scrollPositions.current[elementInstance.Id] = 0;
    }

    scrollableContentRef.current.scrollTop = scrollPositions.current[elementInstance.Id];
  }, [elementInstance.Id, scrollPositions]);

  let valueDict: ParameterValueDict = {};

  if (elementInstance && parameters[elementInstance.name]) {
    valueDict = parameters[elementInstance.name];
  }

  const isElementConfigDebugModeEnabled = useFeatureToggle(
    'ELEMENT_CONFIGURATION_DEBUG_MODE',
  );

  const elementDisplayName = element
    ? getElementDisplayName(element, isElementConfigDebugModeEnabled)
    : '';

  const { elementNamePopover, ...elementNamePopoverEvents } =
    useElementNamePopover(elementDisplayName);

  if (!element) {
    const errorMessage =
      `Could not find element ${elementInstance.TypeName} ` +
      `for element instance ${elementInstance.name}.`;
    // Make sure we log this serious unexpected error too. This makes debugging easier.
    console.error(errorMessage);
    // TODO: The UIErrorBox does not appear on screen. Possibly the CSS is wrong.
    return <UIErrorBox>{errorMessage}</UIErrorBox>;
  }

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Panel
          title={elementDisplayName}
          className={classes.container}
          titleIcon={<ReleaseQualityIndicator releaseQuality={element.releaseQuality} />}
          onClose={deselectAll}
          scrollableRef={scrollableContentRef}
          hasError={isElementInstanceErrored}
          // The `key` here is very important for ensuring that the
          // parameter list always completely re-renders when the
          // user switches the selected element instance.
          // This prevents issues where two elements have the same
          // value for a given parameter but the user has left the
          // UI with a validation error. Because that validation error
          // is in private state and we won't have propagated any value
          // changes out (i.e. we don't call onChange if the value doesn't
          // pass validation), the UI won't know to re-render the
          // editor. This would cause the UI to continue showing the
          // user input and validation errors for an element instance that
          // is no longer focused. We use Id here instead of name
          // because the element instance can be renamed while the list
          // is being rendered.
          key={elementInstance.Id}
          {...elementNamePopoverEvents}
          panelContent="ElementInstance"
        >
          <InstanceNameField
            elementInstance={elementInstance}
            isDisabled={props.isReadonly}
          />
          <ElementParameterGroupList
            connectionMaskDict={connectionMaskDict}
            parameters={element.inputs}
            elementId={element.id}
            parameterValueDict={valueDict}
            onChange={onParamChange}
            onPendingChange={onPendingParamChange}
            isDisabled={props.isReadonly}
            DOETemplateMode={props.DOETemplateMode}
            workflowId={props.workflowId}
            workflowName={workflowName}
            workflowConfig={workflowConfig}
            instanceName={elementInstance.name}
            defaultParameters={element?.defaultParameters ?? {}}
            showValidation={shouldShowValidation}
          />
          {elementNamePopover}
        </Panel>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

const useStyles = makeStylesHook(() => ({
  container: {
    gridArea: 'instancePanel',
    height: '100%',
    justifySelf: 'end',
    zIndex: 2,
  },
}));

export default ElementInstancePanel;
