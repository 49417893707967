import React, { useEffect, useMemo, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import {
  downloadTaskInstructionFiles,
  getLiquidHandlingTasks,
} from 'client/app/apps/simulation-details/dataUtils';
import { useFetchLiquidHandlingInstructions } from 'client/app/apps/simulation-details/overview/simulationDetailsFiles';
import { TreeEntry } from 'client/app/apps/simulation-details/overview/TreeEntryList';
import { SimulationQuery } from 'client/app/gql';

type Props = {
  simulation: Pick<
    SimulationQuery['simulation'],
    'id' | 'name' | 'tasks' | 'instructionsDownloaded' | '__typename'
  >;
  manualExecution: boolean;
};

export default function DownloadInstructionsButton({
  simulation: { id, name, tasks, instructionsDownloaded },
  manualExecution,
}: Props) {
  const mixTasks = useMemo(() => getLiquidHandlingTasks(tasks), [tasks]);

  const [instructions, setInstructions] = useState<readonly TreeEntry[]>([]);
  const fetchLiquidHandlingInstructions = useFetchLiquidHandlingInstructions();

  const handleDownloadInstructions = async () => {
    downloadTaskInstructionFiles(instructions);
  };

  useEffect(() => {
    let cancelled = false;

    if (!mixTasks) {
      return;
    }

    void fetchLiquidHandlingInstructions(id, name, mixTasks).then(instructions => {
      if (!cancelled) {
        setInstructions(instructions);
      }
    });

    return () => {
      cancelled = true;
    };
  }, [id, name, fetchLiquidHandlingInstructions, mixTasks]);

  return (
    <DownloadInstructionButton
      data-heap-tracking="download-instructions-button"
      color={!manualExecution && !instructionsDownloaded ? 'primary' : 'inherit'}
      size="large"
      variant="outlined"
      onClick={handleDownloadInstructions}
    >
      <DownloadIcon />
      Download Instructions
    </DownloadInstructionButton>
  );
}

const DownloadInstructionButton = styled(Button)(({ theme: { spacing } }) => ({
  gap: spacing(3),
  width: 278,
  fontSize: 15,
}));
