import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { mergeRefs } from 'react-merge-refs';
import useResizeObserver from 'use-resize-observer';

import ElementDetailsDialog from 'client/app/components/ElementPlumber/ElementDetailsDialog';
import ElementInstanceBody from 'client/app/components/ElementPlumber/ElementInstanceBody';
import ElementInstancePopover from 'client/app/components/ElementPlumber/ElementInstancePopover';
import { PendingConnection } from 'client/app/components/ElementPlumber/WorkflowLayout';
import {
  ConnectablePortMap,
  ConnectionStartCallback,
} from 'client/app/lib/layout/ConnectionHelper';
import {
  ELEMENT_INSTANCE_NAME_GAP,
  ELEMENT_INSTANCE_WIDTH,
} from 'client/app/lib/layout/LayoutHelper';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import stopPropagation from 'common/lib/stopPropagation';
import { Connection, ElementInstance } from 'common/types/bundle';
import { Position2d } from 'common/types/Position';
import Colors from 'common/ui/Colors';
import useDraggable, { DraggableProps } from 'common/ui/components/useDraggable';
import ZoomContext from 'common/ui/components/Workspace/ZoomContext';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

// Dialogs are descendants of the Workspace. Workspace has handlers for
// dragging and mouse-wheeling (zooming). The contract between the Workspace
// and its children is that the Workspace has no idea what thing is being
// clicked and dragged or mouse-wheeled. It will try to drag or zoom any time
// the opportunity comes up. It's the duty of its descendants to not be
// dragged/zoomed when they don't want to be.  So, this shim blocks these kinds
// of interactions while the dialog is open. This means that, for instance, if
// you use your pointer to select text inside of the code dialog, your mouse
// events won't make it up to the level of the Workspace, causing dragging to
// happen. Likewise, if you use the mouse wheel to scroll around in the Info
// dialog, you won't also be zooming the background.
function InteractionBlockShim(props: { children: React.ReactNode }) {
  return (
    <div onWheel={stopPropagation} onPointerDown={stopPropagation}>
      {props.children}
    </div>
  );
}
const EMPTY_SIZE = { width: 0, height: 0 };

type Props = {
  elementInstance: ElementInstance;
  isSelected: boolean;
  isGrouped: boolean;
  isLoading: boolean;
  hasError: boolean;
  showStatus: boolean;
  dragDelta: Position2d | null;
  pendingConnection: PendingConnection | null;
  allConnections: Connection[];
  portMap: ConnectablePortMap;
  nearestConnection: Connection | null;
  isReadonly?: boolean;
  onSelect: (ei: ElementInstance, e: React.PointerEvent) => void;
  onConnectionStart: ConnectionStartCallback;
  logEventCategory: string;
  zIndex?: number;
  shouldShowValidation?: boolean;
} & DraggableProps;

const ElementInstanceComponent = React.memo(
  ({
    isSelected,
    onSelect,
    isGrouped,
    isLoading,
    showStatus,
    elementInstance,
    shouldShowValidation,
    ...props
  }: Props) => {
    const isEnabledElementValidation = useFeatureToggle('PARAMETER_VALIDATION');
    const [infoDialogOpen, setInfoDialogOpen] = useState(false);
    const classes = useStyles();

    const dispatch = useWorkflowBuilderDispatch();
    const { isWorkflowInModeDOE, isEligibleForDOE } = useElementDOE(elementInstance);

    const popToTheTop = useCallback(() => {
      dispatch({
        type: 'popToTop',
        payload: {
          elementId: elementInstance.Id,
        },
      });
    }, [dispatch, elementInstance.Id]);

    const onInfoIconClick = useCallback(() => {
      logEvent('open-element-instance-info-dialog', props.logEventCategory);
      setInfoDialogOpen(true);
    }, [props.logEventCategory]);

    const onInfoDialogClose = useCallback(() => {
      logEvent('close-element-instance-info-dialog', props.logEventCategory);
      setInfoDialogOpen(false);
    }, [props.logEventCategory]);

    const zoom = useContext(ZoomContext);

    const { onPointerDown, onPointerMove, onPointerUp, isDragging } = useDraggable({
      onDragStart: useCallback(
        e => {
          // If the element is already selected, we don't want to assume what the
          // current interaction actually is. It could be a click or it could be a
          // drag. We need to wait until the pointer up event (onDrop) to figure
          // out which one it was. However, if the user has pressed the metaKey or
          // ctrlKey, they're signalling that they want to modify a complex selection
          // in which case we should not be treating it like a drag.
          if (!isSelected || e.ctrlKey || e.metaKey || isGrouped) {
            onSelect(elementInstance, e);
          }
        },
        [isGrouped, isSelected, onSelect, elementInstance],
      ),
      onDrag: useCallback(
        delta => {
          if (props.isReadonly) {
            return;
          }

          const zoomAdjustedDelta = {
            x: delta.x / zoom,
            y: delta.y / zoom,
          };

          dispatch({
            type: 'setDragDelta',
            payload: {
              id: elementInstance.Id,
              delta: zoomAdjustedDelta,
            },
          });
        },
        [dispatch, elementInstance.Id, props.isReadonly, zoom],
      ),
      onDrop: useCallback(
        delta => {
          const dx = delta.x / zoom;
          const dy = delta.y / zoom;

          // onDrop gets called when element is selected.
          // This is quite a common case and the distance is 0 or around 0.25px.
          const movedAtLeastHalfPixel = dx ** 2 + dy ** 2 > 0.5;

          if (movedAtLeastHalfPixel) {
            dispatch({ type: 'applyDragDelta' });
          }
        },
        [dispatch, zoom],
      ),
    });

    const onAnnotate = useCallback(
      (annotation: string | undefined) => {
        logEvent('edit-annotation', props.logEventCategory, elementInstance.name);
        dispatch({
          type: 'setInstanceAnnotation',
          payload: {
            instanceID: elementInstance.Id,
            annotation,
          },
        });
      },
      [dispatch, elementInstance.Id, elementInstance.name, props.logEventCategory],
    );

    const onDelete = useCallback(() => {
      logEvent(
        'delete-element-with-context-menu',
        props.logEventCategory,
        elementInstance.name,
      );
      dispatch({
        type: 'deleteSelectedObjects',
      });
    }, [dispatch, elementInstance.name, props.logEventCategory]);

    const { nameRef, bodyRef } = useMeasureElementSize(elementInstance.Id);

    let { x, y } = elementInstance.Meta;

    // If this element is selected, it may be part of a multi-selection, in
    // which case we want to apply the x and y delta calculated by whichever
    // element is the drag target to this (and any other selected) element
    // instance.
    if (props.dragDelta) {
      x += props.dragDelta.x;
      y += props.dragDelta.y;
    }

    const showPopoverWithError =
      isEnabledElementValidation &&
      !isWorkflowInModeDOE &&
      Boolean(shouldShowValidation) &&
      Boolean(elementInstance.Meta.showValidation) &&
      isSelected;

    return (
      <>
        <ElementInstancePopover
          open={showPopoverWithError}
          arrow
          errors={elementInstance.Meta.errors}
          boundaryRef={props.boundaryRef}
          PopperProps={{
            style: { zIndex: 1 },
            onWheel: e => e.stopPropagation(), // This prevents Workspace from zooming while scrolling the tooltip content
            onPointerDown: e => e.stopPropagation(), // This prevents Workspace from dragging while dragging inside the tooltip area
          }}
        >
          <div
            className={cx(classes.elementInstance, {
              [classes.isDragging]: isDragging,
              [classes.selectedInstance]: isSelected,
              [classes.disabledInstance]: isWorkflowInModeDOE && !isEligibleForDOE,
            })}
            style={{
              left: `${x}px`,
              top: `${y}px`,
              zIndex: props.zIndex,
            }}
            onPointerOver={popToTheTop}
            onPointerDown={stopPropagation}
          >
            <div className={classes.instanceName}>
              <Typography
                ref={nameRef}
                className={classes.instanceNameInner}
                color="textPrimary"
                variant="body1"
              >
                {elementInstance.name}
              </Typography>
            </div>
            <ElementInstanceBody
              ref={bodyRef}
              allConnections={props.allConnections}
              portMap={props.portMap}
              elementInstance={elementInstance}
              nearestConnection={props.nearestConnection}
              onConnectionStart={props.onConnectionStart}
              onDragStart={onPointerDown}
              onDrag={onPointerMove}
              OnDragEnd={onPointerUp}
              onInfoIconClick={onInfoIconClick}
              onAnnotate={onAnnotate}
              onDelete={onDelete}
              pendingConnection={props.pendingConnection}
              isSelected={isSelected}
              isDisabled={isWorkflowInModeDOE && !isEligibleForDOE}
              isLoading={isLoading}
              isEligibleForDOE={isEligibleForDOE}
              hasError={props.hasError}
              isReadOnly={!!props.isReadonly}
              showStatus={showStatus}
              status={elementInstance.Meta.status}
            />
          </div>
        </ElementInstancePopover>
        {infoDialogOpen && (
          <InteractionBlockShim>
            <ElementDetailsDialog
              isOpen
              onClose={onInfoDialogClose}
              elementId={elementInstance.element.id}
            />
          </InteractionBlockShim>
        )}
      </>
    );
  },
);

const useStyles = makeStylesHook({
  elementInstance: {
    cursor: 'grab',
    position: 'absolute',
    width: ELEMENT_INSTANCE_WIDTH,
    isolation: 'isolate',
  },
  selectedInstance: {
    outline: `2px solid ${Colors.BLUE_80}`,
    borderRadius: '6px',
  },
  disabledInstance: {
    opacity: 0.8,
    pointerEvents: 'none',
  },
  instanceName: {
    padding: 0,
    width: 2 * ELEMENT_INSTANCE_WIDTH,
    pointerEvents: 'none',
    position: 'absolute',
    left: '50%',
    top: `-${ELEMENT_INSTANCE_NAME_GAP}px`,
  },
  instanceNameInner: {
    bottom: 0,
    position: 'absolute',
    textAlign: 'center',
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    wordBreak: 'break-all',
    maxWidth: 2 * ELEMENT_INSTANCE_WIDTH,
    transform: 'translateX(-50%)',
  },
  instanceNameIcon: {
    width: '0.75em',
    marginLeft: '2px',
  },
  instanceHeader: {
    background: Colors.GREY_80,
    color: Colors.WHITE,
    height: '20px',
    position: 'relative',
  },
  isDragging: {
    cursor: 'grabbing',
  },
});

export default ElementInstanceComponent;

/**
 * We measure the size of the element header and body and report them to the
 * state store, so they can be used for layout purposes, e.g. for resizing groups.
 * We need report the two sizes separately instead of as a single box, because the
 * x/y position of the element refers to the top-left corner of the body, and we
 * need to know the size of both header and body to calculate the x/y position's
 * offset inside the element.
 */
function useMeasureElementSize(id: string) {
  const dispatch = useWorkflowBuilderDispatch();

  const bodyRef = useRef<HTMLDivElement | null>(null);
  const nameRef = useRef<HTMLElement | null>(null);

  const reportSize = useCallback(() => {
    const body = bodyRef.current;
    const name = nameRef.current;

    const bodySize = body
      ? { width: body.offsetWidth, height: body.offsetHeight }
      : EMPTY_SIZE;
    const nameSize = name
      ? { width: name.offsetWidth, height: name.offsetHeight + ELEMENT_INSTANCE_NAME_GAP }
      : EMPTY_SIZE;

    dispatch({
      type: 'setElementInstanceSize',
      payload: {
        elementId: id,
        bodySize,
        nameSize,
      },
    });
  }, [dispatch, id]);

  const { ref: obsNameRef } = useResizeObserver({
    onResize: reportSize,
  });

  return {
    bodyRef: bodyRef,
    nameRef: mergeRefs([obsNameRef, nameRef]),
  };
}

function useElementDOE(elementInstance: ElementInstance) {
  const mode = useWorkflowBuilderSelector(state => state.mode);
  const isEligibleForDOE = useMemo(
    () =>
      elementInstance.element.inputs?.reduce(
        (isEligibleForDOE, parameter) =>
          isEligibleForDOE || parameter.configuration?.isDOEable === true,
        false,
      ),
    [elementInstance.element.inputs],
  );
  const isWorkflowInModeDOE = mode === 'DOE';
  return { isWorkflowInModeDOE, isEligibleForDOE };
}
