import React, { useEffect, useRef } from 'react';

import PlateVisualization from 'client/app/apps/plate-constructor/PlateVisualization';
import { reportError } from 'client/app/lib/errors';
import { PlateType, WellShapeEnum } from 'common/types/plateType';

export function PlateDrawing(props: PlateType) {
  const ref = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    oldDrawPlate(ref, props);
  });
  return <canvas ref={ref} />;
}
export default class PlateDrawingNew extends React.Component<
  PlateType,
  { error: boolean }
> {
  state = {
    error: false,
  };
  componentDidCatch(error: any, info: any) {
    console.warn(error, info);
    reportError(error);
  }
  static getDerivedStateFromError() {
    return { error: true };
  }
  render() {
    if (this.state.error) {
      return <p>This plate cannot be visualized</p>;
    }
    return <PlateVisualization plate={this.props} />;
  }
}

// Copy-ported directly from antha-physical-layout-schema
// This will disappear once the plate uploader is rewritten. I hope to reuse the same plate schema here and there.
function oldDrawPlate(ref: React.RefObject<HTMLCanvasElement>, plateType: PlateType) {
  const pt = Object.assign({}, plateType, {
    columns: Number(plateType.columns),
    rows: Number(plateType.rows),
  });

  if (pt) {
    const RENDER_ABSOLUTE_PX_WIDTH = 250;
    const OFFSET_LEFT = 15;
    const OFFSET_TOP = 0;
    const LINE_PLATE_WIDTH = 2.5;
    const LINE_PLATE_COLOR = '#777';
    const LINE_WELL_WIDTH = 1.5;
    const LINE_WELL_COLOR = '#AAA';

    const canvas = ref.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return;
    }

    canvas.width = RENDER_ABSOLUTE_PX_WIDTH;
    canvas.height = RENDER_ABSOLUTE_PX_WIDTH * 0.6;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const RATIO = RENDER_ABSOLUTE_PX_WIDTH / (2 * OFFSET_LEFT + pt.dimension.x); // stretch to 100%

    const PLATE_X = RATIO * pt.dimension.x;
    const PLATE_Y = RATIO * pt.dimension.y;
    const WELL_WIDTH = RATIO * pt.wellShape.dimensionMm.x;
    const WELL_HEIGHT = RATIO * pt.wellShape.dimensionMm.y;
    const WELL_GAP_OFFSET_X = RATIO * pt.wellOffset.x;
    const WELL_GAP_OFFSET_Y = RATIO * pt.wellOffset.y;
    const GRID_START_X = RATIO * pt.wellStart.x;
    const GRID_START_Y = RATIO * pt.wellStart.y;

    // 1: base plate
    ctx.rect(OFFSET_LEFT, OFFSET_TOP, PLATE_X, PLATE_Y);
    ctx.strokeStyle = LINE_PLATE_COLOR;
    ctx.lineWidth = LINE_PLATE_WIDTH;
    ctx.stroke();

    ctx.lineWidth = LINE_WELL_WIDTH;
    ctx.strokeStyle = LINE_WELL_COLOR;

    // TODO: delete cylinder eventually as only circle is canonical
    const isWellShapeRound =
      pt.wellShape.type === WellShapeEnum.CYLINDER ||
      pt.wellShape.type === WellShapeEnum.CIRCLE;

    if (pt.rows >= 16 && pt.columns >= 24) {
      // grid (for the case where you have a plate with tons of wells)
      for (let j = 0; j < pt.rows + 1; j += 1) {
        ctx.beginPath();
        const startX = OFFSET_LEFT + GRID_START_X;
        const startY = OFFSET_TOP + GRID_START_Y + j * WELL_GAP_OFFSET_Y;
        ctx.moveTo(startX, startY);
        ctx.lineTo(startX + pt.columns * WELL_GAP_OFFSET_X, startY);
        ctx.stroke();
      }
      for (let i = 0; i < pt.columns + 1; i += 1) {
        ctx.beginPath();
        const startX = OFFSET_LEFT + GRID_START_X + i * WELL_GAP_OFFSET_X;
        const startY = OFFSET_TOP + GRID_START_Y;
        ctx.moveTo(startX, startY);
        ctx.lineTo(startX, startY + pt.rows * WELL_GAP_OFFSET_Y);
        ctx.stroke();
      }
    } else if (isWellShapeRound) {
      // round individual wells
      ctx.shadowBlur = 1;
      ctx.shadowColor = '#111';

      for (let j = 0; j < pt.rows; j += 1) {
        for (let i = 0; i < pt.columns; i += 1) {
          const startX = OFFSET_LEFT + GRID_START_X + i * WELL_GAP_OFFSET_X;
          const startY = OFFSET_TOP + GRID_START_Y + j * WELL_GAP_OFFSET_Y;

          ctx.beginPath();
          ctx.arc(startX, startY, WELL_WIDTH / 2, 0, 2 * Math.PI);
          ctx.stroke();
        }
      }
    } else {
      // square individual wells
      for (let j = 0; j < pt.rows; j += 1) {
        for (let i = 0; i < pt.columns; i += 1) {
          const startX =
            OFFSET_LEFT + GRID_START_X - WELL_WIDTH / 2 + i * WELL_GAP_OFFSET_X;
          const startY =
            OFFSET_TOP + GRID_START_Y - WELL_HEIGHT / 2 + j * WELL_GAP_OFFSET_Y;
          ctx.rect(startX, startY, WELL_WIDTH, WELL_HEIGHT);
          ctx.shadowBlur = 1;
          ctx.shadowColor = '#111';
        }
      }
    }
    ctx.stroke();
  }
}
