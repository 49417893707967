import React from 'react';

import Autorenew from '@mui/icons-material/Autorenew';
import Typography from '@mui/material/Typography';

import { useFeatureToggle } from 'common/features/useFeatureToggle';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  isReadonly: boolean;
  isSaving: boolean;
  hasWorkflowSaveFailed: boolean;
};

function WorkflowBuilderStatus({ isReadonly, isSaving, hasWorkflowSaveFailed }: Props) {
  const classes = useStyles();
  const isEnabledElementParameterValidation = useFeatureToggle('PARAMETER_VALIDATION');
  const statusCopy = isEnabledElementParameterValidation
    ? 'Saving and validating...'
    : 'Saving ...';

  let content: React.ReactElement | null;

  if (isReadonly) {
    content = null;
  } else if (hasWorkflowSaveFailed) {
    content = (
      <Typography
        variant="body1"
        className={classes.saveStatus}
        color="error"
        noWrap
        align="left"
      >
        Failed to save: Please contact support if this issue persists
      </Typography>
    );
  } else if (isSaving) {
    content = (
      <>
        <Autorenew fontSize="small" className={classes.progress} />
        <Typography variant="body1" noWrap>
          {statusCopy}
        </Typography>
      </>
    );
  } else {
    content = (
      <Typography variant="body1" className={classes.saveStatus} noWrap align="left">
        All changes saved
      </Typography>
    );
  }

  return <div className={classes.container}>{content}</div>;
}

export default React.memo(WorkflowBuilderStatus);

export const STATUS_BAR_HEIGHT = 32;

const useStyles = makeStylesHook(({ spacing, palette }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: spacing(2, 4),
    height: `${STATUS_BAR_HEIGHT}px`, // fixed the height to prevent jumping footer

    color: palette.text.primary,
  },
  saveStatus: {
    overflow: 'hidden',
    alignSelf: 'center',
    flex: 1,
    marginLeft: spacing(5),
  },
  progress: {
    margin: spacing(0, 3),
    animation: '$rotation 1s linear',
    animationIterationCount: 'infinite',
  },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}));
