import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';

import { UserProfileType } from 'client/app/hooks/useUserProfile';
import { getEnvName } from 'common/lib/envs';

const env = getEnvName(window.location?.host);

// Start different error handlers
export function startErrorHandler(service: string, version: string, commitSHA: string) {
  Sentry.init({
    dsn: 'https://815405b1f2fd4a52975143e33897478a@o371134.ingest.sentry.io/5216857',
    environment: env,
    // Releases are global per organization so we need to tag the service (antha-platform)
    release: `${service}@${commitSHA}`,
    // Disable breadcrumbs to avoid collecting personal users' data.
    maxBreadcrumbs: 0,
    // Enable for all envs that is not localhost.
    // We are passing along unknown hosts because they might be envs that we will be interested in.
    enabled: env !== 'localhost',

    integrations: [
      // Log all console errors
      new CaptureConsole({ levels: ['error'] }),
    ],
  });
}

export function setUser(profile: UserProfileType) {
  Sentry.configureScope(function (scope) {
    scope.setUser({ email: profile.email, id: profile.id });
    scope.setTag('org_human_id', profile.organisationHumanIdentifier);
  });
}

export function reportError(error: Error) {
  console.error(error);
  Sentry.captureException(error);
}
