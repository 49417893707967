import React from 'react';

import cx from 'classnames';

import {
  ResultsCard,
  ResultsStyles,
} from 'client/app/apps/simulation-details/overview/results/ResultsComponents';
import {
  ElementOutputs,
  useDownloadElementOutputFile,
} from 'client/app/apps/simulation-details/overview/simulationDetailsFiles';
import FileIcon from 'client/app/components/FileBrowser/FileIcon';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type OutputCardProps = {
  simulationFiletreeLink: FiletreeLink;
  element: ElementOutputs;
};

function OutputFileCard({ simulationFiletreeLink, element }: OutputCardProps) {
  const classes = useStyles();
  const downloadElementOutputFile = useDownloadElementOutputFile();

  const files = element.groups.flatMap(g => g.files);
  // Sometimes elements declare output files but don't actually have any files there, so don't
  // render a card in that case
  if (files.length === 0) {
    return null;
  }
  // Some elements have multiple output parameters that contain the same files (eg, a *File[] named
  // "Files" with all the output files and a *File named "FirstFile" with only the first file).
  // Deduplicate the files by path so we don't display the same file twice.
  const dedupedFiles = [];
  const seenPaths = new Set<string>();
  for (const file of files) {
    if (!seenPaths.has(file.path)) {
      seenPaths.add(file.path);
      dedupedFiles.push(file);
    }
  }

  return (
    <ResultsCard header={element.name}>
      {dedupedFiles.map(f => (
        <div
          key={f.path}
          className={cx(classes.iconTextContainer, classes.clickable)}
          onClick={() => downloadElementOutputFile(simulationFiletreeLink, f)}
        >
          <FileIcon file={{ name: f.name }} />
          <div>{f.name}</div>
        </div>
      ))}
    </ResultsCard>
  );
}

type Props = {
  simulationFiletreeLink: FiletreeLink;
  visLinksCard: React.ReactElement | null;
  elementOutputs: readonly ElementOutputs[] | null;
};

function ResultDataPanel({
  simulationFiletreeLink,
  visLinksCard,
  elementOutputs,
}: Props) {
  return (
    <>
      {visLinksCard}
      {elementOutputs?.map(el => (
        <OutputFileCard
          key={el.name}
          simulationFiletreeLink={simulationFiletreeLink}
          element={el}
        />
      ))}
    </>
  );
}

const useStyles = makeStylesHook({
  icon: ResultsStyles.icon,
  iconTextContainer: ResultsStyles.iconTextContainer,
  clickable: ResultsStyles.clickable,
});

export default ResultDataPanel;
