import React from 'react';

import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

import useExecuteSimulation from 'client/app/apps/simulation-details/useExecuteSimulation';
import { ExecuteSimulationAction } from 'client/app/gql';
import { executionRoutes } from 'client/app/lib/nav/actions';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { ExecutionIcon } from 'common/ui/icons/Execution';

type Props = {
  simulationId: SimulationId;
  disabled: boolean;
  manualExecution: boolean;
};

export default function StartExecutionButton({
  simulationId,
  disabled,
  manualExecution,
}: Props) {
  const executeSimulation = useExecuteSimulation(simulationId);
  const { navigate } = useNavigation();

  const handleStartExecution = async () => {
    // TODO - When we enable breadcrumbs by default, we should refactor ExecuteSimulationAction
    // to remove ExecuteSimulationAction.UPLOADED_DATA.
    const newExecution = await executeSimulation(
      true,
      ExecuteSimulationAction.MARK_AS_IN_PROGRESS,
    );
    if (newExecution) {
      navigate(executionRoutes.openInExecutionDetails, { executionId: newExecution.id });
    }
  };

  return (
    <Tooltip
      title={
        disabled
          ? `The ${
              manualExecution ? 'manual' : 'dispenser'
            } execution is already started. If you want to run in the lab again, please re-simulate the workflow.`
          : ''
      }
    >
      <span>
        <StyledFab
          data-heap-tracking="start-execution-button"
          color="secondary"
          size="medium"
          variant="extended"
          onClick={handleStartExecution}
          disabled={disabled}
        >
          <ExecutionIcon />
          Start Execution
        </StyledFab>
      </span>
    </Tooltip>
  );
}

const StyledFab = styled(Fab)(({ theme: { spacing } }) => ({
  gap: spacing(3),
}));
