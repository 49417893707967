import React, { ReactNode, useMemo } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import LockIcon from '@mui/icons-material/Lock';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import FactorLevels from 'client/app/components/DOEBuilder/components/FactorLevels';
import {
  getFactorName,
  getTypeCopy,
  isNumericFactor,
} from 'client/app/components/DOEBuilder/factorUtils';
import useHasDerivedFactors from 'client/app/components/DOEBuilder/useHasDerivedFactors';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { FactorItem } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type FactorListFactorItem = {
  type: 'factor';
  factor: FactorItem;
};

export type FactorListMutualExclusionItem = {
  type: 'mutualExclusion';
  factors: FactorItem[];
  name: string;
};

export type FactorListItem = FactorListFactorItem | FactorListMutualExclusionItem;

export type FactorListProps = {
  items: FactorListItem[];
  editFactor: (factorId: string) => void;
  editMutualExclusion: (groupName: string, factorId?: string) => void;
  isReadonly: boolean;
};

export default function FactorList({
  items,
  editFactor,
  editMutualExclusion,
  isReadonly,
}: FactorListProps) {
  const classes = useStyles();

  return (
    <ul className={classes.factorList}>
      {items.map(item =>
        item.type === 'factor' ? (
          <FactorListItem
            key={item.factor.id}
            factor={item.factor}
            editFactor={editFactor}
            isReadonly={isReadonly}
          />
        ) : (
          <MutualExclusionItem
            key={item.name}
            factors={item.factors}
            onEdit={editMutualExclusion}
            groupName={item.name}
            isReadonly={isReadonly}
          />
        ),
      )}
    </ul>
  );
}

function FactorListItem({
  factor,
  editFactor,
  isSubFactor,
  isReadonly,
}: {
  factor: FactorItem;
  editFactor: (factorId: string) => void;
  isSubFactor?: boolean;
  isReadonly: boolean;
}) {
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();
  const factors = useWorkflowBuilderSelector(state => state.factors);
  const sourceFactor = useMemo(
    () => factors?.find(f => f.id === factor.sourceFactor?.id),
    [factor.sourceFactor?.id, factors],
  );

  const { hasDerivedFactors: notAllowRemove, derivedFactorName } = useHasDerivedFactors(
    factor.id,
  );

  const isDerived = factor.variableTypeName === 'derived';
  const isDerivedNumerical = isDerived && isNumericFactor(factor);
  const isDerivedCategorical = isDerived && !isDerivedNumerical;
  const isConstant = !isDerived && factor.values.length === 1;

  const showType = !isSubFactor && !isDerivedCategorical;
  const showDerivedType = !isSubFactor && isDerivedCategorical && !!sourceFactor;
  const showHardToChange = !isSubFactor && !isDerived && !(factor.values.length === 1);

  return (
    <li
      className={cx(classes.factorListItem, {
        [classes.subFactor]: isSubFactor,
        [classes.derivedNumerical]: isDerivedNumerical,
        [classes.derivedCategorical]: isDerivedCategorical,
        [classes.constant]: isConstant,
      })}
      onClick={() => {
        editFactor(factor.id);
      }}
    >
      <Field label="Factor">{factor.displayName}</Field>
      {showType && <Field label="Type">{getTypeCopy(factor)}</Field>}
      {showDerivedType && (
        <Field label="Type">
          <span className={classes.lightText}>{getTypeCopy(factor)} from </span>
          <span className={classes.boldText}>{getFactorName(sourceFactor)}</span>
        </Field>
      )}
      {showHardToChange && (
        <div className={classes.field}>
          <Tooltip title="Hard To Change" placement="top-end">
            <Typography variant="caption" color="textSecondary">
              HTC
            </Typography>
          </Tooltip>
          {factor.hardToChange ? (
            <Typography variant="subtitle2" color="textPrimary">
              Yes
            </Typography>
          ) : (
            <Typography variant="subtitle2" color="textPrimary">
              No
            </Typography>
          )}
        </div>
      )}
      {isDerivedNumerical ? (
        <DerivingExpression expression={factor.derivingExpression} />
      ) : (
        <FactorLevels factor={factor} />
      )}
      <Box display="flex" alignItems="center">
        {isReadonly ? null : notAllowRemove ? (
          <Tooltip
            title={
              <div className={classes.lockTooltip}>
                <Typography variant="caption">
                  This factor cannot be deleted or changed because it has been used in a
                  derivation for the &quot;{derivedFactorName}&quot; factor.
                </Typography>
                <Typography variant="caption">
                  Please remove this factor from the &quot;{derivedFactorName}&quot;
                  derivation if you want to delete or change it.
                </Typography>
              </div>
            }
          >
            <LockIcon fontSize="small" />
          </Tooltip>
        ) : (
          <IconButton
            icon={<CloseIcon fontSize="small" color="error" />}
            size="xsmall"
            onClick={e => {
              e.stopPropagation();
              dispatch({ type: 'removeFactors', payload: [factor.id] });
            }}
          />
        )}
      </Box>
    </li>
  );
}

function MutualExclusionItem({
  factors,
  onEdit,
  groupName,
  isReadonly,
}: {
  factors: FactorItem[];
  onEdit: (groupName: string, factorId?: string) => void;
  groupName: string;
  isReadonly: boolean;
}) {
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();

  return (
    <li
      className={cx(classes.factorListItem, classes.mutualExclusion)}
      onClick={() => onEdit(groupName)}
    >
      <Field label="Name">{groupName}</Field>
      <Field label="Type">Mutual Exclusion</Field>
      <Box display="flex" alignItems="center">
        {!isReadonly && (
          <IconButton
            size="xsmall"
            icon={<CloseIcon fontSize="small" color="error" />}
            onClick={e => {
              e.stopPropagation();
              dispatch({ type: 'removeFactors', payload: factors.map(f => f.id) });
            }}
          />
        )}
      </Box>
      <ul className={cx(classes.subFactors, classes.factorList)}>
        {factors.map(factor => (
          <FactorListItem
            key={factor.id}
            factor={factor}
            editFactor={() => onEdit(groupName, factor.id)}
            isSubFactor
            isReadonly={isReadonly}
          />
        ))}
      </ul>
    </li>
  );
}

function Field({ label, children }: { label: string; children: ReactNode }) {
  const classes = useStyles();

  return (
    <div className={classes.field}>
      <Typography variant="caption" color="textSecondary">
        {label}
      </Typography>
      <TypographyWithTooltip variant="subtitle2" color="textPrimary">
        {children}
      </TypographyWithTooltip>
    </div>
  );
}

const DerivingExpression = React.memo(({ expression = '' }: { expression?: string }) => {
  const classes = useStyles();
  const allFactors = useWorkflowBuilderSelector(state => state.factors);

  const parsedExpression = useMemo(
    () =>
      allFactors?.reduce(
        (value, factor) => value.replaceAll(`{${factor.id}}`, getFactorName(factor)),
        expression,
      ),
    [allFactors, expression],
  );

  return (
    <Typography className={classes.derivedValue} variant="body2" color="textPrimary">
      =<div className={classes.derivedExpression}>{parsedExpression}</div>
    </Typography>
  );
});

export const useStyles = makeStylesHook(({ palette, spacing }) => ({
  factorList: {
    padding: 0,
    margin: 0,
  },
  factorListItem: {
    display: 'grid',
    columnGap: spacing(7),
    gridTemplateColumns: `minmax(50px, 200px) minmax(50px, 110px) 50px 1fr auto`,
    alignItems: 'center',

    background: Colors.WHITE,

    borderRadius: '4px',
    border: `1px solid ${palette.divider}`,
    boxShadow: `0px 1px 3px 0px rgba(0, 0, 0, 0.1)`,

    padding: spacing(4, 6),
    cursor: 'pointer',

    '&:hover': {
      background: Colors.BLUE_0,
      borderColor: Colors.BLUE_10,
    },

    '&:not(:last-child)': {
      marginBottom: spacing(4),
    },
    '& .levels': {
      display: 'flex',
      alignItems: 'baseline',
      overflow: 'hidden',
    },
    '& .level': {
      display: 'flex',
      alignItems: 'baseline',
      gap: spacing(2),

      margin: spacing(2),
      padding: spacing(3, 5),
      minWidth: '35px',
      maxWidth: '250px',
      overflow: 'hidden',

      backgroundColor: Colors.BLUE_0,

      border: `1px solid ${Colors.BLUE_10}`,
      borderRadius: '20px',
      flexShrink: 0,

      '& > *': {
        lineHeight: '1em',
      },
      '& .stringValue': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
    '& .more': {
      marginLeft: spacing(2),
      whiteSpace: 'nowrap',
    },
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
  mutualExclusion: {
    gridTemplate: `auto auto / minmax(50px, 200px) 1fr auto`,
    paddingBottom: spacing(6),
    rowGap: spacing(4),
    background: Colors.GREY_10,
  },
  subFactors: {
    gridRow: 2,
    gridColumn: '1 / -1',
  },
  subFactor: {
    gridTemplateColumns: `minmax(50px, 399px) 1fr auto`,
  },
  derivedNumerical: {
    gridTemplateColumns: `minmax(50px, 200px) minmax(50px, 110px) 1fr auto`,
  },
  derivedCategorical: {
    gridTemplateColumns: `minmax(50px, 200px) minmax(50px, 190px) 1fr auto`,
  },
  constant: {
    gridTemplateColumns: `minmax(50px, 200px) minmax(50px, 190px) 1fr auto`,
  },
  derivedValue: {
    display: 'flex',
    gap: spacing(3),
    alignItems: 'center',
  },
  derivedExpression: {
    flex: 1,
    background: Colors.BLUE_0,
    border: `1px solid ${Colors.BLUE_10}`,
    borderRadius: '4px',
    padding: spacing(3, 4),
  },
  lightText: {
    fontWeight: 400,
  },
  boldText: {
    fontWeight: 500,
  },
  lockTooltip: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(3),
    padding: spacing(1),
  },
}));
