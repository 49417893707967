import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';

import { formatWellPosition, roundNumber } from 'common/lib/format';
import { byName } from 'common/lib/strings';
import { Liquid, Plate, WellContents, WellLocationOnDeckItem } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import { SMART_WORD_BREAK_STYLE } from 'common/ui/commonStyles';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { isSameLocation } from 'common/ui/components/simulation-details/mix/deckContents';
import Styles from 'common/ui/components/simulation-details/Styles';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  plate: Plate;
  // Well location under cursor. Synchronised between the sidebar and the plate view.
  highlightedWellLocation: WellLocationOnDeckItem | null;
  liquidColors: LiquidColors;
  onWellMouseEnter?: (loc: WellLocationOnDeckItem) => void;
  onWellMouseLeave?: () => void;
};

export default function WellsList({
  plate,
  highlightedWellLocation,
  liquidColors,
  onWellMouseEnter,
  onWellMouseLeave,
}: Props) {
  const classes = useStyles();
  const plateContents = useMemo(() => flattenPlateContents(plate), [plate]);
  return (
    <div className={classes.list}>
      {plateContents?.map(({ loc, wellContents }) => (
        <WellListItem
          key={formatWellPosition(loc)}
          liquidColors={liquidColors}
          contents={wellContents}
          location={loc}
          isHighlighted={isSameLocation(loc.row, loc.col, highlightedWellLocation)}
          onMouseEnter={onWellMouseEnter}
          onMouseLeave={onWellMouseLeave}
        />
      ))}
    </div>
  );
}

type WellListItemProps = {
  liquidColors: LiquidColors;
  contents: WellContents;
  location: WellLocationOnDeckItem;
  isHighlighted: boolean;
  onMouseEnter?: (loc: WellLocationOnDeckItem) => void;
  onMouseLeave?: () => void;
};

function WellListItem({
  liquidColors,
  location,
  contents,
  isHighlighted,
  onMouseEnter,
  onMouseLeave,
}: WellListItemProps) {
  const classes = useStyles();
  const handleMouseEnter = useCallback(
    () => onMouseEnter?.(location),
    [onMouseEnter, location],
  );
  const color = useMemo(
    () => liquidColors.getColorForWell(contents as Liquid),
    [liquidColors, contents],
  );
  return (
    <div
      className={classNames(classes.wellRow, {
        [classes.wellRowHighlighted]: isHighlighted,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={classes.positionCircle} style={{ backgroundColor: color }}>
        {formatWellPosition(location)}
      </div>
      <div className={classes.nameAndVolume}>
        <div className={classes.name}>{getWellName(contents)}</div>
        <div className={classes.volumeAndUnit}>
          <span className={classes.volume}>
            {roundNumber(contents.total_volume.value)}
          </span>{' '}
          {contents.total_volume.unit}
        </div>
      </div>
    </div>
  );
}

type WellContentsWithPosition = {
  loc: WellLocationOnDeckItem;
  wellContents: WellContents;
};

// As opposed to PlateContentsMatrix
type PlateContentsFlat = WellContentsWithPosition[];

function flattenPlateContents(plate: Plate): PlateContentsFlat | undefined {
  const res: PlateContentsFlat = [];
  const matrix = plate.contents;
  if (!matrix) {
    return undefined;
  }
  for (const colIdx of Object.keys(matrix)) {
    const column = matrix[Number(colIdx)];
    for (const rowIdx of Object.keys(column)) {
      res.push({
        loc: {
          row: Number(rowIdx),
          col: Number(colIdx),
          deck_item_id: plate.id,
        },
        wellContents: column[Number(rowIdx)],
      });
    }
  }
  return res;
}

function getWellName(liquid: WellContents) {
  if (liquid.name) {
    return liquid.name;
  } else if (liquid.sub_liquids) {
    return buildWellNameFrom(liquid.sub_liquids);
  } else if (liquid.components) {
    return buildWellNameFrom(liquid.components);
  } else {
    // Empty name, and no components. The backend should never return
    // liquids like this, but if it does there's not much we can do.
    return '';
  }
}

function buildWellNameFrom(reagents: { name: string }[]) {
  const reagentsSortedByName = [...reagents];
  // Sort, for consistency with how we show a list of components
  // in the RightPanel
  reagentsSortedByName.sort(byName);
  return (
    <div>
      {reagentsSortedByName.map((reagent, index) => (
        <div key={index}>
          {reagent.name}
          {index < reagents.length - 1 && ' +'}
        </div>
      ))}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  list: {
    marginBottom: theme.spacing(3),
  },
  wellRow: {
    alignItems: 'center',
    borderBottom: Styles.lightBorder,
    display: 'flex',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2, 3),
    // Avoid breaking the row into two pages when printing it
    breakInside: 'avoid',
  },
  wellRowHighlighted: {
    background: Colors.GREY_30,
  },
  nameAndVolume: {
    flex: 1,
    display: 'flex',
  },
  name: {
    flex: 1,
    margin: theme.spacing(0, 3),
    ...SMART_WORD_BREAK_STYLE,
  },
  volumeAndUnit: {
    whiteSpace: 'nowrap',
  },
  volume: {
    fontWeight: 400,
  },
  positionCircle: {
    alignSelf: 'center',
    border: '1px solid #ccc',
    borderRadius: '32px',
    padding: theme.spacing(3),
  },
}));
