import React, { useCallback, useContext } from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_WORKFLOW_DETAILS_PANEL } from 'client/app/api/gql/queries';
import { useCopyWorkflowAndNavigate } from 'client/app/api/WorkflowsApi';
import useDownloadWorkflowBundle from 'client/app/apps/workflow-builder/lib/useDownloadWorkflowBundle';
import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import DetailsCard, {
  DetailsCardToolipTitle,
} from 'client/app/components/nav/DetailsCard';
import HeaderEditableName from 'client/app/components/nav/HeaderEditableName';
import { workflowRoutes } from 'client/app/lib/nav/actions';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { EditorType } from 'common/types/bundle';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

export default function TopBuilderEditablename({
  disabled,
  workflowId,
  editorType,
}: {
  disabled?: boolean;
  workflowId?: string;
  editorType: EditorType;
}) {
  const workflowName = useWorkflowBuilderSelector(state => state.workflowName);
  const dispatch = useWorkflowBuilderDispatch();

  const onChange = (newName: string) => {
    dispatch({ type: 'setWorkflowName', payload: newName });
  };

  const { loading, data } = useQuery(QUERY_WORKFLOW_DETAILS_PANEL, {
    variables: { id: workflowId as WorkflowId },
  });

  const onCopyWorkflow = useCopyWorkflowAndNavigate(
    data?.workflow.id,
    data?.workflow.version,
    editorType,
  );

  const { screenId } = useContext(ScreenContext);

  const isDOETemplate = editorType === EditorType.DOE_TEMPLATE;
  const downloadWorkflowBundle = useDownloadWorkflowBundle();
  const onDownloadWorkflow = useCallback(() => {
    logEvent('download-workflow', screenId as string);
    if (!workflowId || !data) {
      console.error('Cannot download workflow: missing id or version');
      return;
    }
    downloadWorkflowBundle(workflowId, data.workflow.version, workflowName).catch(e => {
      console.error(e);
    });
  }, [data, downloadWorkflowBundle, screenId, workflowId, workflowName]);

  const { navigate } = useNavigation();
  const onReturnToWorkflow = useCallback(() => {
    const parentWorkflowID = data?.workflow.parentWorkflowID;
    if (!parentWorkflowID) {
      return;
    }
    navigate(workflowRoutes.openInWorkflowBuilder, {
      workflowId: parentWorkflowID,
    });
  }, [data?.workflow.parentWorkflowID, navigate]);

  const detailsProps = data
    ? {
        workflow: data.workflow,
        onCopy: onCopyWorkflow,
        onDownload: isDOETemplate ? undefined : onDownloadWorkflow,
        onReturnToWorkflow: isDOETemplate ? onReturnToWorkflow : undefined,
        tooltipTitleOverrides: {
          [DetailsCardToolipTitle.RETURN]: 'Return to workflow',
        },
        disabledButtonOverrides: {
          [DetailsCardToolipTitle.RETURN]: !data.workflow.parentWorkflowID,
        },
      }
    : null;

  const prefix = isDOETemplate ? 'DOE Template Editor' : 'Builder';

  return (
    <HeaderEditableName
      name={workflowName}
      onNameChange={onChange}
      prefix={prefix}
      disabled={disabled}
      // We won't show the chevron/panel until we have all the details data.
      popoverPanel={!loading && detailsProps && <DetailsCard {...detailsProps} />}
    />
  );
}
