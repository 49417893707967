import React from 'react';

import { WorkflowsList } from 'client/app/apps/experiments/WorkflowsScreen';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

type WorkflowsDialogProps = {
  dialogClassName?: string;
  disabledWorkflowIds?: WorkflowId[];
} & DialogProps<WorkflowId | typeof CANCEL_CHOICE>;
/**
 * Render a list of Workflows in a dialog, allowing the user to pick one Workflow.
 */
export default function WorkflowsDialog(props: WorkflowsDialogProps) {
  const { disableRestoreFocus, isOpen, onClose, dialogClassName, disabledWorkflowIds } =
    props;

  const onCancel = () => onClose(CANCEL_CHOICE);

  const classes = useStyles();

  return (
    <ComplexActionDialog
      title="Workflows"
      onClose={onCancel}
      isOpen={isOpen}
      showCloseButton
      fullHeight
      className={dialogClassName}
      disableRestoreFocus={disableRestoreFocus}
      content={
        <div className={classes.dialogContent}>
          <WorkflowsList
            dialog
            onSelectWorkflowId={onClose}
            disabledWorkflowIds={disabledWorkflowIds}
          />
        </div>
      }
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  dialogContent: {
    display: 'flex',
    height: 0,
    flexGrow: 1,
    padding: theme.spacing(5),
    // Make the WorkflowsList fill the dialog
    alignItems: 'stretch',
    justifyContent: 'stretch',
  },
}));
