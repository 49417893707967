import { QUERY_RECENTLY_ACCESSED_ELEMENT_SETS } from 'client/app/api/gql/queries';
import { createMutationNew } from 'client/app/api/gql/utils';
import {
  graphql,
  RecordEntityAccessMutation,
  RecordEntityAccessMutationVariables,
} from 'client/app/gql';

export const MUTATION_CREATE_WORKFLOW = graphql(/* GraphQL */ `
  mutation CreateWorkflow($name: String!, $source: WorkflowSourceEnum!) {
    createWorkflow(input: { name: $name, source: $source }) {
      workflow {
        id
        version
      }
    }
  }
`);

export const MUTATION_COPY_WORKFLOW = graphql(/* GraphQL */ `
  mutation CopyWorkflow($id: WorkflowId!, $version: Int!, $source: WorkflowSourceEnum) {
    copyWorkflow(input: { id: $id, version: $version, source: $source }) {
      id
    }
  }
`);

export const MUTATION_CREATE_DOE_TEMPLATE = graphql(/* GraphQL */ `
  mutation CreateDOETemplate($simulationId: SimulationId!) {
    createDOETemplate(input: { simulationId: $simulationId }) {
      id
    }
  }
`);

export const MUTATION_UPDATE_WORKFLOW = graphql(/* GraphQL */ `
  mutation UpdateWorkflow(
    $id: WorkflowId!
    $version: Int!
    $name: String
    $workflow: WorkflowBlob!
  ) {
    updateWorkflow(
      input: { id: $id, version: $version, name: $name, workflow: $workflow }
    ) {
      elementContextMap
      workflow {
        id
        version
        lastModifiedAt
      }
    }
  }
`);

export const MUTATION_DELETE_WORKFLOW = graphql(/* GraphQL */ `
  mutation DeleteWorkflow($id: WorkflowId!) {
    deleteWorkflow(input: { id: $id }) {
      id
    }
  }
`);

export const MUTATION_UPDATE_WORKFLOW_ELEMENT_SET = graphql(/* GraphQL */ `
  mutation UpdateWorkflowElementSet($workflowId: WorkflowId!, $elementSetId: String!) {
    updateWorkflowElementSet(
      input: { workflowId: $workflowId, elementSetId: $elementSetId }
    ) {
      workflow {
        id
        name
        version
        workflow
        parentWorkflowID
        editMode
        source
        createdBy {
          id
          displayName
        }
        contentSource
      }
    }
  }
`);

export const MUTATION_MIGRATE_WORKFLOW = graphql(/* GraphQL */ `
  mutation MigrateWorkflow($id: WorkflowId!) {
    migrateWorkflow(input: { id: $id }) {
      workflow {
        ...WorkflowFragment
      }
    }
  }
`);

export const MUTATION_MIGRATE_TEMPLATE_WORKFLOW = graphql(/* GraphQL */ `
  mutation MigrateTemplateWorkflow($id: TemplateWorkflowId!) {
    migrateTemplateWorkflow(input: { id: $id }) {
      ...TemplateWorkflowCommon
      workflow {
        id
        name
        workflow
      }
    }
  }
`);

export const [useRecordEntityAccessMutation, callRecordEntityAccessMutation] =
  createMutationNew<RecordEntityAccessMutationVariables, RecordEntityAccessMutation>(
    graphql(/* GraphQL */ `
      mutation RecordEntityAccess(
        $entityType: AccessHistoryEntityType!
        $entityIdentifier: String!
      ) {
        recordEntityAccess(
          input: { entityIdentifier: $entityIdentifier, entityType: $entityType }
        )
      }
    `),
    [QUERY_RECENTLY_ACCESSED_ELEMENT_SETS],
  );

export const MUTATION_SIMULATE_WORKFLOW = graphql(/* GraphQL */ `
  mutation SimulateWorkflow(
    $workflowId: WorkflowId!
    $workflowVersion: Int!
    $shouldApplyDesign: Boolean
  ) {
    simulateWorkflow(
      input: {
        workflowId: $workflowId
        workflowVersion: $workflowVersion
        shouldApplyDesign: $shouldApplyDesign
      }
    ) {
      simulation {
        ...SimulationsFragment
        simulationSeriesSiblings {
          ...SimulationsFragment
        }
      }
    }
  }
`);

export const MUTATION_UPDATE_SIMULATION = graphql(/* GraphQL */ `
  mutation UpdateSimulation($simulationID: SimulationId!, $name: String!) {
    updateSimulation(input: { simulationID: $simulationID, name: $name }) {
      simulation {
        id
        name
      }
    }
  }
`);

export const MUTATION_DELETE_SIMULATION = graphql(/* GraphQL */ `
  mutation DeleteSimulation($simulationID: SimulationId!) {
    deleteSimulation(input: { simulationID: $simulationID }) {
      simulationId
    }
  }
`);

export const MUTATION_EXECUTE_SIMULATION = graphql(/* GraphQL */ `
  mutation ExecuteSimulation($simulationId: String!, $withTasks: Boolean = true) {
    executeSimulation(input: { simulationId: $simulationId }) {
      ...ExecutionForSimulation
    }
  }
`);

export const MUTATION_CREATE_FAKE_EXECUTION = graphql(/* GraphQL */ `
  mutation CreateFakeExecution(
    $simulationId: String!
    $action: ExecuteSimulationAction
    $withTasks: Boolean = true
  ) {
    createFakeExecution(input: { simulationId: $simulationId, action: $action }) {
      ...ExecutionForSimulation
    }
  }
`);

export const MUTATION_MARK_EXECUTION_AS_COMPLETED = graphql(/* GraphQL */ `
  mutation MarkExecutionAsCompleted($executionId: String!, $withTasks: Boolean = true) {
    markExecutionAsCompleted(input: { executionId: $executionId }) {
      ...ExecutionForSimulation
    }
  }
`);

export const MUTATIONS_UPLOAD_LIQUID_CLASSES_CSV = graphql(/* GraphQL */ `
  mutation UploadLiquidClassesCSV($deviceID: UUID!, $csv: String!) {
    updateDeviceLiquidClasses(input: { id: $deviceID, csvData: $csv }) {
      updated
      created
    }
  }
`);

export const MUTATION_ADD_ACCESSIBLE_DEVICE = graphql(/* GraphQL */ `
  mutation AddAccessibleDevice($parentDeviceId: UUID!, $accessibleDeviceId: UUID!) {
    addAccessibleDevice(
      input: { parentDeviceId: $parentDeviceId, accessibleDeviceId: $accessibleDeviceId }
    ) {
      ...DeviceCommon
    }
  }
`);

export const MUTATION_REMOVE_ACCESSIBLE_DEVICE = graphql(/* GraphQL */ `
  mutation RemoveAccessibleDevice($parentDeviceId: UUID!, $accessibleDeviceId: UUID!) {
    removeAccessibleDevice(
      input: { parentDeviceId: $parentDeviceId, accessibleDeviceId: $accessibleDeviceId }
    ) {
      ...DeviceCommon
    }
  }
`);

export const MUTATION_UPDATE_ACCESSIBLE_DEVICE = graphql(/* GraphQL */ `
  mutation UpdateAccessibleDevice(
    $parentDeviceId: UUID!
    $accessibleDeviceId: UUID!
    $accessibleDeviceSetup: AccessibleDeviceSetupBlob
  ) {
    updateAccessibleDevice(
      input: {
        parentDeviceId: $parentDeviceId
        accessibleDeviceId: $accessibleDeviceId
        accessibleDeviceSetup: $accessibleDeviceSetup
      }
    )
  }
`);

export const MUTATION_UPDATE_TEMPLATE_WORKFLOW = graphql(/* GraphQL */ `
  mutation UpdateTemplateWorkflow(
    $id: TemplateWorkflowId!
    $name: String!
    $description: String!
    $isShared: Boolean!
    $inputs: [UpdateTemplateWorkflowInputInput!]!
    $configParameters: [UpdateTemplateWorkflowConfigParametersInput!]!
  ) {
    updateTemplateWorkflow(
      input: {
        id: $id
        name: $name
        description: $description
        isShared: $isShared
        inputs: $inputs
        configParameters: $configParameters
      }
    )
  }
`);

export const MUTATION_DELETE_TEMPLATE_WORKFLOW = graphql(/* GraphQL */ `
  mutation DeleteTemplateWorkflow($id: TemplateWorkflowId!) {
    deleteTemplateWorkflow(input: { id: $id })
  }
`);

export const MUTATION_RESTORE_SHARED_WORKFLOW = graphql(/* GraphQL */ `
  mutation restoreWorkflow($sharedWorkflowId: String!, $createDevices: Boolean!) {
    restoreWorkflow(
      input: { sharedWorkflowId: $sharedWorkflowId, createDevices: $createDevices }
    ) {
      workflowId
    }
  }
`);

export const MUTATION_SHARE_WORKFLOW_WITH_ORG = graphql(/* GraphQL */ `
  mutation shareWorkflow(
    $simulationId: String!
    $destOrgName: String!
    $message: String!
  ) {
    shareWorkflow(
      input: { simulationId: $simulationId, destOrgName: $destOrgName, message: $message }
    ) {
      sharedWorkflow {
        id
      }
    }
  }
`);

export const MUTATION_RESTORE_SHARED_METHOD = graphql(/* GraphQL */ `
  mutation restoreMethod($sharedMethodId: String!) {
    restoreMethod(input: { sharedMethodId: $sharedMethodId }) {
      id
      href
    }
  }
`);

export const MUTATION_UPDATE_FAVORITE_SIMULATION = graphql(/* GraphQL */ `
  mutation updateFavoriteSimulation($simulationID: String!, $favorited: Boolean!) {
    updateFavoriteSimulation(
      input: { simulationId: $simulationID, favorited: $favorited }
    ) {
      simulation {
        id
        favoritedBy
        isFavoritedByCurrentUser
      }
    }
  }
`);

export const MUTATION_DELETE_PLATE_TYPE = graphql(/* GraphQL */ `
  mutation DeletePlateType($type: String!) {
    deletePlateType(input: { type: $type }) {
      type
    }
  }
`);

export const MUTATION_CREATE_PLATE_TYPE = graphql(/* GraphQL */ `
  mutation CreatePlateType(
    $type: String!
    $name: String!
    $manufacturer: String
    $description: String
    $catalogNumber: String
    $accessory: String
    $format: String
    $usage: String
    $columns: Int!
    $rows: Int!
    $wellBottomOffset: Float!
    $wellOffset: wellOffset!
    $wellStart: wellStart!
    $dimension: dimension!
    $color: String
    $catalogUrl: String
    $defaultResidualVolume: Float!
    $residualVolumes: residualVolumes!
    $wellShape: wellShape!
    $containerType: ContainerType!
  ) {
    createPlateType(
      input: {
        type: $type
        name: $name
        manufacturer: $manufacturer
        description: $description
        catalogNumber: $catalogNumber
        accessory: $accessory
        format: $format
        usage: $usage
        columns: $columns
        rows: $rows
        wellBottomOffset: $wellBottomOffset
        wellOffset: $wellOffset
        wellStart: $wellStart
        dimension: $dimension
        color: $color
        catalogUrl: $catalogUrl
        defaultResidualVolume: $defaultResidualVolume
        residualVolumes: $residualVolumes
        wellShape: $wellShape
        containerType: $containerType
      }
    ) {
      plateType {
        id
      }
    }
  }
`);

export const MUTATION_UPDATE_PLATE_TYPE = graphql(/* GraphQL */ `
  mutation UpdatePlateType(
    $type: String!
    $name: String!
    $manufacturer: String
    $description: String
    $catalogNumber: String
    $accessory: String
    $format: String
    $usage: String
    $columns: Int!
    $rows: Int!
    $wellBottomOffset: Float!
    $wellOffset: wellOffset!
    $wellStart: wellStart!
    $dimension: dimension!
    $color: String
    $catalogUrl: String
    $defaultResidualVolume: Float!
    $residualVolumes: residualVolumes!
    $wellShape: wellShape!
    $containerType: ContainerType!
  ) {
    updatePlateType(
      input: {
        type: $type
        name: $name
        manufacturer: $manufacturer
        description: $description
        catalogNumber: $catalogNumber
        accessory: $accessory
        format: $format
        usage: $usage
        columns: $columns
        rows: $rows
        wellBottomOffset: $wellBottomOffset
        wellOffset: $wellOffset
        wellStart: $wellStart
        dimension: $dimension
        color: $color
        catalogUrl: $catalogUrl
        defaultResidualVolume: $defaultResidualVolume
        residualVolumes: $residualVolumes
        wellShape: $wellShape
        containerType: $containerType
      }
    ) {
      plateType {
        id
      }
    }
  }
`);

export const MUTATION_CREATE_UPLOAD = graphql(/* GraphQL */ `
  mutation CreateUpload(
    $deviceId: DeviceId
    $parser: String
    $executionTaskId: ExecutionTaskId
    $executionId: ExecutionId
  ) {
    createUpload(
      input: {
        deviceId: $deviceId
        parser: $parser
        executionTaskId: $executionTaskId
        executionId: $executionId
      }
    ) {
      id
      directoryFiletreeLink
    }
  }
`);

export const MUTATION_PROCESS_UPLOAD_SINGLE_CREATE_LABWARE_HISTORY =
  graphql(/* GraphQL */ `
    mutation ProcessUploadSingleCreateLabwareHistory(
      $uploadId: DataUploadId!
      $filetreeLink: String!
      $labwareId: LabwareId!
      $executionId: ExecutionId!
    ) {
      processUpload(
        input: {
          uploadId: $uploadId
          originFiles: [
            {
              originFiletreeLink: $filetreeLink
              createLabwareHistoryMappings: [
                { objectName: "*", labwareId: $labwareId, executionId: $executionId }
              ]
            }
          ]
        }
      ) {
        id
      }
    }
  `);

export const MUTATION_REPROCESS_UPLOAD = graphql(/* GraphQL */ `
  mutation ReprocessUpload($uploadId: DataUploadId!) {
    reprocessUpload(input: { uploadId: $uploadId }) {
      id
      state
    }
  }
`);

export const MUTATION_DELETE_FILES_FROM_UPLOAD = graphql(/* GraphQL */ `
  mutation DeleteFilesFromUpload($uploadId: DataUploadId!, $fileIds: [DataFileId!]!) {
    deleteFilesFromUpload(input: { uploadId: $uploadId, fileIds: $fileIds }) {
      id
      dataFiles {
        items {
          id
          state
          error
          originFiletreeLink
        }
      }
    }
  }
`);

export const MUTATION_CREATE_SYNTHETIC_FILE = graphql(/* GraphQL */ `
  mutation CreateSyntheticFile(
    $deviceId: DeviceId!
    $filetreePath: FiletreePath!
    $parameters: DataGeneratorParameters!
  ) {
    createSyntheticFile(
      input: { deviceId: $deviceId, filetreePath: $filetreePath, parameters: $parameters }
    )
  }
`);

export const MUTATION_CREATE_WORKFLOW_FROM_TEMPLATE = graphql(/* GraphQL */ `
  mutation CreateWorkflowFromTemplate($templateWorkflowId: String!) {
    createWorkflowFromTemplate(input: { templateWorkflowId: $templateWorkflowId }) {
      workflow {
        id
      }
    }
  }
`);

export const MUTATION_COPY_TEMPLATE_WORKFLOW = graphql(/* GraphQL */ `
  mutation CopyTemplateWorkflow($templateWorkflowName: String!, $workflowId: String!) {
    copyTemplateWorkflow(
      input: { templateWorkflowName: $templateWorkflowName, workflowId: $workflowId }
    ) {
      id
    }
  }
`);

/**
 * Used by Plate Mapper to define a new plate comprising of liquids from one or more
 * source plates.
 */
export const MUTATION_CREATE_LABWARE = graphql(/* GraphQL */ `
  mutation CreateLabware(
    $name: String!
    $executionId: ExecutionId!
    $labwareFormat: String!
    $contents: [CreateLabwareLocationContents!]!
  ) {
    createLabware(
      input: {
        name: $name
        executionId: $executionId
        labwareFormat: $labwareFormat
        contents: $contents
      }
    ) {
      id
    }
  }
`);

export const MUTATION_UPDATE_LABWARE = graphql(/* GraphQL */ `
  mutation UpdateLabware(
    $id: LabwareId!
    $newPlateName: String!
    $labwareFormat: String!
    $contents: [CreateLabwareLocationContents!]!
  ) {
    updateLabware(
      input: {
        id: $id
        newPlateName: $newPlateName
        labwareFormat: $labwareFormat
        contents: $contents
      }
    ) {
      id
    }
  }
`);

export const MUTATION_DELETE_LABWARE = graphql(/* GraphQL */ `
  mutation DeleteLabware($id: LabwareId!) {
    deleteLabware(input: { id: $id }) {
      id
    }
  }
`);

export const MUTATION_MARK_SIMULATION_INSTRUCTIONS_DOWNLOADED = graphql(/* GraphQL */ `
  mutation MarkSimulationInstructionsDownloaded($id: SimulationId!) {
    markSimulationInstructionsDownloaded(input: { simulationID: $id }) {
      simulation {
        id
        name
        instructionsDownloaded
      }
    }
  }
`);

export const MUTATION_UPDATE_USER_ROLE = graphql(/* GraphQL */ `
  mutation UpdateUserRole($roleUpdate: UserRoleInput!) {
    updateUserRole(input: $roleUpdate) {
      id
      role {
        id
        name
      }
    }
  }
`);

export const MUTATION_RENAME_BREADCRUMBS_ENTITY = graphql(/* GraphQL */ `
  mutation RenameBreadcrumbsEntity(
    $id: String!
    $entity: BreadcrumbsEntityEnum!
    $newName: String!
  ) {
    renameBreadcrumbsEntity(input: { newName: $newName, id: $id, entity: $entity }) {
      id
    }
  }
`);
