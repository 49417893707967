import React, { useCallback, useMemo } from 'react';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import {
  areAllPlateTypesSelected,
  isAtLeastOneTransferValid,
  NO_VALID_TRANSFERS_MSG,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import { useCherryPickContext } from 'client/app/apps/cherry-picker/CherryPickContext';
import RouteLeavingGuard from 'client/app/components/RouteLeavingGuard';
import { WorkflowSourceEnum } from 'client/app/gql';
import { useSimulationClient } from 'client/app/lib/SimulationClient';
import { configHasNoDevices } from 'client/app/lib/workflow/deviceConfigUtils';
import { ScreenRegistry } from 'client/app/registry';
import { useSimulationNotificationsDispatch } from 'client/app/state/SimulationNotificationsContext';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const DEFAULT_ERROR_MSG =
  'Something went wrong. If the problem persists, send us a message using the Intercom!';

const SAVING_MSG = 'Saving...';
const CHANGES_SAVED_MSG = 'All changes saved.';

export default React.memo(function CherryPickSimulationBar() {
  const classes = useStyles();
  const {
    platesByName,
    uniquePlateNames,
    bundleConfig,
    lastSavedWorkflow,
    isSavingWorkflow,
    cherryPick,
    isReadonly,
  } = useCherryPickContext();

  const dispatch = useSimulationNotificationsDispatch();
  const snackbarManager = useSnackbarManager();

  /** Users need to select a) a Plate type for each plate defined
   * b) A device, otherwise the simulation will just fail.
   * Disable "Simulate" button if these conditions are not met.
   */
  const allPlatesDefined = useMemo(
    () => areAllPlateTypesSelected(platesByName, uniquePlateNames),
    [platesByName, uniquePlateNames],
  );

  const isDeviceSelected = !configHasNoDevices(bundleConfig);

  const atLeastOneValidTransfer = useMemo(
    () => isAtLeastOneTransferValid(cherryPick),
    [cherryPick],
  );

  const tooltipCaption = useMemo(() => {
    if (isReadonly) {
      return 'Cannot simulate an historical version of a workflow.';
    }

    const targets = [];
    if (!allPlatesDefined) {
      targets.push('plate types');
    }
    if (!isDeviceSelected) {
      targets.push('an execution mode');
    }

    if (!targets.length) {
      return !atLeastOneValidTransfer ? NO_VALID_TRANSFERS_MSG : 'Click to simulate';
    }

    const target = targets.join(' and ');
    return `Please select ${target} before simulating`;
  }, [allPlatesDefined, atLeastOneValidTransfer, isDeviceSelected, isReadonly]);

  const simulationService = useSimulationClient();

  const handleSimulate = useCallback(async () => {
    if (!lastSavedWorkflow) {
      // This should never happen, as the lastSavedWorkflow is
      // set once the UI renders.
      throw new Error('Could not load Workflow to simulate.');
    }

    logEvent('simulate', ScreenRegistry.CHERRY_PICKER);
    window.Intercom('trackEvent', 'simulate-cherry-picker');
    try {
      const { requestId: requestID } = simulationService.simulateWorkflow(
        lastSavedWorkflow.workflowId,
        lastSavedWorkflow.editVersion,
        result => {
          dispatch({
            type: 'updateSimulationNotification',
            payload: {
              info: result,
              status: 'SUCCESS',
            },
          });
        },
        progress => {
          dispatch({
            type: 'updateSimulationNotification',
            payload: {
              info: progress,
              status: 'IN_PROGRESS',
            },
          });
        },
        error => {
          dispatch({
            type: 'updateSimulationNotification',
            payload: {
              info: error,
              status: 'ERROR',
            },
          });
        },
      );
      // Add the notification because we've just kicked off the simulation
      dispatch({
        type: 'addSimulationNotification',
        payload: {
          requestID: requestID.toString(),
          status: 'IN_PROGRESS',
          source: WorkflowSourceEnum.CHERRY_PICKER,
          name: lastSavedWorkflow.bundle.Meta.Name,
          startTime: new Date().toLocaleTimeString(),
          parentWorkflowId: lastSavedWorkflow.workflowId,
        },
      });
    } catch (e) {
      console.error(e.message || e);
      snackbarManager.showError(DEFAULT_ERROR_MSG);
    }
  }, [dispatch, lastSavedWorkflow, simulationService, snackbarManager]);

  const savingStatusMsg = useMemo(
    () => (isSavingWorkflow ? SAVING_MSG : CHANGES_SAVED_MSG),
    [isSavingWorkflow],
  );

  return (
    <>
      <RouteLeavingGuard when={isSavingWorkflow} />
      <div className={classes.actions}>
        {!isReadonly && (
          <Typography variant="h3" color="textSecondary">
            {savingStatusMsg}
          </Typography>
        )}
        <Tooltip title={tooltipCaption}>
          <span>
            <Button
              variant="contained"
              color="secondary"
              className={classes.actionBtn}
              onClick={handleSimulate}
              disabled={
                !isDeviceSelected ||
                !allPlatesDefined ||
                !atLeastOneValidTransfer ||
                isReadonly
              }
            >
              Simulate
            </Button>
          </span>
        </Tooltip>
      </div>
    </>
  );
});

const useStyles = makeStylesHook({
  actions: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  actionBtn: {
    margin: '0.5rem',
  },
});
