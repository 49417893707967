import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const ExperimentsIcon = React.memo<SvgIconProps>(function ExperimentsIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <svg fill="none" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 4.105L7.5 2H0v18a2 2 0 002 2h20a2 2 0 002-2V6.105a2 2 0 00-2-2H9zM2 4v16h20V6.105H7.97l-.599-.84L6.47 4H2z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M15 13.95h3v4h-3zM15 7.95h3v4h-3z" />
        <path d="M16 9.95h-4v6h4" stroke="currentColor" strokeWidth="1.5" />
        <path fill="currentColor" d="M8 12.2h4v1.5H8z" />
        <path fill="currentColor" d="M6 10.95h3v4H6z" />
      </svg>
    </SvgIcon>
  );
});
