import { Role } from 'auth0';

export const CUSTOMER_PREFIX = 'Customer';
export const PREFIX_DELIMITER = ':';
export const NO_ROLE_COPY = 'No Role';

export function getCustomerRole(auth0Roles: Role[]): Role | null {
  const custRoles = auth0Roles.filter(isCustomerRole);
  if (custRoles.length === 0) {
    return null;
  } else {
    // We shouldn't assign users more than one customer role in auth0, if that has happened the best we can do here is pick one
    return custRoles[0];
  }
}

export function isCustomerRole(role: Role): boolean {
  const roleSplit = role.name?.split(PREFIX_DELIMITER);
  return !!roleSplit && roleSplit[0] === CUSTOMER_PREFIX && !!role.id;
}

export function removePrefix(name: string) {
  return name.split(PREFIX_DELIMITER).slice(1).join(PREFIX_DELIMITER).trim();
}

export function removePrefixFromRole(role: Role) {
  const name = role.name ? removePrefix(role.name) : '';
  return {
    ...role,
    name,
  };
}
