import React, { Fragment } from 'react';

import { styled } from '@mui/material/styles';

import { GridDecorator } from 'client/app/apps/simulation-details/instructions/PlateMapGrid';
import {
  getColumnNumberFromWellPosition,
  getRowNumberFromWellPosition,
} from 'common/lib/format';
import { LayerPlate } from 'common/types/steps';
import Colors from 'common/ui/Colors';

type Props = {
  direction?: 'byRow' | 'byColumn';
  plate: LayerPlate;
};

export function SerialDilutionDirectionIndicator({ direction, plate }: Props) {
  if (direction !== 'byRow' && direction !== 'byColumn') {
    return null;
  }

  const indicators = [];

  const rows = Array.from(Object.keys(plate.wells)).map(
    well => getRowNumberFromWellPosition(well) + 1,
  );
  const cols = Array.from(Object.keys(plate.wells)).map(
    well => getColumnNumberFromWellPosition(well) + 1,
  );

  const minY = Math.min(...rows);
  const maxY = Math.max(...rows);
  const minX = Math.min(...cols);
  const maxX = Math.max(...cols);

  if (direction === 'byRow') {
    for (let x = minX; x < maxX; x++) {
      indicators.push(<Arrow x={x} y={maxY + 1} direction="horizontal" />);
    }
  } else if (direction === 'byColumn') {
    for (let y = minY; y < maxY; y++) {
      indicators.push(<Arrow x={maxX + 1} y={y} direction="vertical" />);
    }
  }

  return <>{indicators}</>;
}

const Arrow = ({
  x,
  y,
  direction,
}: {
  x: number;
  y: number;
  direction: 'horizontal' | 'vertical';
}) => {
  return direction === 'horizontal' ? (
    <>
      <HorizontalArrow x={x} y={y} width={1} height={1} start />
      <HorizontalArrow x={x + 1} y={y} width={1} height={1}>
        <HorizontalArrowPoint />
      </HorizontalArrow>
    </>
  ) : (
    <>
      <VerticalArrow x={x} y={y} width={1} height={1} start />
      <VerticalArrow x={x} y={y + 1} width={1} height={1}>
        <VerticalArrowPoint />
      </VerticalArrow>
    </>
  );
};

const ArrowContent = styled(GridDecorator)({
  borderStyle: 'solid',
  borderColor: Colors.BLACK,
  display: 'grid',
});

const HorizontalArrow = styled(ArrowContent)<{ start?: boolean }>(({ start }) => ({
  width: 'calc(50% - 4px)',
  height: '30px',
  marginBottom: '12px',
  alignSelf: 'start',
  justifySelf: start ? 'end' : 'start',
  borderWidth: start ? '0 0 2px 2px' : '0 2px 2px 0',
}));

const VerticalArrow = styled(ArrowContent)<{ start?: boolean }>(({ start }) => ({
  width: '30px',
  height: 'calc(50% - 4px)',
  marginRight: '12px',
  justifySelf: 'start',
  alignSelf: start ? 'end' : 'start',
  borderWidth: start ? '2px 2px 0 0' : '0 2px 2px 0',
}));

const ArrowPoint = styled('div')({
  padding: '3px',
  borderRight: `2px solid ${Colors.BLACK}`,
  borderTop: `2px solid ${Colors.BLACK}`,
});

const HorizontalArrowPoint = styled(ArrowPoint)({
  alignSelf: 'start',
  justifySelf: 'end',
  transform: 'translate(calc(50% + 1px), calc(-25% + 1px)) rotate(-45deg)',
});

const VerticalArrowPoint = styled(ArrowPoint)({
  alignSelf: 'end',
  justifySelf: 'start',
  transform: 'translate(calc(-25% + 1px), calc(50% + 1px)) rotate(-135deg)',
});
