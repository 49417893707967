import times from 'lodash/times';

import {
  Action,
  Deck,
  ParallelTransferAction,
  PromptAction,
  Rule,
} from 'common/types/mix';
import {
  LiquidMovement,
  MixPreview,
  MixPreviewFailed,
  MixPreviewStep,
  ParallelDispenseStep,
  ParallelTransferStep,
} from 'common/types/mixPreview';
import { StepsJson } from 'common/types/steps';

/** Format of the actions.json file before July 2020. */
type ActionsJsonV1 = { actions: Action[]; version: string };
/** Format of the actions.json file after July 2020. */
type ActionsJsonV2MultiTask = {
  tasks: {
    actions: Action[];
  }[];
  /**
   * Rules define what should happen under certain conditions (see the comment for Rule).
   */
  rules?: { [id: string]: Rule };
  version: string;
};
/**
 * Older Simulations have actions.json in the older format,
 * newer Simulations have actions.json in the newer format.
 * The UI must be able to render both formats.
 */
type ActionsJson = ActionsJsonV1 | ActionsJsonV2MultiTask;

/**
 * Format of the layout.json file.
 * It stays the same both before July 2020 and for multi-device workflows after July 2020.
 */
type LayoutJson = Deck;

/**
 * Handles both the older format (pre July 2020) of actions.json,
 * and the newer format (post July 2020) of actions.json.
 * Extracts a flat list of steps from either format.
 */
function getActionsFromJson(actionsJson: ActionsJson): Action[] {
  if ('tasks' in actionsJson) {
    // New format. Simply concat all the actions from all sub-tasks.
    return actionsJson.tasks.flatMap(task => task.actions);
  }
  // Old format - flat list of actions. Keep as is.
  return actionsJson.actions;
}

/**
 * Prior to Mar 2021, actions.json did not have rules.
 */
function getRulesFromJson(actionsJson: ActionsJson): { [id: string]: Rule } {
  if ('rules' in actionsJson) {
    return actionsJson.rules ?? {};
  }
  return {};
}

export function createMixPreview(
  layoutJson: LayoutJson,
  actionsJson: ActionsJson,
  stepsJson?: StepsJson,
  jobId?: string,
): MixPreview | MixPreviewFailed {
  return tryCreateMixPreviewFromResponse(
    jobId || 'NO_JOB_ID',
    layoutJson,
    actionsJson,
    stepsJson,
  );
}

/**
 * The total time for a preview is the final step's cumulative time estimate.
 */
export function getPreviewTotalDuration(steps: MixPreviewStep[]): number {
  return steps[steps.length - 1]?.cumulative_time_estimate || 0;
}

function tryCreateMixPreviewFromResponse(
  jobId: string,
  layoutJson: LayoutJson,
  actionsJson: ActionsJson,
  stepsJson?: StepsJson,
): MixPreview | MixPreviewFailed {
  const valid = validateMixPreviewResponse(layoutJson, actionsJson);
  if (!valid) {
    // Represents a failed response, along with the job it was requested for.
    // This happens when the job is very old (2018 and older) and doesn't have
    // any data for the preview.
    return { jobId, valid: false };
  }
  const steps = flattenGroupedTransfers(getActionsFromJson(actionsJson));
  // Add a step at the end to show the final state of the deck
  const finishedStep: PromptAction = {
    kind: 'prompt',
    message: 'Finished',
    time_estimate: 0,
    cumulative_time_estimate: getPreviewTotalDuration(steps),
  };
  return {
    jobId,
    // The layout.json file exactly matches our representation of the Deck
    deck: layoutJson,
    rules: getRulesFromJson(actionsJson),
    steps: [...steps, finishedStep],
    // Flag so we can match on this in the UI
    valid: true,
    instructions: stepsJson,
  };
}

/**
 * List of actions.json and layout.json versions supported by the preview.
 */
const SUPPORTED_SCHEMA_VERSIONS = [
  { actionsJson: '1.0', layoutJson: '1.0' },
  // Version 1.1 adds robocolumns filtration support to the schema. It is backward
  // compatible with 1.0.
  { actionsJson: '1.1', layoutJson: '1.1' },
  // In multi-device workflows (landed Jul 2020), actions.json have per-task structure
  // and version 2.0, layout.json stays in the original v1.1. format.
  { actionsJson: '2.0', layoutJson: '1.1' },
  // Actions v 2.1 adds stage names to each transfer (Jan 2021).
  { actionsJson: '2.1', layoutJson: '1.1' },
  // Actions v2.2 adds gripper arm to labware movements (Jul 2021)
  { actionsJson: '2.2', layoutJson: '1.1' },
  // Actions v2.3 adds well zone to PipettingOptions (Apr 2022)
  { actionsJson: '2.3', layoutJson: '1.1' },
];

function validateMixPreviewResponse(layout: LayoutJson, actions: ActionsJson): boolean {
  if (!actions) {
    console.warn('actions.json not found');
    return false;
  }
  if (!layout) {
    console.warn('layout.json not found');
    return false;
  }
  // For computational workflows, they may return an actions/layout pair, but they have no actual
  // data in them.  Check if the deck is empty, which indicates a simulation that contains no liquid
  // handling.
  if (Object.keys(layout.before.positions).length === 0) {
    console.warn('layout.json contains no positions');
    return false;
  }

  for (const supportedVersionsCombination of SUPPORTED_SCHEMA_VERSIONS) {
    if (
      actions.version === supportedVersionsCombination.actionsJson &&
      layout.version === supportedVersionsCombination.layoutJson
    ) {
      return true;
    }
  }
  console.warn(
    `The combination of actions.json version ${actions.version} ` +
      `and layout.json version ${layout.version} is not supported.`,
  );
  return false;
}

/**
 * Transfers within actions.json can contain multiple sub-actions, including
 * parallel transfers (which themselves can contain multiple dispenses), tip
 * washes, tip loading/unloading, and refreshing tipboxes. In the UI we wish to
 * break this up into multiple steps.
 */
function flattenGroupedTransfers(steps: Action[]): MixPreviewStep[] {
  const expandedSteps = [];
  for (const step of steps) {
    if (step.kind === 'transfer') {
      // A 'transfer' step contains a group of atomic steps, such as tip
      // washing, parallel transfers, and refreshing tipboxes.
      for (const child of step.children) {
        if (child.kind === 'parallel_transfer') {
          // A parallel transfer can contain multiple dispenses for a single
          // aspirate. In the UI we wish to display these dispenses separately,
          // so we expend each dispense into a separate step.
          expandedSteps.push(...flattenMultiDispense(child));
        } else {
          expandedSteps.push(child);
        }
      }
    } else {
      // Leave other steps as they are
      expandedSteps.push(step);
    }
  }
  // The tip unload actions (dropping tips in tip waste) are not interesting
  // for the user. We need to remove them here (not in MixState), so that
  // the total number of steps goes down.
  return removeUninterestingSteps(expandedSteps);
}

type ParallelTransferOrDispenseStep = ParallelTransferStep | ParallelDispenseStep;

/**
 * Some transfers may have multiple dispenses. For example, the tip aspirates
 * 600ul, then dispenses 200ul to A1, 200ul to A2, and 200ul to A3. To show each
 * of these in a single preview step would lead to information overload (ie too
 * many arrows). This function breaks up multi-dispenses into separate
 * transfers, which show in the preview as separate arrows from the location of
 * the original aspirate, to the location of the each dispense.
 */
export function flattenMultiDispense(
  transfer: ParallelTransferAction,
): ParallelTransferOrDispenseStep[] {
  // Get the number of dispenses within this transfer. The schema allows each
  // channel to specify a separate number of `to` destinations, so we need to
  // check each to find out the number of dispenses.
  const multiDispenseCount = Math.max(
    ...Object.values(transfer.channels).map(({ to }) => to.length),
  );

  // The current actions.json schema doesn't break down the time spent at each
  // dispense. This is due to be improved when we review the schema. For now, we
  // can divide the total time_estimate by the number of dispenses to get a
  // rough estimate suitable for the preview.
  const timeForEachDispense = transfer.time_estimate / multiDispenseCount;
  const timeAtStartOfTransfer =
    transfer.cumulative_time_estimate - transfer.time_estimate;

  // Create a new parallel transfer step for each dispense
  return times<ParallelTransferOrDispenseStep>(multiDispenseCount, multiDispenseIndex => {
    const channels: Record<string, LiquidMovement> = {};
    // Add the dispense for each channel
    for (const [channel, action] of Object.entries(transfer.channels)) {
      const destination = action.to[multiDispenseIndex];
      if (!destination) {
        // If destination is null, then this channel is not doing anything during this
        // dispense.
        continue;
      }
      channels[channel] = {
        // Copy across all the liquid properties from the original parallel
        // transfer (including `from`)
        ...action,
        // If there's a filter, use that. Otherwise it's the same as liquidDestination
        tipDestination: destination.filter ?? destination,
        // Final destination of the liquid
        liquidDestination: destination,
        // actions.json v1.1 has a volume attached to each `to` destination. For
        // v1.0, fallback to volume defined in the transfer.
        volume: destination.volume_change ?? action.volume,
        multiDispenseIndex,
        multiDispenseCount,
      };
    }
    return {
      // The first dispense is a complete transfer - i.e has a 'from' and 'to'.
      // For remaining dispenses, indicate they are only dispenses so should be
      // visualised differently in the UI.
      kind: multiDispenseIndex === 0 ? 'parallel_transfer' : 'parallel_dispense',
      channels,
      time_estimate: timeForEachDispense,
      cumulative_time_estimate:
        timeAtStartOfTransfer + timeForEachDispense * (multiDispenseIndex + 1),
    };
  });
}

function removeUninterestingSteps(expandedSteps: MixPreviewStep[]): MixPreviewStep[] {
  const indexOfLastStep = expandedSteps.length - 1;
  // We need to remove the steps here (not filter later in MixState), so that
  // the total number of steps goes down.
  return expandedSteps.filter((step, index) => {
    if (step.kind === 'prompt' && step.message === '___BARRIER___') {
      // The "___BARRIER___" is an artificial prompt that element developers use
      // as substitute for a better way to influence the ordering of actions
      // produced by the Antha scheduler.
      // There is no better way to detect this prompt than by checking the
      // message currently.
      return false;
    }
    if (step.kind === 'unload') {
      if (index === indexOfLastStep) {
        // The very last step is likely an 'unload'. We want to keep this single
        // 'unload' step at the very end, so that the time estimate of the last
        // step matches the time estimate of the Report object, shown in the
        // header.
        return true;
      } else {
        // The tip unload actions are not interesting for the user. Usually,
        // tips are simply dropped into the tipwaste. Even though the unload
        // action allows for tips to be returned back into a tipbox, Haydn says
        // that returning and reusing those tips would be a really bad practice,
        // and the antha-lang simulator doesn't do that.
        // Summary: Once a tip is loaded, we show it's missing from the box.
        // After a tip is loaded, from the user's perspective, that tip is gone.
        // It will be unloaded somewhere later, but we don't display this
        // because unloading doesn't matter to the user.
        return false;
      }
    }
    return true;
  });
}

export function getStageNameForStep(step: ParallelTransferStep): string | undefined {
  // The actions.json schema allows a stage name to be different for each channels. In
  // practice, all channels will have the same stage name. So just return the stage_name
  // for any channel.
  return Object.values(step.channels)[0]?.stage_name;
}
