import React, { useCallback } from 'react';

import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import KebabMenuIcon from '@mui/icons-material/MoreVert';
import Fade from '@mui/material/Fade';
import Menu, { MenuProps } from '@mui/material/Menu';
import { Link } from 'react-router-dom';

import { useCreateDOETemplate } from 'client/app/api/DOETemplateApi';
import { getLiquidHandlingTasks } from 'client/app/apps/simulation-details/dataUtils';
import { downloadTaskInstructionFiles } from 'client/app/apps/simulation-details/dataUtils';
import { useFetchLiquidHandlingInstructions } from 'client/app/apps/simulation-details/overview/simulationDetailsFiles';
import { SimulationQuery } from 'client/app/gql';
import { WorkflowSourceEnum } from 'client/app/gql';
import useRequestHelpForSimulation from 'client/app/hooks/useRequestHelpForSimulation';
import { doeTemplateRoutes, templateWorkflowRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { IntercomTourIDs } from 'common/lib/intercom';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import DOEIcon from 'common/ui/icons/DOEIcon';
import { TalkToUsIcon } from 'common/ui/icons/TalkToUs';
import { TemplateWorkflowIcon } from 'common/ui/icons/TemplateWorkflow';

type Props = {
  simulation: SimulationQuery['simulation'];
  onLoadingChange: (isLoading: boolean) => void;
  showCreateDOEButton: boolean;
  showDownloadInstructionsButton: boolean;
};

/**
 * The button in the Experiments screen that allows the user to create
 * a new Experiment, as well as a few different types of Workflows.
 */
export default function SimulationHeaderDropdown({
  simulation,
  onLoadingChange,
  showCreateDOEButton,
  showDownloadInstructionsButton,
}: Props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const snackbarManager = useSnackbarManager();
  const { navigate } = useNavigation();

  const createDOETemplate = useCreateDOETemplate();

  const handleCreateDOE = useCallback(async () => {
    closeMenu();
    onLoadingChange(true);
    try {
      const workflowId = await createDOETemplate(simulation.id);
      navigate(doeTemplateRoutes.edit, { workflowId });
    } catch (error) {
      snackbarManager.showError(
        `Could not create DOE template from this simulation: ${error.message}`,
      );
    } finally {
      onLoadingChange(false);
    }
  }, [createDOETemplate, navigate, onLoadingChange, simulation.id, snackbarManager]);

  const { requestHelpDialog, handleRequestHelp } = useRequestHelpForSimulation(
    ScreenRegistry.SIMULATION_DETAILS,
    simulation.id,
    simulation.name,
  );

  const fetchLiquidHandlingInstructions = useFetchLiquidHandlingInstructions();
  const handleDownloadInstructions = () => {
    const mixTasks = getLiquidHandlingTasks(simulation.tasks);

    if (mixTasks && mixTasks.length > 0) {
      void fetchLiquidHandlingInstructions(simulation.id, simulation.name, mixTasks).then(
        downloadTaskInstructionFiles,
      );
    }
  };

  const closeMenuAndRequestHelp = () => {
    closeMenu();
    void handleRequestHelp();
  };

  const classes = useStyles();

  return (
    <div className={classes.box}>
      <IconButton
        size="small"
        className={classes.menuIcon}
        onClick={handleOpenMenu}
        icon={<KebabMenuIcon />}
        data-intercom-target={`${IntercomTourIDs.SIMULATION_OPTIONS}-simulation-menu`}
      />
      <DropdownMenu anchorEl={anchorEl} keepMounted open={!!anchorEl} onClose={closeMenu}>
        {simulation.status === 'COMPLETED' &&
          simulation.workflow.source !== WorkflowSourceEnum.FORM_EDITOR && (
            <Link
              data-heap-tracking="simulation-save-as-template-button"
              to={templateWorkflowRoutes.createFromSimulation.getPath({
                simulationId: simulation.id,
              })}
              className={classes.menuItemLink}
            >
              <MenuItemWithIcon icon={<TemplateWorkflowIcon />} text="Save as template" />
            </Link>
          )}
        {showCreateDOEButton && (
          <MenuItemWithIcon
            icon={<DOEIcon />}
            text="Create DOE"
            onClick={handleCreateDOE}
          />
        )}
        {showDownloadInstructionsButton && (
          <MenuItemWithIcon
            icon={<GetAppOutlinedIcon />}
            text="Download instructions"
            onClick={handleDownloadInstructions}
          />
        )}
        <MenuItemWithIcon
          icon={<TalkToUsIcon />}
          text="Request help"
          onClick={closeMenuAndRequestHelp}
        />
      </DropdownMenu>
      {requestHelpDialog}
    </div>
  );
}

function DropdownMenu(props: MenuProps) {
  return (
    <Menu
      TransitionComponent={Fade}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  );
}

const useStyles = makeStylesHook({
  box: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuIcon: {
    color: Colors.GREY_80,
  },
  menuItemLink: {
    // We want to make each menu item look like normal a clickable row,
    // even though it's a hyperlink.
    textDecoration: 'none',
    color: 'inherit',
  },
});
