import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';

import {
  QUERY_METHOD_DEPENDENCIES,
  QUERY_WORK_TREE_APPLICATION,
} from 'client/app/apps/work-tree/queries';
import PlatformHeader from 'client/app/components/nav/PlatformHeader';
import { NavigateBackBannerHref } from 'client/app/components/NavigateBackBanner';
import VisserverIFrame from 'client/app/components/VisserverIFrame';
import { WorkTreeApplicationName } from 'client/app/gql';
import { VisViewRouteProps } from 'client/app/lib/nav/actions';
import setBrowserTabTitle from 'client/app/lib/setBrowserTabTitle';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import LinearProgress from 'common/ui/components/LinearProgress';

type Props = VisViewRouteProps & {
  params: URLSearchParams;
};

/**
 * Displays a visserver visualization in an iframe, with a header detailing
 * simulation details.
 */
export default function Visualization({ visserverApp, params }: Props) {
  const [visserverLoading, setVisserverLoading] = useState<boolean>(true);
  const isEnabledBreadcrumbs = useFeatureToggle('BREADCRUMBS');

  const methodId = params.has('method_id') ? params.get('method_id') : undefined;

  // This query will return all dependencies of a method and allow the
  // user to navigate back to them. Currently we don't have the UI to
  // allow selection of more than one, so we will use the first dependency
  // returned. Currently we are also limiting this to only
  // navigate back to doe_prepare_data view from doe_analyse_responses view
  const isResponseAnalysisApp =
    visserverApp === WorkTreeApplicationName.doe_analyse_responses;
  const { data, loading: dependenciesLoading } = useQuery(
    QUERY_METHOD_DEPENDENCIES,
    methodId && isResponseAnalysisApp
      ? { variables: { id: methodId as UUID } }
      : { skip: true },
  );

  return (
    <>
      {methodId ? (
        <MethodHeader methodId={methodId as MethodId} />
      ) : (
        <VisualizationHeader />
      )}
      {(visserverLoading || dependenciesLoading) && <LinearProgress />}
      {!isEnabledBreadcrumbs &&
        !dependenciesLoading &&
        data?.workTreeGetDependencies?.[0] && (
          <NavigateBackBannerHref
            href={data.workTreeGetDependencies[0].href}
            // TODO - Return the display name formatted correctly (rather than hard-coding).
            // Currently we show this only in doe_analyse_responses so it will always be
            // a doe_prepare_data, but we could refactor to return correct names from server.
            text="View Prepare Data"
          />
        )}
      <VisserverIFrame
        view={visserverApp}
        params={params}
        onReady={() => setVisserverLoading(false)}
      />
    </>
  );
}

/**
 * If a method_id is in the URL, we can get the name of the vis application from the
 * method.
 */
function MethodHeader({ methodId }: { methodId: MethodId }) {
  const { data } = useQuery(QUERY_WORK_TREE_APPLICATION, { variables: { methodId } });

  const title = data?.workTreeApplication?.applicationDisplayName ?? 'Visualization view';

  useEffect(() => {
    setBrowserTabTitle(title);
  }, [title]);

  return (
    <PlatformHeader experimentChipPickerProps={{ methodId }}>
      <Typography variant="h5">{title}</Typography>
    </PlatformHeader>
  );
}

function VisualizationHeader() {
  useEffect(() => {
    setBrowserTabTitle('Visualization view');
  }, []);

  return <PlatformHeader />;
}
