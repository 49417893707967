import React, { useCallback } from 'react';

import cx from 'classnames';

import { ReleaseQualityIndicator } from 'client/app/apps/workflow-builder/lib/ReleaseQualityIndicator';
import { useElementNamePopover } from 'client/app/apps/workflow-builder/panels/element-instance-panel/hooks';
import Panel from 'client/app/apps/workflow-builder/panels/Panel';
import DOEElementParameterList from 'client/app/components/Parameters/DOEElementParameterList';
import { ReleaseQualityEnum } from 'client/app/gql';
import { getElementDisplayName } from 'client/app/lib/workflow/elementConfigUtils';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { ElementInstance, FactorItem } from 'common/types/bundle';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  isReadonly?: boolean;
  className?: string;
  workflowId: string;
  elementInstance: ElementInstance;
};

const NO_FACTORS: FactorItem[] = [];

const DOEElementInstancePanel = ({ isReadonly, className, elementInstance }: Props) => {
  const factors = useWorkflowBuilderSelector(state => state.factors);
  const factorisedParameters = useWorkflowBuilderSelector(
    state => state.factorisedParameters,
  );
  const workflowName = useWorkflowBuilderSelector(state => state.workflowName);
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();

  const element = elementInstance.element;

  const elementDisplayName = getElementDisplayName(elementInstance.element, false);

  const { elementNamePopover, ...elementNamePopoverEvents } =
    useElementNamePopover(elementDisplayName);

  const handleClose = useCallback(() => {
    dispatch({ type: 'deselectAll' });
    dispatch({
      type: 'toggleFactorEditing',
      payload: {
        selectedFactorElement: undefined,
        selectedFactorParameter: undefined,
      },
    });
  }, [dispatch]);

  return (
    <Panel
      title={elementDisplayName}
      className={cx(classes.container, className)}
      titleIcon={
        <ReleaseQualityIndicator
          releaseQuality={element.releaseQuality as ReleaseQualityEnum}
        />
      }
      panelContent="DOEElementInstance"
      onClose={handleClose}
      {...elementNamePopoverEvents}
    >
      <DOEElementParameterList
        factors={factors ?? NO_FACTORS}
        parameters={element.inputs}
        factorisedParameters={factorisedParameters}
        workflowName={workflowName}
        instanceName={elementInstance.name}
        elementId={element.id}
        isReadonly={isReadonly}
      />
      {elementNamePopover}
    </Panel>
  );
};

const useStyles = makeStylesHook(() => ({
  container: {
    gridArea: 'instancePanel',
    height: '100%',
    justifySelf: 'end',
    zIndex: 2,
  },
}));

export default DOEElementInstancePanel;
