import React, { ReactNode } from 'react';

import Skeleton from '@mui/material/Skeleton';
import { lighten } from '@mui/material/styles';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = { icon: ReactNode; className?: string; color?: string; disabled?: boolean };
export default function CardType({
  icon,
  className,
  color = Colors.GREEN_80,
  disabled,
}: Props) {
  const classes = useStyles();

  const updatedColor = disabled ? lighten(color, 0.7) : color;
  const backgroundColor = color ? lighten(color, 0.8) : Colors.GREEN_TERTIARY_0;
  const updatedBackgroundColor = disabled
    ? lighten(backgroundColor, 0.7)
    : backgroundColor;

  return (
    <div
      className={cx(classes.cardTypeBase, classes.cardType, className)}
      style={{ backgroundColor: updatedBackgroundColor, color: updatedColor }}
    >
      {icon}
    </div>
  );
}

export function CardTypeSkeleton() {
  const classes = useStyles();
  return <Skeleton className={classes.cardTypeBase} />;
}

const useStyles = makeStylesHook({
  cardTypeBase: {
    position: 'relative',
    top: '-20px',
    width: '24px',
    height: '28px',
    borderRadius: '0px 0px 4px 4px',
  },
  cardType: {
    // Align icon in the middle
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    justifySelf: 'center', // Center in the grid column
  },
});
