import { computeDragAndDropLandingPosition } from 'client/app/apps/workflow-builder/lib/dragAndDropElementsHelper';
import { Position2d } from 'common/types/Position';
import { arePointsClose } from 'common/ui/lib/position';

/**
 * How far do we have to move the workspace to break current elements cascade.
 *
 * When user add an element it adds it near top left of visible workspace, and then does cascade
 * We keep the same cascade unless the workspace has been drag far enough.
 */
const MAX_DISTANCE = 150;

/**
 * The maximum number of elements we want to have in a cascade.
 */
const MAX_ELEMENTS_IN_CASCADE = 8;

/**
 * This is used by the Workflow Builder to know where to add a new element so it makes a nice cascade when many elements are added after each other.
 * It tracks the position of the underlying Workspace (with onPositionChange) to know where and if it needs to start a new cascade.
 *
 * Warning: It was not mean to be very reusable and is its own module to not pollute too much the already giant WorkflowBuilder.tsx.
 */
export default class ElementsCascadeTracker {
  private latestElementPosition = { x: 0, y: 0 };
  private index = 0;
  private zoom = 1;

  /** Current position of the workspace, we use it to know where to position new element
   * This is not used in render, so there is no need to put it in state.
   */
  private workspacePosition: Position2d = { x: 0, y: 0 };

  public getWorkspacePosition(): Position2d {
    return this.workspacePosition;
  }

  public onPositionChange = (position: Position2d, zoom: number) => {
    const { x, y } = position;
    // The position from workspace represent where it has been dragged after zoom.
    // We need to take the negative because
    // the position from workspace are opposed.
    // i.e. When you drag workspace left, the workspace is moved right.
    // Then we also need to scale back by dividing by zoom.
    this.workspacePosition = { x: -x / zoom, y: -y / zoom };
    this.zoom = zoom;
  };

  public getPositionAndIndexForNewElement(
    workflowLayoutRef: React.MutableRefObject<HTMLDivElement | null>,
    droppedAtPosition?: Position2d,
  ) {
    const currentPosition = {
      x: this.workspacePosition.x,
      y: this.workspacePosition.y,
    };
    if (!arePointsClose(currentPosition, this.latestElementPosition, MAX_DISTANCE)) {
      // Workspace has been move too far, reset index and cascade start position.
      this.index = 0;
      this.latestElementPosition = currentPosition;
    }
    const zoomAndIndexToReturn = { zoom: this.zoom, index: this.index };
    if (droppedAtPosition) {
      const targetPosition = computeDragAndDropLandingPosition(
        droppedAtPosition,
        this.zoom,
        this.workspacePosition,
        workflowLayoutRef,
      );
      return {
        targetPosition,
        ...zoomAndIndexToReturn,
      };
    } else {
      this.index++;

      // Initial offsets for starting the cascade.
      const initialXPositionOffset = 500;
      const initialYPositionOffset = 50;

      // Returns the position of the element in the current cascade.
      const elementPlacementInCascade = this.index % MAX_ELEMENTS_IN_CASCADE;

      // Calculates the positional offsets for the x and y relative to the
      // elements' current position in the cascade.
      // We always want y offset to be greater than x.
      const offsetXForNextElement = 25 * elementPlacementInCascade;
      const offsetYForNextElement = 50 * elementPlacementInCascade;

      // Once we get to the end of a cascade, we need to adjust the x position
      // to prevent direct overlapping.
      const offsetXForNextCascade = 50 * Math.floor(this.index / MAX_ELEMENTS_IN_CASCADE);

      const adjustedPosition = {
        x:
          this.latestElementPosition.x +
          (initialXPositionOffset + offsetXForNextElement + offsetXForNextCascade) /
            this.zoom,
        y: Math.floor(
          this.latestElementPosition.y +
            (initialYPositionOffset + offsetYForNextElement) / this.zoom,
        ),
      };
      return {
        targetPosition: adjustedPosition,
        ...zoomAndIndexToReturn,
      };
    }
  }
}
