import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { QUERY_DEVICE_CONFIG_RESPONSE } from 'client/app/api/gql/queries';
import LabwareSelector from 'client/app/apps/workflow-builder/LabwareSelector';
import isWorkflowReadonly from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import { PanelWithoutScroll } from 'client/app/apps/workflow-builder/panels/Panel';
import DeckLayoutInitialPopper from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/DeckLayoutInitialPopper';
import DeckLayoutWithPreferences from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/DeckLayoutWithPreferences';
import {
  DEFAULT_LAYOUT_LABEL,
  formatValidAddresses,
  RunConfiguration,
  useGetDeviceRunConfigs,
  useGetSelectedMainDevice,
  useIsResetButtonEnabled,
  useParseDeviceRunConfig,
  useSelectedRunConfig,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import { ScreenRegistry } from 'client/app/registry';
import {
  formatLabwareTypeName,
  getNumberOfPositions,
} from 'client/app/state/LabwarePreference';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import doNothing from 'common/lib/doNothing';
import { getAnthaConfigKeyByDeviceClass } from 'common/types/bundleTransforms';
import { DeckState } from 'common/types/mix';
import { Option } from 'common/types/Option';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import IconButton from 'common/ui/components/IconButton';
import IconButtonWithPopper from 'common/ui/components/IconButtonWithPopper';
import Tooltip from 'common/ui/components/Tooltip';
import Workspace from 'common/ui/components/Workspace/Workspace';
import Dropdown from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

type DeckOptionsPanelProps = {
  onClose: () => void;
  className: string;
};

/**
 * DeckOptionsPanel allows the user to be able to select the proper deck layout
 * and set the preferences.
 */
export default React.memo(function DeckOptionsPanel(props: DeckOptionsPanelProps) {
  const { onClose, className } = props;
  const classes = useStyles();
  const dispatch = useWorkflowBuilderDispatch();

  const { isReadonly, labwarePreferenceType, numberOfPositions } =
    useWorkflowBuilderSelector(state => ({
      isReadonly: isWorkflowReadonly(state.editMode, state.source),
      labwarePreferenceType: state.labwarePreferenceType,
      numberOfPositions: state.labwarePreferenceType
        ? getNumberOfPositions(state, state.labwarePreferenceType)
        : 0,
    }));

  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const { selectedDevice, deviceId } = useGetSelectedMainDevice();
  const { loading: loadingAllRunConfigs, allRunConfigOptions } = useGetDeviceRunConfigs();
  const apollo = useApolloClient();

  // A user could switch config quickly and we would have multiple parallel fetch.
  // To avoid setting load to false when an old fetch is resolved we compare the requestId
  const [loadingSelectedRunConfig, setLoadingSelectedRunConfig] = useState<
    false | string
  >(false);

  const { selectedRunConfigId, selectedRunConfig } = useSelectedRunConfig();

  const [confirmRunConfigChangeDialog, openConfirmRunConfigChangeDialog] =
    useDialog(ConfirmationDialog);

  const {
    loading: loadingRunConfig,
    data: runConfigData,
    error: runConfigError,
  } = useParseDeviceRunConfig(deviceId, selectedRunConfigId);

  const handleSelectConfig = useCallback(
    async (newRunConfiguration?: RunConfiguration) => {
      if (!newRunConfiguration || !selectedDevice) {
        return;
      }

      // If a run config was previously selected, ask the user for confirmation.
      if (selectedRunConfigId) {
        const confirmed = await openConfirmRunConfigChangeDialog({
          object: 'deck layout',
          action: 'switch',
          additionalMessage:
            'You will lose the changes you have made to labware positions and to any positions specified in Move Plates elements. Please select the positions again.',
          isActionDestructive: true,
        });
        if (!confirmed) {
          return;
        }
      }
      const newRunConfigId = newRunConfiguration.value;
      // We should consider  quering the QUERY_DEVICE_CONFIG_RESPONSE just before showing the `confirmRunConfigChangeDialog above.
      // This would allow to show the new selected run config a lot quickier once the user confirm the switch.
      const requestId = deviceId + ':' + newRunConfigId;
      setLoadingSelectedRunConfig(requestId);
      const newRunConfigResponse = await apollo
        .query({
          query: QUERY_DEVICE_CONFIG_RESPONSE,
          variables: { id: deviceId, runConfigId: newRunConfigId },
        })
        .catch(() => null); // Do not crash if no default.

      const newSelectedRunConfig = allRunConfigOptions.find(
        config => config.value === newRunConfigId,
      );
      if (!newSelectedRunConfig) {
        // This should never happen.
        // User cannot select a run config that does not exist.
        return;
      }

      dispatch({
        type: 'setDeviceRunConfig',
        payload: {
          deviceId,
          runConfigId: newRunConfigId,
          runConfigVersion: newSelectedRunConfig.version,
          deviceClassKey: getAnthaConfigKeyByDeviceClass(
            selectedDevice?.anthaLangDeviceClass,
          ),
          runConfiguration: newRunConfigResponse?.data,
        },
      });
      setLoadingSelectedRunConfig(current => {
        return current === requestId ? false : current;
      });
    },
    [
      selectedDevice,
      selectedRunConfigId,
      deviceId,
      apollo,
      allRunConfigOptions,
      dispatch,
      openConfirmRunConfigChangeDialog,
    ],
  );

  useEffect(() => {
    // If no run config is selected, and there is only one config available, select it.
    if (!selectedRunConfigId && allRunConfigOptions.length === 1) {
      void handleSelectConfig(allRunConfigOptions[0]);
    }
  }, [allRunConfigOptions, handleSelectConfig, selectedRunConfigId]);

  const areConfigsLoading =
    loadingAllRunConfigs || loadingRunConfig || loadingSelectedRunConfig;
  const deckState = runConfigData?.parsedRunConfig.config.layout as DeckState;

  const validAddresses = runConfigData?.parsedRunConfig.config.validAddresses;
  const validLayoutOptions =
    !areConfigsLoading && deckState && validAddresses
      ? formatValidAddresses(validAddresses)
      : undefined;

  const handleResetConfigWithDefaults = useCallback(async () => {
    const confirmed = await openConfirmationDialog({
      object: 'deck positions',
      action: 'reset',
      additionalMessage:
        'Resetting will restore the default labware positions for this deck configuration.',
      cancelButtonLabel: 'cancel',
      confirmButtonLabel: 'reset',
      isActionDestructive: true,
    });
    if (!confirmed) {
      return;
    }

    dispatch({
      type: 'setDeviceRunConfig',
      payload: {
        deviceId,
        runConfigId: selectedRunConfigId ?? null,
        runConfigVersion: selectedRunConfig.version,
        deviceClassKey: getAnthaConfigKeyByDeviceClass(
          selectedDevice?.anthaLangDeviceClass,
        ),
        runConfiguration: runConfigData,
      },
    });
  }, [
    deviceId,
    dispatch,
    openConfirmationDialog,
    runConfigData,
    selectedDevice?.anthaLangDeviceClass,
    selectedRunConfig.version,
    selectedRunConfigId,
  ]);

  const handleRemoveAllLabwareTypePreferences = useCallback(() => {
    labwarePreferenceType &&
      dispatch({
        type: 'removeAllLabwareTypePreferences',
        payload: labwarePreferenceType,
      });
  }, [dispatch, labwarePreferenceType]);

  const showClearAllPreferencesButton = labwarePreferenceType && !isReadonly;
  const showLabwareSelector =
    selectedRunConfigId || selectedDevice?.anthaLangDeviceClass === 'GilsonPipetMax';
  const isResetButtonEnabled = useIsResetButtonEnabled(loadingSelectedRunConfig);
  const shouldShowDeckLayoutInitialPopper = useMemo(() => {
    return (
      !isReadonly &&
      !selectedRunConfigId &&
      allRunConfigOptions.length > 1 &&
      selectedDevice?.anthaLangDeviceClass !== 'GilsonPipetMax'
    );
  }, [
    allRunConfigOptions.length,
    isReadonly,
    selectedDevice?.anthaLangDeviceClass,
    selectedRunConfigId,
  ]);

  const formattedRunConfigurationOptions = useMemo<Option<RunConfiguration>[]>(() => {
    return allRunConfigOptions.map(runConfiguration => {
      return {
        label: runConfiguration.label,
        value: runConfiguration,
      };
    });
  }, [allRunConfigOptions]);

  const renderLabel = useCallback(
    (option: Option<RunConfiguration>) => {
      return (
        <div className={classes.label}>
          <Typography variant="subtitle2">{option.value.label}</Typography>
          {option.value.lastModifiedAt && (
            <Typography variant="body1">
              &nbsp;{`- Updated ${option.value.lastModifiedAt.format('ll')}`}
            </Typography>
          )}
        </div>
      );
    },
    [classes.label],
  );

  const renderValue = useCallback((value: any) => {
    return value;
  }, []);

  // Scenarios:
  // Loading - show a circular progress bar
  // No run config selected, but deck state is available since it is default (e.g. Gilson)
  // No run config selected, but deck state is not available since you need to select run config.
  // Run config selected, deck state is available
  // Run Config selected, deck state is unavailable.
  // Error - Show deck layout unavailable
  let panelContent;
  if (areConfigsLoading) {
    panelContent = (
      <div className={classes.panelContainer}>
        <CircularProgress />
      </div>
    );
  } else if (shouldShowDeckLayoutInitialPopper) {
    panelContent = (
      <div className={classes.deckLayout}>
        <Workspace
          isShowAllButtonVisible={false}
          isShowHelpButtonVisible={false}
          canvasControlVariant="light_float"
          logCategory={ScreenRegistry.WORKFLOW}
        >
          <div />
        </Workspace>
      </div>
    );
  } else if (validLayoutOptions) {
    panelContent = (
      <div className={classes.deckLayout}>
        <DeckLayoutWithPreferences
          deckState={deckState}
          validLayoutOptions={validLayoutOptions}
        />
      </div>
    );
  } else {
    panelContent = (
      <div className={classes.panelContainer}>
        <div className={classes.errorWrapper}>
          <Typography variant="subtitle2">Error with selected deck layout: </Typography>
          <Typography variant="subtitle2" className={classes.errorMessage}>
            {runConfigError?.message ?? 'Deck layout is currently unavailable.'}
          </Typography>
        </div>
      </div>
    );
  }

  // Allow to open the helper text when a deck layout hasn't been selected.
  const dropdownRef = useRef(null);

  return (
    <PanelWithoutScroll
      title="Deck options"
      className={className}
      onClose={onClose}
      onCloseVariant="done"
      panelContent="DeckOptions"
      fullWidth
    >
      <>
        <div className={classes.selectorsContainer}>
          <div className={classes.selectorWithLabel}>
            <Typography variant="subtitle2">Deck layout</Typography>
            <div className={classes.selectorContainer} ref={dropdownRef}>
              {formattedRunConfigurationOptions.length > 0 ? (
                <>
                  <Dropdown<RunConfiguration>
                    className={classes.dropdown}
                    valueLabel={
                      selectedRunConfig?.label === DEFAULT_LAYOUT_LABEL
                        ? ''
                        : selectedRunConfig?.label
                    }
                    options={formattedRunConfigurationOptions}
                    renderLabel={renderLabel}
                    renderValue={renderValue}
                    onChange={handleSelectConfig}
                    placeholder="Deck Layout"
                    isDisabled={isReadonly}
                    disableUnderline
                    variant="standard"
                    size="medium"
                  />
                  <DeckLayoutInitialPopper
                    anchorEl={dropdownRef.current}
                    open={shouldShowDeckLayoutInitialPopper}
                  />
                </>
              ) : (
                <Typography noWrap variant="subtitle2">
                  {((loadingSelectedRunConfig && 'Loading...') ||
                    selectedRunConfig?.label) ??
                    'Loading...'}
                </Typography>
              )}
              {!isReadonly && (
                <Tooltip title="Reset defaults">
                  <span>
                    <IconButton
                      className={classes.resetButton}
                      size="small"
                      icon={<AutorenewIcon />}
                      onClick={handleResetConfigWithDefaults}
                      disabled={!isResetButtonEnabled}
                    />
                  </span>
                </Tooltip>
              )}
            </div>
          </div>
          {showLabwareSelector && (
            <div className={classes.selectorWithLabel}>
              <Typography variant="subtitle2">Labware to allocate</Typography>
              <div className={cx(classes.selectorContainer, classes.labwareSelectors)}>
                <LabwareSelector validLayoutOptions={validLayoutOptions} />
                <Divider className={classes.divider} orientation="vertical" />
                <IconButtonWithPopper
                  content={
                    <>
                      <Typography variant="caption">
                        <Typography className={classes.header} variant="caption">
                          Input Plates:&nbsp;
                        </Typography>
                        Plates that contain the liquids you have at the start of your
                        workflow.
                      </Typography>
                      <br />
                      <Typography variant="caption">
                        <Typography className={classes.header} variant="caption">
                          Output Plates:&nbsp;
                        </Typography>
                        Plates that contain the output of some liquid handling.
                      </Typography>
                      <br />
                      <Typography variant="caption">
                        <Typography className={classes.header} variant="caption">
                          Temporary Locations:&nbsp;
                        </Typography>
                        Deck positions to which your robot arm will move the plates in
                        your hotels when they are needed.
                      </Typography>
                      <br />
                      <Typography variant="caption">
                        <Typography className={classes.header} variant="caption">
                          Tip Boxes:&nbsp;
                        </Typography>
                        Boxes that contain disposable tips.
                      </Typography>
                      <br />
                      <Typography variant="caption">
                        <Typography className={classes.header} variant="caption">
                          Tip Wastes:&nbsp;
                        </Typography>
                        Chutes in which your liquid handler will dispose of used tips.
                      </Typography>
                    </>
                  }
                  iconButtonProps={{ size: 'small', icon: <HelpOutlineIcon /> }}
                  onClick={doNothing} //TODO: Update with logging
                />
                {showClearAllPreferencesButton && (
                  <>
                    <Divider className={classes.divider} orientation="vertical" />
                    <Button
                      className={classes.clearButton}
                      variant="tertiary"
                      onClick={handleRemoveAllLabwareTypePreferences}
                      disabled={numberOfPositions === 0}
                    >
                      {`Clear all ${formatLabwareTypeName(labwarePreferenceType)}`}
                    </Button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        {confirmationDialog}
        {confirmRunConfigChangeDialog}
        {panelContent}
      </>
    </PanelWithoutScroll>
  );
});

const useStyles = makeStylesHook(theme => ({
  header: {
    fontWeight: 'bold',
  },
  panelContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
  },
  selectorContainer: {
    alignItems: 'center',
    backgroundColor: Colors.GREY_10,
    borderRadius: '4px',
    display: 'flex',
    height: '48px',
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  selectorWithLabel: {
    alignItems: 'start',
    display: 'flex',
    flexDirection: 'column',
  },
  selectorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(5),
    gap: theme.spacing(3),
  },
  clearButton: {
    whiteSpace: 'nowrap',
  },
  deckLayout: {
    border: `1px solid ${Colors.BLUE_5}`,
    height: '550px',
    flex: '1 1 auto',
    minHeight: 0,
  },
  divider: {
    height: '16px',
    margin: theme.spacing(0, 2),
  },
  dropdown: {
    backgroundColor: Colors.GREY_0,
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '4px',
    marginRight: theme.spacing(3),
    padding: theme.spacing(0, 4),
    width: '220px',
    '& .MuiInputBase-input:focus': {
      backgroundColor: Colors.GREY_0,
    },
    '& .MuiSelect-icon': {
      color: Colors.TEXT_PRIMARY,
      marginRight: theme.spacing(3),
    },
  },
  errorMessage: {
    color: Colors.ERROR_MAIN,
  },
  errorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  labwareSelectors: {
    gap: theme.spacing(0, 2),
    '& hr:first-of-type': {
      marginLeft: theme.spacing(4),
    },
  },
  resetButton: {
    color: Colors.TEXT_PRIMARY,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
}));
