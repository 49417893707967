import React, { useEffect, useRef } from 'react';

import { ClassNameMap } from '@mui/styles';
import cx from 'classnames';

import useElementColours from 'client/app/components/ElementPlumber/colours';
import getClassNameForType from 'client/app/components/ElementPlumber/types';
import { getControlPoints } from 'client/app/lib/layout/ConnectionHelper';
import * as LayoutHelper from 'client/app/lib/layout/LayoutHelper';
import { Position2d } from 'common/types/Position';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  startPosition: Position2d;
  endPosition: Position2d;
  type: string;
  complete: boolean;
  connectionIsPending: boolean;
  isSelected: boolean;
  isDisabled: boolean;
  onClick?: (e: MouseEvent) => void;
};

// The "D" property is the shape of the path, obvs.
// https://www.w3.org/TR/SVG/paths.html#TheDProperty
const getPathDefinition = (startPosition: Position2d, endPosition: Position2d) => {
  const { x: startX, y: startY } = startPosition;
  const { x: endX, y: endY } = endPosition;
  const [c1, c2] = getControlPoints(startX, startY, endX, endY);
  // Offset the start of the bezier curve such that:
  // A) the rounded tip can appear outside the instance.
  // B) the curve edges line up with the  port.
  return [
    `M ${startX + LayoutHelper.ELEMENT_INSTANCE_PORT_WIDTH} ${startY}`,
    `C ${c1.x} ${c1.y},`,
    `${c2.x} ${c2.y},`,
    `${endX} ${endY}`,
  ].join(' ');
};

export default function Connection(props: Props) {
  const classes = useStyles();
  const colourCss: ClassNameMap<string> = useElementColours();
  const pathRef = useRef<SVGPathElement | null>(null);

  const getPathClassName = (typeName: string) => {
    return `connection-${getClassNameForType(typeName)}`;
  };

  useEffect(() => {
    const pathElement = pathRef.current;
    if (props.onClick && pathRef.current) {
      pathRef.current.addEventListener('click', props.onClick);
    }

    return () => {
      if (props.onClick && pathElement) {
        pathElement.removeEventListener('click', props.onClick);
      }
    };
  });

  const dValue = getPathDefinition(props.startPosition, props.endPosition);

  const pathClassName = getPathClassName(props.type);

  // There are following cases here:
  //
  //   1) A complete connection with nothing interesting going on
  //   2) A complete connection while a new connection is being created
  //   2.1) A new connection is being created
  //   3) Complete connection being disabled when Workflow Builder is in DOE mode
  //
  //  For the first one, we just render everything as normal. For the second
  //  case, we want to fade out all the connections apart from the one
  //  incomplete connection. We're relying on CSS cascade to apply the
  //  higher-opacity style to the connection if it is the pending one.
  return (
    <path
      ref={pathRef}
      className={cx(
        classes.connection,
        colourCss[pathClassName] ?? classes.greyConnection,
        {
          [classes.fadedCompleteConnection]: props.connectionIsPending,
          [classes.incomplete]: !props.complete,
          [classes.selectedConnection]: props.isSelected,
          [classes.greyConnection]: props.isDisabled,
        },
      )}
      d={dValue}
    />
  );
}

const useStyles = makeStylesHook({
  connection: {
    cursor: 'pointer',
    fill: 'transparent',
    strokeWidth: LayoutHelper.CONNECTION_STROKE_SIZE,
    strokeLinecap: 'round',
    opacity: 0.5,
    zIndex: 1,
    pointerEvents: 'all',
  },
  greyConnection: {
    stroke: '#B8B9B8',
  },

  fadedCompleteConnection: {
    opacity: 0.2,
  },

  incomplete: {
    opacity: 0.8,
  },

  selectedConnection: {
    stroke: Colors.BLUE_30,
    opacity: 1,
  },
});
