import { useCallback } from 'react';
import React from 'react';

import BarChartIcon from '@mui/icons-material/BarChart';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';

import { ArrayElement, SimulationQuery } from 'client/app/gql';
import { visualizationRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Visualisation = ArrayElement<
  NonNullable<SimulationQuery['simulation']['visualisations']>
>;

type Props = {
  simulation: SimulationQuery['simulation'];
  visualization: Visualisation;
};

export default function VisualizationLink({ simulation, visualization }: Props) {
  const classes = useStyles();
  const visualizationHref = visualizationRoutes.base.getPath({
    id: visualization.id,
    visserverApp: visualization.path,
    simulationID: simulation.id,
  });

  const logLinkVisit = useCallback(() => {
    logEvent('open-visualization', ScreenRegistry.VISUALIZATION, visualization.id);
  }, [visualization.id]);

  return (
    <Link to={visualizationHref}>
      <ListItem onClick={logLinkVisit}>
        <ListItemIcon className={classes.icon}>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary={visualization.name} />
      </ListItem>
    </Link>
  );
}

const useStyles = makeStylesHook({
  icon: { minWidth: '32px' },
});
