import React, { useCallback } from 'react';

import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';

// The list here needs to be kept in sync with the antha-core.
//
// Currently we only support CLARIOStar plate reader, so it lives here:
// https://github.com/Synthace/antha/blob/master/instruction-plugins/BMG/ClarioStar/config.go#L82
//
// In the future (when we'll support multiple plate readers) this list should be a property of plate reader config,
// because every plate reader has its own set of available shaking types (or there might be no built-in shaker at all).

const allOptions: Option<string>[] = [
  {
    label: 'Linear',
    value: 'linear',
  },
  {
    label: 'Orbital',
    value: 'orbital',
  },
  {
    label: 'Double Orbital',
    value: 'double orbital',
  },
  {
    label: 'Meander Corner Well',
    value: 'meander corner well',
  },
  {
    label: 'Orbital Corner Well',
    value: 'orbital corner well',
  },
];

type Props = {
  onChange: (value?: string | null) => void;
  isDisabled?: boolean;
} & ParameterEditorBaseProps<string>;

const PlateReaderShakingTypeDropdownEditor = function (props: Props) {
  const onChange = useCallback(
    (value?: string) => {
      if (value === '' || value === undefined) {
        props.onChange(null);
      } else {
        props.onChange(value);
      }
    },
    [props],
  );

  return (
    <Dropdown
      placeholder={props.placeholder ?? 'None'}
      valueLabel={props.value ?? 'None'}
      options={allOptions}
      onChange={onChange}
      isDisabled={props.isDisabled}
      isRequired={props.isRequired}
    />
  );
};

export default PlateReaderShakingTypeDropdownEditor;
