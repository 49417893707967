import { DELETED_SIMULATION_CARD_HEIGHT } from 'client/app/apps/workflow-builder/panels/simulations/DeletedSimulationCard';

/**
 * The deletion animation has 4 states (and 3 transitions)
 * A: Deletion just happened, SimulationCard starts shrinking
 * B: The simulation card is gone, the Deleted card is there
 * C: The Deleted card starts disappearing
 * D: Everything is gone
 */

/**
 * Duration of the Item Card deletion
 */
export const A_TO_B_DURATION = 500;

/**
 * How long the Deleted Card stays after appeared
 */
const B_TO_C_DURATION = 1000;

/**
 * Duration of the fading out of the DeletedSimulationCard
 */
export const C_TO_D_DURATION = 500;

/**
 * How long the Deleted Card takes to start appear to fully disappeard
 */
export const A_TO_C_DURATION = A_TO_B_DURATION + B_TO_C_DURATION;

/**
 * Total duration of the animation
 */
export const A_TO_D_DURATION = A_TO_B_DURATION + B_TO_C_DURATION + C_TO_D_DURATION;

export const SIMULATION_CARD_MAX_HEIGHT = '269px';

export const containerCardTransitionExit = {
  cardTransitionExit: {},
  cardTransitionExitActive: {
    animationName: '$cardTransitionAnimation',
    animationDuration: `${C_TO_D_DURATION}ms`,
    animationDelay: `${A_TO_C_DURATION}ms`,
  },
  '@keyframes cardTransitionAnimation': {
    from: {
      transform: 'scaleY(1) translateY(0)',
      maxHeight: DELETED_SIMULATION_CARD_HEIGHT,
      marginTop: '0px',
      marginBottom: '0px',
    },
    to: {
      transform: 'scaleY(0) translateY(-50%)',
      maxHeight: '0px',
      // cancel the gap between flex items
      marginTop: '-4px',
      marginBottom: '-4px',
    },
  },
};

export const cardItemExit = {
  cardItemTransitionExit: {
    maxHeight: SIMULATION_CARD_MAX_HEIGHT,
    opacity: 1,
    overflow: 'hidden',
  },
  cardItemTransitionExitActive: {
    maxHeight: DELETED_SIMULATION_CARD_HEIGHT,
    opacity: 0,
    transitionProperty: 'opacity, max-height',
    transitionDuration: `${A_TO_B_DURATION}ms`,
    overflow: 'hidden',
  },
};

export const deletedCardAppear = {
  deletedCardAppear: {
    display: 'none',
  },
  deletedCardAppearActive: {
    display: 'visible',
    transition: `display ${A_TO_B_DURATION}ms ${A_TO_B_DURATION}ms`,
  },
};
