import React from 'react';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import Help from 'client/app/components/DOEFactorForm/components/Help';
import { ParameterHeader } from 'client/app/components/Parameters/ElementParameterHeader';
import { FactorItem } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type DOEFactorSamplingProps = {
  factor: Omit<FactorItem, 'values'>;
  onUpdate: (update: Partial<FactorItem>) => void;
  isReadonly: boolean;
  disabled: boolean;
  errors?: {
    numberOfZeros?: string;
  };
  className?: string;
};

export const DOEFactorSampling = ({
  factor,
  onUpdate,
  isReadonly,
  errors,
  className,
  disabled,
}: DOEFactorSamplingProps) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.sampling, className)}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="subtitle2">Sampling</Typography>
        {Help.samplingHelp}
      </Box>

      <Typography variant="body2" component="div" className={classes.samplingInfo}>
        <InfoOutlined fontSize="small" />
        Sampling options only apply to space-fill designs
      </Typography>

      <RadioGroup
        className={classes.samplingTypes}
        value={disabled ? 'discrete' : factor.sampleMode}
        onChange={(_, value: string) =>
          onUpdate({
            sampleMode: value as 'discrete' | 'continuous',
            numberOfZerosToInclude: value === 'continuous' ? 0 : undefined,
          })
        }
      >
        <FormControlLabel
          className={cx(classes.samplingOption, {
            selected: factor.sampleMode === 'discrete' && !disabled,
          })}
          label="Discrete"
          value="discrete"
          control={<Radio color="primary" />}
          disabled={disabled || isReadonly}
        />
        <FormControlLabel
          className={cx(classes.samplingOption, {
            selected: factor.sampleMode === 'continuous' && !disabled,
          })}
          label="Range"
          value="continuous"
          control={<Radio color="primary" />}
          disabled={disabled || isReadonly}
        />
      </RadioGroup>
      {factor.sampleMode !== 'discrete' && !disabled && (
        <div className={classes.field}>
          <ParameterHeader
            displayName="Number of zeros to include"
            isRequired={false}
            help={Help.numberOfZerosHelp}
          />
          <TextField
            type="number"
            value={factor.numberOfZerosToInclude}
            error={!!errors?.numberOfZeros}
            disabled={isReadonly}
            helperText={
              errors?.numberOfZeros && (
                <FormHelperText error>{errors.numberOfZeros}</FormHelperText>
              )
            }
            onChange={e =>
              onUpdate({
                numberOfZerosToInclude: Number.parseInt(e.target.value),
              })
            }
          />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  field: {
    display: 'flex',
    flexDirection: 'column',
  },
  sampling: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
    padding: spacing(6),
    background: Colors.GREY_5,
    border: `1px solid ${Colors.GREY_20}`,
    borderRadius: spacing(2),
  },
  samplingTypes: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(3),
  },
  samplingInfo: {
    display: 'flex',
    gap: spacing(3),
    alignItems: 'center',
    color: palette.text.secondary,
  },
  samplingOption: {
    margin: 0,
    background: Colors.GREY_0,
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '6px',
    boxShadow: `0px 1px 1px 0px rgba(0, 0, 0, 0.1)`,
    '&.selected': {
      borderColor: palette.primary.main,
      background: Colors.BLUE_0,
      color: palette.primary.dark,
    },
  },
}));
