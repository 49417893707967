import React, { useCallback, useState } from 'react';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import cx from 'classnames';

import { useFetchMixPreviewForSimulation } from 'client/app/apps/simulation-details/dataUtils';
import {
  ExistingPlate,
  PlateParameterValue,
} from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import SimulationsDialogContainer from 'client/app/components/Parameters/SimulationsDialog/SimulationsDialogContainer';
import { ArrayElement, SimulationsForDialogQuery } from 'client/app/gql';
import { Plate } from 'common/types/mix';
import { MixPreview } from 'common/types/mixPreview';
import { loadingWithinDialog } from 'common/ui/commonStyles';
import Button from 'common/ui/components/Button';
import ComplexActionDialog, {
  ComplexActionProps,
} from 'common/ui/components/Dialog/ComplexActionDialog';
import DialogWrap from 'common/ui/components/Dialog/DialogWrap';
import LinearProgress from 'common/ui/components/LinearProgress';
import PlatePrepScreen, {
  PlatePrepProps,
} from 'common/ui/components/simulation-details/plate-prep/PlatePrepScreen';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

// We render two main contents for this dialog, which will determine what we show
// in the surrounding dialog container (e.g. ArrowBackIcon, or Select button).
// We also render a Loading dialog container when transitioning from the
// SIMULATIONS_LIST_DIALOG to the PLATE_SELECTOR_DIALOG.
enum DialogPages {
  SIMULATIONS_LIST_DIALOG,
  PLATE_SELECTOR_DIALOG,
}

/**
 * TO-DO T4530 - Once we have the labware_state_id for a selected plate,
 * pass this in as a prop and then update state for the mixPreview so that
 * we navigate to PLATE_SELECTOR_DIALOG, instead of the SIMULATIONS_LIST_DIALOG
 * by default. For now, just handle if an initialValue is provided.
 *  */
type Props = {
  initialValue?: PlateParameterValue;
} & DialogProps<PlateParameterValue | null>;

/**
 * SimulationAndPlateSelectionDialog provides a dialog container in which the user
 * can navigate back and forth between a list of all successfully executed simulations,
 * and (upon selecting a simulation) all the plates from that simulation.
 * Selecting a plate will return that plate in the onClose prop that is handled by
 * the useDialog hook and can be processed by the caller.
 */
export default React.memo(function SimulationAndPlateSelectionDialog({
  isOpen,
  onClose,
  initialValue,
}: Props) {
  const classes = useStyles();
  const snackbarManager = useSnackbarManager();

  const [currentDialogContent, setCurrentDialogContent] = useState<DialogPages>(
    DialogPages.SIMULATIONS_LIST_DIALOG,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [mixPreview, setMixPreview] = useState<MixPreview>();
  const [selectedPlate, setSelectedPlate] = useState<Plate | null>(null);
  const [selectedSimulationName, setSelectedSimulationName] = useState('');

  const fetchMixPreviewForSimulation = useFetchMixPreviewForSimulation();

  type SimulationForDialog = ArrayElement<
    SimulationsForDialogQuery['simulations']['items']
  >;

  const fetchMixPreview = useCallback(
    async (simulation: SimulationForDialog) => {
      try {
        const { actionsLayoutFiletreeLink, id: simulationId } = simulation;
        const preview = await fetchMixPreviewForSimulation(
          simulationId,
          actionsLayoutFiletreeLink,
        );
        if (!preview?.valid) {
          throw new Error(
            `Could not load the preview for simulation with ID ${simulationId}`,
          );
        }
        setMixPreview(preview);
      } catch (error) {
        snackbarManager.showError(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchMixPreviewForSimulation, snackbarManager],
  );

  const handleSimulationSelection = useCallback(
    async (simulation: SimulationForDialog) => {
      await fetchMixPreview(simulation);
      setCurrentDialogContent(DialogPages.PLATE_SELECTOR_DIALOG);
    },
    [fetchMixPreview],
  );

  const onSelectSimulationFromDialog = useCallback(
    async (simulation: SimulationForDialog) => {
      setIsLoading(true);
      setSelectedSimulationName(simulation.name);
      await handleSimulationSelection(simulation);
    },
    [handleSimulationSelection],
  );

  const handleUpdateSelection = useCallback(plate => setSelectedPlate(plate), []);

  const onCancel = useCallback(() => {
    onClose(initialValue ?? '');
  }, [initialValue, onClose]);

  const onBackButtonClick = useCallback(() => {
    setCurrentDialogContent(DialogPages.SIMULATIONS_LIST_DIALOG);
    setMixPreview(undefined);
  }, []);

  const onConfirmSelectionClick = useCallback(() => {
    if (mixPreview && selectedPlate) {
      const selectedExistingPlate: ExistingPlate = {
        item: selectedPlate,
        version: mixPreview.deck.version,
      };
      onClose(selectedExistingPlate);
    }
  }, [mixPreview, onClose, selectedPlate]);

  const dialogProps = { handleSelectPlate: handleUpdateSelection };

  const showPlateSelectionPage =
    currentDialogContent === DialogPages.PLATE_SELECTOR_DIALOG &&
    !isLoading &&
    !!mixPreview;

  return (
    <>
      <SimulationsDialogContainer
        onSelectSimulationFromDialog={onSelectSimulationFromDialog}
        onClose={onCancel}
        isOpen={isOpen}
        dialogClassName={cx({
          [classes.hideDialogPage]: showPlateSelectionPage || isLoading,
        })}
      />
      <ComplexActionDialog // Loading dialog container
        title="Simulations"
        isOpen={isOpen}
        onClose={onCancel}
        showCloseButton
        content={
          <DialogWrap dialog className={classes.linearProgress}>
            <LinearProgress />
          </DialogWrap>
        }
        className={cx({
          [classes.hideDialogPage]: !isLoading,
        })}
      />
      {mixPreview && (
        <PlatePrepScreenDialogContainer
          platePrepProps={{
            mixPreview: mixPreview,
            dialogProps: dialogProps,
          }}
          title={
            selectedSimulationName
              ? `Select plate from "${selectedSimulationName}"`
              : 'Select plate'
          }
          className={cx({
            [classes.hideDialogPage]: !showPlateSelectionPage,
          })}
          isOpen={isOpen}
          onClose={onCancel}
          onBackClick={onBackButtonClick}
          onConfirm={onConfirmSelectionClick}
        />
      )}
    </>
  );
});

type PlatePrepScreenDialogProps = {
  platePrepProps: PlatePrepProps;
  onConfirm: () => void;
} & Omit<ComplexActionProps, 'content'>;

/**
 * The PlatePrepScreen component with DialogContent wrappers to allow us to
 * pass in some styling props for the dialog itself.
 *
 * @param props
 */
const PlatePrepScreenDialogContainer = (props: PlatePrepScreenDialogProps) => {
  const classes = useStyles();
  const dialogContentRootClassOverride = {
    root: classes.dialogContentRootClassOverride,
  };
  return (
    <ComplexActionDialog
      {...props}
      content={
        <DialogContent classes={dialogContentRootClassOverride}>
          <PlatePrepScreen {...props.platePrepProps} />
        </DialogContent>
      }
      dialogActions={
        <DialogActions>
          <Button variant="tertiary" onClick={props.onClose}>
            Cancel
          </Button>
          <Button variant="tertiary" onClick={props.onConfirm} color="primary">
            Select
          </Button>
        </DialogActions>
      }
    />
  );
};

const useStyles = makeStylesHook({
  linearProgress: { ...loadingWithinDialog.linearProgress },
  hideDialogPage: {
    // We want to hide certain components depending on what dialog content
    // is selected, and not have those components retain their screen space
    // or lose their state (e.g. the SimulationsDialogContainer).
    display: 'none',
  },
  dialogContentRootClassOverride: {
    // Needed to make the plate visualization take as much space on the screen
    // as possible, just like in the regular Setup view in Simulation Details.
    display: 'flex',
    // DialogContent will by default add some padding, but we don't want this.
    padding: 0,
    height: '85vh',
  },
});
