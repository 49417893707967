import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { ResultsCard } from 'client/app/apps/simulation-details/overview/results/ResultsComponents';
import { SimulationQuery } from 'client/app/gql';
import { useGet } from 'client/app/lib/xhr';
import { downloadBlob } from 'common/lib/download';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type DownloadCsvFilesProps = {
  simulation: SimulationQuery['simulation'];
  isFileUploading: boolean;
  isFileBeingDeleted: boolean;
  plateFilesUploaded: boolean;
};

function DownloadCsvFiles({
  simulation,
  isFileUploading,
  isFileBeingDeleted,
  plateFilesUploaded,
}: DownloadCsvFilesProps) {
  const classes = useStyles();
  const httpGet = useGet();

  const [downloading, setDownloading] = useState<boolean>(false);

  const isDisabled: boolean =
    isFileUploading || isFileBeingDeleted || !plateFilesUploaded || downloading;

  const [err, setErr] = useState<boolean>(false);

  const filename = simulation.name + '.zip';

  const fileUrl = `/data/execution/${simulation.execution?.id}/results`;

  const downloadCsvFiles = async () => {
    setDownloading(true);

    try {
      const blob = await httpGet(fileUrl).then(response => {
        if (!response.ok) {
          throw new Error(`Could not fetch file: ${filename}`);
        }
        return response.blob();
      });

      downloadBlob(blob, filename);
      setErr(false);
    } catch (error) {
      setErr(true);
      console.error(error);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <ResultsCard header="Data Export">
      <div className={classes.cardText}>
        <Typography variant="body1" color="textSecondary">
          Download a zip file comprising the plates&apos; structured analytical data in
          csv format.
        </Typography>
        <Typography
          variant="body1"
          color="error"
          className={err && !downloading ? classes.errTextOn : classes.errTextOff}
        >
          {err && !downloading ? 'Could not complete download.' : ''}
        </Typography>
      </div>
      <Button
        className={classes.btnDownload}
        variant="secondary"
        onClick={downloadCsvFiles}
        disabled={isDisabled}
      >
        {downloading ? <CircularProgress size={21} /> : err ? 'Try again' : 'DOWNLOAD'}
      </Button>
    </ResultsCard>
  );
}

const useStyles = makeStylesHook(theme => ({
  btnDownload: {
    marginTop: theme.spacing(5),
    width: '100px',
  },
  cardText: {
    marginTop: theme.spacing(5),
  },
  errTextOn: {
    marginTop: theme.spacing(4),
  },
  errTextOff: {
    marginTop: theme.spacing(0),
  },
}));

export default DownloadCsvFiles;
