import {
  DEST_PLATE_ID,
  MappedPlate,
  Transfer,
} from 'client/app/apps/simulation-details/overview/results/PlateMapper';
import { CommonPlateSizes } from 'client/app/components/Parameters/WellSelector/dummyPlates';
import {
  ArrayElement,
  LabwareHistoryMappedLayoutProperties,
  LabwareHistoryType,
  SimulationQuery,
} from 'client/app/gql';
import { formatWellPosition } from 'common/lib/format';
import { Plate, WellLocationOnDeckItem } from 'common/types/mix';
import { getWellContentsHelper } from 'common/ui/components/simulation-details/mix/deckContents';

type SimulationExecution = NonNullable<SimulationQuery['simulation']['execution']>;
type LabwareHistory = ArrayElement<SimulationExecution['labwareHistory']>;

/**
 * Get the mapped plate for a given piece of labware. Mapped plates are labware with a
 * single labware history entry which details all the transfers.
 */
export function getMappedPlateForLabware(
  labwareId: LabwareId,
  sourcePlates: Plate[],
  execution: SimulationExecution,
): MappedPlate {
  const labwareHistory = execution.labwareHistory.find(
    hist => hist.labware.id === labwareId,
  );

  if (labwareHistory?.historyType !== LabwareHistoryType.MAPPED_LAYOUT) {
    // Should be impossible; the plate mapper dialog can only be launched for mapped
    // plates.
    throw new Error(`Labware ${labwareId} is not a mapped plate.`);
  }

  const mappedLayout = labwareHistory.properties as LabwareHistoryMappedLayoutProperties;

  const transfers: Transfer[] = [];

  const labwareHistoriesById = new Map<string, LabwareHistory>(
    execution.labwareHistory.map(history => [history.id, history]),
  );

  const sourcePlatesByName = new Map<string, Plate>(
    sourcePlates.map(plate => [plate.name, plate]),
  );

  for (const {
    location: destLocation,
    sourceLabwareHistoryId,
    sourceLocation,
  } of mappedLayout.contents) {
    const sourceLabwareHistory = labwareHistoriesById.get(sourceLabwareHistoryId);
    if (!sourceLabwareHistory) {
      // This case should be impossible; plates can never disappear from an execution. The
      // only mutable plates are mapped plates, but a mapped plate cannot source from
      // another mapped plate.
      throw new Error(
        `Mapped plate ${labwareHistory.name} references labware ${sourceLabwareHistoryId} but it no longer exists.`,
      );
    }
    const sourceDeckItem = sourcePlatesByName.get(sourceLabwareHistory.name);

    // The server allows storing transfers with incomplete data. We can just skip them.
    if (!destLocation || !sourceLocation || !sourceDeckItem) {
      continue;
    }

    const src: WellLocationOnDeckItem = {
      deck_item_id: sourceDeckItem.id,
      col: sourceLocation.column,
      row: sourceLocation.row,
    };
    const dest: WellLocationOnDeckItem = {
      deck_item_id: DEST_PLATE_ID,
      col: destLocation.column,
      row: destLocation.row,
    };

    const liquidName = getWellContentsHelper(sourceDeckItem, src.col, src.row)?.name;

    if (liquidName === undefined) {
      // As above, this should be impossible. Plates in an execution do not change.
      throw new Error(
        `Mapped plate ${
          labwareHistory.name
        } references a liquid in an empty well ${formatWellPosition(src)} of plate ${
          src.deck_item_id
        }.`,
      );
    }
    transfers.push({
      id: createPlateMappingId(src, dest),
      src,
      dest,
      liquidName,
    });
  }

  return {
    destPlateName: mappedLayout.plateName,
    destPlateType: mappedLayout.labwareFormat as CommonPlateSizes,
    transfers,
  };
}

export function createPlateMappingId(
  src: WellLocationOnDeckItem,
  dest: WellLocationOnDeckItem,
): string {
  return [src.deck_item_id, src.col, src.row, dest.col, dest.row].join(':');
}
