import React, { useEffect, useMemo, useRef, useState } from 'react';

import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { PlateIcon } from 'client/app/icons';
import {
  formatLabwareTypeName,
  getLabwareIndexForPosition,
  isNamedPlate,
  LabwareType,
} from 'client/app/state/LabwarePreference';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { InputPlateIcon } from 'common/ui/icons/InputPlateIcon';
import { NamedPlateIcon } from 'common/ui/icons/NamedPlateIcon';
import { OutputPlateIcon } from 'common/ui/icons/OutputPlateIcon';
import { TipBoxIcon } from 'common/ui/icons/TipBoxIcon';
import { TipWasteIcon } from 'common/ui/icons/TipWasteIcon';

// Minimal size before collapsing the chips on the deck position. Value determined by doing
// start icon (36px) + end icon (36px) + spacing (padding around the label itself so sp3 + sp2)
const ICON_WIDTH = 36;
const CHIP_ICONS_WIDTH = 2 * ICON_WIDTH + 12;

// States for the chip:
// Active - Deck Position has already been selected. You can deselect this chip position.
// Inactive - Deck Position for a different labware
// Placeholder - Indicator for a position that a labware can be added.
type DeckPositionChipProps = {
  state: 'active' | 'inactive' | 'placeholder';
  /** Used to format the correct label on the chips, and grab the preference index. */
  labwareType: LabwareType;
  /** Depending on how much deck position is available, we should show a smaller chip without text.*/
  deckPositionWidth: number;
  isMouseHovering: boolean;
  /**
   * Used to grab the preference index along with the labwareType.
   * Only needed for the active and inactive chip. */
  deckPositionName?: string;
};

function isOverflowActive(div: HTMLDivElement | null, deckPositionWidth: number) {
  return div && div.offsetWidth + CHIP_ICONS_WIDTH > deckPositionWidth;
}

export const DeckPositionChip = React.memo(function DeckPositionChipBase(
  props: DeckPositionChipProps,
) {
  const { deckPositionName, labwareType, state, deckPositionWidth, isMouseHovering } =
    props;
  const classes = useStyles();
  const label = useMemo(() => {
    return formatLabwareTypeName(labwareType);
  }, [labwareType]);
  const index = useWorkflowBuilderSelector(state => {
    return deckPositionName
      ? getLabwareIndexForPosition(state, labwareType, deckPositionName)
      : -1;
  });
  // Collapse the deck position chip if it overflows
  const labelRef = useRef<HTMLDivElement | null>(null);
  const [overflowActive, setOverflowActive] = useState(false);
  useEffect(() => {
    if (isOverflowActive(labelRef.current, deckPositionWidth)) {
      setOverflowActive(true);
      return;
    }
    setOverflowActive(false);
  }, [deckPositionWidth]);

  let startOverride;
  let labelOverride;
  let endOverride;
  let endLabel;
  let chipOverride;
  switch (state) {
    case 'active':
      startOverride = isMouseHovering ? classes.activeHover : classes.active;
      labelOverride = isMouseHovering ? classes.activeHover : classes.active;
      endOverride = isMouseHovering ? classes.activeEndHover : classes.activeEnd;
      endLabel = <Typography variant="subtitle2">#{index + 1}</Typography>;
      break;
    case 'inactive':
      endOverride = classes.inactiveEnd;
      chipOverride = classes.inactive;
      endLabel = <Typography variant="subtitle2">#{index + 1}</Typography>;
      break;
    case 'placeholder':
      startOverride = overflowActive
        ? classes.placeholderOverflowStart
        : classes.placeholderStart;
      labelOverride = classes.placeholderLabel;
      chipOverride = isMouseHovering ? classes.placeholderHover : '';
      endLabel = null;
      break;
  }

  return (
    <div className={cx(classes.chip, chipOverride)}>
      <div className={cx(classes.icon, classes.startIcon, startOverride)}>
        {getLabwareIcon(labwareType)}
      </div>
      <Typography
        ref={labelRef}
        variant="subtitle2"
        className={cx(classes.label, labelOverride, {
          [classes.labelCollapsed]: overflowActive,
        })}
        noWrap
      >
        {label}
      </Typography>
      {endLabel && (
        <div className={cx(classes.icon, classes.endLabel, endOverride)}>{endLabel}</div>
      )}
    </div>
  );
});

export function getLabwareIcon(labware: LabwareType | undefined) {
  if (!labware) {
    return null;
  }
  if (isNamedPlate(labware)) {
    return <NamedPlateIcon fontSize="small" />;
  }
  switch (labware) {
    case 'inputPlates':
      return <InputPlateIcon fontSize="small" />;
    case 'outputPlates':
      return <OutputPlateIcon fontSize="small" />;
    case 'temporaryLocations':
      return <PlateIcon fontSize="small" />;
    case 'tipBoxes':
      return <TipBoxIcon fontSize="small" />;
    case 'tipWastes':
      return <TipWasteIcon fontSize="small" />;
  }
}

const useStyles = makeStylesHook(theme => ({
  chip: {
    alignItems: 'center',
    display: 'flex',
    backgroundColor: Colors.GREY_5,
    borderRadius: '16px',
    maxWidth: 'fit-content',
    '&:last-child': {
      // Added to ensure spacing between the chip and the DeckPositionHoverStatusTick
      // when the latter is wrapped below the chip.
      marginBottom: theme.spacing(7),
    },
  },
  label: {
    backgroundColor: 'inherit',
    borderBottom: `2px solid ${Colors.GREY_30}`,
    borderTop: `2px solid ${Colors.GREY_30}`,
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    padding: theme.spacing(3, 3, 3, 2),
  },
  labelCollapsed: {
    display: 'none',
    '$chip:hover &': {
      display: 'flex',
      alignItems: 'center',
      zIndex: 1,
    },
  },
  // Styling shared by both the start and end label.
  icon: {
    alignItems: 'center',
    backgroundColor: 'inherit',
    border: `2px solid ${Colors.GREY_30}`,
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    width: `${ICON_WIDTH}px`,
  },
  startIcon: {
    borderRight: 'none',
    borderRadius: '16px 0 0 16px',
    padding: theme.spacing(3, 2, 3, 4),
  },
  endLabel: {
    borderLeft: 'none',
    borderRadius: '0 16px 16px 0',
    padding: theme.spacing(2, 3, 2, 2),
    '$chip:hover &': {
      zIndex: 1,
    },
  },
  active: {
    backgroundColor: Colors.GREY_70,
    borderColor: Colors.GREY_70,
    color: Colors.DARK_TEXT_PRIMARY,
  },
  activeHover: {
    backgroundColor: Colors.GREY_60,
    borderColor: Colors.GREY_60,
    color: Colors.DARK_TEXT_PRIMARY,
  },
  activeEnd: {
    backgroundColor: Colors.PRIMARY_DARK,
    borderColor: Colors.PRIMARY_DARK,
    color: Colors.DARK_TEXT_PRIMARY,
  },
  activeEndHover: {
    backgroundColor: Colors.PRIMARY_MAIN,
    borderColor: Colors.PRIMARY_MAIN,
    color: Colors.DARK_TEXT_PRIMARY,
  },
  inactive: {
    '& > *': {
      color: Colors.TEXT_DISABLED,
    },
  },
  inactiveEnd: {
    borderLeft: `2px solid ${Colors.GREY_30}`,
  },
  placeholderStart: {
    borderColor: Colors.GREY_30,
    borderWidth: '2px',
  },
  placeholderLabel: {
    borderRight: `2px solid ${Colors.GREY_30}`,
    borderRadius: '0 16px 16px 0',
  },
  placeholderOverflowStart: {
    border: `2px solid ${Colors.GREY_30}`,
    borderRadius: '16px',
    width: '44px',
    padding: theme.spacing(0, 3),
    '&:hover': {
      borderRadius: '16px 0 0 16px',
      borderRight: 'none',
    },
  },
  placeholderHover: {
    '& > *': {
      borderColor: Colors.PRIMARY_MAIN,
    },
  },
}));
