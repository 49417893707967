import React, { useCallback, useMemo } from 'react';

import HelpOutlined from '@mui/icons-material/HelpOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { sanitizeDeckItemName } from 'common/lib/format';
import { byName } from 'common/lib/strings';
import { Plate, WellLocationOnDeckItem } from 'common/types/mix';
import { SMART_WORD_BREAK_STYLE } from 'common/ui/commonStyles';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { platesOnly } from 'common/ui/components/simulation-details/mix/deckContents';
import { MixState } from 'common/ui/components/simulation-details/mix/MixState';
import getPlateInfo from 'common/ui/components/simulation-details/plate-prep/getPlateInfo';
import WellsList from 'common/ui/components/simulation-details/plate-prep/WellsList';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  deckState: MixState | null;
  // Well location under cursor. Synchronised between the sidebar and the plate view.
  highlightedWellLocation: WellLocationOnDeckItem | null;
  selectedPlateId: string | null;
  liquidColors: LiquidColors;
  onPlateClick: (clickedPlateIdInSidebar: string) => void;
  onWellMouseEnter: (loc: WellLocationOnDeckItem) => void;
  onWellMouseLeave: () => void;
};

/**
 * Sidebar with textual plate summaries
 */
export default function PlatesSidebar({
  deckState,
  onPlateClick,
  selectedPlateId,
  highlightedWellLocation,
  liquidColors,
  onWellMouseEnter,
  onWellMouseLeave,
}: Props) {
  const classes = useStyles();
  const plates = useMemo(
    () => deckState && platesOnly(deckState.deck.items).sort(byName),
    [deckState],
  );
  const selectedPlate = useMemo(
    () => plates?.find(plate => plate.id === selectedPlateId),
    [plates, selectedPlateId],
  );
  if (!plates) {
    return null;
  }
  return (
    <div className={classes.plateList}>
      <span className={classes.header}>PLATES</span>
      <MenuList>
        {plates.map(plate => (
          <PlateListItem
            key={plate.id}
            plate={plate}
            onClick={onPlateClick}
            isSelected={plate.id === selectedPlateId}
          />
        ))}
      </MenuList>
      {selectedPlate && (
        <div className={classes.wellsList}>
          <WellsList
            highlightedWellLocation={highlightedWellLocation}
            plate={selectedPlate}
            liquidColors={liquidColors}
            onWellMouseEnter={onWellMouseEnter}
            onWellMouseLeave={onWellMouseLeave}
          />
        </div>
      )}
    </div>
  );
}

type PlateListItemProps = {
  plate: Plate;
  isSelected?: boolean;
  onClick: (plateId: string) => void;
};

function PlateListItem({ plate, isSelected, onClick }: PlateListItemProps) {
  const classes = useStyles();
  const handleClick = useCallback(() => onClick(plate.id), [onClick, plate.id]);
  const plateInfo = useMemo(() => {
    return getPlateInfo(plate);
  }, [plate]);

  return (
    <MenuItem
      className={classes.plateItem}
      key={plate.id}
      selected={isSelected}
      onClick={handleClick}
    >
      {sanitizeDeckItemName(plate.name)}
      <Tooltip title={plateInfo ?? ''}>
        <HelpOutlined fontSize="small" color="action" />
      </Tooltip>
    </MenuItem>
  );
}

const useStyles = makeStylesHook({
  plateList: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 8px',
  },
  plateItem: {
    ...SMART_WORD_BREAK_STYLE,
    display: 'flex',
    justifyContent: 'space-between',
    '&.Mui-selected,&.Mui-selected:hover': {
      backgroundColor: '#cecfcf',
    },
  },
  header: {
    display: 'block',
    fontSize: '18px',
    fontWeight: 400,
    margin: '16px',
  },
  wellsList: {
    marginTop: '16px',
  },
});
