import React, { useCallback, useLayoutEffect, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import cx from 'classnames';

import { BUILDER_CONTROLS_PADDING_TOP_BOTTOM } from 'client/app/apps/workflow-builder/ControlOverlay';
import Panel from 'client/app/apps/workflow-builder/panels/Panel';
import { ArrayElement, ElementSetQuery } from 'client/app/gql';
import { ScreenRegistry } from 'client/app/registry';
import { alphanumericCompare } from 'common/lib/strings';
import Checkbox from 'common/ui/components/Checkbox';
import { TOP_NAV_HEIGHT_NUMBER } from 'common/ui/components/TopNav/topNavStyles';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useCheckboxChange from 'common/ui/hooks/useCheckboxChange';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type TagFilterPanelProps = {
  availableTags: string[];
  selectedTags: string[];
  className?: string;
  onClose: () => void;
  onTagFilterChange: (tag: string[]) => void;
  /** Ref of an element to which we will vertically align the top of the panel to. */
  alignmentRef: React.RefObject<HTMLDivElement>;
};

export default React.memo(function TagsPanel(props: TagFilterPanelProps) {
  const {
    availableTags,
    selectedTags,
    className,
    onClose,
    onTagFilterChange,
    alignmentRef,
  } = props;
  const classes = useStyles();
  const [matchedTags, setMatchedTags] = useState<string[]>(availableTags);

  const onTagsChange = useCallback(
    (tag: string, isTagIncluded: boolean) => {
      const updatedTags = isTagIncluded
        ? [...selectedTags, tag]
        : selectedTags.filter(existingTag => existingTag !== tag);
      onTagFilterChange(updatedTags);
      logEvent('change-tag-filter', ScreenRegistry.WORKFLOW, updatedTags.join(', '));
    },
    [onTagFilterChange, selectedTags],
  );

  const onTagSearchQueryChange = useTextFieldChange((query: string) => {
    const filteredTags = availableTags.filter(tag => {
      return tag.toLowerCase().includes(query.toLowerCase());
    });
    setMatchedTags(filteredTags);
  });

  // We want to align the panel with the top of the given alignmetnReg element.
  // The size of this might vary if we add more filters, so we calculate the
  // top of the DOM rect and adjust for the TOP_NAV and BUILDER_GRID padding.
  const [offSetY, setoffSetY] = useState(0);
  useLayoutEffect(() => {
    const offSetYCalculated = alignmentRef?.current
      ? alignmentRef?.current?.getBoundingClientRect().top -
        TOP_NAV_HEIGHT_NUMBER -
        BUILDER_CONTROLS_PADDING_TOP_BOTTOM
      : 0;
    setoffSetY(offSetYCalculated);
  }, [alignmentRef]);

  return (
    <Panel
      title="Tags"
      className={cx(className, classes.panel)}
      onClose={onClose}
      panelContent="TagsFilter"
      offSetY={offSetY}
      filters={
        <TextField
          variant="standard"
          autoFocus
          fullWidth
          label="Search"
          onChange={onTagSearchQueryChange}
        />
      }
    >
      <FormControl component="fieldset">
        <FormGroup>
          {matchedTags.map(tag => {
            return (
              <TagCheckbox
                key={tag}
                checked={selectedTags.includes(tag)}
                onChange={onTagsChange}
                tag={tag}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Panel>
  );
});

type TagCheckboxProps = {
  checked: boolean;
  onChange: (tag: string, include: boolean) => void;
  tag: string;
};

const TagCheckbox = React.memo(function (props: TagCheckboxProps) {
  const { checked, onChange, tag } = props;
  const classes = useStyles();
  const onClick = useCheckboxChange(value => {
    onChange(tag, value);
  });

  return (
    <FormControlLabel
      control={
        <Checkbox
          className={classes.checkbox}
          checked={checked}
          onChange={onClick}
          size="small"
        />
      }
      label={tag}
    />
  );
});

type Element = ArrayElement<ElementSetQuery['elementSet']['elements']>;

// 'deprecated' is an internal flag used to hide old elements - don't allow users to select
const HIDDEN_TAGS = new Set(['deprecated']);
export function getAvailableTags(elements: readonly Element[]) {
  const allTags = new Set<string>();

  for (const elem of elements) {
    for (const tag of elem.tags) {
      if (HIDDEN_TAGS.has(tag)) {
        continue;
      }
      allTags.add(tag);
    }
  }
  return [...allTags.values()].sort(alphanumericCompare);
}

const useStyles = makeStylesHook(theme => ({
  checkbox: {
    margin: theme.spacing(0, 0, 1, 3),
  },
  panel: {
    maxHeight: '400px',
  },
}));
