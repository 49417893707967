import React from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import cx from 'classnames';

import { deviceFromGraphQL, GraphQLDevice } from 'client/app/api/deviceFromGraphql';
import { useDownloadRemoteFileFromPath } from 'client/app/api/filetree';
import { QUERY_ALL_DEVICES } from 'client/app/api/gql/queries';
import {
  ResultsCard,
  ResultsStyles,
} from 'client/app/apps/simulation-details/overview/results/ResultsComponents';
import FileIcon from 'client/app/components/FileBrowser/FileIcon';
import {
  ArrayElement,
  DeviceCommonFragment as DeviceCommon,
  SimulationQuery,
} from 'client/app/gql';
import { arrayIsFalsyOrEmpty, groupBy, isFalsyOrEmptyObject } from 'common/lib/data';
import { WorkflowDeviceConfiguration } from 'common/types/bundle';
import DeviceThumbnail from 'common/ui/components/DeviceThumbnail';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  devicesConfiguration: WorkflowDeviceConfiguration;
  simulation: SimulationQuery['simulation'];
};

function DevicePanel({ devicesConfiguration, simulation }: Props) {
  const classes = useStyles();
  const { loading, error, data, refetch } = useQuery(QUERY_ALL_DEVICES);

  const { execution } = simulation;
  if (isFalsyOrEmptyObject(devicesConfiguration) || !execution) {
    return null;
  }

  if (loading) {
    return (
      <div className={classes.progress}>
        <CircularProgress />
      </div>
    );
  }
  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }
  const allDevices: GraphQLDevice[] = data?.devices ?? [];
  const usedDevices = allDevices.filter(d => !!devicesConfiguration[d.id]);
  return (
    <>
      {usedDevices.map(device => (
        <DeviceCard
          key={device.id}
          device={device}
          metadataFiles={filesForDevice(device, execution.tasks)}
        />
      ))}
    </>
  );
}

// The 'as const' means that the type of METADATA_FILE_CATEGORIES is the union of the string
// constants in the array, which makes the following typeof produce a type for that union
const METADATA_FILE_CATEGORIES = ['Configuration', 'Protocols', 'Logs'] as const;
type MetadataFileCategory = (typeof METADATA_FILE_CATEGORIES)[number];

type SimulationExecutionTaskMetadataFiles = ArrayElement<
  SimulationExecutionTask['metadataFiles']
>;

function configType(file: SimulationExecutionTaskMetadataFiles) {
  for (const tag of file.tags) {
    if (tag['config_type'] !== undefined) {
      return tag['config_type'];
    }
  }
  return null;
}

function category(
  file: SimulationExecutionTaskMetadataFiles,
  configType: string | null,
): MetadataFileCategory {
  if (file.fileType === 'DEVICE_LOGS') {
    return 'Logs';
  }
  if (configType === 'PLATEREADER_PROTOCOL') {
    return 'Protocols';
  }
  return 'Configuration';
}

// NOTE: Device-specific details
//
// Given no other information, we just display the names of all the files uploaded, but in some
// cases we have context based on the specifics of the device and can customize the display to be
// more useful in those cases.  Places where that's been done should be called out.

// These are listed in the reverse order we want to display them because we want unlisted types to
// be sorted last, and indexOf() returns -1 if you look up an unknown value.
//
// LC_CONFIG and CARRIER_CONFIG are Tecan-specific
const CONFIG_TYPE_REVERSE_ORDERING: (string | null)[] = [
  'PLATEREADER_PROTOCOL',
  'LC_CONFIG',
  'CARRIER_CONFIG',
];

type SimulationExecutionTasks = Exclude<
  SimulationQuery['simulation']['execution'],
  null
>['tasks'];

type SimulationExecutionTask = ArrayElement<
  NonNullable<NonNullable<SimulationExecutionTasks>>
>;

function filesForDevice(
  device: DeviceCommon,
  tasks: readonly SimulationExecutionTask[] | undefined,
) {
  if (!tasks) {
    return [];
  }

  const files = tasks
    .filter(t => t.simulationTask?.device?.id === device.id)
    .flatMap(t => t.metadataFiles)
    .map(f => {
      const fileConfigType = configType(f);
      return {
        name: f.name,
        filetreeLink: f.filetreeLink,
        category: category(f, fileConfigType),
        configType: fileConfigType,
        size: f.sizeBytes,
      };
    });
  // Short-circuit if no files, since it makes the code below simpler.
  if (files.length === 0) {
    return files;
  }
  files.sort((a, b) => {
    // Sort by config type, then by file name, then category, then size.  The set of properties for
    // comparison should match the properties used in deduping below, otherwise identical files
    // might not sort next to each other and thus deduping won't work.
    const configOrder =
      CONFIG_TYPE_REVERSE_ORDERING.indexOf(b.configType) -
      CONFIG_TYPE_REVERSE_ORDERING.indexOf(a.configType);
    if (configOrder !== 0) {
      return configOrder;
    }
    const nameOrder = a.name.localeCompare(b.name);
    if (nameOrder !== 0) {
      return nameOrder;
    }
    const categoryOrder = (a.category ?? '').localeCompare(b.category ?? '');
    if (categoryOrder !== 0) {
      return categoryOrder;
    }
    return a.size - b.size;
  });
  // Eliminate any duplicates by comparing everything but filetreeLink.  We would ideally do this by
  // hash, but some files in filetree are missing a hash, so they won't reliably deduplicate.
  // Duplicate files must have sorted next to each other, so we can omit any item identical to the
  // one preceding it.
  const dedupedFiles = [files[0]];
  for (let i = 1; i < files.length; i++) {
    if (
      files[i].name !== files[i - 1].name ||
      files[i].category !== files[i - 1].category ||
      files[i].configType !== files[i - 1].configType ||
      files[i].size !== files[i - 1].size
    ) {
      dedupedFiles.push(files[i]);
    }
  }
  return dedupedFiles;
}

function getDisplayName(file: MetadataFile) {
  switch (file.configType) {
    case 'CARRIER_CONFIG':
      // Tecan-specific: There can only be one carrier configuration for any given execution, so we
      // can safely label it "Carrier configuration" with no further details.
      return 'Carrier configuration';
    case 'LC_CONFIG':
      // Tecan-specific
      return `Liquid classes (${file.name.split('.')[0]})`;
    case 'PLATEREADER_PROTOCOL':
      // Note: It's typical for plate readers to have protocols named "foo" stored in files named
      // "foo.something".  If this isn't true for some device in the future, we should special-case
      // it.
      return file.name.split('.')[0];
    default:
      return file.name;
  }
}

type MetadataFile = {
  name: string;
  filetreeLink: FiletreeLink;
  category: MetadataFileCategory;
  configType: string | null;
};

type CardProps = {
  device: DeviceCommon;
  metadataFiles: MetadataFile[];
};

function DeviceCard(props: CardProps) {
  const classes = useStyles();
  const { device: graphQLDevice, metadataFiles } = props;
  const device = deviceFromGraphQL(graphQLDevice);
  const downloadRemoteFileFromPath = useDownloadRemoteFileFromPath();
  const groupedFiles = groupBy(metadataFiles, 'category');

  return (
    <ResultsCard header={device.name}>
      <div className={classes.deviceDetails}>
        <DeviceThumbnail imageUrl={device.imageUrl} />
        <div className={classes.deviceText}>
          <div>
            <strong>{device.model}</strong>
          </div>
          <div>
            <small title={device.id}>{device.id}</small>
          </div>
        </div>
      </div>
      <div className={classes.deviceConfig}>
        {METADATA_FILE_CATEGORIES.map(
          category =>
            !arrayIsFalsyOrEmpty(groupedFiles[category]) && (
              <React.Fragment key={category}>
                <div className={classes.deviceConfigHeader}>{category}</div>
                {groupedFiles[category].map(f => (
                  <div
                    key={f.filetreeLink}
                    className={cx(classes.iconTextContainer, classes.clickable)}
                    onClick={() => downloadRemoteFileFromPath(f.filetreeLink)}
                  >
                    <FileIcon file={{ name: f.name }} />
                    <div>{getDisplayName(f)}</div>
                  </div>
                ))}
              </React.Fragment>
            ),
        )}
      </div>
    </ResultsCard>
  );
}

const useStyles = makeStylesHook({
  deviceDetails: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  deviceText: {
    marginLeft: '8px',
  },
  deviceConfig: {
    display: 'flex',
    flexDirection: 'column',
  },
  deviceConfigHeader: {
    margin: '7px 0 3px',
  },
  iconTextContainer: ResultsStyles.iconTextContainer,
  clickable: ResultsStyles.clickable,
  progress: ResultsStyles.progress,
});

export default DevicePanel;
