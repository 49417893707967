import React, { useCallback, useEffect, useState } from 'react';

import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

import { interpolateConfiguredNames } from 'client/app/lib/workflow/format';
import useElementConfigs from 'client/app/lib/workflow/useElementConfigs';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { stringToMarkdown } from 'common/lib/markdown';
import { ElementError } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import { MarkdownPreview } from 'common/ui/components/MarkdownPreview';
import { DraggableProps } from 'common/ui/components/useDraggable';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const NO_ERRORS: ElementError[] = [];

type Props = {
  errors: ElementError[] | undefined;
} & Omit<TooltipProps, 'title'> &
  DraggableProps;

export default function ElementInstancePopover({
  errors = NO_ERRORS,
  boundaryRef,
  children,
  ...tooltipProps
}: Props & { children: React.ReactElement }) {
  const classes = useStyles();

  const elementSetId = useWorkflowBuilderSelector(state => state.elementSet?.id);
  const { elementConfigs } = useElementConfigs(elementSetId);

  const parseError = useCallback(
    (templateString: string = '') =>
      interpolateConfiguredNames(templateString, elementConfigs),
    [elementConfigs],
  );

  useTooltipRepositioning(tooltipProps.open, boundaryRef);

  const hasDetailsSection = errors.reduce(
    (flag, nextError) => (flag = flag || !!nextError.details),
    false,
  );

  return (
    <Popover
      {...tooltipProps}
      title={
        <div className={classes.container}>
          <section>
            <Typography variant="overline" className={classes.sectionHeader}>
              Summary
            </Typography>
            {errors.map(error => (
              <div key={error.code} className={classes.noGutters}>
                {error.messageType === 'markdown' ? (
                  <MarkdownPreview
                    markdown={stringToMarkdown(error.message)}
                    className={classes.errorPreview}
                  />
                ) : (
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.errorText}
                  >
                    {parseError(error.message)}
                  </Typography>
                )}
              </div>
            ))}
          </section>
          {hasDetailsSection && (
            <section>
              <Typography variant="overline" className={classes.sectionHeader}>
                Details
              </Typography>
              {errors.map((error, idx) =>
                error.messageType === 'markdown' ? (
                  <MarkdownPreview
                    key={idx}
                    markdown={stringToMarkdown(error.details ?? '')}
                    className={classes.errorPreview}
                  />
                ) : (
                  <Typography
                    key={idx}
                    variant="body1"
                    color="textPrimary"
                    className={classes.errorText}
                  >
                    {parseError(error.details)}
                  </Typography>
                ),
              )}
            </section>
          )}
        </div>
      }
    >
      {children}
    </Popover>
  );
}

/**
 * This effect is necessary to stick the tooltip with the element instance while dragging the Workspace.
 */
function useTooltipRepositioning(
  isOpen: boolean | undefined,
  boundaryRef: React.RefObject<HTMLElement> | undefined,
) {
  const [_, rerender] = useState({});
  useEffect(() => {
    if (!isOpen) return;

    const boundaryElement = boundaryRef?.current;
    function pointerMoveListener() {
      rerender({});
    }

    boundaryElement?.addEventListener('pointermove', pointerMoveListener);
    return () => {
      boundaryElement?.removeEventListener('pointermove', pointerMoveListener);
    };
  }, [boundaryRef, isOpen]);
}

const Popover = withStyles({
  arrow: {
    color: Colors.GREY_0,
  },
  tooltip: {
    maxWidth: 'none',
    width: 'fit-content',
    backgroundColor: Colors.GREY_0,
    filter: `drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2)) drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))`,
    padding: 0,
  },
})(MuiTooltip);

const useStyles = makeStylesHook(({ spacing, typography, palette }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
    margin: 0,
    padding: spacing(5),
    width: 380,
    maxHeight: 280,
    overflow: 'hidden auto',
    scrollbarGutter: 'stable',
    color: palette.text.primary,
    ...typography.body2,
  },
  sectionHeader: {
    display: 'block',
    paddingBottom: spacing(3),
    color: Colors.INFO_DARK,
    fontWeight: 400,
  },
  noGutters: {
    margin: 0,
    padding: 0,
  },
  invalidParamsHeader: {
    padding: 0,
    margin: spacing(4, 0, 0),
  },
  errorPreview: {
    '& h1': {
      margin: '18px 0',
      ...typography.h4,
    },
    '& h2': {
      margin: '16px 0',
      ...typography.h5,
    },
    '& h3': {
      margin: '14px 0',
      ...typography.h6,
    },
  },
  errorText: {
    whiteSpace: 'pre-wrap',
  },
}));
