import React, { useCallback, useRef } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import UploadIcon from '@mui/icons-material/CloudUpload';
import KebabMenuIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/SaveAlt';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';

import {
  DEFAULT_TRANSFER,
  downloadCSVTemplate,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import {
  SetActiveStep,
  SetFileName,
  SetPlatesByName,
  SetWorkflowName,
  useCherryPickContext,
} from 'client/app/apps/cherry-picker/CherryPickContext';
import {
  handleUploadCherryPickFile,
  SetLiquidTransfers,
} from 'client/app/apps/cherry-picker/cp-file-upload/csvUploadHelper';
import { ScreenRegistry } from 'client/app/registry';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import HeadersMappingDialog from 'common/ui/components/Dialog/HeadersMappingDialog';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  onUploadCherryPick: SetLiquidTransfers;
  setPlatesByName: SetPlatesByName;
  filename: string | null;
  setFilename: SetFileName;
  setActiveStep: SetActiveStep;
  setWorkflowName: SetWorkflowName;
  workflowName: string;
  parameterMode?: boolean;
};
export default function CherryPickTopBarContainer({
  parameterMode,
}: {
  parameterMode: boolean;
}) {
  const {
    setCherryPick,
    setPlatesByName,
    uploadedFileName,
    setUploadedFileName,
    setActiveStep,
    setWorkflowName,
    workflowName,
  } = useCherryPickContext();
  return (
    <CherryPickTopBar
      onUploadCherryPick={setCherryPick}
      setPlatesByName={setPlatesByName}
      filename={uploadedFileName}
      setFilename={setUploadedFileName}
      setActiveStep={setActiveStep}
      setWorkflowName={setWorkflowName}
      workflowName={workflowName}
      parameterMode={parameterMode}
    />
  );
}

const CherryPickTopBar = React.memo(function CherryPickTopBar(props: Props) {
  const classes = useStyles();
  const { onUploadCherryPick, setPlatesByName, setFilename, setActiveStep, filename } =
    props;
  const snackbar = useSnackbarManager();
  const [headersMappingDialog, openHeadersMappingDialog] =
    useDialog(HeadersMappingDialog);
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

  // Allow to open/close kebab menu
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadFile = useCallback(async () => {
    logEvent('upload-new-cherrypick', ScreenRegistry.CHERRY_PICKER);
    const file = fileInputRef.current?.files?.[0];
    if (!file) {
      return;
    }

    await handleUploadCherryPickFile(
      file,
      fileInputRef.current,
      snackbar,
      openHeadersMappingDialog,
      onUploadCherryPick,
      setFilename,
      setPlatesByName,
      setActiveStep,
    );

    // Close the menu after successfully uploading a file
    setMenuAnchorEl(null);
  }, [
    snackbar,
    openHeadersMappingDialog,
    onUploadCherryPick,
    setFilename,
    setPlatesByName,
    setActiveStep,
  ]);

  const handleClickUploadMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      logEvent('open-kebab-menu', ScreenRegistry.CHERRY_PICKER);
      setMenuAnchorEl(event.currentTarget);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleDownloadTemplate = useCallback(() => {
    logEvent('download-template', ScreenRegistry.CHERRY_PICKER);
    downloadCSVTemplate();
    setMenuAnchorEl(null);
  }, []);

  const handleClear = useCallback(async () => {
    logEvent('clear-cherrypick', ScreenRegistry.CHERRY_PICKER);
    const confirmClear = await openConfirmationDialog({
      action: 'clear',
      object: 'content',
      additionalMessage: 'You will lose all your changes.',
    });
    if (confirmClear) {
      onUploadCherryPick([DEFAULT_TRANSFER]);
      setFilename(null);
      setPlatesByName({});
    }
    setMenuAnchorEl(null);
  }, [onUploadCherryPick, openConfirmationDialog, setFilename, setPlatesByName]);

  return (
    <div className={classes.container}>
      <div className={classes.menuBtn}>
        {filename && (
          <label htmlFor="cherry-pick-upload-topbar">
            <Button
              variant="text"
              color="primary"
              className={classes.actionBtn}
              component="span"
            >
              {filename}
            </Button>
          </label>
        )}
        <IconButton onClick={handleClickUploadMenu} color="primary" size="large">
          <KebabMenuIcon />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          keepMounted
          open={!!menuAnchorEl}
          onClose={handleClose}
        >
          <label htmlFor="cherry-pick-upload-topbar">
            <MenuItemWithIcon
              icon={<UploadIcon />}
              onClick={handleUploadFile}
              text="Upload Picklist"
            />
          </label>
          <MenuItemWithIcon
            onClick={handleDownloadTemplate}
            icon={<DownloadIcon />}
            text="Download Template"
          />
          <MenuItemWithIcon
            onClick={handleClear}
            icon={<ClearIcon />}
            text="Clear Cherry Picker"
          />
        </Menu>
      </div>
      <input
        id="cherry-pick-upload-topbar"
        type="file"
        accept=".csv"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleUploadFile}
      />
      {confirmationDialog}
      {headersMappingDialog}
    </div>
  );
});

const useStyles = makeStylesHook({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 2rem 0',
    borderBottom: '1px solid gray',
  },
  actionBtn: {
    margin: '0.5rem',
  },
  menuBtn: {
    marginLeft: 'auto',
  },
});
