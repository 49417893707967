import React, { useCallback } from 'react';

import { ApolloError, useApolloClient } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import UploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/GetApp';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { MUTATIONS_UPLOAD_LIQUID_CLASSES_CSV } from 'client/app/api/gql/mutations';
import {
  QUERY_DEVICE_LIQUID_CLASSES_CSV_BY_GUID,
  QUERY_GET_MISSING_DEVICE_LIQUID_CLASSES,
  QUERY_MISSING_DEVICE_LIQUID_CLASSES_AS_CSV,
} from 'client/app/api/gql/queries';
import {
  DeviceCommonFragment as DeviceCommon,
  MissingDeviceLiquidClassesQuery,
  MissingDeviceLiquidClassesQueryVariables,
} from 'client/app/gql';
import { downloadTextFile } from 'common/lib/download';
import { pluralize } from 'common/lib/format';
import { getFileText } from 'common/lib/getFileBytes';
import Button from 'common/ui/components/Button';
import { useDialogManager } from 'common/ui/components/DialogManager';
import InfoDialog from 'common/ui/components/InfoDialog';

export type Props = {
  device: DeviceCommon;
  onRefresh: () => void;
};

function LiquidClassesPanel(props: Props) {
  const dialogManager = useDialogManager();
  const { device, onRefresh } = props;

  const apollo = useApolloClient();
  const handleDownloadCSV = useCallback(() => {
    (async () => {
      // TODO: this query should probably be by device ID, not anthahub id.
      //       It's ok currently as all devices with RunConfig have anthaHubGUID
      if (device.anthaHubGUID == null) {
        throw new Error(`Device is missing antha hub GUID`);
      }

      const result = await apollo.query({
        query: QUERY_DEVICE_LIQUID_CLASSES_CSV_BY_GUID,
        variables: {
          anthaHubGUID: device.anthaHubGUID,
        },
        fetchPolicy: 'network-only',
      });
      if (result.errors || !result.data) {
        return;
      }
      if (result.data.devices.length === 0) {
        throw new Error('Requested device not found.');
      }

      const { liquidClasses } = result.data.devices[0];

      downloadTextFile(
        liquidClasses.asCSV,
        `${device.name}_liquid_classes.csv`,
        'application/csv',
      );
    })();
  }, [apollo, device.anthaHubGUID, device.name]);

  const handleDownloadTemplateCSV = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      (async () => {
        // TODO: this query should probably be by device ID, not anthahub id.
        //       It's ok currently as all devices with RunConfig have anthaHubGUID
        if (device.anthaHubGUID == null) {
          throw new Error(`Device is missing antha hub GUID`);
        }

        const result = await apollo.query({
          query: QUERY_MISSING_DEVICE_LIQUID_CLASSES_AS_CSV,
          variables: {
            anthaHubGUID: device.anthaHubGUID,
          },
          fetchPolicy: 'network-only',
        });

        if (result.errors || !result.data) {
          return;
        }
        if (result.data.devices.length === 0) {
          throw new Error('Requested device not found.');
        }

        const { liquidClasses } = result.data.devices[0];

        downloadTextFile(
          liquidClasses.missingAsCSVTemplate,
          `${device.name}_liquid_classes.csv`,
          'application/csv',
        );
      })();
    },
    [apollo, device.anthaHubGUID, device.name],
  );

  const handleUploadCSV = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const files = e.currentTarget.files;
      if (!files) {
        // no files selected, user cancelled
        return;
      }

      (async () => {
        if (files.length !== 1) {
          throw new Error('Please select one CSV file.');
        }
        const data = await getFileText(files[0]);

        try {
          const results = await apollo.mutate({
            mutation: MUTATIONS_UPLOAD_LIQUID_CLASSES_CSV,
            variables: {
              deviceID: device.id,
              csv: data,
            },
            refetchQueries: ['MissingDeviceLiquidClasses'],
          });

          if (!results.data?.updateDeviceLiquidClasses) {
            throw new Error('Did not receive any data from the backend.');
          }
          const { updated, created } = results.data.updateDeviceLiquidClasses;
          dialogManager.openDialog('UPLOAD_LIQUID_CLASSES_CSV_SUCCESS', InfoDialog, {
            title: 'Upload device liquid classes',
            message: `File uploaded successfully: created ${created} liquid classes, updated ${updated}.`,
          });
        } catch (e) {
          if (e instanceof ApolloError) {
            const errorMessage = e.graphQLErrors[0]?.message;
            dialogManager.openDialog('UPLOAD_LIQUID_CLASSES_CSV_ERROR', InfoDialog, {
              title: 'Upload device liquid classes',
              message:
                `Could not parse the liquid class file.\n` +
                `Please fix the following error(s):\n\n${errorMessage}`,
              maxWidth: 'lg',
            });
            return;
          }
          throw e;
        }
        onRefresh();
      })();
    },
    [apollo, device.id, dialogManager, onRefresh],
  );

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>
        Device liquid classes
      </Typography>
      <Button startIcon={<DownloadIcon />} onClick={handleDownloadCSV} variant="tertiary">
        Download as CSV
      </Button>
      <input id="uploadCSV" type="file" hidden onChange={handleUploadCSV} accept=".csv" />
      <label htmlFor="uploadCSV">
        <Button startIcon={<UploadIcon />} component="span" variant="tertiary">
          Upload CSV
        </Button>
      </label>
      {device.anthaHubGUID && (
        <Query<MissingDeviceLiquidClassesQuery, MissingDeviceLiquidClassesQueryVariables>
          query={QUERY_GET_MISSING_DEVICE_LIQUID_CLASSES}
          variables={{
            anthaHubGUID: device.anthaHubGUID,
          }}
          fetchPolicy="network-only"
        >
          {({ loading, data }) => {
            if (loading || !data) {
              return null;
            }
            const { devices } = data;
            if (devices.length === 0) {
              return null;
            }
            const { liquidClasses } = devices[0];
            if (liquidClasses.missing.length === 0) {
              return null;
            }
            return (
              <Typography color="error">
                {pluralize(
                  liquidClasses.missing.length,
                  'liquid class',
                  'liquid classes',
                )}{' '}
                defined in the device configs have no definition. <br />
                Please{' '}
                <a href="" onClick={handleDownloadTemplateCSV}>
                  download a CSV template
                </a>{' '}
                to fill in the missing data.
              </Typography>
            );
          }}
        </Query>
      )}
    </Box>
  );
}

export default LiquidClassesPanel;
