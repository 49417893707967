import { graphql } from 'client/app/gql';

export const MUTATION_CREATE_EXPERIMENT = graphql(/* GraphQL */ `
  mutation CreateExperiment($name: String!) {
    createExperiment(input: { name: $name }) {
      id
    }
  }
`);

export const MUTATION_UPDATE_EXPERIMENT = graphql(/* GraphQL */ `
  mutation UpdateExperiment($id: ExperimentId!, $name: String!) {
    updateExperiment(input: { id: $id, name: $name }) {
      id
      name
    }
  }
`);

export const MUTATION_DELETE_EXPERIMENT = graphql(/* GraphQL */ `
  mutation DeleteExperiment($id: ExperimentId!) {
    deleteExperiment(input: { id: $id })
  }
`);

export const MUTATION_CREATE_EXPERIMENT_BLOCK = graphql(/* GraphQL */ `
  mutation CreateExperimentBlock(
    $experimentID: ExperimentId!
    $type: ExperimentBlockType!
    $insertBefore: ExperimentModuleId
    $workflowID: WorkflowId
    $simulationID: SimulationId
    $datasetID: DatasetId
  ) {
    createExperimentBlock(
      input: {
        experimentID: $experimentID
        type: $type
        insertBefore: $insertBefore
        workflowID: $workflowID
        simulationID: $simulationID
        datasetID: $datasetID
      }
    ) {
      ...ExperimentBlockFragment
    }
  }
`);

export const MUTATION_UPDATE_EXPERIMENT_MODULE = graphql(/* GraphQL */ `
  mutation UpdateExperimentModule(
    $id: ExperimentModuleId!
    $title: String
    $description: String
  ) {
    updateExperimentModule(input: { id: $id, title: $title, description: $description }) {
      id
      title
      description
    }
  }
`);

export const MUTATION_REPOSITION_EXPERIMENT_BLOCK = graphql(/* GraphQL */ `
  mutation RepositionExperimentBlock(
    $id: ExperimentModuleId!
    $insertBefore: ExperimentModuleId
  ) {
    repositionExperimentBlock(input: { id: $id, insertBefore: $insertBefore }) {
      id
      blocks {
        id
      }
    }
  }
`);

export const MUTATION_REMOVE_EXPERIMENT_BLOCK = graphql(/* GraphQL */ `
  mutation RemoveExperimentBlock($id: ExperimentModuleId!) {
    removeExperimentBlock(input: { id: $id })
  }
`);

export const MUTATION_CREATE_AND_LINK_WORKFLOW = graphql(/* GraphQL */ `
  mutation CreateWorkflowInExperiment(
    $experimentID: ExperimentId!
    $source: WorkflowSourceEnum!
    $elements: ElementsBlob
  ) {
    createWorkflowInExperiment(
      input: { experimentID: $experimentID, source: $source, elements: $elements }
    ) {
      id
    }
  }
`);

export const MUTATION_ADD_DATASET_DERIVATION_INPUTS = graphql(/* GraphQL */ `
  mutation AddDatasetDerivationInputs($datasetIds: [UUID!]!, $derivationId: UUID!) {
    addDatasetDerivationInputs(
      input: { datasetIds: $datasetIds, derivationId: $derivationId }
    ) {
      id
    }
  }
`);

export const MUTATION_REMOVE_DATASET_DERIVATION_INPUT = graphql(/* GraphQL */ `
  mutation RemoveDatasetDerivationInput($datasetId: UUID!, $derivationId: UUID!) {
    removeDatasetDerivationInput(
      input: { datasetId: $datasetId, derivationId: $derivationId }
    )
  }
`);

export const MUTATION_UPSERT_AND_LINK_SAMPLE_TO_DATASET = graphql(/* GraphQL */ `
  mutation UpsertAndLinkSampleToDataset(
    $datasetId: DatasetId!
    $sampleName: String!
    $rowKey: String!
  ) {
    upsertAndLinkSample(
      input: { datasetId: $datasetId, name: $sampleName, rowKeyJson: $rowKey }
    ) {
      id
      dataset {
        name
      }
      sample {
        name
      }
      rowKey
    }
  }
`);

export const MUTATION_LINK_SAMPLE_TO_DATASET = graphql(/* GraphQL */ `
  mutation LinkSampleToDataset(
    $datasetId: DatasetId!
    $sampleId: SampleId
    $rowKey: String!
  ) {
    linkSampleToDataset(
      input: { datasetId: $datasetId, sampleId: $sampleId, rowKeyJson: $rowKey }
    ) {
      ...DatasetSampleFragment
    }
  }
`);

export const MUTATION_CREATE_DATASET_TAG = graphql(/* GraphQL */ `
  mutation CreateDatasetTag(
    $datasetId: DatasetId!
    $value: String!
    $name: String!
    $type: String!
    $isKeyColumn: Boolean!
  ) {
    createDatasetTag(
      input: {
        datasetId: $datasetId
        value: $value
        columnMetadata: { name: $name, type: $type, isKeyColumn: $isKeyColumn }
      }
    ) {
      ...DatasetTagFragment
    }
  }
`);

export const MUTATION_REMOVE_DATASET_TAG = graphql(/* GraphQL */ `
  mutation RemoveDatasetTag($id: DatasetTagId!) {
    removeDatasetTag(input: { id: $id })
  }
`);

export const MUTATION_CREATE_DATASET_EVENT = graphql(/* GraphQL */ `
  mutation CreateDatasetEvent(
    $datasetId: DatasetId!
    $name: String!
    $occurredAt: DateTime!
    $rowKeyJson: String!
    $payloadJson: String!
  ) {
    createDatasetEvent(
      input: {
        datasetId: $datasetId
        name: $name
        occurredAt: $occurredAt
        rowKeyJson: $rowKeyJson
        payloadJson: $payloadJson
      }
    ) {
      id
    }
  }
`);

export const MUTATION_REMOVE_DATASET_EVENT = graphql(/* GraphQL */ `
  mutation RemoveDatasetEvent($id: DatasetEventId!) {
    removeDatasetEvent(input: { id: $id })
  }
`);
