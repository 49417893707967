import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  // The action that this dialog is confirming.
  action: string;
  // Used to apply warning styling - for deletion and such
  isActionDestructive?: boolean;
  // The object that is being modified.
  object: string;
  /** Do not prefix object with 'the' */
  omitThe?: boolean;
  // If we have a specific object that is being modified (e.g. the name of the workflow),
  // we should use it to populate the question.
  specificObject?: string;
  // Optional additional information about the impact of the action.
  additionalMessage?: string;
  // Optional custom label for the button that dismisses the dialog
  cancelButtonLabel?: string;
  // Optional custom label for the button that confirms the dialog
  confirmButtonLabel?: string;
};

/*
 *  This dialog is to be used for confirmations.
 */
export default function ConfirmationDialog(props: DialogProps<boolean> & Props) {
  const classes = useStyles();
  const { action, object, omitThe, specificObject, additionalMessage, isOpen, onClose } =
    props;
  const objectText = omitThe ? object : `the ${object}`;
  const title = `${action} ${objectText}?`;
  const objectName = specificObject ? `"${specificObject}"` : objectText;
  const confirmationQuestion = `Are you sure you want to ${action} ${objectName}?`;
  const confirmButton = props.confirmButtonLabel || `Yes, ${action} ${objectText}`;

  const handleOK = () => {
    onClose(true);
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth onClose={handleCancel}>
      <DialogTitle className={classes.title}>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        {confirmationQuestion} {additionalMessage}
      </DialogContent>
      <DialogActions data-test="confirmationDialogActions">
        <Button variant="tertiary" color="inherit" onClick={handleCancel}>
          {props.cancelButtonLabel || 'No'}
        </Button>
        <Button
          variant="tertiary"
          onClick={handleOK}
          color="primary"
          className={cx({
            [classes.destructiveAction]: props.isActionDestructive,
          })}
        >
          {confirmButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStylesHook({
  content: {
    color: '#000',
    whiteSpace: 'pre-line',
  },
  destructiveAction: {
    '&.MuiButton-root': {
      color: Colors.RED,
    },
  },
  title: {
    '&::first-letter': {
      textTransform: 'capitalize',
    },
  },
});
