// This function solves two problems that are rooted in a single problem.
// The root problem is that the data repo does not behave like a proper
// file system, despite looking a bit like a file system. Because of this,
// we have to be careful not to treat every part of a file like a folder.

// Should match anything of the form /device/<some id>/recent/
// This is needed in the case that the user has previously selected a
// value that is a device subfolder. The first 3 folders in this
// path are bogus and will error. So, we need to skip over them
// by setting this as the base path.
//
// Secondarily, we need to be able to tell the user visually which
// device had previously been selected. In order to do that, we
// extract a value for the ID group with this regex. There are two device
// base paths -- one ending in /recent/ and one not ending in /recent/.
// To be honest, I don't understand the difference between the two. Without
// /recent, you'll get a list of files. With /recent/ you might get a list
// of timestamp-based directories. Doesn't make a whole lot of sense.
const DEVICE_BASE_PATH_REGEX = /^\/device\/([a-zA-Z0-9]+)\/(recent\/)?/;
export const DEVICE_BASE_PATH = '/device/';

// The above is also true for any paths starting with /job/<some id>/
const JOB_BASE_PATH_REGEX = /^\/job\/([a-zA-Z0-9]+)\//;
export const JOB_BASE_PATH = '/job/';

export type PathMetadata = {
  basePath: string;
  jobID: string;
  deviceID: string;
};

export default function getMetadataFromPath(value: string) {
  let deviceID = '';
  let jobID = '';

  // For a device path like:
  //   "/device/5D4RPA3RPXZVG9GZGQ4CSX4JTG/recent/20190212T165357Z/foo.txt",
  // this will return an array of the form:
  //   0: "/device/5D4RPA3RPXZVG9GZGQ4CSX4JTG/recent/"
  //   1: "5D4RPA3RPXZVG9GZGQ4CSX4JTG"
  const deviceMatches = value.match(DEVICE_BASE_PATH_REGEX);
  if (deviceMatches !== null) {
    deviceID = deviceMatches[1];
  }

  // For a job path like:
  //   "/job/0HJG789P07XPDMFYR59J4KMSY7/input.json",
  // this will return an array of the form:
  //   0: "/job/0HJG789P07XPDMFYR59J4KMSY7/"
  //   1: "0HJG789P07XPDMFYR59J4KMSY7"
  const jobMatches = value.match(JOB_BASE_PATH_REGEX);
  if (jobMatches !== null) {
    jobID = jobMatches[1];
  }

  return {
    jobID,
    deviceID,
  };
}
