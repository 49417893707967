import React from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

// Minimal size before collapsing the chips on the deck position. Value determined
// from the design in the Figma.
const STATUS_CHIP_LARGE_WIDTH = 162;

type DeckPositionHoverStatusChipProps = {
  // Used to determine whether to show the 'small' or 'large' variant.
  deckPositionWidth: number;
  // Used to determine whether to show the hover state.
  isMouseHovering: boolean;
  // Updates the copy of the chip is selected ('Selected') or unselected ('Select').
  isSelectedForCurrentLabware: boolean;
  // We only show the hover state if the user is not clicking and dragging.
  hasMouseMoved: boolean;
};

/**
 * Displays a Chip used to indicate if the deck position is selected for the current
 * labware. The copy of the Chip will change ('Selected', or 'Select' if unselected).
 * The Chip will show hover states if the deck position has the mouse over.
 */
export default function DeckPositionHoverStatusChip(
  props: DeckPositionHoverStatusChipProps,
) {
  const classes = useStyles();
  const {
    deckPositionWidth,
    isMouseHovering,
    isSelectedForCurrentLabware,
    hasMouseMoved,
  } = props;

  const variant = STATUS_CHIP_LARGE_WIDTH > deckPositionWidth ? 'small' : 'large';

  const showHoverState = isMouseHovering && hasMouseMoved;
  const isLargeVariant = variant === 'large';
  const isSmallVariant = variant === 'small';

  return (
    <div
      className={cx(classes.container, {
        [classes.hover]: showHoverState,
        [classes.large]: isLargeVariant,
        [classes.small]: isSmallVariant,
        [classes.largeSelected]: isLargeVariant && isSelectedForCurrentLabware,
        [classes.smallSelected]: isSmallVariant && isSelectedForCurrentLabware,
      })}
    >
      {isSelectedForCurrentLabware && (
        <CheckCircleOutlineIcon
          className={cx(isLargeVariant ? classes.largeIcon : classes.smallIcon)}
        />
      )}
      <Typography
        className={cx({ [classes.largeVariantText]: isLargeVariant })}
        variant={isLargeVariant ? 'h2' : 'subtitle2'}
        color="textPrimary"
      >
        {isSelectedForCurrentLabware ? 'Selected' : 'Select'}
      </Typography>
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  container: {
    alignItems: 'center',
    backgroundColor: Colors.WHITE,
    display: 'flex',
    justifyContent: 'center',
  },
  large: {
    borderRadius: '32px',
    border: `4px solid ${Colors.GREY_30}`,
    height: '64px',
    width: `${STATUS_CHIP_LARGE_WIDTH}px`,
  },
  largeSelected: {
    paddingRight: theme.spacing(5),
  },
  largeIcon: {
    color: Colors.SUCCESS_MAIN,
    fontSize: '56px',
  },
  largeVariantText: {
    paddingLeft: theme.spacing(3),
  },
  hover: {
    '&$small,&$large': {
      borderColor: Colors.PRIMARY_MAIN,
    },
  },
  small: {
    borderRadius: '16px',
    border: `2px solid ${Colors.GREY_30}`,
    height: '32px',
    minWidth: '72px',
    padding: theme.spacing(3, 2),
  },
  smallSelected: {
    minWidth: '94px',
    padding: theme.spacing(2, 3, 2, 2),
  },
  smallIcon: {
    color: Colors.SUCCESS_MAIN,
    fontSize: '24px',
    marginRight: theme.spacing(2),
  },
}));
