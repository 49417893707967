import React, { useCallback, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';

import {
  MUTATION_DELETE_EXPERIMENT,
  MUTATION_UPDATE_EXPERIMENT,
} from 'client/app/apps/experiments/gql/mutations';
import DetailsCard from 'client/app/components/nav/DetailsCard';
import HeaderEditableName from 'client/app/components/nav/HeaderEditableName';
import PlatformHeader from 'client/app/components/nav/PlatformHeader';
import { ExperimentQuery } from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { experimentsRoutes } from 'client/app/lib/nav/actions';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import useDialog from 'common/ui/hooks/useDialog';
import { cacheEvict } from 'common/utils';

type ExperimentHeaderProps = {
  experiment: ExperimentQuery['experiment'];
  isEditable: boolean;
};

/**
 * Custom header which allows the user to rename the experiment
 */
export function ExperimentHeader({ experiment, isEditable }: ExperimentHeaderProps) {
  const currentUser = useUserProfile();
  // We keep the name in local state. We could just use experiment.name, but this will
  // mean when the user edits the title and hits enter, the old name will reappear for a
  // couple of seconds while the mutation runs.
  const [experimentName, setExperimentName] = useState<string>('');

  useEffect(() => setExperimentName(experiment.name), [experiment]);

  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const navigation = useNavigation();

  const [updateExperiment] = useMutation(MUTATION_UPDATE_EXPERIMENT);
  const [deleteExperiment] = useMutation(MUTATION_DELETE_EXPERIMENT);

  const handleNameChange = useCallback(
    (newExperimentName: string) => {
      if (newExperimentName === experimentName) {
        // No action if unchanged
        return;
      }
      setExperimentName(newExperimentName);
      void updateExperiment({
        variables: { id: experiment.id, name: newExperimentName },
        optimisticResponse: {
          __typename: 'Mutation',
          updateExperiment: {
            __typename: 'Experiment',
            id: experiment.id,
            name: newExperimentName,
          },
        },
      });
    },
    [experiment, experimentName, updateExperiment],
  );

  const handleDelete = useCallback(async () => {
    const isConfirmed = await openConfirmationDialog({
      action: 'delete',
      isActionDestructive: true,
      object: 'experiment',
    });
    if (!isConfirmed) {
      return;
    }
    await deleteExperiment({
      variables: { id: experiment.id },
      optimisticResponse: {
        __typename: 'Mutation',
        deleteExperiment: experiment.id,
      },
      update: cache => cacheEvict({ id: experiment.id, __typename: 'Experiment' }, cache),
    });
    navigation.navigate(experimentsRoutes.base, undefined);
  }, [deleteExperiment, experiment.id, navigation, openConfirmationDialog]);

  return (
    <>
      <PlatformHeader>
        <HeaderEditableName
          prefix="Experiment"
          name={experimentName}
          disabled={!isEditable}
          onNameChange={handleNameChange}
          popoverPanel={
            <DetailsCard
              experiment={experiment}
              onDelete={
                currentUser?.id === experiment.createdBy.id ? handleDelete : undefined
              }
            />
          }
        />
      </PlatformHeader>
      {confirmationDialog}
    </>
  );
}
