import { Input } from 'client/app/apps/template-workflows/TemplateWorkflowEditor';
import { UpdateTemplateWorkflowInputInput } from 'client/app/gql';
import {
  Element,
  ElementInstance,
  Parameter,
  ParameterValue,
  ServerSideElementInstance,
} from 'common/types/bundle';

type ElementMap = { [elementName: string]: Element };

/**
 * Returns object that is a map from element instance name to list of
 * inputs.
 *
 * Skips element instances with no available unselected inputs.
 */
export function getInputsForElement(
  element: Element,
  elementInstanceId: string,
  elementInstanceName: string,
): Input[] {
  const inputs = element.inputs ?? [];
  return inputs.map(elementInput =>
    createInput(
      elementInstanceId,
      elementInput,
      elementInput.configuration?.displayName,
      elementInstanceName,
    ),
  );
}

function createInput(
  ElementInstanceId: string,
  elementInput: Parameter,
  configName?: string,
  elementName?: string,
): Input {
  return {
    ElementInstanceId,
    InputName: elementInput.name,
    DisplayName: configName ?? elementInput.name,
    configName,
    elementName,
  };
}

/**
 * A hacky way to format the parameter value. Currently this is used within a tooltip
 * so it's awkward to render anything richer like a readonly ParameterEditor.
 */
export function formatParameterValue(val: ParameterValue): string {
  if (val === null || val === undefined) {
    return '(Not specified)';
  }

  if (typeof val === 'object') {
    return JSON.stringify(val);
  }

  return `${val}`;
}

export function areInputsEqual(input1: Input, input2: Input): boolean {
  return (
    input1.InputName === input2.InputName &&
    input1.ElementInstanceId === input2.ElementInstanceId
  );
}

/**
 * Component WorkflowLayout requires instances to contain links to elements,
 * this was previously done during workflow deserialization, we have to hack
 * this together. One day this will not be needed, and it will be a good day.
 */
export function convertElementInstancesForLayout<T extends ServerSideElementInstance>(
  serverInstances: {
    [name: string]: T;
  },
  elementMap: ElementMap,
): (T & ElementInstance)[] {
  return Object.entries(serverInstances).map(([name, instance]) => {
    const element = elementMap[instance.TypeName];
    if (!element) {
      console.warn('Element ' + name + ' not found!');
    }
    return {
      ...instance,
      name,
      element,
    };
  });
}

/**
 * Adjusts Meta(x, y) for all element instances to make WorkflowLayout preview
 * start at (0, 0) point
 * @param instances element instances from WorkflowBuilder state
 * @returns same instances with reset Meta(x,y)
 */
export function snapElementInstancesToOrigin(
  instances: ElementInstance[],
): ElementInstance[] {
  let minX = Infinity;
  let minY = Infinity;

  for (const instance of instances) {
    minX = Math.min(instance.Meta.x, minX);
    minY = Math.min(instance.Meta.y, minY);
  }

  return instances.map(ei => ({
    ...ei,
    Meta: {
      x: ei.Meta.x - minX,
      y: ei.Meta.y - minY,
    },
  }));
}

/**
 * Compares two element instances to determine the order of elements in the
 * table. We want to sort them by x coord in the workflow, since intuitively
 * first steps are usually on the left side of the workflow graph.
 */
export function compareElementInstances(
  instance1: ElementInstance,
  instance2: ElementInstance,
) {
  const x1 = instance1.Meta.x;
  const x2 = instance2.Meta.x;

  if (x1 === x2) {
    return instance1.Meta.y - instance2.Meta.y;
  } else {
    return x1 - x2;
  }
}

// remove some extra fields present in the Input struct, to match the update template format
export function toTemplateInput(input: Input): UpdateTemplateWorkflowInputInput {
  return {
    ElementInstanceId: input.ElementInstanceId,
    DisplayName: input.DisplayName,
    InputName: input.InputName,
    Collapsed: input.Collapsed ?? false,
  };
}
