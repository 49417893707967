import React, { useCallback } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Button from 'common/ui/components/Button';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  title: string;
  contentText?: string;
  content?: React.ReactNode;
  // Optional custom label for the button that dismisses the dialog
  cancelButtonLabel?: string;
  // Optional custom label for action button on the dialog
  submitButtonLabel?: string;
  submitButtonDisabled?: boolean;
  onSubmit: () => void;
} & ({ content: React.ReactNode } | { contentText: string }); // Must specify at least one of contentText or content

/*
 * A dialog that allows you to have a simple action or two.
 * This should be primarily used with little content (e.g. a textfield or some informational content).
 *
 * If you have a more complex dialog, use the ComplexActionDialog.
 * Refer to useDialog for more information about how to incorporate this dialog properly.
 */
export default function SimpleDialog(props: DialogProps<void> & Props) {
  const { title, contentText, content, onSubmit, onClose, isOpen, submitButtonDisabled } =
    props;

  const handleCancel = useCallback(() => onClose(), [onClose]);

  return (
    <Dialog open={isOpen} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        {content}
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" onClick={handleCancel}>
          {props.cancelButtonLabel || 'Cancel'}
        </Button>
        <Button
          variant="tertiary"
          onClick={onSubmit}
          disabled={submitButtonDisabled}
          color="primary"
        >
          {props.submitButtonLabel || 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
