import React, { useCallback } from 'react';

import { ArrayElement, draftWorkflowsQuery } from 'client/app/gql';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { ScreenRegistry } from 'client/app/registry';
import { EntityCard } from 'common/ui/components/EntityCard';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

type DraftWorkflowCardProps = {
  draftWorkflow: ArrayElement<draftWorkflowsQuery['draftWorkflows']['items']>;
  onDeleteDraft: (draftId: WorkflowId, name: string) => void;
};

export default function DraftWorkflowCard(props: DraftWorkflowCardProps) {
  const { draftWorkflow, onDeleteDraft } = props;

  const { EditorIcon, route } = getWorkflowPropsBySource(draftWorkflow.source);
  const path = route.getPath({ workflowId: draftWorkflow.id });

  const handleClickRow = useCallback(() => {
    logEvent('open-draft-workflow', ScreenRegistry.EXPERIMENTS);
  }, []);

  const handleClickDelete = useCallback(
    (e: React.MouseEvent) => {
      logEvent('delete-draft-workflow', ScreenRegistry.EXPERIMENTS);
      // Icon for delete is under <a> tag that causes redirect.
      // In order to prevent the redirect, the click event must not propagate up
      e.preventDefault();
      e.stopPropagation();
      onDeleteDraft(draftWorkflow.id, draftWorkflow.name);
    },
    [draftWorkflow.id, draftWorkflow.name, onDeleteDraft],
  );

  return (
    <EntityCard
      entityName="Draft"
      icon={<EditorIcon fontSize="small" />}
      name={draftWorkflow.name}
      author={draftWorkflow.createdBy.displayName}
      date={new Date(draftWorkflow.lastModifiedAt)}
      menu={[{ label: 'Delete', onClick: handleClickDelete }]}
      link={path}
      linkTitle="Edit draft"
      onClick={handleClickRow}
    />
  );
}
