/**
 * The cards in this file can be used to show the various entities (e.g. about a given
 * workflow, simulation, etc) in a grid format. The types are:
 * - BaseCard - <description>
 * - LinkCard - Wraps BaseCard in a react router Link. Pressing or middle clicking the
 *   component will take the user to the route specified in `to`.
 * - ClickableBaseCard - <description>
 */
import React, { ReactNode } from 'react';

import Paper from '@mui/material/Paper';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import {
  activeStyle,
  clickableStyle,
  hoverStyle,
} from 'client/app/apps/experiments/commonExperimentsStyles';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type BaseCardProps = {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
};

/**
 * @see BaseCard
 */
export function ClickableBaseCard(props: BaseCardProps) {
  const classes = useStyles();
  return (
    <div className={classes.clickable}>
      <BaseCard {...props} />
    </div>
  );
}

type LinkCardProps = BaseCardProps & {
  to: string;
  linkTitle?: string;
};

/**
 * Wraps BaseCard in a react router Link. Pressing or middle clicking the component will
 * take the user to the route specified in `to`.
 *
 * @see BaseCard
 */
export function LinkedBaseCard({ to, linkTitle, ...props }: LinkCardProps) {
  const classes = useStyles();
  return (
    <Link to={to} title={linkTitle} className={classes.clickable}>
      <BaseCard {...props} />
    </Link>
  );
}

/**
 * Used to show information about an antha 'thing', such as a workflow or experiment.
 *
 * Unlike ClickableBaseCard and LinkedBaseCard, there is no hover styling to indicate this
 * is clickable.
 */
export function BaseCard({ onClick, className, children }: BaseCardProps) {
  const classes = useStyles();
  return (
    <Paper
      onClick={onClick}
      variant="outlined"
      className={cx(classes.container, className)}
    >
      {children}
    </Paper>
  );
}

const useStyles = makeStylesHook(theme => ({
  clickable: {
    ...clickableStyle.style,
    '&:active': {
      ...activeStyle.style,
    },
    '& $container': {
      ...hoverStyle(theme).background,
    },
  },
  // The card is used to show information about a given workflow, simulation, etc within
  // antha. It presents information in a grid.
  container: {
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '8px',
    height: 'max-content',
    padding: theme.spacing(5, '20px'),
    alignItems: 'center',
    display: 'grid',
    gridColumnGap: theme.spacing(5),
    gridRowGap: theme.spacing(3),
  },
}));
