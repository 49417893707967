import {
  Measurement,
  PipettingHeight,
  PipettingOptions,
  WellContents,
  WellLocationOnDeckItem,
} from 'common/types/mix';
import { LiquidMovement } from 'common/types/mixPreview';

export const MOCK_WELL_LOCATION_ON_DECK: WellLocationOnDeckItem = {
  deck_item_id: '',
  row: 0,
  col: 0,
};
export const MOCK_VOLUME: Measurement = { value: 0, unit: 'ul' };
export const MOCK_CONTENT: WellContents = {
  kind: 'liquid_summary',
  id: '00008045',
  name: 'water',
  type: 'water',
  total_volume: MOCK_VOLUME,
};
export const MOCK_HEIGHT: PipettingHeight = {
  value: 0,
  unit: '',
  reference: 'well_bottom',
};
export const MOCK_PIPETTING: PipettingOptions = {
  height: MOCK_HEIGHT,
  liquid_level_follow: false,
  mixing: {
    cycles: 1,
    volume: MOCK_VOLUME,
    liquid_level_follow: false,
    height: MOCK_HEIGHT,
  },
};

/**
 * Generate a single channel transfer given a source and destination.
 */
export function generateMockSingleTransfer(
  from: WellLocationOnDeckItem = MOCK_WELL_LOCATION_ON_DECK,
  to: WellLocationOnDeckItem = MOCK_WELL_LOCATION_ON_DECK,
): LiquidMovement {
  return {
    from: { loc: from, new_content: MOCK_CONTENT, volume_change: MOCK_VOLUME },
    tipDestination: {
      loc: to,
      new_content: MOCK_CONTENT,
      volume_change: MOCK_VOLUME,
    },
    liquidDestination: {
      loc: to,
      new_content: MOCK_CONTENT,
      volume_change: MOCK_VOLUME,
    },
    volume: MOCK_VOLUME,
    wasted: MOCK_VOLUME,
    policy: '',
    asp: MOCK_PIPETTING,
    dsp: {
      ...MOCK_PIPETTING,
      touchoff: false,
      blowout: { height: MOCK_HEIGHT, volume: MOCK_VOLUME },
    },
    head: 0,
    multiDispenseIndex: 0,
    multiDispenseCount: 1,
  };
}
