import React, { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_INSTANCE_CONFIG_BY_DEVICE_ID } from 'client/app/api/gql/queries';
import { WorkflowConfig } from 'common/types/bundle';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import Autocomplete, { Option } from 'common/ui/filaments/Autocomplete';

/**
 * Structure of Protocol objects within plate washer device instance configs.
 */
type Protocol = {
  Name: string;
};

type Props = {
  onChange: (value: string | undefined) => void;
  workflowConfig?: WorkflowConfig;
} & ParameterEditorBaseProps<string>;

/**
 * An autocomplete which lists all available wash protocols for the configured
 * plate washer device. The list of protocols is fetched from the instance
 * config. The user can still enter a value manually, useful for users who do
 * not have an integrated plate washer.
 */
export default function PlateWasherProtocolDropdown({
  value,
  placeholder,
  onChange,
  isDisabled,
  workflowConfig,
}: Props) {
  // We've assumed only one plate washer will be configured here.
  const plateWasherID = useMemo(() => {
    if (!workflowConfig) {
      return undefined;
    }
    const plateWashers = workflowConfig.PlateWasher?.Devices;
    // The Devices object is keyed by ID.
    const plateWasherIDs = Object.keys(plateWashers || {});
    return plateWasherIDs[0] as string | undefined;
  }, [workflowConfig]);

  // Fetch the instance config for the plate washer.
  const { data } = useQuery(QUERY_INSTANCE_CONFIG_BY_DEVICE_ID, {
    // Do not run this query if there's no plate washer
    skip: plateWasherID === undefined,
    variables:
      plateWasherID === undefined ? undefined : { id: plateWasherID as DeviceId },
  });

  const protocolOptions = useMemo<Option<string>[]>(() => {
    const device = data?.devices[0];
    const instanceConfig = device?.instanceConfig?.config?.InstanceConfig;
    // Get the protocols from the device instance config
    const protocolConfigs: Protocol[] = instanceConfig?.AvailableProtocols || [];
    return protocolConfigs.map(({ Name }) => ({ label: Name, value: Name }));
  }, [data]);

  return (
    <Autocomplete
      placeholder={placeholder}
      valueLabel={value}
      options={protocolOptions}
      onChange={onChange}
      isDisabled={isDisabled}
      acceptCustomValues
    />
  );
}
