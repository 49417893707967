import React, { ReactNode, useMemo } from 'react';

import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FunctionsIcon from '@mui/icons-material/Functions';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import DOEForm from 'client/app/components/DOEFactorForm/components/DOEForm';
import { FactorKind } from 'client/app/components/DOEFactorForm/types';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import DOESingleFactorIcon from 'common/ui/icons/DOESingleFactorIcon';
import LiquidsIcon from 'common/ui/icons/LiquidsIcon';

type Props = {
  onCancel?: () => void;
  onChoice: (type: FactorKind) => void;
  className?: string;
  disabled?: boolean;
  permittedFactorTypes: FactorKind[];
};

function TypeButton({
  type,
  onChoice,
  icon,
  title,
  children,
  disabled,
}: {
  type: FactorKind;
  onChoice: (type: FactorKind) => void;
  icon: ReactNode;
  title: string;
  children: ReactNode;
  disabled?: boolean;
}) {
  const classes = useStyles({ disabled });

  return (
    <button
      type="button"
      className={cx(classes.button, { [classes.buttonEnabled]: !disabled })}
      onClick={() => {
        onChoice(type);
      }}
      disabled={disabled}
    >
      <div className={classes.icon}>{icon}</div>
      <Typography variant="subtitle2" className={classes.type}>
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {children}
      </Typography>
    </button>
  );
}

export default function ChooseFactorType({
  onChoice,
  onCancel,
  className,
  permittedFactorTypes,
}: Props) {
  const classes = useStyles();

  const permitted = useMemo(() => new Set(permittedFactorTypes), [permittedFactorTypes]);

  return (
    <DOEForm
      className={className}
      actions={
        <Button
          variant="tertiary"
          size="small"
          type="button"
          onClick={() => onCancel?.()}
        >
          Cancel
        </Button>
      }
    >
      <div className={cx(classes.container)}>
        <Typography variant="caption" color="textSecondary">
          Choose what you would like to add...
        </Typography>
        <TypeButton
          title="New Factor"
          onChoice={onChoice}
          icon={<DOESingleFactorIcon />}
          type="factor"
          disabled={!permitted.has('factor')}
        >
          Add a multi-level factor that will be included in the workflow and the design
          analysis.
        </TypeButton>

        {permitted.has('derived') && (
          <TypeButton
            title="New Derived Factor"
            onChoice={onChoice}
            icon={<FunctionsIcon />}
            type="derived"
          >
            Add a factor whose levels will be derived from those of another factor, either
            as a numerical formula or as a categorial mapping.
          </TypeButton>
        )}

        {permitted.has('mutual-exclusion') && (
          <TypeButton
            title="New Mutual Exclusion"
            onChoice={onChoice}
            icon={<CompareArrowsIcon />}
            type="mutual-exclusion"
          >
            Add a group of mutually exclusive factors, all of which will be included in
            the workflow and design analysis, but only one of which will be active for any
            given run.
          </TypeButton>
        )}

        {permitted.has('constant') && (
          <TypeButton
            title="New Constant"
            onChoice={onChoice}
            icon={<LiquidsIcon />}
            type="constant"
          >
            Add a constant value that will be included in the workflow but not the design
            analysis.
          </TypeButton>
        )}
      </div>
    </DOEForm>
  );
}

const useStyles = makeStylesHook<string, { disabled: boolean }>(
  ({ spacing, palette }) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: spacing(6),
    },
    icon: {
      position: 'absolute',
      left: '-10px',
      top: spacing(3),
      padding: '6px',
      background: palette.primary.dark,
      display: 'flex',
      color: Colors.WHITE,
      borderRadius: '4px',
      boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.2)`,
    },
    button: {
      border: `1px solid ${Colors.GREY_20}`,
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
      padding: spacing(5, 6, 5, 8),
      gap: spacing(4),
      position: 'relative',
      boxShadow: `0px 1px 1px rgba(0, 0, 0, 0.14)`,
      textAlign: 'left',
      background: Colors.GREY_10,
    },
    buttonEnabled: {
      border: `1px solid ${Colors.GREY_30}`,
      background: `transparent`,
      cursor: 'pointer',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: spacing(5),
        background: Colors.BLUE_0,
        borderRadius: '3px 0 0 3px',
      },
      '&:hover': {
        background: Colors.BLUE_0,
        '&::before': {
          background: Colors.BLUE_10,
        },
        '& $icon': {
          background: Colors.BLUE_100,
        },
      },
    },
    type: {
      color: palette.primary.dark,
    },
  }),
);
