import React from 'react';

import MuiAlert, { AlertProps } from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

type Props = {
  /** Optional title. Will appear in bold at the top, like an header. */
  title?: string;
} & AlertProps;

export default function Alert({ severity, title, icon, ...alertProps }: Props) {
  return (
    <div>
      {title && <AlertTitle>{title}</AlertTitle>}
      <MuiAlert icon={icon} severity={severity} {...alertProps} />
    </div>
  );
}
