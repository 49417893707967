import React, { useCallback } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import cx from 'classnames';

import { MANUAL_DEVICE_DESCRIPTION } from 'common/constants/manual-device';
import { DeviceRunConfig, SimpleDevice } from 'common/types/device';
import Colors from 'common/ui/Colors';
import DeviceThumbnail from 'common/ui/components/DeviceThumbnail';
import IconButton from 'common/ui/components/IconButton';
import Switch from 'common/ui/components/Switch';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  device: SimpleDevice;
  deviceConfig?: DeviceRunConfig;
  error?: string;
  /** If present, this device an accessible device that's part of a group of devices. */
  accessibleDeviceProps?: {
    /** The value of the on / off Switch for this accessible device (device enabled / disabled). */
    accessibleDeviceSwitchValue: boolean;
    /** The user toggled the on / off Switch for this accessible device. */
    onAccessibleDeviceEnabledChange: (isEnabled: boolean) => void;
  };
  onRemoveDevice?: (deviceToRemove: SimpleDevice) => void;
};

export default React.memo(function DeviceListItem(props: Props) {
  const classes = useStyles();
  const { device, onRemoveDevice, deviceConfig, error } = props;

  const onSwitchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      props.accessibleDeviceProps?.onAccessibleDeviceEnabledChange(e.target.checked),
    [props],
  );

  const handleRemoveDevice = useCallback(() => {
    onRemoveDevice?.(device);
  }, [device, onRemoveDevice]);

  return (
    <ListItem
      key={device.id}
      className={cx({
        [classes.error]: !!error,
        [classes.standaloneDevice]: onRemoveDevice,
      })}
    >
      <ListItemAvatar>
        <DeviceThumbnail imageUrl={device.imageUrl} />
      </ListItemAvatar>
      <ListItemText
        className={classes.itemText}
        primary={
          <>
            {device.name}
            {deviceConfig && (
              <small title={deviceConfig.name}>
                <i>&nbsp;({deviceConfig.name})</i>
              </small>
            )}
          </>
        }
        secondary={
          <strong>
            {device.anthaLangDeviceClass === 'Manual'
              ? MANUAL_DEVICE_DESCRIPTION
              : device.model}
          </strong>
        }
      />
      {/* Standalone devices have an inline Clear button */}
      {onRemoveDevice && (
        <div className={classes.clearButton}>
          <IconButton onClick={handleRemoveDevice} size="xsmall" icon={<ClearIcon />} />
        </div>
      )}
      {props.accessibleDeviceProps && (
        <Switch
          color="primary"
          checked={props.accessibleDeviceProps.accessibleDeviceSwitchValue ?? false}
          onChange={onSwitchChange}
        />
      )}
    </ListItem>
  );
});

const useStyles = makeStylesHook(theme => ({
  itemText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
  },
  error: {
    border: `1px solid ${Colors.ERROR}`,
  },
  clearButton: {
    marginRight: theme.spacing(3),
  },
  standaloneDevice: {
    paddingRight: 0,
  },
}));
