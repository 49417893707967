import React, { useCallback, useEffect, useRef } from 'react';

import 'graphiql/graphiql.css';

import { useAuth0 } from '@auth0/auth0-react';
import GraphiQL from 'graphiql';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

async function graphQLFetcher(
  getAccessToken: () => Promise<string>,
  URL: string,
  graphQLParams: any,
) {
  const init = {
    method: 'post',
    headers: { 'Content-Type': 'application/json' } as any,
    body: JSON.stringify(graphQLParams),
  };
  try {
    const token = await getAccessToken();
    init.headers.authorization = `bearer ${token}`;
  } catch (e) {
    console.error(e);
  }
  const response = await fetch(URL, init);
  return response.json();
}

function useGraphQLFetcher(url: string) {
  const { getAccessTokenSilently } = useAuth0();
  return useCallback(
    (graphQLParams: any) => graphQLFetcher(getAccessTokenSilently, url, graphQLParams),
    [getAccessTokenSilently, url],
  );
}

type Props = { url: string };
export default function GraphQLPlayground({ url }: Props) {
  const fetcher = useGraphQLFetcher(url);
  const classes = useStyles();
  const myGraphiQL = useRef<GraphiQL>(null);

  // Workaround for issue where editor doesn't render on page load
  // (https://github.com/graphql/graphiql/issues/770)
  useEffect(() => {
    setTimeout(() => myGraphiQL.current?.refresh(), 500);
  }, [myGraphiQL]);

  return (
    <div className={classes.container}>
      <GraphiQL ref={myGraphiQL} fetcher={fetcher} />
    </div>
  );
}

const useStyles = makeStylesHook({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    // The layout gets broken without that.
    '& *': { boxSizing: 'content-box' },
  },
});
