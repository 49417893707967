import React, { useCallback } from 'react';

import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';

import HelpIcon from 'client/app/icons/Help';
import { TOP_NAV_MENU_ID } from 'client/app/registry';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export function DocsButton() {
  const classes = useStyles();
  const onClickGuides = useCallback(() => {
    logEvent('open-help-and-docs', TOP_NAV_MENU_ID);
    window.open('https://intercom.help/antha/en', '_blank', 'noopener noreferrer');
  }, []);

  return (
    <Tooltip title="Help Center">
      <ListItem button disableRipple onClick={onClickGuides} className={classes.center}>
        <HelpIcon />
      </ListItem>
    </Tooltip>
  );
}

const useStyles = makeStylesHook({
  center: {
    justifyContent: 'center',
  },
});

/**
 * TODO: remove the top DocsButton and leave only the below one when SYN-4995 Breadcrumbs are ready
 */
export default function BreadcrumbsDocsButton() {
  const onClickGuides = useCallback(() => {
    logEvent('open-help-and-docs', TOP_NAV_MENU_ID);
    window.open('https://intercom.help/antha/en', '_blank', 'noopener noreferrer');
  }, []);

  return (
    <Tooltip title="Help Center">
      <Help data-heap-tracking="breadcrumbs-docs-button" onClick={onClickGuides}>
        <HelpIcon />
      </Help>
    </Tooltip>
  );
}

const Help = styled('span')(({ theme }) => ({
  cursor: 'pointer',
  marginLeft: theme.spacing(6),
  marginRight: theme.spacing(5),
}));
