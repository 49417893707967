import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ButtonGroup from '@mui/material/ButtonGroup';
import cx from 'classnames';

import { BUILDER_CONTROLS_PADDING_TOP_BOTTOM } from 'client/app/apps/workflow-builder/ControlOverlay';
import Panel from 'client/app/apps/workflow-builder/panels/Panel';
import { SimulationPart } from 'client/app/apps/workflow-builder/panels/simulations/useSimulations';
import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import useRequestHelpForSimulation from 'client/app/hooks/useRequestHelpForSimulation';
import { simulationRoutes } from 'client/app/lib/nav/actions';
import { formatSimulationError } from 'client/app/lib/workflow/format';
import { ScreenRegistry } from 'client/app/registry';
import { ElementConfigurationSpec } from 'common/types/elementConfiguration';
import Button from 'common/ui/components/Button';
import RouteButton from 'common/ui/components/navigation/RouteButton';
import SimulationError from 'common/ui/components/simulation-details/SimulationError';
import { TOP_NAV_HEIGHT_NUMBER } from 'common/ui/components/TopNav/topNavStyles';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type SimulationErrorPanelProps = {
  simulation: SimulationPart;
  onClose: () => void;
  className: string;
  simulationsPanelScrollableRef: React.RefObject<HTMLDivElement>;
  elementConfigs?: Record<string, ElementConfigurationSpec | null>;
};

/**
 * SimulationErrorPanel wraps our common Panel component and will display
 * all errors for the given simulation in the panel.
 */
export default React.memo(function SimulationErrorPanel(
  props: SimulationErrorPanelProps,
) {
  const {
    simulation,
    onClose,
    className,
    simulationsPanelScrollableRef,
    elementConfigs,
  } = props;
  const classes = useStyles();

  const errors = useMemo(() => {
    const errors = simulation?.errors?.map(error => {
      return formatSimulationError(error, elementConfigs);
    });
    return errors;
  }, [elementConfigs, simulation?.errors]);

  const { screenId } = useContext(ScreenContext);
  const { requestHelpDialog, handleRequestHelp } = useRequestHelpForSimulation(
    screenId!, // We should always have a screenId when we are rendering simulation error panel.
    simulation.id,
    simulation.name,
  );

  // We want to align the error panel with the top of the simulations panel.
  // The size of this might vary if we add more filters, so we calculate the
  // top of the DOM rect and adjust for the TOP_NAV and BUILDER_GRID padding.
  const [offSetY, setoffSetY] = useState(0);
  useLayoutEffect(() => {
    const offSetYCalculated = simulationsPanelScrollableRef?.current
      ? simulationsPanelScrollableRef?.current?.getBoundingClientRect().top -
        TOP_NAV_HEIGHT_NUMBER -
        BUILDER_CONTROLS_PADDING_TOP_BOTTOM
      : 0;
    setoffSetY(offSetYCalculated);
  }, [simulationsPanelScrollableRef]);

  return (
    <>
      <Panel
        title="Error details"
        className={cx(className, classes.wide)}
        onClose={onClose}
        offSetY={offSetY}
        panelContent="SimulationError"
        panelActions={
          <PanelActions
            handleRequestHelp={handleRequestHelp}
            screenId={screenId as string}
            simulationId={simulation.id}
          />
        }
      >
        {errors && (
          <SimulationError errors={errors} triggeredAt={new Date(simulation.startedAt)} />
        )}
      </Panel>
      {requestHelpDialog}
    </>
  );
});

type PanelActionsProps = {
  handleRequestHelp: () => void;
  screenId: string;
  simulationId: string;
};

function PanelActions(props: PanelActionsProps) {
  const classes = useStyles();
  const { handleRequestHelp, screenId, simulationId } = props;

  const handleRequestHelpFromErrorPanel = useCallback(() => {
    logEvent('request-help', screenId, 'error-panel');
    void handleRequestHelp();
  }, [handleRequestHelp, screenId]);

  return (
    <ButtonGroup className={classes.actions}>
      <RouteButton
        className={classes.button}
        variant="outlined"
        size="medium"
        color="inherit"
        label="Open"
        route={simulationRoutes.openInSimulationDetails}
        routeParam={{
          simulationId,
        }}
        endIcon={<OpenInNewOutlinedIcon />}
        fullWidth
        openInNewTab
        logEventCategory={ScreenRegistry.WORKFLOW}
      />
      <Button
        className={classes.button}
        variant="secondary"
        size="medium"
        onClick={handleRequestHelpFromErrorPanel}
        fullWidth
      >
        Request help
      </Button>
    </ButtonGroup>
  );
}

const useStyles = makeStylesHook(theme => ({
  wide: {
    width: '444px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    flex: 1,
    borderColor: theme.palette.divider,

    '&:first-child': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    '&:last-child': {
      borderLeft: 'none',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  container: {
    overflowWrap: 'break-word',
    margin: theme.spacing(5, 0),
  },
  headerBottomSpacing: {
    marginBottom: theme.spacing(2),
  },
  headerTopSpacing: {
    marginTop: theme.spacing(6),
  },
  textPreWrap: {
    whiteSpace: 'pre-wrap',
  },
}));
