import React, { useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';

import AutocompleteWithParameterValues from 'client/app/components/Parameters/AutocompleteWithParameterValues';
import { WellParametersProps } from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import { formatVolumeObj, validateMeasurement } from 'common/lib/format';
import { getFirstValue, mapValues } from 'common/object';
import { RoboColumnContent, RoboColumnSpecs } from 'common/types/robocolumns';
import GenericInputEditor from 'common/ui/components/ParameterEditors/GenericInputEditor';
import Autocomplete from 'common/ui/filaments/Autocomplete';

/**
 * Antha type for Resin Name, used for looking up suggested values in the resin
 * autocomplete input.
 */
const RESIN_NAME_TYPE = 'github.com/Synthace/antha/stdlib/schemas/aliases.ResinName';

/**
 * We can help users by suggesting typical volumes for resins within
 * robocolumns. These vary depending on the type of robocolumn the user has
 * selected (long or short). Eventually we may want to make these definitions
 * user configurable and move them into PostgreSQL.
 */
const ROBOCOLUMN_SPECS_BY_TYPE: { [type: string]: RoboColumnSpecs } = {
  'Short RoboColumns': {
    typicalResinVolumes: [
      { value: 50, unit: 'ul' },
      { value: 100, unit: 'ul' },
      { value: 200, unit: 'ul' },
    ],
  },
  'Long RoboColumns': {
    typicalResinVolumes: [
      { value: 450, unit: 'ul' },
      { value: 500, unit: 'ul' },
      { value: 600, unit: 'ul' },
    ],
  },
};

type Props = {
  /**
   * Used for resin volume suggestions
   */
  roboColumnType: string;
} & WellParametersProps<RoboColumnContent>;

/**
 * Bespoke version of the Plate Contents Editor for setting robocolumn name, resin, and
 * resin volume for a given list of wells within a layout `{ well => robocolumn }`.
 */
export default function RoboColumnParameters({
  onChange,
  roboColumnType,
  contentsByWell,
  isDisabled,
}: Props) {
  const roboColumnSpecs = ROBOCOLUMN_SPECS_BY_TYPE[roboColumnType];

  // Contents of each well within the group will be identical, so we can
  // arbitrarily pick one.
  const contentsOfFirstWell = useMemo(
    () => getFirstValue(contentsByWell),
    [contentsByWell],
  );

  const wellsSorted = useMemo<string[]>(
    () => [...contentsByWell.keys()].sort(),
    [contentsByWell],
  );

  const [resin, setResin] = useState<string | undefined>(contentsOfFirstWell?.Resin);

  const [volume, setVolume] = useState<string | undefined>(contentsOfFirstWell?.Volume);

  // The RoboColumn field denotes the name of the robocolumn, and will be
  // unique for each well. A user can edit these as a string array. We sort
  // the array by well location (ie A1, A2, B1).
  const [names, setNames] = useState<string>(() =>
    wellsSorted
      .map(wellLocation => contentsByWell.get(wellLocation)?.RoboColumn || '')
      .join('\n'),
  );

  // When the states change, trigger onChange with new well contents
  useEffect(() => {
    const newNames = (names || '').split('\n');
    const isValid =
      !!resin && newNames.length === contentsByWell.size && validateMeasurement(volume);
    const newContentsByWell = mapValues(contentsByWell, wellLocation => ({
      Resin: resin,
      Volume: volume,
      RoboColumn: newNames[wellsSorted.indexOf(wellLocation)],
    }));
    onChange(newContentsByWell, isValid);
  }, [contentsByWell, names, onChange, resin, volume, wellsSorted]);

  const suggestedVolumes = useMemo(() => {
    if (!roboColumnSpecs) {
      return [];
    }
    return roboColumnSpecs?.typicalResinVolumes
      .map(volume => formatVolumeObj(volume, false))
      .map(value => ({ label: value, value }));
  }, [roboColumnSpecs]);

  return (
    <>
      <Box mb={1}>
        <InputLabel shrink>Resin</InputLabel>
        <AutocompleteWithParameterValues
          valueLabel={resin}
          onChange={setResin}
          anthaType={RESIN_NAME_TYPE}
          acceptCustomValues
          isRequired
        />
      </Box>
      <Box mb={1}>
        <InputLabel shrink>Volume of resin</InputLabel>
        <Autocomplete
          valueLabel={volume ?? ''}
          onChange={setVolume}
          options={suggestedVolumes}
          acceptCustomValues
          isRequired
        />
      </Box>
      <Box mb={1}>
        <InputLabel shrink>RoboColumn Names</InputLabel>
        <GenericInputEditor
          type=""
          value={names}
          onChange={setNames}
          isDisabled={isDisabled}
          isRequired
          multiline
        />
      </Box>
    </>
  );
}
