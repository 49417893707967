import React, { useCallback, useState } from 'react';

import { useQuery } from '@apollo/client';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { QUERY_All_SHAREABLE_ORGS_AND_ORIGIN_ORG } from 'client/app/api/gql/queries';
import DialogActions from 'common/ui/components/Dialog/DialogActions';
import SelectionActions from 'common/ui/components/Dialog/SelectionActions';
import SearchField from 'common/ui/components/SearchField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

export type OrgType = {
  id: string;
  name: string;
  humanIdentifier: string;
};

/**
 * The container takes care of fetch the orgs, and the workflow origin org.
 * It also apply the query filter.
 * It then render the Dialog.
 */
export default function ShareWithOrganisationsDialogContainer(
  props: { workflowId: WorkflowId } & DialogProps<OrgType | null>,
) {
  const { workflowId } = props;
  const { data } = useQuery(QUERY_All_SHAREABLE_ORGS_AND_ORIGIN_ORG, {
    variables: { workflowId },
  });
  const [query, setQuery] = useState('');
  const shareOriginOrg = data?.workflow?.shareOriginOrg;

  // If the workflow was originally shared with you, you can only share it back with the origin org.
  // If the workflow has been created in your org, your choice is wider.
  const orgs = (
    shareOriginOrg ? [shareOriginOrg] : data?.shareableOrgs ?? []
  ) as readonly OrgType[];
  return (
    <ShareWithOrganisationsDialog
      {...props}
      orgs={orgs.filter(o =>
        o.humanIdentifier?.toLowerCase().includes(query.toLowerCase()),
      )}
      query={query}
      onQueryChange={setQuery}
    />
  );
}

type Props = {
  orgs: OrgType[];
  onQueryChange: (query: string) => void;
  query: string;
} & DialogProps<OrgType | null>;

export function ShareWithOrganisationsDialog(props: Props) {
  const { orgs, onQueryChange, onClose, isOpen } = props;
  const classes = useStyles();

  // We want to make sure the state is reset when closing.
  // This will close and reset the selection state.
  const onCleanClose = useCallback(
    (result: OrgType | null) => {
      onClose(result);
      setSelectedOrg(null);
    },
    [onClose],
  );

  const [selectedOrg, setSelectedOrg] = useState<OrgType | null>(null);
  const handleCancel = useCallback(() => onCleanClose(null), [onCleanClose]);
  const handleConfirm = useCallback(
    () => onCleanClose(selectedOrg),
    [onCleanClose, selectedOrg],
  );
  const clearOrg = useCallback(() => setSelectedOrg(null), [setSelectedOrg]);
  const toggleOrgSelection = useCallback(
    (org: OrgType) => {
      setSelectedOrg(selectedOrg => {
        if (selectedOrg?.id === org.id) {
          return null;
        }
        return org;
      });
    },
    [setSelectedOrg],
  );
  return (
    <Dialog open={isOpen} onClose={handleCancel} fullWidth maxWidth="sm">
      <DialogTitle>Share with other Organisations</DialogTitle>
      <DialogContent className={classes.content}>
        <SearchField onQueryChange={onQueryChange} />
        <List className={classes.scroll}>
          {orgs.map(org => (
            <OrgItem
              key={org.id}
              org={org}
              checked={selectedOrg?.id === org.id}
              toggleOrg={toggleOrgSelection}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <SelectionActions
          handleClear={clearOrg}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
          selectedItems={selectedOrg ? 1 : 0}
          itemLabel="Organisation"
          primaryActionLabel="share"
        />
      </DialogActions>
    </Dialog>
  );
}

const OrgItem = React.memo(function OrgItem(props: {
  org: OrgType;
  checked?: boolean;
  toggleOrg: (org: OrgType) => void;
}) {
  const onToggle = () => props.toggleOrg(props.org);
  return (
    <ListItem selected={props.checked} onClick={onToggle}>
      <ListItemText primary={props.org.name} />
    </ListItem>
  );
});

const useStyles = makeStylesHook({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  scroll: {
    overflow: 'auto',
  },
});
