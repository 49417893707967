import React, { useCallback } from 'react';

import isWorkflowReadonly from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import { useGetSelectedMainDevice } from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import SettingsPanelContainer from 'client/app/apps/workflow-builder/panels/workflow-settings/SettingsPanelContainer';
import ParameterEditor from 'client/app/components/Parameters/ParameterEditor';
import { useDeviceTipTypes } from 'client/app/lib/workflow/useDeviceTipTypes';
import { TIPTYPES } from 'client/app/lib/workflow/workflowConfigProperties';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { sanitiseParameterValue } from 'common/elementConfiguration/parameterUtils';
import { ParameterValue } from 'common/types/bundle';

type TipTypeSettingOptionProps = {
  onChange: (parameterName: string, value: ParameterValue) => void;
  value: ParameterValue;
};
export function TipTypeSettingOption(props: TipTypeSettingOptionProps) {
  const { onChange, value } = props;

  const { isReadonly } = useWorkflowBuilderSelector(state => ({
    isReadonly: isWorkflowReadonly(state.editMode, state.source),
  }));
  const { deviceId, selectedDevice } = useGetSelectedMainDevice();
  const tipTypeProps = useDeviceTipTypes(deviceId, selectedDevice?.runConfigId);

  const sanitisedOnChange = useCallback(
    (value: ParameterValue) => {
      onChange(TIPTYPES.name, sanitiseParameterValue(value));
    },
    [onChange],
  );

  return (
    <SettingsPanelContainer
      title="Tip Types"
      helpContent="Synthace will calculate the best tip types for your workflow, but if you would prefer to select them yourself, you can."
      additionalHelpLinkUrl="https://intercom.help/antha/en/articles/5550553-select-the-tip-types-that-you-want-to-use"
    >
      <ParameterEditor
        anthaType={TIPTYPES.type}
        parameter={TIPTYPES}
        editorProps={tipTypeProps}
        value={value}
        onChange={sanitisedOnChange}
        isDisabled={isReadonly}
      />
    </SettingsPanelContainer>
  );
}
