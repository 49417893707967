import { BreadcrumbsEntityEnum } from 'client/app/gql';
import { AnthaPlatformScreenID, ScreenRegistry } from 'client/app/registry';
import { NavItem, ScreenID } from 'common/ui/navigation';

/**
 * Determines which button in the Top Nav is selected/highlighted.
 * Use "None" to not highlight any.
 **/
type Category = 'Experiments' | 'Inventory' | 'Profile' | 'None';

// Map each screen id with additional info such as the desired label.
export type ScreenDetails = {
  id: AnthaPlatformScreenID;
  label: string;
  category: Category;
  entity?: BreadcrumbsEntityEnum;
};

export function getScreenInfo(screenId: ScreenID): ScreenDetails {
  const knownScreenInfo = screenInfo[screenId as AnthaPlatformScreenID];

  if (!knownScreenInfo) {
    return {
      id: ScreenRegistry.NOT_FOUND_404,
      ...screenInfo[ScreenRegistry.NOT_FOUND_404],
    };
  }

  return { id: screenId as AnthaPlatformScreenID, ...knownScreenInfo };
}

const screenInfo: Record<AnthaPlatformScreenID, Omit<ScreenDetails, 'id'>> = {
  // e.g. antha.ninja/#/ - Redirects to Experiments page
  [ScreenRegistry.HOME]: {
    label: 'Experiments',
    category: 'Experiments',
    entity: BreadcrumbsEntityEnum.EXPERIMENT,
  },
  [ScreenRegistry.NOT_FOUND_404]: {
    label: 'Page Not Found',
    category: 'None',
  },

  [ScreenRegistry.FEATURE_TOGGLE]: {
    label: 'Feature Toggles',
    category: 'None',
  },
  [ScreenRegistry.EXPERIMENTS]: {
    label: 'Experiments',
    category: 'Experiments',
    entity: BreadcrumbsEntityEnum.EXPERIMENT,
  },
  [ScreenRegistry.EXPERIMENT_DETAIL]: {
    label: 'Experiment',
    category: 'Experiments',
    entity: BreadcrumbsEntityEnum.EXPERIMENT,
  },
  // Alphabetical by labels within Experiments
  [ScreenRegistry.WORKFLOW]: {
    label: 'Builder',
    category: 'Experiments',
    entity: BreadcrumbsEntityEnum.WORKFLOW,
  },
  [ScreenRegistry.CHERRY_PICKER]: {
    label: 'Cherry Picker',
    category: 'Experiments',
    entity: BreadcrumbsEntityEnum.CHERRY_PICKER,
  },
  [ScreenRegistry.DOE_DESIGN]: {
    label: 'DOE Design',
    category: 'Experiments',
  },
  [ScreenRegistry.DOE_TEMPLATE]: {
    label: 'DOE Template Editor',
    category: 'Experiments',
  },
  [ScreenRegistry.FORM]: {
    label: 'Form',
    category: 'Experiments',
    entity: BreadcrumbsEntityEnum.FORM,
  },
  [ScreenRegistry.LOCAL_DEV]: {
    label: 'Local Mix Preview',
    category: 'Experiments',
  },
  [ScreenRegistry.MARKDOWN_DEV]: {
    label: 'Markdown Preview',
    category: 'Experiments',
  },
  [ScreenRegistry.RESPONSE_ANALYSIS]: {
    label: 'Response Analysis',
    category: 'Experiments',
    entity: BreadcrumbsEntityEnum.VIS,
  },
  [ScreenRegistry.SIMULATION_DETAILS]: {
    label: 'Simulation Details',
    category: 'Experiments',
    entity: BreadcrumbsEntityEnum.SIMULATION,
  },
  [ScreenRegistry.EXECUTION_DETAILS]: {
    label: 'Execution Details',
    category: 'Experiments',
    entity: BreadcrumbsEntityEnum.EXECUTION,
  },
  [ScreenRegistry.TEMPLATE_WORKFLOWS]: {
    label: 'Template Builder',
    category: 'Experiments',
  },
  [ScreenRegistry.VISUALIZATION]: {
    label: 'Visualization',
    category: 'Experiments',
    entity: BreadcrumbsEntityEnum.VISUALISATION,
  },
  [ScreenRegistry.VIS_VIEW]: {
    label: 'Visualization',
    category: 'Experiments',
    entity: BreadcrumbsEntityEnum.VIS,
  },
  [ScreenRegistry.WORK_TREE]: {
    label: 'Map',
    category: 'Experiments',
    entity: BreadcrumbsEntityEnum.WORK_TREE,
  },
  //Inventory
  [ScreenRegistry.DEVICE_LIBRARY]: {
    label: 'Devices',
    category: 'Inventory',
  },
  [ScreenRegistry.FILE_BROWSER]: {
    label: 'Files',
    category: 'Inventory',
  },
  [ScreenRegistry.POLICY_LIBRARY]: {
    label: 'Liquid Policies',
    category: 'Inventory',
  },
  [ScreenRegistry.PLATE_TYPE_LIBRARY]: {
    label: 'Plate Types',
    category: 'Inventory',
  },
  [ScreenRegistry.PROFILE]: {
    label: 'Profile',
    category: 'Profile',
  },
};

export const STATIC_LIBRARY_NAV_ITEMS: NavItem[] = [
  {
    screenID: ScreenRegistry.FILE_BROWSER,
    label: 'Files',
    icon: 'antha:file-browser',
  },
  {
    screenID: ScreenRegistry.PLATE_TYPE_LIBRARY,
    label: 'Plate Types',
    icon: 'antha:plate',
  },
  {
    screenID: ScreenRegistry.DEVICE_LIBRARY,
    label: 'Devices',
    icon: 'antha:device',
  },
  {
    screenID: ScreenRegistry.POLICY_LIBRARY,
    label: 'Liquid Policies',
    icon: 'antha:policy',
  },
];
