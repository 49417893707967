import assert from 'assert';

import { MixPreview } from 'common/types/mixPreview';
import {
  applyStep,
  getInitialState,
  MixState,
} from 'common/ui/components/simulation-details/mix/MixState';

// Every n steps, we cache the full MixState.
// This makes it much quicker to compute the state. If we compute the state
// for step 250, and later we want to compute the state for step 270 (this
// is very common), we just need to apply 20 steps on top of the cached state,
// instead of starting at initial state and applying all 270 steps.
const CACHE_EVERY_N_STEPS = 50;

type MixStateCacheOptions = {
  /**
   * `noLabwareMovements` is used by the Plate Setup screen because there is not deck to
   * show these movements.
   */
  noLabwareMovements?: boolean;
};

// Speeds up computation of MixState at a specific step
export default class MixStateCache {
  // Sparse array (index -> MixState)
  private readonly cache: MixState[] = [];

  // Initialize with raw data fetched from the backend
  constructor(
    private readonly mixPreview: MixPreview,
    private options?: MixStateCacheOptions,
  ) {}

  computeState(currentStep: number): MixState {
    assert(currentStep >= 0, `Step must be >= 0. Was ${currentStep}`);
    assert(
      currentStep <= this.mixPreview.steps.length,
      `Step out of range: ${currentStep}. Max available step is ${this.mixPreview.steps.length}`,
    );

    const cachedState = this._findPreviousCachedState(currentStep);

    let state = cachedState;

    for (let stepIndex = cachedState.currentStep; stepIndex < currentStep; stepIndex++) {
      state = applyStep(
        state,
        stepIndex,
        this.mixPreview.steps[stepIndex],
        this.options?.noLabwareMovements,
      );
      if (stepIndex % CACHE_EVERY_N_STEPS === 0) {
        // Cache state
        // we've applied one step (step index 0) => currentStep is 1
        const currentStep = stepIndex + 1;
        this.cache[currentStep] = state;
      }
    }
    // Extra sanity check
    assert(
      state.currentStep === currentStep,
      `Current step should be ${currentStep}, was ${state.currentStep}`,
    );
    return state;
  }

  // Find cached state from latest step possible, smaller or equal to currentStep
  _findPreviousCachedState(currentStep: number): MixState {
    let bestCachedStep = -1;
    this.cache.forEach((_, cachedStepIdx) => {
      // Largest possible cached step, smaller or equal than current step
      if (cachedStepIdx <= currentStep && cachedStepIdx > bestCachedStep) {
        bestCachedStep = cachedStepIdx;
      }
    });
    if (bestCachedStep === -1) {
      // Nothing cached yet
      const initialState = getInitialState(this.mixPreview.deck);
      assert(
        initialState.currentStep === 0,
        `Initial step must have step 0, was ${initialState.currentStep}`,
      );
      return initialState;
    }
    const cachedState = this.cache[bestCachedStep];
    // Extra sanity checks
    assert(
      cachedState.currentStep <= currentStep,
      `Cached state should be from a step before ${currentStep}, was ${cachedState.currentStep}`,
    );
    assert(
      cachedState.currentStep === bestCachedStep,
      `Cached step should be ${currentStep}, was ${bestCachedStep}`,
    );
    return cachedState;
  }
}
