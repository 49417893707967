import React, { useContext } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { WorkTreeModeContext } from 'client/app/apps/work-tree/WorkTreeModeContext';
import { WorkTreeApplicationName } from 'client/app/gql';
import { pluralize } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type WorkTreeCreateModePanelProps = {
  handleCancel: () => void;
  handleConfirm: () => void;
  selectedItems: number;
  applicationName: WorkTreeApplicationName;
};

export function WorkTreeCreateModePanel({
  handleCancel,
  handleConfirm,
  selectedItems,
  applicationName,
}: WorkTreeCreateModePanelProps) {
  const context = useContext(WorkTreeModeContext);

  if (context.mode !== 'creating-block') {
    // We only show the panel in 'creating-block' mode.
    return null;
  }

  const inputResultRequirements = context.creatingBlock.inputResultRequirements;
  const minItems = inputResultRequirements.minItems;
  const maxItems = inputResultRequirements.maxItems ?? Infinity;

  const description = getDescription(applicationName, minItems, maxItems);

  const canConfirm = selectedItems >= minItems && selectedItems <= maxItems;

  const validationStatus = getValidationStatus(selectedItems, minItems, maxItems);

  const selectionMessage = getSelectionMessage(selectedItems, maxItems);

  return (
    <WorkTreeModePanel
      title={`${
        applicationName.charAt(0).toUpperCase() + applicationName.slice(1)
      } result data`}
      description={description}
      selectionMessage={selectionMessage}
      validationStatus={validationStatus}
      confirmText="Create"
      confirmDisabled={!canConfirm}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    />
  );
}

/**
 * Formats the description to show on the WorkTreeCreateModePanel
 */
function getDescription(
  applicationName: WorkTreeApplicationName,
  minItems: number,
  maxItems: number,
): string {
  if (minItems === maxItems) {
    return `Select ${pluralize(minItems, 'block')} to ${applicationName}`;
  } else if (minItems > 0 && maxItems < Infinity) {
    return `Select between ${minItems} and ${maxItems} blocks to ${applicationName}`;
  } else if (minItems === 0 && maxItems < Infinity) {
    return `Select up to ${pluralize(maxItems, 'block')} to ${applicationName}`;
  } else if (minItems > 0 && maxItems === Infinity) {
    return `Select at least ${pluralize(minItems, 'block')} to ${applicationName}`;
  }
  return '';
}

/**
 * Returns the ValidationStatus to pass to WorkTreeModePanel.
 *
 * The return statuses are:
 *
 * 'warning' in cases when the user exceeds the maximum selection,
 * 'ok' when the user has met the minimum selection, but not exceeded the maximum,
 * 'neutral' when the user has not yet met the minimum selection,
 */
function getValidationStatus(
  selectedItems: number,
  minItems: number,
  maxItems: number,
): ValidationStatus {
  if (selectedItems > 0) {
    if (selectedItems <= maxItems && selectedItems >= minItems) {
      return 'ok';
    } else if (selectedItems > maxItems) {
      return 'warning';
    }
  }
  return 'neutral';
}

/**
 * Returns the selectionMessage to show on WorkTreeModePanel
 */
function getSelectionMessage(selectedItems: number, maxItems: number): string {
  if (selectedItems > maxItems) {
    return `You have selected more than ${pluralize(maxItems, 'block')}`;
  }
  return `${pluralize(selectedItems, 'block')} selected`;
}

type WorkTreeHiddenModePanelProps = {
  handleCancel: () => void;
  handleConfirm: () => void;
  hiddenCount: number;
};

export function WorkTreeHiddenModePanel({
  handleCancel,
  handleConfirm,
  hiddenCount,
}: WorkTreeHiddenModePanelProps) {
  return (
    <WorkTreeModePanel
      title="Set visibility of blocks"
      selectionMessage={`${pluralize(hiddenCount, 'block')} hidden`}
      confirmText="Done"
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    />
  );
}

type ValidationStatus = 'neutral' | 'ok' | 'warning';

export type WorkTreeModePanelProps = {
  onCancel: () => void;
  onConfirm: () => void;
  title: string;
  description?: string;
  selectionMessage: string;
  validationStatus?: ValidationStatus;
  confirmDisabled?: boolean;
  confirmText: string;
};

/**
 * Non-model panel to show instructions and allow exiting from the current mode.
 */
export function WorkTreeModePanel({
  title,
  selectionMessage,
  validationStatus,
  description,
  confirmText,
  confirmDisabled,
  onConfirm,
  onCancel,
}: WorkTreeModePanelProps) {
  const classes = useStyles();
  return (
    <div className={classes.selectionActions}>
      <div className={classes.selectionActionText}>
        <Typography variant="h5">{title}</Typography>
        {description && <Typography noWrap>{description}</Typography>}
        <div className={classes.selectionMessage}>
          {validationStatus && <ValidationIcon validationStatus={validationStatus} />}
          <Typography
            noWrap
            className={cx({ [classes.warningText]: validationStatus === 'warning' })}
          >
            {selectionMessage}
          </Typography>
        </div>
      </div>
      <div className={classes.selectionActionButtons}>
        <Button onClick={onCancel} variant="tertiary" size="large">
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          variant="tertiary"
          size="large"
          color="primary"
          disabled={confirmDisabled}
        >
          {confirmText}
        </Button>
      </div>
    </div>
  );
}

type ValidationIconProps = {
  validationStatus: ValidationStatus;
};

function ValidationIcon(props: ValidationIconProps) {
  const { validationStatus } = props;
  const classes = useStyles();
  switch (validationStatus) {
    case 'neutral':
      return (
        <CheckCircleIcon
          className={cx(classes.validationIcon, classes.validationIconNeutral)}
        />
      );
    case 'ok':
      return (
        <CheckCircleIcon
          className={cx(classes.validationIcon, classes.validationIconOk)}
        />
      );
    case 'warning':
      return (
        <InfoIcon className={cx(classes.validationIcon, classes.validationIconWarning)} />
      );
  }
}

const useStyles = makeStylesHook(theme => ({
  selectionActions: {
    width: '420px',
    padding: theme.spacing(4, 3, 2, 4),
  },
  selectionActionButtons: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: theme.spacing(3),
  },
  selectionActionText: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
  selectionMessage: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  validationIcon: {
    width: '16px',
    height: '16px',
  },
  validationIconNeutral: {
    color: Colors.DARK_TEXT_PRIMARY,
  },
  validationIconOk: {
    color: theme.palette.success.main,
  },
  validationIconWarning: {
    color: theme.palette.warning.main,
  },
  warningText: {
    color: theme.palette.warning.main,
    fontWeight: 'bold',
  },
}));
