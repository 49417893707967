import React, { ReactNode } from 'react';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  header: string;
  children: ReactNode;
  actions?: ReactNode;
  fullWidth?: boolean;
  headerAction?: ReactNode;
};

export default function ResourcesCard({
  actions,
  header,
  headerAction,
  children,
  fullWidth,
}: Props) {
  const classes = useStyles();
  return (
    <Card
      className={classes.card}
      classes={fullWidth ? { root: classes.fullWidth } : undefined}
    >
      <StyledCardHeader
        action={headerAction}
        title={
          <Typography variant="h2" component="span">
            {header}
          </Typography>
        }
      />
      <StyledCardContent>{children}</StyledCardContent>
      {actions && <CardActions>{actions}</CardActions>}
    </Card>
  );
}

const StyledCardHeader = styled(CardHeader)(({ theme: { spacing } }) => ({
  borderBottom: `1px solid ${Colors.GREY_20}`,
  padding: spacing(5, 6),
}));

const StyledCardContent = styled(CardContent)(({ theme: { spacing } }) => ({
  padding: spacing(6),
}));

const useStyles = makeStylesHook({
  card: {
    display: 'flex',
    height: 'fit-content',
    // Make this card as wide as the other cards
    flex: 1,
    minWidth: '320px',
    maxWidth: '700px',
  },
  fullWidth: {
    maxWidth: '100%',
  },
});
