import { useCallback } from 'react';

import * as xhr from 'client/app/lib/xhr';
import { downloadBlob } from 'common/lib/download';

export default function useDownloadWorkflowBundle() {
  const get = xhr.useGet();
  return useCallback(
    async function downloadWorkflowBundle(
      workflowId: string,
      version: number,
      workflowName: string,
    ) {
      const res = await get(`/web/v2/workflow/download/${workflowId}/${version}`);
      const filename = `${workflowName?.trim() || 'workflow'}.zip`;
      downloadBlob(await res.blob(), filename);
    },
    [get],
  );
}
