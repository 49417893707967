import React, { useCallback } from 'react';

import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { QUERY_FIND_WORK_TREES } from 'client/app/apps/work-tree/queries';
import { useExperimentChipPickerContext } from 'client/app/components/nav/experiment-chip-picker/ExperimentChipPickerContext';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import DOEIcon from 'common/ui/icons/DOEIcon';
import { WorkflowIcon } from 'common/ui/icons/Workflow';
import { isNotNull } from 'common/utils';

type Props = {
  workflowId: WorkflowId;
};

export default function WorkflowBuilderMode({ workflowId }: Props) {
  const classes = useStyles();
  const activeMode = useWorkflowBuilderSelector(state => state.mode);
  const dispatch = useWorkflowBuilderDispatch();

  const isEnabledDOE = useFeatureToggle('NEW_DOE');

  const { showPopover: togglePopover } = useExperimentChipPickerContext();

  const { data, loading: queryWorkTreesLoading } = useQuery(QUERY_FIND_WORK_TREES, {
    variables: {
      workflowId,
    },
    fetchPolicy: 'cache-and-network',
  });

  const parentExperiments: ExperimentId[] = data?.findWorkTrees.filter(isNotNull) ?? [];
  const existsInExperiment = parentExperiments.length > 0;

  const switchToDOEMode = useCallback(() => {
    if (!existsInExperiment) {
      togglePopover('DOE');
    } else {
      dispatch({ type: 'switchMode', payload: 'DOE' });
    }
  }, [dispatch, existsInExperiment, togglePopover]);

  return isEnabledDOE ? (
    <main className={classes.container}>
      <button
        type="button"
        className={cx(classes.mode, { active: activeMode === 'Build' })}
        onClick={() => dispatch({ type: 'switchMode', payload: 'Build' })}
      >
        <WorkflowIcon fontSize="medium" />
        <Typography variant="body1">Build</Typography>
      </button>
      <button
        type="button"
        disabled={queryWorkTreesLoading}
        className={cx(classes.mode, { active: activeMode === 'DOE' })}
        onClick={switchToDOEMode}
      >
        <DOEIcon fontSize="medium" />
        <Typography variant="body1">DOE</Typography>
      </button>
    </main>
  ) : null;
}

const MODE_SWITCH_WIDTH = 226;

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  container: {
    position: 'absolute',
    top: spacing(4),
    left: `calc(50vw - ${MODE_SWITCH_WIDTH / 2}px)`,
    height: '44px',
    width: `${MODE_SWITCH_WIDTH}px`,

    display: 'flex',
    alignItems: 'center',
    justifySelf: 'center',
    zIndex: 2,
    borderRadius: '50px',
    padding: spacing(2),
    background: Colors.GREY_5,
    boxShadow:
      '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
  },
  mode: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 24px',
    borderRadius: '50px',
    userSelect: 'none',
    cursor: 'pointer',
    border: '0',
    background: 'transparent',

    '& > svg': {
      marginRight: spacing(3),
    },
    '&.active': {
      background: palette.primary.main,
      color: Colors.GREY_0,
    },
  },
}));
