import React, { ReactNode } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CSSProperties } from '@mui/styles';

import Colors from 'common/ui/Colors';

// This file contains components shared by the pieces of the results overview.

type CardProps = {
  header: string;
  children: ReactNode;
};

export function ResultsCard(props: CardProps) {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h3">{props.header}</Typography>
        {props.children}
      </CardContent>
    </Card>
  );
}

export const ResultsStyles: { [key: string]: CSSProperties } = {
  icon: {
    marginRight: '6px',
  },
  iconTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '2px 0',
  },
  clickable: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.GREY_20,
    },
  },
  progress: {
    margin: '3rem',
  },
};
