import React, { useCallback, useMemo } from 'react';

import MuiTab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';
import withStyles from '@mui/styles/withStyles';

import Colors from 'common/ui/Colors';

export type TabsInfo<T> = { label: string; value: T }[];
type Props<T> = {
  activeTab: T;
  tabsInfo: TabsInfo<T>;
  onChangeTab: (activeTab: T) => void;
  centered?: boolean;
  className?: string;
  minimumTabWidth?: string;
};

const StyledTab = withStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  selected: {
    color: Colors.PRIMARY_DARK,
  },
}))(MuiTab);

const StyledTabs = withStyles({
  indicator: {
    height: '3px',
    backgroundColor: Colors.PRIMARY_DARK,
  },
})(MuiTabs);

function Tabs<T>(props: Props<T>) {
  const { activeTab, centered, tabsInfo, onChangeTab, className, minimumTabWidth } =
    props;

  const handleTabChange = useCallback(
    (_: React.ChangeEvent<{}>, activeTab: T) => {
      onChangeTab(activeTab);
    },
    [onChangeTab],
  );

  const style = useMemo<React.CSSProperties>(() => {
    return {
      minWidth: minimumTabWidth ?? '172px',
    };
  }, [minimumTabWidth]);

  return (
    <StyledTabs
      value={activeTab}
      onChange={handleTabChange}
      centered={centered}
      className={className}
    >
      {tabsInfo.map(({ label, value }, index) => {
        return (
          <StyledTab
            data-heap-tracking={`tab-label-${label}`}
            label={label}
            key={`${label}-${index}`}
            value={value}
            style={style}
          />
        );
      })}
    </StyledTabs>
  );
}

// Type assertion is currently needed because generics get lost in React.memo. See
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087
export default React.memo(Tabs) as typeof Tabs;
