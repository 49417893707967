import React from 'react';

import Typography from '@mui/material/Typography';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  resultLabel?: string;
};

/* Returns formatted text indicating a negative result of a search.
 The logic of whether to call this should be implemented by the caller.
 Use for consistency when such an indicator is required. */
export default function NoResultsFound(props: Props) {
  const classes = useStyles();
  const { resultLabel = 'results' } = props;
  return (
    <div className={classes.container}>
      <Typography component="p" variant="h3">
        No {resultLabel} found.
      </Typography>
    </div>
  );
}

const useStyles = makeStylesHook({
  container: {
    marginTop: '200px',
    textAlign: 'center',
  },
});
