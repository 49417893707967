import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import momentTimezone from 'moment-timezone';

import { DatasetSettingsContext } from 'client/app/apps/experiments/dataset-settings/DatasetSettingsContext';
import { DatasetSettingsDetailsTab } from 'client/app/apps/experiments/dataset-settings/DatasetSettingsDetailsTab';
import { DatasetSettingsEventsTab } from 'client/app/apps/experiments/dataset-settings/DatasetSettingsEventsTab';
import { DatasetSettingsSampleMatchTab } from 'client/app/apps/experiments/dataset-settings/DatasetSettingsSampleMatchTab';
import { DatasetSettingsSamplesTab } from 'client/app/apps/experiments/dataset-settings/DatasetSettingsSamplesTab';
import { isBioreactorDataset } from 'client/app/apps/experiments/dataset-settings/isBioreactorDataset';
import { QUERY_DATASET_WITH_SETTINGS } from 'client/app/apps/experiments/gql/queries';
import { DatasetQuery, DatasetWithSettingsQuery } from 'client/app/gql';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  derivationId: DatasetDerivationId;
  dataset: DatasetQuery['dataset'];
  isReadonly?: boolean;
};

type DatasetSettingsTab = 'Details' | 'Samples' | 'Sample Matches' | 'Events';

export default function DatasetSettingsDialog({
  derivationId,
  dataset,
  isReadonly,
  isOpen,
  onClose,
}: DialogProps<DatasetWithSettingsQuery['dataset'] | null> & Props) {
  const classes = useStyles();
  const handleClose = () => onClose(null);

  const datasetType = isBioreactorDataset(dataset) ? 'bioreactor' : 'analytical';

  return (
    <ComplexActionDialog
      title={`Edit ${datasetType} dataset details`}
      isOpen={isOpen}
      onClose={handleClose}
      content={
        <DialogContents
          derivationId={derivationId}
          dataset={dataset}
          isReadonly={isReadonly}
        />
      }
      dialogActions={
        <DialogActions>
          {isReadonly && (
            <div className={classes.readonlyWarning}>
              <InfoOutlinedIcon />
              <Typography variant="caption" color="textSecondary">
                Set experiment to Editing to make changes
              </Typography>
            </div>
          )}
          <Button onClick={handleClose} variant="tertiary" color="primary">
            Done
          </Button>
        </DialogActions>
      }
      noActionsDivider
      paperClassName={classes.dialogPaper}
    />
  );
}

function DialogContents({ dataset, derivationId, isReadonly }: Props) {
  const {
    data,
    loading: isDatasetLoading,
    error: datasetError,
  } = useQuery(QUERY_DATASET_WITH_SETTINGS, {
    variables: { id: dataset.datasetId },
  });

  const datasetWithSettings = data?.dataset;

  if (datasetError) {
    return (
      <DialogContent>
        <GraphQLErrorPanel error={datasetError} />
      </DialogContent>
    );
  }

  if (!datasetWithSettings || isDatasetLoading) {
    return (
      <DialogContent>
        <LinearProgress />
      </DialogContent>
    );
  }

  return (
    <DatasetSettingsContext.Provider
      value={{ dataset: datasetWithSettings, derivationId, isReadonly }}
    >
      <DialogTabs />
    </DatasetSettingsContext.Provider>
  );
}

function DialogTabs() {
  const { dataset } = useContext(DatasetSettingsContext);
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState<DatasetSettingsTab>('Details');
  const [timezone, setTimezone] = useState(momentTimezone.tz.guess());
  const onTimezoneChange = useCallback((value: string) => {
    setTimezone(value);
  }, []);

  const handleTabChange = useCallback((newActiveTab: DatasetSettingsTab) => {
    setActiveTab(newActiveTab);
  }, []);

  const tabs = useMemo<DatasetSettingsTab[]>(
    () =>
      isBioreactorDataset(dataset)
        ? ['Details', 'Events', 'Samples']
        : ['Details', 'Sample Matches'],
    [dataset],
  );

  const tabsInfo = useMemo<TabsInfo<DatasetSettingsTab>>(
    () => tabs.map(label => ({ label, value: label })),
    [tabs],
  );

  return (
    <DialogContent className={classes.dialogContent}>
      <Tabs
        activeTab={activeTab}
        onChangeTab={handleTabChange}
        tabsInfo={tabsInfo}
        minimumTabWidth="0px"
      />
      {tabs.map(tab => (
        <div key={tab} hidden={tab !== activeTab} className={classes.tabContents}>
          <TabContents
            tab={tab}
            timezone={timezone}
            onTimezoneChange={onTimezoneChange}
          />
        </div>
      ))}
    </DialogContent>
  );
}

function TabContents({
  tab,
  timezone,
  onTimezoneChange,
}: {
  tab: DatasetSettingsTab;
  timezone: string;
  onTimezoneChange: (value: string) => void;
}) {
  switch (tab) {
    case 'Details':
      return (
        <DatasetSettingsDetailsTab
          onTimezoneChange={onTimezoneChange}
          timezone={timezone}
        />
      );
    case 'Samples':
      return <DatasetSettingsSamplesTab timezone={timezone} />;
    case 'Sample Matches':
      return <DatasetSettingsSampleMatchTab />;
    case 'Events':
      return <DatasetSettingsEventsTab timezone={timezone} />;
  }
}

const useStyles = makeStylesHook(theme => ({
  dialogPaper: {
    background: Colors.GREY_5,
    height: '1000px',
    width: '874px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  tabContents: {
    flexGrow: 1,
    overflow: 'auto',
    marginTop: theme.spacing(6),
    position: 'relative',
  },
  readonlyWarning: {
    flexGrow: 1,
    marginLeft: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));
