import { getEnvName } from 'common/lib/envs';

const validGAEnvs = ['localhost', 'dev', 'staging', 'anthahub', 'production', 'unknown'];

/**
 * Returns the appropriate env nickname to log in GoogleAnalytics.
 * @param host the domain of the environment (with port if non-standard, i.e. window.location.host)
 * @returns the appropriate env nickname to log in GoogleAnalytics
 */
export default function getGATrackingEnvironment(host: string | undefined) {
  const name = getEnvName(host || '');
  if (validGAEnvs.includes(name)) {
    return name;
  }
  return 'production';
}
