import React, { useCallback } from 'react';

import KitchenIcon from '@mui/icons-material/KitchenOutlined';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@mui/styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { SIMULATION_DETAILS_OVERVIEW_TAB_ID } from 'common/ui/AnalyticsConstants';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

type Props = {
  simulationFiletreePath: FiletreePath;
} & RouteComponentProps;

const useStyles = makeStyles({
  repoLinkIcon: {
    marginRight: '6px',
  },
});

const FileBrowserLink = function (props: Props) {
  const classes = useStyles();
  const { history, simulationFiletreePath } = props;

  const onClick = useCallback(() => {
    logEvent('open-file-browser', SIMULATION_DETAILS_OVERVIEW_TAB_ID);
    // Cannot use our navigation here.
    // The simulationFiletreePath contains slashes which doesn't work with our
    // navigation - it would URL encode the slashes.
    history.push(`/file-browser/-/${simulationFiletreePath}/`);
  }, [history, simulationFiletreePath]);

  return (
    <ListItem onClick={onClick} button dense>
      <KitchenIcon className={classes.repoLinkIcon} />
      <ListItemText primary="File Browser" />
    </ListItem>
  );
};

export default withRouter(FileBrowserLink);
