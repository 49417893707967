import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import useRefCallback from 'common/ui/hooks/useRefCallback';
/**
 * Context providing `earlyLoginWithRedirect` that call:
 *  - Auth0's loginWithRedirect if available
 *  - return a promise that will resolve with a call to loginWithRedirect when available
 *
 * It needs to be a child of Auth0Provider
 */

const DEFAULT: {
  earlyGetAccessTokenSilently: () => Promise<string>;
} = {
  earlyGetAccessTokenSilently: () => {
    throw new Error('EarlyLoginContext not implemented');
  },
};

export const EarlyTokenContext = React.createContext(DEFAULT);

/**
 * The `earlyAccessToken` promise below will be resolved in a React effect, once user is authtenticated.
 * We therefore need to extract and make available the Promise's resolve function.
 */
let resolveEarlyAccessToken: (value: string) => void;

/**
 * This promise an access token from Auth0 once user is authenticated.
 */
const earlyAccesToken: Promise<string> = new Promise((resolve, _reject) => {
  resolveEarlyAccessToken = resolve;
});

function EarlyToken(props: PropsWithChildren<{}>) {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      void getAccessTokenSilently().then(resolveEarlyAccessToken);
    }
  }, [getAccessTokenSilently, isAuthenticated]);

  const earlyGetAccessTokenSilently = useRefCallback(
    useCallback(() => {
      if (isAuthenticated) {
        return getAccessTokenSilently();
      }
      return earlyAccesToken;
    }, [getAccessTokenSilently, isAuthenticated]),
  );

  const value = useMemo(() => {
    return {
      earlyGetAccessTokenSilently,
    };
  }, [earlyGetAccessTokenSilently]);

  return (
    <EarlyTokenContext.Provider value={value}>
      {props.children}
    </EarlyTokenContext.Provider>
  );
}

export default React.memo(EarlyToken);
