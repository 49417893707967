import { useCallback } from 'react';

import * as xhr from 'client/app/lib/xhr';
import { downloadFileFromUrl } from 'common/lib/download';
import { filetreePathOrLinkToURLParam, removeScheme } from 'common/lib/format';
import { FileObject } from 'common/types/fileParameter';
import {
  ClientIndexBody,
  FILE_DOWNLOAD_NAME_PARAMETER,
  GetURLResponse,
  IndexResponse,
  ListResponse,
  UISearchBodyNoPath,
  UploadInput,
  UploadUrlResponse,
} from 'common/types/filetree';

// Permitted content types that are not in the usual MIME content type set.
const extensionToContentType: { [extension: string]: string } = {
  parquet: 'application/parquet',
  pb: 'application/protobuf',
};

// Determine the file type by going through these options:
// 1.content type, 2. extension 3. generic octet-stream.
function getContentType(file: File): string {
  // 1. Take the content type from the file
  let fileType = file.type;

  // 2. Use permitted extensions to match against.
  if (!fileType) {
    const extension = file.name.split('.').pop();
    if (extension) {
      fileType = extensionToContentType[extension];
    }
  }

  // 3. Fall back on `application/octet-stream` if there isn't a content type or doesn't have
  // a permitted extension type.
  if (!fileType) {
    fileType = 'application/octet-stream';
  }
  return fileType;
}

export function useUpload() {
  const postJSON = xhr.usePostJSON();
  return useCallback(
    async ({ file, metadata }: UploadInput): Promise<FileObject> => {
      const fileType = getContentType(file);
      const { url: uploadUrl, uploadID } = (await postJSON('/web/filetree/uploadURL', {
        body: {
          contentType: fileType,
        },
      })) as UploadUrlResponse;
      // 2. Upload directly to GCS using that signed url.
      const upload = await fetch(uploadUrl, {
        method: 'PUT',
        body: file,
        headers: { 'Content-type': fileType },
      });
      if (upload.status !== 200) {
        throw new Error('Upload failed');
      }
      // 3. index the path on filetree throuth the appserver
      const indexBody: ClientIndexBody = {
        uploadID,
        indexPath: metadata.indexPath,
        sizeBytes: file.size,
      };
      const indexRes = (await postJSON('/web/filetree/index', {
        body: indexBody,
      })) as IndexResponse;

      const uploadResponse = {
        Name: file.name,
        Path: indexRes.ftl,
      };
      return uploadResponse;
    },
    [postJSON],
  );
}

/** Get the file indexed at `filePath` from filetree */
async function _download(
  getGCSUrl: (filePath: string) => Promise<string>,
  filePath: string,
) {
  // 1. Request the signed google cloud storage (GCS) URL from filetree through the appserver
  const downloadUrl = await getGCSUrl(filePath);
  // 2. GET the file from GCS.
  return fetch(downloadUrl);
}

/** Get the GCS (Google Cloud Signed) URL for the file indexed at `filePath` from filetree */
function useGetGCSUrl() {
  const getJSON = xhr.useGetJSON();
  return useCallback(
    async (filePath: string, filename?: string) => {
      const queryParams: { [paramName: string]: string } = {};
      if (filename) {
        queryParams[FILE_DOWNLOAD_NAME_PARAMETER] = filename;
      }
      const param = filetreePathOrLinkToURLParam(filePath);
      const { url: downloadUrl } = (await getJSON(`/web/filetree/getURL/${param}`, {
        queryParams,
      })) as GetURLResponse;
      return downloadUrl;
    },
    [getJSON],
  );
}

// Download the file as a blob.
export function useDownload() {
  const getGCSUrl = useGetGCSUrl();
  return useCallback(
    async (filePath: string) => _download(getGCSUrl, filePath).then(r => r.blob()),
    [getGCSUrl],
  );
}

// Download the file as a JSON.
export function useDownloadJSON() {
  const getGCSUrl = useGetGCSUrl();
  return useCallback(
    (filePath: string) => _download(getGCSUrl, filePath).then(r => r.json()),
    [getGCSUrl],
  );
}

/** Initiates browser's download of the file indexed at `filePath` from filetree */
export function useUserDownloadFile() {
  const getGCSUrl = useGetGCSUrl();
  return useCallback(
    async (filePath: string, name: string) => {
      const url = await getGCSUrl(filePath, name);
      return downloadFileFromUrl(url, name);
    },
    [getGCSUrl],
  );
}

/**
 * Downloads the file stored at the given filetree path.  If the custom filename is not specified,
 * the last segment of the path will be used as the filename.
 */
export function useDownloadRemoteFileFromPath() {
  const userDownloadFile = useUserDownloadFile();
  return useCallback(
    async function downloadRemoteFileFromPath(
      path: string,
      saveAsCustomFilename?: string,
    ) {
      // Optional name under which the file should be saved locally. We will use the last part of the
      // path, but in theory, people should pass along the filename whenever possible.
      const fileName = saveAsCustomFilename
        ? saveAsCustomFilename
        : path.split('/').pop() || 'default_file';

      await userDownloadFile(path, fileName);
    },
    [userDownloadFile],
  );
}

/** Get list of folder and files at a given filetree path
 *  We currently default to newest order.
 */
export function useList() {
  const getJSON = xhr.useGetJSON();
  return useCallback(
    async (path: string, cursor?: string): Promise<ListResponse> => {
      const param = filetreePathOrLinkToURLParam(path);
      return getJSON(
        `/web/filetree/list/${param}${cursor ? `?cursor=${cursor}` : '?order=newest'}`,
      );
    },
    [getJSON],
  );
}

/** Search within a folder and get files  */
export function useSearch() {
  const postJSON = xhr.usePostJSON();
  return useCallback(
    (filePath: string, options: UISearchBodyNoPath): Promise<ListResponse> => {
      const uriMinusScheme = removeScheme(filePath);
      return postJSON(`/web/filetree/search`, {
        body: { ...options, path: uriMinusScheme },
      });
    },
    [postJSON],
  );
}

export function useCopyLink() {
  const postJSON = xhr.usePostJSON();
  return useCallback(
    (sourceLink: FiletreeLink, indexPath: FiletreePath, generatorSystem?: string) => {
      return postJSON('/web/filetree/copyLink', {
        body: {
          sourceLink,
          indexPath,
          generatorSystem,
        },
      }) as Promise<IndexResponse>;
    },
    [postJSON],
  );
}
