import React from 'react';

import { highlightStringMatches } from 'client/app/lib/workflow/format';

type Props = {
  text: string;
  query: RegExp | null;
};

class QueryHighlighter extends React.PureComponent<Props> {
  render() {
    const { query, text } = this.props;

    if (!query) {
      return text;
    }

    return highlightStringMatches(text, query);
  }
}

export default QueryHighlighter;
