import React, { useCallback, useMemo, useState } from 'react';

import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { DeckPositionChip } from 'client/app/apps/workflow-builder/DeckPositionChip';
import {
  getValidPositionsForLabwareType,
  LayoutOptions,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import DeckPositionHoverStatusChip from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/DeckPositionHoverStatusChip';
import {
  areLabwareTypesEqual,
  getLabwareForPosition,
  LabwareType,
} from 'client/app/state/LabwarePreference';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import Colors from 'common/ui/Colors';
import { DeckPositionRect } from 'common/ui/components/simulation-details/mix/DeckLayout';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * DeckPositionWithPreferences renders the position that a labware can be placed along with info
 * regarding whether or not the position is a place where labware preference has been added.
 * This differs from the DeckPositionView where this allows for interactions for labware preferences.
 */

type DeckPositionWithPreferencesProps = {
  deckPosition: DeckPositionRect;
  validLayoutOptions: LayoutOptions;
  isReadonly: boolean;
};

// Used to determine whether or not the user has clicked on a position even if there is a
// slight movement when the mouse down event happened. MOVING_THRESHOLD was arbitrarily chosen
// and can be fine tuned at a later time if this current value ends up being problematic.
const NOT_MOVING = -1;
const MOVING_THRESHOLD = 200;

/**
 * Because the mouse could still be moving after mouse down, we check that the movement was indeed
 * a recent mouse movement.
 */
function isMouseNotMoving(timeSinceMouseMoved: number): boolean {
  return (
    timeSinceMouseMoved === NOT_MOVING ||
    Date.now() - timeSinceMouseMoved < MOVING_THRESHOLD
  );
}

export default React.memo(function DeckPositionWithPreferences(
  props: DeckPositionWithPreferencesProps,
) {
  const classes = useStyles();
  const { deckPosition, validLayoutOptions, isReadonly } = props;
  const { deckPositionName } = deckPosition;

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [timeSinceMouseMoved, setTimeSinceMouseMoved] = useState(NOT_MOVING);
  const [isMouseHovering, setIsMouseHovering] = useState(false);

  const dispatch = useWorkflowBuilderDispatch();

  const onMouseEnter = useCallback(() => {
    setIsMouseHovering(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsMouseHovering(false);
  }, []);

  const setMouseDown = useCallback(() => {
    setIsMouseDown(true);
    setTimeSinceMouseMoved(NOT_MOVING);
  }, []);

  const setMouseMove = useCallback(() => {
    isMouseDown &&
      setTimeSinceMouseMoved(time => {
        // Only update the time when first moving. Otherwise, don't update the time.
        return time === NOT_MOVING ? Date.now() : time;
      });
  }, [isMouseDown]);

  const selectedLabwareType = useWorkflowBuilderSelector(
    state => state.labwarePreferenceType,
  );
  const labwareForPosition = useWorkflowBuilderSelector(state =>
    getLabwareForPosition(state, deckPositionName),
  );

  const add = useCallback(() => {
    if (selectedLabwareType) {
      dispatch({
        type: 'addLabwarePreference',
        payload: {
          labwareType: selectedLabwareType,
          position: deckPositionName,
        },
      });
    }
  }, [deckPositionName, dispatch, selectedLabwareType]);

  const remove = useCallback(() => {
    if (selectedLabwareType) {
      dispatch({
        type: 'removeLabwarePreference',
        payload: {
          labwareType: selectedLabwareType,
          position: deckPositionName,
        },
      });
    }
  }, [deckPositionName, dispatch, selectedLabwareType]);

  const isSelectedForCurrentLabware = useMemo(() => {
    if (!selectedLabwareType) {
      return false;
    }
    return (
      labwareForPosition.filter(labware =>
        areLabwareTypesEqual(labware, selectedLabwareType),
      ).length > 0
    );
  }, [labwareForPosition, selectedLabwareType]);

  const validPositionsForLabware = useMemo(() => {
    return selectedLabwareType
      ? getValidPositionsForLabwareType(selectedLabwareType, validLayoutOptions)
      : [];
  }, [selectedLabwareType, validLayoutOptions]);

  const isDeckPositionSelectable =
    !isReadonly &&
    selectedLabwareType &&
    validPositionsForLabware.includes(deckPositionName);
  const addedOrder = useWorkflowBuilderSelector(state => [
    ...(state.labwarePreferencesAddedOrder[deckPositionName] ?? ([] as LabwareType[])),
  ]);

  const handleSelection = useCallback(() => {
    setTimeSinceMouseMoved(NOT_MOVING);
    setIsMouseDown(false);

    // We only want to trigger the addition/removal if the user has clicked
    // and not if they are dragging the workspace canvas.
    if (isMouseNotMoving(timeSinceMouseMoved)) {
      isDeckPositionSelectable && !isSelectedForCurrentLabware ? add() : remove();
    }
  }, [
    timeSinceMouseMoved,
    isDeckPositionSelectable,
    isSelectedForCurrentLabware,
    add,
    remove,
  ]);

  const hasMouseMoved = isMouseNotMoving(timeSinceMouseMoved);

  // We only need to track the click actions if the position is valid, as non-valid
  // positions are not selectable.
  const clickHandlers =
    !isReadonly && validPositionsForLabware.includes(deckPosition.deckPositionName)
      ? {
          onMouseMove: setMouseMove,
          onMouseDown: setMouseDown,
          onMouseUp: handleSelection,
          onMouseEnter: onMouseEnter,
          onMouseLeave: onMouseLeave,
        }
      : {};

  return (
    <div
      {...clickHandlers}
      className={cx(classes.deckLocation, {
        [classes.selectableDeckLocation]:
          isDeckPositionSelectable && isMouseHovering && hasMouseMoved,
      })}
      key={deckPositionName}
      style={deckPosition.absolutePosInDeckPixels}
    >
      <div className={classes.actions}>
        <div className={classes.deckPositionChipList}>
          {[...addedOrder].map(labware => {
            return (
              <DeckPositionChip
                key={`${deckPositionName}_${labware}`}
                labwareType={labware}
                deckPositionName={deckPositionName}
                deckPositionWidth={deckPosition.absolutePosInDeckPixels.width}
                isMouseHovering={isMouseHovering}
                state={
                  areLabwareTypesEqual(selectedLabwareType, labware)
                    ? 'active'
                    : 'inactive'
                }
              />
            );
          })}
          {isDeckPositionSelectable &&
            selectedLabwareType &&
            !isSelectedForCurrentLabware && (
              <DeckPositionChip
                labwareType={selectedLabwareType}
                state="placeholder"
                deckPositionWidth={deckPosition.absolutePosInDeckPixels.width}
                isMouseHovering={isMouseHovering}
              />
            )}
        </div>
        {isDeckPositionSelectable && (
          <DeckPositionHoverStatusChip
            isSelectedForCurrentLabware={isSelectedForCurrentLabware}
            isMouseHovering={isMouseHovering}
            deckPositionWidth={deckPosition.absolutePosInDeckPixels.width}
            hasMouseMoved={hasMouseMoved}
          />
        )}
      </div>
      <Typography variant="h5" className={classes.positionLabel} noWrap>
        {deckPositionName}
      </Typography>
    </div>
  );
});

const useStyles = makeStylesHook(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    zIndex: 1,
  },
  deckLocation: {
    position: 'absolute',
    backgroundColor: Colors.GREY_5,
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '4px',
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  deckPositionChipList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    flex: 1,
  },
  positionLabel: {
    backgroundColor: Colors.GREY_20,
    borderRadius: '4px',
    padding: theme.spacing(2, 3),
    textOverflow: 'ellipsis',
    width: `calc(100% - calc(${theme.spacing(5)} * 2))`,
    maxWidth: 'fit-content',
    bottom: theme.spacing(5),
    position: 'absolute',
    '&:hover': {
      textOverflow: 'unset',
      width: 'fit-content',
      cursor: 'default',
      zIndex: 1,
    },
  },
  selectableDeckLocation: {
    outline: `2px solid ${Colors.BLUE_50}`,
  },
}));
