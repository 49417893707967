import { useMemo } from 'react';

import { isNumericFactor } from 'client/app/components/DOEBuilder/factorUtils';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';

export default function useHasDerivedFactors(factorId: string | undefined): {
  hasDerivedFactors: boolean;
  derivedFactorName?: string;
} {
  const factors = useWorkflowBuilderSelector(state => state.factors);

  return useMemo(() => {
    if (!factorId) return { hasDerivedFactors: false };

    for (const nextFactor of factors ?? []) {
      if (nextFactor.variableTypeName !== 'derived') {
        continue;
      } else if (isNumericFactor(nextFactor)) {
        if (nextFactor.derivingExpression?.includes(factorId)) {
          return {
            hasDerivedFactors: true,
            derivedFactorName: nextFactor.displayName,
          };
        }
      } else {
        if (nextFactor.sourceFactor?.id === factorId) {
          return {
            hasDerivedFactors: true,
            derivedFactorName: nextFactor.displayName,
          };
        }
      }
    }

    return { hasDerivedFactors: false };
  }, [factorId, factors]);
}
