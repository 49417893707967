import { MetaItem } from 'client/app/components/Parameters/MetaDataForm';
import { Solute } from 'common/types/filtration';

type Base = {
  /**
   * In the element configuration, factors are associated with liquid groups by
   * their name, but on the client names are mutable, and we want to preserve
   * associations when they change, so we need a client id. This won't be persisted
   * to the backend.
   */
  clientId: string;
  name: string;
};

export type ConcentrationFactor = Base & {
  type: 'subComponent';
  value: Solute;
};
export type CategoricalFactor = Base & {
  type: 'categorical';
  metadataName?: string;
  value: string[];
};
export type NumericalFactor = Base & {
  type: 'numeric';
  metadataName?: string;
  value: number[];
};

export type Factor = ConcentrationFactor | CategoricalFactor | NumericalFactor;

export type FactorType = Factor['type'];

export const FACTOR_TYPE: Record<FactorType, string> = {
  subComponent: 'Concentration',
  categorical: 'Categorical',
  numeric: 'Numeric',
};

export type LiquidGroup = Base & {
  constants: {
    subComponents: SubComponentItem[];
    metaData: MetaItem[];
  };
  factorIds: string[];
};

export type SubComponentItem = {
  id: string;
  name: string;
  concentration: {
    value: number;
    unit: string;
  };
};
