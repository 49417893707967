import moment from 'moment';
import momentTimezone from 'moment-timezone';

import { useFeatureToggle } from 'common/features/useFeatureToggle';

/**
 * Returns moment date of the given momentObject that contains the given time in the
 * momentObject with the current time offset of the given timezone.
 *
 * @param momentObject Moment object to convert.
 * @param timezone A string matching a timezone per the IANA tz database.
 * @returns Formatted ISO 8601 string.
 *
 */
export function convertMomentToMomentTimezone(
  momentObject: moment.Moment,
  timezone: string,
) {
  // Convert the moment into a momenttimezone.Moment. Important note, the second `true`
  // argument in `timezoneTimeStamp.tz(timezone, true)` is used to keep the time in the
  // selected timezone, rather than change it to UTC.
  const timezoneTimeStamp = momentTimezone(momentObject.format());
  return timezoneTimeStamp.tz(timezone, true);
}

/**
 * Returns a formatted string of the timeStamp with the date and time in localized formats
 * as well as the offset relative to UTC. Examples of the formatted text:
 * Date: 06/09/2014
 * Time: 8:30:25 PM
 * Offset: UTC +01:00
 *
 * @param timeStamp IS0 8601 timestamp
 * @returns Formatted string of the timestamp
 */
export function formatTimeStamp(timeStamp: string | Date) {
  const parsedTimeStamp = moment.parseZone(timeStamp);
  if (!parsedTimeStamp.isValid()) {
    return 'Invalid date and time';
  }
  return `${parsedTimeStamp.format('L')} ${parsedTimeStamp.format(
    'LTS',
  )} UTC ${parsedTimeStamp.format('Z')}`;
}

/**
 * Returns a formatted string of the timeStamp with the date and time in localized formats
 * depending on the 'DATASET_TIMEZONES' feature toggle it will an offset relative to UTC or not.
 */
export function useFormatTimeStamp() {
  const showTimezone = useFeatureToggle('DATASET_TIMEZONES');
  return (timestamp: string | Date) => {
    if (showTimezone) {
      return formatTimeStamp(timestamp);
    }

    return moment(timestamp)
      .locale(window.navigator.language || 'en-us')
      .format('ll LTS');
  };
}
