import _memo from 'lodash/memoize';

import {
  getConnectedPortsForInstance,
  isPortConnectable,
  Side,
} from 'client/app/lib/layout/ConnectionHelper';
import { arrayIsFalsyOrEmpty } from 'common/lib/data';
import {
  Connection,
  ElementInstance,
  Group,
  GroupMeta,
  Parameter,
  Terminus,
} from 'common/types/bundle';
import { Dimensions } from 'common/types/Dimensions';
import Keys from 'common/ui/lib/keyboard';

// The ElementPlumber is a complex piece of UI that requires many points of
// decomposition to make the problem tractable. The goal of this file is to
// put numbers that we use to render the ElementPlumber in one place (with
// the exception of CSS files which should still reference these constants).

// WORKSPACE_MARGIN defines how much extra space to add beyond the elements
// on the screen in order to give the user space to move elements around. This
// number must be larger than the tallest element and larger than element
// widths because we're not calculating bounding boxes here and we only know
// top left corner position.
export const WORKSPACE_MARGIN = 800;

export const ELEMENT_INSTANCE_PORT_HEIGHT = 11;
export const ELEMENT_INSTANCE_PORT_HEIGHT_WITH_MARGIN = ELEMENT_INSTANCE_PORT_HEIGHT + 1;
export const ELEMENT_INSTANCE_PORT_WIDTH = 10;

export const ELEMENT_INSTANCE_HEADER_HEIGHT = 20;
export const ELEMENT_INSTANCE_HEADER_MARGIN = 6;
export const ELEMENT_INSTANCE_WIDTH = 140;

export const CONNECTION_STROKE_SIZE = 11;

export const GROUP_BODY_PADDING = 20;

export const ELEMENT_INSTANCE_NAME_GAP = 10;
export const ELEMENT_INSTANCE_NAME_HEIGHT = ELEMENT_INSTANCE_NAME_GAP + 18;

export type LayoutDimensions = Dimensions;

export function getPortLocalPosition(side: Side, idx: number) {
  const y =
    ELEMENT_INSTANCE_PORT_HEIGHT_WITH_MARGIN * idx +
    ELEMENT_INSTANCE_HEADER_HEIGHT +
    ELEMENT_INSTANCE_HEADER_MARGIN;
  const x = side === 'input' ? 0 : ELEMENT_INSTANCE_WIDTH - ELEMENT_INSTANCE_PORT_WIDTH;
  return { x, y };
}

export function getPortIndex(
  portSide: Side,
  portName: string,
  elementInstance: ElementInstance,
  connections: Connection[],
) {
  function isPortConnected(name: string) {
    return connections.some(connection => {
      const terminus = portSide === 'input' ? connection.Target : connection.Source;
      return (
        terminus.ElementInstance === elementInstance.name &&
        terminus.ParameterName === name
      );
    });
  }

  function isPortVisible(parameter: Parameter) {
    return isPortConnectable(parameter) || isPortConnected(parameter.name);
  }

  const targetSide =
    portSide === 'input'
      ? elementInstance.element.inputs
      : elementInstance.element.outputs;

  const parameter = targetSide.find(port => port.name === portName);

  // If the given parameter is not visible, it shouldn't have a position.
  if (!parameter || !isPortVisible(parameter)) {
    return -1;
  }

  if (portSide === 'input') {
    return elementInstance.element.inputs
      .filter(isPortVisible)
      .findIndex(aPort => aPort.name === portName);
  } else {
    return elementInstance.element.outputs
      .filter(isPortVisible)
      .findIndex(aPort => aPort.name === portName);
  }
}

export function getPortPositionByName(
  portName: string,
  portSide: Side,
  elementInstance: ElementInstance,
  connections: Connection[],
) {
  const portIndex = getPortIndex(portSide, portName, elementInstance, connections);

  if (portIndex === -1) {
    return undefined;
  }

  let { x, y } = elementInstance.Meta;
  const portLocalPos = getPortLocalPosition(portSide, portIndex);

  x += portLocalPos.x;
  y += portLocalPos.y;

  // Connections are drawn from the center, so we have to move it down by
  // half the thickness to make sure it aligns correctly with the port.
  // Otherwise, the center of the connection will be at the top corner of
  // the port.
  const connectionSize = CONNECTION_STROKE_SIZE / 2;
  y += connectionSize;

  return { x, y };
}

export function getConnectionTerminusPosition(
  terminus: Terminus,
  side: Side,
  elementInstance: ElementInstance,
  connections: Connection[],
) {
  return getPortPositionByName(
    terminus.ParameterName,
    side,
    elementInstance,
    connections,
  );
}

export const getElementHeight = _memo(function getElementHeight(
  elementInstance: ElementInstance,
  connections: Connection[],
) {
  const element = elementInstance.element;
  const connectedPorts = getConnectedPortsForInstance(elementInstance, connections);

  const maxPortsOnOneSide = Math.max(
    element.inputs.filter(
      input => connectedPorts.inputs.has(input.name) || isPortConnectable(input),
    ).length,
    element.outputs.filter(
      output => connectedPorts.outputs.has(output.name) || isPortConnectable(output),
    ).length,
  );

  const portHeight = maxPortsOnOneSide * ELEMENT_INSTANCE_PORT_HEIGHT_WITH_MARGIN;

  return portHeight + ELEMENT_INSTANCE_HEADER_HEIGHT;
});

export function getLayoutDimensions(
  elementInstances: ElementInstance[],
  connections: Connection[],
  elementGroups: Group[] = [],
) {
  return getBoundingBox([
    ...elementInstances.map(ei => getElementDimensions(ei, connections)),
    ...elementGroups.map(group => getGroupDimensions(group)),
  ]);
}

export function doRectsOverlap(a: LayoutDimensions, b: LayoutDimensions): boolean {
  const overlapsHorizontally = a.left <= b.left + b.width && b.left <= a.left + a.width;
  const overlapsVertically = a.top <= b.top + b.height && b.top <= a.top + a.height;
  return overlapsHorizontally && overlapsVertically;
}

export function isElementWithinSelection(
  area: LayoutDimensions,
  elementInstance: ElementInstance,
  connections: Connection[],
) {
  const { x: left, y: top } = elementInstance.Meta;
  const width = ELEMENT_INSTANCE_WIDTH;
  const height = getElementHeight(elementInstance, connections);
  return doRectsOverlap(area, { left, top, width, height });
}

export function isGroupWithinSelection(area: LayoutDimensions, group: Group) {
  const { x: left, y: top, width, height } = group.Meta;
  return doRectsOverlap(area, { left, top, width, height });
}

// When the user uses arrow keys to reposition an element instance, this
// is how far we move the element on the screen in whatever direction
// the user nudged.
const NUDGE_DISTANCE_PX = 10;
const NUDGE_DISTANCE_FAST_PX = 100;

export function getNudgeDelta(key: string, goFast: boolean) {
  const dist = goFast ? NUDGE_DISTANCE_FAST_PX : NUDGE_DISTANCE_PX;
  switch (key) {
    case Keys.ARROW_UP:
      return { x: 0, y: -dist };
    case Keys.ARROW_DOWN:
      return { x: 0, y: dist };
    case Keys.ARROW_LEFT:
      return { x: -dist, y: 0 };
    case Keys.ARROW_RIGHT:
      return { x: dist, y: 0 };
    default:
      return null;
  }
}

export function addGroupPadding(dimensions: Dimensions): Dimensions {
  return {
    left: dimensions.left - GROUP_BODY_PADDING,
    top: dimensions.top - GROUP_BODY_PADDING,
    width: dimensions.width + GROUP_BODY_PADDING * 2,
    height: dimensions.height + GROUP_BODY_PADDING * 2,
  };
}

export function dimensionsToGroupMeta(dimensions: Dimensions): GroupMeta {
  return {
    x: dimensions.left,
    y: dimensions.top,
    width: dimensions.width,
    height: dimensions.height,
  };
}

export function getGroupDimensions({ Meta: { x, y, width, height } }: Group) {
  return { left: x, top: y, width, height };
}

export function getElementDimensions(
  elementInstance: ElementInstance,
  connections: Connection[],
): Dimensions {
  const {
    x,
    y,
    bodySize = {
      width: ELEMENT_INSTANCE_WIDTH,
      // We should get the element's size from measuring the DOM, but if not we calculate it ourselves.
      height: getElementHeight(elementInstance, connections),
    },
    nameSize = {
      width: ELEMENT_INSTANCE_WIDTH,
      height: ELEMENT_INSTANCE_NAME_HEIGHT,
    },
  } = elementInstance.Meta;

  return {
    top: y - nameSize.height,
    left:
      // If the group's name is wider than its body, we need to offset it leftwards.
      nameSize.width > bodySize.width ? x - (nameSize.width - bodySize.width) / 2 : x,
    height: nameSize.height + bodySize.height,
    width: Math.max(bodySize.width, nameSize.width),
  };
}

export function getBoundingBox(objects: Dimensions[]) {
  if (arrayIsFalsyOrEmpty(objects)) {
    return { width: 0, height: 0, left: 0, top: 0 };
  }

  let rightEdge = -Infinity;
  let bottomEdge = -Infinity;
  let leftEdge = Infinity;
  let topEdge = Infinity;

  for (const object of objects) {
    const instanceRightEdge = object.left + object.width;
    rightEdge = Math.max(rightEdge, instanceRightEdge);

    const instanceBottomEdge = object.top + object.height;
    bottomEdge = Math.max(bottomEdge, instanceBottomEdge);

    leftEdge = Math.min(leftEdge, object.left);
    topEdge = Math.min(topEdge, object.top);
  }

  return {
    width: rightEdge - leftEdge,
    height: bottomEdge - topEdge,
    left: leftEdge,
    top: topEdge,
  };
}
