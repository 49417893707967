import React from 'react';

import DatasetIcon from '@mui/icons-material/InsertChartOutlinedOutlined';

import { DatasetFragmentFragment as DatasetFragment } from 'client/app/gql';
import { EntityCard } from 'common/ui/components/EntityCard';

type Props = {
  dataset: DatasetFragment & { id: DatasetId };
  isSelected?: boolean;
  onClick?: () => void;
};

export default function DatasetCard({ dataset, isSelected, onClick }: Props) {
  return (
    <EntityCard
      entityName="Dataset"
      icon={<DatasetIcon fontSize="small" />}
      authorTitle="Device"
      author={dataset.device?.name ?? 'Stitched'}
      dateTitle="Created at"
      name={dataset.name}
      date={new Date(dataset.createdAt)}
      isSelected={isSelected}
      onClick={onClick}
    />
  );
}
