import { useMemo, useReducer } from 'react';

import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import { v4 } from 'uuid';

import { isNumericFactor } from 'client/app/components/DOEBuilder/factorUtils';
import {
  BasicFactorType,
  FactorParameterInfo,
} from 'client/app/components/DOEFactorForm/types';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { pluralize } from 'common/lib/format';
import { FactorItem, FactorItemType, FactorPath } from 'common/types/bundle';

export type DOEFactorFormErrors = {
  name?: string;
  unit?: string;
  levels?: string;
  levelValues?: Record<string, string>;
  unmappedLevels?: string;
  numberOfZeros?: string;
  derivingExpression?: string;
  sourceFactor?: string;
};

export type MutualExclusionInfo = {
  groupName: string;
  levelCount?: number;
  replicate?: Pick<
    FactorItem,
    'values' | 'unit' | 'sampleMode' | 'numberOfZerosToInclude'
  >;
};

type Level = {
  id: string;
  value: string;
};

type FactorDetails = Omit<FactorItem, 'values'>;

type DOEFactorFormState = {
  errors?: DOEFactorFormErrors;
  showErrors: boolean;
  levels: Level[];
  levelMapping: Record<string, string>;
  levelsToMapCount: number;
  factor: FactorDetails;
  isCustom: boolean;
  isNew: boolean;
  isConstant: boolean;
  factorDescriptors: string[];
  unitOptions: string[] | null;
};

type DOEFactorFormAction =
  | {
      type: 'updateFactor';
      payload: Partial<FactorDetails>;
    }
  | {
      type: 'updateLevels';
      payload: Level[];
    }
  | {
      type: 'updateBasicType';
      payload: BasicFactorType;
    }
  | {
      type: 'updateDerivingExpression';
      payload: string;
    }
  | {
      type: 'updateSourceFactor';
      payload: {
        sourceFactor: FactorDetails['sourceFactor'];
        levelsToMapCount: number;
      };
    }
  | {
      type: 'mapFactorLevels';
      payload: {
        sourceFactorLevels: string[];
        derivedFactorLevel: string;
      };
    }
  | {
      type: 'showErrors';
    };

const reducer = produce(
  (draft: DOEFactorFormState, action: DOEFactorFormAction): DOEFactorFormState => {
    switch (action.type) {
      case 'updateFactor': {
        draft.factor = {
          ...draft.factor,
          ...action.payload,
        };
        break;
      }
      case 'updateLevels': {
        const oldLevels = [...draft.levels];
        const newLevels = action.payload;

        if (draft.factor.sourceFactor) {
          /**
           * In case of Derived Categorical factor we need to update the level mapping
           */
          draft.levelMapping = buildDerivedFactorLevelMapping(
            draft.levelMapping,
            oldLevels,
            newLevels,
          );
        }

        draft.levels = newLevels;
        break;
      }
      case 'showErrors': {
        draft.showErrors = true;
        break;
      }
      case 'updateBasicType': {
        switch (action.payload) {
          case 'categorical': {
            draft.factor.typeName = 'nominal';
            draft.factor.sampleMode = undefined;
            break;
          }
          case 'numerical': {
            draft.factor.typeName = 'continuous';

            if (draft.factor.mutualExclusionGroup != null) {
              draft.factor.sampleMode = 'continuous';
              draft.factor.numberOfZerosToInclude = 0;
            } else {
              draft.factor.sampleMode = 'discrete';
              draft.factor.numberOfZerosToInclude = undefined;
            }
            break;
          }
        }
        break;
      }
      case 'updateDerivingExpression': {
        draft.factor.derivingExpression = action.payload;
        break;
      }
      case 'updateSourceFactor': {
        draft.factor.sourceFactor = action.payload.sourceFactor;
        draft.levelsToMapCount = action.payload.levelsToMapCount;
        draft.levelMapping = {}; // reset mapping when user selects a different factor to derive from
        break;
      }
      case 'mapFactorLevels': {
        const { derivedFactorLevel, sourceFactorLevels } = action.payload;
        draft.levelMapping = mapSourceFactorLevelsToDerivedFactorLevel(
          draft.levelMapping,
          sourceFactorLevels,
          derivedFactorLevel,
        );
        break;
      }
    }

    draft.errors = getErrors(draft);

    if (!draft.errors) {
      draft.showErrors = false;
    }

    return draft;
  },
);

function buildDerivedFactorLevelMapping(
  valueMap: Record<string, string>,
  oldLevels: Level[],
  newLevels: Level[],
) {
  const newValueMap = { ...valueMap };

  for (const sourceFactorLevel in newValueMap) {
    const derivedFactorLevel = newValueMap[sourceFactorLevel];
    const oldLevel = oldLevels.find(level => level.value === derivedFactorLevel);
    const newLevel = newLevels.find(level => level.value === derivedFactorLevel);

    if (newLevel) {
      // corresponding derived factor level was unchanged so do nothing
    } else if (oldLevel) {
      // corresponding derived factor level was renamed or removed
      const correspondingNewLevel = newLevels.find(l => l.id === oldLevel.id);

      if (correspondingNewLevel) {
        // derived factor level was renamed so update the mapping
        newValueMap[sourceFactorLevel] = correspondingNewLevel.value;
      } else {
        // derived factor level was removed so remove the mapping
        delete newValueMap[sourceFactorLevel];
      }
    } else {
      throw new Error(
        `[DOEFactorForm reducer]: derivedFactorLevel="${derivedFactorLevel}" was not found`,
      );
    }
  }

  return newValueMap;
}

function mapSourceFactorLevelsToDerivedFactorLevel(
  valueMap: Record<string, string>,
  sourceFactorLevels: string[],
  derivedFactorLevel: string,
): Record<string, string> {
  const newValueMap = { ...valueMap };

  // Clear all level keys mapped to this level of the derived factor
  for (const sourceFactorLevel in newValueMap) {
    if (newValueMap[sourceFactorLevel] === derivedFactorLevel) {
      delete newValueMap[sourceFactorLevel];
    }
  }
  // Map selected levels to this level of the derived factor
  for (const sourceFactorLevel of sourceFactorLevels) {
    newValueMap[sourceFactorLevel] = derivedFactorLevel;
  }

  return newValueMap;
}

function getErrors(state: DOEFactorFormState): DOEFactorFormErrors | undefined {
  const result: DOEFactorFormErrors = {};

  const isNumeric =
    state.factor.typeName === 'continuous' ||
    state.factor.typeName === 'discrete' ||
    state.factor.typeName === 'ordinal';

  const isDerived = state.factor.variableTypeName === 'derived';

  const currentFactorDescriptor = getCurrentFactorDescriptor(state.factor);

  if (state.factor.displayName === '') {
    result.name = 'Factor name is required';
  } else if (state.factorDescriptors.includes(currentFactorDescriptor)) {
    result.name = 'Factor name must be unique';
  }

  if (state.unitOptions && (!state.factor.unit || state.factor.unit === '')) {
    result.unit = 'Unit is required';
  }

  if (isDerived) {
    if (isNumeric && !state.factor.derivingExpression) {
      result.derivingExpression = 'Expression is required';
    }
    if (!isNumeric && !state.factor.sourceFactor) {
      result.sourceFactor = 'Source factor is required';
    }

    const unmappedLevelCount = state.levelsToMapCount - keys(state.levelMapping).length;
    if (!isNumeric && unmappedLevelCount > 0) {
      result.unmappedLevels = pluralize(unmappedLevelCount, 'level');
    }
  }

  if (!(isNumeric && isDerived)) {
    /**
     * Levels are only applicable to factors that are not Derived Numeric factors
     */
    if (state.levels.length === 0) {
      result.levels = 'One or more levels is required';
    } else {
      const allowSingleValueFactors =
        state.isConstant || state.factor.mutualExclusionGroup;
      if (state.levels.length === 1 && !allowSingleValueFactors) {
        result.levels =
          'At least two levels are required. Single levels are only allowed for constants.';
      }

      const seenValues = new Set<string>();

      const levelValues = state.levels.flatMap(level => {
        if (level.value === '') {
          return [[level.id, 'Level value is required']];
        } else if (isNumeric && Number.isNaN(Number.parseFloat(level.value))) {
          return [[level.id, 'Level value must be a number']];
        } else if (seenValues.has(level.value)) {
          return [[level.id, 'Level value cannot be a duplicate']];
        } else if (
          isDerived &&
          !Object.values(state.levelMapping).includes(level.value)
        ) {
          return [[level.id, "Level hasn't been assigned any source factor levels"]];
        }

        seenValues.add(level.value);

        return [];
      });

      if (levelValues.length) {
        result.levelValues = Object.fromEntries(levelValues);
      }
    }
  }

  const zeroCount = state.factor.numberOfZerosToInclude ?? -1;
  if (
    state.factor.sampleMode === 'continuous' &&
    (!Number.isInteger(zeroCount) || zeroCount < 0)
  ) {
    result.numberOfZeros = 'This value has to be a positive integer';
  }

  return isEmpty(result) ? undefined : result;
}

export function mapStateToFactor(
  state: DOEFactorFormState,
  parameterInfo?: FactorParameterInfo,
): FactorItem {
  const path = parameterInfo?.allowMultipleFactors
    ? ([...parameterInfo.path, state.factor.displayName] as FactorPath)
    : parameterInfo?.path;

  let values = state.levels.map(l => l.value.trim());
  let derivingExpression: FactorItem['derivingExpression'];
  let sourceFactor: FactorItem['sourceFactor'];

  if (state.factor.variableTypeName !== 'derived') {
    /**
     * Here we leave .derivingExpression or .sourceFactor undefined.
     * Because otherwise if the factor is not Derived, and has .derivingExpression or .sourceFactor defined
     * we will not be able to use it in any new deriving expression which is a contradiction.
     */
  } else if (isNumericFactor(state.factor)) {
    derivingExpression = state.factor.derivingExpression;
    values = []; // must come from derivingExpression, which is handled in visserver app
  } else {
    // values are known as the mapping of factors is simpler
    sourceFactor = state.factor.sourceFactor && {
      id: state.factor.sourceFactor.id,
      valueMap: state.levelMapping,
    };
  }

  /**
   * This value only makes sense for range sampling.
   * In case of discrete sampling design calculations will fail to consider number of zeros.
   */
  const numberOfZerosToInclude =
    state.factor.sampleMode === 'continuous'
      ? state.factor.numberOfZerosToInclude
      : undefined;

  return {
    ...state.factor,
    path,
    values,
    displayName: state.factor.displayName.trim(),
    derivingExpression,
    sourceFactor,
    numberOfZerosToInclude,
  };
}

const NUMERICAL_TYPES = [EditorType.MEASUREMENT, EditorType.INT, EditorType.FLOAT];

export function getBasicFactorType(parameter?: FactorParameterInfo) {
  return parameter?.valueEditor?.type &&
    NUMERICAL_TYPES.includes(parameter?.valueEditor?.type)
    ? 'continuous'
    : 'nominal';
}

const getCurrentFactorDescriptor = (factor: Pick<FactorItem, 'path' | 'displayName'>) =>
  !factor.path
    ? factor.displayName
    : factor.path.includes(factor.displayName)
    ? factor.path.join('/')
    : [...factor.path, factor.displayName].join('/');

function initState([parameter, factor, factorDescriptors, typeToAdd, mutualExclusion]: [
  parameter: FactorParameterInfo | undefined,
  factor: FactorItem | null,
  factorDescriptors: string[],
  typeToAdd: 'factor' | 'derived' | 'constant',
  mutualExclusion?: MutualExclusionInfo,
]): DOEFactorFormState {
  let initFactor: DOEFactorFormState['factor'];
  let initLevels: DOEFactorFormState['levels'];
  let unitOptions: string[] | null = null;
  let defaultUnit: string = '';

  const replicate = mutualExclusion?.replicate;

  if (parameter?.valueEditor?.additionalProps?.editor === EditorType.MEASUREMENT) {
    unitOptions = parameter?.valueEditor?.additionalProps?.units;
    defaultUnit = parameter?.valueEditor?.additionalProps.defaultUnit ?? '';
  }

  const basicType: FactorItemType = getBasicFactorType(parameter);

  if (factor) {
    initFactor = { ...factor };
    initLevels = factor.values.map(value => ({ id: v4(), value }));
  } else {
    initFactor = {
      id: v4(),
      displayName: parameter?.allowMultipleFactors ? '' : parameter?.path[2] ?? '',
      typeName: basicType,
      unit: replicate?.unit ?? defaultUnit,
      variableTypeName: typeToAdd === 'constant' ? 'factor' : typeToAdd,
      hardToChange: false,
      included: true,
      path: parameter?.path,
      mutualExclusionGroup: mutualExclusion?.groupName,
    };

    if (replicate) {
      initLevels = replicate?.values.map(value => ({ id: v4(), value }));
    } else if (mutualExclusion?.levelCount) {
      initLevels = Array.from({ length: mutualExclusion.levelCount }, () => ({
        id: v4(),
        value: '',
      }));
    } else {
      initLevels = [
        {
          id: v4(),
          value: '',
        },
      ];
    }
  }

  if (mutualExclusion) {
    /**
     * For mutual exclusion group Numerical factors should have a range sampling
     * with number of zeros set to 0 value
     */
    initFactor.sampleMode = 'continuous';
    initFactor.numberOfZerosToInclude = 0;
  } else if (basicType === 'continuous') {
    initFactor.sampleMode = factor?.sampleMode ?? 'discrete';
    initFactor.numberOfZerosToInclude = factor?.numberOfZerosToInclude ?? 0;
  }

  return {
    factor: initFactor,
    errors: {},
    showErrors: false,
    levels: initLevels,
    levelMapping: factor?.sourceFactor?.valueMap ?? {},
    levelsToMapCount: keys(factor?.sourceFactor?.valueMap).length,
    isCustom: !parameter,
    isNew: !factor,
    isConstant: typeToAdd === 'constant',
    factorDescriptors: !factor
      ? factorDescriptors
      : factorDescriptors.filter(
          descriptor => descriptor !== getCurrentFactorDescriptor(factor),
        ),
    unitOptions,
  };
}

export default function useDOEFactorForm(
  parameter: FactorParameterInfo | undefined = undefined,
  factor: FactorItem | null = null,
  factorDescriptors: string[],
  typeToAdd: 'factor' | 'derived' | 'constant' = 'factor',
  mutualExclusion?: MutualExclusionInfo,
) {
  const [state, dispatch] = useReducer(
    reducer,
    [parameter, factor, factorDescriptors, typeToAdd, mutualExclusion],
    initState,
  );

  const actions = useMemo(
    () => ({
      updateFactor: (factor: Partial<FactorDetails>) =>
        dispatch({ type: 'updateFactor', payload: factor }),
      updateLevels: (levels: Level[]) =>
        dispatch({ type: 'updateLevels', payload: levels }),
      updateBasicType: (type: BasicFactorType) =>
        dispatch({ type: 'updateBasicType', payload: type }),
      updateDerivingExpression: (derivingExpression: string) =>
        dispatch({ type: 'updateDerivingExpression', payload: derivingExpression }),
      updateSourceFactor: (
        sourceFactor: FactorItem['sourceFactor'],
        levelsToMapCount: number,
      ) =>
        dispatch({
          type: 'updateSourceFactor',
          payload: { sourceFactor, levelsToMapCount },
        }),
      mapFactorLevels: (sourceFactorLevels: string[], derivedFactorLevel: string) =>
        dispatch({
          type: 'mapFactorLevels',
          payload: { sourceFactorLevels, derivedFactorLevel },
        }),
      showErrors() {
        dispatch({ type: 'showErrors' });
      },
    }),
    [],
  );

  return { state, ...actions };
}
