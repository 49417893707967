import React, { useCallback, useMemo } from 'react';

import { Query } from '@apollo/client/react/components';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { deviceFromGraphQL, GraphQLDevice } from 'client/app/api/deviceFromGraphql';
import { QUERY_ALL_DEVICES } from 'client/app/api/gql/queries';
import DeviceSelectorDialog from 'client/app/components/DeviceSelectorDialog';
import { AllDevicesQuery } from 'client/app/gql';
import {
  addAccessibleDevice,
  buildDeviceConfigurationForUI,
  configHasDeletedDevice,
  configHasDeletedDeviceRunConfig,
  removeAccessibleDevice,
} from 'client/app/lib/workflow/deviceConfigUtils';
import { mapObject } from 'common/object';
import { WorkflowDeviceConfiguration } from 'common/types/bundle';
import { DeviceRunConfig, SimpleDevice } from 'common/types/device';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import DeviceList from 'common/ui/components/DeviceList';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  allDevices: readonly GraphQLDevice[];
  /** Devices that are already selected */
  deviceConfiguration: WorkflowDeviceConfiguration;
  /** Allow users to select one device only */
  singleSelect?: boolean;
  isDisabled?: boolean;
  /**
   * Callback when user changes the selected devices.
   * This prop is optional. If you don't provide the callback, this view will be read-only
   * (no button to select devices).
   */
  onSelectedDevicesChange?: (deviceConfiguration: WorkflowDeviceConfiguration) => void;
};

function DeviceSelector(props: Props) {
  const classes = useStyles();
  const {
    allDevices,
    deviceConfiguration: deviceConfigurationFromState,
    onSelectedDevicesChange,
    singleSelect,
    isDisabled,
  } = props;

  const [deviceSelectorDialog, openDeviceSelectorDialog] =
    useDialog(DeviceSelectorDialog);

  /**
   * GraphQL `anthaLangDeviceClass` is very specific, e.g. "HamiltonMicrolabSTAR".
   * When saving that to the WorkflowBuilderStateContext, we convert it to "Hamilton",
   * so information is lost.
   * We filter devices by string matching the anthaLangDeviceClass, so having
   * HamiltonMicrolabSTAR !== Hamilton will result in the device being filtered out.
   * Thus, we must retrieve the full anthaLangDeviceClass.
   * TODO This code can go away once the anthaLangDeviceClass is removed.
   * https://synthace.atlassian.net/browse/CA-46
   */
  const deviceConfiguration: WorkflowDeviceConfiguration = useMemo(() => {
    return mapObject(deviceConfigurationFromState, (deviceId, device) => {
      const anthaLangDeviceClass =
        allDevices.find(d => d.id === deviceId)?.model.anthaLangDeviceClass ||
        device.anthaLangDeviceClass;

      return {
        ...device,
        anthaLangDeviceClass,
      };
    });
  }, [allDevices, deviceConfigurationFromState]);

  const selectedDevices = useMemo<GraphQLDevice[]>(() => {
    return allDevices.filter(d => !!deviceConfiguration[d.id]);
  }, [allDevices, deviceConfiguration]);

  // We still need to convert to the datatype shared with AnthaHub to render the device list
  const selectedDevicesCommon = useMemo(
    () => selectedDevices.map(deviceFromGraphQL),
    [selectedDevices],
  );

  const usingMissingSelectedDevice = useMemo(
    () => configHasDeletedDevice(selectedDevices, deviceConfiguration),
    [deviceConfiguration, selectedDevices],
  );
  const usingMissingDeviceConfig = useMemo(
    () => configHasDeletedDeviceRunConfig(selectedDevices, deviceConfiguration),
    [deviceConfiguration, selectedDevices],
  );

  const handleRemoveDevice = useCallback(
    (deviceToRemove: SimpleDevice) => {
      let newConfiguration: WorkflowDeviceConfiguration = {
        ...deviceConfiguration,
      };

      const accessibleDevices = selectedDevices.find(
        device => device.id === deviceToRemove.id,
      )?.accessibleDevices;

      // Remove all main device's accessible devices
      if (accessibleDevices && accessibleDevices.length > 0) {
        for (const accessibleDevice of accessibleDevices) {
          newConfiguration = removeAccessibleDevice(
            newConfiguration,
            accessibleDevice.id,
          );
        }
      }
      // Remove main device
      delete newConfiguration[deviceToRemove.id];

      onSelectedDevicesChange?.(newConfiguration);
    },
    [deviceConfiguration, onSelectedDevicesChange, selectedDevices],
  );

  const handleSelectDevices = useCallback(async () => {
    const result = await openDeviceSelectorDialog({
      preselectedDevices: selectedDevices,
      preSelectedDeviceConfigs: mapObject(
        deviceConfiguration,
        (_deviceId, { runConfigId }) => runConfigId,
      ),
      allDevices: allDevices,
      multiselect: !singleSelect,
    });

    if (!result) {
      // User canceled
      return;
    }

    const newSelection: WorkflowDeviceConfiguration = buildDeviceConfigurationForUI(
      result.selectedDevices,
      result.selectedConfigs,
    );
    onSelectedDevicesChange?.(newSelection);
  }, [
    openDeviceSelectorDialog,
    selectedDevices,
    deviceConfiguration,
    allDevices,
    singleSelect,
    onSelectedDevicesChange,
  ]);

  const handleAccessibleDeviceEnabledChange = useCallback(
    (accessibleDevice: SimpleDevice, isEnabled: boolean) => {
      if (!isEnabled) {
        const deviceSelectionMinusAccessibleDevice = removeAccessibleDevice(
          deviceConfiguration,
          accessibleDevice.id,
        );
        onSelectedDevicesChange?.(deviceSelectionMinusAccessibleDevice);
      } else {
        const parentDevice = selectedDevicesCommon.find(device =>
          device.accessibleDevices.some(a => a.id === accessibleDevice.id),
        );
        if (!parentDevice) {
          console.error(
            `Trying to enable an accessible device ${accessibleDevice.id} ` +
              'but there is no corresponding parent device in the workflow config. ' +
              'This can only happen if the workflow config is in a bad state due to a bug.',
          );
          return;
        }
        const deviceConfigurationPlusAccessibleDevice = addAccessibleDevice(
          deviceConfiguration,
          accessibleDevice,
          parentDevice.id,
        );
        onSelectedDevicesChange?.(deviceConfigurationPlusAccessibleDevice);
      }
    },
    [deviceConfiguration, onSelectedDevicesChange, selectedDevicesCommon],
  );

  const handleSelectConfig = useCallback(
    (deviceId: string) => (newRunConfig?: DeviceRunConfig) => {
      // Prevent users from selecting the default message ("Please select a config")
      if (!newRunConfig) {
        return;
      }
      const newDeviceConfiguration: WorkflowDeviceConfiguration = {
        ...deviceConfiguration,
        // Update just the runConfigId
        [deviceId]: {
          ...deviceConfiguration[deviceId],
          runConfigId: newRunConfig.id,
        },
      };

      onSelectedDevicesChange?.(newDeviceConfiguration);
    },
    [deviceConfiguration, onSelectedDevicesChange],
  );

  const isEditable = !isDisabled && !!onSelectedDevicesChange;
  const noDevicesSelected = selectedDevices.length === 0;

  return (
    <>
      {noDevicesSelected && !usingMissingSelectedDevice && (
        <Typography
          variant="body1"
          color="textSecondary"
          className={cx(classes.message, classes.emptyListText)}
        >
          No execution mode selected
        </Typography>
      )}
      {usingMissingSelectedDevice && (
        <Typography
          variant="body1"
          color="error"
          className={cx(classes.message, classes.problem)}
        >
          The original device for this form has been deleted. Please select a different
          execution mode.
        </Typography>
      )}
      {usingMissingDeviceConfig && (
        <Typography
          variant="body1"
          color="error"
          className={cx(classes.message, classes.problem)}
        >
          The config of the device has been deleted. Please select a different config for
          the device.
        </Typography>
      )}
      {!noDevicesSelected && (
        <DeviceList
          devices={selectedDevicesCommon}
          deviceConfiguration={deviceConfiguration}
          isEditable={isEditable}
          onAccessibleDeviceEnabledChange={
            (isEditable && handleAccessibleDeviceEnabledChange) || undefined
          }
          onRemoveDevice={(isEditable && handleRemoveDevice) || undefined}
          onSelectConfig={(isEditable && handleSelectConfig) || undefined}
        />
      )}
      {onSelectedDevicesChange && !isDisabled && (
        <Button
          variant="secondary"
          onClick={handleSelectDevices}
          className={classes.selectDeviceBtn}
        >
          Select execution mode
        </Button>
      )}
      {deviceSelectorDialog}
    </>
  );
}

const useStyles = makeStylesHook({
  message: {
    margin: '0 24px',
    fontStyle: 'italic',
    marginTop: '1rem',
  },
  emptyListText: {
    color: Colors.GREY_60,
  },
  problem: {
    color: Colors.ERROR,
  },
  selectDeviceBtn: {
    marginLeft: '1rem',
    marginTop: '1rem',
  },
});

/**
 * This wrapper adds device config data sourced from graphql to DeviceSelector.
 * This is easier than adding the same piece of code in all places where the component is used.
 * When we start fetching device data from appserver, the list of configs will just be
 * a field on the device object, but for now we need to augment the data we get from microservices.
 */
export default function DeviceSelectorContainer(props: Omit<Props, 'allDevices'>) {
  return (
    <Query<AllDevicesQuery> query={QUERY_ALL_DEVICES}>
      {({ data, loading, error, refetch }) => {
        if (loading) {
          return (
            <div style={{ margin: '1rem' }}>
              <CircularProgress />
            </div>
          );
        } else if (error) {
          return <GraphQLErrorPanel error={error} onRetry={refetch} />;
        } else if (data) {
          return <DeviceSelector allDevices={data.devices} {...props} />;
        }
        return null;
      }}
    </Query>
  );
}
