import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import { QUERY_DRAFT_WORKFLOWS } from 'client/app/api/gql/queries';
import * as WorkflowsApi from 'client/app/api/WorkflowsApi';
import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import DraftWorkflowCard from 'client/app/components/cards/DraftWorkflowCard';
import { draftWorkflowsQueryVariables, WorkflowSourceEnum } from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { PageInfo } from 'common/server/graphql/pagination';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

type DraftListProps = {
  scrollableRef: React.RefObject<HTMLDivElement>;
  searchQuery: string;
  filterWorkflowTypesList: WorkflowSourceEnum[];
  currentUserId: string;
};

export function DraftList(props: DraftListProps) {
  const { currentUserId, searchQuery, filterWorkflowTypesList, scrollableRef } = props;
  const classes = useStyles();

  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

  const draftWorkflowQueryVariables: draftWorkflowsQueryVariables = useMemo(
    () => ({
      search: searchQuery,
      userId: currentUserId,
      workflowTypeFilter: filterWorkflowTypesList ?? undefined,
    }),
    [currentUserId, filterWorkflowTypesList, searchQuery],
  );

  const {
    loading: isInitialLoading,
    error,
    data,
    refetch,
    fetchMore,
  } = useQuery(QUERY_DRAFT_WORKFLOWS, {
    variables: draftWorkflowQueryVariables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const deleteWorkflow = WorkflowsApi.useDeleteWorkflow();
  const handleDeleteDraftWorkflow = useCallback(
    async (draftId: WorkflowId, name: string) => {
      const isDeleteConfirmed = await openConfirmationDialog({
        action: 'delete',
        isActionDestructive: true,
        object: 'draft',
        specificObject: `${name}`,
      });
      if (isDeleteConfirmed) {
        await deleteWorkflow(draftId);
        await refetch();
      }
    },
    [openConfirmationDialog, deleteWorkflow, refetch],
  );

  const draftWorkflows = data?.draftWorkflows?.items || [];

  const pageInfo = data?.draftWorkflows.pageInfo as PageInfo | undefined;
  const dependencies = [searchQuery, filterWorkflowTypesList, currentUserId];
  const hasNextPage = usePagination({
    entity: 'draftWorkflows',
    pageInfo,
    fetchMore,
    dependencies,
    scrollableRef,
    isInitialLoading,
    variables: draftWorkflowQueryVariables,
  });

  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }

  if (isInitialLoading) {
    return <CircularProgress />;
  }

  if (draftWorkflows.length === 0) {
    return (
      <NoEntitiesMessage
        entityName="drafts"
        messageType={MessageType.NO_FILTER_RESULTS}
        searchQuery={searchQuery}
      />
    );
  }

  return (
    <div className={classes.list}>
      {draftWorkflows.map(draftWorkflow => (
        <DraftWorkflowCard
          key={draftWorkflow.id}
          draftWorkflow={draftWorkflow}
          onDeleteDraft={handleDeleteDraftWorkflow}
        />
      ))}
      {hasNextPage && (
        <div className={classes.circularLoadingContainer}>
          <CircularProgress size={24} />
        </div>
      )}
      {confirmationDialog}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  ...experimentsStyles(theme),
}));
