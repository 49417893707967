import React, { useMemo } from 'react';

import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { hasExecution } from 'client/app/apps/simulation-details/dataUtils';
import ResourcesCard from 'client/app/apps/simulation-details/overview/ResourcesCard';
import DeviceSelector from 'client/app/components/Parameters/DeviceSelector';
import { SimulationQuery } from 'client/app/gql';
import { executionRoutes } from 'client/app/lib/nav/actions';
import {
  hasDispenserDevice,
  hasManualDevice,
} from 'client/app/lib/workflow/deviceConfigUtils';
import { WorkflowDeviceConfiguration } from 'common/types/bundle';
import DeviceThumbnail from 'common/ui/components/DeviceThumbnail';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { ExecutionIcon } from 'common/ui/icons/Execution';

type ExecutionModeCardProps = {
  simulation: SimulationQuery['simulation'];
  devicesConfiguration: WorkflowDeviceConfiguration;
};

export default function ExecutionModeCard(props: ExecutionModeCardProps) {
  const { devicesConfiguration, simulation } = props;
  const isManualExecution = hasManualDevice(simulation.workflow.workflow.Config);
  const isDispenserExecution = hasDispenserDevice(simulation.workflow.workflow.Config);
  const simulationWithExecution = hasExecution(simulation);

  const { navigate } = useNavigation();

  const handleNavigate = () => {
    if (!simulation.execution?.id) {
      return;
    }
    navigate(executionRoutes.executionDetailsSubscreen, {
      executionId: simulation.execution?.id,
    });
  };

  const scheduleStatus = useMemo<string | null>(() => {
    if (simulationWithExecution) {
      return null;
    }
    return isManualExecution || isDispenserExecution
      ? 'This simulation has not yet been executed. Click start execution to proceed.'
      : 'This simulation is not currently scheduled for execution.';
  }, [isDispenserExecution, isManualExecution, simulationWithExecution]);

  return (
    <ResourcesCard header="Execution">
      <ResourcesCardContainer>
        {scheduleStatus ? (
          <Typography color="textSecondary">{scheduleStatus}</Typography>
        ) : (
          <ExecutionButton
            data-heap-tracking="navigate-to-execution-button"
            variant="outlined"
            color="primary"
            startIcon={<ExecutionIcon />}
            endIcon={<ArrowForwardOutlinedIcon />}
            onClick={handleNavigate}
          >
            <ButtonTextContainer>
              <ButtonText>{simulation.name}</ButtonText>
              <ButtonCaptionText variant="caption">Execution</ButtonCaptionText>
            </ButtonTextContainer>
          </ExecutionButton>
        )}
        <DeviceContainer>
          <Typography variant="subtitle2">Execution mode</Typography>
          {isManualExecution ? (
            <ManualDeviceContainer>
              <DeviceThumbnail imageUrl="app/cdn/assets/devices/manual.png" />
              <Typography variant="subtitle2" color="textPrimary">
                This simulation was configured for a manual execution.
              </Typography>
            </ManualDeviceContainer>
          ) : (
            <DeviceSelector deviceConfiguration={devicesConfiguration} />
          )}
        </DeviceContainer>
      </ResourcesCardContainer>
    </ResourcesCard>
  );
}

const BUTTON_WIDTH = 278;

const DeviceContainer = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  width: BUTTON_WIDTH,
  gap: spacing(4),
}));

const ManualDeviceContainer = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: spacing(4),
}));

const ResourcesCardContainer = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(6),
}));

const ExecutionButton = styled(Button)(({ theme: { spacing, palette } }) => ({
  justifyContent: 'space-between',
  borderColor: alpha(palette.primary.main, 0.5),
  height: 58,
  width: BUTTON_WIDTH,
  '& .MuiButton-endIcon svg': {
    fontSize: 24,
  },
  '& .MuiButton-startIcon': {
    paddingLeft: spacing(2),
  },
}));

const ButtonTextContainer = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gap: spacing(1),
}));

const ButtonText = styled(Typography)({
  textAlign: 'start',
  textTransform: 'none',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: 182,
});

const ButtonCaptionText = styled(Typography)({
  fontWeight: 700,
  fontSize: 11,
});
