import React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function DOESingleFactorIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9999 4.00006C22.9999 5.38077 21.8807 6.50006 20.4999 6.50006C20.017 6.50006 19.5661 6.36315 19.1839 6.12602L18.0253 7.28374C18.4809 7.90461 18.75 8.67088 18.75 9.5C18.75 11.3142 17.4617 12.8275 15.75 13.175V18.1144C16.7643 18.433 17.5 19.3806 17.5 20.5C17.5 21.8807 16.3807 23 15 23C13.6193 23 12.5 21.8807 12.5 20.5C12.5 19.3806 13.2357 18.433 14.25 18.1145V13.175C12.7806 12.8767 11.6233 11.7193 11.325 10.25H6.38555C6.06699 11.2643 5.11942 12 4 12C2.61929 12 1.5 10.8807 1.5 9.5C1.5 8.11929 2.61929 7 4 7C5.1194 7 6.06697 7.73572 6.38554 8.74998H11.325C11.6725 7.03831 13.1858 5.75 15 5.75C15.699 5.75 16.3533 5.94124 16.9135 6.27424L18.2021 4.98653C18.072 4.68388 17.9999 4.35038 17.9999 4.00006C17.9999 2.61935 19.1192 1.50006 20.4999 1.50006C21.8807 1.50006 22.9999 2.61935 22.9999 4.00006ZM12.6144 10.25C12.8586 11.0275 13.4725 11.6413 14.25 11.8855C14.2522 11.8862 14.2544 11.8869 14.2566 11.8876C14.4914 11.9606 14.7411 12 15 12C15.2613 12 15.5132 11.9599 15.75 11.8856C16.7643 11.567 17.5 10.6194 17.5 9.5C17.5 9.01672 17.3629 8.56547 17.1254 8.18303C17.1085 8.15574 17.091 8.1288 17.073 8.10223C16.8059 7.70686 16.4287 7.39201 15.9854 7.20168C15.683 7.07188 15.3499 7 15 7C13.8806 7 12.933 7.73572 12.6145 8.74998C12.6138 8.75218 12.6131 8.75438 12.6124 8.75658C12.5394 8.99142 12.5 9.24112 12.5 9.5C12.5 9.7613 12.5401 10.0132 12.6144 10.25Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
