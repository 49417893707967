import { useCallback } from 'react';

import { ApolloClient, useApolloClient } from '@apollo/client';

import { getResultOrThrow } from 'client/app/api/apolloClient';
import {
  MUTATION_COPY_WORKFLOW,
  MUTATION_CREATE_WORKFLOW,
  MUTATION_DELETE_WORKFLOW,
  MUTATION_MIGRATE_WORKFLOW,
  MUTATION_UPDATE_WORKFLOW,
  MUTATION_UPDATE_WORKFLOW_ELEMENT_SET,
} from 'client/app/api/gql/mutations';
import {
  CreateWorkflowMutation,
  MigrateWorkflowMutation,
  UpdateWorkflowElementSetMutation,
  WorkflowSourceEnum,
} from 'client/app/gql';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import * as xhr from 'client/app/lib/xhr';
import { EditorType, ElementContextMap, ServerSideBundle } from 'common/types/bundle';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { usePartialCallback } from 'common/ui/hooks/usePartialCallback';

const CREATE_TEMPLATE_WORKFLOW_FROM_SIMULATION_URL =
  '/web/createTemplateWorkflowFromSimulation';

/**
 * Migrates workflow to the most recent element set and returns that workflow.
 */
async function migrateWorkflow(
  apollo: ApolloClient<object>,
  id: WorkflowId,
): Promise<MigrateWorkflowMutation['migrateWorkflow']['workflow']> {
  const res = await apollo.mutate({
    mutation: MUTATION_MIGRATE_WORKFLOW,
    variables: {
      id,
    },
  });
  return getResultOrThrow(res, 'Migrate workflow', data => data.migrateWorkflow.workflow);
}
export function useMigrateWorkflow() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, migrateWorkflow);
}

/**
 * Create a new workflow and return that workfow.
 */
async function createWorkflow(
  apollo: ApolloClient<object>,
  /** Workflow name */
  name: string,
  /**
   * Which part of the app this workflow originates from.
   */
  source: EditorType,
): Promise<NonNullable<CreateWorkflowMutation['createWorkflow']>['workflow']> {
  const createResult = await apollo.mutate({
    mutation: MUTATION_CREATE_WORKFLOW,
    variables: {
      name,
      source: WorkflowSourceEnum[source],
    },
  });
  return getResultOrThrow(
    createResult,
    'Create workflow',
    data => data.createWorkflow!.workflow,
  );
}
export function useCreateWorkflow() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, createWorkflow);
}

// Save a workflow in the db. Returns the new edit version of the workflow.
async function updateWorkflow(
  apollo: ApolloClient<object>,
  id: WorkflowId,
  /** Version the workflow had when it was fetched */
  version: number,
  /** Name of the workflow */
  name: string,
  /** Workflow contents, in the old v1.2 bundle format */
  workflow: ServerSideBundle,
): Promise<{
  version: number;
  elementContextMap: ElementContextMap | null;
}> {
  const updateResult = await apollo.mutate({
    mutation: MUTATION_UPDATE_WORKFLOW,
    variables: {
      id,
      version,
      name,
      workflow,
    },
  });
  return getResultOrThrow(updateResult, 'Update workflow', data => ({
    version: data.updateWorkflow?.workflow.version,
    elementContextMap: data.updateWorkflow?.elementContextMap ?? null,
  }));
}
export function useUpdateWorkflow() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, updateWorkflow);
}

async function copyWorkflow(
  apollo: ApolloClient<object>,
  id: WorkflowId,
  version: number,
  source?: EditorType,
): Promise<WorkflowId> {
  const copyResult = await apollo.mutate({
    mutation: MUTATION_COPY_WORKFLOW,
    variables: {
      id,
      version,
      source: source as unknown as WorkflowSourceEnum,
    },
  });
  return getResultOrThrow(copyResult, 'Copy workflow', data => data.copyWorkflow.id);
}
export function useCopyWorkflow() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, copyWorkflow);
}

/**
 * Copy a given workflow and open it in proper Editor.
 * @param editorType
 */
export function useCopyWorkflowAndNavigate(
  workflowId: WorkflowId | null | undefined,
  version: number | null | undefined,
  editorType: EditorType,
) {
  const copyWorkflow = useCopyWorkflow();
  const navigation = useNavigation();
  const handleCopyWorkflow = useCallback(async () => {
    if (!workflowId || !version) {
      console.error(
        'Could not copy workflow: the current workflow has not finished loading.',
      );
      return;
    }

    const newWorkflowId = await copyWorkflow(workflowId, version, editorType);
    const { route: openInRoute } = getWorkflowPropsBySource(editorType);
    navigation.navigate(openInRoute, {
      workflowId: newWorkflowId,
    });
  }, [copyWorkflow, editorType, navigation, version, workflowId]);
  return handleCopyWorkflow;
}

// Delete a workflow with the given ID from the database. Returns the workflow ID.
async function deleteWorkflow(
  apollo: ApolloClient<object>,
  id: WorkflowId,
): Promise<WorkflowId> {
  const deleteResult = await apollo.mutate({
    mutation: MUTATION_DELETE_WORKFLOW,
    variables: {
      id,
    },
  });
  return getResultOrThrow(
    deleteResult,
    'Delete workflow',
    data => data.deleteWorkflow!.id,
  );
}
export function useDeleteWorkflow() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, deleteWorkflow);
}

export function useCreateTemplateWorkflowFromSimulation() {
  const postJSON = xhr.usePostJSON();
  return useCallback(
    async function createTemplateWorkflowFromSimulation(
      simulationId: SimulationId,
    ): Promise<TemplateWorkflowId> {
      const { id } = await postJSON(
        `${CREATE_TEMPLATE_WORKFLOW_FROM_SIMULATION_URL}/${simulationId}`,
      );
      return id;
    },
    [postJSON],
  );
}

export function useUpdateElementSet() {
  const apollo = useApolloClient();
  return usePartialCallback(apollo, updateElementSet);
}

async function updateElementSet(
  apollo: ApolloClient<object>,
  workflowId: WorkflowId,
  elementSetId: string,
): Promise<UpdateWorkflowElementSetMutation['updateWorkflowElementSet']['workflow']> {
  const updateResult = await apollo.mutate({
    mutation: MUTATION_UPDATE_WORKFLOW_ELEMENT_SET,
    variables: {
      workflowId,
      elementSetId,
    },
  });
  return getResultOrThrow(
    updateResult,
    'Update workflow',
    data => data.updateWorkflowElementSet.workflow,
  );
}
