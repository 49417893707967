import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@mui/material/CircularProgress';

import { ANTHAHUB_BASE_URL } from 'common/ui/anthahubBaseUrl';

export const AnthaHubLogin = () => {
  const { getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      // We don't want the user to be left logged in on antha.com; AnthaHubs are
      // shared machines. We only logged in in order to get the accessToken to
      // send back to AnthaHub.
      logout({
        returnTo: `${ANTHAHUB_BASE_URL}/login?token=${accessToken}`,
        // Note: the `federated:true` option (https://auth0.com/docs/logout)
        // sounds attractive here: with that, we also attempt to log the user
        // out of their identity provider (IdP), if they have one (e.g. G Suite
        // in our case). However, implementation is IdP-specific, and e.g. in
        // the case of G Suite, the user does not get redirected back to the
        // `returnTo` address above, so they can never log in to AnthaHub. So
        // please don't use `federated:true` here.
      });
    })();
  }, [getAccessTokenSilently, logout]);

  return <CircularProgress />;
};
