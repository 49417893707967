import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client/react/hooks/useMutation';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HelpIcon from '@mui/icons-material/InfoOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { MUTATION_DELETE_FILES_FROM_UPLOAD } from 'client/app/api/gql/mutations';
import AddFileButton from 'client/app/apps/simulation-details/overview/results/AddFileButton';
import {
  DeleteMappedPlateButton,
  EditMappedPlateButton,
} from 'client/app/apps/simulation-details/overview/results/PlateMapper';
import {
  ResultsCard,
  ResultsStyles,
} from 'client/app/apps/simulation-details/overview/results/ResultsComponents';
import { SimulationWithExecution } from 'client/app/apps/simulation-details/overview/results/ResultsScreen';
import {
  arePlateDataUploaded,
  LabwarePanelPlate,
  PlateFile,
  usePlates,
} from 'client/app/apps/simulation-details/overview/results/usePlates';
import FileIcon from 'client/app/components/FileBrowser/FileIcon';
import { ArrayElement, SimulationQuery } from 'client/app/gql';
import BarcodeIcon from 'client/app/icons/BarcodeIcon';
import { byName } from 'common/lib/strings';
import { basename } from 'common/lib/strings';
import { Deck } from 'common/types/mix';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

type LabwareHistory = ArrayElement<
  NonNullable<SimulationQuery['simulation']['execution']>['labwareHistory']
>;

type LabwareHistoryDataset = ArrayElement<LabwareHistory['datasets']>;
type LabwareHistoryDatasetOriginFile = LabwareHistoryDataset['originFile'];

type Props = {
  simulation: SimulationQuery['simulation'];
  deck: Deck;
  onRefresh: () => Promise<unknown>;
  setIsFileUploading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFileBeingDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  setPlateFilesUploaded: React.Dispatch<React.SetStateAction<boolean>>;
};

function LabwarePanel({
  simulation,
  deck,
  onRefresh,
  setIsFileUploading,
  setIsFileBeingDeleted,
  setPlateFilesUploaded,
}: Props) {
  const [deleteFilesFromUpload] = useMutation(MUTATION_DELETE_FILES_FROM_UPLOAD);
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  // Make a consolidated deleteFile function that can be used by each deletable file.  All the logic
  // for deleting files is the same, so all the rows can share a single confirmation dialog,
  // mutation, etc, we just need to be able to toggle the per-row loading spinner properly.
  const deleteFile = useCallback(
    async (
      file: LabwareHistoryDatasetOriginFile,
      setLoading: (loading: boolean) => void,
    ) => {
      const confirmed = await openConfirmationDialog({
        action: 'remove',
        isActionDestructive: true,
        object: 'file from execution',
        specificObject: basename(file.originFiletreeLink),
        additionalMessage:
          'The original file and processed data will still be stored in Files, but will no longer be connected to this execution.',
      });
      if (!confirmed) {
        return;
      }

      // The file being deleted indicator is being set during the file deletion action
      setIsFileBeingDeleted(true);
      // We never reset loading to false here because after the data refreshes the row (and thus the
      // loading indicator) will disappear, and we want the spinner to spin until that happens
      setLoading(true);
      await deleteFilesFromUpload({
        variables: { uploadId: file.upload.id, fileIds: [file.id] },
      });
      await onRefresh();
      setIsFileBeingDeleted(false);
    },
    [deleteFilesFromUpload, onRefresh, openConfirmationDialog, setIsFileBeingDeleted],
  );
  const plates = usePlates(simulation, deck, deleteFile);

  useEffect(() => {
    const con = arePlateDataUploaded(plates);
    setPlateFilesUploaded(con);
  }, [plates, setPlateFilesUploaded]);

  // Mapped plates should appear first in the list, so that after a user has created a
  // plate they immediately see it at the top of the page.
  const platesSorted = useMemo<LabwarePanelPlate[]>(() => {
    return plates.sort((plateA, plateB) => {
      if (plateA.synthetic && !plateB.synthetic) {
        return -1; // if plate A is synthetic and plate B isn't, then move it up
      }
      if (!plateA.synthetic && plateB.synthetic) {
        return 1; // if plate A is not synthetic and plate B is, then move it down
      }

      return byName(plateA, plateB);
    });
  }, [plates]);

  if (platesSorted.length === 0) {
    return null;
  }

  return (
    <>
      {platesSorted.map(plate => (
        <PlateCard
          key={plate.name}
          plate={plate}
          // usePlates() returns an empty array if simulation.execution isn't present, so we can
          // rely on it here
          simulation={simulation as SimulationWithExecution}
          deck={deck}
          onRefresh={onRefresh}
          setIsFileUploading={setIsFileUploading}
        />
      ))}
      {confirmationDialog}
    </>
  );
}

type CardProps = {
  plate: LabwarePanelPlate;
  simulation: SimulationWithExecution;
  deck: Deck;
  onRefresh: () => Promise<unknown>;
  setIsFileUploading: React.Dispatch<React.SetStateAction<boolean>>;
};

function PlateCard(props: CardProps) {
  const classes = useStyles();
  const { plate, simulation, deck, onRefresh, setIsFileUploading } = props;
  return (
    <ResultsCard header={plate.name}>
      {plate.synthetic && (
        <div className={classes.syntheticSubtitle}>
          <Typography variant="body2">Synthetic Plate</Typography>
          <Tooltip title="This plate was created using the Plate Mapper">
            <HelpIcon fontSize="small" className={classes.syntheticHelpIcon} />
          </Tooltip>
        </div>
      )}
      {plate.barcode && (
        <div className={classes.iconTextContainer}>
          <BarcodeIcon className={classes.icon} />
          <span>{plate.barcode}</span>
        </div>
      )}
      {plate.files.map(f => (
        <FileRow key={f.name} file={f} />
      ))}
      <div className={classes.addFileButton}>
        <AddFileButton
          labwareId={plate.labwareId}
          labwareName={plate.name}
          executionId={simulation.execution.id}
          onRefresh={onRefresh}
          setIsFileUploading={setIsFileUploading}
        />
        {plate.synthetic && (
          <>
            <EditMappedPlateButton
              simulation={simulation}
              deck={deck}
              labwareId={plate.labwareId}
            />
            <DeleteMappedPlateButton
              plateName={plate.name}
              labwareId={plate.labwareId}
              simulationId={simulation.id}
            />
          </>
        )}
      </div>
    </ResultsCard>
  );
}

type FileRowProps = {
  file: PlateFile;
};

function FileRow(props: FileRowProps) {
  const classes = useStyles();
  const { file } = props;
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <div
      className={cx(classes.iconTextContainer, classes.clickable)}
      onClick={file.onDownload}
    >
      <FileIcon file={{ name: file.name }} />
      <Typography variant="body2" className={classes.fileName}>
        {file.displayName}
      </Typography>
      {file.onDelete && (
        <>
          {loading && <CircularProgress className={classes.deleteProgress} size={24} />}
          {!loading && (
            <Tooltip title="Remove file and processed data">
              <span>
                <IconButton
                  onClick={e => {
                    // TS can't prove that onDelete is defined here because it might have changed
                    // between the check above and the callback being called, but we know it's never
                    // modified
                    file.onDelete!(setLoading);
                    // The row as a whole is clickable, so prevent that callback from being called
                    e.stopPropagation();
                  }}
                  size="small"
                  icon={<DeleteOutlineIcon />}
                />
              </span>
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  icon: ResultsStyles.icon,
  iconTextContainer: ResultsStyles.iconTextContainer,
  clickable: ResultsStyles.clickable,
  addFileButton: {
    marginTop: theme.spacing(2),
  },
  deleteProgress: {
    flexShrink: 0,
  },
  syntheticSubtitle: {
    display: 'flex',
    alignItems: 'center',
  },
  syntheticHelpIcon: {
    marginLeft: theme.spacing(2),
  },
  fileName: {
    wordBreak: 'break-word',
  },
}));

export default LabwarePanel;
