import React from 'react';

import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { removeCommonPrefixes } from 'client/app/lib/antha-lang/identifiers';
import { Side } from 'client/app/lib/layout/ConnectionHelper';
import { Parameter } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import UIBox from 'common/ui/components/UIBox';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  data: Parameter;
  displayName?: string;
  side: Side;
};

export default function PortTooltip(props: Props) {
  const classes = useStyles();
  const {
    data: { name, type },
    side,
    displayName,
  } = props;
  const formattedType = removeCommonPrefixes(type);
  return (
    <div
      className={cx(classes.tooltip, {
        [classes.input]: side === 'input',
        [classes.output]: side === 'output',
      })}
    >
      <div
        className={cx(classes.corner, {
          [classes.inputCorner]: side === 'input',
          [classes.outputCorner]: side === 'output',
        })}
      />
      <UIBox padding="m">
        <Typography>{displayName ?? name}</Typography>
        <Typography variant="caption" className={classes.portType}>
          {formattedType}
        </Typography>
      </UIBox>
    </div>
  );
}

const useStyles = makeStylesHook({
  tooltip: {
    background: Colors.WHITE,
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.6)',
    position: 'absolute',
    top: '-17px' /* one half the height */,
  },
  input: {
    left: '16px',
  },
  output: {
    right: '16px',
  },
  portType: {
    color: Colors.TEAL,
    fontSize: '11px',
    whiteSpace: 'nowrap',
  },
  corner: {
    background: Colors.WHITE,
    height: '10px',
    position: 'absolute',
    top: '17px',
    transform: 'rotate(45deg)',
    width: '10px',
  },
  inputCorner: {
    left: '-5px',
    borderBottom: '1px rgba(128, 128, 128, 0.6) solid',
    borderLeft: '1px rgba(128, 128, 128, 0.3) solid',
  },
  outputCorner: {
    right: '-5px',
    borderRight: '1px rgba(128, 128, 128, 0.6) solid',
    borderTop: '1px rgba(128, 128, 128, 0.3) solid',
  },
});
