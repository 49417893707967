import React, { useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import Factors from 'client/app/components/DOEBuilder/components/Factors';
import { countActiveFactors } from 'client/app/components/Parameters/doeUtils';
import VisserverIFrame from 'client/app/components/VisserverIFrame';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  workflowId: WorkflowId;
  className?: string;
  isEditingParameterFactor: boolean;
  shouldLoadIframe: boolean;
  isReadonly: boolean;
  onClose: () => void;
};

type DOEStep = 'factors' | 'design';

export default function DOEBuilderPanel({
  workflowId,
  className,
  isEditingParameterFactor,
  shouldLoadIframe,
  isReadonly,
  onClose: handleClose,
}: Props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState<DOEStep>(
    isEditingParameterFactor ? 'factors' : 'design',
  );
  const [isLoading, setLoading] = useState(true);

  const content = useMemo(() => {
    switch (activeStep) {
      case 'factors':
        return <Factors workflowId={workflowId} isReadonly={isReadonly} />;
      case 'design':
      default:
        return (
          <>
            {(!shouldLoadIframe || isLoading) && <Loader />}
            <VisserverIFrame
              view="doe_designer"
              params={{
                workflow_id: workflowId,
              }}
              onReady={() => setLoading(false)}
              shouldNotResize
            />
          </>
        );
    }
  }, [activeStep, isLoading, isReadonly, shouldLoadIframe, workflowId]);

  return (
    <Grow in unmountOnExit>
      <Paper className={cx(className, classes.panel)} elevation={4}>
        <header>
          <Typography variant="h5" color="textPrimary">
            DOE Design
          </Typography>
          <DOEStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            isReadonly={isReadonly}
          />
          <CloseIcon className="close" onClick={handleClose} />
        </header>
        <main>{content}</main>
      </Paper>
    </Grow>
  );
}

type DOEStepperProps = {
  activeStep: DOEStep;
  setActiveStep: (step: DOEStep) => void;
  isReadonly: boolean;
};

function DOEStepper({ activeStep, setActiveStep, isReadonly }: DOEStepperProps) {
  const factors = useWorkflowBuilderSelector(state => state.factors);
  const factorsCount = factors ? countActiveFactors(factors) : 0;
  const classes = useStyles({ activeStep, factorsCount });

  useEffect(() => {
    if (factorsCount === 0) {
      setActiveStep('factors');
    }
  }, [factorsCount, setActiveStep]);

  return (
    <nav>
      <Typography
        className={classes.factorsStep}
        variant="button"
        color="primary"
        onClick={() => setActiveStep('factors')}
      >
        <span>Factors</span>
        <span className="factors-count">{factorsCount}</span>
      </Typography>

      {
        // Until the Design app can restore it's state and show a read only
        // mode, don't let the user navigate here
        !isReadonly && (
          <>
            <div className={classes.stepArrow} />

            <Typography
              className={classes.designStep}
              variant="button"
              color="primary"
              onClick={() => factorsCount > 0 && setActiveStep('design')}
            >
              Calculate design
            </Typography>
          </>
        )
      }
    </nav>
  );
}

const Loader = () => (
  <Box display="flex" width="100%" height="100%" padding="20px">
    <CircularProgress size={40} />
  </Box>
);

const useStyles = makeStylesHook<string, { activeStep: DOEStep; factorsCount: number }>(
  ({ palette, spacing }) => ({
    panel: {
      backgroundColor: Colors.WHITE,
      borderRadius: spacing(3),
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',

      '& header': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: spacing(3, 5),
        borderBottom: `1px solid ${Colors.GREY_20}`,

        '& > .close': {
          color: Colors.GREY_50,
          cursor: 'pointer',
        },
      },
      '& nav': {
        display: 'flex',
        borderRadius: '6px',
        border: `1px solid ${Colors.BLUE_20}`,
        padding: spacing(1),
        userSelect: 'none',
        position: 'relative',

        '& > .MuiTypography-button': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'baseline',

          padding: spacing(2),
          borderRadius: spacing(2),
          width: '185px',
          cursor: 'pointer',
        },
      },
      '& main': {
        overflow: 'hidden',
        flexGrow: 1,
      },
    },
    factorsStep: ({ activeStep }) => ({
      background:
        activeStep === 'factors' ? palette.primary.main : palette.primary.contrastText,
      color:
        activeStep === 'factors' ? palette.primary.contrastText : palette.primary.main,

      '& .factors-count': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: activeStep !== 'factors' ? palette.primary.main : Colors.BLUE_30,
        color: palette.primary.contrastText,
        borderRadius: '50%',
        width: '18px',
        height: '18px',
        fontSize: '12px',
        lineHeight: '1em',
        marginLeft: spacing(3),
      },
    }),
    stepArrow: ({ activeStep }) => ({
      position: 'absolute',
      top: '6px',
      left: '174px',
      width: '21px',
      height: '21px',

      borderRadius: '3px',
      transform: 'rotate(45deg)',
      background:
        activeStep === 'factors' ? palette.primary.main : palette.primary.contrastText,
    }),
    designStep: ({ activeStep, factorsCount }) => ({
      background:
        activeStep === 'design' ? palette.primary.main : palette.primary.contrastText,
      color:
        factorsCount === 0
          ? palette.primary.light
          : activeStep === 'design'
          ? palette.primary.contrastText
          : palette.primary.main,
    }),
  }),
);
