import React from 'react';

import AxisLabel, {
  Props as AxisLabelProps,
} from 'common/ui/components/simulation-details/mix/AxisLabel';

type Props = Omit<AxisLabelProps, 'colOrRow'>;

/**
 * Renders the labels along the side of a plate.
 */
export default function AxisLabels({
  geometry,
  onLabelClick,
  axis,
  isInteractive,
}: Props) {
  const nLabels = axis === 'horizontal' ? geometry.columns : geometry.rows;
  return (
    <g>
      {Array.from({ length: nLabels }, (_, colOrRow) => (
        <AxisLabel
          key={colOrRow}
          axis={axis}
          colOrRow={colOrRow}
          onLabelClick={onLabelClick}
          geometry={geometry}
          isInteractive={isInteractive}
        />
      ))}
    </g>
  );
}
