import { createContext, ReactNode, useContext, useMemo } from 'react';
import React from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_UI_BOOTUP } from 'client/app/api/gql/queries';
import { setUser } from 'client/app/lib/errors';
import LinearProgress from 'common/ui/components/LinearProgress';

export type UserProfileType = {
  // Appserver's UUID
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  isSynthaceEmployee: boolean;
  organisationName: string;
  organisationId: string;
  organisationHumanIdentifier: string;
  isSupport: boolean;
  email: string;

  // intercom specific
  intercomUserId: string;
  intercomToken: string;
  intercomAppId: string;
};

export function useUserProfile(): UserProfileType | undefined {
  return useContext(UserProfileContext);
}

export const UserProfileContext = createContext<UserProfileType | undefined>(undefined);

/**
 * Preloads user profile data so that it can be immediately used by calling
 * useUserProfile().
 */
export function UserProfileContextProvider({ children }: { children: ReactNode }) {
  const { data, loading } = useQuery(QUERY_UI_BOOTUP);

  const profile = useMemo(() => {
    if (data) {
      const profile: UserProfileType = {
        id: data.uiBootup.loggedInUser.id,
        // Intercom user ID can be anything, it just needs to be unique and
        // static for any given user. We used to use the user's Google Datastore
        // ID here, so if they have one of those (it'll be in
        // loggedInUser.legacyId) then we must continue to use it. Users
        // registered since we retired the authentication microservice won't
        // have a legacy ID, so in that case we'll use their regular ID.
        intercomUserId:
          data.uiBootup.loggedInUser.legacyId ?? data.uiBootup.loggedInUser.id,
        email: data.uiBootup.loggedInUser.email,
        firstName: data.uiBootup.loggedInUser.firstName,
        lastName: data.uiBootup.loggedInUser.lastName,
        displayName: data.uiBootup.loggedInUser.displayName,
        isSynthaceEmployee: data.uiBootup.loggedInUser.isSynthaceEmployee,
        intercomToken: data.uiBootup.intercom.token,
        intercomAppId: data.uiBootup.intercom.appId,
        organisationName: data.uiBootup.loggedInOrg.name,
        organisationId: data.uiBootup.loggedInOrg.id,
        organisationHumanIdentifier: data.uiBootup.loggedInOrg.humanIdentifier,
        isSupport: data.uiBootup.loggedInOrg.isSupport,
      };
      // Set Sentry's scope as the currently logged in user.
      setUser(profile);
      return profile;
    }
    return;
  }, [data]);

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <UserProfileContext.Provider value={profile}>{children}</UserProfileContext.Provider>
  );
}
