import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';

import EarlyToken from 'client/app/apps/Login/EarlyToken';
import LoginPage from 'client/app/apps/Login/LoginPage';
import { AUTH0_CUSTOM_DOMAIN, AUTH0_PROD_DOMAIN } from 'common/auth/constants';
import { Auth0Config } from 'common/types/auth0';
import useStateWithLocalStorage from 'common/ui/hooks/useStateWithLocalStorage';
import { getAuth0Config } from 'common/ui/lib/auth0Config';

export const AUTH0_CLIENT_ID_KEY = 'auth0_client_id';
export const CUSTOMER_CONNECTION_NAME_KEY = 'customer_connection_name';
export const CUSTOMER_CONN_INITIAL_VALUE = '';

type LoginWithRedirect = Auth0ContextInterface['loginWithRedirect'];
type LoginProps = {
  children: React.ReactNode;
};

/**
 * This component checks the auth status and decide to show the LoginPage or the app.
 */
function LoginCheck(props: LoginProps) {
  const [auth0ClientId, setAuth0ClientId] = useStateWithLocalStorage<string>(
    AUTH0_CLIENT_ID_KEY,
    '',
  );

  const [customerConnectionName, setCustomerConnectionName] = useStateWithLocalStorage<
    string | undefined
  >(CUSTOMER_CONNECTION_NAME_KEY, CUSTOMER_CONN_INITIAL_VALUE);

  const [auth0Config, setAuth0Config] = useState<Auth0Config | null>(null);

  useEffect(() => {
    (async () => {
      const config = await getAuth0Config();
      setAuth0Config(config);
    })();
  }, []);

  const { children } = props;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuth0Loading, setIsAuth0Loading] = useState(true);

  // `loginWithRedirect` is a function and we cannot check equality between render.
  // To avoid infinite loop we use a ref.
  const loginWithRedirectRef = useRef<undefined | LoginWithRedirect>();
  const setLoginWithRedirect = useCallback(
    (loginWithRedirect: LoginWithRedirect) => {
      loginWithRedirectRef.current = loginWithRedirect;
    },
    [loginWithRedirectRef],
  );

  return (
    <>
      <LoginPage
        isAuth0Loading={isAuth0Loading}
        isAuthenticated={isAuthenticated}
        auth0ClientId={auth0ClientId}
        setAuth0ClientId={setAuth0ClientId}
        customerConnectionName={customerConnectionName}
        setCustomerConnectionName={setCustomerConnectionName}
        loginWithRedirectRef={loginWithRedirectRef}
      />
      {auth0Config && (
        <Auth0Provider
          domain={getAuthLoginDomain(auth0Config)}
          audience={auth0Config.audience}
          clientId={auth0ClientId}
          redirectUri={window.location.origin}
          useRefreshTokens
          cacheLocation="localstorage"
          connection={getAuthConnection(customerConnectionName)}
          // There's an issue with Auth0Provider: changing auth0ClientId (as we do
          // when a user types their org name into the login screen) doesn't
          // re-initialise the Auth0Context's loginWithRedirect callback, so we
          // direct the user to the *wrong* login screen - either the screen for a
          // null client if they're logging in for the first time, or the screen for
          // the old client if they're changing orgs.
          //
          // The fix is to set this key prop to {auth0ClientId}. That forces React
          // to consider this a new component if auth0ClientId changes, then React
          // reinitialises everything.
          //
          // A drawback of this approach is that when the auth0ClientId changes,
          // React will drop this component and children altogether, losing any
          // state. So we have to take extra care with that.
          // To mitigate this, we move out to not be a descendant of Auth0Provider.
          key={auth0ClientId}
        >
          <AuthInversedDataFlow
            setIsAuth0Loading={setIsAuth0Loading}
            setIsAuthenticated={setIsAuthenticated}
            setLoginWithRedirect={setLoginWithRedirect}
          />
          <EarlyToken>{isAuthenticated && <>{children}</>}</EarlyToken>
        </Auth0Provider>
      )}
    </>
  );
}

// Allow overriding auth domain via url params in case someone wants to test
// the auth with a different auth0 domain
function getAuthLoginDomain(auth0Config: Auth0Config): string {
  const params = new URLSearchParams(window.location.search);
  const domain = params.get('authdomain');
  // only allow specific values, otherwise it would be an open redirect
  if (
    domain === AUTH0_CUSTOM_DOMAIN ||
    domain === AUTH0_PROD_DOMAIN ||
    domain === 'auth.synthace.io'
  ) {
    return domain;
  }
  console.warn('invalid authdomain parameter, using default: ' + auth0Config.domain);
  return auth0Config.domain;
}

// Allow overriding auth0 connection via url params in case someone wants to test
// the auth with a different user source - in particular with a different SSO setup.
// `undefined` result indicates that "connection" parameter shouldn't be set
// when authenticating.
function getAuthConnection(defaultConnection: string | undefined): string | undefined {
  const params = new URLSearchParams(window.location.search);
  return params.get('authconn') || defaultConnection;
}

// Auth0Provider resets and dismount/remount when the auth0ClientId changes;
// to avoid the LoginPage to do the same and the org input to loose focus we need to take LoginPage out and up.
// But LoginPage still need auth0's data! This component propagate back the data from auth0 up the common parent (LoginCheck).
// This is unnatural data flow for React and can be dangerous and cause infinite rerender, but here we are fine:
// - most state is boolean and string, with React.memo we avoid rerender;
// - loginWithRedirect is a function, instead of saving it in a state, we use a ref.
const AuthInversedDataFlow = React.memo(function AuthInversedDataFlow(props: {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAuth0Loading: React.Dispatch<React.SetStateAction<boolean>>;
  setLoginWithRedirect: (loginWithRedrecit: LoginWithRedirect) => void;
}) {
  const { setIsAuth0Loading, setIsAuthenticated, setLoginWithRedirect } = props;
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  // We propagate back up the auth0 data we need.
  useEffect(() => {
    setIsAuth0Loading(isLoading);
  }, [isLoading, setIsAuth0Loading]);
  useEffect(() => {
    setIsAuthenticated(isAuthenticated);
  }, [isAuthenticated, setIsAuthenticated]);
  useEffect(() => {
    setLoginWithRedirect(loginWithRedirect);
  }, [loginWithRedirect, setLoginWithRedirect]);
  return null;
});

export default LoginCheck;
