import React from 'react';

import { ExamplesList } from 'client/app/apps/experiments/ExampleGallery/ExamplesList';
import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';

export default function ExampleGalleryScreen() {
  return (
    <ExperimentsNavigation title="Example Gallery">
      <ExamplesList />
    </ExperimentsNavigation>
  );
}
