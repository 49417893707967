import { useEffect, useMemo, useState } from 'react';

import { useApolloClient } from '@apollo/client';

import { QUERY_DEVICE_CONFIG_RESPONSE } from 'client/app/api/gql/queries';
import {
  getSelectedMainDevice,
  useGetDeviceCommonForWorkflow,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import { TIPTYPES } from 'client/app/lib/workflow/workflowConfigProperties';
import { TipTypeAdditionalProps } from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { WorkflowDeviceConfiguration } from 'common/types/bundle';
import { TipType } from 'common/types/device';

const NO_TIP_TYPES: TipType[] = [];

export function useTipTypes(
  deviceConfiguration: WorkflowDeviceConfiguration,
): TipTypeAdditionalProps {
  const devices = useGetDeviceCommonForWorkflow(deviceConfiguration);
  const mainDevice = getSelectedMainDevice(deviceConfiguration, devices);
  return useDeviceTipTypes(mainDevice.deviceId, mainDevice.selectedDevice?.runConfigId);
}

export function useDeviceTipTypes(
  deviceId: string,
  runConfigId?: string,
): TipTypeAdditionalProps {
  const apollo = useApolloClient();
  const [supportedTipTypes, setSupportedTipTypes] = useState<TipType[]>(NO_TIP_TYPES);

  useEffect(() => {
    const retrieveDeviceData = async () => {
      if (deviceId) {
        const runConfig = await apollo
          .query({
            query: QUERY_DEVICE_CONFIG_RESPONSE,
            variables: {
              id: deviceId,
              runConfigId,
            },
          })
          // If we aren't getting a proper response, let's just show all tip types.
          .catch(() => setSupportedTipTypes(NO_TIP_TYPES));
        if (runConfig?.data) {
          setSupportedTipTypes(
            runConfig?.data.parsedRunConfig.config.supportedTipTypes ?? NO_TIP_TYPES,
          );
        }
      } else {
        // Reset the tip types if a device has not been selected.
        setSupportedTipTypes(NO_TIP_TYPES);
      }
    };
    void retrieveDeviceData();
  }, [apollo, deviceId, runConfigId]);

  return useMemo(
    () => ({
      editor: EditorType.TIP_TYPE,
      itemEditor: {
        additionalProps: {
          ...TIPTYPES.configuration?.editor.additionalProps,
          supportedTipTypes,
        },
      },
    }),
    [supportedTipTypes],
  );
}
