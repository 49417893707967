import { v4 as uuid } from 'uuid';

import { indexById } from 'common/lib/data';
import { filterObject, mapObject } from 'common/object';
import {
  Connection,
  defaultWorkflowConfig,
  DUMMY_REPOSITORIES,
  Element,
  Factors,
  ServerSideBundle,
  ServerSideElementInstance,
  WorkflowConfig,
  WorkflowConfigDeviceClassKey,
} from 'common/types/bundle';
import {
  LegacyBundle,
  LegacyConfig,
  LegacyConnection,
  LegacyParameterValueDict,
  LegacyProcess,
} from 'common/types/legacyBundle';
import { withoutNullFields } from 'common/utils';

// Connections

export function convertLegacyConnectionToV2(obj: LegacyConnection): Connection {
  return {
    Source: {
      ElementInstance: obj.source.process,
      ParameterName: obj.source.port,
    },
    Target: {
      ElementInstance: obj.target.process,
      ParameterName: obj.target.port,
    },
  };
}

export function convertV2ConnectionToLegacy(connection: Connection): LegacyConnection {
  return {
    source: {
      process: connection.Source.ElementInstance,
      port: connection.Source.ParameterName,
    },
    target: {
      process: connection.Target.ElementInstance,
      port: connection.Target.ParameterName,
    },
  };
}

// Element instances

function convertLegacyProcessToV2ElementInstance(
  process: LegacyProcess,
  parameters: LegacyParameterValueDict,
): ServerSideElementInstance {
  return {
    TypeName: process.component,
    Id: process.id || uuid(),
    Meta: process.metadata,
    Parameters: parameters,
  };
}

export function convertV2ElementInstanceToLegacyProcess(
  instance: ServerSideElementInstance,
): LegacyProcess {
  return {
    component: instance.TypeName,
    id: instance.Id,
    metadata: {
      x: instance.Meta.x,
      y: instance.Meta.y,
    },
  };
}

// Config

/**
 * Config options Antha Core still expects, and which we don't want
 * to be editable in the UI.
 * (There are no known use cases where a user would want to change these.)
 */
export const DEFAULT_VALUES_OF_DEPRECATED_CONFIG_OPTIONS = {
  plateIds: [],
  maxPlates: 20,
  maxWells: 2000,
  modelEvaporation: false,
  optimizeSplitChains: true,
  outputPlateTypes: [] as string[],
  outputSort: false,
  executionPlannerVersion: 'ep2',
  residualVolumeWeight: 1.0,
  driverSpecificWashPreferences: [] as string[],
};

/**
 * Substrings to look for within a device class name to identify a particular
 * type. Previously we just checked if a class included the key, however for
 * "Tecan" this matched both TecanLiquidHandler (good) and TecanPlateReader
 * (bad, this isn't a liquid handler).
 *
 * This is a temporary workaround until we do a refactor to make use of
 * device_categories instead of antha_lang_device_class.
 *
 * TODO: Remove this when we refactor/remove antha_lang_device_class:
 * https://synthace.atlassian.net/browse/CA-46
 */
const classSubstrings: Record<WorkflowConfigDeviceClassKey, string> = {
  GilsonPipetMax: 'GilsonPipetMax',
  Tecan: 'TecanLiquidHandler',
  TecanFluent: 'TecanFluent',
  Hamilton: 'HamiltonMicrolab',
  QPCR: 'QPCR',
  CyBio: 'CyBio',
  Labcyte: 'Labcyte',
  TTP: 'TTP',
  Formulatrix: 'Formulatrix',
  ShakerIncubator: 'ShakerIncubator',
  PlateReader: 'PlateReader',
  PlateWasher: 'PlateWasher',
  DeCapper: 'DeCapper',
  OpentronsOT2: 'OpentronsOT2',
  CertusFlex: 'CertusFlex',
  Manual: 'Manual',
  GilsonPipettePilot: 'GilsonPipettePilot',
};

/**
 * Crude way to match a device from Postgres (where we store the device class)
 * to one of the fixed keys on the workflow config.
 *
 * TODO(egor): this code should die when we refactor/remove antha_lang_device_class.
 */
export function deviceClassMatchesAnthaConfigKey(
  /** e.g. 'HamiltonMicrolabSTARlet' */
  dbDeviceClass: string,
  /** e.g. 'Hamilton' */
  workflowConfigKey: WorkflowConfigDeviceClassKey,
) {
  // This is obviously not ideal, we should store the string 'Hamilton' in the db.
  return dbDeviceClass
    .toLowerCase()
    .includes(classSubstrings[workflowConfigKey].toLowerCase());
}

export function getDeviceClassByAnthaConfigKey(
  workflowConfigKey: WorkflowConfigDeviceClassKey,
) {
  return classSubstrings[workflowConfigKey];
}

export function getAnthaConfigKeyByDeviceClass(
  anthaLangDeviceClass: string = 'Manual',
): WorkflowConfigDeviceClassKey {
  for (const configKey in classSubstrings) {
    const configDeviceClassKey = configKey as WorkflowConfigDeviceClassKey;

    if (classSubstrings[configDeviceClassKey] === anthaLangDeviceClass) {
      return configDeviceClassKey;
    }
  }
  return 'Manual';
}

/**
 * Convert v1 workflow config into v2 workflow config.
 * Useful when Opening a legacy job in the old workflow builder, or when uploading a v1 workflow.
 */
export function convertLegacyConfigToV2(
  legacyConfig: LegacyConfig,
  /**
   * Full list of devices that exist in the db.
   */
  allDevices: readonly { model: { anthaLangDeviceClass: string } }[],
): WorkflowConfig {
  const devicesById = indexById(allDevices);
  const maybeDevicesV2 = mapObject(
    legacyConfig.devices ?? {},
    (deviceId, legacyDeviceConfig) => {
      const device = devicesById[deviceId];
      if (!device) {
        console.warn(`Cannot find device with id ${deviceId}. Skipping the device.`);
        // Just skip the device. We can still reconstruct the rest of the config which is useful.
        // Don't crash.
        return undefined;
      }
      const runConfigId = legacyDeviceConfig?.runConfigId ?? null;
      return {
        inputPlateTypes: legacyConfig.inputPlateTypes,
        runConfigId,
        runConfigVersion: runConfigId !== null ? 1 : undefined,
        tipTypes: legacyConfig.tipTypes,
      };
    },
  );
  // the filterObject does not understand we are getting rid of undefined
  const devicesV2 = withoutNullFields(
    filterObject(
      maybeDevicesV2,
      (_deviceId, maybeDeviceV2) => maybeDeviceV2 !== undefined,
    ),
  );

  function getDevicesV2OfClass(deviceClassKey: WorkflowConfigDeviceClassKey) {
    return filterObject(devicesV2, (deviceId, _deviceConfig) =>
      deviceClassMatchesAnthaConfigKey(
        devicesById[deviceId].model.anthaLangDeviceClass,
        deviceClassKey,
      ),
    );
  }

  return {
    GlobalMixer: {
      balancingStrategy: 'empty',
      balancingTolerance: 20,
      isCentrifugeEnabled: true,
      allocateInputsVersion:
        legacyConfig.allocateInputsVersion ||
        defaultWorkflowConfig().GlobalMixer.allocateInputsVersion,
      ignorePhysicalSimulation: legacyConfig.ignorePhysicalSimulation,
      inputPlateTypes: legacyConfig.inputPlateTypes,
      liquidHandlingPolicyXlsxJmpFile: legacyConfig.liquidHandlingPolicyXlsxJmpFile,
      liquidHandlingPolicyXlsxJmpFileName:
        legacyConfig.liquidHandlingPolicyXlsxJmpFileName,
      hamiltonStandardLanguage: false,
      barrierMerging: legacyConfig.barrierMerging,
      tipTypes: legacyConfig.tipTypes,
      useDriverTipTracking: legacyConfig.useDriverTipTracking,
      useTipboxAutofill: legacyConfig.useTipboxAutofill,
      requiresDevice: true,
      driverSpecificInputPreferences: legacyConfig.driverSpecificInputPreferences,
      driverSpecificOutputPreferences: legacyConfig.driverSpecificOutputPreferences,
      driverSpecificTemporaryLocations: [],
      driverSpecificPlatePreferences: {},
      driverSpecificTipPreferences: legacyConfig.driverSpecificTipPreferences,
      driverSpecificTipWastePreferences: legacyConfig.driverSpecificTipWastePreferences,
    },
    GilsonPipetMax: { Devices: getDevicesV2OfClass('GilsonPipetMax') },
    Tecan: { Devices: getDevicesV2OfClass('Tecan') },
    TecanFluent: { Devices: getDevicesV2OfClass('TecanFluent') },
    Hamilton: { Devices: getDevicesV2OfClass('Hamilton') },
    QPCR: { Devices: getDevicesV2OfClass('QPCR') },
    CyBio: { Devices: getDevicesV2OfClass('CyBio') },
    Labcyte: { Devices: getDevicesV2OfClass('Labcyte') },
    TTP: { Devices: getDevicesV2OfClass('TTP') },
    Formulatrix: { Devices: getDevicesV2OfClass('Formulatrix') },
    ShakerIncubator: { Devices: getDevicesV2OfClass('ShakerIncubator') },
    PlateReader: { Devices: getDevicesV2OfClass('PlateReader') },
    PlateWasher: { Devices: getDevicesV2OfClass('PlateWasher') },
    DeCapper: { Devices: getDevicesV2OfClass('DeCapper') },
    OpentronsOT2: { Devices: getDevicesV2OfClass('OpentronsOT2') },
    CertusFlex: { Devices: getDevicesV2OfClass('CertusFlex') },
    Manual: { Devices: getDevicesV2OfClass('Manual') },
    GilsonPipettePilot: { Devices: getDevicesV2OfClass('GilsonPipettePilot') },
  };
}

/**
 * Converts a legacy (pre-Dec 2019) v1 bundle to the format we store in Postgres.
 */
export function convertLegacyBundleToServerSideBundle(
  legacyBundle: LegacyBundle,
  allDevices: readonly { model: { anthaLangDeviceClass: string } }[],
): ServerSideBundle {
  return {
    WorkflowId: legacyBundle.Properties.id,
    Meta: { Name: legacyBundle.Properties.name },
    Repositories: DUMMY_REPOSITORIES,
    Elements: {
      Instances: Object.entries(legacyBundle.Processes).reduce(
        (acc, [name, process]) => ({
          ...acc,
          [name]: convertLegacyProcessToV2ElementInstance(
            process,
            legacyBundle.Parameters[name],
          ),
        }),
        {},
      ),
      InstancesConnections: legacyBundle.connections.map(convertLegacyConnectionToV2),
    },
    Config: convertLegacyConfigToV2(legacyBundle.Config, allDevices),
    // Setting SchemaVersion to 2.0 because the conversion logic implements conversion
    // to 2.0 format. The caller might updated it later.
    SchemaVersion: '2.0',
    elementSetId: legacyBundle.elementSetId,
  };
}

/**
 * Removes elements from bundle including connections to the removed elements.
 * Returns new bundle without specified elements.
 */
export function removeElements(
  bundle: ServerSideBundle,
  elementNamesToRemove: Set<string>,
): ServerSideBundle {
  const instancesToRemove = new Set(
    Object.entries(bundle.Elements.Instances)
      .filter(([_instanceName, instance]) => elementNamesToRemove.has(instance.TypeName))
      .map(([instanceName]) => instanceName),
  );

  const instances = filterObject(
    bundle.Elements.Instances,
    instanceName => !instancesToRemove.has(instanceName),
  );

  const connections = bundle.Elements.InstancesConnections.filter(
    connection =>
      !instancesToRemove.has(connection.Source.ElementInstance) &&
      !instancesToRemove.has(connection.Target.ElementInstance),
  );

  const factors: Factors | undefined = bundle.Factors?.filter(
    factor => factor.path && instancesToRemove.has(factor.path[1]),
  );

  return {
    ...bundle,
    Elements: {
      Instances: instances,
      InstancesConnections: connections,
    },
    Factors: factors,
  };
}

export function getMissingElementNames(
  elementNames: string[],
  availableElements: readonly Pick<Element, 'name'>[],
): Set<string> {
  const availableElementNames = new Set(availableElements.map(element => element.name));

  if (availableElementNames.size === 0) {
    return new Set();
  }

  return new Set(
    elementNames.filter(elementName => !availableElementNames.has(elementName)),
  );
}
