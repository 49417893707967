import axios from 'axios';
import produce from 'immer';
import findLastIndex from 'lodash/findLastIndex';

import castValueToType from 'common/lib/castValueToType';
import { downloadBlob } from 'common/lib/download';
import isJSON from 'common/lib/isJSON';
import { Position2d } from 'common/types/Position';
import { ColumnConfiguration, SpreadsheetConfiguration } from 'common/types/spreadsheet';
import {
  CellValue,
  DataTable,
  Row,
  Sheet,
  Spreadsheet,
  TableColumn,
} from 'common/types/spreadsheetEditor';
import { isEmptyCellValue } from 'common/ui/components/tableUtils';

export const MINIMUM_NUMBER_OF_ROWS = 20;

/**
 * Create a spreadsheet based on the user defined
 * spreadsheet configuration.
 *
 * @param spreadsheet (Optional) If present, add this spreadsheet's columns
 * to the configuration.
 * @param headersOnly If true, return no data. Defaults to false.
 */
function computeSpreadsheet(
  configuration: SpreadsheetConfiguration,
  spreadsheet?: Spreadsheet,
  headersOnly: boolean = false,
) {
  const defaultSpreadsheet: Spreadsheet = { sheets: [] };
  for (const sheetConfiguration of configuration.sheets) {
    const table: DataTable = {
      data: headersOnly ? [] : getStartingRows(sheetConfiguration.columns),
      schema: {
        fields: sheetConfiguration.columns.map(({ name, dataType }) => ({
          name,
          type: dataType,
        })),
      },
    };

    defaultSpreadsheet.sheets.push({
      name: sheetConfiguration.name,
      table,
    });
  }

  if (spreadsheet?.sheets && spreadsheet.sheets.length > 0) {
    return mergeSpreadsheets(spreadsheet, defaultSpreadsheet);
  }

  return defaultSpreadsheet;
}

export function getDefaultSpreadsheet(configuration: SpreadsheetConfiguration) {
  return computeSpreadsheet(configuration);
}

/** Get a spreadsheet based on the configuration, with no data.  */
export function getDefaultSpreadsheetHeaders(configuration: SpreadsheetConfiguration) {
  return computeSpreadsheet(configuration, undefined, true);
}

/**
 * Get a spreadsheet based on the configuration and extra columns
 * from the `spreadsheet` argument.
 */
export function getCustomSpreadsheet(
  configuration: SpreadsheetConfiguration,
  spreadsheet: Spreadsheet,
) {
  return computeSpreadsheet(configuration, spreadsheet);
}

/**
 * Users can upload a spreadsheet which has more columns than expected in the configuration.
 * In order to keep these extra columns, we add them to the datatable schema.
 */
function mergeSpreadsheets(
  existingSpreadsheet: Spreadsheet,
  defaultSpreadsheet: Spreadsheet,
) {
  const sheets: Sheet[] = defaultSpreadsheet.sheets.map((defaultSheet, sheetIndex) => {
    if (sheetIndex >= existingSpreadsheet.sheets.length) {
      return defaultSheet;
    }

    const defaultFields = defaultSheet.table.schema.fields;
    const existingFields = existingSpreadsheet.sheets[sheetIndex]?.table.schema.fields;
    const nonDuplicateFields = existingFields.filter(
      field => !defaultFields.find(defaultField => defaultField.name === field.name),
    );
    const allFields = [...defaultFields, ...nonDuplicateFields];

    const existingData = existingSpreadsheet.sheets[sheetIndex].table.data;
    const defaultData = defaultSpreadsheet.sheets[sheetIndex].table.data;
    const allData = existingData.concat(
      defaultData.slice(0, MINIMUM_NUMBER_OF_ROWS - existingData.length),
    );
    const sanitizedData = produce(allData, draft => {
      fillRowsWithMissingProps(draft, allFields);
    });

    return {
      ...defaultSheet,
      table: {
        data: sanitizedData,
        schema: { ...defaultSheet.table.schema, fields: allFields },
      },
    };
  });

  return { sheets };
}

/** Get a row whose cells are filled based on the fill strategy defined in the config. */
export function getStartingRow(
  columnConfigurations: Pick<ColumnConfiguration, 'name'>[],
) {
  return columnConfigurations.reduce((row, { name }) => {
    return {
      ...row,
      [name]: null,
    };
  }, {} as Row);
}

/** Get a given number of pre-filled rows. */
export function getRows(
  numOfRowsToGet: number,
  columnConfigurations: Pick<ColumnConfiguration, 'name'>[],
) {
  return Array(numOfRowsToGet)
    .fill(null)
    .map(() => getStartingRow(columnConfigurations));
}

/**
 * Get a default number of pre-filled rows to show
 * when opening the Spreadsheet editor.
 **/
export function getStartingRows(
  columnConfigurations: Pick<ColumnConfiguration, 'name'>[],
  existingRowCount: number = 0,
) {
  return getRows(
    Math.max(0, MINIMUM_NUMBER_OF_ROWS - existingRowCount),
    columnConfigurations,
  );
}

export function getLastNonEmptyRowIndex(rows: Row[]) {
  return findLastIndex(rows, row =>
    Object.values(row).some(cell => !isEmptyCellValue(cell)),
  );
}

/** If index is not found, returns 0 */
export function getLastNonEmptyRowIndexByColumn(
  dataTable: DataTable,
  columnIndex: number,
) {
  const {
    data: rows,
    schema: { fields },
  } = dataTable;
  const columnNames = fields.map(field => field.name);
  return Math.max(
    0,
    findLastIndex(rows, row => row[columnNames[columnIndex]] !== null),
  );
}

/** If index is not found, returns 0 */
export function getLastNonEmptyRowIndexInRange(
  dataTable: DataTable,
  startColumnIndex: number,
  endColumnIndex: number,
) {
  const {
    data: rows,
    schema: { fields },
  } = dataTable;
  const columnNames = fields.map(field => field.name);

  let lastNonEmptyIndexInSelection = 0;
  for (let columnIndex = startColumnIndex; columnIndex <= endColumnIndex; columnIndex++) {
    lastNonEmptyIndexInSelection = Math.max(
      findLastIndex(rows, row => row[columnNames[columnIndex]] !== null),
      lastNonEmptyIndexInSelection,
    );
  }

  return lastNonEmptyIndexInSelection;
}

export function getColumnNameByIndex(data: DataTable, columnIndex: number) {
  const {
    schema: { fields },
  } = data;
  const columnNames = fields.map(field => field.name);
  return columnNames[columnIndex];
}

export function getDataFromSpreadsheet(
  spreadsheet: Spreadsheet,
  shouldReturnArray: boolean,
): DataTable | DataTable[] | null {
  const tablesHaveData = spreadsheet.sheets.some(
    sheet => getLastNonEmptyRowIndex(sheet.table.data) !== -1,
  );
  if (!tablesHaveData) {
    return null;
  }

  function getTrimmedDataTable(sheet: Sheet) {
    const { schema, data } = sheet.table;

    // We may display more rows than are actually filled out, so trim off contiguous
    // empty ones at the end.
    const lastNonEmptyRow = getLastNonEmptyRowIndex(data);
    return {
      schema,
      data: lastNonEmptyRow !== -1 ? data.slice(0, lastNonEmptyRow + 1) : [],
    };
  }

  // Return a single DataTable if there's a single sheet and it has not been
  // specified that the return type should be an array.
  if (!shouldReturnArray && spreadsheet.sheets.length === 1) {
    return getTrimmedDataTable(spreadsheet.sheets[0]);
  }

  return spreadsheet.sheets.map(sheet => getTrimmedDataTable(sheet));
}

/**
 * Check if all sheets of the parsed file have the right name,
 * based on the sheets configuration.
 */
export function checkHeadersMatchConfiguration(
  parsedSpreadsheet: Spreadsheet,
  configuration: SpreadsheetConfiguration,
) {
  const parsedSheets = parsedSpreadsheet.sheets;
  const { sheets: expectedSheets } = getDefaultSpreadsheet(configuration);

  const numOfSheetsMismatch = parsedSheets.length !== expectedSheets.length;
  if (numOfSheetsMismatch) {
    return false;
  }

  for (const sheetIndex in parsedSheets) {
    const { fields: parsedFields } = parsedSheets[sheetIndex].table.schema;
    const { fields: expectedFields } = expectedSheets[sheetIndex].table.schema;

    const numOfColumnsMismatch = parsedFields.length !== expectedFields.length;
    if (numOfColumnsMismatch) {
      return false;
    }

    for (const columnIndex in parsedFields) {
      const columnMatchesSchema =
        parsedFields[columnIndex].name === expectedFields[columnIndex].name;

      if (!columnMatchesSchema) {
        return false;
      }
    }
  }

  return true;
}

/** Fills the rows in every sheet with missing props. */
export function sanitizeSpreadsheet(
  parsedSpreadsheet: Spreadsheet,
  configuration: SpreadsheetConfiguration,
) {
  return produce(parsedSpreadsheet, draft => {
    const parsedSheets = draft.sheets;
    const { sheets: expectedSheets } = getDefaultSpreadsheet(configuration);
    for (const sheetIndex in parsedSheets) {
      const { data: parsedRows } = parsedSheets[sheetIndex].table;
      const { fields: expectedFields } = expectedSheets[sheetIndex].table.schema;

      fillRowsWithMissingProps(parsedRows, expectedFields);
    }
  });
}

/**
 * Each row object should have all of the expected columns. If not, fill these with
 * `{...[columnName]: null}`. Returns a Spreadsheet whose rows have all expected columns.
 */
function fillRowsWithMissingProps(parsedRows: Row[], allFields: TableColumn[]) {
  for (const rowIndex in parsedRows) {
    allFields.forEach(field => {
      const missingExpectedColumn = !Object.prototype.hasOwnProperty.call(
        parsedRows[rowIndex],
        field.name,
      );
      if (missingExpectedColumn) {
        parsedRows[rowIndex][field.name] = null;
      }
    });
  }
}

/** Check column name is present in the schema. */
export function isValidField(columnName: string, dataTable: DataTable) {
  const validFields = Object.values(dataTable.schema.fields);
  return validFields.some(validField => validField.name === columnName);
}

export function convertDataTableToSpreadsheet(
  dataTable: DataTable,
  sheetName: string = 'Sheet1',
): Spreadsheet {
  return {
    sheets: [{ name: sheetName, table: dataTable }],
  };
}

/**
 * Paste data to the currently selected cell.
 * Deals with overpasting by clipping the data (i.e. if
 * users try to paste 1 row with 10 cols in a table with 5 cols,
 * only the first 5 cols will be pasted).
 */
export function pasteDataToTable(
  dataTable: DataTable,
  pastedRows: CellValue[][],
  selectionStart: Position2d,
) {
  return produce(dataTable, draft => {
    const pastingMoreRowsThanAvailable =
      pastedRows.length > dataTable.data.length - selectionStart.y;
    if (pastingMoreRowsThanAvailable) {
      draft.data.push(...getRows(pastedRows.length, dataTable.schema.fields));
    }

    const numOfCellsToPaste = Math.min(
      dataTable.schema.fields.length,
      pastedRows[0].length,
    );
    pastedRows.forEach((row, index) => {
      const rowIndexToPasteTo = selectionStart.y + index;
      for (let cellIndex = 0; cellIndex < numOfCellsToPaste; cellIndex++) {
        const columnIndex = cellIndex + selectionStart.x;
        const columnName = getColumnNameByIndex(dataTable, columnIndex);
        const columnType = dataTable.schema.fields.find(
          field => field.name === columnName,
        )?.type;

        draft.data[rowIndexToPasteTo][columnName] = castValueToType(
          row[cellIndex],
          columnType ?? 'string',
        );
      }
    });
  });
}

/**
 * @param rawPastedRows This can be in two formats, based on whether
 * it was copied from an external software or copied from Table UI
 * `"col1\tcol2\nval1\tval2"` or
 * `[{colName1: row1, colName2: row1}, {colName1: row2, colName2: row2}]`
 */
export function parseRowsFromRawClipboardString(rawPastedRows: string): CellValue[][] {
  if (isJSON(rawPastedRows)) {
    const parsedRow: Row[] = JSON.parse(rawPastedRows);
    return parsedRow.map(row => Object.values(row));
  } else {
    // Rows were copied from an external software (e.g. Excel)
    return rawPastedRows
      .split('\n')
      .map(row => row.split('\t').map(value => value.trim()));
  }
}

/** Gets a map of empty undo/redo stacks indexed by sheet index */
export function initSpreadsheetEmptyStack(numOfSheets: number) {
  const emptyStack: { [sheetIndex: number]: [] } = {};
  for (let sheetIndex = 0; sheetIndex < numOfSheets; sheetIndex++) {
    emptyStack[sheetIndex] = [];
  }
  return emptyStack;
}

const PARSE_XLSX_ENDPOINT = '/web/parse-xlsx';

/** Parse a binary file into a structured Spreadsheet */
export async function parseFileToSpreadsheet(file: File, accessToken: string) {
  const { data }: { data: Spreadsheet } = await axios.post(PARSE_XLSX_ENDPOINT, file, {
    headers: {
      authorization: `bearer ${accessToken}`,
      'Content-Type': 'application/octet-stream',
    },
  });

  return data;
}

const DOWNLOAD_XLSX_ENDPOINT = '/web/download-xlsx';

export async function downloadSpreadsheet(
  spreadsheet: Spreadsheet,
  fileName: string,
  accessToken: string,
) {
  const { data: bufferData }: { data: ArrayBuffer } = await axios.post(
    DOWNLOAD_XLSX_ENDPOINT,
    spreadsheet,
    {
      headers: {
        authorization: `bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    },
  );

  const bytes = new Uint8Array(bufferData);
  const blob = new Blob([bytes], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  downloadBlob(blob, fileName);
}
