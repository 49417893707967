import React, { useCallback } from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import DeviceSettingOption from 'client/app/apps/workflow-builder/panels/DeviceSettingOption';
import Panel from 'client/app/apps/workflow-builder/panels/Panel';
import DeviceAdvancedOptionsList from 'client/app/apps/workflow-builder/panels/workflow-settings/DeviceAdvancedOptions';
import DeviceSelectorCard from 'client/app/apps/workflow-builder/panels/workflow-settings/devices/DeviceSelectorCard';
import LHPolicyUploadEditor from 'client/app/apps/workflow-builder/panels/workflow-settings/LHPolicyUploadEditor';
import SettingsPanelButton from 'client/app/apps/workflow-builder/panels/workflow-settings/SettingsPanelButton';
import SettingsPanelContainer from 'client/app/apps/workflow-builder/panels/workflow-settings/SettingsPanelContainer';
import { TipTypeSettingOption } from 'client/app/apps/workflow-builder/panels/workflow-settings/TipTypeSettingOption';
import WorkflowSettingsStateContextProvider, {
  useWorkflowSettingsState,
} from 'client/app/apps/workflow-builder/panels/workflow-settings/workflowSettingsState';
import { INPUTPLATES, TIPTYPES } from 'client/app/lib/workflow/workflowConfigProperties';
import { ScreenRegistry } from 'client/app/registry';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import doNothing from 'common/lib/doNothing';
import { ParameterValue, ParameterValueDict } from 'common/types/bundle';
import { DirectUploadSingleValueLegacy } from 'common/types/fileParameter';
import IconButtonWithPopper from 'common/ui/components/IconButtonWithPopper';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DeckOptionsIcon } from 'common/ui/icons/DeckOptionsIcon';
import { DeviceIcon } from 'common/ui/icons/Device';

type Props = {
  className: string;
  onClose: () => void;
};

export default function SettingsPanelWithState(props: Props) {
  return (
    <WorkflowSettingsStateContextProvider>
      <SettingsPanel {...props} />
    </WorkflowSettingsStateContextProvider>
  );
}

const SettingsPanel = React.memo(function SettingsPanel(props: Props) {
  const classes = useStyles();
  const { className, onClose } = props;
  const dispatch = useWorkflowBuilderDispatch();

  const {
    additionalPanel,
    hasDevicesSelected,
    hasManual,
    isDataOnlyConfig,
    isReadonly,
    requiresDevice,
    showDeckOptions,
    workflowConfig,
  } = useWorkflowSettingsState();

  // We edit the global mixer config directly for now, see comments on `GlobalMixerConfig`
  const paramValueDict: ParameterValueDict = workflowConfig.GlobalMixer || {};

  const onConfigParamChange = useCallback(
    (paramChanged: string, newValues: ParameterValue) => {
      logEvent('edit-config-parameter', ScreenRegistry.WORKFLOW, paramChanged);
      // We edit the global mixer config directly for now, see comments on `GlobalMixerConfig`
      const configWithUpdatedGlobalProps = {
        ...workflowConfig,
        GlobalMixer: {
          ...workflowConfig.GlobalMixer,
          [paramChanged]: newValues,
        },
      };

      // We divide the list into two in order to make a two column layout, so
      // it's important to merge the newly changed values (i.e. at most one half
      // of the total set of values) into the existing, full set of values.
      dispatch({ type: 'setConfig', payload: configWithUpdatedGlobalProps });
    },
    [dispatch, workflowConfig],
  );

  const onUploadedPolicyFileChange = useCallback(
    (policyFile: DirectUploadSingleValueLegacy | undefined) => {
      if (!policyFile) {
        // File "changed" but no file.
        // This happens when the user removes the policy file
        // using the little 'x' icon.
        // We take existing config without liguidHandlingPolicyXlsx{File, FileName}
        const {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          liquidHandlingPolicyXlsxJmpFile,
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          liquidHandlingPolicyXlsxJmpFileName,
          ...globalMixerMinusLiquidPolicyFile
        } = workflowConfig.GlobalMixer;
        dispatch({
          type: 'setConfig',
          payload: {
            ...workflowConfig,
            GlobalMixer: globalMixerMinusLiquidPolicyFile,
          },
        });
        return;
      }

      const pf = policyFile;
      if (!pf.bytes || !pf.name) {
        throw new Error('Unexpected value for policy upload');
      }
      const newGlobalMixerConfig = {
        ...workflowConfig.GlobalMixer,
        liquidHandlingPolicyXlsxJmpFile: pf.bytes.bytes,
        liquidHandlingPolicyXlsxJmpFileName: pf.name,
      };
      dispatch({
        type: 'setConfig',
        payload: {
          ...workflowConfig,
          GlobalMixer: newGlobalMixerConfig,
        },
      });
    },
    [dispatch, workflowConfig],
  );

  const handleToggleDeckOptionsPanel = useCallback(() => {
    dispatch({
      type: 'setAdditionalPanel',
      payload: additionalPanel === 'DeckOptions' ? undefined : 'DeckOptions',
    });
  }, [additionalPanel, dispatch]);

  const showDeviceSelectorCard = hasDevicesSelected || isDataOnlyConfig;

  return (
    <Panel
      className={className}
      title="Workflow Settings"
      onClose={onClose}
      panelContent="WorkflowSettings"
    >
      <SettingsPanelContainer
        title="Execution Mode"
        helpContent="Set the workflow to be run manually, via a device, or as a data workflow. This will determine the instructions type and deck settings."
      >
        {showDeviceSelectorCard ? (
          <>
            <DeviceSelectorCard />
            {/* TODO: Update for scenario where the device or run config is missing*/}
            {showDeckOptions && (
              <div className={classes.deckOptions}>
                <Divider className={classes.divider} />
                <div className={classes.header}>
                  <Typography variant="overline" color="textPrimary">
                    Deck Options
                  </Typography>
                  <IconButtonWithPopper
                    content={
                      <Typography variant="caption">
                        Select a deck layout, then tell Synthace where you want it to
                        allocate your labware. To learn more, click{' '}
                        <Link
                          href="https://intercom.help/antha/en/articles/5588117-deck-options"
                          target="_blank"
                          variant="caption"
                          rel="noopenner noreferrer"
                          underline="hover"
                        >
                          here
                        </Link>
                        .
                      </Typography>
                    }
                    iconButtonProps={{
                      size: 'xsmall',
                      icon: <HelpOutlineIcon />,
                    }}
                    onClick={doNothing} //TODO: Update with logging
                  />
                </div>
                <SettingsPanelButton
                  icon={<DeckOptionsIcon />}
                  onClick={handleToggleDeckOptionsPanel}
                  fullWidth
                  selected={additionalPanel === 'DeckOptions'}
                >
                  Deck Options
                </SettingsPanelButton>
              </div>
            )}
          </>
        ) : (
          <EmptyDeviceSelection />
        )}
      </SettingsPanelContainer>
      {requiresDevice && (
        <>
          <DeviceSettingOption
            isDisabled={isReadonly}
            parameter={INPUTPLATES}
            value={paramValueDict[INPUTPLATES.name]}
            onChange={onConfigParamChange}
            helpContent="If you did not prepare your input plates in advance, select the plate types that you want to use for your input plates."
            additionalHelpLinkUrl="https://intercom.help/antha/en/articles/5409800-select-the-plate-types-that-you-want-to-use-for-your-input-plates"
          />
          {!hasManual && (
            <>
              <TipTypeSettingOption
                value={paramValueDict[TIPTYPES.name]}
                onChange={onConfigParamChange}
              />
              <SettingsPanelContainer
                title="Custom Liquid Policies"
                helpContent="If the liquid policies in Synthace do not transfer a liquid in your workflow the way that you want, create a custom liquid policy instead."
                additionalHelpLinkUrl="https://intercom.help/antha/en/articles/5451980-custom-liquid-policies"
              >
                <LHPolicyUploadEditor
                  isDisabled={isReadonly}
                  fileName={
                    workflowConfig.GlobalMixer?.liquidHandlingPolicyXlsxJmpFileName
                  }
                  file={workflowConfig.GlobalMixer?.liquidHandlingPolicyXlsxJmpFile}
                  onChange={onUploadedPolicyFileChange}
                />
              </SettingsPanelContainer>
            </>
          )}
          <DeviceAdvancedOptionsList
            parameterValueDict={paramValueDict}
            onChange={onConfigParamChange}
            isDisabled={isReadonly}
          />
        </>
      )}
    </Panel>
  );
});

function EmptyDeviceSelection() {
  const dispatch = useWorkflowBuilderDispatch();

  const additionalPanel = useWorkflowBuilderSelector(state => state.additionalPanel);
  const handleToggleDeviceSelectorPanel = useCallback(() => {
    dispatch({
      type: 'setAdditionalPanel',
      payload: additionalPanel === 'DeviceSelector' ? undefined : 'DeviceSelector',
    });
  }, [dispatch, additionalPanel]);

  return (
    <SettingsPanelButton
      icon={<DeviceIcon />}
      onClick={handleToggleDeviceSelectorPanel}
      fullWidth
      selected={additionalPanel === 'DeviceSelector'}
    >
      Select Execution Mode
    </SettingsPanelButton>
  );
}

const useStyles = makeStylesHook(theme => ({
  deckOptions: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
}));
