/**
 * Utility functions for chromatography
 *
 * TODO: These conversions exist in core antha, so we should investigate using
 * those in future.
 */
import omit from 'lodash/omit';

import { parseMeasurement } from 'common/lib/format';
import { Connection, ServerSideBundle } from 'common/types/bundle';
import { FilterMatrix, Measurement } from 'common/types/mix';
import {
  BasicChromatographyAction,
  GradientChromatographyAction,
  RoboColumnContent,
} from 'common/types/robocolumns';

/**
 * Radius of robocolumns in CM. Repligen report that Robocolumns are 0.25cm
 * radius, but we've found the calculations are more accurate with 0.2524cm
 *
 * The Tecan liquid class with the highest flow rate is
 * `Flowrate_1000cm/h_55.6ul/s`, i.e. a flow velocity of 1000cm/h and flow rate
 * of 55.6ul/s. We can deduce the robocolumn radius as follows:
 *
 * Radius[cm] = Sqrt(FlowRate[ul/s] * 3600 / 1000 / Pi / FlowVelocity[cm/h])
 *            = Sqrt(55.6 * 3600 / 1000 / Pi / 1000)
 *            = 0.2524cm
 */
const robocolumnRadius = 0.2524;
const robocolumnCrossSectionArea = Math.PI * robocolumnRadius ** 2;

/**
 * Convert a residence time to a volumetric flow rate (volume per time), based
 * on the volume of a column. This assumes that flux in = flux out, i.e.,
 * equilibrium conditions in the column.
 *
 * Flow Rate = Column Volume / Residence Time
 */
export function residenceTimeToFlowRate(
  residenceTime: string = '',
  colVolume: string = '',
): string | undefined {
  const residenceTimeAmt = parseMeasurement(residenceTime);
  const volumeAmt = parseMeasurement(colVolume);
  if (!residenceTimeAmt || !volumeAmt) {
    return;
  }
  // convert minutes to seconds
  if (residenceTimeAmt.unit === 'min') {
    residenceTimeAmt.value *= 60;
    residenceTimeAmt.unit = 's';
  }
  if (residenceTimeAmt.unit !== 's') {
    return;
  }
  return `${volumeAmt.value / residenceTimeAmt.value} ${volumeAmt.unit}/s`;
}

/**
 * Convert a volumetric flow rate (volume per time) to a residence time, based
 * on the volume of a column. This assumes that flux in = flux out, i.e.,
 * equilibrium conditions in the column.
 */
export function flowRateToResidenceTime(
  flowRate: string = '',
  colVolume: string = '',
): string | undefined {
  const flowRateAmt = parseMeasurement(flowRate);
  const volumeAmt = parseMeasurement(colVolume);
  if (!flowRateAmt || !volumeAmt) {
    return;
  }
  const [volumeUnit, timeUnit] = flowRateAmt.unit.split('/');
  // If the units of flow rate and volume are not compatible then return
  // undefined.
  if (timeUnit !== 's' || volumeUnit !== volumeAmt.unit) {
    return;
  }
  return `${volumeAmt.value / flowRateAmt.value} s`;
}

/**
 * Convert residence time (s) to flow velocity (cm/h)
 */
export function residenceTimeToFlowVelocity(
  residenceTime: string = '',
  colVolume: string = '',
) {
  const flowRate = residenceTimeToFlowRate(residenceTime, colVolume);
  const flowRateAmt = parseMeasurement(flowRate || '');
  if (flowRateAmt?.unit.toLowerCase() !== 'ul/s') {
    return;
  }
  const flowRateUlPerH = flowRateAmt.value * 3600; // convert to ul/h
  const flowRateCM3PerH = flowRateUlPerH / 1000; // convert to cm3/h
  return `${flowRateCM3PerH / robocolumnCrossSectionArea} cm/h`; // convert to cm/h
}

/**
 * Convert flow velocity (cm/h) to residence time (s).
 */
export function flowVelocityToResidenceTime(
  flowVelocity: string = '',
  colVolume: string = '',
) {
  const flowVelocityAmt = parseMeasurement(flowVelocity);
  if (flowVelocityAmt?.unit.toLowerCase() !== 'cm/h') {
    return;
  }
  const flowVelocityCMPerS = flowVelocityAmt.value / 3600; // convert to cm/s
  const flowRateCM3PerS = flowVelocityCMPerS * robocolumnCrossSectionArea; // convert to cm3/s
  const flowRate = `${flowRateCM3PerS * 1000} ul/s`; // convert to ul/s
  return flowRateToResidenceTime(flowRate, colVolume);
}

/**
 * Takes a volume (ul, ml, etc) to be loaded into a robocolumn, and divides it
 * by the robocolumn volume. The unit is CV (column volume).
 */
export function toColumnVolumes(
  loadVolume: string,
  roboColumnVolume: string,
): string | undefined {
  const loadVolumeAmt = parseMeasurement(loadVolume);
  const roboColumnVolumeAmt = parseMeasurement(roboColumnVolume);
  if (!roboColumnVolumeAmt || roboColumnVolumeAmt.unit !== loadVolumeAmt?.unit) {
    return;
  }
  return `${loadVolumeAmt.value / roboColumnVolumeAmt.value} CV`;
}

/**
 * Takes a volume in CV (column volume) and converts to ul (or whichever unit is
 * used for the robocolumn volume).
 */
export function fromColumnVolumes(
  loadVolume: string,
  roboColumnVolume: string,
): string | undefined {
  const loadVolumeAmt = parseMeasurement(loadVolume);
  const roboColumnVolumeAmt = parseMeasurement(roboColumnVolume);
  if (!roboColumnVolumeAmt || loadVolumeAmt?.unit !== 'CV') {
    return;
  }
  return `${loadVolumeAmt.value * roboColumnVolumeAmt.value} ${roboColumnVolumeAmt.unit}`;
}

/**
 * Calculate the total volume to be dispensed during a chromatography actions.
 *
 * Total Volume (ul) = Fraction Volume (ul or CV) * Fraction Count * Resin Volume (ul)
 */
export function fractionVolumeToTotalVolume(
  loadVolume: string,
  fractions: number,
  robocolumnVolume: string,
): string | undefined {
  const loadVolumeAmt = parseMeasurement(loadVolume || '');
  const volumePerFraction =
    loadVolumeAmt?.unit === 'CV'
      ? parseMeasurement(fromColumnVolumes(loadVolume, robocolumnVolume) || '')
      : loadVolumeAmt;
  if (!volumePerFraction || !fractions) {
    return;
  }
  return `${volumePerFraction.value * fractions} ${volumePerFraction.unit}`;
}

/**
 * Calculate the load volume per fraction given the total load volume in ul or CV.
 *
 * Load Volume Per Fraction (CV) = Total Load Volume (CV) / Fraction Count
 *
 * If Total Load Volume is provided in ul, then:
 *  Total Load Volume (CV) = Total Load Volume (ul) / RoboColumn Volume (ul)
 */
export function totalVolumeToFractionVolume(
  totalLoadVolume: string,
  fractions: number,
  robocolumnVolume?: string, // can be undefined if totalLoadVolume is in CV
): string | undefined {
  const totalLoadVolumeAmt = parseMeasurement(totalLoadVolume);

  let totalVolumeInCV: Measurement | undefined;
  if (totalLoadVolumeAmt?.unit === 'CV') {
    totalVolumeInCV = totalLoadVolumeAmt;
  } else if (robocolumnVolume) {
    totalVolumeInCV = parseMeasurement(
      toColumnVolumes(totalLoadVolume, robocolumnVolume) || '',
    );
  }

  if (!totalVolumeInCV || !fractions) {
    return;
  }
  return `${totalVolumeInCV.value / fractions} CV`;
}

/**
 * Given a volume to load (in terms of load per fraction, fraction count, and robocolumn
 * volume) and concentration of the given liquid, calculate the total mass of the liquid
 * to be loaded.
 */
export function fractionVolumeToTotalMass(
  loadVolume: string,
  fractions: number,
  robocolumnVolume: string,
  liquidConcentration: string,
): string | undefined {
  const totalLoadVolumeAmt = parseMeasurement(
    normalizeVolume(
      fractionVolumeToTotalVolume(loadVolume, fractions, robocolumnVolume) ?? '',
    ) ?? '',
  );
  const concentration = parseMeasurement(
    normalizeConcentration(liquidConcentration) ?? '',
  );

  if (!totalLoadVolumeAmt || !concentration) {
    return;
  }

  return `${totalLoadVolumeAmt.value * concentration.value} mg`;
}

/**
 * Concentration must be in mg/ml or ug/ml. Volume = Mass / Concentration
 */
export function totalMassToFractionVolume(
  totalMass: string,
  fractions: number,
  liquidConcentration: string,
): string | undefined {
  const totalMassAmt = parseMeasurement(totalMass);
  const concentration = parseMeasurement(
    normalizeConcentration(liquidConcentration) ?? '',
  );

  if (totalMassAmt?.unit !== 'mg' || !concentration || !fractions) {
    return;
  }

  return `${((totalMassAmt.value / concentration.value) * 1000) / fractions} ul`;
}

/**
 * Return a concentration as mg/ml
 */
export function normalizeConcentration(concentration: string): string | undefined {
  const concAmt = parseMeasurement(concentration);
  switch (concAmt?.unit) {
    case 'mg/ml':
    case 'ug/ul':
      return `${concAmt.value} mg/ml`;
    case 'mg/ul':
      return `${concAmt.value * 1000} mg/ml`;
    case 'ug/ml':
      return `${concAmt.value / 1000} mg/ml`;
    default:
      return;
  }
}

/**
 * Return a volume normalized to ml. The input string must contain a volume in ul or ml.
 */
export function normalizeVolume(volume: string): string | undefined {
  const volumeAmt = parseMeasurement(volume);
  switch (volumeAmt?.unit) {
    case 'ul':
      return `${volumeAmt.value / 1000} ml`;
    case 'ml':
      return volume;
    default:
      return;
  }
}

/**
 * Get the most common element in an array. If there's a tie, return the
 * earliest of the most common element.
 */
function mode<T>(array: T[]): T | undefined {
  const counts = new Map<T, number>();
  return array.reduce((currentMode: T | undefined, el) => {
    const count = (counts.get(el) || 0) + 1;
    counts.set(el, count);
    return !currentMode || count > counts.get(currentMode)! ? el : currentMode;
  });
}
/**
 * The user might have set up the Run_Chromatography_Stage element using the
 * Chromatography UI parameter, which can be used instead of several separate
 * map parameters. The UI parameter type is a complex object which the DOE
 * Template Editor cannot handle. So we separate the UI parameter into the map
 * parameters.
 *
 * This is experimental, so for now we'll just implement this on appserver.
 * However, this is brittle; if the element names or parameters change then this
 * code will break.
 *
 * This will be deprecated by the upcoming Plate Contents Editor
 */
export function flattenChromatographyParameters(
  workflow: ServerSideBundle,
): ServerSideBundle {
  const elements = {
    ...workflow.Elements,
    Instances: { ...workflow.Elements.Instances },
  };

  // For each Run_Chromatography_Stage, remove the UI parameter value and fill
  // in the simpler parameters.
  for (const [instanceName, instance] of Object.entries(elements.Instances)) {
    if (instance.TypeName === 'Run_Chromatography_Stage') {
      // Get the chromatography actions defined by the UI. These are keyed by
      // well location, but we only need the actions themselves.
      const actions: BasicChromatographyAction[] = Object.values(
        instance.Parameters.LoadSettingsForEachRoboColumn || {},
      );

      // Didn't use UI, so skip this element instance
      if (actions.length === 0) {
        continue;
      }

      // Just pick the most commonly used value for each parameter. These will
      // be used in the 'default' key of the map parameters.
      const liquidToLoad = mode(actions.map(action => action.LiquidToLoad));
      const residenceTime = mode(actions.map(action => action.ResidenceTime));
      const loadVolume = mode(actions.map(action => action.LoadVolumes?.[0]));
      const noOfFractions = mode(actions.map(action => action.LoadVolumes?.length));
      const aspPolicy = mode(
        actions.map(action => action.AspirationLiquidPolicyOverride),
      );

      elements.Instances[instanceName] = {
        ...instance,
        Parameters: {
          // Copy across all parameters except the UI parameter
          ...omit(instance.Parameters, ['LoadSettingsForEachRoboColumn']),

          // The AdvancedInputs checkbox signals that the UI should show maps rather than
          // Edit Contents button.
          AdvancedInputs: true,

          NumberOfFractions:
            noOfFractions !== undefined ? { default: noOfFractions } : undefined,
          // For each per-robocolumn parameter that the user defined in the UI,
          // set the equivalent default map key
          LoadVolumePerFraction: loadVolume && { default: loadVolume },
          LiquidsToLoad: liquidToLoad && { default: liquidToLoad },
          AspirationLiquidPolicyOverrides: aspPolicy && { default: aspPolicy },
          FlowRatesAndResidenceTimes: residenceTime && {
            default: residenceTime,
          },
        },
      };
    }
  }
  return { ...workflow, Elements: elements };
}

/**
 * Gradient chromatography equivalent of flattenChromatographyParameters. This will be
 * deprecated by the upcoming Plate Contents Editor
 *
 * @see flattenChromatographyParameters()
 */
export function flattenGradientChromatographyParameters(
  workflow: ServerSideBundle,
): ServerSideBundle {
  const elements = {
    ...workflow.Elements,
    Instances: { ...workflow.Elements.Instances },
  };

  // For each Run_Chromatography_Stage, remove the UI parameter value and fill
  // in the simpler parameters.
  for (const [instanceName, instance] of Object.entries(elements.Instances)) {
    if (instance.TypeName === 'Run_Gradient_Chromatography_Stage') {
      // Get the chromatography actions defined by the UI. These are keyed by
      // well location, but we only need the actions themselves.
      const actions: GradientChromatographyAction[] = Object.values(
        instance.Parameters.LoadSettingsForEachRoboColumn || {},
      );

      // Didn't use UI, so skip this element instance
      if (actions.length === 0) {
        continue;
      }

      // Just pick the most commonly used value for each parameter. These will
      // be used in the 'default' key of the map parameters.
      const bufferA = mode(actions.map(action => action.BufferA));
      const bufferB = mode(actions.map(action => action.BufferB));
      const bufferBStartPercent = mode(actions.map(action => action.BStartPercentage));
      const bufferBEndPercent = mode(actions.map(action => action.BEndPercentage));
      const residenceTime = mode(actions.map(action => action.ResidenceTime));
      const loadVolume = mode(actions.map(action => action.LoadVolumes?.[0]));
      const noOfFractions = mode(actions.map(action => action.LoadVolumes?.length));
      const aspPolicy = mode(
        actions.map(action => action.AspirationLiquidPolicyOverride),
      );

      elements.Instances[instanceName] = {
        ...instance,
        Parameters: {
          // Copy across all parameters except the UI parameter
          ...omit(instance.Parameters, ['LoadSettingsForEachRoboColumn']),

          // The AdvancedInputs checkbox signals that the UI should show maps rather than
          // Edit Contents button.
          AdvancedInputs: true,

          // For each per-robocolumn parameter that the user defined in the UI,
          // set the equivalent default map key
          NumberOfFractions:
            noOfFractions !== undefined ? { default: noOfFractions } : undefined,
          BufferAForGradient: bufferA !== undefined ? { default: bufferA } : undefined,
          BufferBForGradient: bufferB !== undefined ? { default: bufferB } : undefined,
          BufferBStartPercentages:
            bufferBStartPercent !== undefined
              ? { default: bufferBStartPercent }
              : undefined,
          BufferBEndPercentages:
            bufferBEndPercent !== undefined ? { default: bufferBEndPercent } : undefined,
          AspirationLiquidPolicyOverrides:
            aspPolicy !== undefined ? { default: aspPolicy } : undefined,
          LoadVolumePerFraction:
            loadVolume !== undefined ? { default: loadVolume } : undefined,
          FlowRatesAndResidenceTimes:
            residenceTime !== undefined ? { default: residenceTime } : undefined,
        },
      };
    }
  }
  return { ...workflow, Elements: elements };
}

/**
 * Like `flattenChromatographyParameters`, the user might have set up the
 * Define_RoboColumn_Plate element using the RoboColumn Layout UI parameter,
 * which can be used instead of several separate map parameters. The UI
 * parameter type is a complex object which the DOE Template Editor cannot
 * handle. So we separate the UI parameter into the map parameters.
 *
 * This is experimental, so for now we'll just implement this on appserver.
 * However, this is brittle; if the element names or parameters change then this
 * code will break.
 *
 * This will be deprecated by the upcoming Plate Contents Editor
 */
export function flattenRoboColumnPlateParameters(
  workflow: ServerSideBundle,
): ServerSideBundle {
  const elements = {
    ...workflow.Elements,
    Instances: { ...workflow.Elements.Instances },
  };

  // For each Define_RoboColumn_Plate, remove the UI parameter value and fill
  // in the simpler parameters.
  for (const [instanceName, instance] of Object.entries(elements.Instances)) {
    if (instance.TypeName === 'Define_RoboColumn_Plate') {
      // Get the robocolumn layout defined by the UI. These are indexed by well
      // location.
      const roboColumnsByWell: Record<string, RoboColumnContent> | undefined =
        instance.Parameters.ResinsAndLocations;

      // Didn't use UI, so skip this element instance
      if (!roboColumnsByWell) {
        continue;
      }

      const robocolumns: RoboColumnContent[] = Object.values(roboColumnsByWell);

      // Just pick the most commonly used value for each parameter. These will
      // be used in the 'default' key of the map parameters.
      const resin = mode(robocolumns.map(robocolumn => robocolumn.Resin));
      const volume = mode(robocolumns.map(robocolumn => robocolumn.Volume));
      // The UI allows the user to specify the location of each robocolumn. We
      // convert this to preferred locations.
      const preferredLocations = Object.keys(roboColumnsByWell);

      elements.Instances[instanceName] = {
        ...instance,
        Parameters: {
          // Copy across all parameters except the UI parameter
          ...omit(instance.Parameters, ['ResinsAndLocations']),

          // The AdvancedInputs checkbox signals that the UI should show maps rather than
          // Edit Contents button.
          AdvancedInputs: true,

          ResinTypeInEachRoboColumn: resin && { default: resin },
          ResinVolumeInEachRoboColumn: volume && { default: volume },
          PreferredArrayLocations: preferredLocations,
        },
      };
    }
  }
  return { ...workflow, Elements: elements };
}

/**
 * A common DOE use case is to generate liquids using the Mix_Set element for
 * loading during chromatography. In this case, two connections must be made
 * between Mix_Set and Run_Chromatography_Stage:
 * - the list of available liquids
 * - the map of run name (determined by DOE) to liquid
 *
 * Both are essential of DOE. However, the latter pertains only to DOE
 * (determining which liquid to use in which run) and a workflow can be
 * successfully simulated without it. For that reason, when creating a DOE
 * template from a successful simulation, we need create the latter connection
 * if it doesn't exist.
 *
 * Related issue: If the user forgets to check Rename Mixtures for DOE, and they
 * have multiple Mix Set elements, then liquids will end up sharing the same
 * name, making it difficult to see what's going on in the preview (and perhaps
 * breaking the simulation). Therefore, we could ensure the checkbox is checked
 * when creating the DOE template.
 *
 * This function is only required while we experiment with the DOE UI and should
 * be replaced.
 */
export function connectChromatographyMixSetElements(
  workflow: ServerSideBundle,
): ServerSideBundle {
  const instances = { ...workflow.Elements.Instances };

  // Connections which link list of liquids
  const listConns = workflow.Elements.InstancesConnections.filter(
    ({ Source, Target }) =>
      instances[Source.ElementInstance].TypeName === 'Mix_Set' &&
      instances[Target.ElementInstance].TypeName === 'Run_Chromatography_Stage' &&
      Source.ParameterName === 'Mixtures' &&
      Target.ParameterName === 'AvailableLiquidsToLoad',
  );

  // Connections which link map of run name to liquid
  const mapConns = workflow.Elements.InstancesConnections.filter(
    ({ Source, Target }) =>
      instances[Source.ElementInstance].TypeName === 'Mix_Set' &&
      instances[Target.ElementInstance].TypeName === 'Run_Chromatography_Stage' &&
      Source.ParameterName === 'RunToMixtureNameForDOE' &&
      Target.ParameterName === 'LiquidsToLoad',
  );

  // Create missing map connections
  const newConns: Connection[] = listConns
    .filter(
      listConn =>
        // Exclude those which are already have the map param connected
        !mapConns.some(
          mapConn =>
            mapConn.Source.ElementInstance === listConn.Source.ElementInstance &&
            mapConn.Target.ElementInstance === listConn.Target.ElementInstance,
        ),
    )
    .map(({ Source, Target }) => ({
      Source: {
        ElementInstance: Source.ElementInstance,
        ParameterName: 'RunToMixtureNameForDOE',
      },
      Target: {
        ElementInstance: Target.ElementInstance,
        ParameterName: 'LiquidsToLoad',
      },
    }));

  // Update the parameters
  listConns.forEach(({ Source, Target }) => {
    // The Rename Mixtures for DOE checkbox in Mix Set causes all output
    // mixtures to be suffixed with the name of the element instance:
    instances[Source.ElementInstance] = {
      ...instances[Source.ElementInstance],
      Parameters: {
        ...instances[Source.ElementInstance].Parameters,
        RenameMixturesForDOE: true,
      },
    };
    // Now that we're linking in LiquidsToLoad, do not copy across the
    // existing value.
    instances[Target.ElementInstance] = {
      ...instances[Target.ElementInstance],
      Parameters: {
        ...instances[Target.ElementInstance].Parameters,
        LiquidsToLoad: null,
      },
    };
  });

  return {
    ...workflow,
    Elements: {
      Instances: instances,
      InstancesConnections: [...workflow.Elements.InstancesConnections, ...newConns],
    },
  };
}

/**
 * A RoboColumn (well with kind = "filter_matrix_summary") will always have a Resin Type
 * tag.
 * TODO: Change to roboColumn.resinName when T4141 is solved
 */
export function getResinName(roboColumnContents: FilterMatrix): string {
  return (
    roboColumnContents.tags?.find(tag => tag.label === 'Resin Type')?.value_string || ''
  );
}

export const ROBOCOLUMN_LOCATIONS_TYPE =
  'map[github.com/Synthace/antha/stdlib/schemas/aliases.RoboColumnName]github.com/Synthace/antha/stdlib/platepreferences.WellLocations';

/**
 * Generate a list of unused RoboColumn names of the form "RoboColumn 1", "RoboColumn 2",
 * etc.
 */
export function generateRoboColumnName(existingNames: Set<string>): string {
  let roboColumnNumber = 0;
  let name = '';
  do {
    roboColumnNumber++;
    name = `RoboColumn ${roboColumnNumber}`;
  } while (existingNames.has(name));
  return name;
}
