import React, { useCallback, useContext } from 'react';

import { useMutation } from '@apollo/client';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KebabIcon from '@mui/icons-material/MoreVertOutlined';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';

import { MUTATION_SHARE_WORKFLOW_WITH_ORG } from 'client/app/api/gql/mutations';
import { useDeleteSimulation } from 'client/app/api/SimulationsApi';
import RenameSimulationDialog from 'client/app/apps/experiments/Workflows/RenameSimulationDialog';
import ShareWithOrganisationsDialog from 'client/app/apps/workflow-builder/dialogs/ShareWithOrganisationsDialog';
import {
  FINAL_SIMULATION_STATUSES,
  isSimulationPlaceholder,
  SimulationForWorkflow,
  SimulationPlaceholder,
} from 'client/app/apps/workflow-builder/panels/simulations/useSimulations';
import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import useRequestHelpForSimulation from 'client/app/hooks/useRequestHelpForSimulation';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { workflowRoutes } from 'client/app/lib/nav/actions';
import sanitizeLink from 'client/app/lib/sanitizeLink';
import { useSimulationClient } from 'client/app/lib/SimulationClient';
import { ScreenRegistry } from 'client/app/registry';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import IconButton from 'common/ui/components/IconButton';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import { SplunkIcon } from 'common/ui/icons/SplunkIcon';
import { TalkToUsIcon } from 'common/ui/icons/TalkToUs';
import { TraceIcon } from 'common/ui/icons/TraceIcon';
import { WorkflowIcon } from 'common/ui/icons/Workflow';

type MoreActionMenuProps = {
  simulation: SimulationForWorkflow;
  menuAnchorEl: HTMLElement | null;
  handleClose: () => void;
  workflowId: WorkflowId;
};

function MoreActions({
  simulation,
  workflowId,
}: {
  simulation: SimulationForWorkflow | SimulationPlaceholder;
  workflowId: WorkflowId;
}) {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenuItem = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);
  const handleClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  if (isSimulationPlaceholder(simulation)) {
    return <IconButton size="small" icon={<KebabIcon />} disabled title="More Actions" />;
  }
  return (
    <>
      <IconButton
        size="small"
        icon={<KebabIcon />}
        onClick={handleMenuItem}
        title="More Actions"
      />
      <MoreActionsMenu
        simulation={simulation}
        menuAnchorEl={menuAnchorEl}
        handleClose={handleClose}
        workflowId={workflowId}
      />
    </>
  );
}

function MoreActionsMenu(props: MoreActionMenuProps) {
  const { simulation, menuAnchorEl, handleClose, workflowId } = props;
  const classes = useStyles();
  const [confirmDeletionDialog, openConfirmDeletionDialog] =
    useDialog(ConfirmationDialog);
  const snackbar = useSnackbarManager();
  const { screenId } = useContext(ScreenContext);
  const { requestHelpDialog, handleRequestHelp } = useRequestHelpForSimulation(
    screenId!, // We should always have a screenId when we are rendering simulation card.
    simulation.id,
    simulation.name,
  );
  const closeMenuAndRequestHelp = useCallback(() => {
    logEvent('request-help', ScreenRegistry.WORKFLOW);
    handleClose();
    void handleRequestHelp();
  }, [handleClose, handleRequestHelp]);

  const logViewSnapshot = useCallback(() => {
    // Currently we only log when the user left-clicks but they could right-click
    // and open in a new tab.
    logEvent('click-view-snapshot', ScreenRegistry.WORKFLOW);
  }, []);

  const snapshotPath = workflowRoutes.openInWorkflowBuilder.getPath({
    workflowId: simulation.workflow.id,
  });

  // Only show request help if the simulation is in a terminal state.
  const showRequestHelp = simulation.status !== 'RUNNING' && handleRequestHelp;
  const userProfile = useUserProfile();
  const isSupport = userProfile?.isSupport ?? false;

  const [shareWorkflowDialog, openShareWorkflowDialog] = useDialog(
    ShareWithOrganisationsDialog,
  );
  const [shareWorkflow] = useMutation(MUTATION_SHARE_WORKFLOW_WITH_ORG);
  const handleShareWorkflow = useCallback(async () => {
    handleClose();
    if (simulation.status !== 'COMPLETED') {
      return;
    }
    const orgsToShareWith = await openShareWorkflowDialog({
      workflowId: simulation.workflow.id,
    });
    if (orgsToShareWith) {
      const { errors } = await shareWorkflow({
        variables: {
          simulationId: simulation.id,
          destOrgName: orgsToShareWith.humanIdentifier,
          message: 'shared from Synthace',
        },
        // @ts-ignore This is valid option. TODO update types def when fix is available
        errorPolicy: 'all',
      });
      if (errors) {
        snackbar.showError(`Failed to share with ${orgsToShareWith.name}`);
      } else {
        snackbar.showSuccess(`Shared with ${orgsToShareWith.name}`);
      }
    }
  }, [handleClose, openShareWorkflowDialog, shareWorkflow, simulation, snackbar]);

  const simulationService = useSimulationClient();

  const [renameSimulationDialog, openRenameSimulationDialog] =
    useDialog(RenameSimulationDialog);

  const handleRenameSimulation = useCallback(async () => {
    handleClose();
    const updatedSimulationName = await openRenameSimulationDialog({
      screenRegistry: ScreenRegistry.WORKFLOW,
      simulationName: simulation.name,
    });
    if (updatedSimulationName && updatedSimulationName !== simulation.name) {
      logEvent('rename-simulation', ScreenRegistry.WORKFLOW, 'new-name');
      void simulationService.updateSimulation(simulation.id, updatedSimulationName);
    } else {
      logEvent('rename-simulation', ScreenRegistry.WORKFLOW, 'no-change');
    }
  }, [
    handleClose,
    openRenameSimulationDialog,
    simulation.id,
    simulation.name,
    simulationService,
  ]);

  const deleteSimulation = useDeleteSimulation();

  const handleDeleteSimulation = useCallback(async () => {
    handleClose();
    // We only want to show the confirmation dialog on successful simulations.
    const shouldConfirmDelete = simulation.status === 'COMPLETED';
    if (shouldConfirmDelete) {
      const isDeleteConfirmed = await openConfirmDeletionDialog({
        action: 'delete',
        isActionDestructive: true,
        object: 'simulation',
        specificObject: `${simulation.name}`,
      });
      if (!isDeleteConfirmed) {
        return;
      }
    }
    logEvent('delete-simulation', ScreenRegistry.WORKFLOW);
    // Note: This workflowId is different from the simulation's since that one is the
    // snapshot workflow id. We need the current workflow id.
    await deleteSimulation(simulation.id, workflowId);
  }, [
    deleteSimulation,
    handleClose,
    openConfirmDeletionDialog,
    simulation.id,
    simulation.name,
    simulation.status,
    workflowId,
  ]);

  // Only delete a simulation if it does not have an execution, is in a final state, and has not been shared.
  const canDelete =
    FINAL_SIMULATION_STATUSES.includes(simulation.status) &&
    !simulation.execution?.id &&
    !simulation.isSharedExternally;

  const synthaceEmployeeOnlyItems = userProfile?.isSynthaceEmployee
    ? [
        <Divider key="divider" className={classes.divider} />,
        isSupport && (
          <MenuItemWithIcon
            key="send-workflow"
            icon={<SendRoundedIcon />}
            onClick={handleShareWorkflow}
            text="Send workflow"
          />
        ),
        simulation.traceUrl && (
          <a
            key="trace"
            className={classes.menuItemLink}
            target="_blank"
            rel="noopener noreferrer"
            href={sanitizeLink(simulation.traceUrl)}
          >
            <MenuItemWithIcon icon={<TraceIcon />} onClick={handleClose} text="Trace" />
          </a>
        ),
        simulation.logsUrl && (
          <a
            key="logs"
            className={classes.menuItemLink}
            target="_blank"
            rel="noopener noreferrer"
            href={sanitizeLink(simulation.logsUrl)}
          >
            <MenuItemWithIcon icon={<SplunkIcon />} onClick={handleClose} text="Logs" />
          </a>
        ),
      ]
    : null;

  return (
    <>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={!!menuAnchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Link
          className={classes.menuItemLink}
          to={snapshotPath}
          onMouseDown={logViewSnapshot}
        >
          <MenuItemWithIcon icon={<WorkflowIcon />} text="View Snapshot" />
        </Link>
        {showRequestHelp && !isSupport && (
          <MenuItemWithIcon
            onClick={closeMenuAndRequestHelp}
            icon={<TalkToUsIcon />}
            text="Request Help"
          />
        )}
        <Tooltip title={!canDelete ? 'Simulation Shared' : ''}>
          <div>
            <MenuItemWithIcon
              onClick={handleDeleteSimulation}
              icon={<DeleteIcon />}
              text="Delete Simulation"
              disabled={!canDelete}
            />
          </div>
        </Tooltip>

        <MenuItemWithIcon
          onClick={handleRenameSimulation}
          icon={<EditOutlinedIcon />}
          text="Rename Simulation"
        />
        {synthaceEmployeeOnlyItems}
      </Menu>
      {confirmDeletionDialog}
      {requestHelpDialog}
      {shareWorkflowDialog}
      {renameSimulationDialog}
    </>
  );
}

const useStyles = makeStylesHook(({ spacing }) => ({
  menuItemLink: {
    color: 'inherit',
    textDecoration: 'none',
  },
  divider: {
    margin: spacing(3, 0),
  },
}));

export default MoreActions;
