export const getMeasurementUnits = (measurementType?: string) => {
  const units = measurementType ? unitRegistry[measurementType] : [];
  return units;
};

/**
 *  getMeasurementFromString will attempt to split out the given text into a numerical
 *  value and a unit from the unitRegistry. An example of an input is: '200.0m' or '-37.5°C'.
 *  measurementType can be specified to indicate which subset from the unitRegistry to match to.
 *  additionalUnits can be specified to add on any custom units to check the text against. */
export default function getMeasurementFromString(
  text: string,
  measurementType?: string,
  additionalUnits?: string[],
) {
  /**
   *  parseFloat should allow us to grab only the number at the beginning
   *  of the string and automatically drop suffixed text. This is exactly what
   *  we want, because a measurement is exactly this (number followed by text).
   *  This handles scientific notation, as well as negative numbers.
   *  Note: It will convert long inputs to scientific notation, but this is OK
   *  because our wunit back-end measurement parsing accepts this too */
  const value = parseFloat(text);

  const unitsPerType = measurementType
    ? getMeasurementUnits(measurementType)
    : validUnits;
  const unitsToMatch = unitsPerType.concat(additionalUnits ?? []);

  let unit = '';
  unitsToMatch.forEach(val => {
    if (text.length > 0 && text.endsWith(val)) {
      /**
       * Always replace unit with the longer match so we can distinguish between
       *  similar units (for example, if the input text was '10mM' we dont want to
       *  match the unit to 'M', which would be valid when calling endswith(), but
       *  we should continue until we match 'mM') */
      unit = val.length > unit.length ? val : unit;
    }
  });
  return { value, unit, originalString: text };
}

const unitRegistry: { [key: string]: string[] } = {
  Concentration: [
    '%',
    'v/v',
    '%',
    'w/v',
    'EM',
    'EM/L',
    'EM/l',
    'EM/mL',
    'EM/ml',
    'EM/uL',
    'EM/ul',
    'EMol/L',
    'EMol/l',
    'EMol/mL',
    'EMol/ml',
    'EMol/nl',
    'EMol/uL',
    'EMol/ul',
    'EMolar',
    'EU/l',
    'EU/ml',
    'EU/nl',
    'EU/ul',
    'Eg/L',
    'Eg/l',
    'Eg/mL',
    'Eg/ml',
    'Eg/nl',
    'Eg/uL',
    'Eg/ul',
    'GM',
    'GM/L',
    'GM/l',
    'GM/mL',
    'GM/ml',
    'GM/uL',
    'GM/ul',
    'GMol/L',
    'GMol/l',
    'GMol/mL',
    'GMol/ml',
    'GMol/nl',
    'GMol/uL',
    'GMol/ul',
    'GMolar',
    'GU/l',
    'GU/ml',
    'GU/nl',
    'GU/ul',
    'Gg/L',
    'Gg/l',
    'Gg/mL',
    'Gg/ml',
    'Gg/nl',
    'Gg/uL',
    'Gg/ul',
    'M',
    'M/L',
    'M/l',
    'M/mL',
    'M/ml',
    'M/uL',
    'M/ul',
    'MM',
    'MM/L',
    'MM/l',
    'MM/mL',
    'MM/ml',
    'MM/uL',
    'MM/ul',
    'MMol/L',
    'MMol/l',
    'MMol/mL',
    'MMol/ml',
    'MMol/nl',
    'MMol/uL',
    'MMol/ul',
    'MMolar',
    'MU/l',
    'MU/ml',
    'MU/nl',
    'MU/ul',
    'Mg/L',
    'Mg/l',
    'Mg/mL',
    'Mg/ml',
    'Mg/nl',
    'Mg/uL',
    'Mg/ul',
    'Mol/L',
    'Mol/l',
    'Mol/mL',
    'Mol/ml',
    'Mol/nl',
    'Mol/uL',
    'Mol/ul',
    'Molar',
    'PM',
    'PM/L',
    'PM/l',
    'PM/mL',
    'PM/ml',
    'PM/uL',
    'PM/ul',
    'PMol/L',
    'PMol/l',
    'PMol/mL',
    'PMol/ml',
    'PMol/nl',
    'PMol/uL',
    'PMol/ul',
    'PMolar',
    'PU/l',
    'PU/ml',
    'PU/nl',
    'PU/ul',
    'Pg/L',
    'Pg/l',
    'Pg/mL',
    'Pg/ml',
    'Pg/nl',
    'Pg/uL',
    'Pg/ul',
    'TM',
    'TM/L',
    'TM/l',
    'TM/mL',
    'TM/ml',
    'TM/uL',
    'TM/ul',
    'TMol/L',
    'TMol/l',
    'TMol/mL',
    'TMol/ml',
    'TMol/nl',
    'TMol/uL',
    'TMol/ul',
    'TMolar',
    'TU/l',
    'TU/ml',
    'TU/nl',
    'TU/ul',
    'Tg/L',
    'Tg/l',
    'Tg/mL',
    'Tg/ml',
    'Tg/nl',
    'Tg/uL',
    'Tg/ul',
    'U/l',
    'U/ml',
    'U/nl',
    'U/ul',
    'X',
    'YM',
    'YM/L',
    'YM/l',
    'YM/mL',
    'YM/ml',
    'YM/uL',
    'YM/ul',
    'YMol/L',
    'YMol/l',
    'YMol/mL',
    'YMol/ml',
    'YMol/nl',
    'YMol/uL',
    'YMol/ul',
    'YMolar',
    'YU/l',
    'YU/ml',
    'YU/nl',
    'YU/ul',
    'Yg/L',
    'Yg/l',
    'Yg/mL',
    'Yg/ml',
    'Yg/nl',
    'Yg/uL',
    'Yg/ul',
    'ZM',
    'ZM/L',
    'ZM/l',
    'ZM/mL',
    'ZM/ml',
    'ZM/uL',
    'ZM/ul',
    'ZMol/L',
    'ZMol/l',
    'ZMol/mL',
    'ZMol/ml',
    'ZMol/nl',
    'ZMol/uL',
    'ZMol/ul',
    'ZMolar',
    'ZU/l',
    'ZU/ml',
    'ZU/nl',
    'ZU/ul',
    'Zg/L',
    'Zg/l',
    'Zg/mL',
    'Zg/ml',
    'Zg/nl',
    'Zg/uL',
    'Zg/ul',
    'aM',
    'aM/L',
    'aM/l',
    'aM/mL',
    'aM/ml',
    'aM/uL',
    'aM/ul',
    'aMol/L',
    'aMol/l',
    'aMol/mL',
    'aMol/ml',
    'aMol/nl',
    'aMol/uL',
    'aMol/ul',
    'aMolar',
    'aU/l',
    'aU/ml',
    'aU/nl',
    'aU/ul',
    'ag/L',
    'ag/l',
    'ag/mL',
    'ag/ml',
    'ag/nl',
    'ag/uL',
    'ag/ul',
    'cM',
    'cM/L',
    'cM/l',
    'cM/mL',
    'cM/ml',
    'cM/uL',
    'cM/ul',
    'cMol/L',
    'cMol/l',
    'cMol/mL',
    'cMol/ml',
    'cMol/nl',
    'cMol/uL',
    'cMol/ul',
    'cMolar',
    'cU/l',
    'cU/ml',
    'cU/nl',
    'cU/ul',
    'cg/L',
    'cg/l',
    'cg/mL',
    'cg/ml',
    'cg/nl',
    'cg/uL',
    'cg/ul',
    'dM',
    'dM/L',
    'dM/l',
    'dM/mL',
    'dM/ml',
    'dM/uL',
    'dM/ul',
    'dMol/L',
    'dMol/l',
    'dMol/mL',
    'dMol/ml',
    'dMol/nl',
    'dMol/uL',
    'dMol/ul',
    'dMolar',
    'dU/l',
    'dU/ml',
    'dU/nl',
    'dU/ul',
    'daM',
    'daM/L',
    'daM/l',
    'daM/mL',
    'daM/ml',
    'daM/uL',
    'daM/ul',
    'daMol/L',
    'daMol/l',
    'daMol/mL',
    'daMol/ml',
    'daMol/nl',
    'daMol/uL',
    'daMol/ul',
    'daMolar',
    'daU/l',
    'daU/ml',
    'daU/nl',
    'daU/ul',
    'dag/L',
    'dag/l',
    'dag/mL',
    'dag/ml',
    'dag/nl',
    'dag/uL',
    'dag/ul',
    'dg/L',
    'dg/l',
    'dg/mL',
    'dg/ml',
    'dg/nl',
    'dg/uL',
    'dg/ul',
    'fM',
    'fM/L',
    'fM/l',
    'fM/mL',
    'fM/ml',
    'fM/uL',
    'fM/ul',
    'fMol/L',
    'fMol/l',
    'fMol/mL',
    'fMol/ml',
    'fMol/nl',
    'fMol/uL',
    'fMol/ul',
    'fMolar',
    'fU/l',
    'fU/ml',
    'fU/nl',
    'fU/ul',
    'fg/L',
    'fg/l',
    'fg/mL',
    'fg/ml',
    'fg/nl',
    'fg/uL',
    'fg/ul',
    'g/L',
    'g/l',
    'g/mL',
    'g/ml',
    'g/nl',
    'g/uL',
    'g/ul',
    'hM',
    'hM/L',
    'hM/l',
    'hM/mL',
    'hM/ml',
    'hM/uL',
    'hM/ul',
    'hMol/L',
    'hMol/l',
    'hMol/mL',
    'hMol/ml',
    'hMol/nl',
    'hMol/uL',
    'hMol/ul',
    'hMolar',
    'hU/l',
    'hU/ml',
    'hU/nl',
    'hU/ul',
    'hg/L',
    'hg/l',
    'hg/mL',
    'hg/ml',
    'hg/nl',
    'hg/uL',
    'hg/ul',
    'kM',
    'kM/L',
    'kM/l',
    'kM/mL',
    'kM/ml',
    'kM/uL',
    'kM/ul',
    'kMol/L',
    'kMol/l',
    'kMol/mL',
    'kMol/ml',
    'kMol/nl',
    'kMol/uL',
    'kMol/ul',
    'kMolar',
    'kU/l',
    'kU/ml',
    'kU/nl',
    'kU/ul',
    'kg/L',
    'kg/l',
    'kg/mL',
    'kg/ml',
    'kg/nl',
    'kg/uL',
    'kg/ul',
    'mM',
    'mM/L',
    'mM/l',
    'mM/mL',
    'mM/ml',
    'mM/uL',
    'mM/ul',
    'mMol/L',
    'mMol/l',
    'mMol/mL',
    'mMol/ml',
    'mMol/nl',
    'mMol/uL',
    'mMol/ul',
    'mMolar',
    'mU/l',
    'mU/ml',
    'mU/nl',
    'mU/ul',
    'mg/L',
    'mg/l',
    'mg/mL',
    'mg/ml',
    'mg/nl',
    'mg/uL',
    'mg/ul',
    'nM',
    'nM/L',
    'nM/l',
    'nM/mL',
    'nM/ml',
    'nM/uL',
    'nM/ul',
    'nMol/L',
    'nMol/l',
    'nMol/mL',
    'nMol/ml',
    'nMol/nl',
    'nMol/uL',
    'nMol/ul',
    'nMolar',
    'nU/l',
    'nU/ml',
    'nU/nl',
    'nU/ul',
    'ng/L',
    'ng/l',
    'ng/mL',
    'ng/ml',
    'ng/nl',
    'ng/uL',
    'ng/ul',
    'pM',
    'pM/L',
    'pM/l',
    'pM/mL',
    'pM/ml',
    'pM/uL',
    'pM/ul',
    'pMol/L',
    'pMol/l',
    'pMol/mL',
    'pMol/ml',
    'pMol/nl',
    'pMol/uL',
    'pMol/ul',
    'pMolar',
    'pU/l',
    'pU/ml',
    'pU/nl',
    'pU/ul',
    'pg/L',
    'pg/l',
    'pg/mL',
    'pg/ml',
    'pg/nl',
    'pg/uL',
    'pg/ul',
    'uM',
    'uM/L',
    'uM/l',
    'uM/mL',
    'uM/ml',
    'uM/uL',
    'uM/ul',
    'uMol/L',
    'uMol/l',
    'uMol/mL',
    'uMol/ml',
    'uMol/nl',
    'uMol/uL',
    'uMol/ul',
    'uMolar',
    'uU/l',
    'uU/ml',
    'uU/nl',
    'uU/ul',
    'ug/L',
    'ug/l',
    'ug/mL',
    'ug/ml',
    'ug/nl',
    'ug/uL',
    'ug/ul',
    'v/v',
    'w/v',
    'x',
    'yM',
    'yM/L',
    'yM/l',
    'yM/mL',
    'yM/ml',
    'yM/uL',
    'yM/ul',
    'yMol/L',
    'yMol/l',
    'yMol/mL',
    'yMol/ml',
    'yMol/nl',
    'yMol/uL',
    'yMol/ul',
    'yMolar',
    'yU/l',
    'yU/ml',
    'yU/nl',
    'yU/ul',
    'yg/L',
    'yg/l',
    'yg/mL',
    'yg/ml',
    'yg/nl',
    'yg/uL',
    'yg/ul',
    'zM',
    'zM/L',
    'zM/l',
    'zM/mL',
    'zM/ml',
    'zM/uL',
    'zM/ul',
    'zMol/L',
    'zMol/l',
    'zMol/mL',
    'zMol/ml',
    'zMol/nl',
    'zMol/uL',
    'zMol/ul',
    'zMolar',
    'zU/l',
    'zU/ml',
    'zU/nl',
    'zU/ul',
    'zg/L',
    'zg/l',
    'zg/mL',
    'zg/ml',
    'zg/nl',
    'zg/uL',
    'zg/ul',
  ],
  Length: [
    '"',
    'Em',
    'Gm',
    'Mm',
    'Pm',
    'Tm',
    'Ym',
    'Zm',
    'am',
    'cm',
    'dam',
    'dm',
    'fm',
    'hm',
    'in',
    'km',
    'm',
    'mm',
    'nm',
    'pm',
    'um',
    'ym',
    'zm',
  ],
  Mass: [
    'Eg',
    'Gg',
    'Mg',
    'Pg',
    'Tg',
    'Yg',
    'Zg',
    'ag',
    'cg',
    'dag',
    'dg',
    'fg',
    'g',
    'hg',
    'kg',
    'mg',
    'ng',
    'pg',
    'ug',
    'yg',
    'zg',
  ],
  Moles: [
    'EMol',
    'GMol',
    'MMol',
    'Mol',
    'PMol',
    'TMol',
    'YMol',
    'ZMol',
    'aMol',
    'cMol',
    'dMol',
    'daMol',
    'fMol',
    'hMol',
    'kMol',
    'mMol',
    'nMol',
    'pMol',
    'uMol',
    'yMol',
    'zMol',
  ],
  Time: ['as', 'fs', 'h', 'min', 'minutes', 'ms', 'ns', 'ps', 's', 'us', 'ys', 'zs'],
  Temperature: ['C'],
  Volume: [
    'EL',
    'El',
    'Em^3',
    'GL',
    'Gl',
    'Gm^3',
    'L',
    'ML',
    'Ml',
    'Mm^3',
    'PL',
    'Pl',
    'Pm^3',
    'TL',
    'Tl',
    'Tm^3',
    'YL',
    'Yl',
    'Ym^3',
    'ZL',
    'Zl',
    'Zm^3',
    'aL',
    'al',
    'am^3',
    'cL',
    'cl',
    'cm^3',
    'dL',
    'daL',
    'dal',
    'dam^3',
    'dl',
    'dm^3',
    'fL',
    'fl',
    'fm^3',
    'hL',
    'hl',
    'hm^3',
    'kL',
    'kl',
    'km^3',
    'l',
    'mL',
    'm^3',
    'ml',
    'mm^3',
    'nL',
    'nl',
    'nm^3',
    'pL',
    'pl',
    'pm^3',
    'uL',
    'ul',
    'um^3',
    'yL',
    'yl',
    'ym^3',
    'zL',
    'zl',
    'zm^3',
  ],
};

export const measurementTypes = Object.keys(unitRegistry);

export const validUnits = Object.values(unitRegistry).flat();
