import React, { useMemo } from 'react';

import cx from 'classnames';

import { roundNumber } from 'common/lib/format';
import { WellContents } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import {
  RectPixels,
  WELL_BORDER_WIDTH_PX,
} from 'common/ui/components/simulation-details/mix/DeckLayout';
import WellLabel, {
  WellLabelContent,
} from 'common/ui/components/simulation-details/mix/WellLabel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type Props = {
  wellPos: RectPixels;
  /**
   * WellType are the possible types we receive from the MixPreview - Used in the Simulation Details.
   * LegacyWellShape are the possible types of plates stored in the UI - Used e.g. in the Plate
   * builder, Cherry Picker, Plate Library.
   * The LegacyWellShape should go away at some point.
   * */
  wellType: string;
  wellContents?: WellContents;
  showContentLabel?: boolean;
  contentLabelOverride?: WellLabelContent;
  color?: string;
  strokeColor?: string;
  isHighlighted?: boolean;
  isDisabled?: boolean;
  /**
   * If hasError is true, and isHighlighted is true, we will show the well in an error state.
   * (i.e. error state is only shown on wells that are highlighted, not on e.g. non-highlighted wells)
   */
  hasError?: boolean;
  disableHoverState?: boolean;
};

export const isWellTypeCircular = (wellType: string) =>
  wellType.toLocaleUpperCase() === 'CYLINDER' ||
  wellType.toLocaleUpperCase() === 'CIRCLE';

function formatWellContentLabel(contents?: WellContents): WellLabelContent {
  return {
    heading: contents?.total_volume ? roundNumber(contents.total_volume.value) : '',
    subHeading: contents?.total_volume.unit,
  };
}

export function Well(props: Props) {
  const classes = useStyles();
  const {
    wellPos,
    wellType,
    wellContents,
    color,
    showContentLabel,
    isHighlighted,
    isDisabled,
    hasError,
    disableHoverState,
    contentLabelOverride,
  } = props;
  const isWellCircular = isWellTypeCircular(wellType);
  const isWellEmpty = !wellContents;
  const showWellHoverState = !isDisabled && !disableHoverState;
  // Calculate coordinates of the line going from top right to bottom left of
  // the well, where d is diameter:
  //  x1 = d/2 + sin(45deg) * d/2 = ~0.85d
  //  y1 = d/2 - cos(45deg) * d/2 = ~0.15d
  const disabledLine = useMemo(
    () => ({
      x1: isWellCircular ? 0.85 * wellPos.width : wellPos.width,
      y1: isWellCircular ? 0.15 * wellPos.height : 0,
      x2: isWellCircular ? 0.15 * wellPos.width : 0,
      y2: isWellCircular ? 0.85 * wellPos.height : wellPos.height,
    }),
    [isWellCircular, wellPos.height, wellPos.width],
  );

  const label = useMemo(() => {
    return contentLabelOverride ?? formatWellContentLabel(wellContents);
  }, [contentLabelOverride, wellContents]);

  return (
    <g transform={`translate(${wellPos.left},${wellPos.top})`}>
      <rect
        width={wellPos.width}
        height={wellPos.height}
        rx={isWellCircular ? wellPos.width / 2 : 0}
        ry={isWellCircular ? wellPos.height / 2 : 0}
        fill={color ?? Colors.GREY_0}
        stroke={props.strokeColor ?? Colors.WELL_BORDER}
        vectorEffect="non-scaling-stroke"
        className={cx(classes.well, {
          [classes.highlighted]: isHighlighted && !hasError,
          [classes.disabled]: isDisabled,
          [classes.filledHoverState]: !isWellEmpty && showWellHoverState,
          [classes.emptyHoverState]: isWellEmpty && showWellHoverState,
          [classes.error]: isHighlighted && hasError,
        })}
        // Used to identify the click target in the MixScreen
        data-well="true"
      />
      {isDisabled && !isHighlighted && (
        <line {...disabledLine} className={classes.disabledLine} />
      )}
      {showContentLabel && <WellLabel label={label} wellPos={wellPos} />}
    </g>
  );
}

const useStyles = makeStylesHook(({ palette }) => ({
  well: {
    strokeWidth: WELL_BORDER_WIDTH_PX,
    vectorEffect: 'non-scaling-stroke',
  },
  emptyHoverState: {
    '&:hover': {
      fill: Colors.GREY_20,
      fillOpacity: 1,
    },
  },
  filledHoverState: {
    '&:hover': {
      fillOpacity: 0.5,
    },
  },
  disabled: {
    stroke: Colors.WELL_BORDER,
  },
  disabledLine: {
    strokeWidth: WELL_BORDER_WIDTH_PX,
    vectorEffect: 'non-scaling-stroke',
    stroke: Colors.WELL_BORDER,
  },
  error: {
    strokeWidth: WELL_BORDER_WIDTH_PX,
    stroke: Colors.ERROR_MAIN,
  },
  highlighted: {
    strokeWidth: WELL_BORDER_WIDTH_PX,
    stroke: palette.primary.main,
  },
}));
