import React from 'react';

import { WorkflowSummaryFragmentFragment } from 'client/app/gql';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { EntityCard } from 'common/ui/components/EntityCard';

type WorkflowCardProps = {
  workflow: WorkflowSummaryFragmentFragment;
  isLink?: boolean;
  onClick?: () => void;
  disabled?: boolean;
};
/**
 * Displays a link to a workflow in the Experiments UI.
 */
export function WorkflowCard({ workflow, isLink, onClick, disabled }: WorkflowCardProps) {
  const { route, EditorIcon } = getWorkflowPropsBySource(workflow.source);
  const path = route.getPath({ workflowId: workflow.id });
  return (
    <EntityCard
      entityName="Workflow"
      icon={<EditorIcon fontSize="small" />}
      name={workflow.name}
      author={workflow.createdBy.displayName}
      date={new Date(workflow.lastModifiedAt)}
      link={isLink ? path : undefined}
      onClick={onClick}
      disabled={disabled}
    />
  );
}
