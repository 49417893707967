import React, { useRef, useState } from 'react';

import { ClassNameMap } from '@mui/styles';
import cx from 'classnames';

import useElementColours from 'client/app/components/ElementPlumber/colours';
import PortTooltip from 'client/app/components/ElementPlumber/PortTooltip';
import getClassNameForType from 'client/app/components/ElementPlumber/types';
import { PendingConnection } from 'client/app/components/ElementPlumber/WorkflowLayout';
import { getOppositeSide, Side } from 'client/app/lib/layout/ConnectionHelper';
import {
  ELEMENT_INSTANCE_PORT_HEIGHT,
  ELEMENT_INSTANCE_PORT_WIDTH,
} from 'client/app/lib/layout/LayoutHelper';
import { isValidConnection } from 'client/app/lib/layout/ValidConnection';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { Parameter } from 'common/types/bundle';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

// Often when moving the pointer around the UI, you'll pass over the ports when
// you intend to drag or select the element instance. This often means that
// when you're trying to click the element instance, you end up clicking the
// tooltip instead. To avoid this, we'll wait a short amount of time between
// when the user hovers over the port and when we show the tooltip.
const TOOLTIP_DELAY_MS = 500;

// The code below is necessary to ensure that we render the tooltip on top
// of all the other ports in the element instance.
//
// The tooltip will be in the stacking context of the Port, which means it
// can only stack above or below siblings of itself. Since it's the only
// thing in the Port, the tooltip will never be able to have a higher z-index
// than other ports. To solve this conundrum, whenever we hover over a Port,
// we make it the highest z-index port in the entire ElementInstance. That way,
// the tooltip will never be obstructed by other Ports.
let CROSS_PORT_Z_INDEX_COUNTER = 9001;

const CLICK_SHIM_STYLE: React.CSSProperties = {
  userSelect: 'none', // prevent text selection on connect
  height: '100%',
  width: '100%',
};

function getNextZIndex() {
  CROSS_PORT_Z_INDEX_COUNTER += 1;
  return CROSS_PORT_Z_INDEX_COUNTER;
}

type Props = {
  data: Parameter;
  displayName?: string;
  isConnected: boolean;
  canConnect: boolean;
  isConnecting: boolean;
  isDisabled: boolean;
  isSiblingConnecting: boolean;
  isPartOfNearestConnection: boolean;
  pendingConnection: PendingConnection | null;
  top: number;
  left: number;
  side: Side;
  onConnectionStart?: (startSide: Side, startPort: Parameter) => void;
};

export default function Port(props: Props) {
  const classes = useStyles();
  const colourCss: ClassNameMap<string> = useElementColours();

  const [isHoveringPort, setIsHoveringPort] = useState(false);
  const [zIndex, setZIndex] = useState(getNextZIndex());

  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const clickShimRef = useRef<HTMLDivElement | null>(null);

  const isTypeConfigConnectionSettingEnabled = useFeatureToggle(
    'TYPE_CONFIGURATION_CONNECTION_SETTINGS',
  );

  const onPointerEnter = () => {
    timeoutId.current = setTimeout(() => {
      timeoutId.current = null;
      setIsHoveringPort(true);
      setZIndex(getNextZIndex());
    }, TOOLTIP_DELAY_MS);
  };

  const onPointerLeave = () => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
    setIsHoveringPort(false);
  };

  const isDeactivatedDuringPendingConnection = () => {
    if (!props.pendingConnection?.port) {
      return false;
    }

    if (props.isConnecting === true) {
      return false;
    }

    if (props.isSiblingConnecting) {
      return true;
    }
    const {
      data: port,
      pendingConnection: { port: pendingConnectionPort, side: pendingConnectionSide },
    } = props;

    const kindsAreComplementary = props.side === getOppositeSide(pendingConnectionSide);

    if (!kindsAreComplementary) {
      return true;
    }

    const input = pendingConnectionSide === 'input' ? pendingConnectionPort : port;
    const output = pendingConnectionSide === 'output' ? pendingConnectionPort : port;

    return !isValidConnection(input, output, isTypeConfigConnectionSettingEnabled);
  };

  const onConnectionStart = (e: React.PointerEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (!props.onConnectionStart) {
      return;
    }

    // If the user has clicked on the tooltip, for instance, we don't want to
    // trigger the beginning of a connection.
    if (e.target !== clickShimRef.current) {
      return;
    }

    // We don't (yet?) allow the user to rewire an already existing connection
    // without first deleting the existing connection.
    if (props.isConnected && props.side === 'input') {
      return;
    }

    props.onConnectionStart(props.side, props.data);
  };

  const className = getClassNameForType(props.data.type);
  const isDeactivated = isDeactivatedDuringPendingConnection();

  // Always show the tooltip for the currently-connecting port to help
  // keep the user in-context.
  //
  // Likewise, if this port is part of a pending connection (i.e. the
  // nearest connection that the user might want to complete), also
  // show the tooltip.
  const isTooltipVisible =
    isHoveringPort || props.isConnecting || props.isPartOfNearestConnection;

  const showAsConnectable = props.isConnected || props.canConnect;

  return (
    <div
      onPointerDown={props.canConnect ? onConnectionStart : undefined}
      onPointerEnter={onPointerEnter}
      onPointerLeave={onPointerLeave}
      style={{
        top: `${props.top}px`,
        left: `${props.left}px`,
        zIndex,
      }}
      className={cx(classes.connectionPort, {
        [classes.deactivated]: isDeactivated,
        [classes.connected]: showAsConnectable,
        [classes.connectedInput]: showAsConnectable && props.side === 'input',
        [classes.connectedOutput]: showAsConnectable && props.side === 'output',
        [classes.disabled]: props.isDisabled,
        ...(className && { [colourCss[className]]: true }),
      })}
    >
      <div ref={clickShimRef} style={CLICK_SHIM_STYLE} />
      {isTooltipVisible && (
        <PortTooltip
          data={props.data}
          displayName={props.displayName}
          side={props.side}
        />
      )}
    </div>
  );
}

const useStyles = makeStylesHook({
  connectionPort: {
    cursor: 'pointer',
    position: 'absolute',
    height: ELEMENT_INSTANCE_PORT_HEIGHT,
    width: ELEMENT_INSTANCE_PORT_WIDTH,
  },
  connected: {
    width: '15px',
  },
  connectedInput: {
    borderBottomRightRadius: '5px',
    borderTopRightRadius: '5px',
  },
  connectedOutput: {
    borderBottomLeftRadius: '5px',
    borderTopLeftRadius: '5px',
    marginLeft: '-5px',
  },
  deactivated: {
    opacity: 0.5,
  },
  disabled: {
    background: '#aaabab',
  },
});
