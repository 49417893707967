import React, { useCallback } from 'react';

import { useMutation } from '@apollo/client';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import CircularProgress from '@mui/material/CircularProgress';

import { MUTATION_RESTORE_SHARED_WORKFLOW } from 'client/app/api/gql/mutations';
import { QUERY_ALL_DEVICES } from 'client/app/api/gql/queries';
import { ArrayElement, IncomingWorkflowsQuery } from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { workflowRoutes } from 'client/app/lib/nav/actions';
import getWorkflowPropsBySource from 'client/app/lib/workflow/getWorkflowPropsBySource';
import { ScreenRegistry } from 'client/app/registry';
import { EntityCard } from 'common/ui/components/EntityCard';
import IconButton from 'common/ui/components/IconButton';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type SharedWorkflow = ArrayElement<IncomingWorkflowsQuery['sharedWorkflows']>;

type IncomingWorkflowCardProps = {
  sharedWorkflow: SharedWorkflow;
};

/**
 * An incoming workflow is a workflow that has been shared from one organisation to
 * another. This is used for sharing workflows to and from Synthace during user support.
 *
 * 1. A user needs help with a failing workflow and presses 'Request help' from the
 *    simulation to share the workflow with Synthace.
 *
 * 2. Any user in the Synthace org can access the workflow from the list of Incoming
 *    Workflows. Clicking on the workflow copies it to their drafts and opens it in the
 *    builder. Devices are also copied to ensure the workflow can be simulated.
 *
 * 3. The Synthace user can make changes, simulate, and share the workflow back to the
 *    other organisation.
 *
 * 4. The original user can access the workflow in their Incoming Workflows list. Clicking
 *    on the workflow copies to the draft and opens it. Unlike the Synthace org, the
 *    devices are not copied to prevent adding unintuitively named copies to their device
 *    list.
 */
export default function IncomingWorkflowCard({
  sharedWorkflow,
}: IncomingWorkflowCardProps) {
  const classes = useStyles();
  const userProfile = useUserProfile();
  const isSupport = userProfile?.isSupport || false;
  const workflowProps = getWorkflowPropsBySource(sharedWorkflow.sourceWorkflow.source);
  const EditorIcon = workflowProps.EditorIcon;

  const { navigate } = useNavigation();
  const snackbar = useSnackbarManager();
  const [restoreSharedWorkflowMutation, { loading }] = useMutation(
    MUTATION_RESTORE_SHARED_WORKFLOW,
    {
      onError: e => {
        console.error(e.message);
        snackbar.showError(
          'Sorry. We are having issues with copying the shared workflow.',
        );
      },
    },
  );

  const createSharedWorkflowCopy = useCallback(async () => {
    try {
      const copyWithDevices = isSupport;
      logEvent(
        'create-via-copying-shared-workflow',
        ScreenRegistry.EXPERIMENTS,
        isSupport ? (copyWithDevices ? 'with-devices' : 'no-devices') : undefined,
      );
      const result = await restoreSharedWorkflowMutation({
        variables: {
          sharedWorkflowId: sharedWorkflow.id,
          createDevices: copyWithDevices,
        },
        refetchQueries: copyWithDevices ? [{ query: QUERY_ALL_DEVICES }] : [], // get the list of devices if some have been created, to update the cache
        awaitRefetchQueries: true,
      });
      if (result) {
        const newWorkflowId = result.data?.restoreWorkflow.workflowId as SharedWorkflowId;
        if (!newWorkflowId) {
          throw new Error(`Could not restore shared workflow: ${sharedWorkflow.id}`);
        }
        navigate(workflowRoutes.openInWorkflowBuilder, {
          workflowId: newWorkflowId,
        });
      }
    } catch (e) {
      console.error(e);
      snackbar.showError('Failed to import workflow');
    }
  }, [isSupport, navigate, restoreSharedWorkflowMutation, sharedWorkflow.id, snackbar]);

  const entityCardAction = loading ? (
    <CircularProgress size={18} />
  ) : (
    <Tooltip title="Open request">
      <IconButton
        className={classes.iconButton}
        size="small"
        icon={<ArchiveOutlinedIcon fontSize="small" />}
        onClick={createSharedWorkflowCopy}
      />
    </Tooltip>
  );

  return (
    <EntityCard
      icon={<EditorIcon fontSize="small" />}
      entityName="Workflow"
      dateTitle="Time received"
      name={sharedWorkflow.sourceWorkflow.name}
      authorTitle="Sent by"
      author={sharedWorkflow.sourceUser.displayName}
      date={new Date(sharedWorkflow.createdAt)}
      additionalColumnTitle={isSupport ? 'Organisation' : undefined}
      additionalColumnValue={isSupport ? sharedWorkflow.sourceOrg.name : undefined}
      onClick={!loading ? createSharedWorkflowCopy : undefined}
      action={entityCardAction}
      hideStatusColumn
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  iconButton: {
    color: theme.palette.text.primary,
  },
}));
