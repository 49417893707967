import { ContentSourceType } from 'common/types/contentSource';
import { Device } from 'common/types/device';

export const MANUAL_DEVICE_DESCRIPTION = 'Conduct this manually in the lab';

export const DATA_ONLY_DUMMY_DEVICE: Device = {
  id: 'DATA_ONLY_DEVICE_ID',
  imageUrl: '/app/cdn/assets/devices/data_only.png',
  model: 'Data-only',
  name: 'Data-only (Computational)',
  anthaLangDeviceClass: 'DATA_ONLY',
  anthaHubGUID: null,
  accessibleDevices: [],
  contentSource: ContentSourceType.USER_GENERATED,
};
