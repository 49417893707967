import React, { useCallback, useMemo } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';

import FilterChip, {
  FilterChipWithPopoverProps,
} from 'common/ui/components/FilterChip/FilterChip';

export type DateRange = {
  startDate?: Moment;
  endDate?: Moment;
};

type Props = FilterChipWithPopoverProps<DateRange>;

/*
 *  Format date in a shorten form if it is the current year.
 */
function useFormattedDate(
  date: Moment | undefined,
  truncate: boolean, // Determine if this value should be truncated
): string | undefined {
  if (date) {
    const todayDate = moment();
    const formattedDate = date.format('ll');
    if (truncate && date.isSame(todayDate, 'year')) {
      // Moment doesn't currently support the format without year
      // https://github.com/moment/moment/issues/4325
      return formattedDate.split(',')[0];
    }
    return formattedDate;
  }
  return undefined;
}

const TODAY = moment();
const MOMENT_DATE_FORMAT = 'DD-MM-YYYY';

export function getDateRange(dateFrom?: string, dateTo?: string) {
  return {
    startDate: dateFrom ? moment(dateFrom, MOMENT_DATE_FORMAT) : undefined,
    endDate: dateTo ? moment(dateTo, MOMENT_DATE_FORMAT) : undefined,
  };
}
export function getDateFromTo(dateRange: DateRange) {
  const { startDate, endDate } = dateRange;
  return {
    dateFrom: startDate?.format(MOMENT_DATE_FORMAT),
    dateTo: endDate?.format(MOMENT_DATE_FORMAT),
  };
}

function getChipValueLabel(
  defaultChipLabel: string,
  startDateFormatted?: string,
  endDateFormatted?: string,
): string {
  if (startDateFormatted && startDateFormatted === endDateFormatted) {
    return 'On: ' + startDateFormatted;
  }
  if (startDateFormatted && endDateFormatted) {
    return startDateFormatted + ' - ' + endDateFormatted;
  }
  if (startDateFormatted) {
    return 'After: ' + startDateFormatted;
  }
  if (endDateFormatted) {
    return 'Before: ' + endDateFormatted;
  }
  return defaultChipLabel;
}

/**
 * A FilterChip that has a Date Range as the popover content.
 */
export default function FilterChipWithDateRange(props: Props) {
  const { filterValue, defaultChipLabel, onFilter, size } = props;

  const startDateFormatted = useFormattedDate(filterValue.startDate, true);
  // Always truncate unless startDate is not in current year.
  const shouldTruncateEndDate = useMemo(
    () => (filterValue.startDate?.isSame(moment(), 'year') ? true : false),
    [filterValue.startDate],
  );
  const endDateFormatted = useFormattedDate(filterValue.endDate, shouldTruncateEndDate);

  const valueLabel = getChipValueLabel(
    defaultChipLabel,
    startDateFormatted,
    endDateFormatted,
  );

  const chipLabelVariant =
    filterValue.startDate || filterValue.endDate ? 'filled' : 'outlined';

  const handleDelete = useCallback(() => {
    onFilter({ startDate: undefined, endDate: undefined });
  }, [onFilter]);

  const onStartDateChange = useCallback(
    (date: unknown) => {
      onFilter({ ...filterValue, startDate: (date as Moment) ?? undefined });
    },
    [filterValue, onFilter],
  );

  const onEndDateChange = useCallback(
    (date: unknown) => {
      onFilter({ ...filterValue, endDate: (date as Moment) ?? undefined });
    },
    [filterValue, onFilter],
  );

  const popoverContent = useCallback(() => {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack direction="row" sx={{ marginTop: 3, gap: 2 }}>
          <Stack direction="column">
            <Typography variant="body2"> Start Date </Typography>
            <DateCalendar
              views={['day']}
              value={filterValue.startDate}
              onChange={onStartDateChange}
              maxDate={filterValue.endDate ?? TODAY}
              disableHighlightToday
            />
          </Stack>
          <Stack>
            <Typography variant="body2"> End Date </Typography>
            <DateCalendar
              views={['day']}
              value={filterValue.endDate}
              onChange={onEndDateChange}
              minDate={filterValue.startDate}
              maxDate={TODAY}
              disableHighlightToday
            />
          </Stack>
        </Stack>
      </LocalizationProvider>
    );
  }, [filterValue.endDate, filterValue.startDate, onEndDateChange, onStartDateChange]);

  return (
    <FilterChip
      popoverContent={popoverContent}
      heading={props.heading}
      chipLabel={valueLabel}
      filterValue={filterValue}
      className={props.className}
      chipLabelVariant={chipLabelVariant}
      onDelete={handleDelete}
      isActive={filterValue.startDate || filterValue.endDate ? true : false}
      size={size}
    />
  );
}
