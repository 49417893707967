import { useCallback, useMemo, useState } from 'react';

import { produce } from 'immer';
import isEmpty from 'lodash/isEmpty';

import { FactorParameterInfo } from 'client/app/components/DOEFactorForm/types';
import { FactorItem } from 'common/types/bundle';

type Errors = {
  name?: string;
  factors?: string;
  levelCount?: string;
};

type State = {
  isCustom: boolean;
  isNew: boolean;
  name: string;
  factors: FactorItem[];
  replicateLevels: boolean;
  selectedFactor: FactorItem | null;
  addingFactor: boolean;
  showErrors: boolean;
  originalName: string | undefined;
};

function replicateFactor(state: State, factorToReplicate: FactorItem) {
  for (const factor of state.factors) {
    factor.unit = factorToReplicate.unit;
    factor.values = [...factorToReplicate.values];
    factor.sampleMode = factorToReplicate.sampleMode;
    factor.numberOfZerosToInclude = factorToReplicate.numberOfZerosToInclude;
    factor.typeName = factorToReplicate.typeName;
  }
}

export default function useDOEMutualExclusionFormState(
  parameter: FactorParameterInfo | null = null,
  group: FactorItem[] | null = null,
  existingGroupNames: string[] = [],
  initialEditFactor: FactorItem | undefined,
) {
  const [state, setState] = useState<State>(() => {
    let initFactors: FactorItem[];

    if (group) {
      initFactors = group?.map(factor => ({ ...factor })) ?? [];
    } else {
      initFactors = [];
    }

    return {
      isCustom: !parameter,
      isNew: !group,
      name: group?.[0]?.mutualExclusionGroup ?? '',
      factors: initFactors,
      replicateLevels: false,
      selectedFactor: initialEditFactor ?? null,
      levels: [],
      addingFactor: false,
      showErrors: false,
      originalName: group?.[0].mutualExclusionGroup,
    };
  });

  const updateName = useCallback((name: string = '') => {
    setState(
      produce((state: State) => {
        state.name = name;
        state.factors.forEach(factor => {
          factor.mutualExclusionGroup = name;
        });
      }),
    );
  }, []);

  const addFactor = useCallback(() => {
    setState(
      produce((state: State) => {
        state.addingFactor = true;
      }),
    );
  }, []);

  const deleteFactor = useCallback((id: string) => {
    setState(
      produce((state: State) => {
        state.factors = state.factors.filter(f => f.id !== id);
        if (id === state.selectedFactor?.id) {
          state.selectedFactor = null;
        }
      }),
    );
  }, []);

  const selectFactor = useCallback((factor: FactorItem | null) => {
    setState(state => ({
      ...state,
      selectedFactor: factor ? { ...factor, values: [...factor.values] } : null,
    }));
  }, []);

  const setReplicateLevels = useCallback((replicateLevels: boolean) => {
    setState(
      produce((state: State) => {
        state.replicateLevels = replicateLevels;

        if (replicateLevels) {
          const factorToReplicate = state.selectedFactor ?? state.factors[0];

          if (factorToReplicate) {
            replicateFactor(state, factorToReplicate);
          }
        }
      }),
    );
  }, []);

  const saveFactor = useCallback((factorToSave: FactorItem) => {
    setState(
      produce((state: State) => {
        if (state.addingFactor) {
          state.factors.push({
            ...factorToSave,
            mutualExclusionGroup: state.name,
          });
          state.addingFactor = false;
        } else {
          state.factors = state.factors.map(f =>
            f.id === factorToSave.id ? factorToSave : f,
          );
          state.selectedFactor = null;
        }

        if (state.replicateLevels) {
          replicateFactor(state, factorToSave);
        }
      }),
    );
  }, []);

  const cancelEditing = useCallback(() => {
    setState(state => ({
      ...state,
      selectedFactor: null,
      addingFactor: false,
    }));
  }, []);

  const showErrors = useCallback(() => {
    setState(state => ({
      ...state,
      showErrors: true,
    }));
  }, []);

  const errors: Errors | null = useMemo(() => {
    const result: Errors = {};

    if (state.name === '') {
      result.name = 'Name is required';
    } else if (
      state.name !== state.originalName &&
      existingGroupNames.includes(state.name)
    ) {
      result.name = 'The mutual exclusion name must be unique';
    }

    if (state.factors.length === 0) {
      result.factors = 'At least one factor is required';
    }

    if (
      state.factors.length &&
      state.factors.some(f => f.values.length !== state.factors[0].values.length)
    ) {
      result.levelCount = 'All factors must have the same number of levels.';
    }

    return isEmpty(result) ? null : result;
  }, [existingGroupNames, state.factors, state.name, state.originalName]);

  return {
    state,
    updateName,
    addFactor,
    deleteFactor,
    selectFactor,
    setReplicateLevels,
    saveFactor,
    cancelEditing,
    showErrors,
    errors,
  };
}
