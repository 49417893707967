import React from 'react';

import { RectPixels } from 'common/ui/components/simulation-details/mix/DeckLayout';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * As the width of the label within the well decreases, the text size (and therefore
 * the height) increases. To prevent the text breaking out of the well vertically,
 * make sure the label has a minimum width equal to this number of characters, even
 * if the label is shorter.
 */
const MINIMUM_LABEL_WIDTH_IN_CHARACTERS = 5;
/** Based on a font size of 12px, determined by eye. */
const LABEL_FONT_SIZE_RATIO = 0.3;

export type WellLabelContent = {
  heading: string;
  subHeading?: string;
};

type WellLabelProps = {
  label: WellLabelContent;
  wellPos: RectPixels;
};

export default function WellLabel({ wellPos, label }: WellLabelProps) {
  const classes = useStyles();

  const { heading, subHeading } = label;

  // Check the shorter side to make sure the text always fits inside the well
  // (important for rectangular wells)
  const radius = Math.min(wellPos.width, wellPos.height) / 2;
  const centerX = wellPos.width / 2;
  const centerY = wellPos.height / 2;

  // If there is a subHeading, then we'll need two lines, so make the font size smaller.
  const baseFontSize = subHeading ? 12 : 15;

  // Compute a font size that ensures the text will fit the text in the well
  const fontSize =
    ((radius * LABEL_FONT_SIZE_RATIO) /
      Math.max(MINIMUM_LABEL_WIDTH_IN_CHARACTERS, heading.length)) *
    baseFontSize;

  return (
    <>
      <text
        className={classes.wellLabel}
        x={centerX}
        y={centerY}
        textAnchor="middle"
        // If there is a subHeading to show underneath, align the value above the
        // centreline of the well. If there is no subHeading, then align it on the
        // centreline.
        dominantBaseline={subHeading ? 'text-after-edge' : 'central'}
        fontSize={fontSize + 'px'}
      >
        {heading}
      </text>
      {subHeading && (
        <text
          className={classes.wellLabel}
          x={centerX}
          // Push the subHeading label below the volume label.
          y={centerY}
          textAnchor="middle"
          dominantBaseline="text-before-edge"
          fontSize={fontSize + 'px'}
        >
          {subHeading}
        </text>
      )}
    </>
  );
}

const useStyles = makeStylesHook({
  wellLabel: {
    // Because we show hover states of the Well component we disable in case the
    // WellLabel is present to allow the hover event to propagate through.
    pointerEvents: 'none',
  },
});
