import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import ElementGroup from 'client/app/components/ElementGroup/ElementGroup';
import CompleteConnection from 'client/app/components/ElementPlumber/CompleteConnection';
import ConnectionComponent from 'client/app/components/ElementPlumber/Connection';
import ElementInstanceComponent from 'client/app/components/ElementPlumber/ElementInstance';
import { useDrawOrder } from 'client/app/components/ElementPlumber/useDrawOrder';
import { useElementGroupDropTarget } from 'client/app/components/ElementPlumber/useElementGroupDropTarget';
import useElementGroupSelection, {
  useCreateElementGroupFromMouseArea,
  useElementGroupMode,
} from 'client/app/components/ElementPlumber/useElementGroupSelection';
import {
  findConnectionsIntersectingBounds,
  findNearestConnectionToPosition,
  getAvailableConnectionsForPort,
  getConnectablePortMap,
  getConnectionsLookup,
  PositionedConnection,
  Side,
} from 'client/app/lib/layout/ConnectionHelper';
import {
  getLayoutDimensions,
  getPortPositionByName,
  isElementWithinSelection,
  isGroupWithinSelection,
  LayoutDimensions,
} from 'client/app/lib/layout/LayoutHelper';
import { Identifiable } from 'client/app/lib/workflow/cloneWithUUID';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { indexBy } from 'common/lib/data';
import doNothing from 'common/lib/doNothing';
import { Connection, ElementInstance, Group, Parameter } from 'common/types/bundle';
import { Position2d } from 'common/types/Position';
import Colors from 'common/ui/Colors';
import { MouseModeControlContextProvider } from 'common/ui/components/Workspace/MouseModeControl';
import { VisualSelectionBox } from 'common/ui/components/Workspace/VisualSelectionBox';
import { useSelectionWithMouseControlContext } from 'common/ui/hooks/useSelection';
import useThrottle from 'common/ui/hooks/useThrottle';
import Keys from 'common/ui/lib/keyboard';

/**
 * We need to be able to understand when the user is clicking in the background
 * with the intention of deselecting everything. The simplest answer here is
 * just to make the SVG element much bigger than it needs to be such that if the
 * user clicks in the background anywhere close to the elements, it will register
 * as a background click. This is a bit hacky, but in practice, it does the job
 * and is much, much simpler than other options.
 */
const CLICK_SHIM_PADDING = 5000;
const EMPTY_ARRAY: any[] = [];

type WorkflowEditMode = 'editable' | 'preview' | 'readonly';

type Props = {
  connections: Identifiable<Connection>[];
  elementInstances: ElementInstance[];
  editMode?: WorkflowEditMode;
  previewWidth?: number;
  /** Reference to workspace element in which elements should not be draggable outside of. */
  boundaryRef?: React.RefObject<HTMLElement>;

  deselectAll?: () => void;
  addConnection?: (connection: Connection) => void;
  setSelectedObjects?: (objectIds: string[]) => void;
  toggleSelectedObjects?: (objectIds: string[]) => void;
  selectedObjectIds?: string[];
  elementGroups?: Group[];
  areElementsLoading?: boolean;
};

export type PendingConnection = {
  elementInstance: ElementInstance;
  side: Side;
  port: Parameter;
  x: number;
  y: number;
};

function WorkflowLayout(props: Props) {
  const layoutBackgroundRef = useRef<SVGSVGElement | null>(null);

  /** Stores a reference to onConnectionEnd with bound availableConnections argument */
  const pointerUpEventListener = useRef<((e: PointerEvent) => void) | null>(null);
  /** Stores a reference to onConnectionMove with bound availableConnections argument */
  const pointerMoveEventListener = useRef<((e: PointerEvent) => void) | null>(null);
  const shimNodeRef = useRef<HTMLDivElement | null>(null);

  const [nearestConnection, setNearestConnection] = useState<Connection | null>(null);
  const [pendingConnection, setPendingConnection] = useState<PendingConnection | null>(
    null,
  );
  const [pointerCurrentPos, setPointerCurrentPos] = useState({ x: 0, y: 0 });
  const [dimensions, setDimensions] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const [portMap, setPortMap] = useState({ input: {}, output: {} });
  const [connectionsLookup, setConnectionsLookup] = useState({});

  const { screenId } = useContext(ScreenContext);

  const workflowBuilderMode = useWorkflowBuilderSelector(state => state.mode);
  const erroredObjectIds = useWorkflowBuilderSelector(state => state.erroredObjectIds);
  const dragDelta = useWorkflowBuilderSelector(state => state.dragDelta);
  const centerElementId = useWorkflowBuilderSelector(state => state.centerElementId);
  const switchElementParameterValidation = useWorkflowBuilderSelector(
    state => state.switchElementParameterValidation,
  );

  const isReadonly = props.editMode !== 'editable';
  const { isElementGroupModeActive } = useElementGroupMode();
  const createElementGroupFromMouseArea = useCreateElementGroupFromMouseArea();

  const {
    elementInstances,
    elementGroups = EMPTY_ARRAY,
    connections,
    selectedObjectIds = EMPTY_ARRAY,
    setSelectedObjects,
    toggleSelectedObjects,
  } = props;
  // Whether this should be here or in the WorkflowBuilderStateContext is a bit
  // murky. Ideally, you could throw some bounds at it and have it do the
  // computation work to figure out which objects fall within those bounds.
  // There is an untidy detail here -- the sizes of element instances aren't
  // stored in the Context. So, to do this calculation would require importing
  // layout measurement helpers into it.  So, we'll  do the calculation here
  // and send a list of selected object IDs to the Context.
  const applyVisualSelection = useCallback(
    (selectionDimensions: LayoutDimensions) => {
      if (isReadonly) return;

      if (isElementGroupModeActive) {
        createElementGroupFromMouseArea(selectionDimensions);
        return;
      }
      const selectedObjectIds = new Set<string>();
      const instancesByName = new Map<string, ElementInstance>();

      elementGroups?.forEach(group => {
        if (isGroupWithinSelection(selectionDimensions, group)) {
          selectedObjectIds.add(group.id);
          elementInstances.forEach(ei => {
            if (group.elementIds.includes(ei.Id)) {
              selectedObjectIds.add(ei.Id);
            }
          });
        }
      });
      elementInstances.forEach((ei: ElementInstance) => {
        instancesByName.set(ei.name, ei);

        if (isElementWithinSelection(selectionDimensions, ei, connections)) {
          selectedObjectIds.add(ei.Id);
        }
      });
      const intersectingConnectionIds = findConnectionsIntersectingBounds(
        connections,
        selectionDimensions,
        instancesByName,
      );

      setSelectedObjects?.([
        ...Array.from(selectedObjectIds),
        ...intersectingConnectionIds,
      ]);
    },
    [
      connections,
      createElementGroupFromMouseArea,
      elementGroups,
      elementInstances,
      isElementGroupModeActive,
      isReadonly,
      setSelectedObjects,
    ],
  );

  /**
   * While creating an element group we would like to highlight elements
   * when they get into the mouse selection. So we throttle mouse movement
   * event and highlight elements within the selection.
   */
  const handleElementMouseAreaSelection = useThrottle(
    useCallback(
      (selectionDimensions: LayoutDimensions) => {
        if (!isElementGroupModeActive) return;

        const newSelectedIds: string[] = [];

        elementInstances.forEach((ei: ElementInstance) => {
          if (isElementWithinSelection(selectionDimensions, ei, connections)) {
            newSelectedIds.push(ei.Id);
          }
        });

        /**
         * We don't want to change workflow state context every time mouse moves.
         * The state has to be updated if and only if some element was selected or unselected.
         */
        if (newSelectedIds.length !== selectedObjectIds?.length) {
          setSelectedObjects?.(newSelectedIds);
        }
      },
      [
        connections,
        elementInstances,
        isElementGroupModeActive,
        selectedObjectIds?.length,
        setSelectedObjects,
      ],
    ),
    50,
  );

  const {
    selectionDimensions,
    inSelectionMode,
    setInSelectionMode,
    isSelecting,
    shimNode,
    onPointerDown: onSelectionPointerDown,
  } = useSelectionWithMouseControlContext(
    getPaddedDimensions(dimensions),
    applyVisualSelection,
    'select',
    undefined, // keep default options
    handleElementMouseAreaSelection,
  );

  useElementGroupSelection(setInSelectionMode);

  const isTypeConfigConnectionSettingEnabled = useFeatureToggle(
    'TYPE_CONFIGURATION_CONNECTION_SETTINGS',
  );

  const updateConnectionData = useCallback(() => {
    const updatedConnectionsLookup = getConnectionsLookup(props.connections);
    const updatedPortMap = getConnectablePortMap(
      props.elementInstances,
      isTypeConfigConnectionSettingEnabled,
    );
    setConnectionsLookup(updatedConnectionsLookup);
    setPortMap(updatedPortMap);
  }, [isTypeConfigConnectionSettingEnabled, props.connections, props.elementInstances]);

  const updateDimensions = useCallback(() => {
    const updatedDimensions = getLayoutDimensions(
      props.elementInstances,
      props.connections,
      props.elementGroups,
    );
    setDimensions(updatedDimensions);
  }, [props.elementInstances, props.connections, props.elementGroups]);

  const isObjectSelected = useMemo(() => {
    const selected = new Set(props.selectedObjectIds);
    return (objectId: string) => selected.has(objectId);
  }, [props.selectedObjectIds]);

  // Enable or disable selection mode when a key is pressed
  const handleKeyDownForSelectionMode = useCallback(
    (e: KeyboardEvent) => {
      // Allow CMD/CTRL + drag selection in readonly WFs.
      if (props.editMode === 'readonly') {
        const isHoldingMultiSelect = e.key === Keys.META || e.key === Keys.CONTROL;
        if (isHoldingMultiSelect && !e.repeat) {
          setInSelectionMode(val => !val);
        }
      }

      if (props.editMode !== 'editable') {
        return;
      }

      if (![Keys.META, Keys.CONTROL].includes(e.key) && (e.metaKey || e.ctrlKey)) {
        // We disable selection mode as soon as another key is pressed while ctrl/meta is still pressed
        // This was the easiest way to not have the selection mode on when copying/pasting
        setInSelectionMode(false);
      }

      switch (e.key) {
        case Keys.CONTROL:
        case Keys.META:
          if (!e.repeat) {
            setInSelectionMode(val => !val);
          }
          break;
        case Keys.ESCAPE:
          setInSelectionMode(false);
          break;
      }
    },
    [props.editMode, setInSelectionMode],
  );

  const handleSelect = useCallback(
    (objectId: string, isMultiSelect: boolean) => {
      const objectIds = [objectId];

      if (isMultiSelect) {
        toggleSelectedObjects?.(objectIds);
      } else {
        setSelectedObjects?.(objectIds);
      }
    },
    [setSelectedObjects, toggleSelectedObjects],
  );
  const handleElementSelect = useCallback(
    (elementInstance: ElementInstance, e?: React.PointerEvent<Element>) => {
      const isMultiSelect = !!(e?.ctrlKey || e?.metaKey); /* multi-select */
      handleSelect(elementInstance.Id, isMultiSelect);
    },
    [handleSelect],
  );
  const handleGroupSelect = useCallback(
    (group: Group, e?: React.PointerEvent) => {
      /**
       * We don't allow selecting groups in read-only mode
       */
      if (isReadonly) return;

      const isMultiSelect = !!(e?.ctrlKey || e?.metaKey); /* multi-select */
      const groupElements = new Set(group.elementIds);
      const objectIds = [group.id];
      const elementNames = new Set<string>();
      const isGroupSelected = isObjectSelected(group.id);

      for (const ei of elementInstances) {
        if (groupElements.has(ei.Id)) {
          elementNames.add(ei.name);

          // When toggling selection (i.e. multi-select), we only want to toggle
          // those child elements whose selection status matches the group.
          if (!isMultiSelect || isObjectSelected(ei.Id) === isGroupSelected) {
            objectIds.push(ei.Id);
          }
        }
      }

      /**
       * When selecting a group we also want to select all the connections
       * between its elements, as this is more useful for copy-and-paste.
       */
      const internalConnections = connections
        .filter(
          conn =>
            elementNames.has(conn.Source.ElementInstance) &&
            elementNames.has(conn.Target.ElementInstance) &&
            // When toggling selection (i.e. multi-select), we only want to toggle
            // those connections whose selection status matches the group.
            (!isMultiSelect || isObjectSelected(conn.id) === isGroupSelected),
        )
        .map(conn => conn.id);

      objectIds.push(...internalConnections);

      if (isMultiSelect) {
        toggleSelectedObjects?.(objectIds);
      } else {
        setSelectedObjects?.(objectIds);
      }
    },
    [
      connections,
      elementInstances,
      isObjectSelected,
      isReadonly,
      setSelectedObjects,
      toggleSelectedObjects,
    ],
  );

  const getAvailableConnections = useCallback(
    (
      elementInstance: ElementInstance,
      side: Side,
      port: Parameter,
    ): PositionedConnection[] => {
      return getAvailableConnectionsForPort(
        connectionsLookup,
        props.elementInstances,
        props.connections,
        elementInstance,
        side,
        port,
        isTypeConfigConnectionSettingEnabled,
      );
    },
    [
      connectionsLookup,
      isTypeConfigConnectionSettingEnabled,
      props.connections,
      props.elementInstances,
    ],
  );

  const onConnectionMove = useCallback(
    (availableConnections: PositionedConnection[], e: PointerEvent) => {
      e.stopPropagation();

      window.getSelection()?.removeAllRanges();

      const eventX = e.offsetX + dimensions.left - CLICK_SHIM_PADDING;
      const eventY = e.offsetY + dimensions.top - CLICK_SHIM_PADDING;

      const nearestConnectionData = findNearestConnectionToPosition(
        { x: eventX, y: eventY },
        availableConnections,
      );

      // In addition to snapping the pending connection to the port, we also
      // want to display the tooltip for the port that we might connect to.
      // To enable that, we pass the would-be connection into the
      // ElementInstance if there is one.
      setNearestConnection(nearestConnectionData?.connection ?? null);

      const endX = nearestConnectionData?.position.x ?? eventX;
      const endY = nearestConnectionData?.position.y ?? eventY;
      setPointerCurrentPos({ x: endX, y: endY });
    },
    [dimensions.left, dimensions.top],
  );

  const onConnectionEnd = useCallback(
    (availableConnections: PositionedConnection[], e: PointerEvent) => {
      if (!props.addConnection) {
        return;
      }

      if (pointerUpEventListener.current && pointerMoveEventListener.current) {
        shimNodeRef.current?.removeEventListener(
          'pointerup',
          pointerUpEventListener.current,
        );
        shimNodeRef.current?.removeEventListener(
          'pointermove',
          pointerMoveEventListener.current,
          true,
        );
      }

      const nearestConnectionData = findNearestConnectionToPosition(
        {
          x: e.offsetX + dimensions.left - CLICK_SHIM_PADDING,
          y: e.offsetY + dimensions.top - CLICK_SHIM_PADDING,
        },
        availableConnections,
      );
      if (nearestConnectionData) {
        props.addConnection(nearestConnectionData.connection);
      }

      setPendingConnection(null);
      setNearestConnection(null);
    },
    [dimensions.left, dimensions.top, props],
  );

  const onConnectionStart = useCallback(
    (startElementInstance: ElementInstance, startSide: Side, startPort: Parameter) => {
      if (props.editMode !== 'editable') {
        return;
      }

      props.deselectAll?.();

      const startPortPosition = getPortPositionByName(
        startPort.name,
        startSide,
        startElementInstance,
        props.connections,
      );

      if (!startPortPosition) {
        throw new Error(
          `Couldn't start a connection from the ${startPort.name} port on ${startElementInstance.name} as the position couldn't be found.`,
        );
      }

      // We're faking the initial position of the pointer prior to the first
      // pointermove event
      setPointerCurrentPos(startPortPosition);
      const availableConnections = getAvailableConnections(
        startElementInstance,
        startSide,
        startPort,
      );

      // Storing refs to the bound event listeners so they can be used for event listener
      // removal in onConnectionEnd. State can't be used for available connections as it
      // wouldn't be up-to-date in onConnectionEnd if we were to set it here.
      pointerMoveEventListener.current = onConnectionMove.bind(
        null,
        availableConnections,
      );
      pointerUpEventListener.current = onConnectionEnd.bind(null, availableConnections);

      setPendingConnection({
        elementInstance: startElementInstance,
        side: startSide,
        port: startPort,
        x: startPortPosition.x,
        y: startPortPosition.y,
      });

      // This is a bit of a hack, to be honest. `window` would be a much more
      // natural place to set this event listener because it would mean that
      // even if the user moused outside of the EP, the connection would continue
      // to track their pointer position. That's a lovely thing.
      //
      // However...
      // Mapping global x and y coordinates from window-level into the local
      // coordinate space of the Workspace is actually quite tricky. For one,
      // the ElementPlumber is not at (0, 0), so we have to account for that.
      // Getting the position of the ElementPlumber robustly requires calculating
      // the bounding rectangle, which is slow and can trigger reflows.
      // Next, the Workspace within the ElementPlumber can be dragged to any
      // arbitrary x and y, so we have to account for that. Next, the Workspace
      // can also be zoomed, so if we wanted to accurately map global x and y
      // into Workspace x and y values, we'd have to consume the ZoomContext
      // and adjust the fraction of the total x and y that is inside the zoomed
      // container.
      //
      // In short -- it ain't worth it, mate.
      //
      // By using offsetX and offsetY of something within the Workspace
      // coordinate space, we get correct coordinates automatically.
      shimNodeRef.current?.addEventListener(
        'pointermove',
        pointerMoveEventListener.current,
        true,
      );
      shimNodeRef.current?.addEventListener('pointerup', pointerUpEventListener.current);
    },
    [getAvailableConnections, onConnectionEnd, onConnectionMove, props],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDownForSelectionMode);

    return () => {
      window.removeEventListener('keydown', handleKeyDownForSelectionMode);
    };
  }, [handleKeyDownForSelectionMode]);

  useEffect(() => {
    updateConnectionData();
    updateDimensions();
  }, [updateConnectionData, updateDimensions]);

  const renderConnections = (leftOffset: number, topOffset: number) => {
    if (!props.connections?.length || !props.elementInstances?.length) {
      return null;
    }

    const elementInstancesByName = indexBy(props.elementInstances, 'name');
    return props.connections.map(connection => {
      const sourceElementInstance =
        elementInstancesByName[connection.Source.ElementInstance];
      const isSourceSelected = isObjectSelected(sourceElementInstance.Id);

      const targetElementInstance =
        elementInstancesByName[connection.Target.ElementInstance];
      const isTargetSelected = isObjectSelected(targetElementInstance.Id);

      const onSelectConnection = (e: MouseEvent) =>
        handleSelect(connection.id, e.ctrlKey || e.metaKey);

      const isSelected = isObjectSelected(connection.id);

      return (
        <CompleteConnection
          key={connection.id}
          connectionIsPending={!!pendingConnection}
          connection={connection}
          connections={props.connections}
          isSelected={isSelected}
          isDisabled={workflowBuilderMode === 'DOE'}
          onSelect={props.editMode === 'editable' ? onSelectConnection : doNothing}
          sourceElementInstance={sourceElementInstance}
          sourceDragDelta={isSourceSelected ? dragDelta : null}
          targetElementInstance={targetElementInstance}
          targetDragDelta={isTargetSelected ? dragDelta : null}
          leftOffset={leftOffset}
          topOffset={topOffset}
        />
      );
    });
  };

  const layoutStyle = getLayoutStyle(
    props.editMode === 'preview',
    dimensions,
    props.previewWidth,
  );

  const paddedDimensions = getPaddedDimensionsCSS(dimensions);

  const leftOffset = -dimensions.left + CLICK_SHIM_PADDING;
  const topOffset = -dimensions.top + CLICK_SHIM_PADDING;
  const onPointerDown = useCallback(
    (e: React.PointerEvent<SVGElement>) => {
      // We do not want to start selecting if we click on an element or connection.
      if (e.target !== layoutBackgroundRef.current) {
        return;
      }
      onSelectionPointerDown(e);
    },
    [onSelectionPointerDown],
  );

  const dropTargetGroupId = useElementGroupDropTarget(
    elementInstances,
    dragDelta,
    elementGroups,
    connections,
    selectedObjectIds,
  );

  const zIndexMap = useDrawOrder(selectedObjectIds, elementInstances, elementGroups);

  return (
    <div style={layoutStyle} onDoubleClick={props.deselectAll}>
      <div
        ref={shimNodeRef}
        style={{
          cursor: 'auto',
          display: pendingConnection ? 'block' : 'none',
          position: 'absolute',
          zIndex: 3,
          ...paddedDimensions,
        }}
      />
      {shimNode}
      {isSelecting && (
        <VisualSelectionBox
          {...selectionDimensions}
          borderColor={Colors.BLUE_70}
          borderStyle="dashed"
          backgroundColor="transparent"
        />
      )}

      {/*
          layoutInner is just a wrapper node used to tidy up the stacking
          contexts. Without it, the elementInstances would all be sharing
          z-space with the nodes necessary for correct click/drag
          interactions, which would not be ideal.
        */}
      <div
        style={{
          width: `${dimensions.width}px`,
          height: `${dimensions.height}px`,
          position: 'absolute',
          zIndex: 1,
        }}
      >
        <svg
          ref={layoutBackgroundRef}
          onPointerDown={onPointerDown}
          style={{
            cursor: inSelectionMode ? 'crosshair' : 'inherit',
            position: 'absolute',
            ...paddedDimensions,
          }}
        >
          {renderConnections(leftOffset, topOffset)}
          {pendingConnection && (
            <PendingConnection
              connection={pendingConnection}
              pointerCurrentPos={pointerCurrentPos}
              leftOffset={leftOffset}
              topOffset={topOffset}
            />
          )}
        </svg>
        {elementGroups
          ? elementGroups.map(group => {
              const isSelected = isObjectSelected(group.id);
              const delta = isSelected ? dragDelta : null;
              return (
                <ElementGroup
                  key={group.id}
                  group={group}
                  isSelected={isSelected}
                  isReadonly={isReadonly}
                  isDropTarget={group.id === dropTargetGroupId}
                  dragDelta={delta}
                  onSelect={handleGroupSelect}
                  zIndex={zIndexMap.get(group.id)}
                />
              );
            })
          : null}
        {elementInstances
          ? elementInstances.map(ei => {
              const isSelected = isObjectSelected(ei.Id);
              const isGrouped = isGroupedWithinSelectedGroup(
                ei.Id,
                elementGroups,
                selectedObjectIds,
              );
              const hasError = erroredObjectIds.includes(ei.Id);
              return (
                <ElementInstanceComponent
                  key={ei.Id}
                  elementInstance={ei}
                  isSelected={isSelected}
                  isGrouped={isGrouped}
                  hasError={hasError}
                  showStatus={false} // TODO: set the condition here for element parameter validation v2
                  isLoading={props.areElementsLoading ?? false}
                  onSelect={handleElementSelect}
                  dragDelta={isSelected ? dragDelta : null}
                  allConnections={props.connections}
                  portMap={portMap}
                  nearestConnection={nearestConnection}
                  pendingConnection={pendingConnection}
                  onConnectionStart={onConnectionStart}
                  isReadonly={isReadonly}
                  boundaryRef={props.boundaryRef}
                  logEventCategory={screenId as string}
                  zIndex={zIndexMap.get(ei.Id)}
                  shouldShowValidation={
                    switchElementParameterValidation || centerElementId === ei.Id
                  }
                />
              );
            })
          : null}
      </div>
    </div>
  );
}

export function WorkflowLayoutWithMouseModeContext(props: Props) {
  return (
    <MouseModeControlContextProvider>
      <WorkflowLayout {...props} />
    </MouseModeControlContextProvider>
  );
}

export default WorkflowLayout;

function PendingConnection(props: {
  connection: PendingConnection;
  pointerCurrentPos: Position2d;
  leftOffset: number;
  topOffset: number;
}) {
  const {
    connection,
    pointerCurrentPos: { x, y },
    leftOffset,
    topOffset,
  } = props;
  // In connection, we really must have the start being the
  // element with the output, and the end being the element with
  // the input, because otherwise the bezier curves get a bit
  // Picasso. So we detangle them here.
  let startPosition = {
    x: connection.x + leftOffset,
    y: connection.y + topOffset,
  };

  let endPosition = {
    x: x + leftOffset,
    y: y + topOffset,
  };

  if (connection.side === 'input') {
    // the connection is starting from an input, so we need to
    // swap everything around
    [startPosition, endPosition] = [endPosition, startPosition];
  }
  return (
    <ConnectionComponent
      startPosition={startPosition}
      endPosition={endPosition}
      type={connection.port.type}
      connectionIsPending
      complete={false}
      isSelected={false}
      isDisabled={false}
    />
  );
}

function getPaddedDimensions(dimensions: LayoutDimensions): LayoutDimensions {
  return {
    height: dimensions.height + 2 * CLICK_SHIM_PADDING,
    left: dimensions.left - CLICK_SHIM_PADDING,
    top: dimensions.top - CLICK_SHIM_PADDING,
    width: dimensions.width + 2 * CLICK_SHIM_PADDING,
  };
}

function getPaddedDimensionsCSS(dimensions: LayoutDimensions): React.CSSProperties {
  const paddedDimensions = getPaddedDimensions(dimensions);
  return {
    height: `${paddedDimensions.height}px`,
    left: `${paddedDimensions.left}px`,
    top: `${paddedDimensions.top}px`,
    width: `${paddedDimensions.width}px`,
  };
}

function getLayoutStyle(
  isPreview: boolean,
  dimensions: LayoutDimensions,
  previewWidth?: number,
): React.CSSProperties {
  const { width, height } = dimensions;
  if (!isPreview) {
    return {
      width: `${width}px`,
      height: `${height}px`,
    };
  } else {
    // Scale down the editor for use in places like form workflows.
    const scale = previewWidth ? previewWidth / width : 1;
    // Set pointerEvents to none to remove all interactivity.
    return {
      pointerEvents: 'none',
      width: `${width * scale}px`,
      height: `${height * scale}px`,
      transform: `scale(${scale})`,
      transformOrigin: 'top left',
      position: 'relative',
    };
  }
}

const isGroupedWithinSelectedGroup = (
  elementId: string,
  elementGroups?: Group[],
  selectedObjectIds?: string[],
) => {
  const group = elementGroups?.find(group => group.elementIds.includes(elementId));
  return !!(group?.id && selectedObjectIds?.includes(group.id));
};
