import React, { useCallback } from 'react';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';

import { useCherryPickContext } from 'client/app/apps/cherry-picker/CherryPickContext';
import DeviceSelector from 'client/app/components/Parameters/DeviceSelector';
import {
  getDeviceConfigurationForUI,
  setDevicesInConfig,
} from 'client/app/lib/workflow/deviceConfigUtils';
import { ScreenRegistry } from 'client/app/registry';
import { WorkflowDeviceConfiguration } from 'common/types/bundle';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

export default function DeviceSettings() {
  const { setBundleConfig, bundleConfig, setSelectedDevice, isReadonly } =
    useCherryPickContext();

  const handleSelectDevices = useCallback(
    (selectedDevices: WorkflowDeviceConfiguration) => {
      logEvent('settings-change-device', ScreenRegistry.CHERRY_PICKER);
      const configWithDevices = setDevicesInConfig(bundleConfig, selectedDevices);
      setBundleConfig(configWithDevices);
      setSelectedDevice(selectedDevices);
    },
    [bundleConfig, setBundleConfig, setSelectedDevice],
  );

  return (
    <Paper>
      <CardHeader title="Select an execution mode" />
      <CardContent>
        <DialogContentText gutterBottom>
          Please select an execution mode to run this CherryPick on
        </DialogContentText>
        <DeviceSelector
          deviceConfiguration={getDeviceConfigurationForUI(bundleConfig)}
          onSelectedDevicesChange={handleSelectDevices}
          singleSelect
          isDisabled={isReadonly}
        />
      </CardContent>
    </Paper>
  );
}
