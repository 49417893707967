import React, { useCallback, useMemo } from 'react';

import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { formatDateTime } from 'common/lib/format';
import { AnthaHubExecutionTask, ExecutionTaskStatus } from 'common/types/execution';
import Colors from 'common/ui/Colors';
import { ExecutionTaskIcon } from 'common/ui/components/simulation-details/ExecutionTaskIcon';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type TaskRowProps = {
  actionButton?: React.ReactNode;
  fullWidth?: boolean;
  isAutorunToggleEnabled: boolean;
  shouldNextTaskRunAutomatically: boolean;
  shouldThisTaskRunAutomatically: boolean;
  task: AnthaHubExecutionTask;
  taskBelongsToAnotherAnthaHub: boolean;
};

export const TaskRow = React.memo(function TaskRow({
  actionButton,
  fullWidth,
  isAutorunToggleEnabled,
  shouldNextTaskRunAutomatically,
  shouldThisTaskRunAutomatically,
  task,
  taskBelongsToAnotherAnthaHub,
}: TaskRowProps) {
  const classes = useStyles();

  const statusForTask = useCallback((task: AnthaHubExecutionTask) => {
    switch (task.status) {
      case ExecutionTaskStatus.QUEUED:
        return 'To start';

      case ExecutionTaskStatus.PENDING:
        return 'Pending';

      case ExecutionTaskStatus.RUNNING:
        return 'In progress';

      /** RESUME_FAILED happens when Antha crashes in the background, then
       *  comes back alive and fails to resume the task. This is not
       *  related to users' actions. Thus, we show it just like a regular
       *  "Failed" task. */
      case ExecutionTaskStatus.RESUME_FAILED:
        return 'Failed';

      case ExecutionTaskStatus.SKIPPED:
        return 'Skipped';

      default:
        return task.status;
    }
  }, []);

  const humanReadableStatus = useMemo(() => statusForTask(task), [statusForTask, task]);
  const taskFailed = task.status === ExecutionTaskStatus.FAILED;
  const hasTimestamp = useMemo(() => task.lastModifiedAt, [task.lastModifiedAt]);

  return (
    <div
      className={cx(classes.taskContainer, {
        [classes.disabledTask]: taskBelongsToAnotherAnthaHub,
        [classes.fullWidth]: fullWidth,
      })}
      key={task.id}
    >
      <div
        className={cx(classes.taskContent, {
          [classes.taskContentWithTimestamp]: hasTimestamp,
        })}
      >
        <ExecutionTaskIcon
          taskFailed={taskFailed}
          shouldThisTaskRunAutomatically={shouldThisTaskRunAutomatically}
          shouldNextTaskRunAutomatically={shouldNextTaskRunAutomatically}
          dashed={!isAutorunToggleEnabled}
        />
        <Typography variant="body2" className={classes.taskName}>
          {task.name}
        </Typography>
        <Typography variant="body2" className={classes.taskStatus}>
          {humanReadableStatus}
        </Typography>
        {task.lastModifiedAt && (
          <Typography variant="body2" className={classes.taskLastModifiedAt}>
            {task.status !== ExecutionTaskStatus.QUEUED
              ? formatDateTime(task.lastModifiedAt.toDate())
              : ''}
          </Typography>
        )}
        {!taskBelongsToAnotherAnthaHub && actionButton}
      </div>
    </div>
  );
});

const useStyles = makeStylesHook(theme => ({
  taskContainer: {
    display: ' flex',
    alignItems: 'center',
    width: '80%',
    margin: 'auto',
    height: '48px',
    backgroundColor: Colors.GREY_20,
    marginTop: '4px',
  },
  disabledTask: {
    color: Colors.GREY_40,
    '& svg': {
      opacity: 0.5,
    },
  },
  taskContent: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '56px 5fr 1.5fr 48px',
    alignItems: 'center',
    '& $taskStatus::first-letter, $taskName::first-letter': {
      textTransform: 'capitalize',
    },
  },
  taskContentWithTimestamp: {
    gridTemplateColumns: '56px 5fr 1.5fr 1.5fr 48px',
  },
  taskName: {
    marginRight: theme.spacing(6),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'box',
    lineClamp: 2,
    boxOrient: 'vertical',
  },
  taskStatus: {
    marginRight: theme.spacing(3),
  },
  taskLastModifiedAt: {
    marginRight: theme.spacing(3),
    gridColumn: '4 / span 2',
  },
  fullWidth: {
    width: '100%',
  },
}));
