import React, { useCallback, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ArrowDropdownIcon from '@mui/icons-material/ArrowDropDown';
import SignoutIcon from '@mui/icons-material/ExitToApp';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import { UserProfileType } from 'client/app/hooks/useUserProfile';
import { getScreenInfo } from 'client/app/lib/nav/mainNav';
import { ScreenRegistry, TOP_NAV_MENU_ID } from 'client/app/registry';
import { APP_VERSION, COMMIT_SHA } from 'client/app/version';
import Copyright from 'common/ui/components/Copyright';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import MenuWithArrow from 'common/ui/components/MenuWithArrow';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import Tooltip from 'common/ui/components/Tooltip';
import { topNavStyles } from 'common/ui/components/TopNav/topNavStyles';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  userProfile: UserProfileType;
  // TODO: remove useAvatar as Breadcrumbs are fully implemented SYN-4995
  useAvatar?: boolean;
};

export default React.memo(function UserDetailsMenu({ userProfile, useAvatar }: Props) {
  const classes = useStyles();
  const [showUserDetails, setShowUserDetailsAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const handleShowUserDetailsMenu = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      logEvent('show-user-details', TOP_NAV_MENU_ID);
      setShowUserDetailsAnchorEl(event.currentTarget);
    },
    [],
  );
  const handleCloseUserDetailsMenu = useCallback(() => {
    setShowUserDetailsAnchorEl(null);
  }, []);
  const { logout } = useAuth0();

  const handleLogout = useCallback(() => {
    logEvent('logout', TOP_NAV_MENU_ID);
    logout({ returnTo: window.location.href });
  }, [logout]);

  const userInitials = userProfile
    ? userProfile.firstName.substr(0, 1) + userProfile.lastName.substr(0, 1)
    : '';

  const { currentScreenID, getScreenPath } = useNavigation();
  const screenInfo = getScreenInfo(currentScreenID);

  const handleNavigateMenuItem = useCallback(() => {
    setShowUserDetailsAnchorEl(null);
  }, []);

  return (
    <>
      {useAvatar ? (
        <Stack
          data-heap-tracking="breadcrumbs-user-details-menu"
          direction="row"
          sx={{ cursor: 'pointer', paddingRight: 4 }}
          onClick={handleShowUserDetailsMenu}
        >
          <Tooltip title="Profile">
            <Avatar className={classes.avatar}>{userInitials}</Avatar>
          </Tooltip>
          <ArrowDropdownIcon />
        </Stack>
      ) : (
        <Tooltip title="Profile">
          <ListItem
            className={cx(
              classes.btnWithDropdown,
              classes.selectedLink,
              classes.profileBtn,
            )}
            button
            disableRipple
            onClick={handleShowUserDetailsMenu}
            selected={screenInfo.category === 'Profile'}
          >
            <ListItemText disableTypography className={classes.bold}>
              {userInitials}
            </ListItemText>
            {/* Adjusted viewbox to have arrow closer to icon */}
            <ArrowDropdownIcon viewBox="3 0 24 24" fontSize="small" />
          </ListItem>
        </Tooltip>
      )}
      {showUserDetails && (
        <MenuWithArrow
          anchorEl={showUserDetails}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={!!showUserDetails}
          onClose={handleCloseUserDetailsMenu}
          classes={{ paper: classes.popover }}
          elevation={4}
          arrowRightOffsetPx={20}
        >
          <div className={classes.dropdownContent}>
            <Typography variant="body2">{userProfile.displayName}</Typography>
            <Typography variant="caption">
              {userProfile.organisationHumanIdentifier}
            </Typography>
            <Divider />
            <Typography variant="caption">Synthace {APP_VERSION} </Typography>
            {userProfile.isSynthaceEmployee && (
              <Typography variant="caption">{COMMIT_SHA.substring(0, 10)}...</Typography>
            )}
            <Copyright />
            <Divider />
          </div>
          <NavLink to={getScreenPath(ScreenRegistry.PROFILE)} className={classes.link}>
            <MenuItemWithIcon
              icon={<AccountCircle className={classes.iconInsideMenu} />}
              text="Profile"
              onClick={() => handleNavigateMenuItem()}
            />
          </NavLink>
          <MenuItemWithIcon
            icon={<SignoutIcon className={classes.iconInsideMenu} />}
            onClick={handleLogout}
            text="Sign out"
          />
        </MenuWithArrow>
      )}
    </>
  );
});

const useStyles = makeStylesHook(theme => ({
  ...topNavStyles(theme),
  profileBtn: {
    gap: '18px',
  },
  avatar: {
    height: '24px',
    width: '24px',

    fontSize: '12px',
    fontWeight: 400,

    backgroundColor: theme.palette.primary.main,
  },
}));
