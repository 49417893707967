import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_ENTITIES_COUNT } from 'client/app/api/gql/queries';

type EntitiesCount = {
  workflows: number;
  doeWorkflows: number;
  incomingWorkflows: number;
  executions: number;
};

/**
 * Return the individual count of workflows, executions, shared workflows
 * and DOE workflows. Useful to e.g. show a "No workflows" message to users.
 */
export function useEntitiesCount(): EntitiesCount | undefined {
  const { data } = useQuery(QUERY_ENTITIES_COUNT);
  const entitiesCount = useMemo(() => {
    if (!data) {
      return;
    }

    const { workflows, doeWorkflows, incomingWorkflows, executions } = data.entitiesCount;
    return { workflows, doeWorkflows, incomingWorkflows, executions };
  }, [data]);

  return entitiesCount;
}
