import React from 'react';

import { useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';

import { MUTATION_DELETE_EXPERIMENT } from 'client/app/apps/experiments/gql/mutations';
import { ArrayElement, ExperimentsQuery } from 'client/app/gql';
import { experimentsRoutes } from 'client/app/lib/nav/actions';
import { workTreeRoutes } from 'client/app/lib/nav/actions';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { EntityCard } from 'common/ui/components/EntityCard';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import { ExampleGalleryIcon } from 'common/ui/icons/ExampleGalleryIcon';
import { ExperimentMapIcon } from 'common/ui/icons/ExperimentMapIcon';
import { NewExperimentsIcon } from 'common/ui/icons/NewExperimentsIcon';
import { cacheEvict } from 'common/utils';

type Experiment = ArrayElement<ExperimentsQuery['experiments']['items']>;

export function ExperimentCard({
  experiment,
  isLink,
  onClick,
  allowDelete,
  showMenuPlaceholder,
  disabled,
  highlighted,
  variant = 'default',
}: {
  experiment: Experiment;
  isLink?: boolean;
  onClick?: (experimentId: ExperimentId) => void;
  allowDelete?: boolean;
  showMenuPlaceholder?: boolean;
  disabled?: boolean;
  highlighted?: boolean;
  variant?: 'default' | 'example';
}) {
  const classes = useStyles();
  const link = isLink
    ? experimentsRoutes.detail.getPath({ id: experiment.id })
    : undefined;

  const workTreeLink = isLink
    ? workTreeRoutes.workTreeExperiment.getPath({ experimentId: experiment.id })
    : undefined;

  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

  const [deleteExperiment] = useMutation(MUTATION_DELETE_EXPERIMENT);

  const handleDelete = async () => {
    const isDeleteConfirmed = await openConfirmationDialog({
      action: 'delete',
      isActionDestructive: true,
      object: 'experiment',
      specificObject: experiment.name,
    });
    if (isDeleteConfirmed) {
      await deleteExperiment({
        variables: { id: experiment.id },
        optimisticResponse: { __typename: 'Mutation', deleteExperiment: experiment.id },
        update: cache =>
          cacheEvict({ id: experiment.id, __typename: 'Experiment' }, cache),
      });
    }
  };

  const actionButton = (
    <IconButton
      className={classes.iconButton}
      size="xsmall"
      icon={<ExperimentMapIcon fontSize="small" />}
      disabled={disabled}
    />
  );

  let actionItem = undefined;
  if (isLink) {
    actionItem =
      disabled || !workTreeLink ? (
        actionButton
      ) : (
        <Tooltip title="Go to Map">
          <Link data-heap-tracking="entity-card-WORK_TREE" to={workTreeLink}>
            {actionButton}
          </Link>
        </Tooltip>
      );
  }

  return (
    <>
      <EntityCard
        heapTrackingLabel="EXPERIMENT"
        entityName="Experiment"
        icon={
          variant === 'example' ? (
            <ExampleGalleryIcon />
          ) : (
            <NewExperimentsIcon fontSize="small" />
          )
        }
        name={experiment.name}
        author={experiment.createdBy.displayName}
        date={new Date(experiment.lastModifiedAt)}
        link={link}
        linkTitle="View Experiment"
        onClick={() => onClick?.(experiment.id)}
        menu={
          isLink && variant !== 'example'
            ? [{ label: 'Delete', onClick: handleDelete }]
            : undefined
        }
        disableMenu={!allowDelete}
        hideStatusColumn={isLink}
        showMenuPlaceholder={showMenuPlaceholder}
        disabled={disabled}
        isSelected={highlighted}
        action={actionItem}
      />
      {confirmationDialog}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  iconButton: {
    color: theme.palette.text.primary,
  },
}));
