import React from 'react';

import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

import Colors from 'common/ui/Colors';

const StyledTooltip = withStyles(theme => ({
  arrow: {
    color: Colors.GREY_0,
  },
  tooltip: {
    backgroundColor: Colors.GREY_0,
    // Applying a box-shadow using theme shadows (i.e. theme.shadows[4] for elevation 4) is tricky because the box-shadow
    // is not displayed correctly to the arrow element. This is due to the arrow having a constrained parent size due to
    // the property overflow:hidden and not being able to display the full box-shadow, so it gets cutoff. So, we hardcode a drop-shadow here that creates a shadow for all elements.
    filter: `drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2)) drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))`,
    padding: theme.spacing(3),
  },
}))(MuiTooltip);

/**
 * Styled Tooltip with a light background to be used in places where
 * multiple lines of information need to be presented to the user.
 *
 * Content for the tooltip should be passed in via the title prop and can be
 * formatted using the PopoverSection component:
 *
 *  <Popover title={<PopoverSection header='My Header' text='MyText' />} />
 */
export default function Popover(props: Omit<TooltipProps, 'arrow'>) {
  return <StyledTooltip {...props} arrow />;
}

/**
 * Formats a header and text that can be used in the WellTooltip.
 */
export function PopoverSection({ header, text }: { header?: string; text: string }) {
  return (
    <div>
      {header && (
        <Typography style={{ fontWeight: 'bold' }} color="textPrimary" variant="caption">
          {header}: &nbsp;
        </Typography>
      )}
      <Typography color="textPrimary" variant="caption">
        {text}
      </Typography>
    </div>
  );
}
