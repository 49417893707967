import React, { useContext, useMemo } from 'react';

import ComputationResult from 'client/app/apps/simulation-details/overview/ComputationResult';
import { isComputationalSimulation } from 'client/app/apps/simulation-details/overview/isComputationalSimulation';
import FilesSidebar, {
  FilesSidebarSection,
} from 'client/app/apps/simulation-details/overview/LeftSidebar';
import ResourcesOverview from 'client/app/apps/simulation-details/overview/ResourcesOverview';
import SimulationErrorScreen from 'client/app/apps/simulation-details/overview/SimulationErrorScreen';
import { SimulationQuery } from 'client/app/gql';
import { SimulationOrExecutionStatusesEnum } from 'client/app/gql';
import { Deck } from 'common/types/mix';
import { PlateType } from 'common/types/plateType';
import { getDeckItemStates } from 'common/ui/components/simulation-details/mix/deckContents';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { RouteHiddenContext } from 'common/ui/lib/router/RouteHiddenContext';

type Props = {
  simulation: SimulationQuery['simulation'];
  deck?: Deck;
  plateTypes: readonly PlateType[];
};

/** Sections to show in files sidebar if this is a liquid handling Simulation. */
const FILES_SIDEBAR_SECTIONS_LIQUID_HANDLING = new Set<FilesSidebarSection>([
  'visualization_links',
  'output_plates',
  'element_outputs',
  'device_instructions',
  'reagents',
  'debugging',
]);
/** Sections to show in files sidebar if this is a computational Simulation. */
const FILES_SIDEBAR_SECTIONS_COMPUTATIONAL = new Set<FilesSidebarSection>([
  'element_outputs',
  'device_instructions',
  'debugging',
]);

export default function OverviewScreen({ simulation, deck, plateTypes }: Props) {
  const classes = useStyles();

  const deckItems = useMemo(() => deck && getDeckItemStates(deck), [deck]);

  const hiddenContext = useContext(RouteHiddenContext);
  if (hiddenContext.hidden) {
    // Don't unnecessarily render the large React tree when
    // we are on a different tab of the Simulation Details.
    return null;
  }

  /** Users might be able to visit a simulation which was not
   *  finalised yet (T2950). Show them a meaningful error page.
   */
  if (simulation.transitiveStatus === SimulationOrExecutionStatusesEnum.UNINTERESTING) {
    return (
      <div className={classes.overviewScreen}>
        <SimulationErrorScreen
          heading="This simulation is not available at the moment."
          messageList={['Please refresh the page']}
          simulation={simulation}
        />
      </div>
    );
  } else if (
    simulation.transitiveStatus === SimulationOrExecutionStatusesEnum.SIMULATION_FAILED
  ) {
    return (
      <div className={classes.overviewScreen}>
        <SimulationErrorScreen simulation={simulation} />
      </div>
    );
  } else {
    const isComputational = isComputationalSimulation(simulation.tasks);

    let overviewScreenContents: React.ReactNode;

    if (isComputational) {
      overviewScreenContents = <ComputationResult simulation={simulation} />;
    } else {
      overviewScreenContents = (
        <ResourcesOverview
          deckItems={deckItems}
          plateTypes={plateTypes}
          simulation={simulation}
        />
      );
    }

    return (
      <div className={classes.overviewScreen}>
        <FilesSidebar
          simulation={simulation}
          deck={deck}
          sections={
            isComputational
              ? FILES_SIDEBAR_SECTIONS_COMPUTATIONAL
              : FILES_SIDEBAR_SECTIONS_LIQUID_HANDLING
          }
        />
        {overviewScreenContents}
      </div>
    );
  }
}

const useStyles = makeStylesHook({
  overviewScreen: {
    display: 'grid',
    justifyContent: 'flex-start',
    gridTemplateColumns: 'auto 1fr',
    flex: 1,
    overflowY: 'scroll',
  },
});
