import React from 'react';

import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import ResourcesCard from 'client/app/apps/simulation-details/overview/ResourcesCard';
import { CardSection } from 'client/app/apps/simulation-details/overview/Types';
import { arrayIsFalsyOrEmpty } from 'common/lib/data';
import Colors from 'common/ui/Colors';
import { SMART_WORD_BREAK_STYLE } from 'common/ui/commonStyles';
import ClickableTooltipIcon from 'common/ui/components/ClickableTooltipIcon';
import Popover from 'common/ui/components/Popover';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  header: string;
  sections: CardSection[];
};

export default function ResourcesCardWithList({ sections, header }: Props) {
  const classes = useStyles();

  const getSectionsToRender = () => {
    if (arrayIsFalsyOrEmpty(sections)) {
      return null;
    }
    return (
      sections
        // Don't show sections if they are not needed for this simulation
        .filter(section => section.sectionItems.length > 0)
        .flatMap(section => {
          const items = section.sectionItems.map(item => {
            const key = `${section.sectionHeader}-${item.primaryLabel}`;

            if (section.sectionHeader === 'Tips') {
              return (
                <TipInfo
                  key={key}
                  classes={classes}
                  tipType={item.primaryLabel}
                  tipUsage={item.secondaryLabels}
                  tipMaxVolume={item.tertiaryLabels}
                />
              );
            }

            return (
              <div key={key} className={classes.cardListItem}>
                <div className={classes.tooltipAndLabel}>
                  {item.tooltipContent && (
                    <ClickableTooltipIcon
                      className={classes.toolTipIcon}
                      tooltipContent={item.tooltipContent}
                      iconButtonProps={{
                        size: 'xsmall',
                        icon: <HelpIcon />,
                      }}
                    />
                  )}
                  <span className={classes.itemPrimaryLabel}>{item.primaryLabel}</span>
                  {item.popoverContent && (
                    <Popover title={item.popoverContent}>
                      <MoreIcon className={classes.moreIcon} />
                    </Popover>
                  )}
                </div>
                <div className={classes.itemSecondaryLabel}>
                  {item.secondaryLabels}
                  {item.tertiaryLabels && (
                    <span className={classes.itemTertiaryLabel}>
                      {` ` + item.tertiaryLabels}
                    </span>
                  )}
                </div>
              </div>
            );
          });
          if (section.sectionHeader) {
            return [
              <div key={section.sectionHeader} className={classes.sectionHeader}>
                {section.sectionHeader}
              </div>,
              ...items,
            ];
          } else {
            // No header
            return items;
          }
        })
    );
  };

  return <ResourcesCard header={header}>{getSectionsToRender()}</ResourcesCard>;
}

type TipInfoProps = {
  classes: ReturnType<typeof useStyles>;
  tipType: string;
  tipUsage: string[];
  tipMaxVolume?: string[];
};
function TipInfo(props: TipInfoProps) {
  const { tipType, tipUsage, tipMaxVolume, classes } = props;

  return (
    <div className={cx(classes.cardListItem, classes.tipListItem)}>
      <div>
        <Typography variant="subtitle2" color="textPrimary" gutterBottom>
          Type:
        </Typography>
        {tipType}
      </div>
      <div className={classes.tipNumbers}>
        <Typography variant="subtitle2" color="textPrimary" gutterBottom>
          Number:
        </Typography>
        {tipUsage}
      </div>
      {tipMaxVolume && (
        <div>
          <Typography variant="subtitle2" color="textPrimary" gutterBottom>
            Max volume:
          </Typography>
          {tipMaxVolume}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  sectionHeader: {
    fontWeight: 400,
    '&:not(:first-child)': {
      marginTop: '24px',
    },
  },
  cardListItem: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
    backgroundColor: Colors.GREY_20,
    padding: theme.spacing(3),
    margin: theme.spacing(3, 0),
  },
  tooltipAndLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  toolTipIcon: {
    marginRight: theme.spacing(2),
  },
  moreIcon: {
    background: Colors.GREY_20,
    borderRadius: '50%',
  },
  itemPrimaryLabel: {
    display: 'inline-block',
    paddingRight: '8px',
    alignSelf: 'center',
    ...SMART_WORD_BREAK_STYLE,
  },
  itemSecondaryLabel: {
    textAlign: 'center',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(3),
  },
  itemTertiaryLabel: {
    color: theme.palette.text.secondary,
  },
  tipListItem: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    rowGap: theme.spacing(4),
  },
  tipNumbers: {
    textAlign: 'right',
  },
}));
