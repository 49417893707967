import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import { deviceFromGraphQL } from 'client/app/api/deviceFromGraphql';
import { QUERY_ALL_DEVICES } from 'client/app/api/gql/queries';
import DeviceItems from 'client/app/apps/workflow-builder/panels/workflow-settings/devices/DeviceItems';
import { useWorkflowSettingsState } from 'client/app/apps/workflow-builder/panels/workflow-settings/workflowSettingsState';
import { useDevices } from 'client/app/components/DeviceLibrary/useDevices';
import {
  addAccessibleDevice,
  removeAccessibleDevice,
} from 'client/app/lib/workflow/deviceConfigUtils';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import { mapObject } from 'common/object';
import { WorkflowDeviceConfiguration } from 'common/types/bundle';
import { Device, SimpleDevice } from 'common/types/device';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * This component is used for showing the selected devices and a button to open the SelectDevicesPanel
 * to allow the user to select the devices. This differs from DeviceSelector which opens up a dialog when
 * you are selecting devices.
 *
 * TODO: Handle missing device + missing run config.
 *
 */
export default function DeviceSelectorCard() {
  const classes = useStyles();
  const { additionalPanel, deviceConfiguration, isReadonly } = useWorkflowSettingsState();
  const dispatch = useWorkflowBuilderDispatch();
  // We need to fetch all of the devices because the device that we are currently getting from the
  // workflow doesn't have all the info that we need to show the device item (e.g. the image url).
  // Most customers have a small number of devices so it is okay that we are doing a query for all
  // devices.
  const { data, loading } = useQuery(QUERY_ALL_DEVICES);
  const selectedDevices = useMemo<Device[]>(() => {
    return (
      data?.devices
        .filter(device => !!deviceConfiguration[device.id])
        .map(deviceFromGraphQL) ?? []
    );
  }, [data?.devices, deviceConfiguration]);

  const { data: allDevices } = useDevices();
  const onSelectedDevicesChange = useCallback(
    (deviceConfiguration: WorkflowDeviceConfiguration) => {
      /**
       * GraphQL `anthaLangDeviceClass` is very specific, e.g. "HamiltonMicrolabSTAR" or "TecanLiquidHandler".
       * When saving that to the WorkflowBuilderStateContext, we convert it to "Hamilton" or "Tecan",
       * so information is lost. This causes problems when we update the worklfow because
       * we filter devices by string matching the anthaLangDeviceClass, so having
       * HamiltonMicrolabSTAR !== Hamilton will result in the device being filtered out.
       * Thus, we must retrieve the full anthaLangDeviceClass.
       * TODO This code can go away once the anthaLangDeviceClass is removed.
       * https://synthace.atlassian.net/browse/CA-46
       */
      const updatedDeviceConfiguration = mapObject(
        deviceConfiguration,
        (deviceId, device) => {
          const anthaLangDeviceClass =
            allDevices?.devices?.find(d => d.id === deviceId)?.model
              .anthaLangDeviceClass || device.anthaLangDeviceClass;

          return {
            ...device,
            anthaLangDeviceClass,
          };
        },
      );
      dispatch({
        type: 'setSelectedDevices',
        payload: updatedDeviceConfiguration,
      });
    },
    [allDevices, dispatch],
  );

  const handleToggleDeviceSelectorPanel = useCallback(() => {
    dispatch({
      type: 'setAdditionalPanel',
      payload: additionalPanel === 'DeviceSelector' ? undefined : 'DeviceSelector',
    });
  }, [dispatch, additionalPanel]);

  const handleAccessibleDeviceEnabledChange = useCallback(
    (accessibleDevice: SimpleDevice, isEnabled: boolean) => {
      if (!isEnabled) {
        const deviceSelectionMinusAccessibleDevice = removeAccessibleDevice(
          deviceConfiguration,
          accessibleDevice.id,
        );
        onSelectedDevicesChange?.(deviceSelectionMinusAccessibleDevice);
      } else {
        const parentDevice = selectedDevices.find(device =>
          device.accessibleDevices.some(a => a.id === accessibleDevice.id),
        );
        if (!parentDevice) {
          // TODO(CI-1299): Decide if we should be logging this in a better way.
          console.error(
            `Trying to enable an accessible device ${accessibleDevice.id} ` +
              'but there is no corresponding parent device in the workflow config. ' +
              'This can only happen if the workflow config is in a bad state due to a bug.',
          );
          return;
        }
        const deviceConfigurationPlusAccessibleDevice = addAccessibleDevice(
          deviceConfiguration,
          accessibleDevice,
          parentDevice.id,
        );
        onSelectedDevicesChange?.(deviceConfigurationPlusAccessibleDevice);
      }
    },
    [deviceConfiguration, onSelectedDevicesChange, selectedDevices],
  );

  const isEditable = !isReadonly && !!onSelectedDevicesChange;
  const areDevicesSelected = Object.keys(selectedDevices).length > 0;

  return (
    <>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <>
          <DeviceItems
            devices={selectedDevices}
            deviceConfiguration={deviceConfiguration}
            isEditable={isEditable}
            onAccessibleDeviceEnabledChange={
              (isEditable && handleAccessibleDeviceEnabledChange) || undefined
            }
          />
          {!isReadonly && (
            <div className={classes.deviceButton}>
              <Button
                className={classes.smallButton}
                color="primary"
                variant="secondary"
                onClick={handleToggleDeviceSelectorPanel}
                size="small"
              >
                {`${areDevicesSelected ? 'Change' : 'Select'} Execution Mode`}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  deviceButton: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
  },
  message: {
    margin: theme.spacing(3, 0),
  },
  smallButton: {
    height: '24px',
  },
}));
