import { Draft } from 'immer';
import { v4 as uuid } from 'uuid';

import {
  addGroupPadding,
  dimensionsToGroupMeta,
  ELEMENT_INSTANCE_WIDTH,
  getBoundingBox,
  getGroupDimensions,
  getLayoutDimensions,
} from 'client/app/lib/layout/LayoutHelper';
import { Connection, ElementInstance, Group } from 'common/types/bundle';
import { Dimensions } from 'common/types/Dimensions';

/**
 * Helper method to remove a set of elements from all existing groups. This is useful
 * for operations that modify elements' group membership, such as creating a new group
 * from a selection of existing elements.
 */
export function removeElementsFromGroups(groups: Draft<Group[]>, elementIds: string[]) {
  const elementIdSet = new Set(elementIds);

  groups.forEach(group => {
    // Find the list of ids of elements in this group that are *not* in the list to remove.
    const unmatchedElementIds = group.elementIds.filter(id => !elementIdSet.has(id));

    // If the list sizes don't match, then some element(s) need to be removed.
    if (group.elementIds.length !== unmatchedElementIds.length) {
      group.elementIds = unmatchedElementIds;
    }
  });
}

/**
 * Gets the next unused group name of the form "Group x" where x is a positive integer.
 */
export function getNextAvailableGroupName(groups: Group[], count = 1): string {
  if (groups.some(group => group.name === `Group ${count}`)) {
    return getNextAvailableGroupName(groups, count + 1);
  }

  return `Group ${count}`;
}

export function getSelectedElementsForGroup(
  elementInstances: ElementInstance[],
  selectedObjectIds: string[],
) {
  // Get the list of selected element instances
  const ids = new Set(selectedObjectIds);
  return elementInstances.filter(ei => ids.has(ei.Id));
}

export function createElementGroup(
  selectedElements: ElementInstance[],
  elementGroups: Group[],
  elementInstances: ElementInstance[],
  connections: Connection[],
): Group {
  // To create a new group from a selection, we must determine a bounding box
  // that emcompasses the selected elements and use it (with some padding) as
  // the size of the created group.
  const dimensions = addGroupPadding(getLayoutDimensions(selectedElements, connections));

  const elementIds = selectedElements.map(el => el.Id);

  // Some of the elements already belong to a group, so we need to remove them from these groups.
  removeElementsFromGroups(elementGroups, elementIds);

  return {
    id: uuid(),
    elementIds,
    name: getNextAvailableGroupName(elementGroups),
    Meta: dimensionsToGroupMeta(dimensions),
  };
}

/**
 * Creates new empty group of size of mouse area selected by user
 */
export function createEmptyElementGroup(
  { left: x, top: y, width, height }: Dimensions,
  groups: Group[],
): Group | null {
  if (width < ELEMENT_INSTANCE_WIDTH || height < ELEMENT_INSTANCE_WIDTH) return null;

  return {
    id: uuid(),
    name: getNextAvailableGroupName(groups),
    elementIds: [],
    Meta: {
      x,
      y,
      width,
      height,
    },
  };
}

/**
 * Update the group membership of a set of elements, removing them from their
 * current group and optionally adding them to a new a group.
 */
export function updateElementGroupMembership(
  elementIds: string[],
  newGroup: Draft<Group> | null,
  allGroups: Draft<Group>[],
  allElements: ElementInstance[],
  connections: Connection[],
) {
  for (const elementId of elementIds) {
    const oldGroup = allGroups.find(group => group.elementIds.includes(elementId));

    if (oldGroup !== newGroup) {
      if (newGroup) {
        newGroup.elementIds.push(elementId);
      }

      if (oldGroup) {
        oldGroup.elementIds = oldGroup.elementIds.filter(id => id !== elementId);
      }
    }
  }

  if (newGroup) {
    // Since it has new elements, we resize the new group to contain them all.
    resizeGroup(newGroup, allElements, connections);
  }
}

/**
 * Resize a group to fit its contents.
 */
export function resizeGroup(
  group: Draft<Group>,
  allElements: ElementInstance[],
  connections: Connection[],
  allowShrink = false,
) {
  const boxesToMeasure: Dimensions[] = [];

  const elements = allElements.filter(ei => group.elementIds.includes(ei.Id));

  if (elements.length > 0) {
    // This is the box that comfortably wraps all the child elements.
    const elementsBoundingBox = addGroupPadding(
      getLayoutDimensions(elements, connections),
    );

    boxesToMeasure.push(elementsBoundingBox);
  } else {
    // If the group has no contents, provide a minimum size.
    boxesToMeasure.push({
      top: group.Meta.y,
      left: group.Meta.x,
      width: ELEMENT_INSTANCE_WIDTH,
      height: ELEMENT_INSTANCE_WIDTH,
    });
  }

  if (!allowShrink) {
    // If we don't allow shrinking, we include the group's current dimensions,
    // So the output has to at least cover that.
    boxesToMeasure.push(getGroupDimensions(group));
  }

  // The new group area is that which wraps all the boxes.
  const newDimensions = getBoundingBox(boxesToMeasure);

  group.Meta = {
    ...group.Meta,
    ...dimensionsToGroupMeta(newDimensions),
  };
}

/**
 * Checks whether a given ID can be deselected. Elements whose group is
 * selected should not be deselected, as this results in undesirable UI behaviour.
 */
export function canDeselectObject(
  id: string,
  selectedObjectSet: Set<string>,
  elementInstances: ElementInstance[],
  elementGroups: Group[],
) {
  const element = elementInstances.find(ei => ei.Id === id);

  if (element) {
    const group = elementGroups.find(group => group.elementIds.includes(element.Id));

    if (group && selectedObjectSet.has(group.id)) {
      return false;
    }
  }

  return true;
}
