import _keys from 'lodash/keys';
import _memo from 'lodash/memoize';
import _omit from 'lodash/omit';

import {
  AllDevicesQuery,
  ArrayElement,
  DeviceCommonFragment as DeviceCommon,
} from 'client/app/gql';
import { arrayIsFalsyOrEmpty, indexBy, isFalsyOrEmptyObject } from 'common/lib/data';
import { filterObject, mapObject } from 'common/object';
import {
  getDeviceConfigs,
  WORKFLOW_CONFIG_DEVICE_KEYS,
  WorkflowConfig,
  WorkflowConfigDeviceClassKey,
  WorkflowDeviceConfiguration,
} from 'common/types/bundle';
import {
  deviceClassMatchesAnthaConfigKey,
  getDeviceClassByAnthaConfigKey,
} from 'common/types/bundleTransforms';
import { Device, SimpleDevice, TipType } from 'common/types/device';

/**
 * When config is set, it requires tweaking some fields. I wish this method could be specific
 * to an actual action (e.g. to setting some specific properties), but it is used by `setConfig`
 * WorkflowBuilderAction which is called in three places, some of them probably not needing any
 * of this logic.
 */
export function updateConfigAfterSet(config: WorkflowConfig): WorkflowConfig {
  const updatedWorkflowConfig = { ...config };
  // Set inputPlateTypes and tipTypes for all of the devices.
  // This is temporary while the config UI stores inputPlateTypes and tipTypes
  // at the top level (in GlobalMixer).
  for (const workflowConfigDeviceClassKey of WORKFLOW_CONFIG_DEVICE_KEYS) {
    const devicesOfClass = updatedWorkflowConfig[workflowConfigDeviceClassKey]?.Devices;
    if (!devicesOfClass) {
      continue;
    }
    updatedWorkflowConfig[workflowConfigDeviceClassKey] = {
      ...updatedWorkflowConfig[workflowConfigDeviceClassKey],
      Devices: mapObject(devicesOfClass, (_deviceId, deviceParams) => ({
        ...deviceParams,
        inputPlateTypes: config.GlobalMixer.inputPlateTypes,
        tipTypes: config.GlobalMixer.tipTypes,
      })),
    };
  }
  return updatedWorkflowConfig;
}

/**
 * Filter out unsupported tip types or reset if no supported tip types provided.
 */
export function buildGlobalTipTypes(
  config: WorkflowConfig['GlobalMixer'],
  supportedTipTypes: TipType[] | undefined,
) {
  if (!supportedTipTypes || supportedTipTypes.length === 0) return [];
  const supportedTipTypeNames = supportedTipTypes.map(tt => tt.name);
  return config.tipTypes?.filter(tipType => supportedTipTypeNames.includes(tipType));
}

type GraphQLDevice = ArrayElement<AllDevicesQuery['devices']>;

/**
 * Check that all devices in the workflow device configuration actually still exists in the devices list,
 * and removes devices that do not exist anymore.
 */
export function removeMissingDeviceFromDeviceConfiguration(
  deviceConfig: WorkflowDeviceConfiguration,
  devices: readonly GraphQLDevice[],
): WorkflowDeviceConfiguration {
  const filteredDeviceConfig = { ...deviceConfig };
  const devicesKeys = new Set(devices.map(d => d.id as string));
  Object.keys(deviceConfig).forEach(key => {
    if (!devicesKeys.has(key)) {
      delete filteredDeviceConfig[key];
    }
  });
  return filteredDeviceConfig;
}

export function getDeviceConfigurationForUI(
  workflowConfig: WorkflowConfig,
): WorkflowDeviceConfiguration {
  const deviceConfigurationForUI: WorkflowDeviceConfiguration = {};

  for (const configKey of WORKFLOW_CONFIG_DEVICE_KEYS) {
    const devicesOfClass = workflowConfig[configKey]?.Devices;
    if (!devicesOfClass) {
      continue;
    }
    for (const [deviceId, deviceConfig] of Object.entries(devicesOfClass)) {
      deviceConfigurationForUI[deviceId] = {
        runConfigId: deviceConfig.runConfigId || undefined,
        runConfigVersion: deviceConfig.runConfigVersion || undefined,
        anthaLangDeviceClass: getDeviceClassByAnthaConfigKey(configKey),
        accessibleDeviceIds: deviceConfig.accessibleDeviceIds,
      };
    }
  }
  return deviceConfigurationForUI;
}

/**
 * Given a v2 workflow config and a set of selected devices, returns a modified
 * copy of the workflow config.
 */
export function setDevicesInConfig(
  config: WorkflowConfig,
  deviceConfiguration: WorkflowDeviceConfiguration,
): WorkflowConfig {
  // Transform to list because it's easier to work with, and add some
  // extra info we need below
  const deviceConfigHelperList = Object.entries(deviceConfiguration).map(
    ([deviceId, deviceConfigFromDeviceSelector]) => ({
      deviceId,
      anthaLangDeviceClass: deviceConfigFromDeviceSelector.anthaLangDeviceClass,
      workflowDeviceConfig: {
        runConfigId: deviceConfigFromDeviceSelector.runConfigId ?? null,
        runConfigVersion: deviceConfigFromDeviceSelector.runConfigVersion,
        accessibleDeviceIds: deviceConfigFromDeviceSelector.accessibleDeviceIds,
        // Copy the plates and tip settings into each device.
        // We have to do this because the way the UI is currently designed doesn't
        // match 1:1 with how Antha core models configs. The plates and tip types
        // are set in one global place in the UI, and we copy them to all the devices.
        inputPlateTypes: config.GlobalMixer.inputPlateTypes,
        tipTypes: config.GlobalMixer.tipTypes,
      },
    }),
  );
  const updatedWorkflowConfig: WorkflowConfig = { ...config };
  // Reset all of the device configs to what we're given in the WorkflowBuilderAction
  for (const workflowConfigDeviceKey of WORKFLOW_CONFIG_DEVICE_KEYS) {
    // Here are all the configs for key 'Tecan' for example
    const deviceConfigsForKey = deviceConfigHelperList.filter(dc =>
      deviceClassMatchesAnthaConfigKey(dc.anthaLangDeviceClass, workflowConfigDeviceKey),
    );
    if (arrayIsFalsyOrEmpty(deviceConfigsForKey)) {
      // Only store keys for devices that are used in the config
      delete updatedWorkflowConfig[workflowConfigDeviceKey];
    } else {
      updatedWorkflowConfig[workflowConfigDeviceKey] = {
        Devices: Object.fromEntries(
          deviceConfigsForKey.map(({ deviceId, workflowDeviceConfig }) => [
            deviceId,
            workflowDeviceConfig,
          ]),
        ),
      };
    }
  }
  return updatedWorkflowConfig;
}

/**
 * When the user selects devices in the device selector UI, this function
 * is used to build the UI representation of the devices for the workflow config.
 */
export function buildDeviceConfigurationForUI(
  selectedDevices: readonly DeviceCommon[],
  selectedRunConfigs?: { [deviceUUID: string]: string | undefined },
): WorkflowDeviceConfiguration {
  const newSelection: WorkflowDeviceConfiguration = {};
  for (const device of selectedDevices) {
    newSelection[device.id] = {
      runConfigId: selectedRunConfigs?.[device.id] ?? undefined,
      anthaLangDeviceClass: device.model.anthaLangDeviceClass,
    };
    // Say the `device` is a Hamilton liquid handler, which also has some
    // extra accessible devices. Handle this case.
    if (device.accessibleDevices && device.accessibleDevices.length > 0) {
      // The workflow config must list the accessibleDeviceIds for this device.
      // Antha Core needs this field.
      newSelection[device.id].accessibleDeviceIds = device.accessibleDevices.map(
        accessibleDevice => accessibleDevice.id,
      );
      // Also add all accessible devices to the workflow config.
      // Antha Core needs all the devices, including accessible devices, listed.
      for (const accessibleDevice of device.accessibleDevices) {
        newSelection[accessibleDevice.id] = {
          // As of Jun 2020, accessible devices are always plate washers
          // and plate readers which have no run configs.
          // This could change in the future.
          runConfigId: undefined,
          anthaLangDeviceClass: accessibleDevice.model.anthaLangDeviceClass,
        };
      }
    }
  }
  return newSelection;
}

/**
 * Remove an accessible device from the workflow config.
 * Useful when the user toggles the accessible device to OFF.
 */
export function removeAccessibleDevice(
  workflowDeviceConfiguration: WorkflowDeviceConfiguration,
  accessibleDeviceIdToRemove: string,
): WorkflowDeviceConfiguration {
  // Remove the accessible device from the main device list
  const selectionWithDeviceRemovedFromAccessibleIds = mapObject(
    workflowDeviceConfiguration,
    (_deviceId, device) => ({
      ...device,
      accessibleDeviceIds: device.accessibleDeviceIds?.filter(
        id => id !== accessibleDeviceIdToRemove,
      ),
    }),
  );
  // Also completely remove the accessible device from the workflow config
  const selectionWithDeviceRemovedFromEverywhere = filterObject(
    selectionWithDeviceRemovedFromAccessibleIds,
    deviceId => deviceId !== accessibleDeviceIdToRemove,
  );
  return selectionWithDeviceRemovedFromEverywhere;
}

export function addAccessibleDevice(
  workflowDeviceConfiguration: WorkflowDeviceConfiguration,
  accessibleDeviceToAdd: SimpleDevice,
  parentDeviceId: string,
): WorkflowDeviceConfiguration {
  const parentDeviceInSelection = workflowDeviceConfiguration[parentDeviceId];
  if (!parentDeviceInSelection) {
    console.error(
      `Trying to enable an accessible device ${accessibleDeviceToAdd.id} ` +
        `but the parent device ${parentDeviceId} is not in the workflow config. ` +
        'This looks like a bug.',
    );
    return workflowDeviceConfiguration;
  }
  const newSelection = {
    ...workflowDeviceConfiguration,
    // Add the accessible device
    [accessibleDeviceToAdd.id]: {
      // As of Jun 2020, accessible devices are always plate washers
      // and plate readers which have no run configs.
      // This could change in the future.
      runConfigId: undefined,
      anthaLangDeviceClass: accessibleDeviceToAdd.anthaLangDeviceClass,
    },
    // Add the accessible device to the list of accessible devices on the parent
    [parentDeviceId]: {
      ...parentDeviceInSelection,
      accessibleDeviceIds: [
        ...new Set(parentDeviceInSelection.accessibleDeviceIds).add(
          accessibleDeviceToAdd.id,
        ),
      ],
    },
  };
  return newSelection;
}

export function configHasNoDevices(workflowConfig: WorkflowConfig) {
  return isFalsyOrEmptyObject(getDeviceConfigs(workflowConfig));
}

export function configHasNoInputPlates(workflowConfig: WorkflowConfig) {
  const configHasInputPlates =
    workflowConfig &&
    // At least one configured device has input plates set
    Object.values(getDeviceConfigs(workflowConfig)).some(
      deviceConfig =>
        deviceConfig.inputPlateTypes && deviceConfig.inputPlateTypes.length > 0,
    );
  return !configHasInputPlates;
}

function getSelectedDevices(
  allDevices: readonly DeviceCommon[],
  deviceConfiguration: WorkflowDeviceConfiguration,
) {
  return allDevices.filter(d => !!deviceConfiguration[d.id]);
}
export function configHasDeletedDevice(
  allDevices: readonly DeviceCommon[],
  deviceConfiguration: WorkflowDeviceConfiguration,
) {
  const selectedDevices = getSelectedDevices(allDevices, deviceConfiguration);
  return selectedDevices.length < Object.keys(deviceConfiguration).length;
}

/**
 * For each device in the workflow config, if they have a run config check whether their version is outdated.
 */
export function configHasOutdatedDeviceRunConfig(
  allDevices: readonly DeviceCommon[],
  deviceConfiguration: WorkflowDeviceConfiguration,
) {
  const selectedDevices = getSelectedDevices(allDevices, deviceConfiguration);
  const devicesById = indexBy(selectedDevices, 'id');
  return Object.entries(deviceConfiguration).some(([id, device]) => {
    if (
      // the device still exists
      devicesById[id] &&
      // has a config specified
      device.runConfigId
    ) {
      // the config does exist
      const latestRunConfig = devicesById[id].runConfigSummaries.find(
        config => config.id === device.runConfigId,
      );

      return (latestRunConfig?.version ?? 1) > (device.runConfigVersion ?? 1);
    }
    return false;
  });
}

export function configHasDeletedDeviceRunConfig(
  allDevices: readonly DeviceCommon[],
  deviceConfiguration: WorkflowDeviceConfiguration,
) {
  const selectedDevices = getSelectedDevices(allDevices, deviceConfiguration);
  const devicesById = indexBy(selectedDevices, 'id');
  return Object.entries(deviceConfiguration).some(([id, device]) => {
    return (
      // the device still exists
      devicesById[id] &&
      // has a config specified
      device.runConfigId &&
      // but the config does not exist
      !devicesById[id]?.runConfigSummaries
        .map(rc => rc.id as string)
        .includes(device.runConfigId)
    );
  });
}

/**
 * Dispensers behave differently than other liquid handlers
 * (e.g. they do not have a deck options config or the ability to be sent to the lab.)
 *
 */
const DISPENSERS: WorkflowConfigDeviceClassKey[] = [
  'Formulatrix',
  'TTP',
  'Labcyte',
  'CertusFlex',
  'GilsonPipettePilot',
];

export function hasDispenserDevice(config: WorkflowConfig) {
  for (const dispenser of DISPENSERS) {
    const listOfDispensers = config[dispenser]?.Devices;
    if (listOfDispensers && Object.keys(listOfDispensers).length > 0) {
      return true;
    }
  }
  return false;
}

export const hasManualDevice = _memo(function hasManualDevice(config: WorkflowConfig) {
  const requiresDevice = config.GlobalMixer.requiresDevice === true;
  return requiresDevice && Object.keys(config['Manual']?.Devices ?? {}).length > 0;
});

export function hasDispenserOrManualDevice(config: WorkflowConfig) {
  return hasDispenserDevice(config) || hasManualDevice(config);
}

export function isBioReactor(device: DeviceCommon) {
  return device.model?.series?.category?.name === 'Bio Reactors';
}

export function isLiquidHandlingDevice(device: DeviceCommon) {
  return !!device.model?.series?.category?.features?.hasLiquidClasses;
}

export const isDataOnly = _memo(function isDataOnly(config: WorkflowConfig) {
  const noDeviceRequired = config.GlobalMixer.requiresDevice === false;
  const deviceConfigurations = _omit(config, 'GlobalMixer');
  return noDeviceRequired && _keys(deviceConfigurations).length === 0;
});

export function getManualDevice(devices: Device[]): Device | undefined {
  return devices.find(d => d.anthaLangDeviceClass === 'Manual');
}
