import React from 'react';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import DeviceItem from 'client/app/apps/workflow-builder/panels/workflow-settings/devices/DeviceItem';
import { getManualDevice } from 'client/app/lib/workflow/deviceConfigUtils';
import {
  DATA_ONLY_DUMMY_DEVICE,
  MANUAL_DEVICE_DESCRIPTION,
} from 'common/constants/manual-device';
import { WorkflowDeviceConfiguration } from 'common/types/bundle';
import { Device, SimpleDevice } from 'common/types/device';
import Colors from 'common/ui/Colors';
import { groupDevices } from 'common/ui/components/DeviceList';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  devices: Device[];
  deviceConfiguration: WorkflowDeviceConfiguration;
  /**
   * If true, show interactive elements, for example toggles to enable / disable
   * accessible devices.
   * Otherwise this is a simple, purely read-only list of devices.
   */
  isEditable?: boolean;
  onAccessibleDeviceEnabledChange?: (
    accessibleDevice: SimpleDevice,
    isEnabled: boolean,
  ) => void;
};

/*
 * Shows the list of the devices grouped together if there are accessible devices.
 * If there are no groupings (aka no accessible devices), we should show them as standalone devices.
 * Note: This is similar to the DeviceItems in common, but this is specifically being used in
 * Settings Panel. This is a simplification and cleanup of the styling from that component.
 */
export default React.memo(function DeviceItems(props: Props) {
  const classes = useStyles();
  const { devices, deviceConfiguration, isEditable, onAccessibleDeviceEnabledChange } =
    props;

  if (devices.length === 0) {
    return (
      <div className={classes.list}>
        <DeviceItem
          key={DATA_ONLY_DUMMY_DEVICE.id}
          device={DATA_ONLY_DUMMY_DEVICE}
          description="No device needed"
        />
      </div>
    );
  }

  const manualDevice = getManualDevice(devices);
  if (manualDevice) {
    return (
      <div className={classes.list}>
        <DeviceItem
          key={manualDevice.id}
          device={manualDevice}
          description={MANUAL_DEVICE_DESCRIPTION}
        />
      </div>
    );
  }

  const { deviceGroups, standaloneDevices } = groupDevices(
    deviceConfiguration,
    devices,
    isEditable ?? false,
  );

  return (
    <div className={classes.list}>
      {deviceGroups.map(({ mainDevice, accessibleDevicesForMainDevice }) => {
        return (
          <div key={mainDevice.id} className={classes.deviceCard}>
            <DeviceItem device={mainDevice} />
            <Divider className={classes.divider} />
            <div className={classes.accessibleDevicesLabel}>
              <Typography variant="overline" color="textPrimary">
                Accessible devices
              </Typography>
            </div>

            {accessibleDevicesForMainDevice.map(({ accessibleDevice, isEnabled }) => {
              const accessibleDeviceProps =
                isEditable && onAccessibleDeviceEnabledChange
                  ? // If the DeviceList is editable, show a toggle for enabling / disabling
                    // each accessible device
                    {
                      accessibleDeviceSwitchValue: isEnabled,
                      onAccessibleDeviceEnabledChange: (newIsEnabled: boolean) =>
                        onAccessibleDeviceEnabledChange(accessibleDevice, newIsEnabled),
                    }
                  : // In the non-editable case, don't show toggles for accessible devices.
                    undefined;
              return (
                <DeviceItem
                  key={accessibleDevice.id}
                  device={accessibleDevice}
                  accessibleDeviceProps={accessibleDeviceProps}
                />
              );
            })}
          </div>
        );
      })}
      {/* Then, list all standalone devices as a flat list, no grouping. */}
      {standaloneDevices.map(standaloneDevice => {
        return <DeviceItem key={standaloneDevice.id} device={standaloneDevice} />;
      })}
    </div>
  );
});

const useStyles = makeStylesHook(theme => ({
  accessibleDevicesLabel: {
    margin: theme.spacing(5, 0),
  },
  deviceCard: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
  divider: {
    color: Colors.GREY_30,
    margin: theme.spacing(3, 0),
  },
  list: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
}));
