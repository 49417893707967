import React, { ReactElement, ReactNode } from 'react';

import ColumnMenuIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { formatDateTime } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import CardType from 'common/ui/components/CardType';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type EntityCardProps = {
  entityName: string;
  icon: ReactNode;
  star?: ReactNode;
  name: string;
  authorTitle?: string;
  author: string;
  dateTitle?: string;
  date: Date;
  statusIcon?: ReactNode;
  additionalColumnTitle?: string;
  additionalColumnValue?: string;
  link?: string;
  linkTitle?: string;
  isSelected?: boolean;
  /**
   * Additional content appearing to the right of the card.
   */
  action?: ReactElement;
  actionText?: string;
  menu?: { label: string; onClick: (e: React.MouseEvent) => void }[];
  /**
   * Leave a gap on the right of the card where the menu button would be. Useful when the
   * menu is conditionally shown but you don't want the columns moving position.
   */
  showMenuPlaceholder?: boolean;
  /**
   * If the menu is provided, still show it, but in a disabled state.
   */
  disableMenu?: boolean;
  /**
   * Will remove the status column, useful for alignment when the status is not needed.
   */
  hideStatusColumn?: boolean;
  onClick?: () => void;
  /**
   * Show the EntityCard in a disabled state and disable all pointer events for the card
   * and any children.
   */
  disabled?: boolean;
  /**
   * Display a tooltip on hover of the card. Will show even if the card is disabled.
   */
  tooltipTitle?: string;
  heapTrackingLabel?: string;
};

/**
 * Used for displaying information about an entity (workflow, experiment, etc) in the
 * experiment UI and lists of that entity.
 */
export function EntityCard({
  isSelected,
  action,
  menu,
  disableMenu,
  showMenuPlaceholder,
  disabled,
  tooltipTitle = '',
  ...props
}: EntityCardProps) {
  const classes = useStyles();
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLButtonElement | null>(null);
  const closeMenu = () => setMenuAnchor(null);

  return (
    <Tooltip
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -Math.floor(ENTITY_CARD_HEIGHT / 2)],
            },
          },
        ],
      }}
      title={tooltipTitle}
    >
      <Paper
        variant="outlined"
        className={cx(classes.card, {
          [classes.cardSelected]: isSelected,
          [classes.cardWithAction]: action,
          [classes.cardDisabled]: disabled,
          [classes.cardWithActionAndMenu]: action && !!menu,
        })}
      >
        <EntityCardContent
          {...props}
          disabled={disabled}
          className={cx({
            [classes.summaryWithoutMenuOrAction]: !menu && !action,
            [classes.summaryWithMenuPlaceholder]: !menu && showMenuPlaceholder,
          })}
        />
        {action && (
          <div className={classes.action}>
            <Typography variant="overline">{props.actionText || 'Action'}</Typography>
            {action}
          </div>
        )}
        {menu && (
          <>
            <IconButton
              icon={<ColumnMenuIcon />}
              onClick={e => setMenuAnchor(e.currentTarget)}
              className={cx(classes.button, classes.menuButton)}
              disabled={disabled || disableMenu}
            />
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              PaperProps={{ square: false }}
              onClose={closeMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              {menu.map(({ label, onClick }) => (
                <MenuItem
                  key={label}
                  onClick={e => {
                    closeMenu();
                    onClick(e);
                  }}
                >
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </Paper>
    </Tooltip>
  );
}

export function EntityCardContent({
  icon,
  star,
  entityName,
  name,
  authorTitle,
  author,
  dateTitle,
  date,
  statusIcon,
  link,
  linkTitle,
  additionalColumnTitle,
  additionalColumnValue,
  onClick,
  className,
  disabled,
  hideStatusColumn,
  heapTrackingLabel,
}: Omit<EntityCardProps, 'menu' | 'isSelected'> & {
  className?: string;
}) {
  const classes = useStyles();
  return (
    <CardLink
      data-heap-tracking={
        heapTrackingLabel ? `entity-card-${heapTrackingLabel}` : undefined
      }
      to={disabled ? undefined : link}
      title={disabled ? undefined : linkTitle}
      className={cx(
        {
          [classes.link]: !disabled && (onClick || link),
          [classes.cardDisabled]: disabled,
        },
        className,
      )}
      onClick={disabled ? undefined : onClick}
    >
      <dl
        className={cx(classes.summaryGrid, {
          [classes.summaryGridNoStatus]: hideStatusColumn,
        })}
      >
        <CardType className={classes.icon} icon={icon} disabled={disabled} />
        {star && (
          <dd className={classes.star}>
            {disabled ? <div className={classes.disabledOverlay}>{star}</div> : star}
          </dd>
        )}
        <dt className={additionalColumnValue ? classes.nameCol : classes.extendedNameCol}>
          {entityName} name
        </dt>
        <dd className={additionalColumnValue ? classes.nameCol : classes.extendedNameCol}>
          {name}
        </dd>
        {additionalColumnValue && (
          <>
            <dt className={classes.additionalCol}>{additionalColumnTitle}</dt>
            <dd className={classes.additionalCol}>{additionalColumnValue}</dd>
          </>
        )}
        <dt className={classes.authorCol}>{authorTitle ?? 'Author'}</dt>
        <dd className={classes.authorCol}>{author}</dd>
        <dt className={classes.dateCol}>{dateTitle ?? 'Last modified'}</dt>
        <dd className={cx(classes.dateCol, classes.date)}>{formatDateTime(date)}</dd>
        {statusIcon && (
          <>
            <dt className={classes.statusCol}>Status</dt>
            <dd className={cx(classes.statusCol, classes.statusIcon)}>
              {disabled ? (
                <div className={classes.disabledOverlay}>{statusIcon}</div>
              ) : (
                statusIcon
              )}
            </dd>
          </>
        )}
      </dl>
    </CardLink>
  );
}

export function CardLink(props: {
  to?: string;
  title?: string;
  className?: string;
  onClick?: () => void;
  children?: ReactNode;
}) {
  return props.to ? <Link to={props.to} {...props} /> : <div {...props} />;
}

export const ENTITY_CARD_COLUMN_TEMPLATE =
  '[icon] 24px [name] minmax(30%, 1fr) [additional] 120px [author] minmax(60px, 188px) [date] minmax(100px, 140px) [status] 48px [end]';
export const ENTITY_CARD_COLUMN_TEMPLATE_NO_STATUS =
  '[icon] 24px [name] minmax(30%, 1fr) [additional] 120px [author] minmax(60px, 188px) [date] minmax(100px, 140px) [status] 0px [end]';
export const ENTITY_CARD_ROW_TEMPLATE = '24px 24px';
export const ENTITY_CARD_COLUMN_GAP = 5;
export const ENTITY_CARD_LEFT_PADDING = '20px';
export const ENTITY_CARD_RIGHT_PADDING = 6;
export const ENTITY_CARD_Y_PADDING = 5;
export const ENTITY_CARD_HEIGHT = 82;
const CONTEXT_MENU_BUTTON_WIDTH = 48;
const ACTION_WIDTH = 80;

const useStyles = makeStylesHook(theme => ({
  card: {
    borderRadius: '8px',
    display: 'grid',
    gridTemplateColumns: `[summary] 1fr [menu] ${CONTEXT_MENU_BUTTON_WIDTH}px [end]`,
    height: `${ENTITY_CARD_HEIGHT}px`,
  },
  cardWithAction: {
    gridTemplateColumns: `[summary] 1fr [action] ${ACTION_WIDTH}px [end]`,
  },
  cardWithActionAndMenu: {
    gridTemplateColumns: `[summary] 1fr [action] ${ACTION_WIDTH}px [menu] ${CONTEXT_MENU_BUTTON_WIDTH}px [end]`,
  },
  cardSelected: {
    boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
  },
  cardDisabled: {
    color: theme.palette.text.disabled,
  },
  summaryWithoutMenuOrAction: {
    gridColumn: 'summary / end',
  },
  summaryWithMenuPlaceholder: {
    paddingRight: `${CONTEXT_MENU_BUTTON_WIDTH}px`,
  },
  link: {
    display: 'block',
    cursor: 'pointer',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': { backgroundColor: Colors.ACTION_PRIMARY_MAIN_HOVER },
    '&:active': { backgroundColor: Colors.ACTION_PRIMARY_MAIN_ACTIVE },
  },
  menuButton: {
    borderLeft: `1px solid ${Colors.GREY_30}`,
    gridColumn: 'menu',
  },
  button: {
    width: 'auto',
    height: 'auto',
    alignSelf: 'stretch',
    borderRadius: '0',
    '&:hover': { backgroundColor: Colors.ACTION_PRIMARY_MAIN_HOVER },
    '&:active': { backgroundColor: Colors.ACTION_PRIMARY_MAIN_ACTIVE },
  },
  summaryGrid: {
    display: 'grid',
    gridTemplateColumns: ENTITY_CARD_COLUMN_TEMPLATE,
    gridTemplateRows: ENTITY_CARD_ROW_TEMPLATE,
    gap: theme.spacing(0, ENTITY_CARD_COLUMN_GAP),
    padding: theme.spacing(
      ENTITY_CARD_Y_PADDING,
      ENTITY_CARD_RIGHT_PADDING,
      ENTITY_CARD_Y_PADDING,
      ENTITY_CARD_LEFT_PADDING,
    ),
    alignItems: 'center',
    gridAutoFlow: 'column',
    margin: 0,
    // Prevent text overflowing off card. Overflow Y must not be clipped because that
    // would cut off the top of the icon.
    overflowX: 'clip',
    '& > dt': {
      ...theme.typography.overline,
    },
    '& > dd': {
      ...theme.typography.body1,
      margin: 0,
      overflow: 'hidden',
      // Date should not be ellipsized. Instead the time should wrap onto the next line so
      // it's not visible.
      '&:not($date)': {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  summaryGridNoStatus: {
    gridTemplateColumns: ENTITY_CARD_COLUMN_TEMPLATE_NO_STATUS,
  },
  icon: {
    gridColumn: 'icon',
    gridRow: '1',
  },
  star: {
    gridColumn: 'icon',
    gridRow: '1 / span 2',
  },
  nameCol: {
    gridColumn: 'name',
  },
  extendedNameCol: {
    gridColumn: 'name  / author',
  },
  additionalCol: {
    gridColumn: 'additional',
  },
  authorCol: {
    gridColumn: 'author',
  },
  dateCol: {
    gridColumn: 'date',
  },
  date: {
    // When the date column is shrunk the time will wrap onto the next line. Set the
    // height to be 1 line so the time is not visible.
    height: theme.typography.body1.lineHeight,
  },
  statusCol: {
    gridColumn: 'status',
    textAlign: 'center',
  },
  statusIcon: {
    display: 'flex',
    justifyContent: 'center',
  },
  disabledOverlay: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  action: {
    display: 'grid',
    gridTemplateRows: ENTITY_CARD_ROW_TEMPLATE,
    alignItems: 'center',
    justifyItems: 'center',
    marginTop: theme.spacing(ENTITY_CARD_Y_PADDING),
  },
}));
