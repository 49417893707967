import React, { useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';

import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import stopPropagation from 'common/lib/stopPropagation';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import Keys from 'common/ui/lib/keyboard';

type Props = {
  groupId: string;
  groupName: string;
  isGroupHighlighted: boolean;
  focus: boolean;
  onBlur: () => void;
  isReadonly: boolean;
  zoomedOut: boolean;
};

export default function ElementGroupName({
  groupId,
  groupName,
  isGroupHighlighted,
  focus,
  onBlur: loseFocus,
  isReadonly,
  zoomedOut,
}: Props) {
  const classes = useStyles({ isGroupHighlighted });
  const dispatch = useWorkflowBuilderDispatch();

  const [isEditing, setIsEditing] = useState(false);
  const toggleEditing = (event: React.MouseEvent<HTMLElement>) => {
    /**
     * stopPropagation of click and double click onto the canvas so that
     * canvas double click does not deselect elements.
     */
    stopPropagation(event);
    setIsEditing(flag => !flag);
  };

  useEffect(() => {
    if (!isReadonly && focus) {
      setIsEditing(true);
    }
  }, [focus, isReadonly]);

  const { name, error, handleChange } = useGroupName(groupId, groupName);
  /**
   * Highlight all text when editing starts so that user doen't have to remove current text.
   */
  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };
  const handleBlur = () => {
    /**
     * Keep editor open until user input is valid.
     */
    if (error) return;

    setIsEditing(false);
    loseFocus();
    dispatch({
      type: 'updateElementGroup',
      payload: { id: groupId, name: DOMPurify.sanitize(name.trim()) },
    });
  };
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === Keys.ENTER || e.key === Keys.ESCAPE) {
      handleBlur();
    }
  };

  return (
    <div className={classes.elementGroupName} onDoubleClick={stopPropagation}>
      {!isReadonly && isEditing ? (
        <TextField
          variant="standard"
          autoFocus
          fullWidth
          value={name}
          error={!!error}
          helperText={error}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          InputProps={{
            disableUnderline: true,
          }}
        />
      ) : (
        <Typography
          variant={zoomedOut ? 'caption' : 'body1'}
          className={classes.readonlyName}
          onDoubleClick={toggleEditing}
        >
          {groupName}
        </Typography>
      )}
    </div>
  );
}

function useGroupName(groupId: string, groupName: string) {
  const [name, setName] = useState<string>(groupName);
  const [error, setError] = useState<string | null>(null);
  const existGroupName = useExistGroupName(groupId);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    setName(newName);

    if (newName === '') {
      setError('Group name cannot be empty. Please, input a valid name for this group.');
    } else if (existGroupName(newName)) {
      setError(
        'This name already exists. Please, choose some other name for this group.',
      );
    } else {
      setError(null);
    }
  };

  return {
    name,
    error,
    handleChange,
  };
}

function useExistGroupName(groupId: string) {
  const elementGroups = useWorkflowBuilderSelector(state => state.elementGroups);
  /**
   * We don't want to compare new name with the current name of the group.
   * Otherwise, we would not be able to keep the current name without changes.
   */
  return (newName: string) =>
    elementGroups.some(group => group.name === newName && group.id !== groupId);
}

const useStyles = makeStylesHook<string, { isGroupHighlighted: boolean }>(
  ({ palette }) => ({
    elementGroupName: ({ isGroupHighlighted }) => ({
      color: isGroupHighlighted ? palette.primary.main : palette.text.primary,
      flexGrow: 1,
      '& ::selection': {
        backgroundColor: palette.primary.light,
      },
      display: '-webkit-box',
      overflow: 'hidden',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      textOverflow: 'ellipsis',
    }),
    readonlyName: {
      userSelect: 'none',
    },
  }),
);
