import { graphql } from 'common/server/graphql';

export enum ContentSourceType {
  EXAMPLE = 'EXAMPLE',
  USER_GENERATED = 'USER_GENERATED',
}

export const ContentTypeEnum = graphql.Enum(
  'ContentType',
  ContentSourceType,
  'The source of a platform asset',
);
