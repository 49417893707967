import React, { useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';

import IconButton, { IconButtonProps } from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';

type Props = {
  tooltipContent: React.ReactElement;
  iconButtonProps: IconButtonProps;
  className?: string;
};

/**
 * ClickableTooltipIcon returns an IconButton with an onClick handler
 * that displays a tooltip (containing the given tooltipContent prop value)
 * on click, and disappears when the user clicks away.
 *
 * Styling of the IconButton can be overridden with iconButtonProps.
 *
 * @param param0
 */
export default function ClickableTooltipIcon({
  tooltipContent,
  iconButtonProps,
  className,
}: Props) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipIconClick = () => {
    setOpen(previousValue => !previousValue);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={className}>
        <Tooltip
          title={tooltipContent}
          PopperProps={{
            disablePortal: true,
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
        >
          <IconButton
            {...iconButtonProps}
            color="inherit"
            onClick={handleTooltipIconClick}
          />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}
