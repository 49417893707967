import { useCallback, useEffect } from 'react';

import isEqual from 'lodash/isEqual';

import * as WorkflowsApi from 'client/app/api/WorkflowsApi';
import {
  getSerialisedJson,
  LiquidTransfer,
  serialiseLiquids,
  updateCherryPickBundle,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import {
  PlatesByName,
  useCherryPickContext,
} from 'client/app/apps/cherry-picker/CherryPickContext';
import { MigrateWorkflowMutation } from 'client/app/gql';
import { SuccessfullySavedWorkflow } from 'client/app/lib/workflow/SuccessfullySavedWorkflow';
import { WorkflowConfig, WorkflowDeviceConfiguration } from 'common/types/bundle';
import useDebounce from 'common/ui/hooks/useDebounce';

const DEBOUNCE_SAVE_DELAY_MS = 1000;

type Props = {
  loadedWorkflow: MigrateWorkflowMutation['migrateWorkflow']['workflow'] | null;
};

/**
 * This component saves the changes to the Cherry Picker to the DB.
 * Any user action that modifies the Cherry Picker will trigger a
 * debounced auto save.
 */
export default function CherryPickAutoSave({ loadedWorkflow }: Props) {
  const {
    cherryPick,
    platesByName,
    enforceOrder,
    bundleConfig,
    uniqueLiquidNames,
    selectedDevice,
    setLastSavedWorkflow,
    lastSavedWorkflow,
    workflowName,
    setIsSavingWorkflow,
  } = useCherryPickContext();

  // Set the loaded workflow from the DB as the latest saved workflow.
  useEffect(() => {
    if (!loadedWorkflow) {
      return;
    }
    const { version, id, workflow } = loadedWorkflow;

    setLastSavedWorkflow({
      workflowId: id,
      editVersion: version,
      bundle: workflow,
    });
  }, [loadedWorkflow, setLastSavedWorkflow]);

  const updateWorkflow = WorkflowsApi.useUpdateWorkflow();
  const debouncedSave = useDebounce(
    useCallback(
      async (
        lastSavedWorkflow: SuccessfullySavedWorkflow,
        cherryPick: LiquidTransfer[],
        platesByName: PlatesByName,
        uniqueLiquidNames: string[],
        enforceOrder: boolean,
        bundleConfig: WorkflowConfig,
        selectedDevice: WorkflowDeviceConfiguration,
        workflowName: string,
      ) => {
        const serialisedJson = getSerialisedJson(
          cherryPick,
          platesByName,
          serialiseLiquids(uniqueLiquidNames),
          enforceOrder,
          bundleConfig,
        );

        const newBundle = updateCherryPickBundle(
          lastSavedWorkflow,
          workflowName,
          bundleConfig,
          selectedDevice,
          serialisedJson,
        );

        // Safety net: we only save the bundle to the DB if its
        // *values* changed (deep comparison).
        // Avoids getting stuck in am infinite loop.
        if (isEqual(newBundle, lastSavedWorkflow.bundle)) {
          return;
        }

        setIsSavingWorkflow(true);
        const { version: newEditVersion } = await updateWorkflow(
          lastSavedWorkflow.workflowId,
          lastSavedWorkflow.editVersion,
          workflowName,
          newBundle,
        );
        setIsSavingWorkflow(false);

        setLastSavedWorkflow({
          workflowId: lastSavedWorkflow.workflowId,
          editVersion: newEditVersion,
          bundle: newBundle,
        });
      },
      [setIsSavingWorkflow, setLastSavedWorkflow, updateWorkflow],
    ),
    DEBOUNCE_SAVE_DELAY_MS,
  );

  useEffect(() => {
    if (lastSavedWorkflow) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      debouncedSave(
        lastSavedWorkflow,
        cherryPick,
        platesByName,
        uniqueLiquidNames,
        enforceOrder,
        bundleConfig,
        selectedDevice,
        workflowName,
      );
    }
  }, [
    bundleConfig,
    cherryPick,
    debouncedSave,
    enforceOrder,
    // The deep comparison in the debouncedSave prevents
    // this circular dependency to get stuck in an infinite loop
    lastSavedWorkflow,
    platesByName,
    selectedDevice,
    uniqueLiquidNames,
    workflowName,
  ]);

  return null;
}
