import React, { useMemo } from 'react';

import { PlateCard } from 'client/app/components/Parameters/PlateType/PlateCard';
import doNothing from 'common/lib/doNothing';
import { ContentSourceType } from 'common/types/contentSource';
import { PlateType } from 'common/types/plateType';
import CardGrid from 'common/ui/components/CardGrid';
import { getHumanReadableSource } from 'common/ui/components/DeviceCard/DeviceCard';
import NoResultsFound from 'common/ui/components/NoResultsFound';
import useProgressiveList from 'common/ui/hooks/useProgressiveList';

function filterPlates(
  queryWithCase: string | undefined,
  plates: PlateType[],
  source?: string | undefined,
) {
  // NOTE(flooey): This implementation is a bit wasteful, but currently (2019-10-24) rendering
  // the plate grid takes significantly more time than filtering the plates.  If performance
  // becomes an issue, there are significant optimizations we could make here.  In order of
  // least effort/complexity/effectiveness to most, these are probably:
  // 1. Cache the result of toLowerCase() on plate properties so it doesn't have to run
  // for every query
  // 2. Create a single property field that contains the concatenated searchable properties
  // and cache them there
  // 3. Have the server store and send the precomputed searchable property so it doesn't
  // have to be computed on the client side
  // 4. Create a real search index on the server side and serve search results from that
  const query = queryWithCase ? queryWithCase.toLowerCase().split(/\s+/) : [];
  const queryFilteredPlates = plates.filter(plate => {
    // For each token in the query, see if it can be found anywhere on the plate.  We want
    // to only return the plates that match every token in the query, though all tokens need
    // not be found in the same field.
    const targets = [
      plate.name.toLowerCase(),
      plate.type.toLowerCase(),
      plate.description.toLowerCase(),
      plate.catalogNumber.toLowerCase(),
      plate.usage.toLowerCase(),
      plate.manufacturer.toLowerCase(),
    ];
    return (
      query?.every(token => targets.some(t => t.includes(token)))
    );
  });

  // Proceed to filter on content source. If no source is specified,
  // simply return the plate being examined.
  return queryFilteredPlates.filter(plate => {
    if (source) {
      // Convert the plate contentSource value to be human readable
      // and comparable to the string value(s) included in the original query
      const plateSource = getHumanReadableSource(
        plate.contentSource as unknown as ContentSourceType,
      ).humanReadableName;
      return (
        source?.includes(plateSource)
      );
    }
    else {
      return true
    }
  })
}

export type PlatesGridProps = {
  onSelect?: (plate: string) => void;
  onDoubleSelect?: (plate: string) => void;
  query?: string;
  filterSource?: string;
  selectedPlate?: string;
  plates: PlateType[];
  onDeletePlateType?: (plateName: string, plateType: string) => void;
  onCopyPlateType?: (plateId: string) => void;
  onUpdatePlateType?: (plateId: string) => void;
};

export default function PlatesGrid(props: PlatesGridProps) {
  const { onDeletePlateType, plates } = props;

  const { query, filterSource } = props;
  let filteredPlates = useMemo(() => {
    return query || filterSource ? filterPlates(query, plates, filterSource) : plates;
  }, [filterSource, plates, query]);
  filteredPlates = useProgressiveList(filteredPlates, 5, 20);

  return (
    <div>
      <CardGrid>
        {query !== '' && filteredPlates.length === 0 && (
          <NoResultsFound resultLabel="plate types" />
        )}
        {filteredPlates.map(plate => (
          <PlateCard
            key={plate.type} // selection is made on the plate type
            selected={!!props.selectedPlate && props.selectedPlate === plate.type}
            {...plate}
            onSelect={props.onSelect ?? doNothing}
            onDoubleSelect={props.onDoubleSelect ?? doNothing}
            onDeletePlateType={onDeletePlateType}
            onCopyPlateType={props.onCopyPlateType}
            onUpdatePlateType={props.onUpdatePlateType}
          />
        ))}
      </CardGrid>
    </div>
  );
}
