import React, {
  PointerEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/EditOutlined';
import HelpIcon from '@mui/icons-material/HelpOutline';
import ColumnMenuIcon from '@mui/icons-material/MoreVert';
import Popover from '@mui/material/Popover';
import MUITable from '@mui/material/Table';
import MUITableBody from '@mui/material/TableBody';
import MUITableCell from '@mui/material/TableCell';
import MUITableHead from '@mui/material/TableHead';
import MUITableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import produce from 'immer';
import isEqual from 'lodash/isEqual';
import memoisedBind from 'memoize-bind';
import { InViewHookResponse, useInView } from 'react-intersection-observer';

import {
  AdditionalEditorProps,
  AutocompleteAdditionalProps,
  DropdownAdditionalProps,
  MeasurementAdditionalProps,
  UnitAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { indexBy } from 'common/lib/data';
import doNothing from 'common/lib/doNothing';
import { pluralizeWord } from 'common/lib/format';
import { IntercomTourIDs } from 'common/lib/intercom';
import isJSON from 'common/lib/isJSON';
import { SheetState } from 'common/rules/evaluateSpreadsheetRules';
import { ParameterValue } from 'common/types/bundle';
import { Position2d } from 'common/types/Position';
import {
  ColumnConfiguration,
  SheetConfiguration,
  SPREADSHEET_ANALYTICS_CATEGORY,
} from 'common/types/spreadsheet';
import { CellValue, DataTable, Row, TableColumn } from 'common/types/spreadsheetEditor';
import Colors from 'common/ui/Colors';
import { ConditionalWrapper } from 'common/ui/components/ConditionalWrapper';
import AddColumnDialog, {
  MetadataPrefixOption,
} from 'common/ui/components/Dialog/AddColumnDialog';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import {
  getLastNonEmptyRowIndex,
  getRows,
  parseRowsFromRawClipboardString,
  pasteDataToTable,
} from 'common/ui/components/Dialog/spreadsheetHelpers';
import IconButton from 'common/ui/components/IconButton';
import IconWithPopover from 'common/ui/components/IconWithPopover';
import { MarkdownPreview } from 'common/ui/components/MarkdownPreview';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import CheckBoxEditor from 'common/ui/components/ParameterEditors/CheckBoxEditor';
import FloatEditor from 'common/ui/components/ParameterEditors/FloatEditor';
import IntegerEditor from 'common/ui/components/ParameterEditors/IntegerEditor';
import MeasurementEditor from 'common/ui/components/ParameterEditors/MeasurementEditor';
import ToggleEditor from 'common/ui/components/ParameterEditors/ToggleEditor';
import { Action, isUndoable, tableReducer } from 'common/ui/components/tableState';
import { TABLE_TOPBAR_HEIGHT } from 'common/ui/components/TableTopBar';
import {
  clearSelectedCells,
  findSelectionRange,
  generateRowIds,
  getCellSelectionStyle,
  getHeader,
  getSelectedData,
  getSelectedRowCount,
  getSelectionBorder,
  makeColumnResizable,
  SelectionBorder,
  SelectionRange,
  SOLUTE_CONC_PREFIX,
  SOLUTE_UNIT_PREFIX,
  TAG_PREFIX,
} from 'common/ui/components/tableUtils';
import Autocomplete from 'common/ui/filaments/Autocomplete';
import Dropdown from 'common/ui/filaments/Dropdown';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useContextMenu, { ContextMenuOption } from 'common/ui/hooks/useContextMenu';
import useDialog from 'common/ui/hooks/useDialog';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';
import { redo, undo, useUndoReducer } from 'common/ui/hooks/useUndoReducer';
import Keys from 'common/ui/lib/keyboard';

const RESIZE_COLUMN_HANDLE_WIDTH = 6;

export type TableConfiguration = Omit<SheetConfiguration, 'name'>;

type CellEditorComponentProps = {
  editorType: EditorType;
  editorProps: AdditionalEditorProps | null;
  anthaType: string;
  value: ParameterValue;
  isDisabled: boolean;
  onChange: (newValue: ParameterValue) => void;
};

export type CellEditorComponentType = React.ComponentType<CellEditorComponentProps>;

type ConfigByFieldName = { [fieldName: string]: ColumnConfiguration };

type Props = {
  dataTable: DataTable;
  isReadonly?: boolean;
  configuration?: TableConfiguration;
  state?: SheetState;
  title?: string;
  /**
   * When using the table within Antha, we may want to use editors that take
   * advantage of the Autocomplete context, but these can't be used within
   * projects other than antha-com, so by default we provide a version that
   * doesn't.
   * */
  cellEditorComponent?: CellEditorComponentType;
  onAddMoreRows?: () => number;
  onDataTableChange?: (data: DataTable) => void;
};

export default function Table({
  dataTable: initialDataTable,
  isReadonly,
  state,
  title,
  configuration,
  cellEditorComponent = DefaultCellEditor,
  onAddMoreRows,
  onDataTableChange,
}: Props) {
  const classes = useStyles();

  const [
    {
      current: { selectionStart, selectionEnd, isDragFilling, focussedCell, dataTable },
    },
    dispatch,
  ] = useUndoReducer(
    tableReducer,
    {
      selectionStart: null,
      selectionEnd: null,
      isDragFilling: false,
      isDragSelecting: false,
      isDragSelectingColumns: false,
      focussedCell: null,
      dataTable: initialDataTable,
    },
    isUndoable,
  );

  useEffect(() => {
    dispatch({ type: 'SET_TABLE', dataTable: initialDataTable });
  }, [dispatch, initialDataTable]);

  const [isCopying, setIsCopying] = useState<boolean>(false);
  // Pressing ENTER or changing a cell's value will toggle this status. When true, arrow keys
  // will move the cursor within the input instead of moving focus between cells.
  const [isEditingCell, setIsEditingCell] = useState<boolean>(false);
  const [rowIds, setRowIds] = useState(generateRowIds(dataTable.data.length));

  const tableRef = useRef<HTMLTableElement>(null);

  const [addColumnDialog, openAddColumnDialog] = useDialog(AddColumnDialog);
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

  const { ref: lastRowRef, inView: isLastRowVisible, entry } = useInView();

  useEffect(() => {
    onDataTableChange?.(dataTable);
  }, [dataTable, onDataTableChange]);

  // Allow resizing columns
  useEffect(() => {
    const table = tableRef.current;
    if (!table) {
      return;
    }

    const cols = table.querySelectorAll('th');
    cols.forEach(col => {
      // The data-attribute makes it easy to retrieve the resizeHandle
      const resizer = col.querySelector('[data-selector="resizer"]');
      if (resizer) {
        makeColumnResizable(col, resizer);
      }
    });
  }, []);

  /** When users scroll to the bottom, add more rows. */
  useEffect(() => {
    if (!isReadonly && isLastRowVisible && onAddMoreRows) {
      const newRowCount = onAddMoreRows();
      setRowIds(existingIds => [...existingIds, ...generateRowIds(newRowCount)]);
    }
  }, [isLastRowVisible, onAddMoreRows, entry, isReadonly]);

  const selectionRange: SelectionRange = useMemo(
    () => findSelectionRange(selectionStart, selectionEnd),
    [selectionEnd, selectionStart],
  );

  // When selection range changes, revert the
  // isCopying state.
  useEffect(() => {
    setIsCopying(false);
  }, [selectionRange]);

  const copySelectedCells = useCallback(
    (e: Event) => {
      if (
        !selectionRange ||
        document.getSelection()?.type === 'Range' // if text is selected
      ) {
        return;
      }
      const selectedData = getSelectedData(dataTable, selectionRange);
      if (!selectedData) {
        return;
      }
      setIsCopying(true);
      logEvent(
        'ctrl-c-copy-values',
        SPREADSHEET_ANALYTICS_CATEGORY,
        Object.keys(selectedData[0]).length.toString(10),
      );
      (e as ClipboardEvent).clipboardData?.setData(
        'text/plain',
        JSON.stringify(selectedData),
      );
      e.preventDefault();
    },
    [dataTable, selectionRange],
  );

  useEffect(() => {
    window.addEventListener('copy', copySelectedCells);
    return () => {
      window.removeEventListener('copy', copySelectedCells);
    };
  }, [copySelectedCells]);

  const handlePaste = useCallback(
    (e: Event) => {
      if (isReadonly || !selectionStart) {
        return;
      }

      e.preventDefault();
      const rawPastedRows = (e as ClipboardEvent).clipboardData?.getData('text/plain');
      if (!rawPastedRows) {
        return;
      }
      logEvent(
        `paste-${isJSON(rawPastedRows) ? 'tableui' : 'external'}-values-ctrl-v`,
        SPREADSHEET_ANALYTICS_CATEGORY,
      );

      const pastedRows = parseRowsFromRawClipboardString(rawPastedRows);
      const newTable = pasteDataToTable(dataTable, pastedRows, selectionStart);
      // Update selection to match pasted cells
      const indexOfLastPastedColumn = Math.min(
        selectionStart.x + pastedRows[0].length - 1,
        dataTable.schema.fields.length - 1,
      );

      dispatch({
        type: 'SELECT_RANGE',
        start: selectionStart,
        end: {
          x: indexOfLastPastedColumn,
          y: (selectionEnd?.y ?? 0) + pastedRows.length - 1,
        },
      });
      dispatch({
        type: 'SET_TABLE',
        dataTable: newTable,
      });

      // Blur cell, as it could be outside the selection.
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    },
    [dataTable, dispatch, isReadonly, selectionEnd?.y, selectionStart],
  );

  useEffect(() => {
    if (!tableRef.current) {
      return;
    }
    const currTableRef = tableRef.current;
    currTableRef.addEventListener('paste', handlePaste);

    return () => {
      currTableRef.removeEventListener('paste', handlePaste);
    };
  }, [handlePaste]);

  const configByFieldName: ConfigByFieldName | null = useMemo(() => {
    if (configuration?.columns) {
      return indexBy(configuration?.columns, 'name');
    }

    return null;
  }, [configuration?.columns]);

  const moveFocusToPosition = useCallback((position: Position2d) => {
    if (!tableRef.current) {
      return;
    }
    // Move focus to new cell.
    const CELLS_TO_SKIP = 1; // For now, we only need to skip the drag handle.
    const tableBody = tableRef.current.children[1];
    const rows = Array.from(tableBody.children);
    rows.shift(); // Remove the headerMask row
    const { cells } = rows[position.y] as HTMLTableRowElement;
    let cellIndex = position.x + CELLS_TO_SKIP;
    // Gaps count as cells. Skip them if any is present.
    for (let i = CELLS_TO_SKIP; i <= cellIndex; i++) {
      if (cells[i].hasAttribute('aria-hidden')) {
        cellIndex++;
      }
    }
    const input = cells[cellIndex].getElementsByTagName('input')[0];
    if (input) {
      input.focus();
    } else {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
  }, []);

  useEffect(() => {
    if (focussedCell) {
      moveFocusToPosition(focussedCell);
    }
  }, [focussedCell, moveFocusToPosition]);

  const setSelectionRange = useCallback(
    (start: Position2d, end?: Position2d, moveFocusToStart?: boolean) => {
      dispatch({
        type: 'SELECT_RANGE',
        start,
        end: end ?? start,
        moveFocusToStart,
      });
    },
    [dispatch],
  );

  const handleClearSelectedCells = useCallback(() => {
    if (isReadonly || !selectionRange) {
      return;
    }
    const onlyOneCellSelected = isEqual(selectionRange[0], selectionRange[1]);
    if (onlyOneCellSelected) {
      return;
    }
    const newData = clearSelectedCells(dataTable, selectionRange);
    dispatch({
      type: 'SET_TABLE_DATA',
      data: newData,
    });
  }, [dataTable, dispatch, isReadonly, selectionRange]);

  const handlePressArrowKey = useCallback(
    (arrow: 'up' | 'down' | 'left' | 'right', isHoldingShift: boolean) => {
      if (!selectionStart || isEditingCell) {
        return;
      }

      const nextPosition: Position2d = produce(selectionEnd ?? selectionStart, draft => {
        if (arrow === 'up' && draft.y > 0) {
          draft.y--;
        }
        if (arrow === 'down') {
          draft.y++;
        }
        if (arrow === 'left' && draft.x > 0) {
          draft.x--;
        }
        if (arrow === 'right' && draft.x < dataTable.schema.fields.length - 1) {
          draft.x++;
        }
      });

      if (isHoldingShift) {
        setSelectionRange(selectionStart, nextPosition);
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      } else {
        setSelectionRange(nextPosition, undefined, true);
      }
    },
    [
      dataTable.schema.fields.length,
      isEditingCell,
      selectionEnd,
      selectionStart,
      setSelectionRange,
    ],
  );

  const handlePressEnter = useCallback(() => {
    if (!selectionStart) {
      return;
    }

    if (isEditingCell) {
      setIsEditingCell(false);
      const oneCellDown = { x: selectionStart.x ?? 0, y: selectionStart.y + 1 };
      setSelectionRange(oneCellDown, oneCellDown, true);
    } else {
      setSelectionRange(selectionStart);
      setIsEditingCell(true);
    }
  }, [isEditingCell, selectionStart, setSelectionRange]);

  // Update selection range to match the new cell selected with Tab.
  const handlePressTab = useCallback(() => {
    if (!selectionStart) {
      return;
    }
    setIsEditingCell(false);

    const isLastColumn = selectionStart.x === dataTable.schema.fields.length - 1;
    const nextCell = isLastColumn
      ? { x: 0, y: selectionStart.y + 1 } // First cell of next row
      : { x: selectionStart.x + 1, y: selectionStart.y }; // Cell to the right
    setSelectionRange(nextCell);
  }, [dataTable.schema.fields.length, selectionStart, setSelectionRange]);

  const handleKeypress = useCallback(
    (e: KeyboardEvent) => {
      if (!tableRef.current) {
        return;
      }
      const isTableHidden = tableRef.current.getClientRects().length === 0;
      if (isTableHidden) {
        return;
      }

      switch (e.key) {
        case Keys.ESCAPE:
          setIsEditingCell(false);
          break;

        case Keys.BACKSPACE:
        case Keys.DELETE:
          handleClearSelectedCells();
          break;

        case Keys.ARROW_UP:
          handlePressArrowKey('up', e.shiftKey);
          break;
        case Keys.ARROW_DOWN:
          handlePressArrowKey('down', e.shiftKey);
          break;
        case Keys.ARROW_LEFT:
          handlePressArrowKey('left', e.shiftKey);
          break;
        case Keys.ARROW_RIGHT:
          handlePressArrowKey('right', e.shiftKey);
          break;

        case Keys.ENTER:
          handlePressEnter();
          break;

        case Keys.TAB:
          handlePressTab();
          break;

        // Meta + Z, undo.
        // Meta + Shift + Z, redo.
        case Keys.Z:
          if (e.metaKey) {
            e.preventDefault();

            if (e.shiftKey) {
              dispatch(redo());
            } else {
              dispatch(undo());
            }
          }
          break;

        // Meta + R, redo.
        case Keys.R:
          if (e.metaKey) {
            e.preventDefault();
            dispatch(redo());
          }
          break;

        default:
          break;
      }
    },
    [
      dispatch,
      handleClearSelectedCells,
      handlePressArrowKey,
      handlePressEnter,
      handlePressTab,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeypress);

    return () => {
      window.removeEventListener('keydown', handleKeypress);
    };
  }, [handleClearSelectedCells, handleKeypress]);

  const onPointerUp = useCallback(
    (e: PointerEvent<HTMLTableElement | HTMLTableCellElement>) => {
      e.preventDefault();
      dispatch({
        type: 'STOP_DRAG_FILL',
      });
    },
    [dispatch],
  );

  // Should the row handle and first actual column be sticky?
  const isFirstColumnSticky = configuration?.columns[0].isFixed === true;

  const contextMenuOptions: ContextMenuOption[] = useMemo(() => {
    const readonlyOptions = [
      {
        label: 'Copy',
        action: () => {
          setIsCopying(true);
          const selectedData = getSelectedData(dataTable, selectionRange);
          void navigator.clipboard.writeText(JSON.stringify(selectedData));
        },
      },
      {
        label: 'Select all',
        action: () => {
          const lastRowIndex = Math.max(0, getLastNonEmptyRowIndex(dataTable.data));
          setSelectionRange(
            { x: 0, y: 0 },
            { x: dataTable.schema.fields.length - 1, y: lastRowIndex },
          );
        },
      },
    ];

    const allOptions = [...readonlyOptions];

    if (!isReadonly && selectionRange) {
      allOptions.unshift({
        label: 'Clear contents',
        action: () => {
          const newData = clearSelectedCells(dataTable, selectionRange);
          dispatch({
            type: 'SET_TABLE_DATA',
            data: newData,
          });
        },
      });

      if (configuration) {
        allOptions.push({
          label: `Insert row above`,
          action: () => {
            const [start] = selectionRange;
            const newData = produce(dataTable.data, draft => {
              draft.splice(start.y, 0, ...getRows(1, configuration.columns));
            });

            dispatch({
              type: 'SET_TABLE_DATA',
              data: newData,
            });
          },
        });
      }

      allOptions.push({
        label: `Delete ${pluralizeWord(getSelectedRowCount(selectionRange), 'row')}`,
        action: () => {
          const [start, end] = selectionRange;
          const newData = dataTable.data.filter(
            (_, rowIndex) => rowIndex < start.y || end.y < rowIndex,
          );

          dispatch({
            type: 'SET_TABLE_DATA',
            data: newData,
          });
        },
      });
    }

    return allOptions;
  }, [configuration, dataTable, dispatch, isReadonly, selectionRange, setSelectionRange]);
  const [contextMenu, openContextMenu] = useContextMenu(contextMenuOptions);

  const onRowDragEnd = useCallback(
    (result: DragEndEvent) => {
      if (isReadonly || !result.active || !result.over) {
        return;
      }
      logEvent('reorder-row', SPREADSHEET_ANALYTICS_CATEGORY);
      const originalIndex = rowIds.indexOf(String(result.active.id));
      const newIndex = rowIds.indexOf(String(result.over.id));

      setRowIds(existingRowIds => {
        const updatedRowIds = [...existingRowIds];
        const idToMove = updatedRowIds.splice(originalIndex, 1)[0];
        updatedRowIds.splice(newIndex, 0, idToMove);
        return updatedRowIds;
      });

      const updatedData = [...dataTable.data];
      const rowToMove = updatedData.splice(originalIndex, 1)[0];
      updatedData.splice(newIndex, 0, rowToMove);
      dispatch({
        type: 'SET_TABLE_DATA',
        data: updatedData,
      });

      // The full row is selected when starting to drag, so update the
      // selection position to reflect the row's new location.
      const start =
        selectionStart?.y === originalIndex
          ? { x: selectionStart.x, y: newIndex }
          : selectionStart;
      const end =
        selectionEnd?.y === originalIndex
          ? { x: selectionEnd.x, y: newIndex }
          : selectionEnd;
      dispatch({ type: 'SELECT_RANGE', start, end });
    },
    [dataTable.data, dispatch, isReadonly, rowIds, selectionEnd, selectionStart],
  );

  const handleDeleteColumn = useCallback(
    async (columnName: string) => {
      if (isReadonly) {
        return;
      }

      const isConfirmed = await openConfirmationDialog({
        action: 'delete',
        isActionDestructive: true,
        object: 'column',
      });
      if (!isConfirmed) {
        return;
      }

      const newDataTable = produce(dataTable, draft => {
        const indexOfColumnToDelete = draft.schema.fields.findIndex(
          field => field.name === columnName,
        );
        draft.schema.fields.splice(indexOfColumnToDelete, 1);
        draft.data.forEach(row => {
          delete row[columnName];
        });

        // TODO Tweak code when these two columns will be just one (TABLE-47)
        if (columnName.startsWith(`${SOLUTE_CONC_PREFIX}:`)) {
          // Also remove solute_concentration_unit
          draft.schema.fields.splice(indexOfColumnToDelete, 1);
          const unitColumnName = `${SOLUTE_UNIT_PREFIX}:${columnName.split(':')[1]}`;
          draft.data.forEach(row => {
            delete row[unitColumnName];
          });
        }
      });
      dispatch({
        type: 'SET_TABLE',
        dataTable: newDataTable,
      });
    },
    [dataTable, dispatch, isReadonly, openConfirmationDialog],
  );

  const handleEditColumn = useCallback(
    async (columnName: string) => {
      if (isReadonly) {
        return;
      }

      const [oldPrefix, oldName] = columnName.split(':') as [
        MetadataPrefixOption,
        string,
      ];
      const columnInfo = await openAddColumnDialog({
        prefix: oldPrefix,
        name: oldName,
        existingColumns: dataTable.schema.fields.map(field => field.name),
      });
      if (!columnInfo) {
        return;
      }
      const { name: newName, prefix: newPrefix } = columnInfo;
      const indexOfColumnToEdit = dataTable.schema.fields.findIndex(
        field => field.name === columnName,
      );
      const newDataTable = produce(dataTable, draft => {
        const newColumnName = `${newPrefix}:${newName}`;
        draft.schema.fields[indexOfColumnToEdit] = {
          name: newColumnName,
          type: newPrefix === 'solute_concentration' ? 'number' : 'string',
        };
        if (newPrefix === SOLUTE_CONC_PREFIX) {
          draft.schema.fields[indexOfColumnToEdit + 1] = {
            name: `${SOLUTE_UNIT_PREFIX}:${newName}`,
            type: 'string',
          };
        }

        draft.data.forEach(row => {
          row[newColumnName] = row[columnName];
          delete row[columnName];

          if (newPrefix === SOLUTE_CONC_PREFIX) {
            row[`${SOLUTE_UNIT_PREFIX}:${newName}`] =
              row[`${SOLUTE_UNIT_PREFIX}:${oldName}`];
            delete row[`${SOLUTE_UNIT_PREFIX}:${oldName}`];
          }
        });
      });

      dispatch({
        type: 'SET_TABLE',
        dataTable: newDataTable,
      });
    },
    [dataTable, dispatch, isReadonly, openAddColumnDialog],
  );

  return (
    <MUITable
      className={classes.table}
      onPointerUp={onPointerUp}
      onContextMenu={openContextMenu}
      ref={tableRef}
    >
      <MUITableHead>
        <MUITableRow>
          <HeaderCell
            className={cx(classes.rowHandle, classes.leftCornerHeader, {
              [classes.sticky]: isFirstColumnSticky,
            })}
          />
          {dataTable.schema.fields.map((field, columnIndex) => {
            const columnConfig = configByFieldName?.[field.name];
            // Manually added columns do not have a config, and columns with no config
            // have no state.
            const isRequired = columnConfig && state?.columns[field.name].isRequired;
            const previousField =
              columnIndex > 0 ? dataTable.schema.fields[columnIndex - 1] : null;
            const isBeforeGap = columnConfig?.hasTrailingGap;
            const isAfterGap =
              previousField && configByFieldName?.[previousField.name]?.hasTrailingGap;
            const isLastColumn = columnIndex === dataTable.schema.fields.length - 1;
            // Columns that don't have a config have been added by users (either via file
            // upload or with the "+" button). Show the menu to allow actions on these.
            const shouldShowColumnMenuHandle = !columnConfig;
            // Show edit button only for columns added with the "+" icon, which
            // have a column metadata type as a prefix.
            const isEditable =
              field.name.startsWith(`${SOLUTE_CONC_PREFIX}:`) ||
              field.name.startsWith(`${TAG_PREFIX}:`);
            const header = columnConfig?.displayName || getHeader(field);
            return (
              <React.Fragment key={field.name}>
                <HeaderCell
                  className={cx({
                    [classes.sticky]: isFirstColumnSticky && columnIndex === 0,
                    [classes.stickyDataCell]: isFirstColumnSticky && columnIndex === 0,
                    [classes.nonStickyBorderCell]:
                      !isAfterGap && isFirstColumnSticky && columnIndex === 1,
                    [classes.beforeGap]: isBeforeGap,
                    [classes.afterGap]: isAfterGap,
                    [classes.leftCornerHeader]: isAfterGap,
                    [classes.rightCornerHeader]: isBeforeGap || isLastColumn,
                    [classes.lastColumn]: isLastColumn,
                  })}
                  columnIndex={columnIndex}
                  dispatch={dispatch}
                >
                  <div className={classes.headerCellInner}>
                    <Typography variant="overline" style={{ fontWeight: 400 }}>
                      {header}
                      {isRequired && '*'}
                    </Typography>
                    {shouldShowColumnMenuHandle && (
                      <ColumnMenu
                        onDelete={memoisedBind(handleDeleteColumn, null, field.name)}
                        onEdit={
                          isEditable
                            ? memoisedBind(handleEditColumn, null, field.name)
                            : undefined
                        }
                      />
                    )}
                    {columnConfig?.description && (
                      <IconWithPopover
                        icon={
                          <HelpIcon
                            data-intercom-target={`${IntercomTourIDs.ELISA_SPREADSHEET}-spreadsheet-column-description`}
                            fontSize="small"
                          />
                        }
                        popoverContent={
                          <>
                            <Typography variant="subtitle2" color="textSecondary">
                              {columnConfig?.displayName || field.name}
                            </Typography>
                            <MarkdownPreview markdown={columnConfig?.description} />
                          </>
                        }
                      />
                    )}
                    <div className={classes.resizeHandle} data-selector="resizer" />
                  </div>
                </HeaderCell>
                {columnConfig?.hasTrailingGap && <Gap />}
              </React.Fragment>
            );
          })}
        </MUITableRow>
      </MUITableHead>
      <MUITableBody>
        <ConditionalWrapper
          condition={!isReadonly}
          wrapper={children => (
            <DndContext onDragEnd={onRowDragEnd}>
              <SortableContext items={rowIds} strategy={verticalListSortingStrategy}>
                {children}
              </SortableContext>
            </DndContext>
          )}
        >
          <tr
            className={cx(classes.headerMask, {
              [classes.headerMaskInDialogWithTitle]: !!title,
              [classes.headerMaskInDialogWithoutTitle]: !title,
            })}
          />
          {dataTable.data.map((row, rowIndex) => {
            const id = rowIds[rowIndex];
            return (
              <TableRow
                id={id}
                key={id}
                cellEditorComponent={cellEditorComponent}
                configByFieldName={configByFieldName}
                dataTable={dataTable}
                isFirstColumnSticky={isFirstColumnSticky}
                isDragFill={isDragFilling}
                isEditingCell={isEditingCell}
                isCopying={isCopying}
                lastRowRef={lastRowRef}
                isReadonly={!!isReadonly}
                row={row}
                rowIndex={rowIndex}
                selectionRange={selectionRange}
                dispatch={dispatch}
              />
            );
          })}
        </ConditionalWrapper>
      </MUITableBody>
      {contextMenu}
      {!isReadonly && addColumnDialog}
      {confirmationDialog}
    </MUITable>
  );
}

const HeaderCell = React.memo(function HeaderCell({
  children,
  className,
  columnIndex,
  dispatch,
}: {
  children?: React.ReactNode;
  columnIndex?: number;
  className?: string;
  dispatch?: React.Dispatch<Action>;
}) {
  const classes = useStyles();

  const handlePointerDown = useCallback(
    (e: PointerEvent<HTMLTableHeaderCellElement>) => {
      if (columnIndex !== undefined) {
        dispatch?.({
          type: 'POINTER_DOWN_ON_COLUMN',
          index: columnIndex,
          startDragSelection: e.shiftKey,
        });
      }
    },
    [columnIndex, dispatch],
  );

  const handlePointerMove = useCallback(() => {
    if (columnIndex !== undefined) {
      dispatch?.({
        type: 'POINTER_MOVE_ON_COLUMN',
        index: columnIndex,
      });
    }
  }, [columnIndex, dispatch]);

  const handlePointerUp = useCallback(() => {
    if (columnIndex !== undefined) {
      dispatch?.({
        type: 'POINTER_UP_ON_COLUMN',
        index: columnIndex,
      });
    }
  }, [columnIndex, dispatch]);

  return (
    <th
      className={cx(className, classes.cell, classes.headerCell)}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerMove={handlePointerMove}
    >
      {children}
    </th>
  );
});

const BodyCellWrapper = React.memo(function BodyCellWrapper({
  intercomTourId,
  children,
  className,
  onPointerDown,
  onPointerUp,
  onPointerMove,
  style,
}: {
  intercomTourId?: string;
  children: React.ReactNode;
  className?: string;
  onPointerDown?: (e: PointerEvent<HTMLTableCellElement>) => void;
  onPointerUp?: (e: PointerEvent<HTMLTableCellElement>) => void;
  onPointerMove?: (e: PointerEvent<HTMLTableCellElement>) => void;
  style?: React.CSSProperties;
}) {
  const classes = useStyles();
  return (
    <MUITableCell
      data-intercom-target={intercomTourId}
      className={cx(className, classes.bodyCell, classes.cell)}
      style={style}
      onPointerUp={onPointerUp ?? doNothing}
      onPointerMove={onPointerMove ?? doNothing}
      onPointerDown={onPointerDown ?? doNothing}
    >
      {children}
    </MUITableCell>
  );
});

const TableRow = React.memo(function Row({
  id,
  cellEditorComponent,
  configByFieldName,
  dataTable,
  lastRowRef,
  isFirstColumnSticky,
  isDragFill,
  isEditingCell,
  isCopying,
  isReadonly,
  row,
  rowIndex,
  selectionRange,
  dispatch,
}: {
  id: string;
  cellEditorComponent: CellEditorComponentType;
  configByFieldName: ConfigByFieldName | null;
  dataTable: DataTable;
  lastRowRef?: InViewHookResponse['ref'];
  isFirstColumnSticky: boolean;
  isDragFill: boolean;
  isEditingCell: boolean;
  isCopying: boolean;
  isReadonly: boolean;
  row: Row;
  rowIndex: number;
  selectionRange: SelectionRange | null;
  dispatch: React.Dispatch<Action>;
}) {
  const classes = useStyles();
  const isLastRow = rowIndex === dataTable.data.length - 1;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: id,
  });

  const onSelect = () => {
    dispatch({ type: 'SELECT_ROW', index: rowIndex });
  };

  return (
    <MUITableRow
      ref={isLastRow ? lastRowRef : setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition: transition ?? undefined,
      }}
    >
      <BodyCellWrapper
        intercomTourId={
          rowIndex === 0
            ? `${IntercomTourIDs.ELISA_SPREADSHEET}-spreadsheet-drag-handle`
            : undefined
        }
        onPointerDown={onSelect}
        className={cx(classes.rowHandle, {
          [classes.sticky]: isFirstColumnSticky,
          [classes.lastRow]: isLastRow,
        })}
      >
        <div
          className={cx({
            [classes.dragAndDropHandleContainer]: !isReadonly,
            [classes.rowNumberContainer]: isReadonly,
          })}
        >
          {!isReadonly && (
            <DragIndicatorIcon {...attributes} {...listeners} tabIndex={-1} />
          )}
          <Typography variant="subtitle2">{rowIndex + 1}</Typography>
        </div>
      </BodyCellWrapper>
      {dataTable.schema.fields.map((field, columnIndex) => {
        const cellValue = row[field.name];
        const cellSchemaType = field.type;
        const columnConfig = configByFieldName?.[field.name];
        const isSticky = isFirstColumnSticky && columnIndex === 0;
        const isFirstNonStickyCell = isFirstColumnSticky && columnIndex === 1;
        const previousField =
          columnIndex > 0 ? dataTable.schema.fields[columnIndex - 1] : null;
        const isAfterGap =
          (previousField && configByFieldName?.[previousField?.name]?.hasTrailingGap) ??
          false;
        // This cell gets a box shadow effect.
        const isCellBeingEdited =
          isEditingCell &&
          selectionRange?.[0].y === rowIndex &&
          selectionRange?.[0]?.x === columnIndex;

        const selectionBorder = getSelectionBorder(selectionRange, {
          x: columnIndex,
          y: rowIndex,
        });

        return (
          <React.Fragment key={`${id}-${columnIndex}`}>
            <BodyCell
              cellEditorComponent={cellEditorComponent}
              value={cellValue ?? ''}
              cellSchemaType={cellSchemaType}
              isReadonly={isReadonly}
              columnConfig={columnConfig}
              rowIndex={rowIndex}
              columnIndex={columnIndex}
              className={cx({
                [classes.editorCell]: columnConfig,
                [classes.sticky]: isSticky,
                [classes.stickyDataCell]: isSticky,
                [classes.nonStickyBorderCell]: !isAfterGap && isFirstNonStickyCell,
                [classes.beforeGap]: columnConfig?.hasTrailingGap,
                [classes.afterGap]: isAfterGap,
                [classes.lastRow]: isLastRow,
                [classes.lastColumn]: columnIndex === dataTable.schema.fields.length - 1,
                [classes.cellBeingEdited]: isCellBeingEdited,
              })}
              isAfterGap={isAfterGap}
              isEditingCell={isEditingCell}
              isDragFill={isDragFill}
              isCopying={isCopying}
              selectionBorder={selectionBorder}
              dispatch={dispatch}
            />
            {columnConfig?.hasTrailingGap && <Gap />}
          </React.Fragment>
        );
      })}
    </MUITableRow>
  );
});

const Gap = React.memo(function Gap() {
  const classes = useStyles();
  return <MUITableCell className={classes.gap} aria-hidden />;
});

const BodyCell = React.memo(function BodyCell({
  value,
  cellSchemaType,
  columnConfig,
  cellEditorComponent,
  className,
  selectionBorder,
  isAfterGap,
  isEditingCell,
  isDragFill,
  isCopying,
  isReadonly,
  rowIndex,
  columnIndex,
  dispatch,
}: {
  value: CellValue;
  cellSchemaType: TableColumn['type'];
  columnConfig: ColumnConfiguration | undefined;
  cellEditorComponent: CellEditorComponentType;
  className?: string;
  style?: React.CSSProperties;
  selectionBorder: SelectionBorder;
  isAfterGap: boolean;
  isEditingCell: boolean;
  isDragFill: boolean;
  isCopying: boolean;
  isReadonly: boolean;
  rowIndex: number;
  columnIndex: number;
  onStartDragFill?: (startPosition: Position2d) => void;
  dispatch: React.Dispatch<Action>;
}) {
  const CellEditor = cellEditorComponent;
  const classes = useStyles();

  const selectionStyle = getCellSelectionStyle(
    columnIndex,
    selectionBorder,
    columnConfig?.hasTrailingGap ?? false,
    isAfterGap,
    isCopying,
    isDragFill,
  );

  const handlePointerDown = useCallback(
    (e: PointerEvent<HTMLTableCellElement>) => {
      if (e.shiftKey) {
        e.preventDefault();
      }
      dispatch({
        type: 'POINTER_DOWN_ON_CELL',
        position: { y: rowIndex, x: columnIndex },
        isHoldingShift: e.shiftKey,
        isRightClick: e.button === 2,
      });
    },
    [columnIndex, dispatch, rowIndex],
  );

  const handlePointerMove = useCallback(() => {
    if (columnIndex !== undefined) {
      dispatch?.({
        type: 'POINTER_MOVE_ON_CELL',
        position: { x: columnIndex, y: rowIndex },
      });
    }
  }, [columnIndex, dispatch, rowIndex]);

  const handlePointerUp = useCallback(() => {
    if (columnIndex !== undefined) {
      dispatch?.({
        type: 'POINTER_UP_ON_CELL',
        position: { x: columnIndex, y: rowIndex },
        isIncrementingColumn: columnConfig?.dragToFillBehaviour === 'increment',
      });
    }
  }, [columnConfig?.dragToFillBehaviour, columnIndex, dispatch, rowIndex]);

  const showDragFillHandle = useMemo(
    () =>
      !isReadonly &&
      !isEditingCell &&
      selectionBorder?.includes('B') &&
      selectionBorder?.includes('R'),
    [isEditingCell, isReadonly, selectionBorder],
  );

  const handleStartDragFill = useCallback(() => {
    if (!isReadonly) {
      dispatch({
        type: 'SELECT_CELL',
        position: { x: columnIndex, y: rowIndex },
        isDragFilling: true,
      });
    }
  }, [columnIndex, dispatch, isReadonly, rowIndex]);

  const handleDoubleClickFillHandle = useCallback(
    () =>
      dispatch({
        type: 'FILL_REST_OF_COLUMN',
        position: { x: columnIndex, y: rowIndex },
      }),
    [columnIndex, dispatch, rowIndex],
  );

  const onValueChange = useCallback(
    (value: CellValue) =>
      dispatch({
        type: 'SET_CELL_VALUE',
        position: { x: columnIndex, y: rowIndex },
        value,
      }),
    [columnIndex, dispatch, rowIndex],
  );

  const editorType = useMemo(() => {
    if (columnConfig) {
      return columnConfig.editor.type;
    }

    switch (cellSchemaType) {
      case 'number':
        return EditorType.FLOAT;

      case 'boolean':
        return EditorType.CHECKBOX;

      case 'string':
      default:
        return EditorType.STRING;
    }
  }, [cellSchemaType, columnConfig]);

  return (
    <BodyCellWrapper
      className={className}
      style={selectionStyle}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      onPointerMove={handlePointerMove}
    >
      {showDragFillHandle && (
        <div
          className={classes.dragFillHandle}
          onPointerDown={handleStartDragFill}
          onDoubleClick={handleDoubleClickFillHandle}
          draggable
        />
      )}
      <CellEditor
        anthaType={columnConfig?.anthaType ?? ''}
        editorType={editorType}
        editorProps={columnConfig?.editor.additionalProps ?? null}
        value={value}
        onChange={onValueChange}
        isDisabled={isReadonly}
      />
    </BodyCellWrapper>
  );
});

// Popover shown in the Header cells, to edit/delete a column
type ColumnMenuProps = {
  onDelete: () => void;
  onEdit?: () => void;
};
const ColumnMenu = React.memo(function ColumnMenu({ onDelete, onEdit }: ColumnMenuProps) {
  const classes = useStyles();

  const [showColumnMenu, setShowColumnMenuAnchorEl] = useState<null | Element>(null);
  const handleShowColumnMenuMenu = useCallback((event: React.MouseEvent<Element>) => {
    event.stopPropagation();
    setShowColumnMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseColumnMenu = useCallback(() => {
    setShowColumnMenuAnchorEl(null);
  }, []);

  return (
    <>
      <IconButton
        size="small"
        icon={<ColumnMenuIcon />}
        onClick={handleShowColumnMenuMenu}
      />
      <Popover
        elevation={4}
        open={!!showColumnMenu}
        anchorEl={showColumnMenu}
        onClose={handleCloseColumnMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.panel }}
      >
        {onEdit && <MenuItemWithIcon icon={<EditIcon />} text="Edit" onClick={onEdit} />}
        <MenuItemWithIcon icon={<DeleteIcon />} text="Delete" onClick={onDelete} />
      </Popover>
    </>
  );
});

export const DefaultCellEditor = React.memo(function DefaultCellEditor({
  editorType,
  editorProps,
  anthaType,
  value,
  isDisabled,
  onChange,
}: {
  editorType: EditorType;
  editorProps: AdditionalEditorProps | null;
  anthaType: string;
  value: ParameterValue;
  isDisabled: boolean;
  onChange: (newValue: ParameterValue) => void;
}) {
  const handleChange = useCallback(
    (newValue: ParameterValue) => {
      logEvent('edit-cell-value', SPREADSHEET_ANALYTICS_CATEGORY);
      onChange(newValue);
    },
    [onChange],
  );
  const onChangeText = useTextFieldChange(handleChange);
  const handleTextBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onChange(event.target.value.trim());
  };

  switch (editorType) {
    case EditorType.STRING:
    case EditorType.PLATE_TYPE:
    case EditorType.DNA:
    case EditorType.POLICY:
      return (
        <TextField
          variant="standard"
          fullWidth
          disabled={isDisabled}
          value={value}
          onChange={onChangeText}
          onBlur={handleTextBlur}
          InputProps={{ disableUnderline: true }}
          inputProps={{
            style: { whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
          }}
        />
      );
    case EditorType.AUTOCOMPLETE: {
      const { canAcceptCustomValues, staticOptions } =
        editorProps as AutocompleteAdditionalProps;
      return (
        <Autocomplete
          valueLabel={value}
          isDisabled={isDisabled}
          onChange={handleChange}
          options={staticOptions.map(value => ({ label: value, value }))}
          acceptCustomValues={canAcceptCustomValues}
          fullWidth
          disableUnderline
        />
      );
    }
    case EditorType.DROPDOWN: {
      const { options } = editorProps as DropdownAdditionalProps;
      return (
        <Dropdown
          valueLabel={value}
          isDisabled={isDisabled}
          onChange={handleChange}
          options={options}
          disableUnderline
        />
      );
    }
    case EditorType.UNIT: {
      const { units } = editorProps as UnitAdditionalProps;
      const options = units.map(value => ({ label: value, value }));
      return (
        <Dropdown
          valueLabel={value}
          onChange={onChange}
          isDisabled={isDisabled}
          options={options}
          disableUnderline
        />
      );
    }
    case EditorType.MEASUREMENT: {
      const { units, defaultUnit } = editorProps as MeasurementAdditionalProps;
      return (
        <MeasurementEditor
          value={value}
          isDisabled={isDisabled}
          onChange={handleChange}
          units={units}
          defaultUnit={defaultUnit}
          disableUnderline
        />
      );
    }
    case EditorType.INT:
      return (
        <IntegerEditor
          type={anthaType}
          isDisabled={isDisabled}
          onChange={handleChange}
          value={value}
          disableUnderline
        />
      );
    case EditorType.FLOAT:
      return (
        <FloatEditor
          type={anthaType}
          isDisabled={isDisabled}
          onChange={handleChange}
          value={value}
          disableUnderline
        />
      );
    case EditorType.CHECKBOX:
      return (
        <CheckBoxEditor onChange={handleChange} value={value} isDisabled={isDisabled} />
      );
    case EditorType.TOGGLE:
      return (
        <ToggleEditor onChange={handleChange} value={value} isDisabled={isDisabled} />
      );
    default:
      return null;
  }
});

const ROW_HANDLE_COLUMN_WIDTH = `73px`;
const DRAG_FILL_HANDLE_SIZE = 6;
const DIALOG_TITLE_HEIGHT = '58px';
const DIALOG_PADDING_HORIZONTAL = '24px';
const DIALOG_PADDING_VERTICAL = '8px';
const NO_TITLE_DIALOG_PADDING = '20px';
const SCROLLBAR_WIDTH = '15px';

export const CELL_BORDER = Colors.GREY_20;
export const HEADER_CELL_BACKGROUND = Colors.GREY_10;
export const HEADER_CELL_RADIUS = '8px';

const useStyles = makeStylesHook(theme => ({
  table: {
    borderCollapse: 'separate',
    // Allow table to become wider than its container.
    // This allows resizing columns indefinitely.
    width: 'max-content',
    // Make the first two th go on top of all the other
    // sticky th and selected sticky cells.
    '& thead th:nth-child(-n + 2)': {
      zIndex: 5,
    },
  },
  beforeGap: {
    borderRight: `0.5px solid ${CELL_BORDER}`,
  },
  dragAndDropHandleContainer: {
    display: 'grid',
    gridTemplateColumns: '20px 35px',
    alignItems: 'center',
    justifyItems: 'center',
    userSelect: 'none',
  },
  rowNumberContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  dragFillHandle: {
    position: 'absolute',
    width: `${DRAG_FILL_HANDLE_SIZE}px`,
    height: `${DRAG_FILL_HANDLE_SIZE}px`,
    borderRadius: '100%',
    right: `-${DRAG_FILL_HANDLE_SIZE / 2}px`,
    bottom: `-${DRAG_FILL_HANDLE_SIZE / 2}px`,
    zIndex: 2,
    cursor: 'crosshair',
    backgroundColor: Colors.BLUE_80,
    userSelect: 'none',
  },
  gap: {
    width: theme.spacing(3),
    padding: 0,
    visibility: 'hidden',
    border: 'none',
  },
  afterGap: {
    borderLeft: `0.5px solid ${CELL_BORDER}`,
  },
  cell: {
    // We can't use border-collapse in combination with sticky columns, so we
    // use 0.5px borders (that add up to 1px lines between cells) and augment
    // the edges of the table.
    border: `0.5px solid ${CELL_BORDER}`,
    padding: theme.spacing(3),
  },
  bodyCell: {
    backgroundColor: Colors.WHITE,
    // So that the drag fill handle can be absolutely positioned
    // within the cell.
    position: 'relative',
  },
  editorCell: {
    padding: theme.spacing(0, 3),
  },
  headerCell: {
    position: 'sticky',
    top: 0,
    zIndex: 4,
    backgroundColor: HEADER_CELL_BACKGROUND,
    borderTop: `1px solid ${CELL_BORDER}`,
  },
  headerCellInner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
  },
  cellBeingEdited: {
    boxShadow: `0px 0px 8px 1px ${Colors.GREY_50}`,
    zIndex: 2,
  },
  headerMask: {
    width: `calc(100% - ${DIALOG_PADDING_HORIZONTAL} - ${SCROLLBAR_WIDTH})`,
    height: '40px',
    position: 'absolute',
    left: 0,
    backgroundColor: Colors.WHITE,
    zIndex: 3,
  },
  headerMaskInDialogWithTitle: {
    top: `calc(${TABLE_TOPBAR_HEIGHT} + ${DIALOG_TITLE_HEIGHT} + ${DIALOG_PADDING_VERTICAL})`,
  },
  headerMaskInDialogWithoutTitle: {
    top: `calc(${TABLE_TOPBAR_HEIGHT} + ${NO_TITLE_DIALOG_PADDING} + ${DIALOG_PADDING_VERTICAL})`,
  },
  rowHandle: {
    borderLeft: `1px solid ${Colors.GREY_20}`,
    width: ROW_HANDLE_COLUMN_WIDTH,
    textAlign: 'right',
  },
  leftCornerHeader: {
    borderTopLeftRadius: HEADER_CELL_RADIUS,
  },
  resizeHandle: {
    position: 'absolute',
    top: 0,
    right: `-${RESIZE_COLUMN_HANDLE_WIDTH / 2}px`,
    width: `${RESIZE_COLUMN_HANDLE_WIDTH}px`,
    height: '100%',
    zIndex: 2,
    userSelect: 'none',
    '&:hover': {
      cursor: 'col-resize',
    },
  },
  rightCornerHeader: {
    borderTopRightRadius: HEADER_CELL_RADIUS,
  },
  sticky: {
    position: 'sticky',
    zIndex: 1,
    left: 0,
  },
  stickyDataCell: {
    left: ROW_HANDLE_COLUMN_WIDTH,
    borderRight: `1px solid ${CELL_BORDER}`,
  },
  // For the first non-sticky column in a row
  nonStickyBorderCell: { borderLeft: 0 },
  lastRow: {
    borderBottom: `1px solid ${CELL_BORDER}`,
  },
  lastColumn: {
    borderRight: `0.5px solid ${CELL_BORDER}`,
  },
  panel: { marginTop: theme.spacing(1), borderRadius: theme.spacing(3) },
}));
