import React, { useCallback, useState } from 'react';

import UploadIcon from '@mui/icons-material/CloudUpload';

import { parseConfigurationFile } from 'client/app/components/DeviceLibrary/parseConfigurationFile';
import UploadDeviceConfig from 'client/app/components/DeviceLibrary/UploadDeviceConfigDialog';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import { getFileText } from 'common/lib/getFileBytes';
import Button from 'common/ui/components/Button';
import { useDialogManager } from 'common/ui/components/DialogManager';
import InfoDialog from 'common/ui/components/InfoDialog';

type Props = {
  device: DeviceCommon;
};

// Keep count of each UploadDeviceConfigButton created. This is used to create a unique ID
// for each file input, ensuring that the label attach to the correct input.
let idCount = 0;
const createButtonId = () => `uploadRunConfig${idCount++}`;

function UploadDeviceConfigButton(props: Props) {
  const [inputId] = useState<string>(createButtonId);

  const dialogManager = useDialogManager();
  const { device } = props;

  const handleSelectFile = useCallback(
    async (e: React.FormEvent<HTMLInputElement>) => {
      const files = e.currentTarget.files;
      if (!files) {
        // no files selected, user cancelled
        return;
      }

      try {
        const parsedConfigFile = await processDeviceConfigFile(files);

        dialogManager.openDialog('UPLOAD_DEVICE_CONFIG_FILE', UploadDeviceConfig, {
          device,
          parsedConfigFile,
        });
      } catch (e) {
        dialogManager.openDialog('UPLOAD_LIQUID_CLASSES_CSV_SUCCESS', InfoDialog, {
          title: 'Device configuration error',
          message: `Failed to parse device configuration:\n\n${e.message}`,
        });
      }
    },
    [device, dialogManager],
  );

  return (
    <>
      <input type="file" id={inputId} hidden onChange={handleSelectFile} accept=".json" />
      <label htmlFor={inputId}>
        <Button startIcon={<UploadIcon />} component="span" variant="tertiary">
          Add New Configuration
        </Button>
      </label>
    </>
  );
}

// Promisify the API for reading files
async function processDeviceConfigFile(files: FileList) {
  if (files.length !== 1) {
    // This should never happen - we didn't enable multiselection.
    throw new Error('User selected more than one file');
  }
  const text = await getFileText(files[0]);
  return parseConfigurationFile(text);
}

export default UploadDeviceConfigButton;
