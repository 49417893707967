import React, { useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import ExtensionIcon from '@mui/icons-material/Extension';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { getResultOrThrow } from 'client/app/api/apolloClient';
import { useCopyLink, useUpload } from 'client/app/api/filetree';
import {
  MUTATION_CREATE_UPLOAD,
  MUTATION_PROCESS_UPLOAD_SINGLE_CREATE_LABWARE_HISTORY,
} from 'client/app/api/gql/mutations';
import { useDataUploadPolling } from 'client/app/apps/simulation-details/overview/results/hooks';
import SyntheticDataDialog from 'client/app/apps/simulation-details/overview/results/SyntheticDataDialog';
import PathSelectorDialog from 'client/app/components/FileBrowser/PathSelectorDialog';
import SimpleDeviceSelectorDialog, {
  SimpleDeviceSelectorDialogWithPrefixItems,
} from 'client/app/components/SimpleDeviceSelectorDialog';
import { DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import AppIcon from 'client/app/icons/AppIcon';
import GenericDeviceParserIcon from 'client/app/icons/GenericDeviceParserIcon';
import { DEVICE_BASE_PATH } from 'client/app/lib/file-browser/getMetadataFromPath';
import {
  FileBrowserFileSelection,
  FileBrowserFileSingleSelection,
} from 'client/app/lib/file-browser/types';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { basename, concatURL } from 'common/lib/strings';
import { FileEditorValue, FileObject } from 'common/types/fileParameter';
import {
  FiletreeLink,
  parseFiletreeLink,
  sanitizeFilenameForFiletree,
} from 'common/types/filetree';
import { SIMULATION_DETAILS_EXECUTION_TAB_ID } from 'common/ui/AnalyticsConstants';
import Button from 'common/ui/components/Button';
import LinearProgress from 'common/ui/components/LinearProgress';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import DirectUploadEditor from 'common/ui/components/ParameterEditors/DirectUploadEditor';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

const GENERIC_DATA_ID = 'generic_data';
const GENERIC_DATA_PARSER = 'eu.gcr.io/antha-images/data-processing-generic';

export type AddFileButtonProps = {
  labwareId: LabwareId;
  labwareName: string;
  executionId: ExecutionId;
  onRefresh: () => Promise<unknown>;
  setIsFileUploading: React.Dispatch<React.SetStateAction<boolean>>;
};

function AddFileButton({
  labwareId,
  labwareName,
  executionId,
  onRefresh,
  setIsFileUploading,
}: AddFileButtonProps) {
  const classes = useStyles();
  const isSyntheticDataEnabled = useFeatureToggle('SYNTHETIC_DATA');

  const upload = useUpload();

  // State that determines what the UI shows
  const [uploadMenuAnchor, setUploadMenuAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

  // Dialogs
  const [pathSelectorDialog, openPathSelectorDialog] = useDialog(PathSelectorDialog);
  // A regular deviceSelectorDialog, for use in selecting a device for the file-from-device dialog.
  // This can't be the one with prefixItems because they have different types for their result
  // (Device | null versus Device | string | null).
  const [deviceSelectorDialog, openDeviceSelectorDialog] = useDialog(
    SimpleDeviceSelectorDialog,
  );
  // A deviceSelectorDialog with the generic upload card prefixed, for use in selecting a parser in
  // the file-from-computer flow.
  const [deviceSelectorDialogWithPrefixItems, openDeviceSelectorDialogWithPrefixItems] =
    useDialog(SimpleDeviceSelectorDialogWithPrefixItems);
  const [syntheticDataDialog, openSyntheticDataDialog] = useDialog(SyntheticDataDialog);

  // Mutations for performing data processing
  const [createUpload] = useMutation(MUTATION_CREATE_UPLOAD);
  const [processUpload] = useMutation(
    MUTATION_PROCESS_UPLOAD_SINGLE_CREATE_LABWARE_HISTORY,
  );
  const copyLink = useCopyLink();

  // When we initiate a data upload, we set uploadToPoll, and then this hook will repeatedly poll
  // the status of the upload and call the callback when it's done.  At that point we call onRefresh
  // to show the new file(s).
  const [uploadToPoll, setUploadToPoll] = useState<DataUploadId | undefined>(undefined);
  useDataUploadPolling(uploadToPoll, async () => {
    setUploadToPoll(undefined);
    await onRefresh();
    setShowLoadingIndicator(false);
    setIsFileUploading(false);
  });

  // Do processing takes the incoming file and either a device or a parser, creates a DataUpload,
  // requests processing for that file, and starts polling for the results.
  const doProcessing = useCallback(
    async (
      link: FiletreeLink,
      filename: string,
      deviceId?: DeviceId,
      parser?: string,
    ) => {
      setIsFileUploading(true);
      setShowLoadingIndicator(true);
      const uploadResult = await createUpload({
        variables: {
          deviceId,
          parser,
          executionId,
        },
      });
      // TODO: The file that was provided may have already been parsed (if it came from a device),
      // but in the current scheme we needlessly parse it again.  As an optimization, we could look
      // for existing DataFiles or ParsedDataFiles that are for this file and just create new
      // LabwareDatasets for them.
      const dataUpload = getResultOrThrow(
        uploadResult,
        'Create upload',
        data => data.createUpload,
      );
      const copyResult = await copyLink(
        link,
        concatURL(
          parseFiletreeLink(dataUpload.directoryFiletreeLink).path,
          sanitizeFilenameForFiletree(filename),
        ) as FiletreePath,
        'Manual upload',
      );
      await processUpload({
        variables: {
          filetreeLink: copyResult.ftl,
          uploadId: dataUpload.id,
          labwareId: labwareId,
          executionId: executionId,
        },
      });
      setUploadToPoll(dataUpload.id);
    },
    [
      copyLink,
      createUpload,
      executionId,
      labwareId,
      processUpload,
      setIsFileUploading,
      setShowLoadingIndicator,
    ],
  );

  // For uploading from the local machine, we need to first upload the file, then choose a device
  // for processing, then do the processing.  The actual upload is handled by DirectUploadEditor, so
  // when this callback is called the file is in Filetree and we just need to choose a device.
  const handleUploadFromComputer = useCallback(
    async (value: FileEditorValue) => {
      if (!value) {
        return;
      }
      logEvent('add-file-from-computer', SIMULATION_DETAILS_EXECUTION_TAB_ID);
      setUploadMenuAnchorEl(null);
      const fileObject = value as FileObject;

      const selectDeviceResult = await openDeviceSelectorDialogWithPrefixItems({
        title: `Select source device of ${fileObject.Name} for ${labwareName}`,
        prefixItems: [
          {
            id: GENERIC_DATA_ID,
            title: 'Another device',
            body: "Data from devices that haven't been integrated into Synthace can be uploaded as external data.\n\nSelect this option to use our generic parser.",
            image: <GenericDeviceParserIcon className={classes.genericDeviceImage} />,
          },
        ],
      });
      if (selectDeviceResult) {
        await doProcessing(
          fileObject.Path,
          fileObject.Name,
          selectDeviceResult === GENERIC_DATA_ID
            ? undefined
            : (selectDeviceResult as DeviceCommon).id,
          selectDeviceResult === GENERIC_DATA_ID ? GENERIC_DATA_PARSER : undefined,
        );
      }
    },
    [
      classes.genericDeviceImage,
      doProcessing,
      labwareName,
      openDeviceSelectorDialogWithPrefixItems,
    ],
  );

  // For uploading from a device, we need to choose the device, then choose the file in that
  // device's directory.  We'll assume that we want to use the same device for processing; it would
  // be weird to take data uploaded by one device and parse it as if it were data for another
  // device, and the user can download locally and reupload if they need to do that.
  const handleUploadFromDevice = useCallback(async () => {
    logEvent('add-file-from-device-selection', SIMULATION_DETAILS_EXECUTION_TAB_ID);
    setUploadMenuAnchorEl(null);

    const selectDeviceResult = await openDeviceSelectorDialog({
      title: `Select source device for ${labwareName}`,
    });

    if (selectDeviceResult) {
      const { id, name } = selectDeviceResult;
      const devicePath = concatURL(DEVICE_BASE_PATH, id);

      let selectedPath: FileBrowserFileSelection = null;
      const pathSuccess = await openPathSelectorDialog({
        value: null,
        basePath: devicePath,
        onChange: val => {
          selectedPath = val;
        },
        overrideDialogTitle: `Select file from ${name} for ${labwareName}`,
      });
      if (pathSuccess && selectedPath) {
        const link = (selectedPath as FileBrowserFileSingleSelection).pathWithScheme;
        await doProcessing(link, basename(link), id);
      }
    }
  }, [doProcessing, labwareName, openDeviceSelectorDialog, openPathSelectorDialog]);

  const handleSyntheticData = useCallback(async () => {
    logEvent('add-synthetic-data', SIMULATION_DETAILS_EXECUTION_TAB_ID);
    setUploadMenuAnchorEl(null);

    const selectDeviceResult = await openDeviceSelectorDialog({
      title: `Select device to generate data for ${labwareName}`,
    });
    if (selectDeviceResult) {
      const { id, name } = selectDeviceResult;
      const syntheticDataFile = await openSyntheticDataDialog({
        deviceId: id,
        deviceName: name,
        labwareName,
      });
      if (syntheticDataFile) {
        await doProcessing(syntheticDataFile, basename(syntheticDataFile), id);
      }
    }
  }, [doProcessing, labwareName, openDeviceSelectorDialog, openSyntheticDataDialog]);

  const handleOpenMenu = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setUploadMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setUploadMenuAnchorEl(null);
  }, []);

  return (
    <>
      {showLoadingIndicator && <LinearProgress />}
      <Button
        variant="secondary"
        onClick={handleOpenMenu}
        className={cx({ [classes.hidden]: showLoadingIndicator })}
      >
        Add File
      </Button>
      <Menu
        anchorEl={uploadMenuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!uploadMenuAnchor}
        onClose={handleCloseMenu}
        className={cx({ [classes.hidden]: showLoadingIndicator })}
      >
        <Typography variant="body2" className={classes.menuTitle}>
          FROM
        </Typography>

        <DirectUploadEditor
          value={null}
          renderType="MenuItem"
          onChange={handleUploadFromComputer}
          setIsUploading={setIsFileUploading}
          onUpload={upload}
        />
        <MenuItemWithIcon
          onClick={handleUploadFromDevice}
          icon={<AppIcon iconId="antha:device" />}
          text="Device"
        />
        {isSyntheticDataEnabled && (
          <MenuItemWithIcon
            onClick={handleSyntheticData}
            icon={<ExtensionIcon />}
            text="Synthetic Data"
          />
        )}
      </Menu>
      {deviceSelectorDialog}
      {deviceSelectorDialogWithPrefixItems}
      {pathSelectorDialog}
      {syntheticDataDialog}
    </>
  );
}

const useStyles = makeStylesHook({
  hidden: {
    display: 'none',
  },
  menuTitle: {
    margin: '4px 16px',
  },
  genericDeviceImage: {
    display: 'block',
    width: '40px',
    height: '40px',
  },
});

export default AddFileButton;
