import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import uniq from 'lodash/uniq';

import {
  QUERY_DATASET_DERIVATION_SAMPLES,
  QUERY_SAMPLE_SEARCH,
} from 'client/app/apps/experiments/gql/queries';
import { ArrayElement, SamplesQuery } from 'client/app/gql';
import { ChipPicker } from 'common/ui/components/ChipPicker';

type Sample = ArrayElement<SamplesQuery['samples']['items']>;

type Props = {
  placeholder: string;
  searchPlaceholder?: string;
  value: string | null;
  exclude?: Set<string>;
  isDisabled?: boolean;
  allowCustom?: boolean;
  limitToDatasetDerivationId?: DatasetDerivationId;
  listHelperText: string;
  onChange: (sampleName: string | null, sample?: Sample) => void;
};

export function SampleAutocomplete(props: Props) {
  const { limitToDatasetDerivationId } = props;

  return limitToDatasetDerivationId ? (
    <DerivationSampleAutocomplete
      {...props}
      datasetDerivationId={limitToDatasetDerivationId}
    />
  ) : (
    <GlobalSampleAutocomplete {...props} />
  );
}

function GlobalSampleAutocomplete(props: Props) {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();
  const samples = useSamples(searchTerm ?? null);
  return (
    <SampleAutocompleteBase {...props} samples={samples} onSearchChange={setSearchTerm} />
  );
}

function DerivationSampleAutocomplete(
  props: Props & { datasetDerivationId: DatasetDerivationId },
) {
  const samples = useSamplesInDerivation(props.datasetDerivationId);
  return <SampleAutocompleteBase {...props} samples={samples} />;
}

function SampleAutocompleteBase({
  placeholder,
  searchPlaceholder,
  value,
  exclude,
  isDisabled,
  allowCustom,
  samples,
  listHelperText,
  onChange,
  onSearchChange,
}: Props & {
  samples?: readonly Sample[];
  onSearchChange?: (searchTerm: string | undefined) => void;
}) {
  const options =
    samples?.map(sample => sample.name).filter(sampleName => !exclude?.has(sampleName)) ??
    [];

  const sampleByName = Object.fromEntries(
    samples?.map(sample => [sample.name, sample]) ?? [],
  );

  const handleChange = (sampleName?: string) => {
    onChange(sampleName ?? null, sampleName ? sampleByName[sampleName] : undefined);
  };

  return (
    <ChipPicker
      options={options}
      value={value ?? undefined}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      listHelperText={listHelperText}
      disabled={isDisabled}
      onSearchChange={onSearchChange}
      onPick={handleChange}
      allowCustom={allowCustom}
    />
  );
}

function useSamples(searchText: string | null): readonly Sample[] | undefined {
  const { data } = useQuery(QUERY_SAMPLE_SEARCH, {
    variables: { search: searchText ?? '' },
  });
  return data?.samples.items;
}

function useSamplesInDerivation(
  datasetDerivationId: DatasetDerivationId,
): Sample[] | undefined {
  const { data } = useQuery(QUERY_DATASET_DERIVATION_SAMPLES, {
    variables: { datasetDerivationId: datasetDerivationId },
    // skip cache in case any samples or datasets have been added since last fetch
    fetchPolicy: 'network-only',
  });
  return uniq(
    data?.datasetDerivation.inputs
      .flatMap(input => input.samples)
      .map(match => match.sample),
  ).sort((a, b) => a.name.localeCompare(b.name));
}
