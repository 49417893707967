import React, { useCallback, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import cx from 'classnames';

import { makeWellSelectorContents } from 'client/app/apps/cherry-picker/CherryPickApi';
import { useCherryPickContext } from 'client/app/apps/cherry-picker/CherryPickContext';
import { CherryPickPlateRole } from 'client/app/apps/cherry-picker/cp-plate-vis/CherryPickPlatesView';
import PlateOrTypeSelector from 'client/app/apps/cherry-picker/cp-plate-vis/PlateOrTypeSelector';
import TypeSelector from 'client/app/apps/cherry-picker/cp-plate-vis/PlateTypeSelector';
import PlateUploader from 'client/app/apps/cherry-picker/cp-plate-vis/PlateUploader';
import { ScreenRegistry } from 'client/app/registry';
import { renameKey } from 'common/object';
import Colors from 'common/ui/Colors';
import DeckLayout, {
  DUMMY_DECK,
  getLayoutForWellSelector,
} from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import makeWellSelector from 'common/ui/components/simulation-details/PlateTransform';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { useEnterKeyPress } from 'common/ui/hooks/useEnterKeyPress';

type PlateVizProps = {
  plateRole: CherryPickPlateRole;
  plateName: string;
};

export default function CherryPickPlateViz({ plateRole, plateName }: PlateVizProps) {
  const classes = useStyles();
  const {
    activeStep,
    allPlatesByType: plates,
    cherryPick,
    setCherryPick,
    plateMinSizeByName,
    platesByName,
    setPlatesByName,
    transfersInfoByPlate,
    isReadonly,
  } = useCherryPickContext();

  const [editedPlateName, setEditedPlateName] = useState(plateName);

  const plateContents = useMemo(() => {
    if (plateRole === 'Source') {
      return makeWellSelectorContents(
        transfersInfoByPlate[plateName].wellsContentByPosition,
      );
    } else {
      const sourcePlateName = Object.keys(transfersInfoByPlate)[activeStep.source];
      const sourcePlateTransferInfo = transfersInfoByPlate[sourcePlateName];
      return makeWellSelectorContents(sourcePlateTransferInfo.plateOutputs[plateName]);
    }
  }, [activeStep.source, plateRole, plateName, transfersInfoByPlate]);

  const [deckLayout, wellSelector] = useMemo((): [DeckLayout, PlateState | undefined] => {
    const plate = platesByName[plateName];
    // If plate hasn't been chosen by users yet
    if (!plate) {
      return [new DeckLayout(DUMMY_DECK), undefined];
    }

    const wellSelector = makeWellSelector(plate);
    // Add liquid contents to display coloured wells.
    wellSelector.contents = plateContents;
    const deckLayout = getLayoutForWellSelector(wellSelector);

    return [deckLayout, wellSelector];
  }, [plateContents, plateName, platesByName]);

  const updatePlateType = useCallback(() => {
    const updatedPlates = renameKey(platesByName, plateName, editedPlateName);
    setPlatesByName(updatedPlates);
  }, [editedPlateName, plateName, platesByName, setPlatesByName]);

  // Allow users to edit the plate name via the UI
  const handleConfirmEdit = useCallback(() => {
    logEvent('edit-cp-plate-name', ScreenRegistry.CHERRY_PICKER);
    let newCherryPick = [...cherryPick];
    // Find all transfers of "plateName" and replace them
    // with the new name
    newCherryPick = newCherryPick.map(transfer => {
      const newTransfer = { ...transfer };
      // The same plate can be source or destination, so `plateName` alone
      // is not enough to determine what we should update.
      if (plateRole === 'Source') {
        if (newTransfer.sourcePlate === plateName) {
          newTransfer.sourcePlate = editedPlateName;
        }
      } else {
        if (newTransfer.destinationPlate === plateName) {
          newTransfer.destinationPlate = editedPlateName;
        }
      }
      return newTransfer;
    });
    updatePlateType();
    setCherryPick(newCherryPick);
  }, [cherryPick, editedPlateName, plateRole, plateName, setCherryPick, updatePlateType]);

  const handleEnter = useEnterKeyPress(handleConfirmEdit);

  const handleEditPlateName = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedPlateName(e.target.value);
  }, []);

  return (
    <div
      className={cx({
        [classes.container]: true,
        [classes.sourceContainer]: plateRole === 'Source',
      })}
    >
      <div className={classes.plateViz}>
        <Grid container alignItems="flex-end" wrap="nowrap">
          <Grid item className={classes.plateName}>
            <TextField
              variant="standard"
              defaultValue={plateName}
              onChange={handleEditPlateName}
              onKeyPress={handleEnter}
              onBlur={handleConfirmEdit}
              fullWidth
              disabled={isReadonly}
            />
          </Grid>
          {plateRole === 'Source' && (
            <Grid item>
              <PlateUploader
                setPlatesByName={setPlatesByName}
                setCherryPick={setCherryPick}
                plates={plates}
                plateMinSizeByName={plateMinSizeByName}
                isDisabled={isReadonly}
              />
            </Grid>
          )}
          {platesByName[plateName] && (
            <Grid item>
              <TypeSelector
                setPlatesByName={setPlatesByName}
                plateName={plateName}
                plates={plates}
                plateMinSizeByName={plateMinSizeByName}
                isDisabled={isReadonly}
                compact
              />
            </Grid>
          )}
        </Grid>
        <PlateOrTypeSelector
          plateType={wellSelector}
          setPlatesByName={setPlatesByName}
          selectedPlateName={plateName}
          deckLayout={deckLayout}
          plates={plates}
          plateMinSizeByName={plateMinSizeByName}
          isReadonly={isReadonly}
        />
      </div>
    </div>
  );
}

const useStyles = makeStylesHook({
  container: {
    // The dimension below are what the browser computes given this
    // component sits in a grid. I'm explicitly setting it because
    // plateViz and platePlaceholder behave in a slightly different way
    // and they would otherwise be misaligned by a few pixels.
    height: '310px',
    width: '369px',
    margin: 'auto',

    display: 'flex',
    justifyContent: 'center',
  },
  sourceContainer: {
    backgroundColor: Colors.GREY_10,
  },
  plateName: {
    marginBottom: '0.5rem',
    width: '100%',
  },
  plateViz: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  },
});
