import React, { useCallback } from 'react';

import TableIcon from '@mui/icons-material/TableChartOutlined';

import { IntercomTourIDs } from 'common/lib/intercom';
import { Markdown } from 'common/lib/markdown';
import SpreadsheetStateContextProvider from 'common/rules/SpreadsheetStateContext';
import {
  SPREADSHEET_ANALYTICS_CATEGORY,
  SpreadsheetConfiguration,
} from 'common/types/spreadsheet';
import { DataTable } from 'common/types/spreadsheetEditor';
import Button from 'common/ui/components/Button';
import SpreadsheetEditorDialog from 'common/ui/components/Dialog/SpreadsheetEditorDialog';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import { CellEditorComponentType } from 'common/ui/components/Table';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

type Props = {
  buttonTitle?: string;
  /** If provided, a header is displayed showing this value  */
  dialogTitle?: string;
  /** If provided along with dialogTitle, an info icon will be displayed
   * with this value displayed within a popover on hover.  */
  dialogInfo?: Markdown;
  cellEditorComponent?: CellEditorComponentType;
  workflowName?: string;
  configuration: SpreadsheetConfiguration;
  onChange: (data: DataTable | DataTable[] | null) => void;
  onPendingChange?: (data: DataTable | DataTable[] | undefined) => void;
  // Should onChange return an array of DataTable rather than a single one?
  shouldReturnArray: boolean;
} & ParameterEditorBaseProps<DataTable | DataTable[]>;

export default function SpreadsheetEditor(props: Props) {
  const {
    value,
    isDisabled,
    configuration,
    cellEditorComponent,
    dialogTitle,
    dialogInfo,
    buttonTitle,
    workflowName,
    onChange,
    onPendingChange,
    shouldReturnArray,
  } = props;
  const classes = useStyles();
  const [spreadsheetDialog, openSpreadsheetDialog] = useDialog(SpreadsheetEditorDialog);

  const handleOpenDialog = useCallback(async () => {
    const openedAt = performance.now();
    logEvent('spreadsheet-dialog-opened', SPREADSHEET_ANALYTICS_CATEGORY);
    const newData = await openSpreadsheetDialog({
      data: value,
      configuration,
      cellEditorComponent,
      title: dialogTitle,
      info: dialogInfo,
      workflowName,
      shouldReturnArray,
      isReadonly: !!isDisabled,
      onChange: onPendingChange,
    });
    onChange(newData);

    const sheetsRows = [];
    if (!Array.isArray(newData)) {
      sheetsRows.push(newData?.data.length ?? 0);
    } else {
      newData?.forEach(table => {
        sheetsRows.push(table.data.length);
      });
    }
    const closedAt = performance.now();
    logEvent(
      'spreadsheet-dialog-open-time-in-seconds',
      SPREADSHEET_ANALYTICS_CATEGORY,
      `${Math.floor((closedAt - openedAt) / 1000).toString(
        10,
      )} seconds - (${sheetsRows.map(
        (rowsCount, index) => `Sheet ${index + 1} had ${rowsCount} rows`,
      )})`,
    );
  }, [
    openSpreadsheetDialog,
    value,
    configuration,
    cellEditorComponent,
    dialogTitle,
    dialogInfo,
    workflowName,
    shouldReturnArray,
    isDisabled,
    onPendingChange,
    onChange,
  ]);

  function getDefaultTitle() {
    if (isDisabled) {
      return 'View Spreadsheet';
    }

    const hasRowsWithData = Array.isArray(value)
      ? value.some(sheet => sheet.data.length > 0)
      : value?.data.length;

    if (!hasRowsWithData) {
      return 'Add Spreadsheet';
    }

    return 'Edit Spreadsheet';
  }

  return (
    <SpreadsheetStateContextProvider configuration={configuration} data={value}>
      <Button
        data-intercom-target={`${IntercomTourIDs.ELISA_SPREADSHEET}-spreadsheet-open`}
        variant="secondary"
        onClick={handleOpenDialog}
      >
        <TableIcon />
        <span className={classes.btnTitle}>{buttonTitle || getDefaultTitle()}</span>
      </Button>
      {spreadsheetDialog}
    </SpreadsheetStateContextProvider>
  );
}

const useStyles = makeStylesHook(theme => ({
  btnTitle: {
    marginLeft: theme.spacing(4),
  },
}));
