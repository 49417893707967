import React, { useMemo, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';
import entries from 'lodash/entries';

import { getFactorName } from 'client/app/components/DOEBuilder/factorUtils';
import { FactorItem } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  value: string | undefined;
  factors: FactorItem[];
  errorMsg?: string;
  isReadonly: boolean;
  onChange: (derivingExpression: string) => void;
};

export default function FactorDerivingExpression({
  value,
  factors,
  errorMsg,
  isReadonly,
  onChange: updateDerivingExpression,
}: Props) {
  const classes = useStyles();

  const [variablesMap, factorsMap] = useFactorVariableMapping(factors);

  const [textValue, setTextValue] = useState<string>(() =>
    getReadableFunctionExpression(value, factorsMap),
  );
  const handleChange = useTextFieldChange(setTextValue);
  const handleBlur = () => {
    let result = textValue;

    for (const [variable, factor] of variablesMap) {
      result = result.replaceAll(variable, `{${factor.id}}`);
    }

    result = DOMPurify.sanitize(result);
    result = result.trim();

    updateDerivingExpression(result);
  };

  const expressionTextFieldRef = useRef<HTMLInputElement | undefined>();
  const addVariable = (factorName: string) => {
    setTextValue(s => s + `{${factorName}}`);
    expressionTextFieldRef.current?.focus();
  };

  return (
    <>
      <TextField
        value={textValue}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Deriving expression"
        error={!!errorMsg}
        helperText={errorMsg}
        disabled={isReadonly}
        inputRef={ref => (expressionTextFieldRef.current = ref)}
      />
      <main className={classes.container}>
        <Typography variant="caption" color="textSecondary">
          Click on a factor to add it to the Equation
        </Typography>
        <Box className={classes.variables}>
          {entries(variablesMap).map(([_, factor]) => (
            <Chip
              key={factor.id}
              label={getFactorName(factor)}
              className={classes.chip}
              onClick={() => addVariable(getFactorName(factor))}
            />
          ))}
        </Box>
      </main>
    </>
  );
}

/**
 * Here we map variables to factor IDs to rely on direct variable mapping.
 *
 * {Glycerol} -> Glycerol UUID
 *
 * {Enzyme} -> Enzyme UUID
 *
 * etc.
 */
function useFactorVariableMapping(
  factors: FactorItem[],
): [Map<string, FactorItem>, Map<string, string>] {
  return useMemo(() => {
    const variablesMap = new Map<string, FactorItem>();
    const factorsMap = new Map<string, string>();

    factors.forEach(factor => {
      const variableName = `{${getFactorName(factor)}}`;
      variablesMap.set(variableName, factor);
      factorsMap.set(factor.id, variableName);
    });

    return [variablesMap, factorsMap];
  }, [factors]);
}

function getReadableFunctionExpression(
  derivingExpression: string = '',
  factorsMap: Map<string, string>,
) {
  let result = derivingExpression;
  for (const [factorId, variable] of factorsMap) {
    result = result.replaceAll(`{${factorId}}`, variable);
  }
  return result;
}

const useStyles = makeStylesHook(({ spacing }) => ({
  container: {
    background: Colors.BLUE_0,
    border: `1px solid ${Colors.BLUE_5}`,
    borderRadius: spacing(3),
    padding: spacing(4, 5),
    marginTop: spacing(5),
  },
  variables: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(3),
    margin: spacing(4, 0, 0),
  },
  chip: {
    fontWeight: 400,
    backgroundColor: Colors.BLUE_5,
    border: `1px solid ${Colors.BLUE_5}`,

    '&:hover': {
      cursor: 'pointer',
      border: `1px solid ${Colors.BLUE_10}`,
      backgroundColor: Colors.BLUE_5,
    },
  },
}));
