import React, { useCallback, useContext, useEffect, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import { FeatureTogglesFallback } from 'client/app/components/FeatureToggles/FeatureTogglesFallback';
import FeatureTogglesList from 'client/app/components/FeatureToggles/FeatureTogglesList';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { FeatureTogglesContext } from 'common/features/FeatureTogglesContext';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import Keys from 'common/ui/lib/keyboard';

const SHORTCUT_KEYS = new Set(
  // some platforms report the value as '!' others as '1'
  [Keys.ONE, Keys.BANG],
);

export function FeatureTogglesDialog() {
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reloadOnClose, setReloadOnClose] = useState(false);
  const uiFeatureToggles = useContext(FeatureTogglesContext);
  const currentUser = useUserProfile();

  const closeDialog = useCallback(() => {
    setDialogOpen(false);
    if (reloadOnClose) {
      // Reload on a zero timeout per
      // https://stackoverflow.com/questions/13306492/location-reload
      setTimeout(() => {
        location.reload();
      }, 0);
    }
  }, [reloadOnClose, setDialogOpen]);

  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (SHORTCUT_KEYS.has(e.key) && e.shiftKey && (e.ctrlKey || e.metaKey)) {
        // Let the keyboard shortcut both open and close the dialog
        if (!dialogOpen) {
          setDialogOpen(true);
          uiFeatureToggles.setHasOpenedFeatureTogglesDialog();
        } else {
          closeDialog();
        }
      }
    };
    window.addEventListener('keydown', keyHandler);
    return () => {
      window.removeEventListener('keydown', keyHandler);
    };
  }, [closeDialog, dialogOpen, setDialogOpen, uiFeatureToggles]);

  return (
    <Dialog open={dialogOpen} onClose={closeDialog} maxWidth="md" scroll="paper">
      {currentUser?.email.includes('synthace.com') ? (
        <>
          <DialogTitle className={classes.content}>
            <Typography variant="h3">Internal Tooling</Typography>
            <Typography variant="h3" className={classes.margin}>
              Upcoming Features
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <FeatureTogglesList
              isVisible={dialogOpen}
              onChange={setReloadOnClose}
              isInternalTooling
            />
            <div className={classes.margin}>
              <FeatureTogglesList
                isVisible={dialogOpen}
                onChange={setReloadOnClose}
                isInternalTooling={false}
              />
            </div>
            {reloadOnClose && (
              <Typography variant="caption" align="center">
                Antha will reload to apply changes.
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="tertiary" onClick={closeDialog}>
              Close
            </Button>
          </DialogActions>
        </>
      ) : (
        <FeatureTogglesFallback />
      )}
    </Dialog>
  );
}

const useStyles = makeStylesHook(theme => ({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    borderBottom: `1px solid ${Colors.GREY_DIVIDER}`,
  },
  margin: {
    marginLeft: theme.spacing(3),
  },
}));
