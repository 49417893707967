import { ApolloClient } from '@apollo/client';

import { getResultOrThrow } from 'client/app/api/apolloClient';
import {
  MUTATION_CREATE_FAKE_EXECUTION,
  MUTATION_EXECUTE_SIMULATION,
} from 'client/app/api/gql/mutations';
import { Fragments } from 'client/app/api/gql/queries';
import {
  CreateFakeExecutionMutation,
  CreateFakeExecutionMutationVariables,
  ExecuteSimulationAction,
  ExecuteSimulationMutation,
  ExecuteSimulationMutationVariables,
  ExecutionForSimulationFragment,
  SimulationOrExecutionStatusesEnum,
} from 'client/app/gql';

class ExecutionClient {
  apollo: ApolloClient<object>;
  constructor(apollo: ApolloClient<object>) {
    this.apollo = apollo;
  }
  async executeSimulation(
    simulationId: SimulationId,
  ): Promise<ExecutionForSimulationFragment | null> {
    console.debug(`Executing simulation ${simulationId}`);
    const executionResult = await this.apollo.mutate<
      ExecuteSimulationMutation,
      ExecuteSimulationMutationVariables
    >({
      mutation: MUTATION_EXECUTE_SIMULATION,
      variables: {
        simulationId,
      },
      update(cache, response) {
        const newExecution = response.data?.executeSimulation;
        cache.modify({
          id: cache.identify({ __typename: 'Simulation', id: simulationId }),
          fields: {
            transitiveStatus() {
              return SimulationOrExecutionStatusesEnum.EXECUTION_SCHEDULED;
            },
            execution() {
              return cache.writeFragment({
                data: newExecution,
                fragment: Fragments.ExecutionForSimulation,
                variables: {
                  withTasks: true,
                },
              });
            },
          },
        });
      },
    });
    return getResultOrThrow<ExecuteSimulationMutation, ExecutionForSimulationFragment>(
      executionResult,
      'Execute simulation',
      data => data.executeSimulation,
    );
  }
  async createFakeExecution(
    simulationId: SimulationId,
    action?: ExecuteSimulationAction,
  ): Promise<ExecutionForSimulationFragment | null> {
    const executionResult = await this.apollo.mutate<
      CreateFakeExecutionMutation,
      CreateFakeExecutionMutationVariables
    >({
      mutation: MUTATION_CREATE_FAKE_EXECUTION,
      variables: {
        simulationId,
        action,
      },
      update(cache, response) {
        const newExecution = response.data?.createFakeExecution;

        cache.modify({
          id: cache.identify({ __typename: 'Simulation', id: simulationId }),
          fields: {
            transitiveStatus() {
              return action === ExecuteSimulationAction.MARK_AS_COMPLETED
                ? SimulationOrExecutionStatusesEnum.EXECUTION_COMPLETED
                : SimulationOrExecutionStatusesEnum.EXECUTION_IN_PROGRESS;
            },
            execution() {
              return cache.writeFragment({
                data: newExecution,
                fragment: Fragments.ExecutionForSimulation,
                variables: {
                  withTasks: true,
                },
              });
            },
          },
        });
      },
    });
    return getResultOrThrow<CreateFakeExecutionMutation, ExecutionForSimulationFragment>(
      executionResult,
      'Create fake execution',
      data => data.createFakeExecution,
    );
  }
}

export default ExecutionClient;
