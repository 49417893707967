import React, { useState } from 'react';

import PolicyCard from 'client/app/apps/policy-library/PolicyCard';
import Policies, { Policy } from 'client/app/lib/policies';
import Colors from 'common/ui/Colors';
import CardGrid from 'common/ui/components/CardGrid';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import NoResultsFound from 'common/ui/components/NoResultsFound';
import SearchField from 'common/ui/components/SearchField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  onPick?: (policy: string) => void;
  onQuickPick?: (policy: string) => void;
  selectedId?: string;
};

export default function PolicyLibraryApp(props: Props) {
  const classes = useStyles();
  const { onPick, selectedId, onQuickPick } = props;
  const [filterQuery, setFilterQuery] = useState('');

  const filteredItems = computeFilteredPolicies(Policies, filterQuery);
  const renderedItems = filteredItems.map((item, index) => (
    <PolicyCard
      key={index.toString()}
      onPick={onPick}
      onQuickPick={onQuickPick}
      policy={item}
      selected={selectedId === item.id}
    />
  ));

  return (
    <div className={classes.content}>
      <ContainerWithIntersectionBar
        filters={
          <SearchField onQueryChange={setFilterQuery} placeholder="Search" addMargin />
        }
        content={
          <CardGrid>
            {filterQuery !== '' && renderedItems.length === 0 && (
              <NoResultsFound resultLabel="liquid policies" />
            )}
            {renderedItems}
          </CardGrid>
        }
      />
    </div>
  );
}

function computeFilteredPolicies(policiesArray: Policy[], filterQuery: string): Policy[] {
  const lowerCaseQuery = filterQuery.toLowerCase();

  return policiesArray.filter(policy => {
    const { name, description, id } = policy;
    return (
      name.toLowerCase().includes(lowerCaseQuery) ||
      description.toLowerCase().includes(lowerCaseQuery) ||
      id.toLowerCase().includes(lowerCaseQuery)
    );
  });
}

const useStyles = makeStylesHook({
  content: {
    background: Colors.GREY_5,
    flex: 1,
    overflow: 'hidden',
  },
});
