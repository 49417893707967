import {
  Cap,
  Deck,
  ErrorAction,
  Lid,
  Plate,
  PromptAction,
  Tipwaste,
  WellLocationOnDeckItem,
} from 'common/types/mix';
import { LiquidMovement, MixPreviewStep } from 'common/types/mixPreview';
import {
  getDeckItemStates,
  updatePlate,
} from 'common/ui/components/simulation-details/mix/deckContents';
import moveLabware from 'common/ui/components/simulation-details/mix/moveLabware';
import {
  markTipsUsed,
  refreshTipboxes,
  TipboxState,
} from 'common/ui/components/simulation-details/mix/TipboxState';

// Fully describes everything needed to render the MixView at a fixed step.
export type MixState = {
  // The step at which we are.
  // For example: "currentStep is 1 <=> we've applied one step".
  // This matches the value of the slider in the UI.
  currentStep: number;
  // The estimated time the robot will spend to get to this step
  timeElapsed: number;
  // Prompts that happened up to current step
  prompts: readonly { stepNumber: number; prompt: PromptAction }[];
  // Errors that happened up to current step
  errors: readonly { stepNumber: number; error: ErrorAction }[];
  // Contents of the deck at this step.
  // Note the deck contents can be filtered - see `filterState`.
  deck: DeckState;
  // Edges visible at this step.
  // Note the edges can be filtered - see `filterState`.
  edges: readonly Edge[];
  // All tipbox replacements that happened up to this step
  tipboxReplacements: readonly TipboxReplacement[];
  // Locations on the deck to highlight for the current step, such as location
  // of tip cleaner
  highlightedDeckPositionNames: ReadonlySet<string>;
};

// Lists tipboxes replaced at a specific step
export type TipboxReplacement = {
  // We highlight this step on the slider, so it's easy for users to tell when
  // they have to replace a tipbox.
  step: number;
  // The tipboxes (usually just one) that need to replaced at this step.
  replacedTipboxIds: readonly string[];
};

export type DeckItemStateCommonProps = {
  /**
   * Name of a deck position where the deck item currently is,
   * for example "hx://6T-7/1_DWPNestRB".
   * Deck items can physically move around during the execution, therefore
   * we need to know where each item is located at any given step of the execution.
   */
  currentDeckPositionName: string;
  /**
   * The rotation of the plate in the clockwise direction, from 0 to 360.
   */
  currentRotationDegrees: number;
};

export type PlateState = Plate & DeckItemStateCommonProps;

export type TipwasteState = Tipwaste & DeckItemStateCommonProps;

export type LidState = Lid & DeckItemStateCommonProps;

export type CapState = Cap & DeckItemStateCommonProps;

export type DeckItemState =
  | CapState
  | PlateState
  | LidState
  | TipboxState
  | TipwasteState;

// Contents of deck items at this step
export type DeckState = {
  items: readonly DeckItemState[];
};

type EdgeCommon = {
  /**
   * Step at which this edge appears
   */
  stepNumber: number;
};

/**
 * An edge which shows the movement of liquid from one place to another
 */
type LiquidMovementEdge = {
  from: WellLocationOnDeckItem;
  to: WellLocationOnDeckItem;
  /**
   * Object describing the movement of something
   */
  action: LiquidMovement;
  /**
   * Number of the channel.
   */
  channel: number;
  /**
   * List of all channels that aspirated from this well during this step. This
   * is used to determine the start point of the edge within the well.
   */
  channelsAspiratingFromWell: number[];
  /**
   * List of all channels that dispensed to this well during this step. This
   * is used to determine the end point of the edge within the well.
   */
  channelsDispensingToWell: number[];
} & EdgeCommon;

/**
 * Transfer of liquid from one place to another using a tip
 */
export type LiquidTransferEdge = {
  type: 'liquid_transfer';
} & LiquidMovementEdge;

/**
 * A `LiquidDispenseEdge` shows the provenance of a liquid currently being
 * dispensed at a given location. Similar to `LiquidTransferEdge` this manifests
 * as an arrow from a source to a destination, but in this case the liquid is
 * already in the tip as part of a multidispense. The arrow in the UI is dashed
 * to distinguish it from a `LiquidTransferEdge`.
 */
export type LiquidDispenseEdge = {
  type: 'liquid_dispense';
} & LiquidMovementEdge;

/**
 * A `FiltrationEdge` shows liquid falling from the bottom of a filter (e.g. a
 * robocolumn) into a well. A liquid transfer which involves filtering will be
 * structured with the following (see `SingleChannelTransfer` type for full
 * structure):
 * - `from`: the start location of the liquid
 * - `filter`: the location of the robocolumn
 * - `to[]`: the destination of the liquid after it has fallen out of the
 *   filter. (Note: this array will only ever have one element since
 *   multi-dispenses are split into separate steps)
 *
 * A transfer with `filter` shows as two arrows:
 * - one LiquidTransferEdge/LiquidDispenseEdge from `from` to `filter`
 * - one FiltrationEdge from `from` to `to[0]`
 */
export type FiltrationEdge = {
  type: 'filtration';
} & LiquidMovementEdge;

export type MoveLabwareEdge = {
  type: 'move_plate';
  /**
   * Deck position name (e.g. TecanPos_1_1 or  hx://BioTek_405TS_Landscape_0001/1)
   * the plate is moving from.
   */
  fromDeckPositionName: string;
  /**
   * Deck position name (e.g. TecanPos_1_1 or  hx://BioTek_405TS_Landscape_0001/1)
   * the plate is moving to.
   */
  toDeckPositionName: string;
  deckItemTypes: DeckItemState['kind'][];
} & EdgeCommon;

/**
 * The MixView shows an arrow for each Edge, which includes liquid transfers and
 * plate movements.
 */
export type Edge =
  | LiquidTransferEdge
  | MoveLabwareEdge
  | LiquidDispenseEdge
  | FiltrationEdge;

/**
 * Any kind of edge that represents the movement of liquid. Used to determine which edges
 * to show when filtering the preview (e.g. by enabling well provenance view).
 */
export function isLiquidMovementEdge(
  edge: Edge,
): edge is LiquidTransferEdge | LiquidDispenseEdge {
  return (
    edge.type === 'liquid_transfer' ||
    edge.type === 'liquid_dispense' ||
    edge.type === 'filtration'
  );
}

/**
 * State at the very start, with initial well contents, before any steps happen.
 * You'll usually want to call `getInitialState(mixPreviewFromBackend.deck)`.
 */
export function getInitialState(deck: Deck): MixState {
  return {
    currentStep: 0,
    timeElapsed: 0,
    prompts: [],
    errors: [],
    deck: { items: getDeckItemStates(deck) },
    edges: [],
    tipboxReplacements: [],
    highlightedDeckPositionNames: new Set(),
  };
}

export function isSameLocation(
  locationA: WellLocationOnDeckItem,
  locationB: WellLocationOnDeckItem,
): boolean {
  return (
    locationA.deck_item_id === locationB.deck_item_id &&
    locationA.col === locationB.col &&
    locationA.row === locationB.row
  );
}

/**
 * Apply a single step on top of given state. Does not mutate the state. Returns a copy,
 * uses structural sharing.
 *
 * `noLabwareMovements` is used by the Plate Setup screen because there is not deck to
 * show these movements.
 */
export function applyStep(
  state: MixState,
  stepIndex: number,
  step: MixPreviewStep,
  noLabwareMovements?: boolean,
): MixState {
  // currentStep is 1 <=> we've applied one step (step index 0)
  // This is for consistency with the meaning of currentStep in the rest
  // of the codebase.
  const currentStep = stepIndex + 1;
  let newDeckItems: readonly DeckItemState[] = state.deck.items;
  const edgesToAdd: Edge[] = [];
  const highlightedDeckPositionNames: Set<string> = new Set();
  let replacedTipboxIds: readonly string[] = [];

  if (step.kind === 'parallel_transfer' || step.kind === 'parallel_dispense') {
    // Multiple pipettes each doing its thing
    for (const [channelKey, singleChannelStep] of Object.entries(step.channels)) {
      // A channel can only ever be an integer. This is defined in actions.schema.json.
      const channel = Number(channelKey);
      const targetState = singleChannelStep.liquidDestination;
      // It's inefficient to call updatePlate in a loop, because each
      // updatePlate call needs to find the same plate in the plates array
      newDeckItems = updatePlate(newDeckItems, singleChannelStep.from);
      newDeckItems = updatePlate(newDeckItems, targetState);

      const edgeType =
        step.kind === 'parallel_dispense' ? 'liquid_dispense' : 'liquid_transfer';

      const aspLocation = singleChannelStep.from.loc;
      const dspLocation = singleChannelStep.liquidDestination.loc;

      // Get list of all channels that are aspirating from this well during this step.
      // This is used by preview for determining the start position of the edge within the
      // source well.
      const channelsAspiratingFromWell = Object.keys(step.channels)
        .filter(channel => isSameLocation(step.channels[channel].from.loc, aspLocation))
        .map(Number);
      // Get list of all channels that are dispensing to this well during this step.
      const channelsDispensingToWell = Object.keys(step.channels)
        .filter(channel =>
          isSameLocation(step.channels[channel].liquidDestination.loc, dspLocation),
        )
        .map(Number);

      // If this liquid transfer includes an intermediary 'filter' (i.e. the
      // liquid is passed through a filter), then the tip is dispensing at the
      // `filter` location, not `to`. For Filter Plates, the liquid is already
      // on the filter, so the `from` and `filter` locations are the same.
      // Thus we show up to two edges:
      //   1. going from the `from` location to `to`, if these are different
      //   2. liquid  dropping from the filter.
      if (targetState.filter) {
        if (
          !isSameLocation(
            singleChannelStep.from.loc,
            singleChannelStep.tipDestination.loc,
          )
        ) {
          edgesToAdd.push({
            type: edgeType,
            stepNumber: currentStep,
            channel,
            channelsAspiratingFromWell,
            channelsDispensingToWell,
            action: singleChannelStep,
            from: singleChannelStep.from.loc,
            to: singleChannelStep.tipDestination.loc,
          });
        }
        edgesToAdd.push({
          type: 'filtration',
          stepNumber: currentStep,
          channel,
          channelsAspiratingFromWell,
          channelsDispensingToWell,
          action: singleChannelStep,
          from: singleChannelStep.tipDestination.loc,
          to: singleChannelStep.liquidDestination.loc,
        });
      } else {
        edgesToAdd.push({
          type: edgeType,
          stepNumber: currentStep,
          channel,
          channelsAspiratingFromWell,
          channelsDispensingToWell,
          action: singleChannelStep,
          from: singleChannelStep.from.loc,
          to: singleChannelStep.tipDestination.loc,
        });
      }
    }
  }
  if (step.kind === 'load') {
    const tipLocations = Object.values(step.channels);
    newDeckItems = markTipsUsed(newDeckItems, tipLocations, currentStep);
  }
  let newPrompts = state.prompts;
  if (step.kind === 'prompt') {
    newPrompts = [...state.prompts, { stepNumber: currentStep, prompt: step }];
    // Some prompts get the user to update the state of some wells, so update
    // the plates to reflect those changes
    if (step.effects) {
      for (const singleLocationUpdate of Object.values(step.effects)) {
        newDeckItems = updatePlate(newDeckItems, singleLocationUpdate);
      }
    }
  }
  let newErrors = state.errors;
  if (step.kind === 'error') {
    newErrors = [...state.errors, { stepNumber: currentStep, error: step }];
  }
  if (step.kind === 'tipbox_refresh') {
    replacedTipboxIds = step.tipboxes_to_refresh.map(tipboxRefresh => tipboxRefresh.id);
    newDeckItems = refreshTipboxes(newDeckItems, replacedTipboxIds, currentStep);
  }
  if (step.kind === 'move_plate' && !noLabwareMovements) {
    const { updatedDeckItems, edges } = moveLabware(
      newDeckItems,
      step.effects,
      currentStep,
    );
    newDeckItems = updatedDeckItems;
    edgesToAdd.push(...edges);
  }
  if (step.kind === 'tip_wash') {
    // Highlight each of the deck positions used for washing tips
    for (const location of step.tip_wash_positions) {
      highlightedDeckPositionNames.add(location.position);
    }
  }
  const newDeck = { ...state.deck, items: newDeckItems };
  const tipboxReplacements =
    replacedTipboxIds.length === 0
      ? state.tipboxReplacements
      : [...state.tipboxReplacements, { step: currentStep, replacedTipboxIds }];
  return {
    currentStep,
    deck: newDeck,
    // TODO: We need a linked list here to avoid copying the whole list
    //       on each step. Now applying n steps is O(n^2).
    edges: [...state.edges, ...edgesToAdd],
    prompts: newPrompts,
    errors: newErrors,
    timeElapsed: step.cumulative_time_estimate,
    tipboxReplacements,
    highlightedDeckPositionNames,
  };
}
