import { default as axios } from 'axios';

import { Auth0Config } from 'common/types/auth0';

const CONFIG_LOCAL_STORAGE_KEY = 'auth0-config';
const CACHE_TTL_MILLISECONDS = 60 * 60 * 1000;

type CachedConfig = {
  cachedAt: number;
  config: Auth0Config;
};

export async function getAuth0Config(): Promise<Auth0Config> {
  // Check to see if we have cached config in local storage.
  const cachedJSON = localStorage.getItem(CONFIG_LOCAL_STORAGE_KEY);
  if (cachedJSON) {
    const cachedConfig = JSON.parse(cachedJSON) as CachedConfig;

    // If there's cached config and it was cached within the last
    // CACHE_TTL_MILLISECONDS, use that.
    if (Date.now() - cachedConfig.cachedAt <= CACHE_TTL_MILLISECONDS) {
      return cachedConfig.config;
    }
  }

  // We don't have fresh, cached config; fetch from the server.
  const response = await axios.get('/.well-known/auth0config.json');
  const config = response.data;
  const toCache: CachedConfig = {
    config: config,
    cachedAt: Date.now(),
  };
  // Cache it for next time
  localStorage.setItem(CONFIG_LOCAL_STORAGE_KEY, JSON.stringify(toCache));
  return config;
}
