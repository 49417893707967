import React, { useState } from 'react';

import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import doNothing from 'common/lib/doNothing';
import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type ExperimentChipProps = {
  href: string;
  firstName: string;
  lastName: string;
  name: string;
  isAuthoredByCurrentUser?: boolean;
  showFocusIndicator?: boolean;
};

/**
 * Chip component which will show basic information about an experiment.
 */

export default function ExperimentChip(props: ExperimentChipProps) {
  const classes = useStyles();
  const { href, firstName, lastName, name, isAuthoredByCurrentUser, showFocusIndicator } =
    props;

  const [isMouseHoveringChip, setIsMouseHoveringChip] = useState(false);

  const focusIndicator = showFocusIndicator ? <span>•</span> : undefined;
  // We use the Mui deleteIcon to show the focusIndicator on the Chip
  // and this requires us to pass in a handler to onDelete, even though we don't want
  // the deleteIcon to do anything. This is an accepted use of the API as demoed in Mui docs:
  // https://v4.mui.com/components/chips/#chip-2
  const onDelete = showFocusIndicator ? doNothing : undefined;

  return (
    <Chip
      className={cx(classes.chip, {
        [classes.chipHover]: isMouseHoveringChip,
      })}
      classes={{ label: cx({ [classes.labelWithFocusIndicator]: showFocusIndicator }) }}
      onMouseEnter={() => setIsMouseHoveringChip(true)}
      onMouseLeave={() => setIsMouseHoveringChip(false)}
      clickable
      label={<TypographyWithTooltip variant="subtitle2">{name}</TypographyWithTooltip>}
      icon={
        <Tooltip title={`${firstName} ${lastName}`}>
          <Avatar
            className={cx(classes.avatar, {
              [classes.avatarCurrentUser]: isAuthoredByCurrentUser,
            })}
          >
            <Typography variant="caption">{`${firstName.charAt(0)}${lastName.charAt(
              0,
            )}`}</Typography>
          </Avatar>
        </Tooltip>
      }
      variant="outlined"
      deleteIcon={focusIndicator}
      onDelete={onDelete}
      component="a"
      href={href}
    />
  );
}

export function ExperimentChipSkeleton() {
  const classes = useStyles();
  return (
    <div className={classes.skeleton}>
      <Skeleton variant="circular" className={classes.avatar} />
      <Skeleton variant="text" className={classes.labelSkeleton} />
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  chip: {
    border: `1px solid ${Colors.GREY_30}`,
    width: '100%',
    justifyContent: 'start',
    paddingRight: theme.spacing(3),
    '& .MuiChip-icon': {
      color: Colors.WHITE,
    },
    '& .MuiChip-deleteIcon': {
      marginLeft: 'auto',
      marginRight: '-6px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '22px',
      minWidth: '22px',
      color: theme.palette.primary.dark,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
  chipHover: {
    '&&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  avatar: {
    width: '24px',
    height: '24px',
    backgroundColor: Colors.DARK_TEXT_SECONDARY,
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  avatarCurrentUser: {
    backgroundColor: theme.palette.primary.dark,
  },
  skeleton: {
    display: 'flex',
    height: '32px',
    alignItems: 'center',
    justifyContent: 'start',
    gap: theme.spacing(2),
  },
  labelSkeleton: {
    width: '220px',
    height: '24px',
    backgroundColor: Colors.DARK_TEXT_SECONDARY,
  },
  labelWithFocusIndicator: {
    paddingRight: 0,
  },
}));
