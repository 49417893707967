import React from 'react';

import { useQuery } from '@apollo/client';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import { withRouter } from 'react-router-dom';

import { QUERY_AVAILABLE_ROLES, QUERY_USERS_ROLES } from 'client/app/api/gql/queries';
import { usePermissions } from 'client/app/components/Permissions';
import { UserTable } from 'client/app/components/UserTable/UserTable';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { UserInitialsIcon } from 'common/ui/icons/UserInitialsIcon';
import { theme } from 'common/ui/theme';

const NOT_ADMIN_COPY =
  'Expecting to see other users here? Contact an admin to update your role.';

const HeadingWithValue = ({
  heading,
  value,
  className,
}: {
  heading: string;
  value?: string;
  className?: string;
}) => {
  return (
    <div className={className}>
      <Typography variant="subtitle1">{heading}</Typography>
      <br />
      <Typography>{value}</Typography>
    </div>
  );
};

const ProfileScreen = function () {
  const classes = useStyles();
  const userProfile = useUserProfile();

  const { data: userData } = useQuery(QUERY_USERS_ROLES);
  const { data: roleData } = useQuery(QUERY_AVAILABLE_ROLES);

  const orgUsers = userData?.users;

  const { currentUserHasPermission } = usePermissions();
  const hasUserPermissions = currentUserHasPermission('update:users', 'read:users');

  return (
    <Container maxWidth="xs">
      <Stack paddingTop={7} paddingBottom={5} gap={9}>
        <div className={classes.iconContainer}>
          <UserInitialsIcon size="large" userDisplayName={userProfile?.displayName} />
        </div>
        <Stack gap={4}>
          <Stack direction="row" justifyContent="space-between">
            <HeadingWithValue
              heading="Name"
              value={userProfile?.displayName}
              className={classes.userDetails}
            />
            <HeadingWithValue
              heading="Email"
              value={userProfile?.email}
              className={classes.userDetails}
            />
          </Stack>
          <HeadingWithValue
            heading="Organisation"
            value={userProfile?.organisationHumanIdentifier}
            className={classes.userDetails}
          />
        </Stack>
      </Stack>
      <Divider />
      {hasUserPermissions ? (
        <UserTable
          userProfile={userProfile}
          orgUsers={orgUsers}
          availableRoles={roleData?.roles}
        />
      ) : (
        <div className={classes.nonAdminView}>
          <Typography variant="caption">{NOT_ADMIN_COPY}</Typography>
        </div>
      )}
    </Container>
  );
};

const useStyles = makeStylesHook(
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    iconContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    userDetails: {
      display: 'grid',
      gridTemplateColumns: '50% 50%',
      gridRow: 'auto auto',
    },
    nonAdminView: {
      paddingTop: '20px',
      textAlign: 'center',
    },
  }),
);

export default withRouter(ProfileScreen);
