import { getDeviceConfigurationForUI } from 'client/app/lib/workflow/deviceConfigUtils';
import { State } from 'client/app/state/WorkflowBuilderStateContext';

/**
 * Performs a check to determine if the current workflow should require a device
 * to be selected or not. Primarily used for determining the `requiresDevice`
 * value when the `resetWorkflow` dispatch is performed on workflow builder load.
 */
export function workflowRequiresDevice(state: State) {
  if (state.config.GlobalMixer.requiresDevice !== undefined) {
    return state.config.GlobalMixer.requiresDevice;
  }
  const uiConfig = getDeviceConfigurationForUI(state.config);
  const hasDevices = Object.keys(uiConfig).length > 0;
  const hasElements = state.elementInstances.length > 0;
  // If the workflow is an existing workflow with `requiresDevice` not set, we have to determine what
  // value to set `requiresDevice` to. We make an assumption that, if the workflow has not had any
  // devices or elements added to it already, it's probably an "empty" workflow and `requiresDevice`
  // should be set to true.
  // If the user has selected devices, or added elements, it tells us it's likely an existing/edited
  // workflow, so in that case we just return if there are devices in the workflow or not.
  if (hasDevices || hasElements) {
    return hasDevices;
  }
  return true;
}
