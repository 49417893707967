import React from 'react';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

type Props = WithStyles<typeof styles>;

class UILeftRight extends React.Component<Props> {
  render() {
    const { classes } = this.props;
    if (!this.props.children || !Array.isArray(this.props.children)) {
      throw new Error('You must pass two children to UILeftRight');
    }

    const [left, right] = this.props.children;
    return (
      <div className={classes.leftRight}>
        {left && <div className={classes.left}>{left}</div>}
        {right && <div className={classes.right}>{right}</div>}
      </div>
    );
  }
}

const styles = createStyles({
  leftRight: {
    display: 'flex',
  },
  left: {
    alignItems: 'center',
    display: 'flex',
    flex: 'auto',
    justifyContent: 'flex-start',
  },
  right: {
    alignItems: 'center',
    display: 'flex',
    flex: 'auto',
    justifyContent: 'flex-end',
  },
});

export default withStyles(styles)(UILeftRight);
