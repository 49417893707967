import React, { useMemo } from 'react';

import { formatWellPosition } from 'common/lib/format';
import { WellContents, WellLocationOnDeckItem } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import AxisLabels from 'common/ui/components/simulation-details/mix/AxisLabels';
import { getWellContentsHelper } from 'common/ui/components/simulation-details/mix/deckContents';
import { DeckItemWithWellsGeometry } from 'common/ui/components/simulation-details/mix/DeckLayout';
import InteractiveWell from 'common/ui/components/simulation-details/mix/InteractiveWell';
import { WellTooltipTitleProps } from 'common/ui/components/simulation-details/mix/InteractiveWell';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import { WellLabelContent } from 'common/ui/components/simulation-details/mix/WellLabel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type Props = {
  geometry: DeckItemWithWellsGeometry;
  plate: PlateState;
  liquidColors: LiquidColors;
  /**
   * Wells to visually outline on the plate
   */
  highlightedWells?: readonly WellLocationOnDeckItem[];
  /**
   * Wells to show in a disabled state on the plate with disabled interactions.
   * Tooltips will still appear on disabled wells.
   */
  disabledWells?: readonly WellLocationOnDeckItem[];
  /**
   * Show all wells in a disabled state on the plate with disabled interactions.
   * Will override disabledWells property.
   * Tooltips will still appear on disabled wells.
   */
  disableAllWells?: boolean;
  /**
   * Display the highlightedWells in an error state.
   */
  hasError?: boolean;
  /**
   * Within each well, show text to describe its content.
   */
  showContentLabels?: boolean;
  /**
   * By default, if showContentLabels is true, we will display a label on the well
   * describing the contents. This label can be overriden by passing in this function
   * to return an updated WellLabelContent based on the well (in 'A1' format).
   */
  getContentLabel?: (well: string, wellContents?: WellContents) => WellLabelContent;
  /**
   * On hover, show the contents of the well or "Empty well <well location>"
   */
  showContentTooltip?: boolean;
  /**
   * By default the tooltip title will be formatted per LegacyWellTooltipTitle but
   * this can be overridden using this function component.
   */
  TooltipTitle?: React.FunctionComponent<WellTooltipTitleProps>;
  /**
   * Allow clicking on wells and row/column headers
   */
  isInteractive?: boolean;
  onColHeaderClick?: (col: number) => void;
  onRowHeaderClick?: (row: number) => void;
  onWellPointerDown?: (loc: WellLocationOnDeckItem, e: React.PointerEvent) => void;
  onWellPointerUp?: (loc: WellLocationOnDeckItem, e: React.PointerEvent) => void;
  onWellMouseEnter?: (loc: WellLocationOnDeckItem, e: React.MouseEvent) => void;
  onWellMouseLeave?: (loc: WellLocationOnDeckItem, e: React.MouseEvent) => void;
  /**
   * Handler attached to the plate rectangle.
   */
  onPlatePointerDown?: (e: React.PointerEvent) => void;
  /** Ref attached to the rect that represents the geometric shape and size of the plate. */
  plateRectRef?: React.RefObject<SVGRectElement> | null;
};

function getColorForWellContents(
  wellContents: WellContents | undefined,
  liquidColors: LiquidColors,
): string | undefined {
  if (!wellContents) {
    return;
  }
  switch (wellContents.kind) {
    case 'filter_matrix_summary':
      // TODO: this should return a pattern (e.g. crosshatch) for this filter
      // matrix T2667. For now we just make all filter matrixes coloured blue.
      return Colors.LIGHT_BLUE;

    // Handle liquid_summary and, for older simulations, undefined
    default:
      return liquidColors.getColorForWell(wellContents);
  }
}

/**
 * An interactive plate, with wells and column/row headers. This is used in the
 * Simulation details (Setup and Preview) and in the WellSelector. This component
 * should be used within an <svg> element.
 *
 * This component serves a similar purpose to the PlateVisualization component,
 * which is used for rendering plates within the plate library. These two
 * components could probably be merged at some point.
 */
export default function PlateLayout(props: Props) {
  const classes = useStyles();
  const {
    plate,
    geometry,
    liquidColors,
    highlightedWells,
    disabledWells,
    disableAllWells,
    hasError,
    getContentLabel,
    showContentLabels,
    showContentTooltip,
    TooltipTitle,
    isInteractive,
    onPlatePointerDown,
    onWellPointerDown,
    onWellPointerUp,
    onWellMouseEnter,
    onWellMouseLeave,
    onColHeaderClick,
    onRowHeaderClick,
    plateRectRef,
  } = props;

  const { width: plateWidth, height: plateHeight } = geometry.absolutePosInDeckPixels;

  const wells = useMemo(() => {
    const wells: JSX.Element[] = [];
    for (let row = 0; row < geometry.rows; row++) {
      for (let col = 0; col < geometry.columns; col++) {
        const wellContents = getWellContentsHelper(plate, col, row);
        const wellAbsolutePos = geometry.getWellRect(col, row);
        const wellLocationOnDeckItem = { deck_item_id: plate.id, row, col };
        const isHighlighted = highlightedWells?.some(
          loc => loc.row === row && loc.col === col,
        );
        const isDisabled =
          disableAllWells ||
          disabledWells?.some(loc => loc.row === row && loc.col === col);
        const color = getColorForWellContents(wellContents, liquidColors);
        const contentLabelOverride = getContentLabel?.(
          formatWellPosition(row, col),
          wellContents,
        );

        wells.push(
          <InteractiveWell
            key={row * geometry.columns + col}
            isHighlighted={isHighlighted}
            isDisabled={isDisabled}
            hasError={hasError}
            wellType={geometry.wellType}
            wellContents={wellContents}
            wellLocationOnDeckItem={wellLocationOnDeckItem}
            wellPos={wellAbsolutePos}
            color={color}
            onWellMouseEnter={onWellMouseEnter}
            onWellMouseLeave={onWellMouseLeave}
            onWellPointerUp={onWellPointerUp}
            onWellPointerDown={onWellPointerDown}
            showContentLabel={showContentLabels}
            showContentTooltip={showContentTooltip}
            contentLabelOverride={contentLabelOverride}
            tooltipTitleOverride={
              TooltipTitle && (
                <TooltipTitle
                  wellContents={wellContents}
                  wellLocationOnDeckItem={wellLocationOnDeckItem}
                />
              )
            }
          />,
        );
      }
    }
    return wells;
  }, [
    geometry,
    plate,
    highlightedWells,
    disableAllWells,
    disabledWells,
    liquidColors,
    getContentLabel,
    TooltipTitle,
    hasError,
    onWellMouseEnter,
    onWellMouseLeave,
    onWellPointerUp,
    onWellPointerDown,
    showContentLabels,
    showContentTooltip,
  ]);

  return (
    <>
      <rect
        x="0"
        y="0"
        width={plateWidth}
        height={plateHeight}
        className={classes.plate}
        onPointerDown={onPlatePointerDown}
        ref={plateRectRef}
      />
      <AxisLabels
        axis="horizontal"
        geometry={geometry}
        onLabelClick={onColHeaderClick}
        isInteractive={isInteractive}
      />
      <AxisLabels
        axis="vertical"
        geometry={geometry}
        onLabelClick={onRowHeaderClick}
        isInteractive={isInteractive}
      />
      {wells}
    </>
  );
}

const useStyles = makeStylesHook({
  plate: {
    stroke: Colors.GREY_20,
    fill: 'transparent',
    strokeWidth: 1,
    vectorEffect: 'non-scaling-stroke',
  },
});
