import React, { useCallback } from 'react';

import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { SimpleDevice } from 'common/types/device';
import Colors from 'common/ui/Colors';
import DeviceThumbnail from 'common/ui/components/DeviceThumbnail';
import Switch from 'common/ui/components/Switch';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { ExampleGalleryIcon } from 'common/ui/icons/ExampleGalleryIcon';

type Props = {
  device: SimpleDevice;
  error?: string;
  /** If present, this device is an accessible device that's part of a group of devices. */
  accessibleDeviceProps?: {
    /** The value of the on / off Switch for this accessible device (device enabled / disabled). */
    accessibleDeviceSwitchValue: boolean;
    /** The user toggled the on / off Switch for this accessible device. */
    onAccessibleDeviceEnabledChange: (isEnabled: boolean) => void;
  };
  /**
   * Custom description to the item to override default behaviour for showing model
   */
  description?: string;
};

/**
 * Component is used to show the exact list item with the device thumbnail, device info and a switch.
 * This component is similiar to the DeviceItem in common, but is a simpler version without the x.
 */
export default React.memo(function DeviceItem(props: Props) {
  const classes = useStyles();
  const { device, error, description } = props;

  const onSwitchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      props.accessibleDeviceProps?.onAccessibleDeviceEnabledChange(e.target.checked),
    [props],
  );
  return (
    <div
      key={device.id}
      className={cx(classes.item, {
        [classes.error]: !!error,
      })}
    >
      <div className={classes.imageWithText}>
        <DeviceThumbnail imageUrl={device.imageUrl} />

        {/* Check to see if we want ellipsis or two lines */}
        <div className={classes.deviceInfo}>
          <Typography
            variant="overline"
            color="textPrimary"
            className={classes.text}
            gutterBottom
          >
            <div className={cx(classes.exampleIconAndTitle)}>
              {device.contentSource === 'EXAMPLE' && (
                <Tooltip title="Example Device">
                  <div className={classes.exampleIcon}>
                    <ExampleGalleryIcon />
                  </div>
                </Tooltip>
              )}
              <div>{device.name}</div>
            </div>
          </Typography>
          <Typography variant="caption" color="textPrimary" className={classes.text}>
            {description ?? device.model}
          </Typography>
        </div>
      </div>

      {props.accessibleDeviceProps && (
        <Switch
          color="primary"
          checked={props.accessibleDeviceProps.accessibleDeviceSwitchValue ?? false}
          onChange={onSwitchChange}
        />
      )}
    </div>
  );
});

const useStyles = makeStylesHook(theme => ({
  contentSource: {
    wordBreak: 'break-word',
    fontStyle: 'italic',
  },
  deviceInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(3),
  },
  error: {
    border: `1px solid ${Colors.ERROR}`,
  },
  exampleIcon: {
    marginRight: theme.spacing(1),
  },
  exampleIconAndTitle: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  imageWithText: {
    display: 'grid',
    gridTemplateColumns: '48px auto', // 48px is the width of our DeviceThumbnail
    alignItems: 'center',
  },
  item: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  text: {
    wordBreak: 'break-word',
  },
}));
