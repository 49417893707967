import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import SingleFileDisplay from 'client/app/components/Parameters/FileEditor/SingleFileDisplay';
import {
  convertFileObjectToV2,
  FileEditorValueOrFileSeries,
  FileObject,
  FileObjectLegacy,
  isFileObjectLegacy,
  isFileSeries,
} from 'common/types/fileParameter';
import { removeIcon } from 'common/ui/commonStyles';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/**
 * Allow a way for users to interact with the selected file (s):
 *  - View the name of the file (s)
 *  - Download the file
 *  - Clear the file selection
 */

type Props = {
  value: FileEditorValueOrFileSeries;
  onUnsetValue: () => void;
  isDisabled?: boolean;
};

export default React.memo(function FileDisplay(props: Props) {
  const classes = useStyles();
  const { value, isDisabled, onUnsetValue } = props;
  let valueDisplay;
  if (value) {
    if (isFileSeries(value) || Array.isArray(value)) {
      const files = isFileSeries(value) ? value.Files : value;
      const textDisplay = files.length + ' files selected';
      const fileNames = files.map(
        (file: Partial<FileObject> & Partial<FileObjectLegacy>) =>
          file?.name || file?.Name,
      );
      valueDisplay = (
        <Tooltip title={fileNames.join(', ')}>
          <div className={classes.valueDisplay}>{textDisplay}</div>
        </Tooltip>
      );
    } else if (isFileObjectLegacy(value)) {
      valueDisplay = (
        <SingleFileDisplay isDisabled={isDisabled} value={convertFileObjectToV2(value)} />
      );
    } else {
      valueDisplay = <SingleFileDisplay isDisabled={isDisabled} value={value} />;
    }
  }

  return (
    <div className={classes.contentWrapper}>
      {valueDisplay}
      {!isDisabled && (
        <IconButton className={classes.removeIcon} onClick={onUnsetValue} size="large">
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
});

const useStyles = makeStylesHook({
  contentWrapper: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  valueDisplay: {
    padding: '12px',
  },
  removeIcon: {
    ...removeIcon.style,
  },
});
