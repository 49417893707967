import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import _keys from 'lodash/keys';

import isWorkflowReadonly from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import { AdditionalPanelContent } from 'client/app/apps/workflow-builder/panels/Panel';
import {
  getDeviceConfigurationForUI,
  hasDispenserOrManualDevice,
  hasManualDevice,
  isDataOnly,
} from 'client/app/lib/workflow/deviceConfigUtils';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import {
  emptyWorkflowConfig,
  WorkflowConfig,
  WorkflowDeviceConfiguration,
} from 'common/types/bundle';

export default function WorkflowSettingsStateContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const workflowConfig = useWorkflowBuilderSelector(state => state.config);
  const editMode = useWorkflowBuilderSelector(state => state.editMode);
  const source = useWorkflowBuilderSelector(state => state.source);
  const additionalPanel = useWorkflowBuilderSelector(state => state.additionalPanel);

  const deviceConfiguration = useMemo(
    () => getDeviceConfigurationForUI(workflowConfig),
    [workflowConfig],
  );
  const hasManual = hasManualDevice(workflowConfig);
  const isDataOnlyConfig = isDataOnly(workflowConfig);
  const isReadonly = isWorkflowReadonly(editMode, source);

  // Only show deck options if a non-dispenser device is selected
  const isDispenserOrManual = useMemo(() => {
    return hasDispenserOrManualDevice(workflowConfig);
  }, [workflowConfig]);
  const hasDevicesSelected = _keys(deviceConfiguration).length > 0;
  const showDeckOptions = hasDevicesSelected && !isDispenserOrManual;

  const requiresDevice = workflowConfig.GlobalMixer.requiresDevice === true;

  return (
    <WorkflowSettingsStateContext.Provider
      value={{
        additionalPanel,
        deviceConfiguration,
        hasDevicesSelected,
        hasManual,
        isDataOnlyConfig,
        isReadonly,
        requiresDevice,
        showDeckOptions,
        workflowConfig,
      }}
    >
      {children}
    </WorkflowSettingsStateContext.Provider>
  );
}

type WorkflowSettingsState = {
  additionalPanel: AdditionalPanelContent | null;
  deviceConfiguration: WorkflowDeviceConfiguration;
  hasDevicesSelected: boolean;
  hasManual: boolean;
  isDataOnlyConfig: boolean;
  isReadonly: boolean;
  requiresDevice: boolean;
  showDeckOptions: boolean;
  workflowConfig: WorkflowConfig;
};

const WorkflowSettingsStateContext = createContext<WorkflowSettingsState>({
  additionalPanel: undefined,
  deviceConfiguration: {},
  hasDevicesSelected: false,
  hasManual: false,
  isDataOnlyConfig: false,
  isReadonly: false,
  requiresDevice: false,
  showDeckOptions: false,
  workflowConfig: emptyWorkflowConfig(),
});

export function useWorkflowSettingsState() {
  return useContext(WorkflowSettingsStateContext);
}
