import { useCallback } from 'react';

import { useFetchMixPreview } from 'client/app/api/MixPreviewApi';
import { SimulationWithExecution } from 'client/app/apps/simulation-details/overview/results/ResultsScreen';
import { TreeEntry } from 'client/app/apps/simulation-details/overview/TreeEntryList';
import {
  ArrayElement,
  SimulationOrExecutionStatusesEnum,
  SimulationQuery,
} from 'client/app/gql';
import { getDeviceConfigurationForUI } from 'client/app/lib/workflow/deviceConfigUtils';
import { WorkflowDeviceConfiguration } from 'common/types/bundle';

type SimulationTask = ArrayElement<NonNullable<SimulationQuery['simulation']['tasks']>>;

/**
 * Given a Simulation, finds the liquid handling tasks in the task list.
 */
export function getLiquidHandlingTasks(tasks: readonly SimulationTask[] | undefined) {
  if (!tasks) {
    return undefined;
  }
  return tasks.filter((current: SimulationTask | undefined) => {
    return current?.type === 'mix';
  });
}

export function getDevicesUsedInSimulation(
  simulation: SimulationQuery['simulation'],
): WorkflowDeviceConfiguration {
  // We could get the devices from the simulation.tasks. For example,
  // if there are 14 tasks, we could gather all their devices, and find
  // out there are 3 different devices.
  // But that wouldn't give us the runConfigId for each device.
  // The run config is e.g. the "(With washer and reader)" which is good
  // to show to scientists.
  // We can get the full info including runConfigId for each device from
  // the workflow. The workflow is an immutable snapshot and guaranteed
  // to stay frozen at the time of the simulation.
  return getDeviceConfigurationForUI(simulation.workflow.workflow.Config);
}

export function useFetchMixPreviewForSimulation() {
  const fetchMixPreview = useFetchMixPreview();
  return useCallback(
    async function fetchMixPreviewForSimulation(
      simulationId: SimulationId,
      simulationActionsLayoutFiletreeLink: FiletreeLink | null,
    ) {
      if (!simulationActionsLayoutFiletreeLink) {
        // If the workflow does no liquid handling, we have no data for the Preview.
        return null;
      }
      try {
        return await fetchMixPreview(simulationId, simulationActionsLayoutFiletreeLink);
      } catch (e) {
        console.error(e);
        throw e;
      }
    },
    [fetchMixPreview],
  );
}

const simulationStatusesWithExecution = [
  SimulationOrExecutionStatusesEnum.EXECUTION_SCHEDULED,
  SimulationOrExecutionStatusesEnum.EXECUTION_IN_PROGRESS,
  SimulationOrExecutionStatusesEnum.EXECUTION_SUCCESS,
  SimulationOrExecutionStatusesEnum.EXECUTION_FAILED,
  SimulationOrExecutionStatusesEnum.EXECUTION_COMPLETED,
];

/**
 * Returns true if the simulation has an associated execution
 */
export function hasExecution(
  simulation: SimulationQuery['simulation'],
): simulation is SimulationWithExecution {
  return (
    simulation.execution !== null &&
    simulationStatusesWithExecution.includes(simulation.transitiveStatus)
  );
}

function delay(time: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, time));
}

/**
 * Downloads the files for the given list of instruction
 */
export function downloadTaskInstructionFiles(instructions: readonly TreeEntry[]) {
  instructions.map(async (file, i) => {
    // without a delay some of the onDownload requests will fail
    file.type === 'file' && (await delay(500 * i).then(() => file.onDownload()));
  });
}
