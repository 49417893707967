import React, { useCallback, useMemo, useState } from 'react';

import DeleteIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KebabIcon from '@mui/icons-material/MoreVertOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import isEqual from 'lodash/isEqual';

import {
  WELL_ITERATION_ORDER_PARAMETER_NAME,
  WELL_ITERATION_PATTERN_PARAMETER_NAME,
} from 'client/app/components/Parameters/PlateContents/lib/generateWellIterationUtils';
import {
  ContentsByWell,
  getContentParameterDropdownLabelsFromConfig,
  OrderedParameter,
  WellParametersProps,
} from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import { WellGroup } from 'client/app/components/Parameters/PlateContents/WellGroupList';
import { formatWellRange } from 'common/lib/format';
import { ParameterValueDict } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import IconButton from 'common/ui/components/IconButton';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import { AddSelectedWellsIcon } from 'common/ui/icons/AddSelectedWellsIcon';
import { RemoveSelectedWellsIcon } from 'common/ui/icons/RemoveSelectedWellsIcon';

type WellGroupListItemProps = {
  wellGroup: WellGroup;
  isEditing?: boolean;
  isReadOnly?: boolean;
  isDeletable?: boolean;
  /**
   * Callback which generates the component containing fields for modifying currently selected wells.
   */
  wellParameters: (params: WellParametersProps<ParameterValueDict>) => JSX.Element;
  onEdit?: (groupId: string) => void;
  onDelete?: (deletedWells: Set<string>) => void;
  onAdd: () => void;
  onCancel: () => void;
  onChange: (contentsByWell: ContentsByWell) => void;
  onAddWells?: (groupId: string) => void;
  onRemoveWells?: (groupId: string) => void;
  /**
   * The Parameters related to the contents of the WellGroupListItem.
   */
  contentPropertyParams: OrderedParameter[];
};

/**
 * Show and edit properties about a group of wells.
 *
 * The component has three states:
 * 1. isEditing === false : A summary card showing information about the wells, with options to Edit and Delete.
 * 2. isEditing === true : A card with a list of editable parameters for modifying properties about the
 * currently selected wells.
 * 3. isReadOnly === true : A summary card showing information about the wells, with options to View.
 */
export default function WellGroupListItem({
  wellGroup,
  wellParameters,
  isEditing,
  isDeletable,
  isReadOnly,
  onEdit,
  onDelete,
  onAdd: handleAdd,
  onCancel: handleCancel,
  onChange,
  onAddWells,
  onRemoveWells,
  contentPropertyParams,
}: WellGroupListItemProps) {
  const classes = useStyles();

  const { id, contentsByWell, title, color } = wellGroup;

  // Prevent saving if user has entered invalid parameters
  const [isInvalid, setIsInvalid] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const selectionLabel = useMemo(
    () => formatWellRange([...contentsByWell.keys()]),
    [contentsByWell],
  );

  const iterationOrderAndPattern = useMemo(() => {
    const wellIterationOrderParameter = contentPropertyParams.find(
      param => param.name === WELL_ITERATION_ORDER_PARAMETER_NAME,
    );
    const wellIterationPatternParameter = contentPropertyParams.find(
      param => param.name === WELL_ITERATION_PATTERN_PARAMETER_NAME,
    );
    const wellIterationOrderValueLabels = getContentParameterDropdownLabelsFromConfig(
      wellIterationOrderParameter,
    );
    const wellIterationPatternValueLabels = getContentParameterDropdownLabelsFromConfig(
      wellIterationPatternParameter,
    );

    // Assume that the first well has the same iteration order and pattern as other wells.
    // Would be easier if we were to take it from the parameter itself, but seems simple enough
    // to retrieve from one item that is already being passed in.
    const [wellContent] = contentsByWell.values();
    const iterationOrder = wellContent?.[WELL_ITERATION_ORDER_PARAMETER_NAME];
    const iterationOrderLabelFromConfig =
      wellIterationOrderValueLabels[iterationOrder] ?? iterationOrder;
    const iterationPattern = wellContent?.[WELL_ITERATION_PATTERN_PARAMETER_NAME];
    const iterationPatternLabelFromConfig =
      wellIterationPatternValueLabels[iterationPattern] ?? iterationPattern;
    const allocation =
      iterationOrderLabelFromConfig === iterationPatternLabelFromConfig
        ? iterationOrderLabelFromConfig
        : `${iterationPatternLabelFromConfig}' - '${iterationOrderLabelFromConfig}`;
    return iterationOrderLabelFromConfig && iterationPatternLabelFromConfig
      ? `[Allocate wells by '${allocation}']`
      : 'No pattern or order selected yet.';
  }, [contentPropertyParams, contentsByWell]);

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const handleMenuItem = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleSelectGroup = useCallback(() => {
    onEdit?.(id);
    handleCloseMenu();
  }, [onEdit, id, handleCloseMenu]);

  const [confirmDeleteDialog, openConfirmDeleteDialog] = useDialog(ConfirmationDialog);

  const handleDelete = useCallback(async () => {
    handleCloseMenu();
    const isConfirmed = await openConfirmDeleteDialog({
      action: 'delete',
      isActionDestructive: true,
      object: 'well set',
    });
    if (!isConfirmed) {
      return;
    }
    onDelete?.(new Set(contentsByWell.keys()));
  }, [openConfirmDeleteDialog, onDelete, contentsByWell, handleCloseMenu]);

  // Fires whenever the user changes a field within the well parameters
  const handleParameterChange = useCallback(
    (newContentsByWell: ContentsByWell) => {
      // Do a deep comparison to check if the parameter values are different.
      // Without this, we can end up in an infinite loop - onParameterChange ->
      // handleParameterChange -> setStagedContentsByWell ->
      // onParameterChange...
      const changed = [...newContentsByWell.keys()].some(
        wellLocation =>
          !isEqual(newContentsByWell.get(wellLocation), contentsByWell.get(wellLocation)),
      );
      if (changed) {
        setHasChanges(true);
        onChange(newContentsByWell);
      }
    },
    [contentsByWell, onChange],
  );

  const handleCanSaveParameters = useCallback(
    (valid: boolean) => setIsInvalid(!valid),
    [],
  );

  const wellParametersMemo = useMemo(
    () =>
      wellParameters({
        contentsByWell: contentsByWell,
        isDisabled: isReadOnly,
        onChange: handleParameterChange,
        canSaveParameters: handleCanSaveParameters,
      }),
    [
      contentsByWell,
      handleCanSaveParameters,
      handleParameterChange,
      isReadOnly,
      wellParameters,
    ],
  );

  const hasActionButtons = !!onAddWells || !!onRemoveWells;

  return (
    <>
      <Paper className={classes.card} variant="outlined">
        <div className={classes.cardTitle}>
          <div className={classes.well} style={{ backgroundColor: color }} />
          <Typography color="textPrimary" variant="subtitle2">
            {title}
          </Typography>
          {!isEditing && (
            <>
              <IconButton
                className={classes.kebabIcon}
                size="small"
                icon={<KebabIcon />}
                onClick={handleMenuItem}
                title="More Actions"
              />
              <Menu
                anchorEl={menuAnchorEl}
                keepMounted
                open={!!menuAnchorEl}
                onClose={handleCloseMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItemWithIcon
                  onClick={handleSelectGroup}
                  icon={isReadOnly ? <VisibilityOutlinedIcon /> : <EditOutlinedIcon />}
                  text={isReadOnly ? 'View' : 'Edit'}
                />
                {!isReadOnly && (
                  <MenuItemWithIcon
                    onClick={handleDelete}
                    icon={<DeleteIcon />}
                    text="Delete"
                    disabled={!isDeletable}
                  />
                )}
              </Menu>
            </>
          )}
        </div>
        {!isEditing && (
          <>
            <Typography
              className={classes.liquidInfo}
              color="textPrimary"
              variant="body2"
            >
              {selectionLabel}
            </Typography>
            <Typography
              className={classes.liquidOrderPatternInfo}
              color="textSecondary"
              variant="body2"
            >
              {iterationOrderAndPattern}
            </Typography>
          </>
        )}
        {(hasActionButtons || isEditing) && <Divider className={classes.divider} />}
        {isEditing && (
          <>
            {wellParametersMemo}
            <div className={classes.cardContentActions}>
              <Button onClick={handleCancel} variant="secondary">
                Cancel
              </Button>
              <Button
                onClick={handleAdd}
                variant="secondary"
                color="primary"
                disabled={isReadOnly || isInvalid || !hasChanges}
              >
                Add
              </Button>
            </div>
          </>
        )}
        {hasActionButtons && (
          <div className={classes.cardActions}>
            {onAddWells && (
              <Button
                variant="tertiary"
                onClick={() => onAddWells(id)}
                startIcon={<AddSelectedWellsIcon />}
              >
                Add selected wells
              </Button>
            )}
            {onRemoveWells && (
              <Button
                variant="tertiary"
                onClick={() => onRemoveWells(id)}
                startIcon={<RemoveSelectedWellsIcon />}
              >
                Remove selected wells
              </Button>
            )}
          </div>
        )}
      </Paper>
      {confirmDeleteDialog}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  card: {
    border: `1px solid ${Colors.GREY_20}`,
    borderRadius: '4px',
    padding: theme.spacing(5, 3, 0, 3),
  },

  cardActions: {
    marginTop: theme.spacing(3),
  },
  cardContentActions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(6, 0, 5, 0),
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    marginBottom: theme.spacing(4),
  },
  divider: {
    margin: theme.spacing(0, 2),
  },
  kebabIcon: {
    color: Colors.TEXT_PRIMARY,
    marginLeft: 'auto',
  },
  liquidOrderPatternInfo: {
    margin: theme.spacing(0, 2, 4, 2),
  },
  liquidInfo: {
    margin: theme.spacing(2),
    marginTop: theme.spacing(0),
  },
  well: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    border: `1.6px solid ${Colors.GREY_30}`,
    marginRight: theme.spacing(3),
  },
}));
