import React, { useMemo } from 'react';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import VisualizationLinks, {
  simHasVisualizations,
} from 'client/app/apps/simulation-details/overview/VisualizationLinks';
import { SimulationQuery } from 'client/app/gql';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  simulation: SimulationQuery['simulation'];
};

export default React.memo(function ComputationResult({ simulation }: Props) {
  const classes = useStyles();
  const hasVisualizations = useMemo(() => simHasVisualizations(simulation), [simulation]);

  return (
    <Paper className={classes.box}>
      <Typography variant="h1" className={classes.boxHeader}>
        Results of the data analysis
      </Typography>
      <div className={classes.boxContent}>
        <Typography component="p">
          This screen shows the results of the data analysis workflow.
        </Typography>
        {hasVisualizations && (
          <div className={classes.visualizationLinks}>
            <VisualizationLinks simulation={simulation} />
          </div>
        )}
      </div>
    </Paper>
  );
});

const useStyles = makeStylesHook({
  box: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    margin: '32px',
    minWidth: '400px',
    maxWidth: '750px',
  },
  boxHeader: {
    color: '#fff',
    backgroundColor: Colors.SIMULATION_DETAILS_APP,
    padding: '8px 16px',
  },
  boxContent: {
    padding: '16px',
  },
  visualizationLinks: {
    marginTop: '16px',
  },
});
