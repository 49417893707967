import React, { useCallback } from 'react';

import Typography from '@mui/material/Typography';

import FilterChip, {
  FilterChipPopoverContentProps,
  FilterChipWithPopoverProps,
} from 'common/ui/components/FilterChip/FilterChip';
import Switch from 'common/ui/components/Switch';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = Omit<FilterChipWithPopoverProps<boolean>, 'defaultChipLabel'> & {
  activeChipLabel: string;
  inactiveChipLabel: string;
};

/**
 * A FilterChip that has a Switch (boolean) as its popover content.
 */
export default React.memo(function FilterChipWithSwitch(props: Props) {
  const classes = useStyles();
  const { filterValue, activeChipLabel, inactiveChipLabel, onFilter, size } = props;

  const valueLabel = filterValue ? activeChipLabel : inactiveChipLabel;

  // What happens when you clear the chip.
  const handleDelete = useCallback(() => {
    onFilter(false);
  }, [onFilter]);

  const popoverContent = useCallback(
    ({ onClose }: FilterChipPopoverContentProps) => {
      const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onFilter(e.target.checked);
        onClose();
      };
      return (
        <div className={classes.options}>
          <Switch onChange={onChange} color="primary" checked={filterValue} />
          <Typography variant="body1"> {activeChipLabel} </Typography>
        </div>
      );
    },
    [activeChipLabel, classes.options, filterValue, onFilter],
  );

  return (
    <FilterChip
      popoverContent={popoverContent}
      heading={props.heading}
      chipLabel={valueLabel}
      filterValue={filterValue}
      className={props.className}
      onDelete={handleDelete}
      isActive={filterValue}
      isDisabled={props.isDisabled}
      size={size}
    />
  );
});

const useStyles = makeStylesHook(theme => ({
  options: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '276px',
    paddingTop: theme.spacing(3),
  },
}));
