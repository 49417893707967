import React, { useCallback, useMemo, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { callUpdateDeviceRunConfig } from 'client/app/components/DeviceLibrary/mutations';
import { ArrayElement, DeviceCommonFragment as DeviceCommon } from 'client/app/gql';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type RunConfig = ArrayElement<DeviceCommon['runConfigSummaries']>;

export type Props = {
  onClose: (savedChanges: boolean) => void;
  device: DeviceCommon;
  existingConfig: RunConfig;
};

const NAME_NOT_UNIQUE_MESSAGE =
  'A config with the same name already exists for this device.';

function EditRunConfig(props: Props) {
  const classes = useStyles();

  // For simplicity initialise the editable name with the name from the existing config
  const [name, setName] = useState<string>(
    props.existingConfig ? props.existingConfig.name : '',
  );

  const { device, existingConfig, onClose } = props;

  const handleNameChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newName = e.currentTarget.value;
      setName(newName);
    },
    [setName],
  );

  const isNameUnique = useMemo(() => {
    return (
      device.runConfigSummaries.findIndex(
        c => c.name === name && c.id !== existingConfig.id,
      ) === -1
    );
  }, [device, existingConfig, name]);

  const apollo = useApolloClient();
  const handleSaveConfig = useCallback(async () => {
    if (name === '') {
      throw new Error("Run config name can't be empty");
    }

    // As we are updating a config, pass the name/rawConfig if it's actually been
    // changed by the user.
    await callUpdateDeviceRunConfig(apollo, {
      id: existingConfig.id,
      name,
    });

    onClose(true); // true means we have made some changes that should force a refresh
  }, [apollo, existingConfig.id, name, onClose]);

  const handleCancel = useCallback(() => onClose(false), [onClose]);

  const canSave = name !== '' && name !== existingConfig.name && isNameUnique;

  return (
    <Dialog open onClose={handleCancel} fullWidth maxWidth="xs">
      <DialogTitle>Edit configuration</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <TextField
          variant="standard"
          className={classes.nameInput}
          label="Name"
          value={name}
          onChange={handleNameChanged}
          error={!isNameUnique}
          helperText={isNameUnique ? undefined : NAME_NOT_UNIQUE_MESSAGE}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSaveConfig} color="primary" disabled={!canSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStylesHook({
  dialogContent: {
    color: Colors.BLACK,
    display: 'flex',
    flexDirection: 'column',
  },
  nameInput: {
    marginBottom: '1rem',
  },
});

export default EditRunConfig;
