import React, { Ref } from 'react';

import Badge, { BadgeProps } from '@mui/material/Badge';
import { animated, useSpring, useTrail } from 'react-spring';

import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const NUMBER_OF_DOTS = 3;

type Props = {
  children: JSX.Element;
  lastSimulationStatus: SimulationStatus | null;
};

/*
 * SimulationsBadge gives the user an indication of the simulation statuses that have occurred
 * since the Simulations Panel was last opened (also known as the unread simulations).
 *
 * This will show:
 * - Loading when the latest simulation is running
 * - Red if the latest simulation errored
 * - Completed if the latest simulation has completed.
 *
 */
export default React.memo(
  React.forwardRef(function SimulationsBadge(props: Props, ref: Ref<HTMLDivElement>) {
    const { children, lastSimulationStatus: lastStatus } = props;

    if (!lastStatus) {
      return <>{children}</>;
    }
    let badgeColor: BadgeProps['color'] = 'secondary';
    let content;
    switch (lastStatus) {
      case 'COMPLETED':
        content = '';
        break;
      case 'RUNNING':
      case 'QUEUED':
        content = <LoadingIndicator />;
        break;
      case 'FAILED':
        content = '';
        badgeColor = 'error';
        break;
    }

    return (
      <Badge color={badgeColor} badgeContent={content} ref={ref}>
        {children}
      </Badge>
    );
  }),
);

function LoadingIndicator() {
  const classes = useStyles();

  const styles = useSpring({
    loop: true,
    from: { opacity: 0, color: Colors.SECONDARY_CONTRAST },
    to: [{ opacity: 1, color: Colors.SECONDARY_LIGHT }],
    config: { duration: 1000 },
  });

  const trail = useTrail(NUMBER_OF_DOTS, styles);

  return (
    <div className={classes.badge}>
      {trail.map((styles, index) => {
        return (
          <animated.div style={styles} key={index}>
            <LoadingDot />
          </animated.div>
        );
      })}
    </div>
  );
}

function LoadingDot() {
  return (
    <svg height="6" width="6" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50%" cy="50%" r="1.75" fill="currentColor" />
    </svg>
  );
}

const useStyles = makeStylesHook({
  badge: {
    display: 'flex',
  },
});
