/** The weight of name vs description field when calculating search ranking */
export const NAME_SEARCH_WEIGHT = 0.8;
/** The weight of number of unique matches vs length of string when calculating score for name search */
const UNIQUE_MATCHES_IN_NAME_WEIGHT = 0.6;

export type SmartSearchData = {
  searchQueryRegex: RegExp | null;
  searchTermCount: number;
};
/**
 * Given a string, compute a RegExp (used to highlight the search results with `QueryHighlighter`)
 * and the number of terms in the query. This output is then used to filter and sort a list, using
 * helpers `filterAndRank<type of list, e.g. Jobs>`
 */
export function smartSearch(searchQuery: string): SmartSearchData {
  let searchQueryRegex = null;
  let searchTermCount = 0;
  if (searchQuery) {
    // We want to match word prefixes, so each term will be after \b (word boundary) or after a space.
    // (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#special-word-boundary)
    // The regex will look like (\bword_1|\bword_2|\bword_3).
    // Please note that this regex is used both for filtering/ranking as well as
    // highlighting the matches in the search results.
    // We need to trim and get rid of multiple spaces so we don't end up with (\bword||).
    // We also want to search elements by their names in code (e.g. Run_QPCR_Machine), so we first replace "_" with " "
    const replacedUnderscores = searchQuery.replace('_', ' ');
    const trimmed = replacedUnderscores.trim();
    if (trimmed) {
      const escaped = escapeRegex(trimmed);
      const searchTerms = escaped.split(/\s+/g);
      const regexStr = '(' + searchTerms.map(w => `\\b${w}`).join('|') + ')';
      searchQueryRegex = new RegExp(regexStr, 'gi');
      searchTermCount = searchTerms.length;
    }
  }

  return { searchQueryRegex, searchTermCount };
}

function computeSingleTermScore(
  term: string,
  query: RegExp | null,
  searchTermCount: number,
  ignoreLengthWhenComputingScore: boolean = false,
) {
  if (!query) {
    return 0;
  }
  // The score function looks at number of unique matches
  // and relative length of matches vs total length of string.
  // E.g. if you search "pcr", the "PCR" element should have higher score than "PCR Samples".
  // But if you search for "pcr mul", then "PCR multi" should have higher score.
  // We normalise all the scores are in 0-1 range to make it easier to reason about.
  // We also use same regex later for highlighting the search matches,
  // so make sure the high ranked results actually have some words highlighted.
  // For example watch out for searching in something that we never show to the user.
  const matches = term.match(query);
  if (matches) {
    const uniqueMatches = new Set<string>(matches);
    const totalMatchLength = matches.reduce((l, m) => l + m.length, 0);
    return ignoreLengthWhenComputingScore
      ? uniqueMatches.size / searchTermCount
      : (UNIQUE_MATCHES_IN_NAME_WEIGHT * uniqueMatches.size) / searchTermCount +
          ((1.0 - UNIQUE_MATCHES_IN_NAME_WEIGHT) * totalMatchLength) / term.length;
  }
  return 0;
}

function getTotalScore(
  importantTermsScores: number[],
  otherTermsScores: number[],
  weight: number,
) {
  const sumOfWeightedImportantTerms = importantTermsScores
    .map(termScore => termScore * weight)
    .reduce((a, b) => a + b, 0);
  const sumOfWeightedOtherTerms = otherTermsScores
    .map(termScore => termScore * (1 - weight))
    .reduce((a, b) => a + b, 0);
  return sumOfWeightedImportantTerms + sumOfWeightedOtherTerms;
}

export function computeTotalScore(
  importantTerms: string[],
  otherTerms: string[],
  weight: number,
  query: RegExp | null,
  searchTermCount: number,
) {
  const importantTermsScores = importantTerms.map(term =>
    computeSingleTermScore(term, query, searchTermCount),
  );
  const otherTermsScores = otherTerms.map(term =>
    computeSingleTermScore(term, query, searchTermCount, true),
  );

  return getTotalScore(importantTermsScores, otherTermsScores, weight);
}

/**
 * Escaping regex recommended by
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 * */

function escapeRegex(text: string) {
  // $& means the whole matched string
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
