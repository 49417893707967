import React from 'react';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import PlateLibrary from 'client/app/apps/plate-library/PlateLibrary';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export default function PlateLibraryApp() {
  const classes = useStyles();
  const [allPlatesByType, isInitialLoading] = usePlatesByType();
  return (
    <div className={classes.content}>
      <PlateLibrary plates={allPlatesByType} isInitialLoading={isInitialLoading} />
    </div>
  );
}

const useStyles = makeStylesHook({
  content: {
    backgroundColor: Colors.GREY_5,
    flex: 1,
    overflow: 'hidden',
  },
});
