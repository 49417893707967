import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AddInputDialogElementList from 'client/app/apps/template-workflows/editor/AddInputDialogElementList';
import AddInputDialogTable from 'client/app/apps/template-workflows/editor/AddInputDialogTable';
import {
  areInputsEqual,
  convertElementInstancesForLayout,
} from 'client/app/apps/template-workflows/editor/helpers';
import { Input } from 'client/app/apps/template-workflows/TemplateWorkflowEditor';
import { WorkflowLayoutWithMouseModeContext as WorkflowLayout } from 'client/app/components/ElementPlumber/WorkflowLayout';
import { TemplateWorkflowByIdQuery } from 'client/app/gql';
import { useElements } from 'client/app/hooks/useElements';
import cloneWithUUID from 'client/app/lib/workflow/cloneWithUUID';
import { indexBy } from 'common/lib/data';
import Button from 'common/ui/components/Button';
import LinearProgress from 'common/ui/components/LinearProgress';
import Workspace from 'common/ui/components/Workspace/Workspace';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

const NO_SELECTED_OBJECT_IDS: string[] = [];

type GraphQLTemplateWorkflow = TemplateWorkflowByIdQuery['templateWorkflow'];

type Props = DialogProps<Input[] | null> & {
  templateWorkflow: GraphQLTemplateWorkflow;
  selectedInputs: Input[];
  initialElementInstanceId?: string;
};

/**
 * Dialog for selecting inputs for the templates.
 */
function AddInputDialog({
  isOpen,
  onClose,
  templateWorkflow,
  selectedInputs: initialInputs,
  initialElementInstanceId,
}: Props) {
  const classes = useStyles();
  const bundle = templateWorkflow.workflow.workflow;
  const { elements } = useElements(templateWorkflow.workflow.workflow.elementSetId);

  const [selectedInputs, setSelectedInputs] = useState<Input[]>([]);

  const [selectedInstanceId, setSelectedInstanceId] = useState<string | null>(null);

  // initialize selectedInputs; we can't simply use initialInputs as default value for
  // useState on the line above, because we reuse the dialog multiple times and it keeps
  // the previous state.
  useEffect(() => {
    if (isOpen) {
      setSelectedInputs(initialInputs);
    }
  }, [initialInputs, isOpen]);

  const onSelectInput = useCallback((input: Input) => {
    setSelectedInputs(inputs => [...inputs, input]);
  }, []);
  const onUnselectInput = useCallback((input: Input) => {
    setSelectedInputs(inputs =>
      inputs.filter(oldInput => !areInputsEqual(oldInput, input)),
    );
  }, []);
  const onCancel = useCallback(() => onClose(null), [onClose]);
  const onClickConfirm = useCallback(() => {
    onClose(selectedInputs);
  }, [onClose, selectedInputs]);

  const onInputChange = useCallback((newInput: Input) => {
    setSelectedInputs(inputs =>
      inputs.map(oldInput => (areInputsEqual(oldInput, newInput) ? newInput : oldInput)),
    );
  }, []);

  const elementInstances = useMemo(() => {
    if (!elements) {
      return [];
    }
    const elementsByName = indexBy(elements, 'name');
    return convertElementInstancesForLayout(bundle.Elements.Instances, elementsByName);
  }, [elements, bundle.Elements.Instances]);

  const connections = useMemo(
    () => bundle.Elements.InstancesConnections.map(cloneWithUUID),
    [bundle.Elements.InstancesConnections],
  );

  const selectedElementInstance = useMemo(
    () => elementInstances.find(instance => instance.Id === selectedInstanceId),
    [elementInstances, selectedInstanceId],
  );

  // Triggered when prop initialElementInstanceId changes; that means user opened the
  // dialog with different initialElementInstanceId and they definitely want that focused
  useEffect(() => {
    if (!isOpen || !initialElementInstanceId) {
      return;
    }
    const elementInstance = elementInstances.find(
      instance => instance.Id === initialElementInstanceId,
    );
    if (elementInstance) {
      setSelectedInstanceId(elementInstance.Id);
    }
  }, [elementInstances, initialElementInstanceId, isOpen]);

  // For now, WorkflowLayout takes a prop that accepts multiple IDs. In this component,
  // there is no use case for selecting more than one element instance.
  const setSelectedObjects = (objectIds: string[]) => {
    const [objectId] = objectIds;
    setSelectedInstanceId(objectId ?? null);
  };

  // In this component, there is no use case for selecting more than one element instance.
  const toggleSelectedObjects = (objectIds: string[]) => {
    const id = objectIds[0] ?? null;
    if (selectedInstanceId === id) {
      setSelectedInstanceId(null);
    } else {
      setSelectedInstanceId(id);
    }
  };

  const deselectAll = () => {
    setSelectedInstanceId(null);
  };

  const content = elements ? (
    <>
      <div className={classes.elementSelector}>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          className={classes.elementSelectorGridRoot}
        >
          <Grid item className={classes.elementList}>
            <AddInputDialogElementList
              elementInstances={elementInstances}
              selectedInstance={selectedElementInstance}
              onSelectInstance={setSelectedInstanceId}
            />
          </Grid>
          <Grid item xs>
            <Workspace
              initialShowAll
              isShowHelpButtonVisible={false}
              isShowAllButtonVisible={false}
              logCategory="template-workflows"
              variant="dots"
              canvasControlVariant="light_float"
            >
              <WorkflowLayout
                editMode="readonly"
                elementInstances={elementInstances}
                connections={connections}
                setSelectedObjects={setSelectedObjects}
                selectedObjectIds={
                  selectedInstanceId ? [selectedInstanceId] : NO_SELECTED_OBJECT_IDS
                }
                deselectAll={deselectAll}
                toggleSelectedObjects={toggleSelectedObjects}
              />
            </Workspace>
          </Grid>
        </Grid>
      </div>
      <div className={classes.table}>
        {selectedElementInstance && (
          <AddInputDialogTable
            elementInstance={selectedElementInstance}
            connections={connections}
            selectedInputs={selectedInputs}
            onSelectInput={onSelectInput}
            onUnselectInput={onUnselectInput}
            onInputChange={onInputChange}
          />
        )}
      </div>
    </>
  ) : (
    <div>
      <Typography>Loading elements...</Typography>
      <LinearProgress />
    </div>
  );

  return (
    <Dialog open={isOpen} fullWidth maxWidth="md">
      <DialogTitle>Edit Template inputs</DialogTitle>
      <DialogContent className={classes.dialogContent}>{content}</DialogContent>
      <DialogActions>
        <Button variant="tertiary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="tertiary" color="primary" onClick={onClickConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStylesHook({
  dialogContent: {
    height: '80vh',
  },
  elementSelector: {
    height: '50%',
  },
  elementSelectorGridRoot: {
    height: '100%',
  },
  elementList: {
    overflow: 'auto',
    marginRight: '1rem',
  },
  table: {
    paddingTop: '1rem',
    height: '50%',
    overflow: 'auto',
  },
});

export default AddInputDialog;
