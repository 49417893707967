import React, { createContext, PropsWithChildren, useContext, useReducer } from 'react';

import {
  SimulationErrorState,
  SimulationProgress,
  SimulationResult,
} from 'client/app/lib/SimulationClient';
import {
  SimulationNotificationDetails,
  SimulationStatus,
} from 'client/app/lib/workflow/types';
export type SimulationNotificationAction =
  | {
      type: 'addSimulationNotification';
      payload: SimulationNotificationDetails;
    }
  | { type: 'removeSimulationNotification'; payload: string }
  | {
      type: 'updateSimulationNotification';
      payload: {
        info: SimulationResult | SimulationProgress | SimulationErrorState;
        status: SimulationStatus;
      };
    }
  | {
      type: 'toggleFavoriteSimulation';
      payload: {
        isFavoritedByCurrentUser: boolean;
        favoritedBy: string[] | undefined;
        simulationId: string;
      };
    };

function simulationNotificationsReducer(
  state: SimulationNotifications,
  action: SimulationNotificationAction,
) {
  switch (action.type) {
    case 'addSimulationNotification': {
      const simulationNotification = action.payload;
      return [...state, simulationNotification];
    }

    case 'removeSimulationNotification': {
      const requestID = action.payload;
      return state.filter(simulation => simulation.requestID !== requestID);
    }

    case 'updateSimulationNotification': {
      const { info, status } = action.payload;
      const updatedNotifications = state.map(notification =>
        notification.requestID === info.requestId.toString()
          ? {
              ...notification,
              ...info, // Add the new information from SimulationService callback
              status,
            }
          : notification,
      );
      return updatedNotifications;
    }

    case 'toggleFavoriteSimulation': {
      const { isFavoritedByCurrentUser, favoritedBy, simulationId } = action.payload;

      const updatedNotifications = state.map(notification =>
        notification.simulationId === simulationId
          ? {
              ...notification,
              isFavoritedByCurrentUser,
              favoritedBy,
            }
          : notification,
      );

      return updatedNotifications;
    }
  }
}

function useSimulationNotificationsReducer() {
  return useReducer(simulationNotificationsReducer, []);
}

export function useSimulationNotifications(workflowId: string) {
  const [simulationNotifcations, _] = useContext(SimulationNotificationsContext);
  return simulationNotifcations.filter(
    notification => notification.parentWorkflowId === workflowId,
  );
}

export function useSimulationNotificationsDispatch() {
  const [_, dispatch] = useContext(SimulationNotificationsContext);
  return dispatch;
}

export type SimulationNotifications = readonly SimulationNotificationDetails[];

export default function SimulationNotificationsContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const value = useReducer(simulationNotificationsReducer, []);
  return (
    <SimulationNotificationsContext.Provider value={value}>
      {children}
    </SimulationNotificationsContext.Provider>
  );
}

export const SimulationNotificationsContext = createContext<
  ReturnType<typeof useSimulationNotificationsReducer>
>([[], () => {}]);
