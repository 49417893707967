import { useMemo } from 'react';

const APPROVED_CONCENTRATION_UNITS = [
  '% v/v',
  '% w/v',
  'M',
  'mM',
  'uM',
  'nM',
  'pM',
  'fM',
  'g/l',
  'g/ml',
  'mg/l',
  'mg/ml',
  'ug/ml',
  'ug/ul',
  'ng/ml',
  'ng/ul',
  'X',
  'U/ml',
  'U/ul',
];

export default function useUnitOptions() {
  return useMemo(() => {
    return APPROVED_CONCENTRATION_UNITS.map(value => ({ label: value, value }));
  }, []);
}
