import React from 'react';

import cx from 'classnames';

import { IntersectionBar } from 'common/ui/components/IntersectionBar';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const HEADER_HEIGHT = 75;
const HEADER_HEIGHT_DENSE = 32;
const UPPER_CONTENT_HEIGHT_LARGE = 100;
const UPPER_CONTENT_HEIGHT_DENSE_LARGE = 57;

type ContainerProps = {
  content: React.ReactNode;
  /**
   * @deprecated Use headerLeftContent and headerRightContent instead
   */
  filters?: React.ReactElement;
  headerLeftContent?: React.ReactNode;
  headerRightContent?: React.ReactNode;
  /**
   * Specify a desired size for the header container
   */
  size?: 'small' | 'large';
  /**
   * No margin around content, header is smaller
   */
  dense?: boolean;
  scrollableRef?: React.RefObject<HTMLDivElement>;
};

/*
 * ContainerWithIntersectionBar is used to add an intersection bar that will appear upon scrolling.
 *
 * Sample usage:
 * <ContainerWithIntersectionBar
 *   headerRightContent={<SearchField searchQuery={searchQuery} />}
 *   content={<CardGrid> {renderCards} </CardGrid>} />
 *
 * This is designed to be used directly in places such as the various inventory pages, and it has
 * styling specific for use in those pages.
 *
 * Content in the header of the container (such as filters) can be specified by using
 * the headerLeftContent and headerRightContent. The header height is limited to HEADER_HEIGHT
 * or HEADER_HEIGHT_DENSE and may overflow if content is too large.
 *
 * One thing to watch out for is the scrolling and how overflow is handled. Currently, we have
 * different mechanisms for handling overflow so this is an attempt to consolidate.
 */

export default function ContainerWithIntersectionBar({
  content,
  filters,
  headerLeftContent,
  headerRightContent,
  size = 'small',
  scrollableRef,
  dense,
}: ContainerProps) {
  const classes = useStyles();
  const multipleFilters = React.Children.count(filters?.props.children) > 1;

  const largeHeader = size === 'large';

  const headerContentLeftAndRight = (
    <>
      {headerLeftContent}
      <div className={classes.headerSpacer} />
      {headerRightContent}
    </>
  );

  return (
    <div className={classes.container}>
      <div
        className={cx(classes.header, {
          [classes.multipleFilters]: multipleFilters,
          [classes.headerNotDense]: !dense,
          [classes.upperContentHeightLarge]: largeHeader,
          [classes.upperContentHeightDenseLarge]: dense && largeHeader,
        })}
      >
        {filters || headerContentLeftAndRight}
      </div>
      <div
        className={cx(classes.scrollable, { [classes.scrollableNotDense]: !dense })}
        ref={scrollableRef}
      >
        <IntersectionBar />
        <div className={cx(classes.content, { [classes.contentNotDense]: !dense })}>
          {content}
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    flex: 1,
    overflowY: 'hidden',
  },
  header: {
    flexShrink: 0,
    flexGrow: 0,
    display: 'flex',
    // Right justify the header content
    justifyContent: 'flex-end',
    // Vertically align header content to bottom of header div
    alignItems: 'flex-end',
    overflow: 'hidden',
    gap: theme.spacing(5),
    height: `${HEADER_HEIGHT_DENSE}px`,
    paddingRight: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  headerNotDense: {
    height: `${HEADER_HEIGHT}px`,
    padding: theme.spacing(0, 8, 0, 6),
    margin: 0,
  },
  upperContentHeightLarge: {
    height: `${UPPER_CONTENT_HEIGHT_LARGE}px`,
  },
  upperContentHeightDenseLarge: {
    height: `${UPPER_CONTENT_HEIGHT_DENSE_LARGE}px`,
  },
  multipleFilters: {
    justifyContent: 'space-between',
  },
  headerSpacer: {
    // Fill gap between left and right content
    flex: 1,
  },
  scrollable: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  scrollableNotDense: {
    padding: theme.spacing(0, 6),
  },
  contentNotDense: {
    paddingTop: theme.spacing(4),
  },
}));
