import React, { useCallback, useRef } from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_WORKFLOW_DETAILS_PANEL } from 'client/app/api/gql/queries';
import { useCopyWorkflowAndNavigate } from 'client/app/api/WorkflowsApi';
import {
  DEFAULT_TRANSFER,
  downloadCSVTemplate,
} from 'client/app/apps/cherry-picker/CherryPickApi';
import { useCherryPickContext } from 'client/app/apps/cherry-picker/CherryPickContext';
import { handleUploadCherryPickFile } from 'client/app/apps/cherry-picker/cp-file-upload/csvUploadHelper';
import DetailsCard, {
  DetailsCardToolipTitle,
} from 'client/app/components/nav/DetailsCard';
import HeaderEditableName from 'client/app/components/nav/HeaderEditableName';
import PlatformHeader from 'client/app/components/nav/PlatformHeader';
import { ScreenRegistry } from 'client/app/registry';
import { EditorType } from 'common/types/bundle';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import HeadersMappingDialog from 'common/ui/components/Dialog/HeadersMappingDialog';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import useDialog from 'common/ui/hooks/useDialog';

export default function CherryPickerHeader({ workflowId }: { workflowId: WorkflowId }) {
  const {
    workflowName,
    setWorkflowName,
    isReadonly,
    setCherryPick,
    setUploadedFileName,
    setPlatesByName,
    setActiveStep,
  } = useCherryPickContext();
  const { loading, data } = useQuery(QUERY_WORKFLOW_DETAILS_PANEL, {
    variables: { id: workflowId },
  });
  const snackbar = useSnackbarManager();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const [headersMappingDialog, openHeadersMappingDialog] =
    useDialog(HeadersMappingDialog);
  const onCopy = useCopyWorkflowAndNavigate(
    data?.workflow.id,
    data?.workflow.version,
    EditorType.CHERRY_PICKER,
  );
  ``;

  const onDownload = useCallback(() => {
    logEvent('download-template', ScreenRegistry.CHERRY_PICKER);
    downloadCSVTemplate();
  }, []);

  const onClear = useCallback(async () => {
    logEvent('clear-cherrypick', ScreenRegistry.CHERRY_PICKER);
    const confirmClear = await openConfirmationDialog({
      action: 'clear',
      object: 'content',
      additionalMessage: 'You will lose all your changes.',
    });
    if (confirmClear) {
      setCherryPick([DEFAULT_TRANSFER]);
      setUploadedFileName(null);
      setPlatesByName({});
    }
  }, [openConfirmationDialog, setCherryPick, setPlatesByName, setUploadedFileName]);

  const onUpload = useCallback(async () => {
    logEvent('upload-new-cherrypick', ScreenRegistry.CHERRY_PICKER);
    const file = fileInputRef.current?.files?.[0];
    if (!file) {
      return;
    }

    await handleUploadCherryPickFile(
      file,
      fileInputRef.current,
      snackbar,
      openHeadersMappingDialog,
      setCherryPick,
      setUploadedFileName,
      setPlatesByName,
      setActiveStep,
    );
  }, [
    openHeadersMappingDialog,
    setActiveStep,
    setCherryPick,
    setPlatesByName,
    setUploadedFileName,
    snackbar,
  ]);

  const inputId = 'cherry-pick-upload-topbar';
  const fileTypes = '.csv';

  const fileUploadProps = {
    onUpload,
    fileInputRef,
    fileTypes,
    inputId,
  };

  const tooltipTitleOverrides = {
    [DetailsCardToolipTitle.DOWNLOAD]: 'Download template',
    [DetailsCardToolipTitle.UPLOAD]: 'Upload picklist',
    [DetailsCardToolipTitle.CLEAR]: 'Clear picklist entries',
  };

  const disabledButtonOverrides = {
    [DetailsCardToolipTitle.UPLOAD]: isReadonly,
    [DetailsCardToolipTitle.CLEAR]: isReadonly,
  };

  const detailsProps = data
    ? {
        workflow: data.workflow,
        onCopy,
        onDownload,
        onClear,
        fileUploadProps,
        tooltipTitleOverrides,
        disabledButtonOverrides,
      }
    : null;

  return (
    <>
      <PlatformHeader experimentChipPickerProps={{ workflowId }}>
        <HeaderEditableName
          prefix="Cherry Picker"
          name={workflowName}
          onNameChange={name => setWorkflowName(name)}
          disabled={isReadonly}
          popoverPanel={!loading && detailsProps && <DetailsCard {...detailsProps} />}
        />
      </PlatformHeader>
      {confirmationDialog}
      {headersMappingDialog}
    </>
  );
}
