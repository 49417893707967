import React, { useCallback, useContext } from 'react';

import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';

import { QUERY_ELEMENT_BY_ID } from 'client/app/api/gql/queries';
import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import ElementDetails from 'client/app/components/ElementDetails/ElementDetails';
import sanitizeLink from 'client/app/lib/sanitizeLink';
import { getElementDisplayName } from 'client/app/lib/workflow/elementConfigUtils';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import Colors from 'common/ui/Colors';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import DialogActions from 'common/ui/components/Dialog/DialogActions';
import LinearProgress from 'common/ui/components/LinearProgress';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  elementId: string;
  selectedParameterName?: string;
  onClose: () => void;
} & DialogProps<void>;

export default function ElementDetailsDialog(props: Props) {
  const { elementId } = props;
  const classes = useStyles();

  const { screenId } = useContext(ScreenContext);
  const logOpenElementCode = useCallback(
    () => logEvent('open-element-code-in-repository', screenId as string),
    [screenId],
  );

  const isElementConfigDebugModeEnabled = useFeatureToggle(
    'ELEMENT_CONFIGURATION_DEBUG_MODE',
  );

  const { data } = useQuery(QUERY_ELEMENT_BY_ID, {
    variables: {
      id: elementId,
    },
  });

  const element = data?.element;

  return (
    <ComplexActionDialog
      title={
        element ? getElementDisplayName(element, isElementConfigDebugModeEnabled) : ''
      }
      onClose={props.onClose}
      isOpen={props.isOpen}
      showCloseButton
      paperClassName={classes.paper}
      maxWidth="sm"
      content={
        element ? (
          <ElementDetails
            element={element}
            selectedParameterName={props.selectedParameterName}
          />
        ) : (
          <LinearProgress />
        )
      }
      dialogActions={
        element?.sourceCodeURL && (
          <DialogActions>
            <Button
              href={sanitizeLink(element.sourceCodeURL)}
              target="_blank"
              onClick={logOpenElementCode}
            >
              Show code (Internal)
            </Button>
          </DialogActions>
        )
      }
    />
  );
}

const useStyles = makeStylesHook({
  paper: {
    height: '80vh',
    backgroundColor: Colors.GREY_5,
  },
});
