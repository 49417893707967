import {
  QUERY_ALL_DEVICES,
  QUERY_DEVICE_WITH_CONFIG_BY_ID,
  QUERY_GET_MISSING_DEVICE_LIQUID_CLASSES,
  QUERY_INSTANCE_CONFIG_BY_DEVICE_ID,
} from 'client/app/api/gql/queries';
import { createMutationNew } from 'client/app/api/gql/utils';
import {
  DeleteDeviceMutationVariables,
  DeleteDeviceRunConfigsMutation,
  DeleteDeviceRunConfigsMutationVariables,
  graphql,
  RegisterDeviceMutation,
  RegisterDeviceMutationVariables,
  UpdateDeviceConfigurationMutation,
  UpdateDeviceConfigurationMutationVariables,
  UpdateDeviceRunConfigMutation,
  UpdateDeviceRunConfigMutationVariables,
} from 'client/app/gql';

export const [useRegisterDeviceMutation, callRegisterDeviceMutation] = createMutationNew<
  RegisterDeviceMutationVariables,
  RegisterDeviceMutation
>(
  graphql(/* GraphQL */ `
    mutation RegisterDevice($token: String!) {
      registerDevice(input: { token: $token }) {
        id
      }
    }
  `),
  [QUERY_ALL_DEVICES],
);

export const [useDeleteDeviceMutation, callDeleteDeviceMutation] = createMutationNew<
  DeleteDeviceMutationVariables,
  UUID
>(
  graphql(/* GraphQL */ `
    mutation DeleteDevice($deviceID: UUID!) {
      deleteDevice(input: { id: $deviceID })
    }
  `),
  [QUERY_ALL_DEVICES],
);

export const [useUpdateDeviceConfiguration, callUpdateDeviceConfiguration] =
  createMutationNew<
    UpdateDeviceConfigurationMutationVariables,
    UpdateDeviceConfigurationMutation
  >(
    graphql(/* GraphQL */ `
      mutation UpdateDeviceConfiguration(
        $deviceId: DeviceId!
        $configuration: DeviceConfigData
        $runConfigurations: [DeviceRunConfigData!]!
      ) {
        updateDeviceConfiguration(
          input: {
            deviceId: $deviceId
            configuration: $configuration
            runConfigurations: $runConfigurations
          }
        ) {
          id
          instanceConfig {
            id
          }
          runConfigurations {
            id
          }
        }
      }
    `),
    [
      QUERY_ALL_DEVICES,
      QUERY_DEVICE_WITH_CONFIG_BY_ID,
      QUERY_INSTANCE_CONFIG_BY_DEVICE_ID,
      QUERY_GET_MISSING_DEVICE_LIQUID_CLASSES,
    ],
  );

export const [useUpdateDeviceRunConfig, callUpdateDeviceRunConfig] = createMutationNew<
  UpdateDeviceRunConfigMutationVariables,
  UpdateDeviceRunConfigMutation
>(
  graphql(/* GraphQL */ `
    mutation UpdateDeviceRunConfig($id: UUID!, $name: String!) {
      updateDeviceRunConfig(input: { id: $id, name: $name })
    }
  `),
);

export const [useDeleteDeviceRunConfigs, callDeleteDeviceRunConfigs] = createMutationNew<
  DeleteDeviceRunConfigsMutationVariables,
  DeleteDeviceRunConfigsMutation
>(
  graphql(/* GraphQL */ `
    mutation DeleteDeviceRunConfigs($ids: [UUID!]!) {
      deleteDeviceRunConfigs(input: { ids: $ids })
    }
  `),
);
