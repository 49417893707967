import React from 'react';

import IconGridOn from '@mui/icons-material/GridOn';
import Typography from '@mui/material/Typography';

import { SBS_STANDARD_LENGTH, SBS_STANDARD_WIDTH } from 'common/lib/defaultPlate';
import doNothing from 'common/lib/doNothing';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import PlateView from 'common/ui/components/simulation-details/plate-prep/PlateView';
import WellsList from 'common/ui/components/simulation-details/plate-prep/WellsList';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  deckLayout: DeckLayout;
  simulationName?: string;
  selectedPlate: PlateState;
  liquidColors: LiquidColors;
};

/** This component a "Printer friendly" version of the PlatePrepScreen.
 *  It has a simplified layout so that everything fits nicely in the page
 *  when users want to print their setup screen.
 */
function PlatePrepScreenPrintable({
  deckLayout,
  simulationName,
  selectedPlate,
  liquidColors,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.platePrepScreen}>
      <div className={classes.mainPanel}>
        {simulationName && (
          <div className={classes.header}>
            <Typography variant="h2" gutterBottom>
              {simulationName}
            </Typography>
            <div className={classes.setupIcon}>
              <IconGridOn />
              <Typography variant="h2"> Setup</Typography>
            </div>
          </div>
        )}

        <Typography variant="body1">{selectedPlate.name} </Typography>
        <div className={classes.plate}>
          {selectedPlate && (
            <PlateView
              key={selectedPlate.id}
              deckLayout={deckLayout}
              plate={selectedPlate}
              liquidColors={liquidColors}
              // Unused properties in the printable version
              highlightedWellLocation={null}
              onWellMouseEnter={doNothing}
              onWellMouseLeave={doNothing}
            />
          )}
        </div>
        <div className={classes.bottomBar}>
          <WellsList
            plate={selectedPlate}
            liquidColors={liquidColors}
            // Unused properties in the printable version
            highlightedWellLocation={null}
          />
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStylesHook({
  platePrepScreen: {
    display: 'flex',
    flex: 1,
    minHeight: 0,
  },
  bottomBar: {
    columnCount: 'auto',
    // Standard A4 format is roughly 8in. A bit less than
    // half to account for page margin.
    columnWidth: '3.5in',
  },
  header: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
  },
  mainPanel: {
    backgroundColor: 'white',
    height: '100%',
    width: '100%',
    padding: '16px',
  },
  plate: {
    // Most plates roughly have these dimensions (wells layout is what
    // varies), so we can print it to scale.
    width: `${SBS_STANDARD_WIDTH}mm`,
    height: `${SBS_STANDARD_LENGTH}mm`,
    marginBottom: '1rem',
  },
  setupIcon: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default PlatePrepScreenPrintable;
