import React, { useMemo } from 'react';

import { WellLocationOnDeckItem } from 'common/types/mix';
import PlateLayout, { Props as PlateLayoutProps } from 'common/ui/components/PlateLayout';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  deckLayout: DeckLayout;
  // Well location under cursor. Synchronised between the sidebar and the plate view.
  highlightedWellLocation: WellLocationOnDeckItem | null;
} & Omit<PlateLayoutProps, 'geometry'>;

// Shows a 2d grid of wells in the plate prep screen.
export default React.memo(function PlateView(props: Props) {
  const classes = useStyles();
  const { highlightedWellLocation, deckLayout, plate, ...plateProps } = props;
  const geometry = useMemo(
    () => deckLayout.getCurrentGeometry(plate),
    [deckLayout, plate],
  );
  const { width: plateWidth, height: plateHeight } = geometry.absolutePosInDeckPixels;
  const highlightedWells = useMemo(
    () => (highlightedWellLocation ? [highlightedWellLocation] : undefined),
    [highlightedWellLocation],
  );
  return (
    <svg viewBox={`0 0 ${plateWidth} ${plateHeight}`} className={classes.plateSvg}>
      <PlateLayout
        {...plateProps}
        plate={plate}
        geometry={geometry}
        highlightedWells={highlightedWells}
        showContentLabels
        showContentTooltip
      />
    </svg>
  );
});

const useStyles = makeStylesHook({
  plateSvg: {
    alignSelf: 'flex-start',
    // Make sure the whole plate is visible on screen
    height: '100%',
    width: '100%',
  },
});
