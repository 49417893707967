import React, { useMemo } from 'react';

import WellSelectorDialog from 'client/app/components/Parameters/WellSelector/WellSelectorDialog';
import { AppIcon } from 'client/app/icons';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import SelectFromDialogButton from 'common/ui/components/SelectFromDialogButton';

export type WellCoordsEditorProps = {
  onChange: (newWellsSelected: string[] | undefined) => void;
} & ParameterEditorBaseProps<string[]>;

/**
 * Parameter editor for well coordinates. Allow users to select wells
 * ["A1", "C3", ..] by clicking on an interactive plate visualization.
 */
export default function WellCoordsEditor({
  value,
  onChange,
  isDisabled,
}: WellCoordsEditorProps) {
  const dialogProps = useMemo(() => ({ value }), [value]);
  return (
    <SelectFromDialogButton
      value={value}
      dialog={WellSelectorDialog}
      selectedValueLabel={value ? value.join(', ') : ''}
      placeholder="Select some wells"
      dialogProps={dialogProps}
      onChange={onChange}
      icon={<AppIcon iconId="antha:plate" />}
      isDisabled={isDisabled}
    />
  );
}
