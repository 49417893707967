import React, { useCallback, useContext } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import TrashIcon from '@mui/icons-material/DeleteOutlined';
import CopyIcon from '@mui/icons-material/FileCopyOutlined';
import DownloadIcon from '@mui/icons-material/GetApp';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import { ExperimentQuery, WorkflowDetailsPanelQuery } from 'client/app/gql';
import { formatDateTime } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { WorkflowIcon } from 'common/ui/icons/Workflow';

type DetailsCardProps = (
  | {
      workflow: WorkflowDetailsPanelQuery['workflow'];
    }
  | { experiment: ExperimentQuery['experiment'] }
) & {
  onCopy?: () => void;
  onDownload?: () => void;
  onReturnToWorkflow?: () => void;
  onClear?: () => void;
  onDelete?: () => void;
  fileUploadProps?: fileUploadProps;
  tooltipTitleOverrides?: { [key in DetailsCardToolipTitle]?: string };
  disabledButtonOverrides?: { [key in DetailsCardToolipTitle]?: boolean };
};

type fileUploadProps = {
  onUpload: () => void;
  fileInputRef: React.MutableRefObject<HTMLInputElement | null>;
  fileTypes: string;
  inputId: string;
};

export enum DetailsCardToolipTitle {
  CLEAR = 'Clear',
  COPY = 'Copy',
  DOWNLOAD = 'Download',
  RETURN = 'Return to original workflow',
  UPLOAD = 'Upload',
}

/**
 * Card appearing in Popover when user click on a custom Header chevron.
 * Mostly use for different workflow editors.
 */
export default function DetailsCard(props: DetailsCardProps) {
  const classes = useStyles();
  const { screenId } = useContext(ScreenContext);
  const {
    onClear,
    fileUploadProps,
    tooltipTitleOverrides,
    disabledButtonOverrides,
    onCopy,
    onDownload,
    onReturnToWorkflow,
    onDelete,
  } = props;

  const createdBy =
    'workflow' in props ? props.workflow.createdBy : props.experiment.createdBy;
  const lastModifiedAt =
    'workflow' in props ? props.workflow.lastModifiedAt : props.experiment.lastModifiedAt;

  const handleCopy = useCallback(() => {
    if (onCopy) {
      logEvent('copy-workflow-from-details-card', screenId as string);
      onCopy();
    }
  }, [onCopy, screenId]);

  const isButtonDisabled = useCallback(
    (title: DetailsCardToolipTitle) => {
      return tooltipTitleOverrides?.[title] ?? title;
    },
    [tooltipTitleOverrides],
  );

  const getButtonStatus = useCallback(
    (title: DetailsCardToolipTitle) => {
      return !!disabledButtonOverrides?.[title];
    },
    [disabledButtonOverrides],
  );

  return (
    <div className={classes.detailsPanel}>
      <div className={classes.mainDetails}>
        <Typography variant="overline">Author</Typography>
        <Typography variant="overline">Last modified</Typography>
        <Typography>{createdBy.displayName}</Typography>
        <Typography>{formatDateTime(new Date(lastModifiedAt))}</Typography>
      </div>
      {(onCopy ||
        onDownload ||
        onReturnToWorkflow ||
        onClear ||
        fileUploadProps ||
        onDelete) && (
        <>
          <Divider className={classes.divider} />
          <div className={classes.actions}>
            {onCopy && (
              <Tooltip title={isButtonDisabled(DetailsCardToolipTitle.COPY)}>
                <span>
                  <IconButton
                    onClick={handleCopy}
                    disabled={getButtonStatus(DetailsCardToolipTitle.COPY)}
                    size="small"
                    icon={<CopyIcon />}
                  />
                </span>
              </Tooltip>
            )}
            {onDownload && (
              <Tooltip title={isButtonDisabled(DetailsCardToolipTitle.DOWNLOAD)}>
                <span>
                  <IconButton
                    onClick={onDownload}
                    disabled={getButtonStatus(DetailsCardToolipTitle.DOWNLOAD)}
                    size="small"
                    icon={<DownloadIcon />}
                  />
                </span>
              </Tooltip>
            )}
            {onDelete && (
              <Tooltip title="Delete">
                <IconButton onClick={onDelete} size="small" icon={<TrashIcon />} />
              </Tooltip>
            )}
            {'workflow' in props && onReturnToWorkflow && (
              <Tooltip title={isButtonDisabled(DetailsCardToolipTitle.RETURN)}>
                <span>
                  <IconButton
                    onClick={onReturnToWorkflow}
                    disabled={getButtonStatus(DetailsCardToolipTitle.RETURN)}
                    size="small"
                    icon={<WorkflowIcon />}
                  />
                </span>
              </Tooltip>
            )}
            {fileUploadProps && (
              <>
                <input
                  id={fileUploadProps.inputId}
                  type="file"
                  accept={fileUploadProps.fileTypes}
                  ref={fileUploadProps.fileInputRef}
                  style={{ display: 'none' }}
                  onChange={fileUploadProps.onUpload}
                  disabled={getButtonStatus(DetailsCardToolipTitle.UPLOAD)}
                />
                <Tooltip title={isButtonDisabled(DetailsCardToolipTitle.UPLOAD)}>
                  <label htmlFor={fileUploadProps.inputId}>
                    <IconButton
                      disabled={getButtonStatus(DetailsCardToolipTitle.UPLOAD)}
                      size="small"
                      icon={<UploadIcon />}
                      component="span"
                    />
                  </label>
                </Tooltip>
              </>
            )}
            {onClear && (
              <Tooltip title={isButtonDisabled(DetailsCardToolipTitle.CLEAR)}>
                <span>
                  <IconButton
                    onClick={onClear}
                    disabled={getButtonStatus(DetailsCardToolipTitle.CLEAR)}
                    size="small"
                    icon={<ClearIcon />}
                  />
                </span>
              </Tooltip>
            )}
          </div>
        </>
      )}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  detailsPanel: {
    width: '412px',
    padding: theme.spacing(5, 5, 3, 5),
    backgroundColor: Colors.GREY_70,
  },
  mainDetails: {
    margin: theme.spacing(3, 0, 6, 0),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  divider: {
    margin: theme.spacing(5, 0, 3, 0),
    color: Colors.GREY_30,
  },

  actions: {
    display: 'flex',
    justifyContent: 'start',
    gap: theme.spacing(0, 4),
    marginTop: theme.spacing(3),
  },
}));
