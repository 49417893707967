import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import Typography from '@mui/material/Typography';
import { mergeRefs } from 'react-merge-refs';
import useResizeObserver from 'use-resize-observer';

import {
  isNumericFactor,
  LEVEL_MARGIN,
  SHOW_MORE_LABEL_WIDTH,
} from 'client/app/components/DOEBuilder/factorUtils';
import { FactorItem } from 'common/types/bundle';
import getMeasurementFromString from 'common/ui/components/ParameterEditors/unitRegistry';
import useThrottle from 'common/ui/hooks/useThrottle';

export type FactorLevelsProps = {
  factor: FactorItem;
};

export default function FactorLevels({ factor }: FactorLevelsProps) {
  const [levelsToShow, setLevelsToShow] = useState(factor.values.length);
  const containerRef = useRef<HTMLElement | null>(null);
  const moreToShow = factor.values.length - levelsToShow;

  const rerenderAllChips = useThrottle(
    /**
     * The +1 is here just to make the setState value different from the initial.
     * Because setting state to the same value would not trigger a re-render
     * and consequently the layout effect below.
     */
    () => setLevelsToShow(factor.values.length + 1),
    100,
  );
  const observer = useResizeObserver({
    onResize: rerenderAllChips,
  });
  const mergedRef = mergeRefs([observer.ref, containerRef]);

  useEffect(() => {
    rerenderAllChips();
  }, [factor, rerenderAllChips]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    /**
     * This effect limits the amount of levels shown to avoid exceeding the factor row boundary.
     */
    const container = containerRef.current;
    if (container) {
      const box = container.getBoundingClientRect();
      const chips = container.getElementsByClassName('level');

      let chipTotalWidth = 0;
      let count = 0;
      let i = 1;

      for (const chip of chips) {
        // If this is the last chip, and it fits, we don't need to worry about saving space for the more label.
        const isLast = i === factor.values.length;

        const chipRect = chip.getBoundingClientRect();
        const chipWidth = chipRect.width + LEVEL_MARGIN;

        if (chipTotalWidth + chipWidth > box.width - (isLast ? 0 : SHOW_MORE_LABEL_WIDTH))
          break;

        chipTotalWidth += chipWidth;
        count += 1;
        i++;
      }

      setLevelsToShow(count);
    }
  });

  return (
    <div className="levels" ref={mergedRef}>
      {factor.values.slice(0, levelsToShow).map((value, index) => {
        if (!value) return null;

        if (!isNumericFactor(factor)) {
          return (
            <div key={index} className="level">
              <Typography variant="subtitle2" color="textPrimary" className="stringValue">
                {value}
              </Typography>
            </div>
          );
        }

        const level = getMeasurementFromString(value);
        return (
          <div key={index} className="level">
            <Typography variant="subtitle2" color="textPrimary">
              {level.value}
            </Typography>
            {factor.unit && (
              <Typography variant="caption" color="textSecondary">
                {factor.unit}
              </Typography>
            )}
          </div>
        );
      })}
      {moreToShow > 0 && (
        <Typography variant="caption" color="textSecondary" className="more">
          +{moreToShow} more
        </Typography>
      )}
    </div>
  );
}
