/// <reference path="../types/markdown-it-collapsible.d.ts" />

import MarkdownIt from 'markdown-it';
import MarkdownItCollapsible from 'markdown-it-collapsible';
import MarkdownItMultiMdTable from 'markdown-it-multimd-table';

import { OpaqueAlias } from 'common/types/OpaqueAlias';

export type Markdown = OpaqueAlias<string, 'Markdown'>;

// memoize parser so it can be easily reused
let parser: MarkdownIt | null = null;
function getParser() {
  return parser ?? (parser = newParser());
}

function newParser(): MarkdownIt {
  const p = new MarkdownIt();
  p.set({ html: true });
  const tableOpts = {
    multiline: true,
    rowspan: true,
    headerless: true,
    multibody: true,
    aotolabel: true,
  };
  return p.use(MarkdownItCollapsible).use(MarkdownItMultiMdTable, tableOpts);
}

/**
 * Converts Markdown into HTML, returns string containing the HTML.
 */
export function renderMarkdown(markdown: Markdown): string {
  return getParser().render(markdown);
}

/**
 * Converts plain text into markdown. This is not a perfect conversion to markdown,
 * e.g. we do not escape characters for bold/italic/strikethrough.
 * @param plainText
 */
export function stringToMarkdown(plainText: string): Markdown {
  return plainText as Markdown;
}
