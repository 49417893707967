import React, { useMemo } from 'react';

import { ApolloClient, ApolloLink, ApolloProvider, ServerError } from '@apollo/client';
import { InMemoryCache, InMemoryCacheConfig } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { FetchResult } from '@apollo/client/link/core';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { Observable } from '@apollo/client/utilities';

function getApolloClientWithBearerToken(
  rootPath: string,
  getAccessTokenSilently: () => Promise<string>,
  inMemoryCacheConfig?: InMemoryCacheConfig,
): ApolloClient<any> {
  return new ApolloClient({
    cache: new InMemoryCache(inMemoryCacheConfig),
    link: ApolloLink.from([
      // Aynchronously add authentication header to outgoing requests
      setContext(async () => {
        // This is fine.
        // If the stored token is valid, there is not outgoint request.
        const token = await getAccessTokenSilently();
        return { token };
      }),
      new ApolloLink((operation, forward) => {
        const context = operation.getContext();
        const token = context.token;
        if (token) {
          operation.setContext({
            headers: {
              ...context.headers,
              authorization: `bearer ${token}`,
            },
          });
        }
        return forward(operation);
      }),
      onError((response: ErrorResponse): Observable<FetchResult> | void => {
        // Handle http response statuses >= 400 - we want the server to respond with
        // 4xx or 5xx statuses because it helps with monitoring in various tools.
        // Unfortunately apollo treats non-200 responses as "network errors" (why?!
        // the response was received so the network clearly works!).
        // To avoid having to handle both cases everywhere we make 4xx/500 errors looks like
        // regular response with errors. We do this only for 4xx and 500 because these
        // status codes might have error messages intended for the users which 501+
        // will not. 500 technically also is an internal server error and shouldn't
        // carry any detailed messages but situation on the appserver side is a bit
        // messy so for now we also include 500 status here.

        // if this is a server error with status code 4xx/500 with "errors" in the body
        if ((response.networkError as ServerError)?.result?.errors?.[0]?.message) {
          const serverErr = response.networkError as ServerError;
          if (serverErr.response.status >= 400 && serverErr.response.status <= 500) {
            return Observable.of({
              errors: serverErr.result.errors,
            });
          }
        }
      }),
      new HttpLink({
        // the operation name in URL is not for the server, it's for our debugging purposes so that
        // browser network tab displays more meaningful information in the "file" column. Without
        // this all queries show just "graphql" (simply the part of the path after last "/").
        uri: operation => `${rootPath}${operation.operationName}`,
        credentials: 'same-origin',
        useGETForQueries: false,
      }),
    ]),
  });
}

type ApolloProviderWithAuthProps = {
  children: React.ReactNode;

  /**
   * The root path for all GraphQL HTTP calls. e.g. `/graphql/`.
   */
  rootPath: string;

  /**
   * An async function to get the auth token that will be used in the HTTP authorization header.
   */
  getAccessTokenSilently: () => Promise<string>;

  /* Pass along the config to allow for custom cache behavior such as making sure that
   * Simulations across different queries can be used if the same id is used.
   * https://www.apollographql.com/docs/react/caching/cache-configuration/
   */
  inMemoryCacheConfig?: InMemoryCacheConfig;
};

/**
 * Creates an Apollo Provider with a configured client, complete with access
 * token. When the user logs in or out, the change to the access token in the
 * AdminUIContext will be propagated here and this component will update
 * accordingly.
 *
 * Any component contained by this component (directly or indirectly) can make
 * GraphQL calls using Apollo's `useQuery` call, and those calls will be made
 * using this component's Apollo client. (See
 * https://www.apollographql.com/docs/react/get-started/)
 * @param props
 */
export default function ApolloProviderWithAuth(props: ApolloProviderWithAuthProps) {
  const { children, rootPath, getAccessTokenSilently, inMemoryCacheConfig } = props;
  const apolloClient = useMemo(
    () =>
      getApolloClientWithBearerToken(
        rootPath,
        getAccessTokenSilently,
        inMemoryCacheConfig,
      ),
    [getAccessTokenSilently, inMemoryCacheConfig, rootPath],
  );
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
