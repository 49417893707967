import React from 'react';

import BreadcrumbsHeader from 'client/app/components/nav/breadcrumbs/BreadcrumbsHeader';
import DefaultHeader, {
  type Props,
  HeaderWithContent,
} from 'client/app/components/nav/TopMainNav';
import { useFeatureToggle } from 'common/features/useFeatureToggle';

export default function PlatformHeader({
  children,
  ...props
}: React.PropsWithChildren<Props>) {
  const isEnabledBreadcrumbs = useFeatureToggle('BREADCRUMBS');

  if (isEnabledBreadcrumbs) {
    return <BreadcrumbsHeader />;
  }

  if (children) {
    return <HeaderWithContent {...props}>{children}</HeaderWithContent>;
  }

  return <DefaultHeader {...props} />;
}
