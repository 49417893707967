import React, { useRef } from 'react';

import { WellsContentByPosition } from 'client/app/apps/cherry-picker/CherryPickApi';
import {
  PLATE_FILL,
  PLATE_STROKE,
  WELL_FILL,
  WELL_STROKE,
} from 'client/app/apps/plate-constructor/Colors';
import { PlateFormFields } from 'client/app/apps/plate-constructor/PlateFormFields';
import SVGAnnotation from 'client/app/apps/plate-constructor/SVGAnnotation';
import { formatWellPosition } from 'common/lib/format';
import { FormPlateType } from 'common/types/plateType';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { Well } from 'common/ui/components/simulation-details/mix/Well';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useSvgScale from 'common/ui/hooks/useSvgScale';
import useUUID from 'common/ui/hooks/useUUID';

/**
 * Annotations start from the middle of the well, we need to offset it out of the well:
 * 1/2 of the dimension to reach the edge, and 1/10 to give it some space (but not too
 * much); hence 0.6. Use by multiplying the well dimension.
 * */
const OFFSET_OUT_OF_WELL = 0.6;

function generateWellsInfo(plate: FormPlateType) {
  const rows = Math.max(1, Number(plate.rows));
  const columns = Math.max(1, Number(plate.columns));
  const wellDimensions = {
    x: Number(plate.wellShape.dimensionMm.x),
    y: Number(plate.wellShape.dimensionMm.y),
  };
  const separationX = Math.max(0, Number(plate.wellOffset?.x));
  const separationY = Math.max(0, Number(plate.wellOffset?.y));
  return Array.from({ length: rows * columns }, (_, i) => {
    const column = i % columns;
    const row = Math.floor(i / columns);
    return {
      row,
      column,
      width: wellDimensions.x,
      height: wellDimensions.y,
      left: column * separationX - wellDimensions.x / 2,
      top: row * separationY - wellDimensions.y / 2,
      x: column * separationX - wellDimensions.x / 2,
      y: row * separationY - wellDimensions.y / 2,
    };
  });
}

type Props = {
  plate: FormPlateType;
  selectedField?: PlateFormFields;
  // A map used to colour the wells based on their content
  wellsContentsByPosition?: WellsContentByPosition;
};
export default React.memo(function PlateVisualization(props: Props) {
  const { plate, selectedField, wellsContentsByPosition } = props;
  const columns = Number(plate.columns);
  const rows = Number(plate.rows);

  // While the TS type specfies dimension_mm and well_start_mm should be defined,
  // there are a few plates on ninja where they aren't, so this needs to be handled
  // to avoid a crash.
  const plateX = Math.max(0, Number(plate.dimension.x)) || 0;
  const plateY = Math.max(0, Number(plate.dimension.y)) || 0;
  const gridOffsetX = plate.wellStart.x ?? 0;
  const gridOffsetY = plate.wellStart.y ?? 0;

  const classes = useStyles();
  const svgRef = useRef<SVGSVGElement>(null);
  const scale = useSvgScale(svgRef, plateX, plateY);

  const baseAnnotationProps = {
    scale,
    tailLength: 5,
    unit: 'mm',
  };

  const gridOffset = `translate(${gridOffsetX} ${gridOffsetY})`;

  // This dialog is displayed on the Plate Library page where the plate is already likely to be
  // displayed in the grid. Therefore, we have to make sure we use a different ID in the dialog
  // or we'll be editing the former's definition and will see no change in the plate preview in
  // the dialog!

  // We're using lazy initial state here to guarantee the UUID is calculated only once.
  const id = useUUID();

  const wells = generateWellsInfo(plate);

  // Helper variables to make the SVG annotation calculations easier to read
  const { x: wellOffsetX, y: wellOffsetY } = plate.wellOffset;
  const { width: wellWidth, height: wellHeight } = wells[0];
  const wellX = wells[0].x;
  const wellY = wells[0].y;
  const wellXY = { x: wellX, y: wellY };

  const liquidColors = LiquidColors.createDefault();
  return (
    <div className={classes.container}>
      <svg
        ref={svgRef}
        className={classes.svgContainer}
        viewBox={`0 0 ${plateX} ${plateY}`}
        width="100%"
        height="100%"
        preserveAspectRatio="xMidYMid meet"
        vectorEffect="non-scaling-stroke"
      >
        <defs>
          <rect
            id={`plate_${id}`}
            x="0"
            y="0"
            width={`${plateX}`}
            height={`${plateY}`}
            stroke={PLATE_STROKE}
            fill={PLATE_FILL}
            vectorEffect="non-scaling-stroke"
          />
          <clipPath id={`clip_plate_${id}`}>
            <use xlinkHref={`#plate_${id}`} />
          </clipPath>
        </defs>
        <use xlinkHref={`#plate_${id}`} />
        <g clipPath={`url(#clip_plate_${id})`}>
          <g transform={gridOffset} overflow="hidden">
            {wells.map(well => {
              // Default color for empty well
              let color = WELL_FILL;

              // e.g. "A2"
              const wellPositionName = formatWellPosition(well.row, well.column);
              // e.g. "Liquid A"
              const wellContent = wellsContentsByPosition?.[wellPositionName];

              if (wellContent) {
                color = liquidColors.getColorFromLiquidString(wellContent);
              }

              return (
                <Well
                  key={`well_${well.row}X${well.column}`}
                  wellPos={well}
                  wellType={plate.wellShape.type}
                  color={color}
                  strokeColor={WELL_STROKE}
                  disableHoverState
                />
              );
            })}
          </g>
        </g>
        <g transform={gridOffset}>
          {columns > 1 && selectedField === 'sepX' && (
            <SVGAnnotation
              from={wellXY}
              to={wells[1]}
              direction="HORIZONTAL"
              offset={-wellHeight * OFFSET_OUT_OF_WELL}
              value={wellOffsetX}
              {...baseAnnotationProps}
            />
          )}
          {rows > 1 && selectedField === 'sepY' && (
            <SVGAnnotation
              from={wellXY}
              to={wells[columns]}
              direction="VERTICAL"
              offset={-wellWidth * OFFSET_OUT_OF_WELL}
              value={wellOffsetY}
              {...baseAnnotationProps}
            />
          )}
          {columns > 1 && selectedField === 'startX' && (
            <SVGAnnotation
              from={{ x: -gridOffsetX, y: wellY }}
              to={wellXY}
              direction="HORIZONTAL"
              offset={0}
              value={plate.wellStart.x}
              {...baseAnnotationProps}
            />
          )}
          {rows > 1 && selectedField === 'startY' && (
            <SVGAnnotation
              from={{ x: wellX, y: -gridOffsetY }}
              to={wellXY}
              direction="VERTICAL"
              offset={0}
              value={plate.wellStart.y}
              {...baseAnnotationProps}
            />
          )}
          {columns > 1 && selectedField === 'wellX' && (
            <SVGAnnotation
              from={wellXY}
              to={{ x: wellX + wellWidth, y: wellY }}
              direction="HORIZONTAL"
              offset={-wellHeight / 2 - 1}
              value={wellWidth}
              {...baseAnnotationProps}
            />
          )}
          {rows > 1 && selectedField === 'wellY' && (
            <SVGAnnotation
              from={wellXY}
              to={{ x: wellX, y: wellY + wellHeight }}
              direction="VERTICAL"
              offset={-wellWidth / 2 - 1}
              value={wellHeight}
              {...baseAnnotationProps}
            />
          )}
        </g>
      </svg>
    </div>
  );
});

const useStyles = makeStylesHook({
  svgContainer: {
    // annotations may overflow
    overflow: 'visible',
    vectorEffect: 'non-scaling-stroke',
  },
  container: {
    height: '100%',
    boxSizing: 'border-box',
  },
});
