import { castDraft, produce } from 'immer';
import { WritableDraft } from 'immer/dist/types/types-external';

import { formatDefaultLayoutOptions } from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import { isDeckPositionsParameter } from 'client/app/components/Parameters/DeckPositions/lib/deckPositionsParameterUtils';
import { DeviceParsedRunConfigQuery } from 'client/app/gql';
import { State } from 'client/app/state/WorkflowBuilderStateContext';
import { WorkflowConfig } from 'common/types/bundle';
import { OpaqueAlias } from 'common/types/OpaqueAlias';

export type NamedPlate = OpaqueAlias<string, 'labware:NamedPlate'>;

export const SIMPLE_LABWARE_TYPE = [
  'outputPlates',
  'inputPlates',
  'tipBoxes',
  'tipWastes',
  'temporaryLocations',
] as const;
export type SimpleLabwareType = typeof SIMPLE_LABWARE_TYPE[number];
SIMPLE_LABWARE_TYPE as readonly SimpleLabwareType[];

export type LabwareType = SimpleLabwareType | NamedPlate;
export type LabwarePreference = { labwareType: LabwareType; position: string };

const NAMED_PLATE_PREFIX = 'named_plate:';

export function toNamedPlate(name: string) {
  return (NAMED_PLATE_PREFIX + name) as NamedPlate;
}

export function fromNamedPlate(namedPlate: NamedPlate) {
  return namedPlate.slice(NAMED_PLATE_PREFIX.length);
}

export function formatLabwareTypeName(labware: LabwareType | undefined) {
  if (!labware) {
    return '';
  }
  if (isNamedPlate(labware)) {
    return fromNamedPlate(labware);
  }
  switch (labware) {
    case 'inputPlates':
      return 'Input Plates';
    case 'outputPlates':
      return 'Output Plates';
    case 'temporaryLocations':
      return 'Temporary Locations';
    case 'tipBoxes':
      return 'Tip Boxes';
    case 'tipWastes':
      return 'Tip Wastes';
  }
}

export function isNamedPlate(
  labwareType: LabwareType | undefined,
): labwareType is NamedPlate {
  return labwareType?.indexOf(NAMED_PLATE_PREFIX) === 0;
}

export function getNamedPlates(state: State) {
  const namedPlatesFromConfig =
    state.config.GlobalMixer.driverSpecificPlatePreferences ?? {};
  return Object.keys(namedPlatesFromConfig).map(plate => toNamedPlate(plate));
}

const DRIVER_SPECIFIC_MAP: {
  [key in SimpleLabwareType]:
    | 'driverSpecificOutputPreferences'
    | 'driverSpecificInputPreferences'
    | 'driverSpecificTipPreferences'
    | 'driverSpecificTipWastePreferences'
    | 'driverSpecificTemporaryLocations';
} = {
  outputPlates: 'driverSpecificOutputPreferences',
  inputPlates: 'driverSpecificInputPreferences',
  tipBoxes: 'driverSpecificTipPreferences',
  tipWastes: 'driverSpecificTipWastePreferences',
  temporaryLocations: 'driverSpecificTemporaryLocations',
};

export function areLabwareTypesEqual(
  labwareType1: LabwareType | undefined,
  labwareType2: LabwareType | undefined,
) {
  return labwareType1 === labwareType2;
}

/**
 * Returns the list of labware that have the position assigned as a preference.
 * Useful for UI rendering in the deck layout.
 */
export function getLabwareForPosition(state: State, position: string): LabwareType[] {
  const labware: LabwareType[] = [];
  for (const key in DRIVER_SPECIFIC_MAP) {
    const driverSpecific = DRIVER_SPECIFIC_MAP[key as SimpleLabwareType];
    const currentPositions = state.config.GlobalMixer?.[driverSpecific] || [];
    if (currentPositions.includes(position)) {
      labware.push(key as SimpleLabwareType);
    }
  }
  for (const [plateName, preferences] of Object.entries(
    state.config.GlobalMixer?.driverSpecificPlatePreferences ?? {},
  )) {
    if (preferences?.includes(position)) {
      labware.push(toNamedPlate(plateName));
    }
  }
  return labware;
}

export function getLabwareIndexForPosition(
  state: State,
  labware: LabwareType,
  position: string,
): number {
  const prefs = getLabwarePreferences(state, labware);
  return prefs.findIndex(p => p === position);
}

function getLabwarePreferencesDraft(
  draft: WritableDraft<State>,
  labwareType: LabwareType,
) {
  if (isNamedPlate(labwareType)) {
    const plateName = fromNamedPlate(labwareType);
    if (!draft.config.GlobalMixer.driverSpecificPlatePreferences) {
      draft.config.GlobalMixer.driverSpecificPlatePreferences = {};
    }
    if (!draft.config.GlobalMixer.driverSpecificPlatePreferences[plateName]) {
      draft.config.GlobalMixer.driverSpecificPlatePreferences[plateName] = [];
    }
    return draft.config.GlobalMixer.driverSpecificPlatePreferences[plateName];
  }
  const driverSpecific = DRIVER_SPECIFIC_MAP[labwareType];
  if (!draft.config.GlobalMixer[driverSpecific]) {
    draft.config.GlobalMixer[driverSpecific] = [];
  }
  return draft.config.GlobalMixer[driverSpecific];
}

function getLabwarePreferences(state: State, labwareType: LabwareType) {
  if (isNamedPlate(labwareType)) {
    const plateName = fromNamedPlate(labwareType);
    return state.config.GlobalMixer.driverSpecificPlatePreferences?.[plateName] ?? [];
  }
  const driverSpecific = DRIVER_SPECIFIC_MAP[labwareType];
  return state.config.GlobalMixer[driverSpecific] ?? [];
}

export function getNumberOfPositions(state: State, labwareType: LabwareType) {
  const labwarePreferences = getLabwarePreferences(state, labwareType);
  return labwarePreferences.length;
}
export function addLabwarePreference(state: State, pref: LabwarePreference) {
  return produce(state, draft => {
    const { position, labwareType } = pref;
    const labwarePreferences = getLabwarePreferencesDraft(draft, labwareType);
    const idx = labwarePreferences.indexOf(position);
    if (idx >= 0) {
      return;
    }
    labwarePreferences.push(position);
    if (!draft.labwarePreferencesAddedOrder[position]) {
      draft.labwarePreferencesAddedOrder[position] = new Set<LabwareType>();
    }
    draft.labwarePreferencesAddedOrder[position]?.delete(labwareType);
    draft.labwarePreferencesAddedOrder[position]?.add(labwareType);
  });
}

export function removeLabwarePreference(state: State, pref: LabwarePreference) {
  return produce(state, draft => {
    const { position, labwareType } = pref;
    const labwarePreferences = getLabwarePreferencesDraft(draft, labwareType);
    const idx = labwarePreferences.indexOf(position);
    labwarePreferences.splice(idx, 1);
    draft.labwarePreferencesAddedOrder[position]?.delete(labwareType);
  });
}

export function removeAllLabwareTypePreferences(state: State, labwareType: LabwareType) {
  return produce(state, draft => {
    if (isNamedPlate(labwareType)) {
      const plateName = fromNamedPlate(labwareType);
      draft.config.GlobalMixer.driverSpecificPlatePreferences[plateName] = [];
    } else {
      const driverSpecific = DRIVER_SPECIFIC_MAP[labwareType];
      draft.config.GlobalMixer[driverSpecific] = [];
    }
    Object.values(draft.labwarePreferencesAddedOrder).forEach(labwareTypes => {
      labwareTypes?.delete(labwareType);
    });
  });
}

export function addAndSetNamedPlate(state: State, plateName: string) {
  return produce(state, draft => {
    if (!draft.config.GlobalMixer.driverSpecificPlatePreferences[plateName]) {
      draft.config.GlobalMixer.driverSpecificPlatePreferences[plateName] = [];
      draft.labwarePreferenceType = toNamedPlate(plateName);
    }
  });
}

export function removeNamedPlate(state: State, plateName: string) {
  return produce(state, draft => {
    delete draft.config.GlobalMixer.driverSpecificPlatePreferences[plateName];

    // remove from any position order tracking
    Object.values(draft.labwarePreferencesAddedOrder).forEach(position => {
      void position?.delete?.(toNamedPlate(plateName));
    });

    // if the named plate we are removing is the currently selected labware type, we need to unselect it
    if (
      isNamedPlate(draft.labwarePreferenceType) &&
      draft.labwarePreferenceType === toNamedPlate(plateName)
    ) {
      draft.labwarePreferenceType = undefined;
    }
  });
}

export function renameNamedPlate(
  state: State,
  oldPlateName: string,
  newPlateName: string,
) {
  return produce(state, draft => {
    // if a plate with the new name already exist, we do nothing.
    if (draft.config.GlobalMixer.driverSpecificPlatePreferences[newPlateName]) {
      return;
    }
    draft.config.GlobalMixer.driverSpecificPlatePreferences[newPlateName] =
      draft.config.GlobalMixer.driverSpecificPlatePreferences[oldPlateName];
    delete draft.config.GlobalMixer.driverSpecificPlatePreferences[oldPlateName];
    // if the named plate we are renaming is the currently selected labware type, we need to update it
    if (
      isNamedPlate(draft.labwarePreferenceType) &&
      draft.labwarePreferenceType === toNamedPlate(oldPlateName)
    ) {
      draft.labwarePreferenceType = toNamedPlate(newPlateName);
    }
    // if the named plate we are renaming has preferences, we need to update the labwarePreferencesAddedOrder
    const entries = Object.entries(draft.labwarePreferencesAddedOrder);
    entries.forEach(([position, labwareTypes]) => {
      const lt = [...(labwareTypes ?? [])];
      const idx = lt.indexOf(toNamedPlate(oldPlateName));
      if (idx >= 0) {
        lt.splice(idx, 1, toNamedPlate(newPlateName)),
          (draft.labwarePreferencesAddedOrder[position] = new Set(lt));
      }
    });
  });
}

export function insertLabwarePreference(
  state: State,
  pref: LabwarePreference,
  idx: number,
) {
  return produce(state, draft => {
    const { position, labwareType } = pref;
    const labwarePreferences = getLabwarePreferencesDraft(draft, labwareType);
    labwarePreferences.splice(idx, 0, position);
  });
}

export function getLabwarePreferencesAddedOrderFromConfig(config: WorkflowConfig) {
  const preferencesOrder = {} as { [key: string]: Set<LabwareType> };
  const globalMixer = config.GlobalMixer;
  Object.entries(DRIVER_SPECIFIC_MAP).forEach(([key, driverKey]) => {
    globalMixer[driverKey]?.forEach(position => {
      if (!preferencesOrder[position]) {
        preferencesOrder[position] = new Set();
      }
      preferencesOrder[position].add(key as LabwareType);
    });
  });

  Object.keys(globalMixer.driverSpecificPlatePreferences).forEach(key => {
    globalMixer.driverSpecificPlatePreferences[key].forEach(position => {
      if (!preferencesOrder[position]) {
        preferencesOrder[position] = new Set();
      }
      preferencesOrder[position].add(toNamedPlate(key));
    });
  });
  return preferencesOrder;
}

/**
 * Uses the given runConfiguration to update the draft. Specifically, the GlobalMixer
 * of the draft config, as well as updating related fields for labwarePreference that
 * are dependent on changes to the GlobalMixer.
 */
export function setDefaultConfig(
  draft: WritableDraft<State>,
  runConfiguration: DeviceParsedRunConfigQuery | undefined,
) {
  const newGlobalMixer = formatDefaultLayoutOptions(runConfiguration);
  draft.config.GlobalMixer = {
    ...draft.config.GlobalMixer,
    ...castDraft(newGlobalMixer),
  };
  // It's important to update the labware preferences here, so we display correct lab items
  // for each deck position.
  draft.labwarePreferencesAddedOrder = getLabwarePreferencesAddedOrderFromConfig(
    draft.config,
  );
  draft.labwarePreferenceType = undefined;

  resetElementParametersToDefaults(draft);
}

function resetElementParametersToDefaults(draft: WritableDraft<State>) {
  /**
   * Here we reset specific parameters related to selected device or run configuration.
   * When any of those change we reset `parameterName` to `defaultValue` for all element instances
   * so that their respective parameter editors are not broken.
   */
  for (const ei of draft.elementInstances) {
    const elementParamConfig = ei.element.configuration?.parameters;

    if (!elementParamConfig) continue;

    for (const parameterName in elementParamConfig) {
      if (isDeckPositionsParameter(parameterName, ei)) {
        draft.parameters[ei.name][parameterName] =
          elementParamConfig[parameterName]?.defaultValue;
      }
    }
  }
}
