import React, { useCallback, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';

import { useDeletePlateType } from 'client/app/api/PlateTypesApi';
import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import PlateConstructorDialog from 'client/app/apps/plate-constructor/PlateConstructorDialog';
import { PlateFormAction } from 'client/app/apps/plate-constructor/PlateForm';
import { getDeviceContentSourceFilterOptions } from 'client/app/components/DeviceLibrary/types';
import PlatesGrid from 'client/app/components/Parameters/PlateType/PlatesGrid';
import { ContentSourceType } from 'common/types/contentSource';
import { PlateType } from 'common/types/plateType';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { useDialogManager } from 'common/ui/components/DialogManager';
import Fab from 'common/ui/components/Fab';
import FilterChipWithCheckbox from 'common/ui/components/FilterChip/FilterChipWithCheckbox';
import { Option } from 'common/ui/components/FilterChip/FilterChipWithCheckbox';
import LinearProgress from 'common/ui/components/LinearProgress';
import SearchField from 'common/ui/components/SearchField';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

type Props = {
  plates: { [id: string]: PlateType };
  children?: React.ReactChild;
  onSelect?: (plate: string) => void;
  onDoubleSelect?: (plate: string) => void;
  selectedPlate?: string;
  dialog?: boolean;
  isInitialLoading: boolean;
};
export default function PlateLibrary(props: Props) {
  const classes = useStyles();
  const { onSelect, onDoubleSelect, selectedPlate, plates, isInitialLoading } = props;
  const [query, setQuery] = useState('');
  const [filterSource, setFilterSource] = useState('');

  const onChangeFilterQuery = useCallback((query: string) => {
    setQuery(query);
  }, []);

  const onChangeFilterSource = useCallback((source: string[]) => {
    const stringSource = source.toString();
    setFilterSource(stringSource);
  }, []);

  const [contentSourceTypes, setContentSourceTypes] = useStateWithURLParams({
    paramName: 'type',
    paramType: 'string[]',
    defaultValue: [],
  });

  const onFilterContentSource = useCallback(
    (newValue: Option<ContentSourceType>[]) => {
      const newSources = newValue
        .filter(option => option.selected)
        .map(option => option.label);
      setContentSourceTypes(newSources);
      return onChangeFilterSource(newSources);
    },
    [onChangeFilterSource, setContentSourceTypes],
  );

  const snackbarManager = useSnackbarManager();
  const dialogManager = useDialogManager();
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);

  const onSavePlate = useCallback(
    (plateName: string) => {
      snackbarManager.showSuccess(`"${plateName}" was saved successfully.`);
    },
    [snackbarManager],
  );

  const plateTypes = useMemo(
    () => new Set(Object.values(plates).map(plate => plate.type)),
    [plates],
  );

  const onPlateConstructor = useCallback(
    (action: PlateFormAction, selectedPlateId?: string) => {
      (async () => {
        await dialogManager.openDialogPromise(
          'PLATE_CONSTRUCTOR',
          PlateConstructorDialog,
          {
            plates,
            plateTypes,
            isOpen: true,
            isLoaded: true,
            onSavePlate,
            selectedPlateId,
            action,
          },
        );
      })();
    },
    [dialogManager, plates, plateTypes, onSavePlate],
  );

  const onCopyPlateType = useCallback(
    (selectedPlateId: string) =>
      onPlateConstructor(PlateFormAction.CREATE, selectedPlateId),
    [onPlateConstructor],
  );

  const onUpdatePlateType = useCallback(
    (selectedPlateId: string) =>
      onPlateConstructor(PlateFormAction.UPDATE, selectedPlateId),
    [onPlateConstructor],
  );

  const onFabClick = useCallback(
    () => onPlateConstructor(PlateFormAction.CREATE),
    [onPlateConstructor],
  );

  const deletePlateType = useDeletePlateType();
  const onDeletePlateType = useCallback(
    (plateName: string, plateType: string) => {
      (async () => {
        const deleteConfirmed = await openConfirmationDialog({
          action: 'delete',
          isActionDestructive: true,
          object: 'plate type',
          specificObject: `${plateName}`,
        });
        if (!deleteConfirmed) {
          return;
        }

        await deletePlateType(plateType);
      })();
    },
    [openConfirmationDialog, deletePlateType],
  );

  const plateList = useMemo(() => (plates ? Object.values(plates) : []), [plates]);

  return isInitialLoading ? (
    <LinearProgress />
  ) : (
    <ContainerWithIntersectionBar
      filters={
        <>
          <FilterChipWithCheckbox
            heading="Filter by Owner"
            defaultChipLabel="Plate Owner"
            filterValue={getDeviceContentSourceFilterOptions(contentSourceTypes ?? [])}
            onFilter={onFilterContentSource}
            className={classes.chip}
          />
          <SearchField
            placeholder="Search"
            onQueryChange={onChangeFilterQuery}
            autoFocus={!!props.dialog}
            addMargin
          />
        </>
      }
      content={
        <>
          <PlatesGrid
            plates={plateList}
            onSelect={onSelect}
            onDoubleSelect={onDoubleSelect}
            query={query}
            filterSource={filterSource}
            selectedPlate={selectedPlate}
            onDeletePlateType={!props.dialog ? onDeletePlateType : undefined}
            onCopyPlateType={!props.dialog ? onCopyPlateType : undefined}
            onUpdatePlateType={!props.dialog ? onUpdatePlateType : undefined}
          />
          {!props.dialog && <Fab icon={<AddIcon />} onClick={onFabClick} />}
          {props.children}
          {confirmationDialog}
        </>
      }
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  ...experimentsStyles(theme),
}));
