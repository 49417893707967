import React, { useMemo } from 'react';

import PlateLibraryDialog from 'client/app/components/Parameters/PlateType/PlateLibraryDialog';
import { PlateSelection } from 'client/app/components/Parameters/PlateType/PlateSelectionEditor';
import { AppIcon } from 'client/app/icons';
import { PlateType } from 'common/types/plateType';
import SelectFromDialogButton from 'common/ui/components/SelectFromDialogButton';
import getPlateInfo from 'common/ui/components/simulation-details/plate-prep/getPlateInfo';

type Props = {
  /** Human readable version of the plate, e.g. "1536 square well plate, clear" */
  displayValue: string;
  selectedPlateType: string;
  platesByType: { [type: string]: PlateType };
  onChange: (plate: PlateSelection) => void;
  isDisabled?: boolean;
};

export default function PlateTypeEditor(props: Props) {
  const { onChange, displayValue, selectedPlateType, platesByType, isDisabled } = props;

  const dialogProps = useMemo(
    () => ({ previousPlate: selectedPlateType, plates: platesByType }),
    [platesByType, selectedPlateType],
  );

  const plateInfo = useMemo(() => {
    return getPlateInfo(platesByType[selectedPlateType]);
  }, [platesByType, selectedPlateType]);

  return (
    <SelectFromDialogButton
      value={selectedPlateType}
      selectedValueLabel={displayValue}
      icon={<AppIcon iconId="antha:plate" />}
      dialog={PlateLibraryDialog}
      dialogProps={dialogProps}
      placeholder="Select a plate type"
      onChange={onChange}
      isDisabled={isDisabled}
      tooltipContent={plateInfo}
    />
  );
}
