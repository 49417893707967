import React from 'react';

import ConnectionComponent from 'client/app/components/ElementPlumber/Connection';
import * as LayoutHelper from 'client/app/lib/layout/LayoutHelper';
import { Connection, Element, ElementInstance, Terminus } from 'common/types/bundle';
import { Position2d } from 'common/types/Position';

type Props = {
  connection: Connection;
  sourceElementInstance: ElementInstance;
  targetElementInstance: ElementInstance;
  topOffset: number;
  leftOffset: number;
  isSelected: boolean;
  isDisabled: boolean;
  connectionIsPending: boolean;
  sourceDragDelta: Position2d | null;
  targetDragDelta: Position2d | null;
  onSelect: (e: MouseEvent) => void;
  connections: Connection[];
};

// CompleteConnection is a connection that has both a source and a target.
// This class exists to map the source port and target port positions into
// x and y coordinates.
function CompleteConnection(props: Props) {
  const getTypeName = () => {
    const inputPort = props.connection.Source;
    const inputElement = props.sourceElementInstance.element;
    return getPortType(inputPort, inputElement);
  };

  /**
   * Retrieve the antha type of the port on the element (e.g. wunit.Length)
   * These types are used to figure out what color to display as well as
   * what types of connections are allowed.
   */
  const getPortType = (port: Terminus, element: Element) => {
    const portList = element.outputs;
    const portData = portList.find(aPort => aPort.name === port.ParameterName);
    if (!portData) {
      throw new Error(`No port named ${port.ParameterName} on element ${element.id}`);
    }
    return portData.type;
  };

  const getSourceAndTargetPoints = () => {
    const sourcePosition = LayoutHelper.getConnectionTerminusPosition(
      props.connection.Source,
      'output',
      props.sourceElementInstance,
      props.connections,
    );

    const targetPosition = LayoutHelper.getConnectionTerminusPosition(
      props.connection.Target,
      'input',
      props.targetElementInstance,
      props.connections,
    );

    return { sourcePosition, targetPosition };
  };

  const { sourcePosition, targetPosition } = getSourceAndTargetPoints();

  // We expect to find a source and target for both ends of the connection, even
  // if the ports have been configured as hidden, so if this happens this means
  // something has gone very wrong.
  if (!sourcePosition || !targetPosition) {
    throw new Error(
      `Failed to locate source and/or target for a connection between ${props.sourceElementInstance.name} and ${props.targetElementInstance.name}.`,
    );
  }
  const typeName = getTypeName();
  const { leftOffset, topOffset } = props;

  // While the user is dragging selected elements around the screen, we avoid
  // expensive mutations of the WorkflowBuilderState by keeping track of the position
  // change of just the single element the user is actually interacting with.
  // This is a performance enhancement as well as required functionality for
  // doing multi-element dragging. A connection could be between two
  // unselected elements; one selected, one unselected, or both selected.
  // Hence, here we need to apply whichever drag delta there might be.
  const { sourceDragDelta, targetDragDelta } = props;

  const startPosition = {
    x: sourcePosition.x + (sourceDragDelta?.x ?? 0) + leftOffset,
    y: sourcePosition.y + (sourceDragDelta?.y ?? 0) + topOffset,
  };

  const endPosition = {
    x: targetPosition.x + (targetDragDelta?.x ?? 0) + leftOffset,
    y: targetPosition.y + (targetDragDelta?.y ?? 0) + topOffset,
  };

  return (
    <ConnectionComponent
      startPosition={startPosition}
      endPosition={endPosition}
      connectionIsPending={props.connectionIsPending}
      isSelected={props.isSelected}
      isDisabled={props.isDisabled}
      onClick={(e: MouseEvent) => {
        props.onSelect(e);
      }}
      type={typeName}
      complete
    />
  );
}

export default CompleteConnection;
