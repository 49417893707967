import React, { Ref, useCallback, useMemo, useState } from 'react';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';

import useExecuteSimulation from 'client/app/apps/simulation-details/useExecuteSimulation';
import { SimulationQuery } from 'client/app/gql';
import { executionRoutes } from 'client/app/lib/nav/actions';
import { hasDispenserOrManualDevice } from 'client/app/lib/workflow/deviceConfigUtils';
import { ScreenRegistry } from 'client/app/registry';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import SimpleDialog from 'common/ui/components/Dialog/SimpleDialog';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import useDialog, { DialogProps } from 'common/ui/hooks/useDialog';

type ScheduleButtonProps = {
  simulation: SimulationQuery['simulation'];
};

export default function ScheduleButton({ simulation }: ScheduleButtonProps) {
  const isSyntheticDataEnabled = useFeatureToggle('SYNTHETIC_DATA');
  const isEnabledBreadcrumbs = useFeatureToggle('BREADCRUMBS');
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const [hasAlreadyClickedSchedule, setHasAlreadyClickedSchedule] = useState(false);

  const { navigate } = useNavigation();

  // There is something to execute in the lab.
  const hasTasksToBeExecuted = simulation.tasks ? simulation.tasks.length > 0 : false;

  const executeSimulation = useExecuteSimulation(simulation.id);

  const executeFakeSimulation = useCallback(
    () => executeSimulation(true),
    [executeSimulation],
  );

  const showExecuteConfirmationDialog = useCallback(
    async (fake: boolean) => {
      logEvent('open-execute-dialog', ScreenRegistry.SIMULATION_DETAILS);
      const confirmExecute = await openConfirmationDialog({
        action: 'schedule',
        object: 'execution',
        specificObject: simulation.name,
        additionalMessage: fake
          ? 'This will create an already-completed execution.  Nothing will show up in the lab.'
          : 'You will find the execution on the lab computer connected to your device.',
      });

      if (confirmExecute) {
        logEvent('confirm-execute-dialog', ScreenRegistry.SIMULATION_DETAILS);
        setHasAlreadyClickedSchedule(true);
        const newExecution = await executeSimulation(fake);
        if (newExecution && isEnabledBreadcrumbs) {
          navigate(executionRoutes.openInExecutionDetails, {
            executionId: newExecution.id,
          });
        }
      } else {
        logEvent('close-execute-dialog', ScreenRegistry.SIMULATION_DETAILS);
      }
    },
    [
      executeSimulation,
      isEnabledBreadcrumbs,
      navigate,
      openConfirmationDialog,
      simulation.name,
    ],
  );

  const [markAsCompletedDialog, openMarkAsCompletedDialog] =
    useDialog(MarkAsCompletedDialog);
  /*
   * Hacky solution to unblock Alpha deployment to clients. This solution
   * is circumscribed to this file only, so it will be easy to delete later.
   * (T4373)
   * AnthaHub does not support "Dispensers", so "Schedule" doesn't work
   * as expected. However, users must have access to the "Results" page, which
   * is possible only if they schedule and AnthaHub supports the device.
   * As a workaround, if users simulate with "Dispensers", we'll allow them
   * to mark execution as complete rather than scheduling it.
   */
  const isSimulatingWithADispenserOrManualDevice = useMemo(() => {
    return hasDispenserOrManualDevice(simulation.workflow.workflow.Config);
  }, [simulation.workflow]);

  const showMarkAsCompletedDialog = useCallback(async () => {
    logEvent('open-mark-as-completed-dialog', ScreenRegistry.SIMULATION_DETAILS);
    const confirmMarkasCompleted = await openMarkAsCompletedDialog({
      simulationName: simulation.name,
    });

    if (confirmMarkasCompleted) {
      logEvent('confirm-mark-as-completed-dialog', ScreenRegistry.SIMULATION_DETAILS);
      setHasAlreadyClickedSchedule(true);
      const newExecution = await executeFakeSimulation();
      if (newExecution && isEnabledBreadcrumbs) {
        navigate(executionRoutes.openInExecutionDetails, {
          executionId: newExecution.id,
        });
      } else {
        window.location.reload();
      }
    } else {
      logEvent('close-mark-as-completed-dialog', ScreenRegistry.SIMULATION_DETAILS);
    }
  }, [
    executeFakeSimulation,
    isEnabledBreadcrumbs,
    navigate,
    openMarkAsCompletedDialog,
    simulation.name,
  ]);

  if (!hasTasksToBeExecuted) {
    // Computational simulation.
    return null;
  }

  const preventReschedule = simulation.execution || hasAlreadyClickedSchedule;
  if (preventReschedule) {
    // Supporting multiple Executions in the UI requires non-trivial rethinking
    // of several parts of the UI (e.g. Simulation Details, Select Plate from Execution
    // in Workflow Builder).
    // Until that UI redesign happens, we only allow each Simulation to have at most
    // one Execution. If there is already an Execution, we disable this button and therefore
    // users cannot add a second Execution.
    return (
      <Tooltip
        title="The execution is already scheduled. If you want to schedule this
               to run in the lab again, please re-simulate the workflow."
        disableHoverListener={isSimulatingWithADispenserOrManualDevice}
      >
        <ScheduleButtonExtendedFab
          disabled
          label={
            isSimulatingWithADispenserOrManualDevice ? 'Mark as completed' : 'Schedule'
          }
          onClick={() => showExecuteConfirmationDialog(true)}
        />
      </Tooltip>
    );
  } else {
    return (
      <>
        {isSyntheticDataEnabled && (
          <ScheduleButtonExtendedFab
            label="Create fake execution"
            onClick={() => showExecuteConfirmationDialog(true)}
          />
        )}
        {isSimulatingWithADispenserOrManualDevice ? (
          // TODO - Deprecate once Breadcrumbs lands
          <ScheduleButtonExtendedFab
            label="Mark as completed"
            onClick={showMarkAsCompletedDialog}
          />
        ) : (
          <Tooltip title="Schedule to run in the lab">
            <ScheduleButtonExtendedFab
              label="Schedule"
              onClick={() => showExecuteConfirmationDialog(false)}
            />
          </Tooltip>
        )}
        {confirmationDialog}
        {markAsCompletedDialog}
      </>
    );
  }
}

type ScheduleButtonExtendedFabProps = {
  label: string;
  disabled?: boolean;
  onClick: () => void;
};

const ScheduleButtonExtendedFab = React.forwardRef(function ScheduleButtonExtendedFab(
  { label, disabled, onClick, ...tooltipProps }: ScheduleButtonExtendedFabProps,
  ref: Ref<HTMLSpanElement>,
) {
  const isEnabledBreadcrumbs = useFeatureToggle('BREADCRUMBS');

  const Component = isEnabledBreadcrumbs ? StyledFab : Fab;

  return (
    <span ref={ref} {...tooltipProps}>
      <Component
        data-heap-tracking="schedule-button"
        color="secondary"
        size="medium"
        variant="extended"
        disabled={disabled}
        onClick={onClick}
      >
        {isEnabledBreadcrumbs && <AccessTimeOutlinedIcon />}
        {label}
      </Component>
    </span>
  );
});

function MarkAsCompletedDialog(props: DialogProps<boolean> & { simulationName: string }) {
  const { isOpen, onClose, simulationName } = props;

  const handleOK = () => {
    onClose(true);
  };

  const handleCancel = () => {
    onClose(false);
  };

  return (
    <SimpleDialog
      title="Mark as a completed execution"
      contentText={`Marking "${simulationName}" as a completed execution will enable uploading related analytical data under a Results tab on this page and add this execution to the Executions tab on the Experiments page for easy access.
  This cannot be undone.`}
      submitButtonLabel="Mark as completed"
      onSubmit={handleOK}
      onClose={handleCancel}
      isOpen={isOpen}
    />
  );
}

const StyledFab = styled(Fab)(({ theme: { spacing } }) => ({
  gap: spacing(3),
}));
