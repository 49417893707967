import React, { PropsWithChildren, useCallback } from 'react';

import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import { DocsButton } from 'client/app/components/nav/DocsButton';
import ExperimentChipPicker, {
  ExperimentChipPickerProps,
} from 'client/app/components/nav/experiment-chip-picker/ExperimentChipPicker';
import { IntercomButton } from 'client/app/components/nav/IntercomButton';
import InventorySubmenu from 'client/app/components/nav/LegacyInventorySubmenu';
import UserDetailsMenu from 'client/app/components/nav/UserDetailsMenu';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { getScreenInfo, ScreenDetails } from 'client/app/lib/nav/mainNav';
import { ScreenRegistry, TOP_NAV_MENU_ID } from 'client/app/registry';
import { SynthaceBrandmark } from 'common/assets/SynthaceBrandmark';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { getEnvColour, getEnvName } from 'common/lib/envs';
import Colors from 'common/ui/Colors';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import Tooltip from 'common/ui/components/Tooltip';
import StyledTopNavContainer from 'common/ui/components/TopNav/StyledTopNavContainer';
import { topNavStyles } from 'common/ui/components/TopNav/topNavStyles';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { ExperimentsIcon } from 'common/ui/icons/Experiments';

export type Props = {
  experimentChipPickerProps?: ExperimentChipPickerProps;
};

/**
 * Default Header
 * It renders navigation on the left, screen title in the center and profile on the right.
 */
export default function DefaultHeader(props: Props) {
  const { currentScreenID } = useNavigation();
  const screenInfo = getScreenInfo(currentScreenID);

  return (
    <Header {...screenInfo} experimentChipPickerProps={props.experimentChipPickerProps}>
      <Typography variant="h5">{screenInfo.label}</Typography>
    </Header>
  );
}

/**
 * A Header
 * It renders navigation on the left and profile on the right.
 * The child component will be at the center.
 */
export function HeaderWithContent(props: React.PropsWithChildren<Props>) {
  const { currentScreenID } = useNavigation();
  const screenInfo = getScreenInfo(currentScreenID);

  return (
    <Header {...screenInfo} experimentChipPickerProps={props.experimentChipPickerProps}>
      {props.children}
    </Header>
  );
}

function Header(props: PropsWithChildren<Omit<ScreenDetails, 'label'>> & Props) {
  const classes = useStyles();
  const userProfile = useUserProfile();
  const envName = getEnvName(window.location?.host);
  const isShowColorLogo = useFeatureToggle('SHOW_COLOR_LOGO');
  const envColour = isShowColorLogo ? 'multicolor' : getEnvColour(envName);
  const { getScreenPath } = useNavigation();
  const handleLogoClick = useCallback(() => {
    logEvent('logo-clicked', TOP_NAV_MENU_ID);
  }, []);

  return (
    <StyledTopNavContainer backgroundOverrideClass={classes.backgroundOverride}>
      {userProfile && (
        <>
          <nav className={cx(classes.nav, classes.leftNavGroup)}>
            <SynthaceBrandmark
              className={classes.logo}
              logoColour={envColour}
              onClick={handleLogoClick}
            />
            <NavLink to={getScreenPath(ScreenRegistry.EXPERIMENTS)}>
              <Tooltip title="Experiments">
                <ListItem
                  className={cx(classes.selectedLink, classes.center)}
                  button
                  disableRipple
                  selected={props.category === 'Experiments'}
                >
                  <ExperimentsIcon />
                </ListItem>
              </Tooltip>
            </NavLink>
            <InventorySubmenu />
          </nav>
          <div className={classes.navContent}>
            <Divider className={classes.divider} orientation="vertical" />
            {props.experimentChipPickerProps && (
              <ExperimentChipPicker {...props.experimentChipPickerProps} />
            )}
            {props.children}
          </div>
          <div className={cx(classes.additionalItems, classes.rightNavGroup)}>
            <IntercomButton userProfile={userProfile} />
            <DocsButton />
            <UserDetailsMenu userProfile={userProfile} />
          </div>
        </>
      )}
    </StyledTopNavContainer>
  );
}

const LEFT_NAV_GROUP_WIDTH = 152;
const RIGHT_NAV_GROUP_WIDTH = 144;

const useStyles = makeStylesHook(theme => ({
  ...topNavStyles(theme),
  backgroundOverride: {
    background: `linear-gradient(to right, ${Colors.BLACK} 66.11%, ${Colors.BLUE_90} 89.39%, ${Colors.INDIGO_70} 100%)`,
  },
  divider: {
    color: theme.palette.divider,
    height: '24px',
    marginLeft: theme.spacing(6),
  },
  navContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minWidth: '350px',
    maxWidth: `calc(100% - ${LEFT_NAV_GROUP_WIDTH + RIGHT_NAV_GROUP_WIDTH}px)`,
    gap: theme.spacing(7),
    marginRight: theme.spacing(7),
  },
  leftNavGroup: {
    minWidth: LEFT_NAV_GROUP_WIDTH,
  },
  rightNavGroup: {
    minWidth: RIGHT_NAV_GROUP_WIDTH,
  },
}));
