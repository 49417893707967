import React, { useCallback, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';

import { MANUFACTURER_IMAGES } from 'client/app/components/Parameters/PlateType/plateConstants';
import PlateDrawing from 'client/app/components/Parameters/PlateType/PlateDrawing';
import UILeftRight from 'client/app/components/UILeftRight';
import sanitizeLink from 'client/app/lib/sanitizeLink';
import { getVolumeString } from 'common/lib/format';
import { PlateType } from 'common/types/plateType';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import Tooltip from 'common/ui/components/Tooltip';
import { useCardClick, useScrollToCard } from 'common/ui/hooks/cardHooks';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { usePopover } from 'common/ui/hooks/usePopover';
import { ExampleGalleryIcon } from 'common/ui/icons/ExampleGalleryIcon';

const additionalInfo: [keyof PlateType, string][] = [
  ['accessory', 'Accessory'],
  ['catalogNumber', 'Number'],
  ['type', 'Parameter Value'],
  ['manufacturer', 'Manufacturer'],
];

type Props = {
  onSelect: (plateId: string) => void;
  onDoubleSelect: (plateId: string) => void;
  selected?: boolean;
  onDeletePlateType?: (plateName: string, plateType: string) => void;
  onCopyPlateType?: (plateId: string) => void;
  onUpdatePlateType?: (plateId: string) => void;
} & PlateType;

export const PlateCard = React.memo(function PlateCard(props: Props) {
  // get the type from props, which is a PlateType, when selecting a card, as the type is the field that needs to be inserted in workflows
  const { type, onSelect, selected, onDoubleSelect } = props;
  const classes = useStyles();
  const { onClick, onDoubleClick } = useCardClick(type, onSelect, onDoubleSelect);
  const selectedCardRef = useScrollToCard();
  return (
    <Card
      raised={selected}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      className={classes.card}
    >
      <div
        className={classNames(classes.inner, { [classes.selected]: selected })}
        // Only get a ref if this is the selected plate
        ref={ref => (selected ? (selectedCardRef.current = ref) : null)}
      >
        <PlateHeader {...props} />
        <PlateContent {...props} />
        <PlateFooter {...props} />
      </div>
    </Card>
  );
});

function PlateFooter(props: Props) {
  const classes = useStyles();
  const { onDeletePlateType, editable, name, type } = props;
  const [collapsed, setCollapsed] = useState(true);
  const toggle = useCallback(() => setCollapsed(collapsed => !collapsed), [setCollapsed]);
  const onClickDelete = useCallback(
    () => onDeletePlateType?.(name, type),
    [name, onDeletePlateType, type],
  );

  return (
    <div>
      <Divider />
      <UILeftRight>
        <>
          {onDeletePlateType && editable && (
            <Button variant="tertiary" onClick={onClickDelete}>
              Delete
            </Button>
          )}
          {props.catalogUrl ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={sanitizeLink(props.catalogUrl)}
              className={classes.catalogLink}
            >
              <Button variant="tertiary">Visit Catalog</Button>
            </a>
          ) : null}
        </>
        <div>
          {collapsed ? <ExpandMore onClick={toggle} /> : <ExpandLess onClick={toggle} />}
        </div>
      </UILeftRight>
      <Collapse in={!collapsed}>
        {additionalInfo.map(([key, label]) => {
          const value = props[key] as string | undefined | null;
          return <InfoLine key={key} label={label} value={value} />;
        })}
      </Collapse>
    </div>
  );
}

function PlateHeaderAction(props: {
  id: string;
  onCopyPlateType: (id: string) => void;
  onUpdatePlateType: (id: string) => void;
  editable: boolean;
}) {
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  const { onCopyPlateType, onUpdatePlateType, id, editable } = props;
  const onClickCopy = () => onCopyPlateType(id);
  const onClickUpdate = () => onUpdatePlateType(id);

  return (
    <>
      <IconButton onClick={onShowPopover} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={popoverAnchorElement}
        keepMounted
        open={isPopoverOpen}
        onClose={onHidePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onClickCopy}>Copy to custom plate</MenuItem>
        <MenuItem onClick={onClickUpdate} disabled={!editable}>
          Edit plate type
        </MenuItem>
      </Menu>
    </>
  );
}

function PlateHeader(props: Props) {
  const classes = useStyles();
  const subheaderContent =
    props.contentSource === 'EXAMPLE' ? (
      <div className={classes.exampleIconAndDescription}>
        <div>{props.description}</div>
        <Tooltip title="Example Plate" placement="bottom">
          <div className={classes.iconDiv}>
            <ExampleGalleryIcon />
          </div>
        </Tooltip>
      </div>
    ) : (
      props.description
    );

  return (
    <CardHeader
      title={props.name}
      subheader={subheaderContent}
      avatar={<ManufacturerIcon {...props} />}
      action={
        props.onCopyPlateType && props.onUpdatePlateType ? (
          <PlateHeaderAction
            onCopyPlateType={props.onCopyPlateType}
            onUpdatePlateType={props.onUpdatePlateType}
            id={props.id}
            editable={props.editable}
          />
        ) : null
      }
    />
  );
}

function ManufacturerIcon(props: Props) {
  if (!MANUFACTURER_IMAGES[props.manufacturer ?? '']) {
    return <div style={{ width: '0px', height: '40px' }} />;
  }
  return (
    <img
      src={MANUFACTURER_IMAGES[props.manufacturer ?? '']}
      style={{ width: '40px', height: '40px' }}
    />
  );
}

function PlateContent(props: Props) {
  const classes = useStyles();
  return (
    <div className={classes.content}>
      <PlateDrawing {...props} />
      <WellVolume
        max={props.wellShape.volumeOverrideUl}
        min={props.defaultResidualVolume}
      />
    </div>
  );
}

function InfoLine({ label, value }: { label: string; value?: string | null }) {
  return (
    <div>
      <UILeftRight>
        <Typography>{label}</Typography>
        <Typography>{value}</Typography>
      </UILeftRight>
    </div>
  );
}

function WellVolume({ max, min }: { max: number; min: number }) {
  const classes = useStyles();
  return (
    <div className={classes.wellVolume}>
      <div>{getVolumeString(max)}</div>
      <div>{getVolumeString(min)}</div>
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  card: {
    alignSelf: 'center',
  },
  inner: {
    padding: '5px',
    border: `1px solid transparent`,
    display: 'flex',
    flexDirection: 'column',
  },
  selected: {
    border: `1px solid ${Colors.BLUE_50}`,
  },
  content: {
    flexBasis: '160px',
    overflow: 'hidden',
    padding: '5px',
    display: 'grid',
    gridTemplateColumns: '4fr 1fr',
    gridTemplateRows: '100%',
  },
  plateImage: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'left center',
  },
  wellVolume: {
    width: '60px',
    height: '60px',
    justifySelf: 'flex-end',
    justifyContent: 'space-around',
    display: 'flex',
    flexDirection: 'column',
    padding: '2px',
    '& div:first-child': {
      color: Colors.GREEN,
    },
    '& div:last-child': {
      color: Colors.RED,
    },
    alignItems: 'center',
    border: `3px solid ${Colors.GREY_40}`,
  },

  catalogLink: {
    textDecoration: 'none',
  },
  iconDiv: {
    display: 'inline-block',
    marginLeft: theme.spacing(1),
  },
  exampleIconAndDescription: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
}));
