import React, { useCallback, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { QUERY_ELEMENT_SET_WITH_ELEMENTS } from 'client/app/api/gql/queries';
import { GraphQLWorkflow } from 'client/app/api/gql/utils';
import * as WorkflowsApi from 'client/app/api/WorkflowsApi';
import Panel from 'client/app/apps/workflow-builder/panels/Panel';
import { ElementSetSelector } from 'client/app/apps/workflow-builder/panels/switch-element-set/ElementSetSelector';
import { ElementSetQuery } from 'client/app/gql';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { getMissingElementNames } from 'common/types/bundleTransforms';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import LinearProgress from 'common/ui/components/LinearProgress';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type GraphQLElementSet = ElementSetQuery['elementSet'];

export type ElementBranchSwitcherResults = {
  updatedWorkflow?: GraphQLWorkflow;
  selectedElementSet?: GraphQLElementSet;
};

type Props = {
  workflowId: WorkflowId;
  onClose: (result?: ElementBranchSwitcherResults) => void;
  className: string;
};

export function ElementBranchSwitcher(props: Props) {
  const { workflowId, onClose, className } = props;
  const snackbarManager = useSnackbarManager();
  const classes = useStyles();

  const { originalElementInstances, originalElementSetID } = useWorkflowBuilderSelector(
    state => {
      return {
        originalElementInstances: state.elementInstances,
        originalElementSetID: state.elementSet?.id ?? '',
      };
    },
  );
  const [selectedElementSetID, setSelectedElementSetID] = useState(originalElementSetID);
  const [isSwitching, setIsSwitching] = useState(false);
  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(QUERY_ELEMENT_SET_WITH_ELEMENTS, {
    variables: { id: selectedElementSetID },
  });
  if (error) {
    snackbarManager.showError(`Error while querying element set: ${error.message}`);
  }

  const selectedElementSet = data?.elementSet;
  const isOriginalSetSelected = selectedElementSetID === originalElementSetID;

  // For now only missing elements are investigated, but missing parameters should be
  // looked at too. Element might have been updated and a parameter could be removed.
  // Connections from/to non-existing parameters should be removed too.
  const missingElementNames = useMemo(() => {
    if (isLoading || !selectedElementSet) {
      return null;
    }
    const elementNames = originalElementInstances.map(instance => instance.element.name);
    return getMissingElementNames(elementNames, selectedElementSet.elements);
  }, [isLoading, selectedElementSet, originalElementInstances]);

  const didCheckForMissingElements = missingElementNames !== null;

  const canConfirm = useMemo(
    () =>
      didCheckForMissingElements &&
      !isOriginalSetSelected &&
      !isSwitching &&
      selectedElementSet,
    [didCheckForMissingElements, isOriginalSetSelected, isSwitching, selectedElementSet],
  );

  const switchElementSet = useSwitchElementSet();

  const onConfirm = useCallback(async () => {
    setIsSwitching(true);
    try {
      if (!selectedElementSet) {
        throw new Error('Selected element set is undefined');
      }
      const updatedWorkflow = await switchElementSet(workflowId, selectedElementSet);
      onClose({ updatedWorkflow, selectedElementSet });
    } catch (error) {
      snackbarManager.showError(`Error while changing element set: ${error.message}`);
    } finally {
      setIsSwitching(false);
      // We're done now, and the element set is switched - so trigger the onClose().
      onClose();
    }
  }, [onClose, selectedElementSet, snackbarManager, switchElementSet, workflowId]);

  const onCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Panel
      title="Elements Branch"
      panelContent="ElementsBranch"
      className={cx(className, classes.elementBranchSwitcher)}
    >
      <ElementSetSelector
        selectedElementSetID={selectedElementSetID}
        onChange={setSelectedElementSetID}
      />
      <Box margin="1rem 0">
        {!isOriginalSetSelected && (
          <ElementsStatus
            isSwitching={isSwitching}
            isLoading={isLoading}
            missingElementNames={missingElementNames}
            elementSetName={selectedElementSet?.name ?? ''}
          />
        )}
      </Box>
      <div className={classes.buttons}>
        <Button variant="tertiary" disabled={isSwitching} onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="tertiary"
          color="primary"
          disabled={!canConfirm}
          onClick={onConfirm}
        >
          Save
        </Button>
      </div>
    </Panel>
  );
}

function ElementsStatus({
  isLoading,
  isSwitching,
  missingElementNames,
  elementSetName,
}: {
  isSwitching: boolean;
  isLoading: boolean;
  missingElementNames: null | Set<string>;
  elementSetName?: string;
}) {
  const classes = useStyles();

  if (isSwitching || isLoading) {
    return (
      <>
        <Typography
          className={classes.text}
          color="textPrimary"
          variant="body2"
          gutterBottom
        >
          {isSwitching ? 'Updating  workflow...' : 'Loading elements...'}
        </Typography>
        <LinearProgress />
      </>
    );
  }

  if (missingElementNames === null || !elementSetName) {
    return null;
  }

  if (missingElementNames.size === 0) {
    return (
      <Typography className={classes.text} color="textPrimary" variant="body2">
        All elements used in this workflow were found in the branch {elementSetName}. You
        can safely switch to the branch.
      </Typography>
    );
  }

  return (
    <>
      <Typography
        className={classes.text}
        color="textPrimary"
        variant="body2"
        gutterBottom
      >
        These elements were not found in branch {elementSetName}:
      </Typography>
      <Typography
        className={classes.text}
        color="textPrimary"
        variant="body2"
        gutterBottom
      >
        {[...missingElementNames].join(', ')}
      </Typography>
      <Typography className={classes.text} color="textPrimary" variant="body2">
        You can still switch to branch {elementSetName}, but the missing elements and
        their connections will be removed from the workflow.
      </Typography>
    </>
  );
}

function useSwitchElementSet() {
  const updateElementSet = WorkflowsApi.useUpdateElementSet();
  return async (workflowId: WorkflowId, selectedElementSet: GraphQLElementSet) => {
    const updatedWorkflow = await updateElementSet(workflowId, selectedElementSet.id);

    return updatedWorkflow;
  };
}

const useStyles = makeStylesHook({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  elementBranchSwitcher: {
    height: 'max-content',
    maxHeight: '100%',
  },
  text: {
    color: Colors.TEXT_SECONDARY,
  },
});
