import React, { useMemo, useRef } from 'react';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import cx from 'classnames';

import { getLabwareIcon } from 'client/app/apps/workflow-builder/DeckPositionChip';
import { formatLabwareTypeName, LabwareType } from 'client/app/state/LabwarePreference';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { Position2d } from 'common/types/Position';
import Colors from 'common/ui/Colors';
import { DeckPositionRect } from 'common/ui/components/simulation-details/mix/DeckLayout';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { isDrag } from 'common/ui/lib/ClickRecognizer';

type Props = {
  deckPosition: DeckPositionRect;
  selectedIndex: number;
  count: number;
  disabled: boolean;
  selected: boolean;
  onSelect: (id: string) => void;
};

export default function MovePlatePosition({
  deckPosition,
  selectedIndex,
  count,
  disabled,
  selected,
  onSelect,
}: Props) {
  const styles = useStyles();

  const labwarePreferences = useLabwarePreferences(deckPosition);

  const { ...pointerEvents } = useDeckPositionPointerEvents(
    deckPosition,
    disabled,
    onSelect,
  );

  const deckPostionCopy = disabled ? 'NOT SELECTABLE' : selected ? 'SELECTED' : 'SELECT';

  return (
    <div
      className={styles.singleDeckPosition}
      style={deckPosition.absolutePosInDeckPixels}
    >
      <main className={cx({ disabled, selected })} {...pointerEvents}>
        {disabled && (
          <header>
            {labwarePreferences.map(labware => (
              <LabwareChip key={labware} labware={labware} />
            ))}
          </header>
        )}
        <section className={cx({ selected })}>
          {selected && (
            <CheckCircleRoundedIcon
              className={cx(styles.successIcon, 'selectTextHover')}
            />
          )}
          <span className="selectTextHover">{deckPostionCopy}</span>
        </section>
        <footer>
          <span
            className={cx(styles.positionBadge, 'positionBadgeHover', { disabled })}
            title={deckPosition.deckPositionName}
          >
            {deckPosition.deckPositionName}
          </span>
          {selected && count > 1 && (
            <span className={styles.positionOrderNumber}>
              {selectedIndex} of {count}
            </span>
          )}
        </footer>
      </main>
    </div>
  );
}

function useLabwarePreferences(deckPosition: DeckPositionRect) {
  const addedOrder = useWorkflowBuilderSelector(
    state => state.labwarePreferencesAddedOrder,
  );
  const labwarePreferences = addedOrder[deckPosition.deckPositionName];
  return useMemo(
    () => (labwarePreferences ? [...labwarePreferences] : []),
    [labwarePreferences],
  );
}

/**
 * Users should be able to drag into their desired positions without any odd
 * behaviour such as selecting a position while dragging.
 * So we block the position selection when user's intent was just to drag the view.
 */
function useDeckPositionPointerEvents(
  deckPosition: DeckPositionRect,
  disabled: boolean,
  onSelect: (p: string) => void,
) {
  const pointerPositionRef = useRef<Position2d | null>(null);
  const onPointerDown = (event: React.PointerEvent) => {
    pointerPositionRef.current = { x: event.clientX, y: event.clientY };
  };
  const onPointerUp = (event: React.PointerEvent) => {
    if (!disabled && !isDrag(pointerPositionRef.current, event)) {
      onSelect(deckPosition.deckPositionName);
    }
  };

  return {
    onPointerDown,
    onPointerUp,
  };
}

type LabwareChipProps = { labware: LabwareType };

function LabwareChip({ labware }: LabwareChipProps) {
  const styles = useStyles();

  return (
    <div className={styles.labwareChip}>
      {getLabwareIcon(labware)}
      <span>{formatLabwareTypeName(labware)}</span>
    </div>
  );
}

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  singleDeckPosition: {
    position: 'absolute',
    userSelect: 'none',

    '& > main': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      color: palette.text.primary,
      background: Colors.WHITE,
      border: '0.5px solid rgba(0, 0, 0, 0.23)',
      borderRadius: '6px',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12)',

      position: 'relative',
      height: '100%',

      cursor: 'pointer',

      '&.disabled': {
        cursor: 'initial',
        color: palette.text.disabled,
        background: Colors.GREY_5,
        border: `1px solid ${Colors.GREY_20}`,
      },
      '&.selected': {
        border: `2px solid ${palette.primary.main}`,
      },
      '&:not(.disabled):hover': {
        background: Colors.BLUE_0,
        '& .selectTextHover': {
          color: palette.primary.main,
        },
        '& .positionBadgeHover': {
          background: Colors.BLUE_5,
        },
      },

      '& > header,footer': {
        position: 'absolute',
        padding: spacing(5),
        width: '100%',
      },

      '& > header': {
        top: 0,

        display: 'flex',
        flexDirection: 'column',
      },
      '& > section': {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
        fontSize: '24px',
        letterSpacing: '0.3px',
        textTransform: 'uppercase',

        '&.selected': {
          color: palette.text.secondary,
        },
      },
      '& > footer': {
        bottom: 0,

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    },
  },
  positionBadge: {
    padding: spacing(2, 3),

    color: palette.text.secondary,
    background: Colors.GREY_10,
    borderRadius: '4px',

    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: '0.1px',

    maxWidth: '75%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    '&.disabled': {
      background: Colors.GREY_20,
    },
  },
  positionOrderNumber: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    marginRight: spacing(3),
  },
  labwareChip: {
    display: 'flex',
    alignItems: 'center',
    color: palette.text.secondary,

    width: 'fit-content',
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '16px',

    padding: '7px 14px',
    marginBottom: spacing(3),
    '&:last-child': {
      marginBottom: 'initial',
    },

    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.1px',

    '& > svg': {
      fontSize: '18px',
      marginRight: spacing(3),
    },
  },
  successIcon: {
    color: Colors.SUCCESS_MAIN,
    fontSize: '40px',
    marginRight: '10px',
  },
}));
