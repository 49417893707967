import React, { useCallback, useMemo, useState } from 'react';

import {
  ContentsByWell,
  OrderedParameter,
  WellParametersProps,
} from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import WellGroupListItem from 'client/app/components/Parameters/PlateContents/WellGroupListItem';
import { filterMap, getFirstValue } from 'common/object';
import { ParameterValueDict } from 'common/types/bundle';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDebounce from 'common/ui/hooks/useDebounce';

export const EMPTY_WELL_GROUP_ID = Symbol('EMPTY');

const AUTOSAVE_DEBOUNCE_MS = 500;

export type WellGroupListProps = {
  /**
   * All well groups to show in the list.
   */
  groups: WellGroup[];
  /**
   * Contents of each well, indexed by well address (e.g. A1, B1)
   */
  contentsByWell: ContentsByWell;
  /**
   * List of wells that the user has currently selected.
   */
  selectedWells: string[];
  /**
   * Subset of the selectedWells that are empty.
   */
  selectedEmptyWells: string[];
  /**
   * Function to generate initial well contents when the user adds wells to a group.
   */
  generateWellContents: (
    wellLocations: string[],
    contentsToCopy?: ParameterValueDict,
  ) => ContentsByWell;
  /**
   * Callback which generates the component containing fields for modifying currently selected wells.
   */
  wellParameters: (params: WellParametersProps<ParameterValueDict>) => JSX.Element;
  onChange: (newContentsByWell: ContentsByWell) => void;
  onSelectionChange: (locations: string[]) => void;
  /**
   * Disables user input (e.g. deleting groups). The user can still click on
   * groups to view well contents.
   */
  isDisabled?: boolean;
  /**
   * The liquid group currently being edited (i.e. the open liquid card).
   */
  editingGroup: WellGroup | undefined;
  handleSetEditingGroup: (group: WellGroup | undefined) => void;
  /**
   * The Parameters related to the contents of the well group list items.
   */
  contentPropertyParams: OrderedParameter[];
};

/**
 * A group of well which have identical contents, and can therefore be collapsed
 * into a single list item.
 */
export type WellGroup = {
  id: string;
  contentsByWell: ContentsByWell;
  color: string;
  title: string;
  isEmpty?: boolean;
};

/**
 * Renders a list of WellGroupListItem components (one for each group of wells).
 */
export default function WellGroupList({
  contentsByWell,
  selectedWells,
  wellParameters,
  isDisabled,
  onChange,
  onSelectionChange,
  generateWellContents,
  editingGroup,
  handleSetEditingGroup,
  groups,
  selectedEmptyWells,
  contentPropertyParams,
}: WellGroupListProps) {
  const classes = useStyles();
  const editingGroupId = editingGroup?.id ?? '';

  // We store state prior to pressing 'Assign Liquids' so that we can restore state if user cancels
  const [prevState, setPrevState] = useState<ContentsByWell>(contentsByWell);

  const handleDelete = useCallback(
    (deletedWells: Set<string>) => {
      onChange(
        filterMap(contentsByWell, wellLocation => !deletedWells.has(wellLocation)),
      );
      handleSetEditingGroup(undefined);
      onSelectionChange([]);
    },
    [contentsByWell, handleSetEditingGroup, onChange, onSelectionChange],
  );

  const handleAddLiquidCard = useCallback(() => {
    handleSetEditingGroup(undefined);
    onSelectionChange([]);
    setPrevState(contentsByWell);
  }, [contentsByWell, handleSetEditingGroup, onSelectionChange]);

  // Because we are autosaving, these parameter will directly change the workflow. We enclose this in
  // a debounce as the user may be typing in longer parameter (e.g. text)
  // and we don't want to save until they are complete.
  const handleChange = useDebounce((changedContentsByWell: ContentsByWell) => {
    onChange(new Map([...contentsByWell, ...changedContentsByWell]));
  }, AUTOSAVE_DEBOUNCE_MS);

  // When user clicks cancel in the editor, reset the selection. This will also
  // cause the parameters editor to close, bringing the user back to the plate
  // summary.
  const handleCancel = useCallback(() => {
    handleSetEditingGroup(undefined);
    onSelectionChange([]);
    onChange(prevState);
  }, [handleSetEditingGroup, onChange, onSelectionChange, prevState]);

  const handleAddWells = useCallback(
    (groupId: string) => {
      const group = groups.find(group => group.id === groupId);
      if (group) {
        const newContentsByWell = new Map([
          ...contentsByWell,
          ...generateWellContents(selectedWells, getFirstValue(group.contentsByWell)),
        ]);
        onChange(newContentsByWell);
        setPrevState(newContentsByWell);
      }
      onSelectionChange([]);
    },
    [
      contentsByWell,
      generateWellContents,
      groups,
      onChange,
      onSelectionChange,
      selectedWells,
    ],
  );

  // When the user starts editing a group of wells, select all wells of that
  // group on the plate.
  const handleEditGroup = useCallback(
    (groupId: string) => {
      const group = groups.find(group => group.id === groupId);
      if (!group) {
        return;
      }
      handleSetEditingGroup(group);
      onSelectionChange([...group.contentsByWell.keys()]);
    },
    [groups, handleSetEditingGroup, onSelectionChange],
  );

  const handleRemoveWells = useCallback(
    (groupId: string) => {
      const group = groups.find(group => group.id === groupId);
      if (group) {
        // Only remove wells that are in both the current well group and the current selection.
        const wellsInGroup = [...group.contentsByWell.keys()];
        const wellsToRemove = selectedWells.filter(well => wellsInGroup.includes(well));
        const newContentsByWell = filterMap(
          contentsByWell,
          wellLocation => !wellsToRemove.includes(wellLocation),
        );
        onChange(newContentsByWell);
        setPrevState(newContentsByWell);
      }
      onSelectionChange([]);
    },
    [contentsByWell, groups, onChange, onSelectionChange, selectedWells],
  );

  // We only want to remove wells if the selected wells are part of 1 or more existing well groups.
  // We don't want to show the remove button if the user is currently editing the group.
  const canRemoveWells = useCallback(
    (group: WellGroup) => {
      const wellsFromGroupInCurrentSelection = filterMap(
        group.contentsByWell,
        wellLocation => selectedWells.includes(wellLocation),
      );
      return (
        !isDisabled &&
        group.id !== editingGroupId &&
        wellsFromGroupInCurrentSelection.size > 0
      );
    },
    [editingGroupId, isDisabled, selectedWells],
  );

  // We can add wells as long as the user has selected at least 1 well (we overwrite liquids so
  // the wells do not have to be empty).
  // We don't want to show the add button if the user is currently editing the group.
  const canAddWells = useCallback(
    (group: WellGroup) => {
      const wellsFromGroupInCurrentSelection = filterMap(
        group.contentsByWell,
        wellLocation => selectedWells.includes(wellLocation),
      );

      const selectionContainsWellsFromOtherGroups =
        selectedWells.length > wellsFromGroupInCurrentSelection.size;

      const canAddToSelectedWells =
        wellsFromGroupInCurrentSelection.size === 0 ||
        selectionContainsWellsFromOtherGroups;

      const hasSelectedWells = selectedWells.length > 0 || selectedEmptyWells.length > 0;
      return (
        !isDisabled &&
        hasSelectedWells &&
        group.id !== editingGroupId &&
        canAddToSelectedWells
      );
    },
    [editingGroupId, isDisabled, selectedEmptyWells.length, selectedWells],
  );

  // We show all the groups by default except:
  // - If we are in editing mode (i.e. editingGroupId is defined) in which case only show the group being edited.
  // - Empty groups.
  const groupsToShow = useMemo(
    () =>
      groups.filter(group =>
        !group.isEmpty && editingGroupId ? group.id === editingGroupId : true,
      ),
    [editingGroupId, groups],
  );

  return (
    <div className={classes.list}>
      {groupsToShow.map(
        group =>
          !group.isEmpty && (
            <WellGroupListItem
              key={group.id}
              wellGroup={group}
              isDeletable={!isDisabled}
              isReadOnly={isDisabled}
              wellParameters={wellParameters}
              isEditing={group.id === editingGroupId}
              onEdit={handleEditGroup}
              onAdd={handleAddLiquidCard}
              onChange={handleChange}
              onDelete={handleDelete}
              onCancel={handleCancel}
              onAddWells={canAddWells(group) ? handleAddWells : undefined}
              onRemoveWells={canRemoveWells(group) ? handleRemoveWells : undefined}
              contentPropertyParams={contentPropertyParams}
            />
          ),
      )}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    overflowY: 'auto',
    width: '100%',
  },
}));
