import React from 'react';

import { Position2d } from 'common/types/Position';
import Colors from 'common/ui/Colors';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const ANNOTATION_STROKE = Colors.GREY_80;

export default React.memo(function SVGAnnotation(props: {
  from: Position2d;
  to: Position2d;
  offset: number;
  tailLength: number;
  direction: 'VERTICAL' | 'HORIZONTAL';
  value: number | string;
  unit: string;
  scale: number;
  onClick?: (id: string) => void;
}) {
  const { direction, from, to, offset, unit, value, scale } = props;
  const classes = useStyles();
  const vertical = direction === 'VERTICAL';

  // Move the text sligthly off the line
  const textOffset = scale * (offset <= 0 ? -10 : 10);
  const labelX = vertical ? from.x + offset + textOffset : from.x + (to.x - from.x) / 2;
  const labelY = !vertical ? from.y + offset + textOffset : from.y + (to.y - from.y) / 2;

  // compensate SVG viewbox scaling back, so the text have similar size from the rest of the page.
  const scaleText = `scale(${scale} ${scale})`;
  return (
    <>
      <path
        fill="none"
        vectorEffect="non-scaling-stroke"
        stroke={ANNOTATION_STROKE}
        strokeDasharray={2}
        d={`
         M${from.x} ${from.y}
         l${vertical ? offset : 0} ${vertical ? 0 : offset}
         l${vertical ? 0 : to.x - from.x} ${vertical ? to.y - from.y : 0}
         l${vertical ? -offset : 0} ${vertical ? 0 : -offset}
         L${to.x} ${to.y}`}
      />
      <g transform={`translate(${labelX} ${labelY})`}>
        <text
          x={0}
          y={0}
          transform={scaleText}
          textAnchor={vertical ? 'end' : 'middle'}
          className={classes.legendText}
        >
          {value + ' ' + unit}
        </text>
      </g>
    </>
  );
});

const useStyles = makeStylesHook({
  legendText: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: '12px',
  },
});
