import { useMemo } from 'react';

import { GraphQLDevice } from 'client/app/api/deviceFromGraphql';
import { byName } from 'common/lib/strings';
import { ContentSourceType } from 'common/types/contentSource';
import { getHumanReadableSource } from 'common/ui/components/DeviceCard/DeviceCard';
export default function filterDevices(
  devicesArray: readonly GraphQLDevice[],
  filterQuery: string,
  filterModel: string,
  filterSource: string,
) {
  const lowerCaseQuery = filterQuery ? filterQuery.toLowerCase() : '';
  return devicesArray.filter(device => {
    if (device.model.anthaLangDeviceClass === 'Manual') return false;
    const { name, datastoreID, contentSource } = device;
    const humanReadabaleContentSource = getHumanReadableSource(
      contentSource as unknown as ContentSourceType,
    ).humanReadableName;
    if (!name) return false;

    const fullModelName = `${device.model.series.manufacturer.name} ${device.model.name}`;
    return (
      (name.toLowerCase().includes(lowerCaseQuery) ||
        fullModelName.toLowerCase().includes(lowerCaseQuery) ||
        datastoreID?.toLowerCase().startsWith(lowerCaseQuery)) &&
      (!filterModel || device.model.id === filterModel) &&
      (!filterSource || filterSource.includes(humanReadabaleContentSource))
    );
  });
}

export function useFilteredDevices(
  devices: readonly GraphQLDevice[],
  {
    filterQuery,
    filterModel,
    filterSource,
  }: { filterQuery: string; filterModel: string; filterSource: string },
) {
  const sortedArrayOfDevices = useMemo(() => {
    return [...devices].sort(byName);
  }, [devices]);
  const filteredArrayOfDevices = useMemo(() => {
    return (
      sortedArrayOfDevices &&
      filterDevices(sortedArrayOfDevices, filterQuery, filterModel, filterSource)
    );
  }, [filterModel, filterQuery, filterSource, sortedArrayOfDevices]);
  return filteredArrayOfDevices;
}
