import React, { useCallback, useContext, useEffect, useState } from 'react';

import { InMemoryCacheConfig, useQuery } from '@apollo/client';

import { QUERY_FEATURE_TOGGLES } from 'client/app/api/gql/queries';
import { EarlyTokenContext } from 'client/app/apps/Login/EarlyToken';
import { WelcomeCheck } from 'client/app/apps/Login/WelcomeCheck';
import { simulationsForWorkflow } from 'client/app/apps/workflow-builder/panels/simulations/simulationCacheConfig';
import AppRouter from 'client/app/components/AppRouter/AppRouter';
import { FeatureTogglesDialog } from 'client/app/components/FeatureToggles/FeatureTogglesDialog';
import ExperimentChipPickerContextProvider from 'client/app/components/nav/experiment-chip-picker/ExperimentChipPickerContext';
import ProductAnalyticsTrackers from 'client/app/components/ProductAnalyticsTrackers';
import Screen, { LoadingScreenWithTopMainNav } from 'client/app/components/Screen';
import { UserProfileContextProvider } from 'client/app/hooks/useUserProfile';
import WorkflowBuilderStateContextProvider from 'client/app/state/WorkflowBuilderStateContext';
import { FeatureTogglesContext } from 'common/features/FeatureTogglesContext';
import { DialogManagerProvider } from 'common/ui/components/DialogManager';
import SnackbarManagerProvider from 'common/ui/components/SnackbarManager';
import ApolloProviderWithAuth from 'common/ui/graphql/ApolloProviderWithAuth';

// This is needed to allow for Apollo to recognize that the Simulation is the same entity
// if it has the same id.
const CacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        simulation(_, { args, toReference, canRead }) {
          const ref = toReference({
            __typename: 'Simulation',
            id: args?.id,
          });
          if (!canRead(ref)) {
            return;
          }
          return ref;
        },
        simulationsForWorkflow,
      },
    },
  },
  possibleTypes: {
    ExperimentBlock: [
      'TitleBlock',
      'TextBlock',
      'WorkflowBlock',
      'SimulationBlock',
      'DatasetDerivationBlock',
    ],
  },
};

function PlatformLayoutSimplified() {
  const { earlyGetAccessTokenSilently } = useContext(EarlyTokenContext);

  const [isLoadingFeatureToggles, setIsLoadingFeatureToggles] = useState(true);
  const handleLoadedFeatureToggles = useCallback(
    () => setIsLoadingFeatureToggles(false),
    [],
  );

  return (
    <ApolloProviderWithAuth
      rootPath="/web/graphql/"
      getAccessTokenSilently={earlyGetAccessTokenSilently}
      inMemoryCacheConfig={CacheConfig}
    >
      <WorkflowBuilderStateContextProvider>
        <SnackbarManagerProvider>
          <WelcomeCheck>
            <UserProfileContextProvider>
              <ProductAnalyticsTrackers />
              <DialogManagerProvider>
                <ExperimentChipPickerContextProvider>
                  <FeatureToggleManager
                    handleLoadedFeatureToggles={handleLoadedFeatureToggles}
                  />
                  <FeatureTogglesDialog />
                  {!isLoadingFeatureToggles ? (
                    <Screen>
                      <AppRouter />
                    </Screen>
                  ) : (
                    <LoadingScreenWithTopMainNav />
                  )}
                </ExperimentChipPickerContextProvider>
              </DialogManagerProvider>
            </UserProfileContextProvider>
          </WelcomeCheck>
        </SnackbarManagerProvider>
      </WorkflowBuilderStateContextProvider>
    </ApolloProviderWithAuth>
  );
}

/**
 * This component fetches the Feature Toggles from Appserver
 * and sets them in the UI.
 */
function FeatureToggleManager({
  handleLoadedFeatureToggles,
}: {
  handleLoadedFeatureToggles: () => void;
}) {
  const { data, loading } = useQuery(QUERY_FEATURE_TOGGLES);
  const backendFeatureToggles = data?.featureToggles;
  const uiFeatureToggles = useContext(FeatureTogglesContext);

  useEffect(() => {
    if (!loading) {
      handleLoadedFeatureToggles();
    }
  }, [handleLoadedFeatureToggles, loading]);

  // If users haven't opened the feature toggles dialog in this session,
  // load feature toggles from backend. Otherwise, load the values from
  // the session (i.e. allow users to override features for the current session).
  useEffect(() => {
    if (!backendFeatureToggles || loading) {
      return;
    }

    if (!uiFeatureToggles.hasOpenedFeatureTogglesDialog()) {
      uiFeatureToggles.setAll(backendFeatureToggles);
    } else {
      const currentSessionFeatures = uiFeatureToggles.loadFromSession();
      if (currentSessionFeatures) {
        uiFeatureToggles.setAll(currentSessionFeatures);
      } else {
        // Safety net: Current session's feature list was somehow lost.
        // Use features from backend rather than crashing the UI.
        uiFeatureToggles.setAll(backendFeatureToggles);
      }
    }
  }, [backendFeatureToggles, loading, uiFeatureToggles]);

  return null;
}

export default PlatformLayoutSimplified;
