import React, { useCallback, useMemo, useState } from 'react';

import HelpIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { PanelWithoutScroll } from 'client/app/apps/workflow-builder/panels/Panel';
import {
  useDeckPositionsParameter,
  useSelectedDeckConfiguration,
} from 'client/app/components/Parameters/DeckPositions/lib/deckPositionsParameterUtils';
import MovePlatePosition from 'client/app/components/Parameters/DeckPositions/MovePlatePosition';
import { ScreenRegistry } from 'client/app/registry';
import Button from 'common/ui/components/Button';
import IconButton from 'common/ui/components/IconButton';
import Carrier, { isCarrier } from 'common/ui/components/simulation-details/mix/Carrier';
import Tooltip from 'common/ui/components/Tooltip';
import Workspace from 'common/ui/components/Workspace/Workspace';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const NO_SELECTED_DECK_POSITIONS: string[] = [];

type Props = {
  className: string;
  onClose: () => void;
};

export default function DeckPositionsEditorPanel({ className, onClose }: Props) {
  const styles = useStyles();
  const { loading, deckLayout, plateMoveToPositions } = useSelectedDeckConfiguration();
  const deckPositionsParameter = useDeckPositionsParameter();

  const [selectedDeckPositions, setSelectedDeckPositions] = useState(
    deckPositionsParameter.value ?? NO_SELECTED_DECK_POSITIONS,
  );

  const handleDeselectAll = () => {
    setSelectedDeckPositions(NO_SELECTED_DECK_POSITIONS);
  };
  const handleClose = () => {
    deckPositionsParameter.update(selectedDeckPositions);
    onClose();
  };
  const handleDeckPositionSelect = useCallback((selectedPositionName: string) => {
    setSelectedDeckPositions(state => {
      const idx = state.indexOf(selectedPositionName);
      if (idx === -1) {
        return [...state, selectedPositionName];
      } else {
        return [...state.slice(0, idx), ...state.slice(idx + 1)];
      }
    });
  }, []);

  const deckPositions = useMemo(() => deckLayout?.getAllDeckPositions(), [deckLayout]);
  const findSelectedIndex = (selectedPositionName: string) => {
    return (
      1 + selectedDeckPositions.findIndex(posName => posName === selectedPositionName)
    );
  };

  let panelContents: JSX.Element | null = null;

  if (loading) {
    panelContents = (
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CircularProgress />
      </Box>
    );
  }

  if (deckLayout && deckPositions && plateMoveToPositions) {
    panelContents = (
      <>
        <Box className={styles.editorHeader}>
          <Box className={styles.headerTextGroup}>
            <Typography variant="subtitle2">
              Select one or more positions below where plates can be moved
            </Typography>
            <Tooltip
              title={
                <Typography variant="body2" className={styles.tooltipContents}>
                  Click on one or more deck positions to select them. Plates will be added
                  to the selected deck position based on the order of selection. If the
                  protocol uses more plates than the number of selected positions, plates
                  will be moved to the selected positions sequentially.
                </Typography>
              }
            >
              <IconButton size="xsmall" icon={<HelpIcon className={styles.icon} />} />
            </Tooltip>
          </Box>
          {selectedDeckPositions.length > 1 && (
            <Box className={styles.selectedBtnGroup}>
              <Typography className={styles.selectedLabel} variant="body1">
                {selectedDeckPositions.length} selected
              </Typography>
              <Button
                className={styles.deselectBtn}
                variant="secondary"
                onClick={handleDeselectAll}
              >
                DESELECT
              </Button>
            </Box>
          )}
        </Box>
        <Workspace
          isShowAllButtonVisible
          isShowHelpButtonVisible
          canvasControlVariant="light_float"
          initialShowAll
          logCategory={ScreenRegistry.WORKFLOW}
        >
          <div style={deckLayout.deckBounds}>
            {deckPositions.map(deckPosition =>
              isCarrier(deckPosition) ? (
                <Carrier
                  key={deckPosition.deckPositionName}
                  deckPosition={deckPosition}
                />
              ) : (
                <MovePlatePosition
                  key={deckPosition.deckPositionName}
                  deckPosition={deckPosition}
                  disabled={!plateMoveToPositions.has(deckPosition.deckPositionName)}
                  selected={selectedDeckPositions.includes(deckPosition.deckPositionName)}
                  selectedIndex={findSelectedIndex(deckPosition.deckPositionName)}
                  count={selectedDeckPositions.length}
                  onSelect={handleDeckPositionSelect}
                />
              ),
            )}
          </div>
        </Workspace>
      </>
    );
  }

  return (
    <PanelWithoutScroll
      className={className}
      title="Deck Positions"
      onClose={handleClose}
      onCloseVariant="done"
      panelContent="DeckPositions"
      fullWidth
    >
      {panelContents}
    </PanelWithoutScroll>
  );
}

const useStyles = makeStylesHook(({ palette, spacing }) => ({
  editorHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: spacing(2),
    paddingBottom: spacing(4),
    height: '45px', // fixed size to skip jumping header behaviour
  },
  headerTextGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  selectedBtnGroup: {
    display: 'flex',
    alignItems: 'baseline',
  },
  deselectBtn: {
    padding: spacing(1, 3),
  },
  selectedLabel: {
    color: palette.text.secondary,
    marginRight: spacing(4),
    fontWeight: 400,
  },
  icon: {
    color: palette.text.primary,
    marginLeft: spacing(3),
  },
  tooltipContents: {
    padding: spacing(3, 2),
  },
}));
