import React from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { lowerCaseExceptFirstLetter } from 'common/lib/strings';

function formatParamName(key: string) {
  return lowerCaseExceptFirstLetter(
    key.replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2'),
  );
}

function formatValue(value: any) {
  switch (typeof value) {
    case 'string':
      return value;
    case 'number':
      return `${value}`;
    case 'object':
      if ('value' in value && 'unit' in value) {
        return `${value.value}${value.unit}`;
      }

      return JSON.stringify(value);
    default:
      return null;
  }
}

export default function DeviceParams({ params }: { params?: Record<string, any> }) {
  if (!params) {
    return null;
  }

  return (
    <ParamList>
      {Object.entries(params).map(([key, value]) => {
        const formattedValue = formatValue(value);

        return formattedValue !== null && formattedValue !== '' ? (
          <>
            <Typography variant="body1" component="dt">
              {formatParamName(key)}
            </Typography>
            <Typography variant="subtitle2" component="dd">
              {formatValue(value)}
            </Typography>
          </>
        ) : null;
      })}
    </ParamList>
  );
}

const ParamList = styled('dl')(({ theme: { spacing, palette } }) => ({
  display: 'grid',
  gridTemplateRows: 'auto auto',
  gridTemplateColumns: 'auto',
  gridAutoFlow: 'column',
  justifyContent: 'start',
  rowGap: spacing(2),
  columnGap: spacing(8),
  ' dt': {
    color: palette.text.secondary,
  },
  margin: 0,
}));
