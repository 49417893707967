import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useLocation } from 'react-router-dom';

export type PopoverType = 'standard' | 'DOE' | 'structured-data';

type ExperimentChipPickerContextState = {
  popover: PopoverType | null;
};

const ExperimentChipPickerContext = createContext<
  ExperimentChipPickerContextState & {
    showPopover: (popover: PopoverType) => void;
    hidePopover: () => void;
  }
>({
  popover: null,
  showPopover: () => {},
  hidePopover: () => {},
});

export default function ExperimentChipPickerContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const location = useLocation();

  const [state, setState] = useState<ExperimentChipPickerContextState>({ popover: null });

  const showPopover = useCallback((popover: PopoverType) => setState({ popover }), []);
  const hidePopover = useCallback(() => setState({ popover: null }), []);

  useEffect(() => {
    hidePopover();
  }, [hidePopover, location.pathname]);

  const value = useMemo(
    () => ({
      showPopover,
      hidePopover,
      ...state,
    }),
    [hidePopover, showPopover, state],
  );

  return (
    <ExperimentChipPickerContext.Provider value={value}>
      {children}
    </ExperimentChipPickerContext.Provider>
  );
}

export const useExperimentChipPickerContext = () =>
  useContext(ExperimentChipPickerContext);
