import React, { useCallback, useMemo } from 'react';

import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';

import { useCherryPickContext } from 'client/app/apps/cherry-picker/CherryPickContext';
import { ConfigParameterList } from 'client/app/components/WorkflowSettings/ConfigParameterList';
import { useTipTypes } from 'client/app/lib/workflow/useDeviceTipTypes';
import {
  CHERRYPICK_CONFIG,
  TIPTYPES,
} from 'client/app/lib/workflow/workflowConfigProperties';
import { ScreenRegistry } from 'client/app/registry';
import { Parameter, ParameterValue } from 'common/types/bundle';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';

export default function OtherSimulationSettings() {
  const { setBundleConfig, bundleConfig, selectedDevice, isReadonly } =
    useCherryPickContext();

  const handleChangeSettings = useCallback(
    (parameterChanged: string, value: ParameterValue) => {
      logEvent('settings-others', ScreenRegistry.CHERRY_PICKER, parameterChanged);
      setBundleConfig(prevValues => ({
        ...prevValues,
        GlobalMixer: {
          ...prevValues.GlobalMixer,
          [parameterChanged]: value,
        },
      }));
    },
    [setBundleConfig],
  );

  const tipTypeProps = useTipTypes(selectedDevice);
  const configParameters = useMemo(
    () => [
      /**
       * To get compatible tip types we actually need to send request to backend
       * so TIPTYPES parameter is patched here to include requested data.
       */
      {
        ...TIPTYPES,
        configuration: {
          ...TIPTYPES.configuration,
          editor: {
            ...TIPTYPES.configuration?.editor,
            additionalProps: tipTypeProps,
          },
        },
      } as Parameter,
      ...CHERRYPICK_CONFIG,
    ],
    [tipTypeProps],
  );

  return (
    <Paper>
      <CardHeader title="Simulation settings" />
      <CardContent>
        <DialogContentText gutterBottom>
          Please specify these additional parameters for the simulation
        </DialogContentText>
        <ConfigParameterList
          parameters={configParameters}
          parameterValueDict={bundleConfig.GlobalMixer || {}}
          onChange={handleChangeSettings}
          isDisabled={isReadonly}
        />
      </CardContent>
    </Paper>
  );
}
