import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

import Button from 'common/ui/components/Button';

type Props = {
  title: string;
  message: string;
  maxWidth?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
  onClose: () => void;
} & WithStyles<typeof styles>;

// A simple dialog with a message and a Close button
class InfoDialog extends React.PureComponent<Props> {
  render() {
    const { classes, message, title, maxWidth = 'xs' } = this.props;
    return (
      <Dialog
        open
        maxWidth={maxWidth}
        fullWidth={false}
        // Needed for ESC key to close
        onClose={this.props.onClose}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.content}>{message}</DialogContent>
        <DialogActions>
          <Button variant="tertiary" onClick={this.props.onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = createStyles({
  content: {
    color: '#000',
    whiteSpace: 'pre-line',
  },
});

export default withStyles(styles)(InfoDialog);
