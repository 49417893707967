import React, { useContext } from 'react';

import { AppPermission } from 'common/auth/permissions';
import { createHasPermission } from 'common/ui/components/HasPermission';
import {
  createPermissionsProvider,
  DEFAULT_CONTEXT,
  PermissionsContext,
} from 'common/ui/PermissionsProvider';

const permissionContext = React.createContext<PermissionsContext<AppPermission>>(
  DEFAULT_CONTEXT as PermissionsContext<AppPermission>,
);

export function usePermissions() {
  return useContext(permissionContext);
}

export const PermissionsProvider = createPermissionsProvider(permissionContext);

export const HasPermission = createHasPermission<AppPermission>(usePermissions);
