import React, { useCallback, useMemo } from 'react';

import Typography from '@mui/material/Typography';

import { getResinName } from 'common/lib/chromatography';
import { formatVolumeObj, formatWellPosition } from 'common/lib/format';
import { WellContents, WellLocationOnDeckItem } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import Popover from 'common/ui/components/Popover';
import RoboColumn from 'common/ui/components/simulation-details/mix/RoboColumn';
import {
  Props as WellProps,
  Well,
} from 'common/ui/components/simulation-details/mix/Well';
import { WellLabelContent } from 'common/ui/components/simulation-details/mix/WellLabel';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  wellLocationOnDeckItem: WellLocationOnDeckItem;
  wellContents?: WellContents;
  onWellMouseEnter?: (loc: WellLocationOnDeckItem, e: React.MouseEvent) => void;
  onWellMouseLeave?: (loc: WellLocationOnDeckItem, e: React.MouseEvent) => void;
  onWellPointerUp?: (loc: WellLocationOnDeckItem, e: React.PointerEvent) => void;
  onWellPointerDown?: (loc: WellLocationOnDeckItem, e: React.PointerEvent) => void;
  /** Whether to show the contents of the well (used in Setup) */
  showContentLabel?: boolean;
  showContentTooltip?: boolean;
  contentLabelOverride?: WellLabelContent;
  tooltipTitleOverride?: JSX.Element;
} & WellProps;

export type WellTooltipTitleProps = {
  wellContents?: WellContents;
  wellLocationOnDeckItem: WellLocationOnDeckItem;
};

export default function InteractiveWell(props: Props) {
  const {
    wellContents,
    onWellPointerUp,
    onWellPointerDown,
    onWellMouseEnter,
    onWellMouseLeave,
    showContentLabel,
    showContentTooltip,
    wellLocationOnDeckItem,
    wellPos,
    contentLabelOverride,
    tooltipTitleOverride,
  } = props;

  const handlePointerUp = useCallback(
    (e: React.PointerEvent) => onWellPointerUp?.(wellLocationOnDeckItem, e),
    [onWellPointerUp, wellLocationOnDeckItem],
  );

  const handlePointerDown = useCallback(
    (e: React.PointerEvent) => onWellPointerDown?.(wellLocationOnDeckItem, e),
    [onWellPointerDown, wellLocationOnDeckItem],
  );

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent) => onWellMouseEnter?.(wellLocationOnDeckItem, e),
    [onWellMouseEnter, wellLocationOnDeckItem],
  );

  const handleMouseLeave = useCallback(
    (e: React.MouseEvent) => onWellMouseLeave?.(wellLocationOnDeckItem, e),
    [onWellMouseLeave, wellLocationOnDeckItem],
  );

  const { wellType, color, strokeColor, isHighlighted, isDisabled, hasError } = props;

  const WellComponent =
    wellContents?.kind === 'filter_matrix_summary' ? RoboColumn : Well;

  const tooltipTitle = useMemo(() => {
    return (
      // Preferentially show the tooltipTitleOverride if defined, otherwise default back to LegacyWellTooltipTitle.
      tooltipTitleOverride ?? (
        <div style={{ color: Colors.TEXT_PRIMARY }}>
          <LegacyWellTooltipTitle
            wellContents={wellContents}
            wellLocationOnDeckItem={wellLocationOnDeckItem}
          />
        </div>
      )
    );
  }, [tooltipTitleOverride, wellContents, wellLocationOnDeckItem]);

  return (
    <Popover title={tooltipTitle} disableHoverListener={!showContentTooltip}>
      <g
        onMouseEnter={isDisabled ? undefined : handleMouseEnter}
        onMouseLeave={isDisabled ? undefined : handleMouseLeave}
        onPointerUp={isDisabled ? undefined : handlePointerUp}
        onPointerDown={isDisabled ? undefined : handlePointerDown}
      >
        <WellComponent
          wellPos={wellPos}
          wellType={wellType}
          wellContents={wellContents}
          showContentLabel={showContentLabel}
          contentLabelOverride={contentLabelOverride}
          color={color}
          strokeColor={strokeColor}
          isHighlighted={isHighlighted}
          isDisabled={isDisabled}
          hasError={hasError}
        />
      </g>
    </Popover>
  );
}

const TOOLTIP_MAX_VISIBLE_ITEMS = 4;

function LegacyWellTooltipTitle({
  wellContents,
  wellLocationOnDeckItem,
}: WellTooltipTitleProps) {
  const classes = useStyles();
  if (!wellContents) {
    return <>Empty well at {formatWellPosition(wellLocationOnDeckItem)}</>;
  }
  switch (wellContents.kind) {
    // This is undefined on older simulations, in which case it can be assumed this is a
    // liquid.
    case 'liquid_summary':
    case undefined:
      return (
        <>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.tooltipHeader}
          >
            Well at {formatWellPosition(wellLocationOnDeckItem)}
          </Typography>
          <dl className={classes.tooltipTable}>
            {wellContents.name && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Liquid Name
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {wellContents.name}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents.type && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Liquid Type
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {wellContents?.type}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents?.total_volume.value > 0 && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Volume
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {formatVolumeObj(wellContents?.total_volume)}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents.solutes && (
              <>
                <dt className={classes.tooltipSubheader}>Final Concentrations</dt>
                {wellContents.solutes
                  .slice(0, TOOLTIP_MAX_VISIBLE_ITEMS)
                  .map((solute, idx) => (
                    <dt key={solute.name + idx}>
                      <Typography variant="caption" color="textPrimary">
                        {`${formatVolumeObj(solute.concentration)} ${solute.name}`}
                      </Typography>
                    </dt>
                  ))}
                {wellContents.solutes.length > TOOLTIP_MAX_VISIBLE_ITEMS && (
                  <dt>
                    <Typography variant="caption" color="textSecondary">
                      +{wellContents.solutes.length - TOOLTIP_MAX_VISIBLE_ITEMS} more...
                    </Typography>
                  </dt>
                )}
              </>
            )}
            {wellContents.tags && (
              <>
                <dt className={classes.tooltipSubheader}>Metadata</dt>
                {wellContents.tags.slice(0, TOOLTIP_MAX_VISIBLE_ITEMS).map(tag => (
                  <>
                    <dt>
                      <Typography variant="caption" color="textSecondary">
                        {tag.label || 'n/a'}
                      </Typography>
                    </dt>
                    <dd>
                      <Typography variant="caption" color="textPrimary">
                        {tag.value_float ?? (tag.value_string || 'n/a')}
                      </Typography>
                    </dd>
                  </>
                ))}
                {wellContents.tags.length > TOOLTIP_MAX_VISIBLE_ITEMS && (
                  <dt>
                    <Typography variant="caption" color="textSecondary">
                      +{wellContents.tags.length - TOOLTIP_MAX_VISIBLE_ITEMS} more...
                    </Typography>
                  </dt>
                )}
              </>
            )}
          </dl>
        </>
      );
    case 'filter_matrix_summary':
      return (
        <>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.tooltipHeader}
          >
            Chromatography Column at {formatWellPosition(wellLocationOnDeckItem)}
          </Typography>
          <dl className={classes.tooltipTable}>
            {wellContents.name && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Column Name
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {wellContents.name}
                  </Typography>
                </dd>
              </>
            )}
            <dt>
              <Typography variant="caption" color="textSecondary">
                Resin
              </Typography>
            </dt>
            <dd>
              <Typography variant="caption" color="textPrimary">
                {getResinName(wellContents)}
              </Typography>
            </dd>
            <dt>
              <Typography variant="caption" color="textSecondary">
                Column Volume
              </Typography>
            </dt>
            <dd>
              <Typography variant="caption" color="textPrimary">
                {formatVolumeObj(wellContents?.total_volume)}
              </Typography>
            </dd>
          </dl>
        </>
      );
    case 'filtration_plate_matrix_summary':
      return (
        <>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.tooltipHeader}
          >
            Well at {formatWellPosition(wellLocationOnDeckItem)}
          </Typography>
          <dl className={classes.tooltipTable}>
            {wellContents.name && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Resin Name
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {wellContents.name}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents.total_volume.value > 0 && (
              <>
                <dt>
                  <Typography variant="caption" color="textSecondary">
                    Resin Volume
                  </Typography>
                </dt>
                <dd>
                  <Typography variant="caption" color="textPrimary">
                    {formatVolumeObj(wellContents.total_volume)}
                  </Typography>
                </dd>
              </>
            )}
            {wellContents.tags && wellContents.tags.length > 0 && (
              <>
                <dt className={classes.tooltipSubheader}>Metadata</dt>
                {wellContents.tags.slice(0, TOOLTIP_MAX_VISIBLE_ITEMS).map(tag => (
                  <>
                    <dt>
                      <Typography variant="caption" color="textSecondary">
                        {tag.label || 'n/a'}
                      </Typography>
                    </dt>
                    <dd>
                      <Typography variant="caption" color="textPrimary">
                        {tag.value_float?.toFixed(2) ?? (tag.value_string || 'n/a')}
                      </Typography>
                    </dd>
                  </>
                ))}
                {wellContents.tags.length > TOOLTIP_MAX_VISIBLE_ITEMS && (
                  <dt>
                    <Typography variant="caption" color="textSecondary">
                      +{wellContents.tags.length - TOOLTIP_MAX_VISIBLE_ITEMS} more...
                    </Typography>
                  </dt>
                )}
              </>
            )}
          </dl>
        </>
      );
  }
}

const useStyles = makeStylesHook(({ spacing }) => ({
  tooltipTable: {
    display: 'grid',
    gap: spacing(2, 2),
    margin: spacing(2, 0, 0, 0),
    padding: spacing(2),
    minWidth: '200px',
    fontWeight: 'normal',
    '& dt': {
      gridColumn: 1,
    },
    '& dd': {
      margin: 0,
      gridColumn: 2,
    },
  },
  tooltipHeader: {
    textAlign: 'center',
    padding: spacing(1),
  },
  tooltipSubheader: {
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.1px',
    whiteSpace: 'nowrap',
    paddingTop: spacing(2),
  },
}));
