import React, { useCallback, useMemo } from 'react';

import { useQuery } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import { QUERY_WORKFLOWS_BY_SIMULATIONS } from 'client/app/api/gql/queries';
import * as SimulationsApi from 'client/app/api/SimulationsApi';
import { experimentsStyles } from 'client/app/apps/experiments/commonExperimentsStyles';
import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import RenameSimulationDialog from 'client/app/apps/experiments/Workflows/RenameSimulationDialog';
import { WorkflowCard } from 'client/app/components/cards/WorkflowCard';
import WorkflowCardExpandable from 'client/app/components/cards/WorkflowCardExpandable';
import { ContentType, FavoritedByFilterEnum, WorkflowSourceEnum } from 'client/app/gql';
import usePagination from 'client/app/hooks/usePagination';
import { useSimulationClient } from 'client/app/lib/SimulationClient';
import { ScreenRegistry } from 'client/app/registry';
import { PageInfo } from 'common/server/graphql/pagination';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import { DateRange } from 'common/ui/components/FilterChip/FilterChipWithDateRange';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

type WorkflowListProps = {
  scrollableRef: React.RefObject<HTMLDivElement>;
  searchQuery?: string;
  favorited: boolean;
  filterDateRange: DateRange;
  contentSource?: ContentType;
  filterUserId?: string;
  filterWorkflowTypes: WorkflowSourceEnum[];
  filterSuccessfulSimulations: boolean;
  dialog?: boolean;
  onClick?: (workflowId: WorkflowId) => void;
  disabledWorkflowIds?: WorkflowId[];
};

export function WorkflowList(props: WorkflowListProps) {
  const {
    dialog,
    scrollableRef,
    searchQuery,
    favorited,
    filterDateRange,
    contentSource,
    filterUserId,
    filterWorkflowTypes,
    filterSuccessfulSimulations,
    onClick,
    disabledWorkflowIds,
  } = props;
  const classes = useStyles();
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const deleteSimulation = SimulationsApi.useDeleteSimulation();
  const snackbarManager = useSnackbarManager();

  const [renameSimulationDialog, openRenameSimulationDialog] =
    useDialog(RenameSimulationDialog);
  const simulationService = useSimulationClient();

  const workflowQueryVariables = useMemo(
    () => ({
      search: searchQuery,
      favorited: favorited
        ? FavoritedByFilterEnum.FAVORITED_BY_ANYONE
        : FavoritedByFilterEnum.ALL,
      filterStartDate: filterDateRange.startDate
        ? filterDateRange.startDate.format('YYYY-MM-DD')
        : undefined,
      filterEndDate: filterDateRange.endDate
        ? filterDateRange.endDate.format('YYYY-MM-DD')
        : undefined,
      userId: filterUserId,
      workflowTypeFilter: filterWorkflowTypes ?? undefined,
      filterSimulationStatus: filterSuccessfulSimulations ? 'COMPLETED' : undefined,
      contentSource: contentSource,
    }),
    [
      contentSource,
      favorited,
      filterDateRange.endDate,
      filterDateRange.startDate,
      filterSuccessfulSimulations,
      filterUserId,
      filterWorkflowTypes,
      searchQuery,
    ],
  );

  const onRenameSimulation = useCallback(
    async (
      simulationId: SimulationId,
      simulationName: string,
      workflowId: WorkflowId,
    ) => {
      const updatedSimulationName = await openRenameSimulationDialog({
        screenRegistry: ScreenRegistry.EXPERIMENTS,
        simulationName,
      });
      if (updatedSimulationName && updatedSimulationName !== simulationName) {
        logEvent('rename-simulation', ScreenRegistry.EXPERIMENTS, 'new-name');
        await simulationService.updateSimulation(
          simulationId,
          updatedSimulationName,
          workflowId,
          workflowQueryVariables,
        );
      } else {
        logEvent('rename-simulation', ScreenRegistry.EXPERIMENTS, 'no-change');
      }
    },
    [openRenameSimulationDialog, simulationService, workflowQueryVariables],
  );

  const onDeleteSimulation = useCallback(
    async (
      simulationId: SimulationId,
      simulationName: string,
      workflowId: WorkflowId,
    ) => {
      logEvent('delete-simulation', ScreenRegistry.EXPERIMENTS);
      const isDeleteConfirmed = await openConfirmationDialog({
        action: 'delete',
        isActionDestructive: true,
        object: 'simulation',
        specificObject: `${simulationName}`,
      });
      if (isDeleteConfirmed) {
        try {
          await deleteSimulation(simulationId, workflowId, workflowQueryVariables);
        } catch (e) {
          console.error(e);
          snackbarManager.showError(
            `Simulation:${simulationId} (${simulationName}) has been shared elsewhere and cannot be deleted. Please refresh the page.`,
          );
        }
      }
    },
    [deleteSimulation, openConfirmationDialog, snackbarManager, workflowQueryVariables],
  );

  const {
    loading: isInitialLoading,
    error,
    data,
    refetch,
    fetchMore,
  } = useQuery(QUERY_WORKFLOWS_BY_SIMULATIONS, {
    variables: workflowQueryVariables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const workflows = data?.workflowsBySimulations?.items || [];

  const pageInfo = data?.workflowsBySimulations.pageInfo as PageInfo | undefined;
  const dependencies = [
    searchQuery,
    favorited,
    filterDateRange,
    filterUserId,
    filterSuccessfulSimulations,
  ];
  const hasNextPage = usePagination({
    entity: 'workflowsBySimulations',
    pageInfo,
    fetchMore,
    dependencies,
    scrollableRef,
    isInitialLoading,
    variables: workflowQueryVariables,
  });

  if (error) {
    return <GraphQLErrorPanel error={error} onRetry={refetch} />;
  }
  if (isInitialLoading) {
    return <CircularProgress />;
  }

  if (!data || data.workflowsBySimulations.items.length === 0) {
    return (
      <NoEntitiesMessage
        entityName="workflows"
        messageType={MessageType.NO_FILTER_RESULTS}
        searchQuery={searchQuery}
      />
    );
  }

  return (
    <div className={classes.list}>
      {workflows.map(workflow =>
        dialog ? (
          <WorkflowCard
            key={workflow.id}
            workflow={workflow}
            onClick={() => onClick?.(workflow.id)}
            disabled={disabledWorkflowIds?.includes(workflow.id)}
          />
        ) : (
          <WorkflowCardExpandable
            key={workflow.id}
            workflow={workflow}
            onDeleteSimulation={onDeleteSimulation}
            onRenameSimulation={onRenameSimulation}
            workflowQueryVariables={workflowQueryVariables}
          />
        ),
      )}
      {hasNextPage && (
        <div className={classes.circularLoadingContainer}>
          <CircularProgress size={24} />
        </div>
      )}
      {confirmationDialog}
      {renameSimulationDialog}
    </div>
  );
}

const useStyles = makeStylesHook(theme => ({
  ...experimentsStyles(theme),
}));
