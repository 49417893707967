import React from 'react';

import { useQuery } from '@apollo/client';
import { matchPath, Route, useLocation } from 'react-router-dom';

import { QUERY_SIMULATION_ID_FROM_EXECUTION_ID } from 'client/app/api/gql/queries';
import UIErrorBox from 'client/app/components/UIErrorBox';
import { executionRoutes, simulationRoutes } from 'client/app/lib/nav/actions';
import { isUUIDInfer } from 'common/lib/strings';
import LinearProgress from 'common/ui/components/LinearProgress';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { ScreenIds } from 'common/ui/components/simulation-details/simulationDetailsScreenIds';
import { NavigationRoute } from 'common/ui/navigation';

// Extract the type of the params from the route, so that we don't have to list
// the params here again. It is important the param list only exists in one place.
type GetRouteParams<RouteType> = RouteType extends NavigationRoute<infer T> ? T : never;

type RouteParams = GetRouteParams<typeof executionRoutes.executionDetailsSubscreen>;

/**
 * The ExecutionDetailsScreen serves as a wrapper for the SimulationDetails component but allowing
 * users to use the executionId as part of the URL (rather than the simulationId).
 *
 * A Simulation can be associated with an Execution (and vice versa).
 * There can be only one Execution per Simulation (and vice versa).
 * So what we do typically is pass the simulationId as part of the URL, and then query the simulation info
 * (which will include the execution info).
 *
 * e.g. /#/simulation-details/{simulatonId}
 *
 * With this wrapper, we query the simulationId from the executionId - and then use that simulationId
 * to naviate to the SimulationDetailsScreen
 *
 * e.g. /#/execution-details/{executionId}
 *
 */
export default function LegacyExecutionDetailsScreen() {
  const location = useLocation();
  const match = matchPath<RouteParams>(location.pathname, {
    path: executionRoutes.executionDetailsSubscreen.pathTemplate,
    exact: true,
  });

  const { navigate } = useNavigation();

  const params = match?.params.executionId
    ? { variables: { id: match.params.executionId } }
    : { skip: true };

  const { data, loading, error } = useQuery(QUERY_SIMULATION_ID_FROM_EXECUTION_ID, {
    ...params,
  });

  if (!match?.params.executionId || !isUUIDInfer<ExecutionId>(match.params.executionId)) {
    return <UIErrorBox>Sorry we could not find this Execution</UIErrorBox>;
  }

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <UIErrorBox>{error.message}</UIErrorBox>;
  }

  return (
    <Route
      path={executionRoutes.executionDetailsSubscreen.pathTemplate}
      exact
      render={() => {
        if (data?.execution.simulation.id) {
          navigate(
            simulationRoutes.simulationDetailsSubscreen,
            {
              simulationId: data.execution.simulation.id,
              subscreenId: ScreenIds.RESULTS,
            },
            { method: 'replace', keepURLSearchParams: true },
          );
        }
        return <UIErrorBox>Sorry we could not find this Execution</UIErrorBox>;
      }}
    />
  );
}
