import React, { useCallback, useEffect, useRef, useState } from 'react';

import { SIMULATION_DETAILS_PREVIEW_TAB_ID } from 'common/ui/AnalyticsConstants';
import Colors from 'common/ui/Colors';
import { KeyPoint } from 'common/ui/components/simulation-details/StepSlider';
import { Props as StepSliderProps } from 'common/ui/components/simulation-details/StepSlider';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useThrottle from 'common/ui/hooks/useThrottle';

// TODO Fixed width to keep it simple for now. Making this work with flexible
// width will require measuring a DOM node and updating on resize, which needs
// a bit of research (there are good popular libraries but they have a few
// dependencies).
const MAX_X = 500;
const SLIDER_BUTTON_WIDTH = 18;
const SLIDER_BAR_TOP = 8;
const SLIDER_BAR_HEIGHT = 18;

/**
 * Debounce analytics event logging when dragging the slider.
 */
const ANALYTICS_LOG_DEBOUNCE_MS = 1000;

function getKeyPointColor(keyPoint: KeyPoint) {
  switch (keyPoint.kind) {
    case 'tipbox':
      return Colors.TIPBOX;
    case 'prompt':
      return Colors.KEY_POINT_DEFAULT;
    case 'error':
      return Colors.ERROR_LIGHT;
    case 'transfer':
      return Colors.KEY_POINT_TRANSFER;
    case 'stage':
      return Colors.GREY_60;
    default:
      // Unknown type of key point
      return Colors.KEY_POINT_DEFAULT;
  }
}

type Props = Pick<StepSliderProps, 'currentStep' | 'onStepChange' | 'keyPoints'> & {
  maxStep: number;
};

/**
 * Draggable part of the StepSlider
 */
export default function StepSliderBar({
  maxStep,
  currentStep,
  onStepChange,
  keyPoints,
}: Props) {
  const classes = useStyles();

  // For dragging, keep a reference of the slider bar so we know the bounds to
  // constrain the slider button.
  const sliderBar = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const getPosOfStep = useCallback((step: number) => (step / maxStep) * MAX_X, [maxStep]);

  const debouncedDragLogEvent = useThrottle(
    () => logEvent('drag-slider', SIMULATION_DETAILS_PREVIEW_TAB_ID),
    ANALYTICS_LOG_DEBOUNCE_MS,
  );

  const updateSliderPosition = useCallback(
    (pageX: number) => {
      if (!sliderBar.current) {
        return;
      }
      debouncedDragLogEvent();
      const sliderX = sliderBar.current.getBoundingClientRect().x;
      const dragPos = Math.max(0, Math.min(MAX_X, pageX - sliderX));
      const newStep = Math.round((dragPos / MAX_X) * maxStep);
      if (newStep !== currentStep) {
        onStepChange(newStep);
      }
    },
    [currentStep, debouncedDragLogEvent, maxStep, onStepChange],
  );

  const handleDragStart = useCallback(
    (e: React.PointerEvent) => {
      setIsDragging(true);
      updateSliderPosition(e.pageX);
      e.preventDefault(); // prevent text selection
    },
    [updateSliderPosition],
  );

  const handleDragEnd = useCallback(() => setIsDragging(false), []);

  const handleDrag = useCallback(
    (e: PointerEvent) => {
      if (!isDragging) {
        return;
      }
      updateSliderPosition(e.pageX);
    },
    [isDragging, updateSliderPosition],
  );

  useEffect(() => {
    window.addEventListener('pointermove', handleDrag);
    window.addEventListener('pointerup', handleDragEnd);
    return () => {
      window.removeEventListener('pointermove', handleDrag);
      window.removeEventListener('pointerup', handleDragEnd);
    };
  }, [handleDrag, handleDragEnd]);

  const buttonPos = getPosOfStep(currentStep);
  // Make slider button highlighted if we are at a key point
  const keyPointUnderSliderButton = keyPoints.find(kp => kp.step === currentStep);

  return (
    <div className={classes.slider} ref={sliderBar} onPointerDown={handleDragStart}>
      {/* Slider bar */}
      <div className={classes.sliderBar} />
      {/* Keypoints */}
      {keyPoints.map(kp => (
        <div
          key={kp.step}
          className={classes.keyPoint}
          style={{
            left: `${getPosOfStep(kp.step)}px`,
            backgroundColor: getKeyPointColor(kp),
          }}
        />
      ))}
      {/* Slider button */}
      <div className={classes.sliderButton} style={{ left: `${buttonPos}px` }}>
        <div
          className={classes.sliderButtonInner}
          style={
            keyPointUnderSliderButton && {
              backgroundColor: getKeyPointColor(keyPointUnderSliderButton),
            }
          }
        />
      </div>
    </div>
  );
}

const useStyles = makeStylesHook({
  slider: {
    position: 'relative',
    width: `${MAX_X}px`,
    height: '32px',
  },
  sliderBar: {
    background: Colors.SLIDER_BAR,
    height: `${SLIDER_BAR_HEIGHT}px`,
    position: 'absolute',
    top: `${SLIDER_BAR_TOP}px`,
    width: `${MAX_X}px`,
  },
  keyPoint: {
    position: 'absolute',
    background: Colors.KEY_POINT_DEFAULT,
    height: `${SLIDER_BAR_HEIGHT}px`,
    top: `${SLIDER_BAR_TOP}px`,
    width: '4px',
  },
  sliderButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: '4px',
    // Move left half the width so it's centered
    transform: `translateX(${-SLIDER_BUTTON_WIDTH / 2}px)`,
  },
  sliderButtonInner: {
    background: Colors.SLIDER_BUTTON,
    border: '1px #fff solid',
    height: '26px',
    width: `${SLIDER_BUTTON_WIDTH}px`,
  },
});
